import React from "react";
import {useSelector} from "react-redux";

import {Card, Typography, Button, Divider} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {RootState} from "../../../reducers";
import TicketDialog from "./Dialog";
import TicketCard from "./TicketCard";

const ticketQtySelector = (state: RootState) => state.ticket.qty;

const TicketInfo = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const ticketQty = useSelector(ticketQtySelector);

  return (
    <div className={classes.root}>
      <Typography variant={"h1"}>配信数</Typography>

      <Card className={classes.card}>
        <div className={classes.content}>
          <div className={classes.info}>
            <Typography variant="h1">今月の残り配信数</Typography>
            <div className={classes.counter}>
              <Typography className={classes.count} variant="h2">
                {ticketQty.default.available + ticketQty.non_default.available}
              </Typography>
              <Typography className={classes.label} variant="h1">
                回
              </Typography>
              <Typography
                className={classes.subLabel}
                variant="body1"
              >{`(残り配信数: ${ticketQty.default.available +
                ticketQty.non_default.available}
                                予約中: ${ticketQty.default.reserved +
                                  ticketQty.non_default
                                    .reserved} )`}</Typography>
            </div>
          </div>
          <div>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              追加申請する
            </Button>
          </div>
        </div>
        <Divider />
        <div className={classes.container}>
          <TicketCard
            ticketName={"プラン内配信"}
            count={ticketQty.default.available}
          />
          <TicketCard
            ticketName={"追加購入配信"}
            count={ticketQty.non_default.available}
          />
        </div>
      </Card>
      <TicketDialog isOpen={open} closeHandle={() => setOpen(false)} />
    </div>
  );
};

export default TicketInfo;

const useStyles = makeStyles({
  root: {
    margin: "24px 0 24px 0"
  },
  card: {
    padding: "24px 16px 20px 16px"
  },
  content: {
    justifyContent: "space-between",
    display: "flex"
  },
  info: {
    display: "flex",
    alignItems: "center"
  },
  counter: {
    display: "flex",
    marginLeft: "16px",
    alignItems: "center"
  },
  count: {
    fontSize: "46px",
    color: "#2eb964"
  },
  label: {
    margin: "10px",
    color: "#2eb964"
  },
  subLabel: {
    margin: "10px"
  },
  container: {
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  }
});
