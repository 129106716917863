import React from "react";

//component
import PageTitle from "../PageTitle";
import Title from "../Title";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from "../../../assets/help/setting/plan/image01.png";
import Image02 from "../../../assets/help/setting/plan/image02.png";
import Image03 from "../../../assets/help/setting/plan/image03.png";
import Image04 from "../../../assets/help/setting/plan/image04.png";

const PlanAndTicket = () => {
  return (
    <article className={style.root}>
      <a id="1" className={style.anchor} />
      <div className={style.box}>
        <PageTitle title={"各種設定"} />
        <Title title={"6. プラン/オプション設定"} />
        <Typography gutterBottom variant={"body1"}>
          右上のプルダウンから「プラン/オプション」を選択します。
        </Typography>
        <div className={style.img}>
          <img src={Image01} />
        </div>
        <Typography gutterBottom variant={"body1"}>
          配信数では、今月の残り配信数の確認や、配信数の追加申請ができます。
        </Typography>
        <div className={style.img}>
          <img src={Image02} />
        </div>
        <Typography gutterBottom variant={"body1"}>
          オプションでは、メンバー枠や、記事化レーダーで使用するキーワード数の追加ができます。
        </Typography>
        <div className={style.img}>
          <img src={Image03} />
        </div>
        <Typography gutterBottom variant={"body1"}>
          プランでは、各プランで利用できる機能の確認や、プラン変更ができます。
          <br />
          プランの変更は、月1回までとなっています。
        </Typography>
        <div className={style.img}>
          <img src={Image04} />
        </div>
      </div>
    </article>
  );
};

export default PlanAndTicket;
