interface FilterTypesModel {
    creator: 'creator',
    reviewer: 'reviewer',
    tag: 'tag',
}

export const PressFilterTypes: FilterTypesModel = {
    creator: 'creator',
    reviewer: 'reviewer',
    tag: 'tag',
}
