export const fixed_colors: string[] = [
    '#e60012',
    '#f39800',
    '#fff100',
    '#009944',
    '#0068b7',
    '#1d2088',
    '#920783',
];

export const defaultBgColor = '#aaaaaa';
export const blackText = '#444';
export const whiteText = '#fff';
