import React from 'react';
import Title from '../Title'

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/pressRelease/creator/image01.png';
import Image02 from '../../../assets/help/pressRelease/creator/image02.png';
import Image03 from '../../../assets/help/pressRelease/creator/image03.png';
import Image04 from '../../../assets/help/pressRelease/creator/image04.png';
import Image05 from '../../../assets/help/pressRelease/creator/image05.png';
import SubTitle from "../SubTitle";
import Typography from "@material-ui/core/Typography";

const Create = () => {
	return (
		<div className={style.box}>
			<Title title={"2. 共同編集"}/>
			<SubTitle step={1} title={"共同編集メンバーの追加"}/>
			<Typography gutterBottom variant={"body1"}>
				右パネルの作成メンバーの「追加」ボタンを押下します。<br/>
				※「追加」ボタンは作成者にのみ表示されます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				共同編集したいメンバーを選択します。<br/>
				事前にプロジェクトにメンバー追加する必要があります。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				メンバーを選択後、右下の「追加」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				追加したメンバーはパネルの作成メンバーに表示されます。
			</Typography>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
			<SubTitle step={2} title={"メンバーの削除"}/>
			<Typography gutterBottom variant={"body1"}>
				メニューからメンバーの削除を行えます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image05}/>
			</div>
		</div>
	);
};

export default Create
