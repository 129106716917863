import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route, RouteComponentProps, Switch} from "react-router-dom";

// components
import AppLayout from "../../components/Layout/App";
import Detail from "../../components/Setting/Detail/Company";
import CompanyEdit from "../../components/Form/CompanyEdit";
import ServiceDetail from "../../components/Setting/Detail/Service";
import ServiceEdit from "../../components/Form/ServiceEdit";
import Group from "../../components/Setting/Group";
import Plan from "../../components/Setting/Plan";
import Payment from "../../components/Setting/Payment";
import Radar from "../../components/Setting/Radar";

import Title from "../../components/Common/Title";
import HelpFab from "../../components/Common/HelpFab";
import AddOption from "../../components/Setting/Option/Add";
import RemoveOption from "../../components/Setting/Option/Remove";
import PlanApplyForm from "../../components/Form/PlanApply";
import ApplyComplete from "../../components/Setting/Plan/PlanApplyComplete";

// reducer
import {RootState} from "../../reducers";

// actions
import * as PaymentActionCreator from "../../actions/Payment/ActionCreator";
import * as PaymentHistoryActionCreator from "../../actions/PaymentHistory/ActionCreator";
import * as CompanyActionCreators from "../../actions/Company/ActionCreator";
import * as TicketActionCreators from "../../actions/Ticket/ActionCreator";
import * as OrderInfoActionCreators from "../../actions/OrderInfo/ActionCreator";

// models
import {CompanyModel} from "../../model/CompanyModel";

// configs
import {RADAR, MEMBER} from "../../config/payment_config";

const companySelector = (state: RootState) => state.company;
const groupSelector = (state: RootState) => state.group;
const optionSelector = (state: RootState) => state.option;

interface Props extends RouteComponentProps<void> {}

const SettingPage: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const group = useSelector(groupSelector);
  const option = useSelector(optionSelector);

  useEffect(() => {
    if (group.selectedId) {
      dispatch(CompanyActionCreators.getCompany.request());
      dispatch(PaymentActionCreator.getCardEditURL.request());
      dispatch(PaymentActionCreator.getCardInfo.request());
      dispatch(
        PaymentHistoryActionCreator.getPaymentHistory.request({offset: 0})
      );
      dispatch(OrderInfoActionCreators.getOrderInfoList.request({offset: 0}));
    }
  }, [group.selectedId]);

  useEffect(() => {
    if (company.uuid) {
      dispatch(TicketActionCreators.getTicketQty.request());
    }
  }, [company.uuid, group.selectedId]);

  const onSubmitPatch = (values: CompanyModel) => {
    dispatch(CompanyActionCreators.patchCompany.request(values));
  };

  const onSubmitPost = (values: CompanyModel) => {
    dispatch(CompanyActionCreators.postCompany.request(values));
  };

  const initService = {
    ...company,
    is_public_page: String(company.is_public_page),
    is_review_skip: String(company.is_review_skip)
  };

  return (
    <AppLayout>
      <Title>
        企業設定
        <HelpFab url={"/help/company#1"} />
      </Title>
      <Switch>
        <Route exact={true} path="/setting/" component={Detail} />
        <Route exact={true} path="/setting/company" component={Detail} />
        <Route
          exact={true}
          path="/setting/company/edit/"
          render={props => (
            <CompanyEdit onSubmit={onSubmitPatch} initialValues={company} />
          )}
        />
        <Route exact={true} path="/setting/service" component={ServiceDetail} />
        <Route
          exact={true}
          path="/setting/service/edit/"
          render={props => (
            <ServiceEdit onSubmit={onSubmitPatch} initialValues={initService} />
          )}
        />
        <Route
          exact={true}
          path="/setting/company/crate/"
          render={props => <CompanyEdit onSubmit={onSubmitPost} />}
        />
        <Route exact={true} path="/setting/plan/" component={Plan} />
        <Route
          exact={true}
          path="/setting/option/member/add/"
          render={props => (
            <AddOption
              option={option.member}
              optionInfo={company.option.member}
            />
          )}
        />
        <Route
          exact={true}
          path="/setting/option/member/remove/"
          render={props => <RemoveOption option={option.member} />}
        />
        <Route
          exact={true}
          path="/setting/option/radar/add/"
          render={props => (
            <AddOption
              option={option.radar}
              optionInfo={company.option.radar}
            />
          )}
        />
        <Route
          exact={true}
          path="/setting/option/radar/remove/"
          render={props => <RemoveOption option={option.radar} />}
        />
        <Route exact={true} path="/setting/payment/" component={Payment} />
        <Route
          exact
          path="/setting/plan/apply/complete/"
          component={ApplyComplete}
        />
        <Route
          exact
          path="/setting/plan/apply/"
          render={props => (
            <PlanApplyForm locationState={props.location.state} />
          )}
        />
        <Route exact={true} path="/setting/plan/" component={Plan} />
        <Route exact={true} path="/setting/radar/" component={Radar} />
      </Switch>
    </AppLayout>
  );
};

export default SettingPage;
