import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router';
import { NavLink } from "react-router-dom";

// component
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Divider, makeStyles, Button, Typography, Checkbox, Paper, Tooltip } from "@material-ui/core";

// action
import * as ActionCreators from "../../../../../actions/PressReleaseReserve/ActionCreator";

// state
import { RootState } from "../../../../../reducers";

// image
import errorIcon from '../../../../../assets/icon-error.svg';

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const mediaSelector = (state: RootState) => state.media;
const companySelector = (state: RootState) => state.company;

const useStyles = makeStyles({
	text: {
		fontSize: 16,
		fontWeight: 'bold',
		padding: 3,
		color: '#6d7470',
	},
	button: {
		display: 'flex',
		padding: '5px 10px',
		width: '100%',
		backgroundColor: '#fff',
	},
	activeButton: {
		display: 'flex',
		padding: '5px 10px',
		backgroundColor: '#E3F5EA',
		width: '100%',
	},
	addButton: {
		textAlign: "center",
		padding: 10
	},
	noDataText: {
		padding: '20px 0',
		textAlign: 'center',
	},
	info: {
		background: "#fff",
		border: '1px solid #e2e9e5',
		boxShadow: 'none',
		height: "100%"
	},
	itemSecondary: {
		display: 'flex',
		alignItems: 'center'
	},
	errorIcon: {
		width: 18,
		height: 18
	},
	upgradeButton: {
		marginTop: 5
	},
});

interface Props {
	handleReserveSave,
	isLocked: boolean,
}

const Side: React.FC<Props> = ({ handleReserveSave, isLocked }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const reserve = useSelector(pressReleaseReserveSelector);
	const pressRelease = useSelector(pressReleaseSelector);
	const media = useSelector(mediaSelector);
	const company = useSelector(companySelector);
	const { auto, custom } = reserve.data;
	const mediaLimit = company.plan.media_limit;

	const [displayButton, setDisplayButton] = useState<boolean>(false);

	const listId = auto.list_id;
	const favoriteList = media.favoriteList.results;
	const mediaList = listId === 0 ? auto.id_list :
		favoriteList.length > 0 && favoriteList.find(i => i.list_id === listId).id_list || [];

	const handleListItemClick = () => dispatch(ActionCreators.setPressReleaseReserveActive.request(0));

	const handleChangeSend = () => {
		dispatch(ActionCreators.setPressReleaseReserveAuto.request({ is_send: !auto.is_send }));
	}

	const hasEdit = pressRelease.role.edit;
	const { isReserveEdit } = pressRelease.detail;
	const idListCheck = (listId === 0 && auto.id_list.length === 0) ||
		(listId > 0 && favoriteList.length > 0 && favoriteList.find(i => i.list_id === media.favoriteList.index).id_list.length === 0);


	useEffect(() => {
		if (mediaLimit) {
			setDisplayButton(custom.length < mediaLimit);
		} else {
			setDisplayButton(true);
		}
	}, [custom.length]);

	return (
		<Paper className={classes.info}>
			<List>
				<ListItem button className={reserve.active === 0 ? classes.activeButton : classes.button}>
					<ListItemText
						classes={{ primary: classes.text, secondary: classes.text }}
						primary={`配信リスト・${mediaList.length}媒体`}
						onClick={handleListItemClick}
					/>
					<ListItemSecondaryAction className={classes.itemSecondary}>
						{auto.is_send && (!auto.mail_title || !auto.mail_body || idListCheck) &&
							<ErrorIcon error={[{ 件名: auto.mail_title }, { 本文: auto.mail_body }, { 配信先: idListCheck }]} />}
						<Checkbox
							onChange={handleChangeSend}
							checked={auto.is_send}
							color="primary"
							disabled={!hasEdit || !isReserveEdit || isLocked}
						/>
					</ListItemSecondaryAction>
				</ListItem>
				<Divider />
				{custom.map((item, index) => (
					<CustomSideItem item={item} key={index} isLocked={isLocked}/>
				))}
				{custom.length === 0 && (
					<Typography variant='h6' className={classes.noDataText}>
						登録済みのメディアはありません。<br />
						下のボタンから登録ページに移動します。
					</Typography>)}
				<div className={classes.addButton}>
					{custom.length === 0 && <Typography variant="h6" gutterBottom>登録メディア0件</Typography>}
					{displayButton ?
						<Button variant="outlined" size="large" color="primary"
								disabled={!reserve.data.categories.length}
								onClick={() => {
									handleReserveSave()
									dispatch(push("/media/create/"))
								}}>
							メディアを追加
						</Button>
						:
						<>
							<Typography variant="h6">
								登録できるメディア数が上限に達しています。<br/>
								メディアの追加を行うには、プランのアップグレードが必要です。<br/>
							</Typography>
							<NavLink exact to={"/setting/plan"}>
								<Button variant="contained" size="large" color="primary" className={classes.upgradeButton}>
									プランのアップグレード
								</Button>
							</NavLink>
						</>
					}
				</div>
			</List>
		</Paper>
	);
}

export default Side;

interface ContentsProps {
	item,
	isLocked: boolean,
}

const CustomSideItem: React.FC<ContentsProps> = ({ item, isLocked }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const reserve = useSelector(pressReleaseReserveSelector);
	const pressRelease = useSelector(pressReleaseSelector);

	const handleListItemClick = () => {
		if (item.id) dispatch(ActionCreators.setPressReleaseReserveActive.request(item.id));
	};

	const handleChangeSend = () => {
		if (!item.is_send) handleListItemClick();
		dispatch(ActionCreators.setPressReleaseReserveCustom.request({ id: item.id, is_send: !item.is_send }));
	};

	const hasEdit = pressRelease.role.edit;
	const { isReserveEdit } = pressRelease.detail;

	return (
		<>
			<ListItem button onClick={handleListItemClick} className={reserve.active === item.id ? classes.activeButton : classes.button}>
				<ListItemText
					classes={{ primary: classes.text, secondary: classes.text }}
					primary={item.name || item.publisher || `${item.last_name} ${item.first_name ? item.first_name : ''}`}
					onClick={handleListItemClick}
				/>
				<ListItemSecondaryAction className={classes.itemSecondary}>
					{item.is_send && (!item.mail_title || !item.mail_body) &&
						<ErrorIcon error={[{ 件名: item.mail_title }, { 本文: item.mail_body }]} />}
					<Checkbox
						onChange={handleChangeSend}
						checked={Boolean(item.is_send)}
						color="primary"
						disabled={!hasEdit || !isReserveEdit || isLocked}
					/>
				</ListItemSecondaryAction>
			</ListItem>
			<Divider />
		</>
	);
}


interface ErrorIconProps {
	error: any[],
}

const ErrorIcon: React.FC<ErrorIconProps> = ({ error }) => {
	const errorArray: string[] = [];
	const classes = useStyles();

	error.map(i => {
		if (typeof Object.values(i)[0] === 'string' ? !Object.values(i)[0] : Object.values(i)[0]) {
			errorArray.push(Object.keys(i)[0]);
		}
	})
	const errorMessage = 'メールの' + errorArray.join('と') + 'を確認してください';

	return (
		<Tooltip title={errorMessage} arrow>
			<img src={errorIcon} className={classes.errorIcon}/>
		</Tooltip>
	);
}
