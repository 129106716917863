import React from 'react';
import {useDispatch} from "react-redux";

//material-ui
import {TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//component
import {TableItemContent} from "./TableItemContent";
import {TableItemContentForm} from "./TableItemContentForm";

//model
import {RadarWordModel} from "../../../../model/RadarModel";

//action
import * as ActionCreators from "../../../../actions/Radar/ActionCreator";

interface TableItemProps {
    item: RadarWordModel
    dialogOpenHandler: (isOpen: boolean) => void
    deleteTargetIdSetter: (id: number | null) => void
}

export const TableItem: React.FC<TableItemProps> = ({item, dialogOpenHandler, deleteTargetIdSetter}) => {
    const dispatch = useDispatch();
    const [form, setForm] = React.useState(false);
    const [word, setWord] = React.useState("");
    const [excludeWord, setExcludeWord] = React.useState("");

    const handleEditClick = () => {
        setWord(item.radar_word);
        setExcludeWord(item.exclude_word || "");
        setForm(true);
    }

    const handleEditCancelClick = () => {
        setForm(false);
    }

    const handleUpdate = () => {
        dispatch(ActionCreators.patchUpdateRadarWord.request({
            id: item.id,
            radar_word: word,
            exclude_word: excludeWord
        }));
        setForm(false);
        setWord('')
        setExcludeWord('')
    }

    const handleDeleteClick = (): void => {
        deleteTargetIdSetter(item.id)
        dialogOpenHandler(true)
    }

    return (
        <>
            <TableRow>
                {form ?
                    <TableItemContentForm
                        wordInfo={item}
                        word={word}
                        excludeWord={excludeWord}
                        wordChangeHandler={(e) => setWord(e.target.value)}
                        excludeWordChangeHandler={(e) => setExcludeWord(e.target.value)}
                        handleUpdate={handleUpdate}
                        handleEditCancelClick={handleEditCancelClick}
                    />
                    :
                    <TableItemContent
                        wordInfo={item}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                    />
                }
            </TableRow>
        </>
    )
}
