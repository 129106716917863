import React from "react";
import {NavLink} from "react-router-dom";
import clsx from 'clsx';

// material-ui
import {makeStyles} from "@material-ui/core/styles";
import {Typography, Button, Card, CardHeader, CardContent, CardMedia} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";

// model
import {StepModel} from "../TopPageStep";


interface StepContentProps {
	step: StepModel,
	activeStep: number,
	handleSetStep: (type: StepModel["type"], newStep: number) => void,
}

const StepContent: React.FC<StepContentProps> = ({step, activeStep, handleSetStep}) => {
	const classes = useStyles();
	const endStep: boolean = activeStep === step.contents.length;

	return (
		<Card className={clsx(classes.card, {
			[classes.reset]: endStep,
		})}>
			{endStep ?
				<CardContent>
					<Typography variant="body1">全てのステップが終了しました</Typography>
					<Button variant="outlined" color="primary" onClick={() => handleSetStep(step.type, 0)} className={classes.button}>
						Step1へ戻る
					</Button>
				</CardContent>
				:
				step.contents.map((content, index) => {
					return (
						activeStep === index &&
						<React.Fragment key={index}>
							<CardHeader title={`Step${index + 1} ${content.label}`} />
							<CardContent className={classes.cardContent}>
								<CardMedia image={content.image} className={classes.cardMedia} />
								<div className={classes.cardContentDetail}>

									{content.text.split("\n").map((text, index) => {
										return <Typography variant="body1" key={index}>{text}<br /></Typography>
									})}

									<div className={classes.linkContainer}>
										<NavLink to={content.link} className={classes.link}>
											{content.linkText}<ArrowForward color={"primary"} className={classes.arrow} />
										</NavLink>
									</div>

									<div className={classes.buttonContainer}>
										<Button
											variant="outlined"
											color="primary"
											disabled={activeStep === 0}
											onClick={() => handleSetStep(step.type, activeStep - 1)}
											className={classes.button}
										>
											戻る
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={() => handleSetStep(step.type, activeStep + 1)}
											className={classes.button}
										>
											{activeStep === step.contents.length - 1 ? "終了" : "次のStepへ"}
										</Button>
									</div>
								</div>
							</CardContent>
						</React.Fragment>
					)
				})
			}
		</Card>
	);
};

export default StepContent;

const useStyles = makeStyles({
	card: {
		maxWidth: 950,
		margin: "0 auto 30px",
	},
	reset: {
		textAlign: "center",
		minHeight: 306,
	},
	cardContent: {
		display: "flex",
		minHeight: 250,
	},
	cardMedia: {
		width: "35%",
		marginRight: 45,
		backgroundSize: "contain",
	},
	cardContentDetail: {
		width: "calc(65% - 45px)",
		position: "relative",
	},
	linkContainer: {
		fontSize: 14,
		textAlign: "right",
		marginTop: 10,
	},
	link: {
		"&:hover": {
			textDecoration: "underline",
		}
	},
	arrow: {
		fontSize: 25,
		verticalAlign: "bottom",
		marginLeft: 10,
	},
	buttonContainer: {
		position: "absolute",
		top: "75%",
	},
	button: {
		margin: "15px 10px 0 0",
	},
});
