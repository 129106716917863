import React from "react";
import { useSelector } from "react-redux";
import { createReleasedAt, createPublicPagePressKit } from "../../../../../util/createUrl";
import { generatePressKitList } from "../../../../../util/reserve";
import classNames from 'classnames';

// component
import { makeStyles, Typography } from "@material-ui/core/";

// img
import LinkIcon from "../../../../../assets/link_icon.svg";

import { RootState } from "../../../../../reducers";
import { PressKitFirestoreModel } from "../../../../../model/PressKitModel";

const pressReleaseIdSelector = (state: RootState): string => state.pressRelease.detail.press_id;
const pressKitIdSelector = (state: RootState): Array<string> => state.pressReleaseReserve.data.press_kit_id;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;

const useStyles = makeStyles({
	root: {
	},
	url: {
		marginTop: 5,
		marginRight: 10,
		borderRadius: 4,
		display: "flex",
		cursor: "pointer"
	},
	icon: {
		marginRight: 10
	},
	pressKitUrlListContainer: {
		display: "flex",
		flexDirection: "column",
		wordBreak: "break-all",
		marginTop: 5,
		padding: 10
	},
	pressKit: {
		wordBreak: "break-all",
		marginTop: 5,
		display: "flex",
		cursor: "pointer"
	}
});


interface Props {
	date: Date,
	prefix: string
}
const MailText: React.FC<Props> = ({ date, prefix }) => {
	const classes = useStyles();
	const pressKitListInFirestoreState = useSelector(firestorePressKitsSelector);
	const attachedPressKitIdList = useSelector(pressKitIdSelector);
	const pressKitInfoList = generatePressKitList(attachedPressKitIdList, pressKitListInFirestoreState);
	const press_id = useSelector(pressReleaseIdSelector);

	const handleClick = (url: string) => {
		window.open(url, "_blank");
	};

	return (
		<div className={classes.root}>
			{attachedPressKitIdList.length > 0 &&
				<ul className={classes.pressKitUrlListContainer}>
					<Typography>素材は下記のリンクからダウンロードしてください。</Typography>
					{pressKitInfoList.map((pressKit, index) => {
						const pressKitUrl = createPublicPagePressKit(pressKit.id, prefix);
						return (
							<div key={index}>
								<Typography variant="body1" display="block">{pressKit.title}</Typography>
								<div key={index}>
									<Typography
										display="block"
										className={classes.pressKit}
										variant="body1"
										onClick={() => handleClick(pressKitUrl)}
									>
										<img src={LinkIcon} className={classes.icon} />{pressKitUrl}
									</Typography>
								</div>
							</div>
						)
					})}
				</ul>
			}
		</div>
	);
};
export default MailText;
