import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as ActionCreators from "../../../../actions/PressReleaseReserve/ActionCreator";

//component
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
  Button,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { RootState } from "../../../../reducers";

import style from "./attachPressKitDialog.module.scss";
import { PressKitFirestoreModel } from "../../../../model/PressKitModel";
import { NavLink } from "react-router-dom";

interface AttachPressKitDialogProps {
  isOpen: boolean;
  closeHandle: any;
};

const useStyles = makeStyles({
  hasNoPressKit: {
    color: "#6d7470",
    fontWeight: "bold"
  },
  link: {
    color: "#2EB964",
    textDecoration: "underline"
  },
})

const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;
const pressKitIdSelector = (state: RootState) => state.pressReleaseReserve.data.press_kit_id;

export const AttachPressKitDialog: React.FC<AttachPressKitDialogProps> = ({ isOpen, closeHandle }) => {
  const pressKitIdInState = useSelector(pressKitIdSelector);
  const pressKitListInFirestoreState = useSelector(firestorePressKitsSelector);
  const [selectedPressKit, setSelectedPressKit] = useState<Array<string>>(pressKitIdInState);
  const dispatch = useDispatch();
  const hasPressKit = pressKitListInFirestoreState.length > 0 ? true : false;
  const classes = useStyles();

  const handleSetSelectedPressKit = (pressKitId: string) => {
    const currentSelectedPressKit = selectedPressKit;
    if (currentSelectedPressKit.includes(pressKitId)) {
      const modifiedList = currentSelectedPressKit.filter(id => id != pressKitId);
      setSelectedPressKit(modifiedList);
      return;
    }
    setSelectedPressKit([...selectedPressKit, pressKitId]);
  };

  const handleButtonClick = () => {
    dispatch(ActionCreators.setAttachedPressKitId(selectedPressKit))
    closeHandle();
  };

  const removeIfIncludesDeletedId = (pressKitIdList: Array<string>) => {
    const idListInStateOfFirestore = pressKitListInFirestoreState.map(pressKit => pressKit.id);
    const idListRemovedDeletedId: Array<string> = [];
    pressKitIdList.forEach(pressKitId => {
      if (idListInStateOfFirestore.includes(pressKitId)) {
        idListRemovedDeletedId.push(pressKitId)
      }
    })
    if (idListRemovedDeletedId.length) dispatch(ActionCreators.setAttachedPressKitId(idListRemovedDeletedId))
  };

  useEffect(() => {
    removeIfIncludesDeletedId(pressKitIdInState);
  }, [isOpen])

  useEffect(() => {
    setSelectedPressKit(pressKitIdInState);
  }, [pressKitIdInState]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandle}
    >
      <DialogTitle>
        メールに添付するプレスキットを選択してください
      </DialogTitle>
      <DialogContent className={style.content}>
        <section className={style.item}>
          {hasPressKit ? (
            <ul className={style.form}>
              {pressKitListInFirestoreState.map((pressKit, index) => {
                return <li key={index} className={style.chip}>
                  {selectedPressKit.indexOf(pressKit.id) >= 0 ?
                    <Chip
                      color="primary"
                      label={pressKit.title}
                      onClick={() => handleSetSelectedPressKit(pressKit.id)}
                    />
                    :
                    <Chip
                      label={pressKit.title}
                      onClick={() => handleSetSelectedPressKit(pressKit.id)}
                    />
                  }
                </li>
              })}
            </ul>
          ) : (
              <div className={style.hasNoPressKitContainer}>
                <Typography className={classes.hasNoPressKit}>
                  プレスキットを作成してください<br />
                  <NavLink target="_blank" to="/help/press_kit#1">
                    <Typography className={classes.link}>プレスキットとは？</Typography>
                  </NavLink>
                </Typography>
              </div>
            )}
          <div className={style.buttonContainer}>
            <Button
              className={style.button}
              onClick={closeHandle}
              variant='outlined'
              size='large'
            >
              キャンセル
            </Button>
            <Button
              className={style.button}
              onClick={handleButtonClick}
              variant='contained'
              color='primary'
              size='large'
              disabled={!hasPressKit}
            >
              設定する
            </Button>
          </div>
        </section>
      </DialogContent>
    </Dialog>
  )
};
