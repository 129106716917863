import React from 'react';
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const PageTitle = ({title}) => {

	const classes = useStyles();

	return (
		<>
			<Typography variant="h1"gutterBottom className={classes.title}>
				{title}
			</Typography>
		</>
	);
};

export default PageTitle;

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			padding: 10,
			backgroundColor: "#e2e9e5",
			borderLeft: "3px solid #2eb964"
		},
	}),
);
