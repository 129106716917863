import React from 'react';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";

import TermFilter from "../../Common/TermFilter"
import PressFilter from "../../Common/PressFilter"

import CalendarImage from "../../../../assets/icon_calendar_green.svg";
import * as AnalyticsActionCreators from "../../../../actions/Analytics/ActionCreator";

import { SUMMARY, DETAIL } from "../../../../config/analytics_config";

const PressReleaseSelector = (state: RootState) => state.pressRelease;
const AnalyticsSelector = (state: RootState) => state.analytics;



const useStyles = makeStyles((Theme) =>
    createStyles({
        filter: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px"
        },
        icon: {
            width: "20px",
            margin: "15px 10px 10px 10px",
        },
    }
    )
)

interface Props {
    page: string
}


const HeaderMenu: React.FC<Props> = ({ page }) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const pressRelease = useSelector(PressReleaseSelector);
    const analytics = useSelector(AnalyticsSelector);


    const handleFilterByTerm = (from: Date, to: Date) => {
        dispatch(AnalyticsActionCreators.getAnalyticsSummary.request({ from, to }))
    }

    const createActions = () => {
        let act: JSX.Element = <></>;
        switch (page) {
            case SUMMARY:
                act = <>
                    <div className={classes.filter}>
                        <PressFilter contents={pressRelease.sendList.result} value={""} />
                        <div>
                            <img className={classes.icon} src={CalendarImage} />
                            <TermFilter handleChange={handleFilterByTerm} />
                        </div>
                    </div>
                </>
                break

            case DETAIL:
                act = <>
                    <div className={classes.filter}>
                        <div>
                            <PressFilter contents={pressRelease.sendList.result} value={analytics.detail.press_id} />
                        </div>
                    </div>
                </>
                break
        }
        return act;
    }

    return createActions()
}

export default HeaderMenu;
