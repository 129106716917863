import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/editor/ai/image01.png';
import Point from "../Point";
import PageTitle from "../PageTitle";


const Block = () => {
	return (
		<div className={style.box}>
			<PageTitle title={"エディタ"}/>
			<Title title={"1. AI校閲"}/>
			<Typography gutterBottom variant={"body1"}>
				エディタ画面の右側メニューから「AI 校閲」を選択し、
				AIの校閲内容を確認できます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
		</div>
	);
};

export default Block
