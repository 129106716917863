import React from 'react';
import { useDispatch } from "react-redux";

//model
import { RadarResultContentModel } from "../../../model/RadarModel";

//material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
    List,
    ListItem,
    Typography,
    Divider,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

//action
import * as ActionCreators from "../../../actions/Radar/ActionCreator";

//style
import style from "../radar.module.scss";

interface Props {
    item: RadarResultContentModel
}


export const RadarListItem: React.FC<Props> = ({ item }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleDeleteClick = () => {
        dispatch(ActionCreators.deleteRadarResult.request({ id: item.id }))
    }

    return (
        <li className={style.listContent}>
            <List>
                <ListItem>
                    <a
                        className={classes.link}
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Typography className={classes.title}>{item.title}</Typography>
                        <Typography className={classes.source} variant="h6">{item.media}</Typography>
                    </a>
                    <ListItemSecondaryAction>
                        <IconButton className={style.deleteButton} onClick={handleDeleteClick}>
                            <DeleteIcon className={style.deleteIcon} />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </li>
    )
}

const useStyles = makeStyles({
    link: {
        width: "100%",
        display: "block"
    },
    title: {
        fontSize: 13,
        minWidth: 150,
        marginTop: "auto",
        marginBottom: "auto"
    },
    source: {
        textAlign: "right",
        fontSize: 12,
        marginRight: 20
    }
})
