import fetch from 'isomorphic-fetch';
import {history} from '../configureStore';
import store from 'store'
import {API_URL} from '../config'
import { generateUploadUrl, generateListUrl } from '../config/gcs_config'


export function fetchGet(apiName) {

	var date = new Date() ;
	var a = date.getTime() ;
	var b = Math.floor( a / 1000 ) ;

	const last = apiName.endsWith('/')
	const param = last? '?': '&';
	// paragraph.indexOf(searchTerm);
	return fetch(`${API_URL}${apiName}${param}b=${b}`, {
		method: "GET",
		cache:"no-cache",
		headers: {
			"Content-Type": "application/json",
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}


export function fetchPost(apiName, payload) {
	return fetch(`${API_URL}${apiName}/`, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}


export function fetchPost2(apiName, payload) {
	return fetch(`${API_URL}${apiName}/`, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}

export function fetchPostForm(apiName, payload) {
    return fetch(`${API_URL}${apiName}/`, {
        method: "POST",
        body: payload,
        headers: {
			"Authorization": store.get('token') && `Token ${store.get('token')}`
        },
    })
        .then(response => {
            if (response.status === 204) {
                return {status: response.status,json: 'json'}
            } else {
                return response.json().then(json => ({
                        status: response.status,
                        json: json,
                    })
                )
            }
        })
        .then(({status, json}) => {
            return responseHandler(status, json)
        })
        .catch(()=>{
                history.push('/sorry')
            }
        )
}

export function fetchPatchForm(apiName, payload) {

	const formData = new FormData();
	formData.append('img', payload.img);

	return fetch(`${API_URL}${apiName}/`, {
		method: "PATCH",
		body: formData,
		headers: {
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}


export function fetchPatch(apiName, payload) {
	return fetch(`${API_URL}${apiName}/`, {
		method: "PATCH",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}


export function fetchPut(apiName, payload) {
	return fetch(`${API_URL}${apiName}/`, {
		method: "PUT",
		body: JSON.stringify(payload || ""),
		headers: {
			"Content-Type": "application/json",
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}


export function fetchDelete(apiName, payload?) {
	return fetch(`${API_URL}${apiName}`, {
		method: "DELETE",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json",
			"Authorization": store.get('token') && `Token ${store.get('token')}`
		},
	})
		.then(response => {
			if (response.status === 204) {
				return {status: response.status,json: 'json'}
			} else {
				return response.json().then(json => ({
						status: response.status,
						json: json,
					})
				)
			}
		})
		.then(({status, json}) => {
			return responseHandler(status, json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}


export function getJson(apiName) {
	return fetch(`${apiName}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
		})
		.then(response => {
			return response.json().then(json => json)
		})
		.catch(()=>{
				history.push('/sorry')
			}
		)
}



// TODO statusで処理分けるのか確認
const responseHandler = (status, json) => {
	if (status < 300) {
		return [json, undefined]
	} else {
		if (status === 400) {
			return [undefined, json]
		} else if ( status === 401 || status === 403 ) {
			const path = store.get('path');
			const { is_free_plan_expired, is_over_word, is_over_member } = json;

			if (status === 403 && (is_free_plan_expired || is_over_word || is_over_member)) {
				// トライアル期間終了もしくは、オプション枠超過時の処理
				return [undefined, json]
			} else {
				if (path && path.indexOf('briefing') >= 0 && path.indexOf('answer') >= 0) {
					const docId = path.split('/')[4];
					const search = path.indexOf("?i=");
					if (search >= 0) {
						history.push(`/form/${docId}?i=${path.substring(search + 3)}`);
					} else {
						history.push(`/form/${docId}`);
					}
					store.remove('path');
				} else {
					history.push('/sign_in');
				}
			}
		} else {
			//
			throw new Error('エラーが発生しました。');
		}
	}
}

const generateFileName = (fileName: string) => {
  const splitArr = fileName.split('.')
  if (splitArr.length < 2) {
    return fileName
  }
  const fileExtension = splitArr.pop()
  const name = splitArr.join('.')
  const timeStamp = new Date().getTime()
  
  return `${name}_${timeStamp}.${fileExtension}`
}

export function fetchUploadToGCS(file: File, folders: string[]) {
	const fileName = generateFileName(file.name)
	const URL = generateUploadUrl(folders, fileName)
	return fetch(URL, {
		method: "POST",
		body: file,
	})
	.then(response => {
		if (response.status == 204) {
			return { status: response.status, json: 'json' }
		} else {
			return response.json().then(json => ({
				status: response.status,
				json: json
			}))
		}
	})
	.then(({ status, json }) => {
		return responseHandler(status, json)
	})
	.catch(() => {
		history.push('./sorry')
	})
}
