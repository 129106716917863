import React, {useEffect, useState} from "react";
//state
import HtmlDiff from 'htmldiff-js';
//component
import EditorComponent from "./Editor";
import {makeStyles, Typography, Button, Box, Tabs, Tab} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers";
import Preview from "../../Common/Editor/Preview";
import {PressReleaseBodyModel} from "../../../model/PressReleaseModel";

const pressReleaseSelector = (state: RootState) => state.pressRelease;

const createDiff = (before, after) => {
    return HtmlDiff.execute(before, after)
};

const Editor: React.FC = () => {

    const classes = useStyles();
    const pressRelease = useSelector(pressReleaseSelector);
    const [diff, setDiff] = useState();
    const [diffList, setDiffList] = useState([]);
    const [comp, setComp] = useState<any>(null);
    const {detail} = pressRelease;
    const {component, style} = detail.body;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (pressRelease.diff.fetched === true && pressRelease.detail.fetched === true) {
            const html = createDiff(pressRelease.detail.body.html, pressRelease.diff.body.html)
            setDiff(html)
            setTimeout(function () {
                setComp(1)
            }, 200)

        }
    }, [pressRelease.diff.fetched, pressRelease.detail.fetched]);


    useEffect(() => {
        const wrap: HTMLElement | null = document.getElementById("diffHtml");
        if (wrap) {
            const list = wrap.querySelectorAll('del.diffmod, .diffdel, .diffins, ins.diffmod');
            const diffHtml: any = []
            list.forEach(
                function (currentValue, currentIndex, listObj) {
                    diffHtml.push(currentValue)
                }
            );
            setDiffList(diffHtml)
        }

    }, [comp]);

    const {first_name, last_name} = pressRelease.diff.user;

    // const diffBody = {
    //     component: [],
    //     html: HTMLElement | string,
    //     style: any[],
    //     css: string
    // }

    return (
        <article className={classes.root}>
            <Box className={classes.tabs}>
                <Tabs
                    className={classes.tab}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab label={`${last_name}${first_name}さん`} {...a11yProps(0)} />
                    <Tab label={`編集中の内容`} {...a11yProps(1)} />
                    <Tab label={`差分`} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <div className={classes.tabPanels}>
                <TabPanel value={value} index={0}>
                    <Preview body={pressRelease.diff.body}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EditorComponent html={pressRelease.detail.body.html}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Preview body={{
                        component: [],
                        html: diff,
                        style: [],
                        css: ""
                    }}/>
                    {/*<div id="diffHtml" className={classes.diffHtml} dangerouslySetInnerHTML={{__html: diff}}></div>*/}
                    {/*<div className={classes.diff}>*/}
                    {/*    <Typography className={classes.diffText}><span className={classes.ins}></span>：追加<span*/}
                    {/*        className={classes.del}></span>：削除</Typography>*/}
                    {/*    <ul className={classes.diffList}>*/}
                    {/*        {diffList.map((item) => {*/}
                    {/*            return (*/}
                    {/*                <li className={classes.diffItem}>*/}
                    {/*                    <div dangerouslySetInnerHTML={{__html: item.parentNode.innerHTML}}></div>*/}
                    {/*                    /!*<Button variant="outlined" size="small" color="primary">*!/*/}
                    {/*                    /!*    反映*!/*/}
                    {/*                    /!*</Button>*!/*/}
                    {/*                </li>*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </TabPanel>
            </div>
        </article>
    );
};


export default Editor;


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}


const useStyles = makeStyles({
    root: {
        flex: 1
    },
    tabs: {
        position: "fixed",
        width: "100%",
        height: 60,
        zIndex: 2,
        backgroundColor: "#f3f7f4"
    },
    tab: {
        position: "fixed",
        top: 100,
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#f3f7f4",
        border: "none"
    },
    tabPanels: {
        marginTop: 60
    },
    diffHtml: {
        position: "absolute",
        overflow: "hidden",
        height: 0
    },
    diffItem: {
        marginTop: 10,
        backgroundColor: "#fff",
        padding: 10,
        borderLeft: "3px solid #ddd",
        marginBottom: 10
    },
    diff: {
        width: 300,
    },
    diffList: {
        overflow: "scroll",
        height: "calc( 100vh - 64px)",
        transform: "scale(0.9, 0.9)"
    },
    diffText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10
    },
    ins: {
        display: "block",
        backgroundColor: "#ff00009c",
        width: 20,
        height: 10,
        margin: 5
    },
    del: {
        display: "block",
        backgroundColor: "#ddd",
        width: 20,
        height: 10,
        margin: 5
    }
});
