import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {isIE} from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import {
    PREVIEW_TYPE_DESKTOP,
    PREVIEW_TYPE_MOBILE,
    PREVIEW_TYPE_PDF,
} from "../../../config/preview_type";

// component
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Preview from "../Editor/Preview";
import PreviewRadios from "./PreviewRadios";

//img
import IconClose from '../../../assets/icon_close_gray.svg'

//action
import * as PressReleaseActionCreators from "../../../actions/PressRelease/ActionCreator";
import * as PreviewActionCreators from "../../../actions/Preview/ActionCreator";

//state
import { RootState } from "../../../reducers";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressKitSelector = (state: RootState) => state.pressKit;
const routerSelector = (state: RootState) => state.router;
const previewSelector = (state: RootState) => state.preview;

interface PreviewDialogProps {
    isRevew? :boolean,
    isHistory? :boolean,
    isOpen: boolean,
    closeHandle,
    handleAction?,
    handleActionText?: String,
    defaultLayout?: string,
    page?: string,
}

const useStyles = makeStyles({
    root: {
        position: "relative",
        width: 926,
        height: 530,
        padding: 0
    },
    mobile: {
        width: 420,
        height: 600,
        padding: 0
    },
    close: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
    title: {
        padding: '0',
        background: '#F3F7F4',
        display: "flex",
        justifyContent: "center",
    },
    action: {
        borderTop: '1px solid #E2E9E5'
    },
    pdf: {
        width: '100%',
        height: '100%'
    },
    note: {
        background: "#2eb964",
        color: "#fff",
        fontSize: 14,
        padding: 10,
        bottom: 50,
        position: "absolute",
        width: "100%",
        textAlign: "center"
    },
    pdfProgress: {
        color: "#4caf50",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -20,
        marginLeft: -35,
    }
});


const PreviewDialog: React.FC<PreviewDialogProps> = ({ isHistory = false, isOpen, closeHandle, handleAction, handleActionText, page, defaultLayout = PREVIEW_TYPE_DESKTOP }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const pressKit = useSelector(pressKitSelector);

    const preview = useSelector(previewSelector);
    const router = useSelector(routerSelector);
    const isPressRelease = router.location.pathname.indexOf('press_release') > -1;

    const [PDFLoading, setPDFLoading] = React.useState<boolean>(false);
    let timer: any;

    let documents: any = document.querySelectorAll('.froala_editor');
    let target: any;
    let previewClassList: any;

    let html:any = ""
    if (isPressRelease) {
        const history = pressRelease.history
        html = isHistory ? history.body: pressRelease.detail.body
    } else {
        html = pressKit.detail.body
    }

    useEffect(() => {
        if (isOpen === true && !isHistory) {
            dispatch(PreviewActionCreators.setPreviewType.request(defaultLayout));
            if (pressRelease.detail.status < 3) {
                dispatch(PressReleaseActionCreators.postPressReleasePDF.request({isDownload: false}));
            }
        }

        if (!isOpen) {
            dispatch(PreviewActionCreators.setPreviewType.request(PREVIEW_TYPE_DESKTOP));
        }
    }, [isOpen, isHistory]);

   useEffect(() => {
        if (documents.length > 0) {
            if (page === 'PAGE_EDIT' || page === 'PAGE_REVIEW' || page === 'PAGE_OGP') {
                target = documents[0];
            } else if (page === 'PAGE_DETAIL' && documents.length == 2) {
                target = documents[1];
            }

            if (target) {
                previewClassList = target.classList;
            }

            if (previewClassList) {
                if (preview === "PREVIEW_TYPE_DESKTOP") {
                    if (previewClassList.contains('preview-type-mobile')) {
                        previewClassList.remove('preview-type-mobile');
                    }
                    if (!previewClassList.contains('preview-type-desktop')) {
                        previewClassList.add('preview-type-desktop');
                    }
                } else if (preview === "PREVIEW_TYPE_MOBILE") {
                    if (previewClassList.contains('preview-type-desktop')) {
                        previewClassList.remove('preview-type-desktop');
                    }
                    if (!previewClassList.contains('preview-type-mobile')) {
                        previewClassList.add('preview-type-mobile');
                    }
                }
            }
        }
    }, [documents]);

    //ローダーのタイマー
    useEffect(() => {
        clearTimeout(timer);

        if (preview === "PREVIEW_TYPE_PDF") {
            if ((pressRelease.detail.status < 3 && pressRelease.PDF.url) || pressRelease.detail.status > 2) {
                setPDFLoading(true)
                timer = setTimeout(() => {
                    setPDFLoading(false);
                }, 1500);
            }
        }
    }, [preview]);

    const contentClass =  preview === PREVIEW_TYPE_MOBILE ? classes.mobile : classes.root;

    return (
        <Dialog
            open={isOpen}
        >
            <DialogTitle className={classes.title}>
                <PreviewRadios isHistory={isHistory} page={page}/>
                <IconButton aria-label="close" onClick={()=>closeHandle()} className={classes.close}>
                    <img src={IconClose}/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={contentClass}>
                <Content preview={preview} html={html} pressRelease={pressRelease}/>
                {//pdf生成時に出すローダー
                    (preview === "PREVIEW_TYPE_PDF" && pressRelease.PDF.loading) && <CircularProgress size={80} className={classes.pdfProgress} />
                }
                {//pdf生成済みの時に出すローダー
                    PDFLoading && <CircularProgress size={80} className={classes.pdfProgress} />
                }
            </DialogContent>
            <DialogActions className={classes.action}>
                <Button variant="outlined" size="large" color="primary" onClick={closeHandle}>
                    閉じる
                </Button>
                {handleAction  && handleActionText &&
                <Button variant="contained" size="large" color="primary" onClick={()=>handleAction()}>
                    {handleActionText}
                </Button>}
            </DialogActions>
        </Dialog>
    );
};

export default PreviewDialog

const Content = ({preview, pressRelease, html}) => {

    const classes = useStyles();
    const random = Math.random();
    const [content, setContent] = React.useState<JSX.Element>(<></>);

    useEffect(() => {
        if (preview ===  PREVIEW_TYPE_PDF) {
            // 承認前はPDFを生成する
            const url: string = pressRelease.detail.status < 3 ? pressRelease.PDF.url : pressRelease.detail.pdf;
            const pdfContent = url ? <iframe src={`${url}?r=${random}`} className={classes.pdf}/> : <div/>;
            setContent(pdfContent);
        } else {
            setContent(<Preview body={html}/>);
        }
    }, [preview, html, pressRelease.detail, pressRelease.PDF]);

    return content
};
