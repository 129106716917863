import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, makeStyles, Typography } from "@material-ui/core";

import IconClose from '../../../../assets/icon_close_gray.svg'
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

interface Props {
    isOpen: boolean,
    closeHandle
}

const RegisterCardDialog: React.FC<Props> = ({ isOpen, closeHandle }) => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(push('/setting/payment'))
    }

    return (
        <Dialog
            open={isOpen}>
            <DialogTitle>
                カード登録
                <IconButton aria-label="close" onClick={() => closeHandle()} className={classes.close}>
                    <img src={IconClose} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <div className={classes.content}>
                    <Typography variant="body1" gutterBottom>クレジットカードが登録されていません</Typography>
                    <Button variant="contained" size="large" color="primary" onClick={handleClick}>
                        カードを登録する
                </Button>
                </div>

            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" color="primary" onClick={closeHandle}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default RegisterCardDialog;

const useStyles = makeStyles({
    root: {
        width: "42.4rem",
        height: "30rem",
        background: "#F3F7F4",
        textAlign: "center"
    },
    close: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
    },
    content: {
        width: "270px",
        height: "148px",
        margin: "auto",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
})
