import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// components
import { makeStyles } from "@material-ui/core/styles";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Tab, Tabs, Typography
} from '@material-ui/core';
import NonMember from './NonMember';
import MemberSelect from './MemberSelect';

// actions
import * as BriefingActionCreators from '../../../../actions/Briefing/ActionCreator';

// config
import { BRIEFING_PAGE_TYPES } from '../../../../config/briefing';
import { FREE, SPOT } from '../../../../config/payment_config';

// state
import { RootState } from '../../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;
const companySelector = (state: RootState) => state.company;

interface DialogProps {
    isOpen: boolean,
    title: string,
    handleClose: () => void,
    type: "send" | "share",
}

const SendMailDialog: React.FC<DialogProps> = ({ isOpen, handleClose, title, type }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const briefing = useSelector(briefingSelector);
    const company = useSelector(companySelector);
    const { briefing_mail_limit: mail_limit } = company.plan;
    const { mail_count, last_send, fetched: qtyFetched } = briefing.qty;
    const [selected, setSelected] = useState<string[]>([]);
    const [nonMembers, setNonMembers] = useState<string[]>([]);
    const [tab, setTab] = useState<number>(0);
    const [error, setError] = useState("");

    const handlePostSendMail = () => {
        const handleSuccess = () => {
            handleClose();
            setSelected([]);
            setNonMembers([]);
        }
        const handleError = (text: string) => {
            setError(text);
        }
        dispatch(BriefingActionCreators.postBriefingSendMail.request({
            members: tab == 0 ? selected : tab == 1 ? nonMembers : [],
            title: title,
            postSuccess: handleSuccess,
            postError: handleError,
            pageType: BRIEFING_PAGE_TYPES.EDIT,
        }));
    }

    const handleChange = (email: string, checked: boolean) => {
        const newSelected: string[] = [...selected];
        if (checked) {
            setSelected(newSelected.filter(n => n !== email));
        } else {
            newSelected.push(email);
            setSelected(newSelected);
        }
    }

    useEffect(() => {
        if (tab != 0) setSelected([]);
        if (tab != 1) setNonMembers([]);
        setError("");
    }, [tab])

    const handleNonMemberChange = (emailOrIndex: string | number, checked: boolean) => {
        const newSelected: string[] = [...nonMembers];
        if (checked && typeof emailOrIndex === 'number') {
            newSelected.splice(emailOrIndex, 1);
            setNonMembers(newSelected);
        } else if (typeof emailOrIndex === 'string') {
            newSelected.push(emailOrIndex);
            setNonMembers(newSelected);
        }
    }

    const todayMonth = dayjs(Date.now()).format("MM");
    const todayYear = dayjs(Date.now()).format("YY");
    const lastSendMonth = last_send ? dayjs(last_send).format("MM") : "";
    const lastSendYear = last_send ? dayjs(last_send).format("YY") : "";

    const isThisMonthLastSend = todayMonth == lastSendMonth && todayYear == lastSendYear;
    const isReachedMailLimit = mail_count >= mail_limit && isThisMonthLastSend;
    const isExistsLimit = [FREE, SPOT].includes(company.plan.code);
    const isSendButtonDisabled = (tab == 0 && selected.length == 0) || (tab == 1 && nonMembers.length == 0);

    return (
        <Dialog open={isOpen} onClose={() => {
            handleClose();
            setError("");
        }}>
            <DialogTitle>
                {type == "send" ? "メールを送信して回答を依頼" : "回答の共同編集を依頼"}
            </DialogTitle>
            <DialogContent className={classes.content}>
                {(qtyFetched && last_send && isExistsLimit) &&
                    <>
                        {isReachedMailLimit ?
                            <Typography color="error">
                                メール送信数の上限に達しているため送信できません。
                            </Typography>
                            :
                            <div className={classes.mailCounts}>
                                <MailCountMessage count={isThisMonthLastSend ? mail_limit - mail_count : mail_limit} />
                            </div>}
                    </>}
                <Tabs value={tab} textColor='primary' centered
                    onChange={(e, newValue) => setTab(newValue)}>
                    <Tab label='グループメンバー' />
                    <Tab label='メールアドレス' />
                </Tabs>
                <div className={classes.tabContent}>
                    {tab === 0 && <MemberSelect selected={selected} handleChange={handleChange} />}
                    {tab === 1 && <NonMember selected={nonMembers} handleChange={handleNonMemberChange} />}
                </div>
                <Typography className={classes.error} color="error">
                    {error}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' onClick={handleClose}>
                    閉じる
                </Button>
                <Button variant='contained' color='primary'
                    disabled={isSendButtonDisabled || (qtyFetched && last_send && isExistsLimit && isReachedMailLimit)}
                    onClick={handlePostSendMail}>
                    送信
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SendMailDialog;

const useStyles = makeStyles({
    content: {
        width: 700,
        minHeight: 150,
    },
    tabContent: {
        padding: '10px 0',
    },
    error: {
        fontSize: 13,
    },
    upgrade: {
        textDecoration: 'underline',
    },
    mailCounts: {
        padding: '5px 0',
    },
})


interface MailCountProps {
    count: number,
}

const MailCountMessage: React.FC<MailCountProps> = ({ count }) => (
    <Typography>
        今月はあと{count > 0 ? count : 0}件のメールを送信できます。
    </Typography>
);
