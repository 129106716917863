import {createAsyncAction} from "typesafe-actions";
import ActionType from "./ActionType";
import {PaymentModel, PlanRequestModel,OptionRequestModel, OptionDeleteModel, TicketRequestModel} from "../../model/PaymentModel";

export const getCardEditURL = createAsyncAction(
    ActionType.GET_CARD_EDIT_URL_REQUEST,
    ActionType.GET_CARD_EDIT_URL_SUCCEEDED,
    ActionType.GET_CARD_EDIT_URL_FAILED
)<void, PaymentModel, Error>();


export const getCardInfo = createAsyncAction(
    ActionType.GET_CARD_INFO_REQUEST,
    ActionType.GET_CARD_INFO_SUCCEEDED,
    ActionType.GET_CARD_INFO_FAILED
)<void, PaymentModel, Error>();

export const postPlan = createAsyncAction(
    ActionType.POST_PLAN_REQUEST,
    ActionType.POST_PLAN_SUCCEEDED,
    ActionType.POST_PLAN_FAILED
)<PlanRequestModel, PlanRequestModel, Error>();

export const patchPlan = createAsyncAction(
    ActionType.PATCH_PLAN_REQUEST,
    ActionType.PATCH_PLAN_SUCCEEDED,
    ActionType.PATCH_PLAN_FAILED
)<PlanRequestModel, void, Error>();

export const deletePlan = createAsyncAction(
    ActionType.DELETE_PLAN_REQUEST,
    ActionType.DELETE_PLAN_SUCCEEDED,
    ActionType.DELETE_PLAN_FAILED
)<void, void ,Error>();

export const postTicketPayment = createAsyncAction(
    ActionType.POST_TICKET_PAYMENT_REQUEST,
    ActionType.POST_TICKET_PAYMENT_SUCCEEDED,
    ActionType.POST_TICKET_PAYMENT_FAILED
)<TicketRequestModel,void,Error>();

export const postOption = createAsyncAction(
    ActionType.POST_OPTION_REQUEST,
    ActionType.POST_OPTION_SUCCEEDED,
    ActionType.POST_OPTION_FAILED

)<OptionRequestModel, PaymentModel, Error>();

export const patchOption = createAsyncAction(
    ActionType.PATCH_OPTION_REQUEST,
    ActionType.PATCH_OPTION_SUCCEEDED,
    ActionType.PATCH_OPTION_FAILED
)<OptionRequestModel,PaymentModel,Error>();

export const deleteOption = createAsyncAction(
    ActionType.DELETE_OPTION_REQUEST,
    ActionType.DELETE_OPTION_SUCCEEDED,
    ActionType.DELETE_OPTION_FAILED
)<OptionDeleteModel,PaymentModel,Error>();
