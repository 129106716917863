import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import { Field, reduxForm, InjectedFormProps } from "redux-form";

//material-ui
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//component
import TextField from "../Field/TextField";

//model
import {RootState} from "../../reducers";
import { Values, Errors } from "../../model/FormModel";

//style
import style from "./form.module.scss";


const radarWordSelector = (state: RootState) => state.radar.words;

const validate = (values: Values) => {
  const errors: Errors = {}
  const requireFields = [
    'radarWord',
  ]
  requireFields.forEach(field => {
    if (!values[field]) {
      errors[field] = '検索ワードを入力してください'
    }
  })
  return errors
}

const AddRadarWordForm: React.FC<InjectedFormProps> = ({handleSubmit, submitting, pristine, error, reset}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const radarWords = useSelector(radarWordSelector);

    useEffect(() => {
        dispatch(reset('RADAR_WORD'))
    }, [radarWords])

    return (
        <form onSubmit={handleSubmit}>
            {error && <p>{error}</p>}
            <div className={style.rootAddPressKit}>
                <Typography className={classes.notice}>※ 検索ワードおよび除外ワードはスペース区切りで入力してください</Typography>
                <div className={classes.formContainer}>
                    <Field name="radarWord" type="text" component={TextField} label="検索ワードを入力してください"/>
                </div>
                <div className={classes.formContainer}>
                    <Field name="excludeWord" type="text" component={TextField} label="除外する単語を入力してください"/>
                </div>
                <Button variant="contained" size="medium" type="submit" color="primary"
                        disabled={pristine || submitting}>
                    登録
                </Button>
            </div>
        </form>
    )
}

export default reduxForm({
  validate,
  form: "RADAR_WORD",
})(AddRadarWordForm)

const useStyles = makeStyles({
    formContainer: {
        marginTop: 10
    },
    notice: {
        fontSize: 11
    }
})
