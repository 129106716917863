import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";

//component
import {Bar} from 'react-chartjs-2';

//action
import * as ActionCreators from '../../../actions/Radar/ActionCreator';

//model
import {RootState} from "../../../reducers";

//style
import style from "../radar.module.scss";

import {selectDisplayItems, makeDates, makeCurrentMonthDate} from "../../../util/radar";

const radarDisplayItemsSelector = (state: RootState) => state.radar.displayItems;
const radarDisplayModeSelector = (state: RootState) => state.radar.displayMode;
const radarTargetIndexSelector = (state: RootState) => state.radar.targetIndex;
const radarTargetWordIdSelector = (state: RootState) => state.radar.targetWordId;
const radarSelectedSelector = (state: RootState) => state.radar.selected;

const options = {
    scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                userCallback: (label, index, labels) => {
                    if (Math.floor(label) === label) {
                        return label;
                    }
                },
            }
        }],
    },
}

interface Props {}


export const Chart: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const {start, end} = useSelector(radarSelectedSelector);
    const label_data = !!start && !!end
        ? makeDates(start, end)
        : makeDates(makeCurrentMonthDate('start'), makeCurrentMonthDate('end'))
    const mode = useSelector(radarDisplayModeSelector);
    const targetIndex = useSelector(radarTargetIndexSelector);
    const targetWordId = useSelector(radarTargetWordIdSelector);
    const displayItems = useSelector(radarDisplayItemsSelector) || [];
    const items = selectDisplayItems(targetWordId, displayItems)
    const displayQty = items.map(item => item.length)
    const data = {
        labels: label_data.map(data => `${data[1] + 1}/${data[2]}`),
        datasets: [
            {
                label: '検出された記事数',
                data: displayQty,
                backgroundColor: '#f9a825'
            }
        ]
    }
    const handleElementsClick = chartElements => {
        if (chartElements.length > 0) {
            const selectedIndex: number = chartElements[0]._index
            if (mode != 'day') {
                dispatch(ActionCreators.setDisplayMode.request('day'))
            }
            if (targetIndex != selectedIndex) {
                dispatch(ActionCreators.setTargetIndex.request(selectedIndex))
            }
        }
    }

    return (
        <div className={style.chartContainer}>
            <Bar
                data={data}
                onElementsClick={handleElementsClick}
                options={options}
            />
        </div>
    )
}
