import React from "react";
import {Bar} from "react-chartjs-2";
import {Typography, Card} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import ImagePressRelease from "../../../../assets/analytics.svg"


const useStyles = makeStyles((Theme) =>
    createStyles({
        title: {
            display: "flex",
            alignItems: "center"
        },
        paper: {
            padding: Theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        icon: {
            width: "30px",
            margin: "0px 15px 20px 0px"
        }
    })
)

interface Props {
    labels: string[],
    lineData?: number[],
    barData?: number[],
    title: string,
    option: {}
}

const MixChart: React.FC<Props> = ({lineData, barData, labels, title, option}) => {
    const classes = useStyles();

    const datasets = {
        labels: labels,
        datasets: [{
            type: 'bar',
            label: '配信先数',
            data: barData,
            fill: false,
            backgroundColor: '#509458',
            borderColor: '#509458',
            hoverBackgroundColor: '#509458',
            hoverBorderColor: '#509458',
            yAxisID: 'y-axis-1'
        },
            {
                label: '開封率',
                type: 'line',
                data: lineData,
                fill: false,
                borderColor: '#f4d002',
                backgroundColor: '#f4d002',
                pointBorderColor: '#f4d002',
                pointBackgroundColor: '#f4d002',
                pointHoverBackgroundColor: '#f4d002',
                pointHoverBorderColor: '##f4d002',
                yAxisID: 'y-axis-2'
            }]
    };

    return (
        <Card className={classes.paper}>
            <div className={classes.title}>
                <img className={classes.icon} src={ImagePressRelease}/>
                <Typography variant="h1" component="h1"
                            gutterBottom>{title}</Typography>
            </div>
            <Bar
                height={150}
                width={480}
                data={datasets}
                options={option}
            />
        </Card>

    )
}

export default MixChart; 
