import React from "react";

//material-ui
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//component
import {TicketQtyView} from "./TicketQty";

//model
import {TicketQtyModel} from "../../../../../model/TicketModel";

interface Props {
  ticketQty: TicketQtyModel;
}

export const ReviewAccept: React.FC<Props> = ({ticketQty}) => {
  const classes = useStyles();
  const totalAvailableTicketQty =
    ticketQty["default"]["available"] + ticketQty["non_default"]["available"];
  return (
    <>
      <Typography>
        配信設定を承認します。
        <br />
        レビュワー全員が承認した場合、残り配信数を1回消費して配信予約を確定します。
      </Typography>
      <div className={classes.warn}>
        <Typography color="error">現在の残り配信数は</Typography>
        <Typography color="error" variant="h6">
          {totalAvailableTicketQty}回です
        </Typography>
        <Typography>
          レビュワー全員が承認したときに、残り配信数が0回の場合は
          <br />
          <strong>予約が確定せず</strong>配信設定編集状態に戻ります。
        </Typography>
      </div>
      <TicketQtyView ticketQty={ticketQty} />
    </>
  );
};

const useStyles = makeStyles({
  warn: {
    margin: 15,
    padding: 5,
    background: "#f5f5f5"
  }
});
