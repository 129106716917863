import React from "react";
import { useSelector } from "react-redux";

//material-ui
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//model
import { RootState } from "../../../reducers";

const topicSelectedSelector = (state: RootState) => state.topic.selected;

export const SearchResultTitle: React.FC = () => {
    const classes = useStyles();
    const { word, year, month } = useSelector(topicSelectedSelector);
    const date = `${year}年${month}月`;
    const title = (!!year && !!month) ? `検索ワード: ${date} "${word}"` : '検索ワード:';

    return <Typography variant="h4" className={classes.word}>{title}</Typography>
}

const useStyles = makeStyles({
    word: {
        padding: 20
    }
})
