import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// components
import AppLayout from "../../components/Layout/App";
import Tag from "../../components/PressRelease/Tag/";

// actions
import * as PressReleaseTagActionCreators from "../../actions/PressReleaseTag/ActionCreator";

// state
import { RootState } from "../../reducers";
const pressGroupSelector = (state: RootState) => state.group;

const TagPage: React.FC = () => {
    const dispatch = useDispatch();
    const group = useSelector(pressGroupSelector);

    useEffect(() => {
        if (group.selectedId) {
            dispatch(PressReleaseTagActionCreators.getPressReleaseTagList.request({ offset: 1 }));
        }
    }, [group.selectedId]);

    return (
        <AppLayout>
            <Tag />
        </AppLayout>
    );
}

export default TagPage;
