import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// action
import * as PressReleasePublishActionCreators from "../../actions/PressReleasePublish/ActionCreator";
import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";

// components
import Layout from "./Layout";
import OGP from "../../components/PressRelease/OGP";
import { PAGE_OGP } from "../../config/page_type";
import { RootState } from "../../reducers";
import { checkEditRole, checkReviewRole } from "../../util/checkAuthority";
import { STATUS_ACCEPT } from "../../config/status_type";

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;

type PageProps = {} & RouteComponentProps<{ groupId: string, pressReleaseId: string }>;

const OGPPage: React.FC<PageProps> = props => {

    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const me = useSelector(meSelector);

    useEffect(() => {
        const pressReleaseId = props.match.params.pressReleaseId
        if (pressReleaseId) {
            dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: pressReleaseId }));
        }
        dispatch(PressReleaseActionCreators.setPressReleasePageType.request(PAGE_OGP));
    }, []);

    useEffect(() => {
        if (pressRelease.detail.status >= STATUS_ACCEPT) {
            dispatch(PressReleasePublishActionCreators.getPressReleasePublish.request({ press_id: pressRelease.detail.press_id }));
        }
    }, [pressRelease.detail.status]);

    useEffect(() => {
        if (me.uuid && pressRelease.detail.fetched === true) {
            dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(checkEditRole(pressRelease, me)));
            dispatch(PressReleaseActionCreators.setPressReleaseReviewRole.request(checkReviewRole(pressRelease.detail.review.member, me)));
        }
    }, [me, pressRelease.detail, pressRelease.creators]);

    return (
        <Layout page={PAGE_OGP}>
            <OGP />
        </Layout>
    );
};

export default OGPPage;
