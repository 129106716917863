import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// components
import Layout from "./EditLayout";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "../../components/Briefing/Edit";
import Header from '../../components/Briefing/Common/Header';

// actions
import * as BriefingActionCreators from '../../actions/Briefing/ActionCreator';
import * as MemberACtionCreators from '../../actions/Member/ActionCreator';

// config
import { BRIEFING_PAGE_TYPES } from '../../config/briefing';

// state
import { RootState } from '../../reducers';
const groupSelector = (state: RootState) => state.group;
const briefingSelector = (state: RootState) => state.briefing;

type PageProps = {} & RouteComponentProps<{ formId: string }>

const BriefingEditPage: React.FC<PageProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const group = useSelector(groupSelector);
  const briefing = useSelector(briefingSelector);
  const { formId } = props.match.params;
  const pageType = BRIEFING_PAGE_TYPES.EDIT;

  useEffect(() => {
    if (group.selectedId) {
      dispatch(BriefingActionCreators.getBriefingDetail.request({ id: formId, pageType }));
      dispatch(MemberACtionCreators.getMemberList.request({ offset: 0, limit: 100 }));
      dispatch(BriefingActionCreators.getBriefingPublicInfo.request({ id: formId }));
    }
  }, [group.selectedId]);

  useEffect(() => {
    if (briefing.detail.body.groupId) {
      dispatch(BriefingActionCreators.getBriefingQty.request({ groupId: briefing.detail.body.groupId }));
    }
  }, [briefing.detail.body.groupId]);

  return (
    <Layout>
      <div className={classes.container}>
        <Header pageType={pageType} />
        <Edit />
      </div>
    </Layout>
  );
}

export default BriefingEditPage;

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    height: '100%',
  },
})
