import React, {useState} from 'react';
import {useDispatch} from "react-redux";

//material-ui
import {Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";

//component
import {TableItem} from "./TableItem";
import {DeleteConfirmDialog} from "../../../Radar/Dialog/DeleteCofirm";

//model
import {RadarWordModel} from "../../../../model/RadarModel";
import {makeStyles} from "@material-ui/core/styles";


interface RadarWordTableProps {
    list: Array<RadarWordModel>
}

export const RadarWordTable: React.FC<RadarWordTableProps> = ({list}) => {
    const classes = useStyles()
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [deleteTargetId, setDeleteTargetId] = useState<number | null>(null);

    const dialogOpenHandler = (isOpen: boolean) => {
        setIsDialogOpen(isOpen)
    }

    const handleCloseDialog = (): void => {
        setIsDialogOpen(false)
        setDeleteTargetId(null)
    }

    const deleteTargetIdSetter = (id: number | null): void => {
        setDeleteTargetId(id)
    }

    return (
        <section>
            <Table>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell style={{ width: "5%" }}>{""}</TableCell>
                        <TableCell style={{width: "40%"}}>検索ワード</TableCell>
                        <TableCell style={{width: "35%"}}>除外ワード</TableCell>
                        <TableCell style={{ width: "20%" }}>{""}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, index) => (
                        <TableItem
                            item={item}
                            key={index}
                            dialogOpenHandler={dialogOpenHandler}
                            deleteTargetIdSetter={deleteTargetIdSetter}
                        />
                    ))}
                </TableBody>
            </Table>
            <DeleteConfirmDialog
                isOpen={isDialogOpen}
                handleClose={handleCloseDialog}
                dialogOpenHandler={dialogOpenHandler}
                deleteTargetId={deleteTargetId}
                deleteTargetIdSetter={deleteTargetIdSetter}
            />
        </section>
    )
}

const useStyles = makeStyles({
    tableHead: {
        backgroundColor: '#eaeaea',
    }
});
