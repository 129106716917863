import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// components
import Layout from "./EditLayout";
import { makeStyles } from "@material-ui/core/styles";
import Detail from '../../components/Briefing/Detail';
import Header from '../../components/Briefing/Common/Header';

// actions
import * as BriefingActionCreators from '../../actions/Briefing/ActionCreator';
import * as MemberActionCreators from '../../actions/Member/ActionCreator';

// config
import { BRIEFING_PAGE_TYPES as PAGE_TYPES } from '../../config/briefing';

// state
import { RootState } from '../../reducers';
const groupSelector = (state: RootState) => state.group;
const briefingSelector = (state: RootState) => state.briefing;

type PageProps = {} & RouteComponentProps<{ formId: string }>

const BriefingDetailPage: React.FC<PageProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const group = useSelector(groupSelector);
    const briefing = useSelector(briefingSelector);
    const { formId } = props.match.params;
    const pageType = PAGE_TYPES.PUBLIC;

    useEffect(() => {
        if (group.selectedId) {
            dispatch(MemberActionCreators.getMemberList.request({ offset: 0, limit: 100 }));
            dispatch(BriefingActionCreators.getBriefingDetail.request({ id: formId, pageType }));
            dispatch(BriefingActionCreators.getBriefingPublicInfo.request({ id: formId }));
        }
    }, [group.selectedId]);

    useEffect(() => {
        if (briefing.detail.body.groupId) {
            dispatch(BriefingActionCreators.getBriefingQty.request({ groupId: briefing.detail.body.groupId }));
        }
    }, [briefing.detail.body.groupId]);


    return (
        <Layout>
            <Header pageType={pageType} />
            <Detail pageType={pageType} />
        </Layout>
    );
}

export default BriefingDetailPage;

const useStyles = makeStyles({
    circularProgress: {
        position: 'absolute',
        top: '40%',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    }
})
