import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

//component
import { Button, Typography } from "@material-ui/core";
import SettingTitle from "../../Common/SettingTitle";
import TableFrame from "../../Common/TableFrame";
import Groups from "../Group";

// style
import style from "./detail.module.scss";

// state
import { RootState } from "../../../reducers";


const companySelector = (state: RootState) => state.company;

const ServiceDetail: React.FC = () => {
    const company = useSelector(companySelector);

    return (
        <div>
            <div className={style.serviceSetting}>
                <SettingTitle text="サービス情報" />
                <div className={style.action}>
                    <NavLink exact to={`/setting/service/edit/`}>
                        <Button variant="contained" size="large" color="primary">
                            サービス情報変更
                    </Button>
                    </NavLink>
                </div>
                <TableFrame>
                    <ul>
                        <li className={style.item}>
                            <label>URL</label>
                            {company.prefix ?
                                <div>
                                    <Typography variant={"body1"}>プレスリリース：https://pr.harvest.site/press_release/<span
                                        className={style.prefix}>{company.prefix}</span></Typography>
                                    <Typography variant={"body1"}>プレスキット：https://pr.harvest.site/press_kit/<span
                                        className={style.prefix}>{company.prefix}</span></Typography>
                                </div>
                                : <Typography variant={"body1"}>設定されていません</Typography>
                            }
                            {/*<Button variant={"outlined"} onClick={() => window.open(`https://pr.harvest.site/press_kit/{company.prefix}`)} color="primary">https://pr.harvest.site/press_kit/{company.prefix}</Button>*/}
                        </li>
                        <li className={style.item}>
                            <label>プレスリリース<br />webページの公開</label>
                            <div className={style.text}>
                                <p>{company.is_public_page ? "公開する" : "公開しない"}</p>
                            </div>
                            {/*{company.is_public_page === "true"  && <Button variant={"outlined"} onClick={() => window.open(`https://pr.harvest.site/press_release/${company.prefix}`)} color="primary">プレスリリースWEB</Button>}*/}
                        </li>
                        <li className={style.item}>
                            <label>プレスリリースの<br />確認フロー機能</label>
                            <div className={style.text}>
                                <p>{company.is_review_skip ? "OFF" : "ON"}</p>
                            </div>
                            {/*{company.is_public_page === "true"  && <Button variant={"outlined"} onClick={() => window.open(`https://pr.harvest.site/press_release/${company.prefix}`)} color="primary">プレスリリースWEB</Button>}*/}
                        </li>
                        <li className={style.item}>
                            <label>プレスリリース配信用<br />メールアドレス</label>
                            <div className={style.text}>
                                <p>{company.email}</p>
                            </div>
                        </li>
                    </ul>
                </TableFrame>
            </div>
            <div>
                <Groups />
            </div>
        </div>
    );
}

export default ServiceDetail;
