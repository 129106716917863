import React from 'react';

//component
import Title from '../Title';
import PageTitle from "../PageTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/setting/service/image01.png';
import Image02 from '../../../assets/help/setting/service/image02.png';


const Service = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"各種設定"}/>
                <Title title={"2. サービス設定"}/>
                <Typography gutterBottom variant={"body1"}>
                    右上のプルダウンから「サービス」を選択します。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
                <Typography gutterBottom variant={"body1"}>
                    HARVESTの設定を確認・変更することができます。<br/>
                    「サービス情報変更」から内容を修正することができます。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>
            </div>
        </article>
    );
};

export default Service;
