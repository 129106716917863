import React from 'react';

//material-ui
import {Typography} from "@material-ui/core";

//model
import {RadarWordModel} from "../../../model/RadarModel";

//style
import style from "../radar.module.scss";


interface Props {
    qty: number
    word: RadarWordModel | undefined
    selectedDate: string
}

export const ListHeader: React.FC<Props> = ({qty, word, selectedDate}) => {
    return (
        <div className={style.listHeader}>
            <div className={style.qty}>
                <Typography variant="h6">検出された記事数:</Typography>
                <Typography variant="h5" className={style.value}>{qty}件</Typography>
            </div>
            <div className={style.date}>
                <Typography variant="h6">選択日時:</Typography>
                <Typography variant="h5" className={style.value}>{selectedDate}</Typography>
            </div>
        </div>
    )
}
