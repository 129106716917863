import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import store from 'store'

import * as GroupActionCreators from "../../../actions/Group/ActionCreator";

// component
import { Select, FormControl, InputLabel } from '@material-ui/core/';

// state
import { RootState } from "../../../reducers";
const pressGroupSelector = (state: RootState) => state.group;


const useStyles = makeStyles({
    select: {
        width: '100%',
        padding: '0 10px',
        color: '#2EB964',
        fontWeight: 'bold',
        backgroundColor: "#f3f9f2",
        marginTop: 9,
    },
    selectInput: {
        padding: "2px 24px 0 0",
    },
    form: {
        width: '100%',
        height: 45,
        flexDirection: 'inherit',
        margin: "6px 0",
    },
    inputLabel: {
        zIndex: 2,
        fontSize: 16,
        fontWeight: 600,
    },
    formControl: {
        top: 5,
        left: 10,
    },
    icon: {
        fontSize: 27,
        top: 0,
    },
});


const GroupSelect: React.FC<{}> = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const group = useSelector(pressGroupSelector);

    // group select
    const handleGroupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        dispatch(GroupActionCreators.setSelectedId.request(event.target.value as string));
        store.set('groupId', event.target.value)
    };

    return (
        <FormControl classes={{ root: classes.form }}>
            <InputLabel
                disableAnimation={true}
                focused={false}
                classes={{
                    root: classes.inputLabel,
                    formControl: classes.formControl
                }}
            >
                プロジェクト
            </InputLabel>
            <Select
                className={classes.select}
                native
                value={group.selectedId}
                onChange={handleGroupChange}
                classes={{ icon: classes.icon, select: classes.selectInput }}
            >
                {group.results.map(value => (
                    <option value={value.uuid} key={value.uuid}>{value.name}</option>
                ))}
            </Select>
        </FormControl>
    );
}


export default GroupSelect
