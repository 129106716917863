import React from 'react';

//material-ui
import {makeStyles} from "@material-ui/core/styles";
import {Button, TableCell} from "@material-ui/core";

//model
import {RadarWordModel} from "../../../../model/RadarModel";


interface Props {
    wordInfo: RadarWordModel
    handleEditClick: () => void
    handleDeleteClick: () => void
}

export const TableItemContent: React.FC<Props> = ({wordInfo, handleEditClick, handleDeleteClick}) => {
    const classes = useStyle();

    return (
        <>
            <TableCell>{''}</TableCell>
            <TableCell>{wordInfo.radar_word}</TableCell>
            <TableCell>{wordInfo.exclude_word}</TableCell>
            <TableCell>
                <div className={classes.menu}>
                    <Button variant="outlined" size="large" color="primary" onClick={handleEditClick}>
                        編集
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.redButton}
                        onClick={handleDeleteClick}
                    >
                        削除
                    </Button>
                </div>
            </TableCell>
        </>
    )
}

const useStyle = makeStyles({
    redButton: {
        background: "#E95050"
    },
    menu: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
})
