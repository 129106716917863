import { takeLatest, put, call, select } from "redux-saga/effects";
import { diff } from "deep-object-diff";
import { push } from "connected-react-router";
import { startSubmit, stopSubmit, reset } from "redux-form";

// selector
import {
  selectMedia,
  selectGroup,
  selectPressReleaseReserve,
} from "./selector";

// fetch
import {
  fetchGet,
  fetchPatch,
  fetchDelete,
  fetchPost,
  fetchPostForm,
} from "./fetch";

// actions
import MediaActionType from "../actions/Media/ActionType";
import * as ActionCreators from "../actions/Media/ActionCreator";

// models
import {
  MediaListRequestModel,
  postFavoriteMediaListRequestModel,
  AllMediaListRequestModel,
  patchFavoriteMediaListRequestModel,
  deleteFavoriteMediaListRequestModel,
  FavoriteMediaListDetailRequestModel,
} from "../model/MediaModel";

// configs
import { CUSTOM_MEDIA_LIST_DISPLAY_COUNT } from "../config/list_display_count";

function* getMediaList(
  action: ReturnType<typeof ActionCreators.getMediaList.request>
) {
  try {
    const group = yield select(selectGroup);
    const request: MediaListRequestModel = action.payload;
    let offset = request.offset;
    offset =
      offset <= 1
        ? 0
        : offset * CUSTOM_MEDIA_LIST_DISPLAY_COUNT -
          CUSTOM_MEDIA_LIST_DISPLAY_COUNT;

    const [data, error] = yield call(
      fetchGet,
      `groups/${group.selectedId}/custom_medias/?offset=${offset}&limit=${CUSTOM_MEDIA_LIST_DISPLAY_COUNT}`
    );

    yield put(
      ActionCreators.getMediaList.success(
        Object.assign({}, { offset: request.offset }, data)
      )
    );
  } catch (e) {
    yield put(ActionCreators.getMediaList.failure(e));
  }
}

function* getMediaAllList(
  action: ReturnType<typeof ActionCreators.getMediaAllList.request>
) {
  try {
    const group = yield select(selectGroup);
    const reserve = yield select(selectPressReleaseReserve);
    const media = yield select(selectMedia);
    const { categories, category_details } = reserve.data;
    const request: AllMediaListRequestModel = action.payload;
    let offset = request.offset;
    offset = offset <= 1 ? 0 : offset * 10 - 10;
    const [data, error] = yield call(
      fetchGet,
      `medias/?offset=${offset}&limit=${request.limit ||
        10}&ca=${request.categories.join(
        ","
      )}&de=${request.category_details.join(",")}&search=${media.search.text}`
    );
    yield put(
      ActionCreators.getMediaAllList.success(
        Object.assign(
          {},
          { offset: request.offset as number },
          { results: data[0] }
        )
      )
    );
  } catch (e) {
    yield put(ActionCreators.getMediaAllList.failure(e));
  }
}

function* getFavoriteMediaList(
  action: ReturnType<typeof ActionCreators.getFavoriteMediaList.request>
) {
  try {
    const group = yield select(selectGroup);
    const request: MediaListRequestModel = action.payload;
    let offset = request.offset;
    offset = offset <= 1 ? 0 : offset * 10 - 10;
    const [data, error] = yield call(
      fetchGet,
      `groups/${
        group.selectedId
      }/favorite_medias/?offset=${offset}&limit=${request.limit || 10}`
    );
    yield put(
      ActionCreators.getFavoriteMediaList.success(
        Object.assign(
          {},
          { offset: request.offset as number },
          { results: data.results, count: data.count }
        )
      )
    );
  } catch (e) {
    yield put(ActionCreators.getFavoriteMediaList.failure(e));
  }
}

function* getFavoriteMediaListDetail(
  action: ReturnType<typeof ActionCreators.getFavoriteMediaListDetail.request>
) {
  try {
    const group = yield select(selectGroup);
    const media = yield select(selectMedia);
    const request: FavoriteMediaListDetailRequestModel = action.payload;
    const pageLimit = 51;
    let offset = request.offset;
    offset = offset <= 1 ? 0 : offset * pageLimit - pageLimit;
    const [data, error] = yield call(
      fetchGet,
      `groups/${group.selectedId}/favorite_medias/${request.id}/?offset=${offset}&limit=${pageLimit}&search=${media.search.text}`
    );
    const results = Object.assign(
      {},
      { medias: data.media, all_id_list: data.all_id_list }
    );
    yield put(
      ActionCreators.getFavoriteMediaListDetail.success(
        Object.assign({}, { results: results, count: data.length })
      )
    );
  } catch (e) {
    yield put(ActionCreators.getFavoriteMediaListDetail.failure(e));
  }
}

function* postFavoriteMediaList(
  action: ReturnType<typeof ActionCreators.postFavoriteMediaList.request>
) {
  try {
    const group = yield select(selectGroup);
    const request: postFavoriteMediaListRequestModel = action.payload;
    const { postSuccess, postError } = request;
    const [data, error] = yield call(
      fetchPost,
      `groups/${group.selectedId}/favorite_medias`,
      request
    );
    if (!error && postSuccess) {
      postSuccess();
    } else if (postError) {
      postError(error[Object.keys(error)[0]][0]);
    }
    yield put(ActionCreators.postFavoriteMediaList.success());
    yield put(
      ActionCreators.getFavoriteMediaList.request({ offset: 1, limit: 10 })
    );
  } catch (e) {
    yield put(ActionCreators.postFavoriteMediaList.failure(e));
  }
}

function* patchFavoriteMediaList(
  action: ReturnType<typeof ActionCreators.patchFavoriteMediaList.request>
) {
  try {
    const group = yield select(selectGroup);
    const media = yield select(selectMedia);
    const offset = media.categoryList.offset;
    const request: patchFavoriteMediaListRequestModel = action.payload;
    const { postSuccess, postError } = request;
    const [data, error] = yield call(
      fetchPatch,
      `groups/${group.selectedId}/favorite_medias/${request.id}`,
      request
    );
    if (!error && postSuccess) {
      postSuccess();
    } else if (postError) {
      postError(error[Object.keys(error)[0]][0]);
    }
    yield put(ActionCreators.patchFavoriteMediaList.success());
    yield put(
      ActionCreators.getFavoriteMediaList.request({ offset: 1, limit: 10 })
    );
    yield put(
      ActionCreators.getFavoriteMediaListDetail.request({
        id: Number(request.id),
        offset: offset,
      })
    );
  } catch (e) {
    yield put(ActionCreators.patchFavoriteMediaList.failure(e));
  }
}

function* deleteFavoriteMediaList(
  action: ReturnType<typeof ActionCreators.deleteFavoriteMediaList.request>
) {
  try {
    const group = yield select(selectGroup);
    const request: deleteFavoriteMediaListRequestModel = action.payload;
    const [data, error] = yield call(
      fetchPatch,
      `groups/${group.selectedId}/favorite_medias/${request.id}`,
      Object.assign({}, request, { is_delete: true })
    );
    yield put(ActionCreators.deleteFavoriteMediaList.success());
    yield put(
      ActionCreators.getFavoriteMediaList.request({ offset: 1, limit: 10 })
    );
  } catch (e) {
    yield put(ActionCreators.deleteFavoriteMediaList.failure(e));
  }
}

function* getMediaDetail(
  action: ReturnType<typeof ActionCreators.getMediaDetail.request>
) {
  try {
    const group = yield select(selectGroup);
    const [data, error] = yield call(
      fetchGet,
      `groups/${group.selectedId}/custom_medias/${action.payload.id}/`
    );
    yield put(ActionCreators.getMediaDetail.success(data));
  } catch (e) {
    yield put(push("/press_release/"));
  }
}

function* postMedia(
  action: ReturnType<typeof ActionCreators.postMedia.request>
) {
  try {
    const group = yield select(selectGroup);
    const [data, error] = yield call(
      fetchPost,
      `groups/${group.selectedId}/custom_medias`,
      action.payload
    );
    if (data && !error) {
      yield put(stopSubmit("MEDIA"));
      yield put(reset("MEDIA"));
      yield put(push("/media/"));
    } else {
      yield put(
        stopSubmit(
          "MEDIA",
          Object.assign(error, { _error: error.non_field_errors })
        )
      );
    }
  } catch (e) {
    yield put(ActionCreators.postMedia.failure(e));
  }
}

function* patchMedia(
  action: ReturnType<typeof ActionCreators.patchMedia.request>
) {
  yield put(startSubmit("MEDIA"));
  try {
    const media = yield select(selectMedia);
    const group = yield select(selectGroup);
    const [data, error] = yield call(
      fetchPatch,
      `groups/${group.selectedId}/custom_medias/${action.payload.id}`,
      diff(media.detail, action.payload)
    );
    if (data && !error) {
      yield put(stopSubmit("MEDIA"));
      yield put(reset("MEDIA"));
      yield put(push("/media/"));
    } else {
      yield put(
        stopSubmit(
          "MEDIA",
          Object.assign(error, { _error: error.non_field_errors })
        )
      );
    }
  } catch (e) {
    yield put(ActionCreators.patchMedia.failure(e));
  }
}

function* deleteMedia(
  action: ReturnType<typeof ActionCreators.deleteMedia.request>
) {
  try {
    const group = yield select(selectGroup);
    const [data, error] = yield call(
      fetchDelete,
      `groups/${group.selectedId}/custom_medias/${action.payload.id}`
    );
    yield put(ActionCreators.getMediaList.request({ offset: 0 }));
  } catch (e) {
    yield put(ActionCreators.deleteMedia.failure(e));
  }
}

function* getMediaCategories(
  action: ReturnType<typeof ActionCreators.getMediaCategories.request>
) {
  try {
    const request: MediaListRequestModel = action.payload;
    let offset = request.offset;
    offset = offset <= 1 ? 0 : offset * 10 - 10;
    const [data, error] = yield call(
      fetchGet,
      `category/?offset=${offset}&limit=${request.limit || 10}`
    );
    yield put(ActionCreators.getMediaCategories.success(data));
  } catch (e) {
    yield put(ActionCreators.getMediaCategories.failure(e));
  }
}

function* postCsvImport(
  action: ReturnType<typeof ActionCreators.postCsvImport.request>
) {
  try {
    const group = yield select(selectGroup);
    const media = yield select(selectMedia);
    const request: any = action.payload;
    const formData = new FormData();
    if (request.data) formData.append("file", request.data);
    if (request.data.name) formData.append("file", request.data.name);
    const [data, error] = yield call(
      fetchPostForm,
      `groups/${group.selectedId}/csv_import`,
      formData
    );
    if (data && !error) yield put(ActionCreators.postCsvImport.success(data));
    else yield put(ActionCreators.postCsvImport.success(error));
    yield put(
      ActionCreators.getMediaList.request({ offset: media.list.offset })
    );
  } catch (e) {
    yield put(ActionCreators.postCsvImport.failure(e));
  }
}

const mediaSaga = [
  takeLatest(MediaActionType.GET_MEDIA_LIST_REQUEST, getMediaList),
  takeLatest(MediaActionType.GET_MEDIA_DETAIL_REQUEST, getMediaDetail),
  takeLatest(MediaActionType.POST_MEDIA_REQUEST, postMedia),
  takeLatest(MediaActionType.PATCH_MEDIA_REQUEST, patchMedia),
  takeLatest(MediaActionType.DELETE_MEDIA_REQUEST, deleteMedia),
  takeLatest(MediaActionType.GET_MEDIA_ALL_LIST_REQUEST, getMediaAllList),
  takeLatest(
    MediaActionType.GET_FAVORITE_MEDIA_LIST_REQUEST,
    getFavoriteMediaList
  ),
  takeLatest(
    MediaActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_REQUEST,
    getFavoriteMediaListDetail
  ),
  takeLatest(
    MediaActionType.POST_FAVORITE_MEDIA_LIST_REQUEST,
    postFavoriteMediaList
  ),
  takeLatest(
    MediaActionType.PATCH_FAVORITE_MEDIA_LIST_REQUEST,
    patchFavoriteMediaList
  ),
  takeLatest(
    MediaActionType.DELETE_FAVORITE_MEDIA_LIST_REQUEST,
    deleteFavoriteMediaList
  ),
  takeLatest(MediaActionType.GET_MEDIA_CATEGORIES_REQUEST, getMediaCategories),
  takeLatest(MediaActionType.POST_CSV_IMPORT_REQUEST, postCsvImport),
];

export default mediaSaga;
