import React from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";

import PressRelease from "../../components/Help/PressRelease";
import Send from "../../components/Help/Send";
import Company from "../../components/Help/Setting/Company";
import Service from "../../components/Help/Setting/Service";
import Member from "../../components/Help/Member"
import Media from "../../components/Help/Media";

interface Props extends RouteComponentProps<void> {
}

const Help: React.FC<Props> = () => {

    return (
        <>
            <Switch>
                <div>
                    <Route exact={true} path="/help/press_release#1" component={PressRelease}/>
                    <Route exact={true} path="/help/send#1" component={Send}/>
                    <Route exact={true} path="/help/company#1" component={Company}/>
                    <Route exact={true} path="/help/service#1" component={Service}/>
                    <Route exact={true} path="/help/member#1" component={Member}/>
                    <Route exact={true} path="/help/media#1" component={Media}/>
                </div>
            </Switch>
        </>
    );
}

export default Help;
