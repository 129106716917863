import React from "react";
import Content from "./Content/";

const Reserve: React.FC = () => {
    return (
        <Content/>
    );
}

export default Reserve;


