import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

// components
import { Button, makeStyles, Typography } from '@material-ui/core';
import TextField from '../../Field/TextField';

// icons
import IconBriefing from '../../../assets/guide/briefing.svg';

const BriefingConfirm: React.FC<InjectedFormProps> = ({
    handleSubmit, pristine, submitting, error
}) => {
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Typography>
                回答依頼メールを受け取ったメールアドレスを入力してください。
            </Typography>
            <div className={classes.input}>
                <Field name="email" type="text" variant="outlined" color="primary" component={TextField} />
            </div>
            <div className={classes.buttons}>
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
                <Button variant="contained" color="primary" type="submit" disabled={pristine || submitting}>
                    送信
                </Button>
            </div>
            <div className={classes.errorMessage}>
                <img src={IconBriefing} className={classes.img} />
            </div>
        </form>
    );
}

export default reduxForm({
    form: "BRIEFING_CONFIRM"
})(BriefingConfirm);

const useStyles = makeStyles({
    form: {
        margin: "100px auto",
        padding: "30px 50px",
        maxWidth: 600,
    },
    input: {
        margin: "15px 0",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
    },
    errorMessage: {
        display: 'flex',
        justifyContent: 'center',
        padding: '110px 50px',
        flexDirection: 'column',
        maxWidth: 530,
        margin: '0 auto',
        alignItems: 'center',
    },
    text: {
        padding: '50px 0',
    },
    img: {
        width: 230,
    },
})
