import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchGet, fetchPost } from "./fetch";
import { selectGroup, selectPressRelease } from "./selector";

// action
import ActionType from '../actions/PressReleaseAI/ActionType';
import * as ActionCreators from '../actions/PressReleaseAI/AIActionCreator';

// 内容チェック
function* postPressReleaseCheck(action: ReturnType<typeof ActionCreators.postPressReleaseCheck.request>) {
    const request = findChildrenNone(action.payload);
    try {
        yield put(ActionCreators.setPressReleaseCheckNode(request.nodeList))
        const isEmptyString = checkEmptyString(request.textList);

        if (!isEmptyString) {
            const [data,error] = yield call(fetchPost, `check_variants`, {"sentence": request.textList});
            yield put(ActionCreators.postPressReleaseCheck.success(data.results))
        }
    } catch (e) {
        yield put(ActionCreators.postPressReleaseCheck.failure(e));
    }
}

// 内容チェック
function* postPressReleaseTitleSuggest(action: ReturnType<typeof ActionCreators.postPressReleaseTitleSuggest.request>) {
    try {
        const pressRelease = yield select(selectPressRelease);
        const request = pressRelease.detail.body.html.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
        const isEmptyString = checkEmptyString(request);

        if (!isEmptyString) {
            const [data,error] = yield call(fetchPost, `title_suggest`, {"text": request});
            yield put(ActionCreators.postPressReleaseTitleSuggest.success(data.results))
        }
    } catch (e) {
        yield put(ActionCreators.postPressReleaseTitleSuggest.failure(e));
    }
}

const pressReleaseAISaga = [
    takeLatest(ActionType.POST_PRESS_RELEASE_CHECK_REQUEST, postPressReleaseCheck),
    takeLatest(ActionType.POST_PRESS_RELEASE_TITLE_SUGGEST_REQUEST, postPressReleaseTitleSuggest),
];

export default pressReleaseAISaga;



// AIチェックにかけるテキストを抽出
export const findChildrenNone = (element) => {
    // [].slice.call() - HTMLCollection to Array
    var children:any = [].slice.call(element.children), found = 0;
    const textList:any = [];
    const nodeList:any = [];
    while (children.length > found) {
        const text = children[found].innerText;
        if (text) {
            textList.push(text);
            nodeList.push(children[found]);
        }
        found++;
    }
    return {
        nodeList,
        textList
    }
};


// AI校閲&titleSuggestにPOSTするか判断するのに必要
export const checkEmptyString = (element) => {
    let isEmptyString: boolean = false;

    if (typeof element === 'string') { // titleSuggest
        const text = element.replace(/\s|&nbsp;/g,"");
        if (text === "") {
            isEmptyString = true;
        } else {
            isEmptyString = false;
        }
    } else { // AI校閲
        let countEmptyString: number = 0;
        element.forEach(text => {
            const replacedText = text.replace(/\s/g,"");
            if (replacedText === "") {
                countEmptyString++;
            }
        });

        if (element.length === countEmptyString) {
            isEmptyString = true;
        } else {
            isEmptyString = false;
        }
    }

    return isEmptyString;
};
