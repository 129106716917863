import React from 'react';

//component
import PageTitle from '../PageTitle';
import Title from '../Title';
import SubTitle from '../SubTitle';
import Point from '../Point';

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/pressRelease/create/image01.png';
import Image02 from '../../../assets/help/pressRelease/create/image02.png';
import Image03 from '../../../assets/help/pressRelease/create/image03.png';
import Image04 from '../../../assets/help/pressRelease/create/image04.png';
import Image05 from '../../../assets/help/pressRelease/create/image05.png';


const Create = () => {
	return (
		<div className={style.box}>
			<PageTitle title={"プレスリリース"}/>
			<Point>
				プレスリリースの作成〜配信までの業務がブラウザ上で完結できるので、社内の確認や承認フローが簡単になります。<br/>
				また、汎用的なテンプレートが用意されているので、初心者でも学びながらプレスリリースを作成することができます。
			</Point>
			<Title title={"1. 新規作成〜保存"}/>
			<SubTitle step={1} title={"新規作成"}/>
			<Typography gutterBottom variant={"body1"}>
				「新規作成」ボタンからプレスリリースの新規作成ができます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<SubTitle step={2} title={"テンプレート選択"}/>
			<Typography gutterBottom variant={"body1"}>
				デザイン別、プレスリリース内容別にテンプレートを準備しております。<br/>
				テンプレートを選択して、「このテンプレートで作成」ボタンを押下してください。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<SubTitle step={3} title={"保存する"}/>
			<Typography gutterBottom variant={"body1"}>
				画面右上の「保存」ボタンから一時保存することができます。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<SubTitle step={4} title={"配信設定へ進む（確認フロー機能OFFの場合）"}/>
			<Typography gutterBottom variant={"body1"}>
				画面右上の「配信設定」ボタンから配信設定に進むことができます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image05}/>
			</div>
			<SubTitle step={5} title={"確認依頼を出す（確認フロー機能ONの場合）"}/>
			<Typography gutterBottom variant={"body1"}>
				画面右上の「確認依頼を出す」ボタンからメンバーに確認依頼を出すことができます。<br/>
				メンバー招待はメンバー一覧画面から招待することができます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
		</div>
	);
};

export default Create;
