import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Typography} from "@material-ui/core";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import { convertFileSize, getFileType } from "../../../../util/File";

// component
import DateTime from "../../../Common/DateTime";
import { PressKitListMenu, ListMenu } from '../ListMenu'
import { Thumbnail } from "../../../Common/Thumbnail";

// img
import ImageEllipsis from "../../../../assets/icon_ellipsis.svg";

//style
import style from "./list_layout.module.scss";

// state
import { RootState } from "../../../../reducers";
import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";

//material-ui
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import {PressKitFirestoreFileModel, PressKitFirestoreModel} from "../../../../model/PressKitModel";

interface PressKitListLayoutItemProps {
    list: any,
}

const selectedPressKitSelector = (state: RootState): string => state.pressKit.add.selectedPressKit
const sortingSelector = (state: RootState): boolean => state.pressKit.edit.sorting;
const pressKitSortingSelector = (state: RootState): boolean => state.pressKit.edit.pressKitSorting;
const viewFileSelector = (state: RootState): Array<PressKitFirestoreFileModel> => state.pressKit.edit.viewFile;
const viewPressKitSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.edit.viewPressKit;

const SortableItemPressKit = SortableElement(({ item }) => (
    <PressKitListLayoutItem item={item} />
))

const SortableListPressKit = SortableContainer(({ list }) => (
    <ul>
        {list.map((item, index) => (
            <SortableItemPressKit
                index={index}
                key={index}
                item={item}
            />
        ))}
    </ul>
))

const PressKitList: React.FC<any> = ({ list }) => {
    return (
        <ul>
            {list.map((item, index) => (
                <PressKitListLayoutItem
                    item={item}
                    key={index}
                />
            ))}
        </ul>
    )
}

export const PressKitListLayout: React.FC<PressKitListLayoutItemProps> = ({ list }) => {
    const dispatch = useDispatch()
    const pressKitSorting = useSelector(pressKitSortingSelector)
    const viewPressKit = useSelector(viewPressKitSelector)
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const sortedList = [...arrayMove(viewPressKit, oldIndex, newIndex)]
        dispatch(PressKitActionCreators.setViewPressKit(sortedList))
        dispatch(PressKitActionCreators.appendSortHistory({ oldIndex, newIndex }))
    }
    return (
            <ul className={style.listRoot}>
                <div className={style.listWrapper}>
                    {pressKitSorting ? (
                        <SortableListPressKit list={viewPressKit} onSortEnd={onSortEnd} lockAxis="y"/>
                    ) : (
                        <PressKitList list={list} />
                    )}
                </div>
            </ul>
    );
};

const PressKitListLayoutItem = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const dispatch = useDispatch()
    const fileSorting = useSelector(sortingSelector);
    const pressKitSorting = useSelector(pressKitSortingSelector);
    const sorting = fileSorting || pressKitSorting;
    const selectedPressKit = useSelector(selectedPressKitSelector)

    const handleClick = () => {
        dispatch(PressKitActionCreators.setSelectedPressKit(item.id))
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <li className={style.list}>
            <List className={style.inner}>
                {pressKitSorting ? (
                    <ListItem
                        className={style.pressKitListItem}
                        onClick={handleClick}
                        selected={item.id == selectedPressKit}
                    >
                        <ListItemText
                            className={style.listItemText}
                            primary={
                                <Typography id={item.id}>
                                    {item.title}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={handleMenuClick} disabled={sorting}>
                                <img src={ImageEllipsis}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ) : (
                    <ListItem
                        className={style.pressKitListItem}
                        button
                        onClick={handleClick}
                        selected={item.id == selectedPressKit}
                        disabled={sorting}
                    >
                        <ListItemText
                            className={style.listItemText}
                            primary={
                                <Typography id={item.id}>
                                    {item.title}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction>
                            <IconButton onClick={handleMenuClick} disabled={sorting}>
                                <img src={ImageEllipsis}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
                <PressKitListMenu
                    item={item}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                />
            </List>
        </li>
    );
}

interface FilesListLayoutItemProps {
    list: any,
    pressKitTitle: string,
}

const FileListHeader: React.FC<any> = ({ pressKitTitle }) => {
    return (
        <>
            <div className={style.fileHeaderContainer}>
                <Typography className={style.fileName}>ファイル名</Typography>
                <div className={style.fileInfoContainer}>
                    <div className={style.dataContainer}>
                        <Typography className={style.data}>作成者</Typography>
                    </div>
                    <div className={style.dataContainer}>
                        <Typography className={style.data}>ファイルの大きさ</Typography>
                    </div>
                    <div className={style.dataContainer}>
                        <Typography className={style.data}>ファイル形式</Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

const FileType: React.FC<any> = ({ file }) => {
    const fileType = getFileType(file);
    return (
        <div className={style.dataContainer}>
            <Typography className={style.data}>{fileType}</Typography>
        </div>
    )
};

const FileSize: React.FC<any> = ({ fileSize }) => {
    const convertedFileSize = convertFileSize(fileSize);
    return (
        <div className={style.dataContainer}>
            <Typography className={style.data}>{convertedFileSize}</Typography>
        </div>
    )
};

const SortableItem = SortableElement(({ item }) => (
    <FilesListLayoutItem item={item} />
))

const SortableList = SortableContainer(({ list }) => {
    return (
        <ul>
            {list.map((item, index) => (
                <SortableItem
                    key={index}
                    index={index}
                    item={item}
                />
            ))}
        </ul>
    )
})

export const FilesList: React.FC<any> = ({ list }) => {
    return (
            <ul>
                {list.map( (item, index) => (
                    <FilesListLayoutItem
                        item={item}
                        key={index}
                    />
                ))}
            </ul>
    );
}

export const FilesListLayout: React.FC<FilesListLayoutItemProps> = ({ list, pressKitTitle }) => {
    const fileSorting = useSelector(sortingSelector);
    const viewFile = useSelector(viewFileSelector);
    const dispatch = useDispatch();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const sortedList = [...arrayMove(viewFile, oldIndex, newIndex)]
        dispatch(PressKitActionCreators.setViewFile(sortedList))
        dispatch(PressKitActionCreators.appendSortHistory({ oldIndex, newIndex }))
    }
    return (
            <ul className={style.fileListRoot}>
                <FileListHeader pressKitTitle={pressKitTitle} />
                {fileSorting ? (
                    <SortableList list={viewFile} onSortEnd={onSortEnd} lockAxis="y"/>
                ) : (
                    <FilesList list={list} />
                )}
            </ul>
    );
}


const FilesListLayoutItem = ({ item }) => {

    const classes = useStyles();
    const fileSorting = useSelector(sortingSelector);
    const pressKitSorting = useSelector(pressKitSortingSelector);
    const sorting = fileSorting || pressKitSorting;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const { createUser, size } = item

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const createdAt = item.createdAt
    let timeStamp
    if (createdAt) {
        timeStamp = createdAt.seconds * 1000
    } else {
        timeStamp = Math.round((new Date()).getTime());
    }

    return (
        <li className={style.fileList}>

            <List className={style.inner}>
                <ListItem className={classes.secondaryAction}>
                    <ListItemIcon>
                        <Thumbnail file={item} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography id={item.id}>
                                {item.displayName}
                            </Typography>
                        }
                        secondary={<DateTime datetime={timeStamp} />}
                    />
                    <ListItemSecondaryAction>
                        <div className={style.fileInfoContainer}>
                            <div className={style.dataContainer}>
                                <Typography className={style.data}>{createUser}</Typography>
                            </div>
                            <FileSize fileSize={size} />
                            <FileType file={item} />
                        </div>
                        <IconButton className={style.button} onClick={handleClick} disabled={sorting}>
                            <img src={ImageEllipsis}/>
                        </IconButton>
                        <ListMenu
                            item={item}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
        </li>
    )
}


const useStyles = makeStyles({
    secondaryAction: {
        paddingRight: 400
    }
});
