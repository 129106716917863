import React from "react";
import {useSelector} from "react-redux";
import {createReleasedAt, createPublicPagePressKit} from "../../../../../util/createUrl";
import {generatePressKitList} from "../../../../../util/reserve";

// component
import {makeStyles, Typography} from "@material-ui/core/";

// img
import ImagePDF from "../../../../../assets/icon_pdf.svg";
import ImagePublic from "../../../../../assets/icon_public.svg";
import LinkIcon from "../../../../../assets/link_icon.svg";

import {RootState} from "../../../../../reducers";
import {PressKitFirestoreModel} from "../../../../../model/PressKitModel";

const pressKitIdSelector = (state: RootState): Array<string> => state.pressReleaseReserve.data.press_kit_id;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;

const useStyles = makeStyles({
    root: {
        padding: 10
    },
    pressKit: {
        wordBreak: "break-all",
        marginTop: 5,
        display: "flex",
        cursor: "pointer"
    },
});


interface Props {
    date: Date,
    prefix: string
}

const PressKit: React.FC<Props> = ({date, prefix}) => {
    const classes = useStyles();
    const pressKitListInFirestoreState = useSelector(firestorePressKitsSelector);
    const attachedPressKitIdList = useSelector(pressKitIdSelector);
    const pressKitInfoList = generatePressKitList(attachedPressKitIdList, pressKitListInFirestoreState);

    const handleClick = (url: string) => {
        window.open(url, "_blank");
    };

    return (
        <div>
            {attachedPressKitIdList.length > 0 &&
            <ul>
                <Typography>素材は下記のリンクからダウンロードしてください。</Typography>
                {pressKitInfoList.map((pressKit, index) => {
                    const pressKitUrl = createPublicPagePressKit(pressKit.id, prefix);
                    return (
                        <div key={index}>
                            <Typography variant="body1" display="block">{pressKit.title}</Typography>
                            <div key={index}>
                                <Typography
                                    display="block"
                                    className={classes.pressKit}
                                    variant="body1"
                                    onClick={() => handleClick(pressKitUrl)}
                                >
                                    {pressKitUrl}
                                </Typography>
                            </div>
                        </div>
                    )
                })}
            </ul>
            }
        </div>
    );
};
export default PressKit;
