import React from "react";

//component
import PageTitle from "../PageTitle";
import Title from "../Title";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from "../../../assets/help/setting/payment/image01.png";
import Image02 from "../../../assets/help/setting/payment/image02.png";

const Payment = () => {
  return (
    <article className={style.root}>
      <a id="1" className={style.anchor} />
      <div className={style.box}>
        <PageTitle title={"各種設定"} />
        <Title title={"7. ご契約情報"} />
        <Typography gutterBottom variant={"body1"}>
          <Typography gutterBottom variant={"body1"} color={"error"}>
            HARVEST内で使用します。外部に表示されることはありません。
          </Typography>
          右上のプルダウンから「ご契約情報」を選択します。
        </Typography>
        <div className={style.img}>
          <img src={Image01} />
        </div>
        <Typography gutterBottom variant={"body1"}>
          契約プラン・購入配信数などの現在の契約情報を確認することができます。
        </Typography>
        <div className={style.img}>
          <img src={Image02} />
        </div>
      </div>
    </article>
  );
};

export default Payment;
