// actions
import MediaActionType from '../actions/Media/ActionType';
import MediaAction from '../actions/Media/Action';

// models
import { MediaModel, MediaListModel, MediaCategoryListModel, CategoryMediaListModel, FavoriteMediaListModel, SearchModel, CsvModel } from "../model/MediaModel";

// state
import createReducer from "./createReducer";
import { combineReducers } from "redux";

const initialStateMediaList: MediaListModel = {
	results: [],
	offset: 0,
	count: 0,
	fetched: false,
};

const initialStateFavoriteMediaList: FavoriteMediaListModel = {
	results: [],
	offset: 0,
	count: 0,
	index: 0,
	fetched: false,
	id_list: [],
	all_id_list: [],
}

export const list = createReducer<MediaListModel>(initialStateMediaList, {
	[MediaActionType.GET_MEDIA_LIST_SUCCEEDED](state: MediaListModel, action: MediaAction) {
		const result: any = action.payload
		return {
			results: result.results,
			offset: result.offset,
			count: result.count,
		}
	}
});

const initialState: MediaModel = {
	name: "",
	publisher: "",
	department: "",
	position: "",
	last_name: "",
	first_name: "",
	last_name_kana: "",
	email: "",
	media_type: "",
	tel: "",
	fax: "",
	memo: "",
};

export const detail = createReducer<MediaModel>(initialState, {
	[MediaActionType.GET_MEDIA_DETAIL_SUCCEEDED](state: MediaModel, action: MediaAction) {
		return action.payload
	}
});

const favoriteList = createReducer<FavoriteMediaListModel>(initialStateFavoriteMediaList, {
	[MediaActionType.GET_FAVORITE_MEDIA_LIST_REQUEST](state: FavoriteMediaListModel, action: MediaAction) {
		const result: any = action.payload
		return {
			...state,
			fetched: false,
		}
	},
	[MediaActionType.GET_FAVORITE_MEDIA_LIST_SUCCEEDED](state: FavoriteMediaListModel, action: MediaAction) {
		const result: any = action.payload
		const results = result.results.map(i => {
			return {
				...i,
				id_list: [],
			}
		})
		const list = state.results.find(i => i.list_id === state.index);
		if (list && results.find(i => i.list_id === state.index)) {
			results.find(i => i.list_id === state.index).id_list = list.id_list;
		}
		return {
			...state,
			fetched: true,
			results: results,
			offset: result.offset,
			count: result.count,
		}
	},
	[MediaActionType.SET_FAVORITE_MEDIA](state: FavoriteMediaListModel, action: MediaAction) {
		let results: any = state.results;
		if (typeof action.payload === 'number' && typeof state.index === 'number') {
			const id_list = increaseOrDecreaseMediaList(state.results.find(i => i.list_id === state.index).id_list.concat(), action.payload)
			results.find(i => i.list_id === state.index).id_list = id_list;
		}

		return {
			...state,
			results: results,
		}
	},
	[MediaActionType.SET_FAVORITE_MEDIA_LIST](state: FavoriteMediaListModel, action: MediaAction) {
		return {
			...state,
			index: action.payload,
		}
	},
	[MediaActionType.ADD_MEDIA_FAVORITE_LIST_ALL](state: FavoriteMediaListModel, action: MediaAction) {
		let results = state.results;
		if (results.length > 0) results.find(i => i.list_id === state.index).id_list = action.payload;
		if (results.length === 0) results.push({ list_id: state.index, id_list: action.payload });
		return {
			...state,
			results: results,
		}
	},
	[MediaActionType.REMOVE_MEDIA_FAVORITE_LIST_ALL](state: FavoriteMediaListModel, action: MediaAction) {
		let results = state.results;
		if (typeof state.index === 'number') results.find(i => i.list_id === state.index).id_list = [];
		return {
			...state,
			results: results,
		}
	},
	[MediaActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_REQUEST](state: FavoriteMediaListModel, action: MediaAction) {
		return {
			...state,
			fetched: false,
		}
	},
	[MediaActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_SUCCEEDED](state: FavoriteMediaListModel, action: MediaAction) {
		return {
			...state,
			fetched: true,
		}
	},
})

const increaseOrDecreaseMediaList = (list: number[], id: number) => {
	if (list.indexOf(id) >= 0) {
		list = list.filter(item => item !== id);
	} else {
		list.push(id)
	}
	return list;
}

const initialCategoriesState: MediaCategoryListModel = {
	results: [],
	offset: 0,
	count: 0,
	fetched: false,
}

const categories = createReducer<MediaCategoryListModel>(initialCategoriesState, {
	[MediaActionType.GET_MEDIA_CATEGORIES_REQUEST](state: MediaCategoryListModel, action: MediaAction) {
		return {
			...state,
			fetched: false,
		}
	},
	[MediaActionType.GET_MEDIA_CATEGORIES_SUCCEEDED](state: MediaCategoryListModel, action: MediaAction) {
		const result: any = action.payload
		return {
			fetched: true,
			results: result.results,
			offset: result.offset,
			count: result.count,
		}
	},
})

const initialStateMediaCategoryList: CategoryMediaListModel = {
	results: [],
	offset: 0,
	count: 0,
	fetched: false,
	id_list: [],
	all_id_list: [],
};

const categoryList = createReducer<CategoryMediaListModel>(initialStateMediaCategoryList, {
	[MediaActionType.GET_MEDIA_ALL_LIST_REQUEST](state: MediaListModel, action: MediaAction) {
		return {
			...state,
			fetched: false,
		}
	},
	[MediaActionType.GET_MEDIA_ALL_LIST_SUCCEEDED](state: CategoryMediaListModel, action: MediaAction) {
		const result: any = action.payload;
		return {
			...state,
			fetched: true,
			results: result.results.medias,
			all_id_list: result.results.all_id_list,
			offset: result.offset,
			count: result.results.medias.length,
		}
	},
	[MediaActionType.SET_MEDIA_CATEGORY_ID_LIST](state: CategoryMediaListModel, action: MediaAction) {
		const list = state.id_list && typeof action.payload === 'number' &&
			increaseOrDecreaseMediaList(state.id_list, action.payload);
		return {
			...state,
			id_list: list,
		}
	},
	[MediaActionType.ADD_MEDIA_CATEGORY_ID_LIST_ALL](state: CategoryMediaListModel, action: MediaAction) {
		const request: any = action.payload;
		if (request.search) {
			return {
				...state,
				id_list: Array.from(new Set(state.results.map(v => v.id).concat(state.id_list))),
			}
		} else {
			return {
				...state,
				id_list: state.all_id_list,
			}
		}
	},
	[MediaActionType.REMOVE_MEDIA_CATEGORY_ID_LIST_ALL](state: CategoryMediaListModel, action: MediaAction) {
		return {
			...state,
			id_list: [],
		}
	},
	[MediaActionType.RESET_MEDIA_CATEGORY_LIST](state: CategoryMediaListModel, action: MediaAction) {
		return {
			...initialStateMediaCategoryList,
		}
	},
	[MediaActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_REQUEST](state: CategoryMediaListModel, action: MediaAction) {
		const request: any = action.payload;
		return {
			...state,
			fetched: false,
			offset: request.offset,
			id_list: request.id_list || [],
		}
	},
	[MediaActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_SUCCEEDED](state: CategoryMediaListModel, action: MediaAction) {
		const result: any = action.payload;
		return {
			...state,
			results: result.results.medias,
			all_id_list: result.results.all_id_list,
			fetched: true,
			count: result.count,
		}
	},
})

const initialSearchState: SearchModel = {
	text: '',
	is_result: false,
}

const search = createReducer<SearchModel>(initialSearchState, {
	[MediaActionType.SET_SEARCH](state: SearchModel, action: MediaAction) {
		if (typeof action.payload === 'object') {
			return {
				...state,
				...action.payload,
			}
		} else {
			return {
				...state,
			}
		}
	},
});

const initialCsvState: CsvModel = {
	fetched: false,
	result: {
		success: [],
		required: [],
		duplicate: [],
		invalid: [],
	},
	error: '',
}

const csv = createReducer(initialCsvState, {
	[MediaActionType.POST_CSV_IMPORT_REQUEST](state: CsvModel, action: MediaAction) {
		return {
			...initialCsvState,
		}
	},
	[MediaActionType.POST_CSV_IMPORT_SUCCEEDED](state: CsvModel, action: MediaAction) {
		const result: any = action.payload;
		return {
			...state,
			fetched: true,
			result: result.data || state.result,
			error: result.error || state.error,
		}
	},
});

export const media = combineReducers({
	list,
	detail,
	favoriteList,
	categories,
	categoryList,
	search,
	csv,
});


