import React from 'react';
import Title from '../Title'
import Typography from "@material-ui/core/Typography";
import SubTitle from "../SubTitle";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/registration/image01.png';
import Image02 from '../../../assets/help/registration/image02.png';
import {NavLink} from "react-router-dom";
import PageTitle from "../PageTitle";

const Index = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"会員登録"}/>
                <Title title={"1.新規会員登録"}/>
                <Typography gutterBottom variant={"body1"}>
                    必要項目を入力してください。
                </Typography>
                <Typography gutterBottom variant={"body1"} color={"error"}>
                    企業名は<NavLink to={`/help/web_page#1`} className={style.link}>プレスリリースweb公開ページ</NavLink>、
                    <NavLink target="_blank" to={`/help/press_kit#1`} className={style.link}>プレスキットページ</NavLink>で表示されます。
                </Typography>

                <Typography variant={"body1"} color={"error"}>
                    個人情報はHARVEST内で使用します。外部には表示されません。
                </Typography>
                <Typography gutterBottom variant={"body1"} color={"error"}>

                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>

                <Typography gutterBottom variant={"body1"}>
                    仮登録メールが送信されます。<br/>
                    メールをご確認ください。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>

            </div>
        </article>
    );
};

export default Index;
