import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {push} from 'connected-react-router'

// action
import * as PressReleaseActionCreators from "../../../../../actions/PressRelease/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../../../../actions/PressReleaseFirebase/ActionCreator";

// component
import Button from "@material-ui/core/Button";

import {RootState} from "../../../../../reducers";
const pressGroupSelector = (state: RootState) => state.group;
const pressReleaseSelector = (state: RootState) => state.pressRelease;

interface PressReleaseEditButtonProps {
    disabled: boolean
}

const PressReleaseSkipReviewButton: React.FC<PressReleaseEditButtonProps> = ({disabled}) => {
    const dispatch = useDispatch();
    const group = useSelector(pressGroupSelector);
    const pressRelease = useSelector(pressReleaseSelector);

    // ステースを0(:編集中に戻す)
    const handleClick = () => {
        dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
            page: "reserve",
            press_id: pressRelease.detail.press_id,
            user_id: "",
        }));
        dispatch(PressReleaseActionCreators.postPressReleaseStatusChange.request(0))
        dispatch(push(`/press_release/${group.selectedId}/detail/${pressRelease.detail.press_id}/edit/`))
    };

    return (
        <Button
            variant="outlined"
            color="primary"
            size="large"
            disabled={!pressRelease.role.edit || disabled}
            onClick={handleClick}>プレスリリースを編集する
        </Button>
    );
};

export default PressReleaseSkipReviewButton;

