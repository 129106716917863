import * as React from "react";
import { Provider } from "react-redux";
import App from "./App";
import configureStore, { history } from './configureStore'
import store from 'store'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { configureFirebase } from './configureFirebase'

export const appStore = configureStore();

const rrfProps = configureFirebase(appStore)

function ReduxRoot() {
	return (
		<Provider store={appStore}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<App/>
			</ReactReduxFirebaseProvider>
		</Provider>
	);
}

export default ReduxRoot;
