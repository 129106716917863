import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from '@material-ui/core';
import HelpFab from '../../Common/HelpFab';
import TableFrame from '../../Common/TableFrame';
import Title from '../../Common/Title';
import CreateLabel from './Create';
import TagTable from './Table';
import Pagination from '../../Common/Pagination';

// img
import ImagePressRelease from "../../../assets/guide/press_release.svg";

// actions
import * as PressReleaseTagActionCreators from '../../../actions/PressReleaseTag/ActionCreator';

// state
import { RootState } from '../../../reducers';
import { theme } from '../../../withRoot';
const pressReleaseTagSelector = (state: RootState) => state.pressReleaseTag;

const Tag = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { list, form } = useSelector(pressReleaseTagSelector);
    const { id, isOpen, name, description, bg_color } = form;

    const postPressReleaseTagRequest = () => {
        dispatch(PressReleaseTagActionCreators.postPressReleaseTag.request({
            name: name,
            description: description,
            bg_color: bg_color,
        }));
    }

    const patchPressReleaseTagRequest = () => {
        dispatch(PressReleaseTagActionCreators.patchPressReleaseTag.request({
            id: id,
            name: name,
            description: description,
            bg_color: bg_color,
        }))
    }

    const handleSave = () => {
        if (id > 0) patchPressReleaseTagRequest();
        else postPressReleaseTagRequest();
    }

    const handleCreateOpen = () => {
        dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request({ isOpen: true, id: 0 }));
    }

    const handleCreateClose = () => {
        dispatch(PressReleaseTagActionCreators.resetPressReleaseTagForm.request());
    }

    const handlePageChange = (pageNumber) => {
        dispatch(PressReleaseTagActionCreators.getPressReleaseTagList.request({ offset: pageNumber }))
    }

    useEffect(() => {
        return () => {
            handleCreateClose();
        }
    }, [])

    return (
        <section>
            <div className={classes.title}>
                <Title>
                    プレスリリース管理ラベル<HelpFab url={'/help/press_release#7'} />
                </Title>
                {list.count != 0 && <Button variant="outlined" size="medium" color="primary" disabled={isOpen}
                    className={classes.button} onClick={handleCreateOpen}>
                    新規作成
			    </Button>}
            </div>
            {isOpen &&
                <CreateLabel
                    handleCreateClose={handleCreateClose}
                    handleSave={handleSave}
                />}
            {list.count > 0 &&
                <div style={{ marginTop: isOpen ? 0 : '-20px' }}>
                    <TableFrame>
                        <TagTable list={list.results} />
                    </TableFrame>
                    <div className={classes.pagination}>
                        <Pagination
                            activePage={list.offset > 0 ? list.offset : 1}
                            totalCount={list.count}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                </div>}
            {list.count === 0 && !isOpen && (
                <div className={classes.noData}>
                    <div className={classes.noDataImg}><img src={ImagePressRelease} /></div>
                    <Typography variant="h4" gutterBottom>
                        プレスリリースにラベルをつけて管理しましょう
                    </Typography>
                    <Button variant="contained" color="primary" size={"large"} className={classes.newCreateButton} onClick={handleCreateOpen}>
                        新規作成する
                    </Button>
                </div>
            )}
        </section >
    );
}

export default Tag;

const useStyles = makeStyles({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    head: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginRight: 10,
        height: 35,
    },
    noData: {
        marginTop: 20,
        textAlign: "center",
        marginBottom: 30,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    noDataImg: {
        margin: "30px auto",
        width: 400,
        height: 245,
    },
    newCreateButton: {
        marginTop: 20,
        width: 400,
    },
    pagination: {
        marginTop: 20,
    },
})
