import PressReleaseActionType from '../actions/PressRelease/ActionType';
import PressReleaseAction from '../actions/PressRelease/Action';
import {
	PressReleaseListModel, SetPressListFilterRequestModel
} from "../model/PressReleaseModel";

import {
	ListModel
} from "../model/ListModel";

import createReducer from "./createReducer";
import { combineReducers } from "redux";
import { PressFilterTypes } from '../config/press_filter_type';

const initialStateList: ListModel = {
	results: [],
	offset: 0,
	count: 0,
};

const initialFilterState = {
	creator: '',
	reviewer: '',
	tag: [],
}

const search = createReducer<ListModel>(initialStateList, {
	[PressReleaseActionType.GET_PRESS_RELEASE_LIST_SUCCEEDED](state: PressReleaseListModel[], action: PressReleaseAction) {
		return action.payload
	}
});

const publicList = createReducer<ListModel>(initialStateList, {
	[PressReleaseActionType.GET_PRESS_RELEASE_LIST_PUBLIC_SUCCEEDED](state: PressReleaseListModel[], action: PressReleaseAction) {
		return action.payload
	}
});

const archive = createReducer<ListModel>(initialStateList, {
	[PressReleaseActionType.GET_PRESS_RELEASE_LIST_ARCHIVE_SUCCEEDED](state: PressReleaseListModel[], action: PressReleaseAction) {
		return action.payload
	}
});

const increaseOrDecreaseIdList = (list: number[], id: number) => {
	if (list.indexOf(id) >= 0) {
		list = list.filter(item => item !== id);
	} else {
		list.push(id)
	}
	return list;
}

const filter = createReducer(initialFilterState, {
	[PressReleaseActionType.SET_PRESS_LIST_FILTER](state: any, action: PressReleaseAction) {
		const request: any = action.payload;
		if (Object.keys(request)[0] === PressFilterTypes.tag) {
			const tag = increaseOrDecreaseIdList(state.tag, request.tag);
			return {
				...state,
				tag: tag,
			}
		} else return {
			...state,
			...request,
		}
	},
	[PressReleaseActionType.RESET_PRESS_LIST_FILTER] (state: any, action: PressReleaseAction) {
		return {
			creator: '',
			reviewer: '',
			tag: [],
		}
	},
})

export const pressReleaseList = combineReducers({
	search,
	publicList,
	archive,
	filter,
});
