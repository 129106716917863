import ActionType from '../actions/PressReleaseTag/ActionType';
import Action from '../actions/PressReleaseTag/Action';
import {
    PressReleaseTagListModel, PressReleaseTagModel, PressReleaseTagFormModel
} from "../model/PressReleaseTagModel";

import createReducer from "./createReducer";
import { combineReducers } from "redux";
import { theme } from '../withRoot';

const initialState: PressReleaseTagListModel = {
    fetched: false,
    results: [],
    offset: 0,
    count: 0,
};

const list = createReducer<PressReleaseTagListModel>(initialState, {
    [ActionType.GET_PRESS_RELEASE_TAG_LIST_REQUEST](state: PressReleaseTagListModel, action: Action) {
        return {
            ...state,
            results: state.fetched ? state.results : [],
            fetched: false,
        }
    },
    [ActionType.GET_PRESS_RELEASE_TAG_LIST_SUCCEEDED](state: PressReleaseTagListModel, action: Action) {
        const result: any = action.payload;
        return {
            ...state,
            results: result.results,
            offset: result.offset,
            count: result.count,
            fetched: true,
        }
    },
    [ActionType.POST_PRESS_INFO_TAG_RELATION_REQUEST](state: PressReleaseTagListModel, action: Action) {
        return {
            ...state,
            fetched: false,
        }
    },
    [ActionType.DELETE_PRESS_INFO_TAG_RELATION_REQUEST](state: PressReleaseTagListModel, action: Action) {
        return {
            ...state,
            fetched: false,
        }
    },
    [ActionType.POST_PRESS_INFO_TAG_RELATION_SUCCEEDED](state: PressReleaseTagListModel, action: Action) {
        return {
            ...state,
            fetched: true,
        }
    },
    [ActionType.DELETE_PRESS_INFO_TAG_RELATION_SUCCEEDED](state: PressReleaseTagListModel, action: Action) {
        return {
            ...state,
            fetched: true,
        }
    },
});

const initialFormState: PressReleaseTagFormModel = {
    isOpen: false,
    id: 0,
    name: '',
    description: '',
    bg_color: theme.palette.primary.main,
}

const form = createReducer<PressReleaseTagFormModel>(initialFormState, {
    [ActionType.SET_PRESS_RELEASE_TAG_FORM](state: PressReleaseTagFormModel, action: Action) {
        return {
            ...state,
            ...action.payload,
        }
    },
    [ActionType.RESET_PRESS_RELEASE_TAG_FORM](state: PressReleaseTagFormModel, action: Action) {
        return {
            isOpen: false,
            id: 0,
            name: '',
            description: '',
            bg_color: theme.palette.primary.main,
        }
    }
})


export const pressReleaseTag = combineReducers({
    list,
    form,
});
