import React, { useEffect, useState } from "react";
import Froalaeditor from 'froala-editor';
import FroalaEditor from 'react-froala-wysiwyg';
import {useDispatch, useSelector} from "react-redux";
import { API_URL } from '../../../config';
import store from 'store';

//img
import Left from '../../../assets/editor/left.svg';
import Center from '../../../assets/editor/center.svg';
import Right from '../../../assets/editor/right.svg';
import CodeBlock from '../../../assets/editor/code.svg';
import Column from '../../../assets/editor/parts_4column_white.svg';
import Column2 from '../../../assets/editor/parts_2column.svg';
import Column3 from '../../../assets/editor/parts_3column.svg';
import Column4 from '../../../assets/editor/parts_4column.svg';
import IconBlock from '../../../assets/editor/icon_block.svg';
import IconPDF from '../../../assets/editor/icon_pdf.svg';
import IconPadding from '../../../assets/editor/padding.svg';

//component
import Alert from '../../Common/Alert';

//プラグイン
import 'froala-editor/js/languages/ja.js';
import 'froala-editor/js/froala_editor.min.js';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/edit_in_popup.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/forms.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';
import FroalaCodeBlockPlugin from './FroalaCodeBlockPlugin';
import './FroalaCellPaddingPlugin';
import FroalaBlockPlugin from './FroalaBlockPlugin';
import FroalaPdfPlugin from './FroalaPdfPlugin';


//CSS
import 'froala-editor/css/froala_editor.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/css/froala_editor.pkgd.css';
import 'froala-editor/css/plugins.pkgd.css';
import 'froala-editor/css/third_party/embedly.css';
import 'froala-editor/css/third_party/font_awesome.css';
import 'froala-editor/css/third_party/spell_checker.css';
import 'froala-editor/css/themes/dark.css';
import './editor_plugins_style.css';

//action
import * as PressReleaseActionCreators from "../../../actions/PressRelease/ActionCreator";
import * as PressReleaseAIActionCreators from "../../../actions/PressReleaseAI/AIActionCreator";
import * as BlockActionCreators from "../../../actions/Block/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../../actions/PressReleaseFirebase/ActionCreator";

//state
import {RootState} from "../../../reducers";
const blockSelector = (state: RootState) => state.block;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;
const firebaseSelector = (state: RootState) => state.firebase;

interface FroalaEditorProps {
    html: string | HTMLElement,
    css?: string,
    groupSelectedId: string,
}

const FroalaEditorComponent: React.FC<FroalaEditorProps> = ({ html, css, groupSelectedId }) => {

    const dispatch = useDispatch();
    const block = useSelector(blockSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const me = useSelector(meSelector);
    const accessData = useSelector(firebaseSelector);


    const [model, setModel] = useState<string>("");
    const [froalaEditor, setFroalaEditor] = useState<any>(null);
    const [isFroalaInitialized, setIsFroalaInitialized] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [height, setHeight] = useState<number>(window.innerHeight);

    const path: string = location.pathname;
    const saveButton: any = document.getElementsByClassName('MuiButton-containedPrimary');

    let timeoutId: any;

    const [id, setId] = useState<number>(0);
    const [errorCode, setErrorCode] = useState<number>(0);
    const [alert, setAlert] = useState<boolean>(false);

    let logoutTimer: any;
    const [editor, setEditor] = useState<string>("");

    //エディタ初期化
    const handleManualController = initControls => {
        initControls.initialize();
        setFroalaEditor(initControls.getEditor());
    };

    const handleModelChange = (model: any) => {
        setModel(model);
    };

    //エディタにhtmlをセット
    useEffect(() => {
        if( !isFroalaInitialized ) {
            setCount(count + 1);

            if (froalaEditor) {
                if (Object.keys(froalaEditor).length > 100 && html) {
                    froalaEditor.html.set(html);
                    setIsFroalaInitialized(true);
                    froalaEditor.pdf.insertHeaderFooter();
                    const appEditor =  document.querySelectorAll('#appEditor .fr-view');
                    dispatch(PressReleaseAIActionCreators.postPressReleaseCheck.request(appEditor[0]));
                }
            }
        }
    }, [count]);

    //バージョン戻しの時に動作
    useEffect(() => {
        if (pressRelease.detail.revert === true) {
            froalaEditor.html.set(html);
        }
    }, [pressRelease.detail.revert]);

    //画像一覧からの画像削除時に動作
    useEffect(() => {
        if (isFroalaInitialized && id) {
            froalaEditor.opts.imageManagerDeleteURL = `${API_URL}groups/${groupSelectedId}/image/${id}/`;
        }
    }, [id]);

    //画像削除後に、一覧のモーダルを開き直す必要があるため設定する
    useEffect(() => {
        if (isFroalaInitialized && errorCode) {
            if (errorCode === 21) {
                setErrorCode(0);
                froalaEditor.imageManager.show();
            }
        }
    }, [errorCode]);

    //ボタンにカーソルをのせた時に出現する日本語変更&dropdownlistの項目変更
    useEffect(() => {
        Froalaeditor.COMMANDS.textColor.title = "テキストの色";
        Froalaeditor.COMMANDS.moreText.title = "テキストの装飾";
        Froalaeditor.COMMANDS.backgroundColor.title = "テキストの背景色";
        Froalaeditor.COMMANDS.moreParagraph.title = "書式";
        Froalaeditor.COMMANDS.paragraphFormat.title = "見出し";
        Froalaeditor.COMMANDS.paragraphStyle.title = "スタイル";
        Froalaeditor.COMMANDS.lineHeight.title = "行の高さ";
        Froalaeditor.COMMANDS.moreRich.title = "挿入";
        Froalaeditor.COMMANDS.imageReplace.title = "画像の差し替え";
        Froalaeditor.COMMANDS.formatOL.options.default = "1. __　2. __";
        Froalaeditor.COMMANDS.formatOL.options['lower-alpha'] = "a. __　b. __";
        Froalaeditor.COMMANDS.formatOL.options['lower-greek'] = "α. __　β. __";
        Froalaeditor.COMMANDS.formatOL.options['lower-roman'] = "i. __　ii. __";
        Froalaeditor.COMMANDS.formatOL.options['upper-alpha'] = "A. __　B. __";
        Froalaeditor.COMMANDS.formatOL.options['upper-roman'] = "I. __　II. __";
        Froalaeditor.COMMANDS.formatUL.options = {
            default : "●",
            circle : "○",
            square : "■",
        };
    },[]);

    //コードブロック挿入後 ol,ul を挿入するとliのみ背景色が変化してしまう問題への対処
    const changeBGColor = () => {
        let blocks: any = froalaEditor.selection.blocks();

        Object.keys(blocks).forEach(key => {
            if (blocks[key].tagName === 'LI' && blocks[key].classList.contains('codeblock')) {
                blocks[key].parentElement.classList.add('codeblock');
                if (blocks[key].style.backgroundColor) {
                    const color: string = blocks[key].style.backgroundColor;
                    blocks[key].parentElement.style.backgroundColor = color;
                    blocks[key].style.backgroundColor = '';
                }

                if (blocks[key].style.border) {
                    const border: string = blocks[key].style.border;
                    blocks[key].parentElement.style.border = border;
                    blocks[key].style.border = '';
                }

                if (blocks[key].style.borderRadius) {
                    const borderRadius: string = blocks[key].style.borderRadius;
                    blocks[key].parentElement.style.borderRadius = borderRadius;
                    blocks[key].style.borderRadius = '';
                }

                blocks[key].style.paddingTop = '';
                blocks[key].style.paddingBottom = '';
                blocks[key].classList.remove('codeblock');
            }
        });
    };


    if (isFroalaInitialized) {
        let anchors: any = document.querySelectorAll('a.fr-command');

        if (document.getElementById('formatOL-1') != null) {
            document.getElementById('formatOL-1')!.onclick = () => {
                changeBGColor();
            };
        }
        if (document.getElementById('formatUL-1') != null) {
            document.getElementById('formatUL-1')!.onclick = () => {
                changeBGColor();
            };
        }

        if (anchors.length > 0) {
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].onclick = () => {
                    changeBGColor();
                };
            }
        }
    }

    //コードブロックのサイズ変化に応じてpaddingとborder-radiusを変化
    useEffect(() => {
        let codeblock: any;
        if (isFroalaInitialized) {
            codeblock = froalaEditor.selection.blocks();
        }

        if (codeblock && Object.keys(codeblock).length > 0 ) {
            Object.keys(codeblock).forEach(key => {
                const element: any = codeblock[key];

                if (element.tagName !== 'OL' && element.tagName !== 'UL' && element.tagName !== 'LI') {
                    if (element.previousElementSibling.classList.contains('codeblock')) {
                        const innerHTML: string = element.innerHTML;
                        element.previousElementSibling.innerHTML += `<br/>` + innerHTML;

                        const selection: any = window.getSelection();
                        const range: any = document.createRange();
                        range.setStart(element.previousElementSibling.lastChild, 0);
                        range.setEnd(element.previousElementSibling.lastChild, 0);
                        selection.removeAllRanges();
                        selection.addRange(range);

                        element.outerHTML = '';
                    }
                }
            });
        }
    }, [Object.keys(document.getElementsByClassName('codeblock')).length]);

    //ウィンドウサイズの変化に合わせてエディタの高さを変化
    window.onresize = () => {
        clearTimeout(timeoutId);
        let newWindowHeight: number = window.innerHeight;

        timeoutId = setTimeout( () => {
            if (height != newWindowHeight) {
                setHeight(newWindowHeight)
                const wrapper = document.getElementsByClassName('fr-wrapper') as HTMLCollectionOf<HTMLElement>;
                const view = document.getElementsByClassName('fr-element fr-view') as HTMLCollectionOf<HTMLElement>;

                if (wrapper && view) {
                    wrapper[0].style.cssText = `height: ${newWindowHeight - 120}px; overflow: auto;`
                    view[0].style.cssText = `min-height: ${newWindowHeight - 120}px;`
                }
            }
        }, 300);
    };

    //編集ロック&ロック解除に必要
    useEffect(() => {
        const data = accessData.data[pressRelease.detail.press_id];
        if (pressRelease.pageType === 'PAGE_EDIT') {
            if ((data && isFroalaInitialized) && data.edit && data.edit.editor) {
                setEditor(data.edit.editor);
                if (data.edit.editor !== me.uuid) {
                    froalaEditor.edit.off();
                } else {
                    froalaEditor.edit.on();
                }
            } else {
                setEditor("");
                if (data && data.edit && !data.edit.editor && editor) {
                    dispatch(PressReleaseActionCreators.getPressReleaseHistoryDetailsLatest.request({press_id: pressRelease.detail.press_id}));
                }
            }
        } else {
            if (froalaEditor) {
                froalaEditor.edit.on();
            }
        }
    }, [accessData.data[pressRelease.detail.press_id]]);

   //ロック解除後にPRを最新の状態にするのに必要
    useEffect(() => {
        if (pressRelease.detail.latestFetched && pressRelease.detail.body.html && froalaEditor) {
            froalaEditor.html.set(pressRelease.detail.body.html);
            froalaEditor.edit.on();
        }
    },[pressRelease.detail.latestFetched]);

    //画像とテキストが横並びになる機能
    Froalaeditor.DefineIcon('imageAndTextStyle', {NAME: 'magic', SVG_KEY: 'imageClass'});
    Froalaeditor.RegisterCommand('imageAndTextStyle', {
        title: '画像とテキストの配置',
        type: 'dropdown',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        options: {
            'left': '画像：左\nテキスト：右',
            'center': '画像のみ中央',
            'right': '画像：右\nテキスト：左',
        },
        html: () => {
            let e = '<ul class="fr-dropdown-list" role="presentation">',
                t = Froalaeditor.COMMANDS.imageAndTextStyle.options;

            for (let a in t) {
                if (a === 'left') {
                    e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="imageAndTextStyle" data-param1="' + a + '" title= "'+ t[a] +'" >' + `<img src=${Right} class='rightIcon'>` + "</a></li>";
                } else if (a === 'center') {
                    e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="imageAndTextStyle" data-param1="' + a + '" title= "'+ t[a] +'" >' + `<img src=${Center} class='centerIcon'>` + "</a></li>";
                } else {
                    e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="imageAndTextStyle" data-param1="' + a + '" title= "'+ t[a] +'" >' + `<img src=${Left} class='leftIcon'>` + "</a></li>";
                }
            }
            return e += "</ul>";
        },
        callback: (e, t) => {
            if (t === 'center') {
                froalaEditor.image.display('block');
                froalaEditor.image.align('center');
            } else {
                froalaEditor.image.display('inline');

                if (t === 'right') {
                    froalaEditor.image.align('right');
                } else {
                    froalaEditor.image.align('left');
                }
            }
        },
        refreshOnShow: (e, t) => {
            froalaEditor.image.refreshAlignOnShow(e, t);
        }
    });

    //コードブロック
    FroalaCodeBlockPlugin(froalaEditor);
    Froalaeditor.DefineIconTemplate('codeblock', `<img src=${CodeBlock} class="codeblockIcon"/>`);
    Froalaeditor.DefineIcon('codeblockColor', { template: 'codeblock' });
    Froalaeditor.RegisterCommand('codeblockColor', {
        title: '段落の背景色',
        undo: false,
        focus: true,
        refreshOnCallback: false,
        popup: true,
        callback: () => {
            froalaEditor.popups.isVisible("codeblockColor.picker") ? (froalaEditor.$el.find(".fr-marker").length && (froalaEditor.events.disableBlur(), froalaEditor.selection.restore()), froalaEditor.popups.hide("codeblockColor.picker")) : froalaEditor.codeblock.showColorsPopup("codeblockColor");
        }
    });

    Froalaeditor.RegisterCommand("applycodeblockColor", {
        undo: true,
        callback: (o, t) => {
            froalaEditor.codeblock.background(t);
        }
    });

    Froalaeditor.DefineIcon("colorsBack", { NAME: "arrow-left", SVG_KEY: "back" });
    Froalaeditor.RegisterCommand("colorsBack", {
        title: "Back",
        undo: false,
        focus: false,
        back: true,
        refreshAfterCallback: false,
        callback: () => {
            froalaEditor.codeblock.back();
        }
    });

    Froalaeditor.DefineIcon("remove", { NAME: "eraser", SVG_KEY: "remove" });

    //column機能
    Froalaeditor.DefineIconTemplate('column', `<img src=${Column} class="insertColumnIcon"/>`);
    Froalaeditor.DefineIcon('insertColumn', { template: 'column' });
    Froalaeditor.RegisterCommand('insertColumn', {
        title: 'カラムの挿入',
        type: 'dropdown',
        focus: true,
        undo: true,
        refreshAfterCallback: false,
        options: {
            'column2': '2カラム',
            'column3': '3カラム',
            'column4': '4カラム',
        },
        html: () => {
            let e = '<ul class="fr-dropdown-list" role="presentation">',
                t = Froalaeditor.COMMANDS.insertColumn.options;

            for (let a in t) {
                if (a === 'column2') {
                    e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="insertColumn" data-param1="' + a + '" title= "'+ t[a] +'" >' + `<img src=${Column2} class="columnIcon"/>` + "</a></li>";
                } else if (a === 'column3') {
                    e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="insertColumn" data-param1="' + a + '" title= "'+ t[a] +'" >' + `<img src=${Column3} class="columnIcon"/>` + "</a></li>";
                } else {
                    e += '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="insertColumn" data-param1="' + a + '" title= "'+ t[a] +'" >' + `<img src=${Column4} class="columnIcon"/>` + "</a></li>";
                }
            }
            return e += "</ul>";
        },
        callback: (e, t) => {
            if (t === 'column2') {
                froalaEditor.html.insert('<p><br></p><div class="column2"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><div class="column2"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><p><br></p>');
            } else if (t === 'column3') {
                froalaEditor.html.insert('<p><br></p><div class="column3"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><div class="column3"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><div class="column3"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><p><br></p>');
            } else {
                froalaEditor.html.insert('<p><br></p><div class="column4"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><div class="column4"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><div class="column4"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><div class="column4"><img src="https://storage.googleapis.com/harvest_press_stg/img/d34d5118c94d468d939fb8bdf01d4187/dummy.png" alt="Image" class="fr-fic fr-draggable fr-dib" style="width: 100%;"><p style="text-align: center;">テキスト</p></div><p><br></p>');
            }
            const blocks: any = froalaEditor.selection.blocks();
            const selection: any = window.getSelection();
            const range: any = document.createRange();
            if (t === 'column2') {
                range.setStart(blocks[0].previousElementSibling.previousElementSibling.childNodes[1], 0);
                range.setEnd(blocks[0].previousElementSibling.previousElementSibling.childNodes[1], 0);
            } else if (t === 'column3') {
                range.setStart(blocks[0].previousElementSibling.previousElementSibling.previousElementSibling.childNodes[1], 0);
                range.setEnd(blocks[0].previousElementSibling.previousElementSibling.previousElementSibling.childNodes[1], 0);
            } else {
                range.setStart(blocks[0].previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.childNodes[1], 0);
                range.setEnd(blocks[0].previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.childNodes[1], 0);
            }
            selection.removeAllRanges();
            selection.addRange(range);
        },
    });

    // ブロック
    FroalaBlockPlugin(froalaEditor);
    Froalaeditor.DefineIconTemplate('blockButton', `<img src=${IconBlock} class="blockIcon"/>`);
    Froalaeditor.DefineIcon('blockButton', { template: 'blockButton' });
    Froalaeditor.RegisterCommand('blockButton', {
        title: 'カスタムブロックの挿入',
        icon: 'blockButton',
        undo: false,
        focus: false,
        plugin: 'block',
        callback: function () {
            dispatch(BlockActionCreators.getBlock.request({ offset: 0 }));
            froalaEditor.block.showPopup(block.results);
        }
    });

    // PDF
    FroalaPdfPlugin(froalaEditor);

    // PDF用　popup表示
    Froalaeditor.DefineIconTemplate('PDFButton', `<img src=${IconPDF} class="blockIcon"/>`);
    Froalaeditor.DefineIcon('PDFButton', { template: 'PDFButton' });
    Froalaeditor.RegisterCommand('PDFButton', {
        title: 'PDFの設定',
        undo: false,
        focus: false,
        plugin: 'pdf',
        callback: function () {
            froalaEditor.pdf.showPopup();
        }
    });


    //改ページ挿入
    Froalaeditor.DefineIconTemplate('newPage', '<span class="newPageButton">改ページ</span>');
    Froalaeditor.DefineIcon('newPage', { template: 'newPage' });
    Froalaeditor.RegisterCommand('newPage', {
        title: '改ページの挿入',
        undo: true,
        focus: true,
        refreshOnCallback: false,
        callback: function () {
            const newPageButton = document.getElementById('newPage-1');
            if (newPageButton) {
                newPageButton.addEventListener('click', () => {
                    froalaEditor.html.insert("<p class='newPage' style='page-break-after:always;'/>");
                }, false);
            }
        }
    });


    //tableセルの余白設定
    Froalaeditor.DefineIconTemplate('cellPadding', `<img src=${IconPadding} class="cellPaddingIcon"/>`);
    Froalaeditor.DefineIcon('cellPadding', { template: 'cellPadding' });
    Froalaeditor.RegisterCommand('cellPadding', {
        title: 'セルの余白',
        undo: false,
        focus: true,
        refreshOnCallback: false,
        callback: function () {
            froalaEditor.popups.isVisible("cellPadding.popup") ? (froalaEditor.$el.find(".fr-marker").length && (froalaEditor.events.disableBlur(), froalaEditor.selection.restore()), froalaEditor.popups.hide("cellPadding.popup")) : froalaEditor.cellPadding.showCellPaddingPopup();
        }
    });

    Froalaeditor.RegisterCommand("customCellPadding", {
        undo: false,
        callback: function () {
            froalaEditor.cellPadding.customCellPadding();
        }
    });

    Froalaeditor.DefineIcon("cellPaddingBack", { NAME: "arrow-left", SVG_KEY: "back" });
    Froalaeditor.RegisterCommand("cellPaddingBack", {
        title: "Back",
        undo: false,
        focus: false,
        back: true,
        callback: function () {
            froalaEditor.cellPadding.back();
        },
        refresh: function refresh(e) {
            0 !== froalaEditor.table.selectedCells().length || froalaEditor.opts.toolbarInline ? (e.removeClass("fr-hidden"), e.next(".fr-separator").removeClass("fr-hidden")) : (e.addClass("fr-hidden"), e.next(".fr-separator").addClass("fr-hidden"));
        }
    });

    const events = {
        events : {
            'image.loaded': function () {
                setImageSize()
            },
            'image.resizeEnd': function () {
                setImageSize()
            },
            'table.resized': function (table) {
                resetMargin()
            },
            'commands.after': function (cmd) {
                const tableCmds = ['tableCells', 'tableColumns'];
                if (tableCmds.indexOf(cmd) > 0) {
                    resetMargin()
                }
            },
            'blur':  function() {
                const html = document.getElementsByClassName('fr-element fr-view')[0].innerHTML;
                dispatch(PressReleaseActionCreators.setPressReleaseDetails.request({body: {html, css: "", style:[], component:[]}}));
            },
            'save.before': function() {
                const html = document.getElementsByClassName('fr-element fr-view')[0].innerHTML;
                const component: any = [];
                const style = [];
                dispatch(PressReleaseActionCreators.setPressReleaseDetails.request({ body: { html, css: "", style, component }}));

                if (path.search('/create') != -1) {
                    // 新規作成の自動保存
                    //dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ name: "未タイトル", isDiff: false, isReview: false, isReviewRequest: false }));
                } else if (path.search('/edit') != -1) {
                    if (saveButton.length > 0 && saveButton[0].tabIndex == 0) {
                        dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff: false, isReview: false, isReviewRequest: false }));
                    }
                } else if (path.search('/review') != -1) {
                    if (saveButton.length > 0 && saveButton[0].tabIndex == 0) {
                        dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff: false, isReview: true, isReviewRequest: false }));
                    }
                } else if (path.search('/diff') != -1) {
                    if (saveButton.length > 0 && saveButton[0].tabIndex == 0) {
                        dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff: true, isReview: false, isReviewRequest: false }));
                    }
                }
            },
            'commands.mousedown': function (btn) {
                if (btn[0].classList.contains('fr-svg' && 'fr-delete-img')) {
                    setId(btn[0].previousElementSibling.dataset.id);
                }
            },
            //画像一覧のモーダルを開き直すのに必要
            'imageManager.error': function (error, response) {
                setErrorCode(error.code);
            },
            // 一度編集してから一定時間経過で編集者情報を削除するのに必要
            'contentChanged': function () {
                clearTimeout(logoutTimer);

                if (path.includes("/edit")) {
                    const param = path.split("/");
                    const pressId = param[4];

                    logoutTimer = setTimeout(() => {
                        setAlert(false);
                        dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseEditorData.request(pressId));

                        const activeElement: any = document.activeElement;
                        if (activeElement) {
                            activeElement.blur();
                        }
                    }, 600000);
                }
            },
            'paste.afterCleanup': function (html) {
                const tempEl = document.createElement('div');
                tempEl.innerHTML = html;
                const imgNodeList: NodeListOf<HTMLImageElement> = tempEl.querySelectorAll('img');

                if (imgNodeList.length > 0){
                    setAlert(true);
                }
            }
        }
    };

    // pdf生成で画像の高さが必要なため設定する
    const setImageSize = () => {
        const html:any = document.getElementsByClassName('fr-element fr-view')[0];
        if (html) {
            const imgs:any = html.querySelectorAll(".header img, .footer img");
            [...imgs].forEach(item => {
                item.style.width = `${item.clientWidth}px`;
                item.style.height = `${item.clientHeight}px`;
            });
        }
    };

    // widthが100%でもmarginの設定が残ってしまう時があるため設定する
    const resetMargin = () => {
        const html:any = document.getElementsByClassName('fr-element fr-view')[0];
        const table:HTMLElement[] = html.querySelectorAll("table");
        [...table].forEach(item => {
            if (item.style.width === '100%') {
                if (item.style.marginLeft || item.style.marginRight) {
                    item.style.marginLeft = '';
                    item.style.marginRight = '';
                }
            }
        });
    };

    // エディタにfocusしたらRealtimeDatabaseにeditor情報を書込みに行く
    const handlePostEditorData = () => {
        if (pressRelease.pageType === "PAGE_EDIT") {
            dispatch(PressReleaseFirebaseActionCreators.postPressReleaseEditorData.request());
        }
    };

    return(
        <div id="appEditor" style={{width: "100%"}} onFocus={handlePostEditorData}>
            {alert &&
                <Alert variant="filled" severity="error" title="画像をアップロードしてください" onClose={() => setAlert(false)}>
                    貼り付けた画像は、正しく表示されない恐れがあります。<br/>
                    画像をアップロードしてください。<br/>
                </Alert>
            }
            <FroalaEditor
                model={model}
                onModelChange={handleModelChange}
                onManualControllerReady={handleManualController}
                tag='textarea'
                config={{
                    key: "9KH3cC4B3E3C3D2D1zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A14A19B7B5C6A2A1 ==",
                    theme: 'dark',
                    toolbarSticky: false,
                    attribution: false,
                    charCounterCount: false,
                    colorsHEXInput: false,
                    placeholderText: "",
                    language: 'ja',
                    height: window.innerHeight - 120,
                    fontFamilySelection: true,
                    fontSizeSelection: true,
                    fontSizeUnit: 'pt',
                    fontSizeDefaultSelection: '11pt',
                    fontSize: ['8','9', '10','11', '12', '14','16', '18','20', '24','30', '36','48','60', '72','96'],
                    imageUploadURL: `${API_URL}groups/${groupSelectedId}/image/`,
                    imageUploadParams: {
                        name: "IMG",
                    },
                    imageManagerLoadURL: `${API_URL}groups/${groupSelectedId}/image/?limit=100&offset=0`,
                    imageManagerToggleTags: false,
                    imageManagerDeleteMethod: 'DELETE',
                    requestHeaders: {
                        "Authorization": store.get('token') && `Token ${store.get('token')}`
                    },
                    requestWithCORS: false,
                    imageEditButtons:['imageReplace', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageAndTextStyle', 'imageAlt', 'imageSize'],
                    saveInterval: 30000,
                    tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'cellPadding'],
                    tableStyles: {
                        'fr-alternate-rows': '縞模様(行)',
                        'fr-dashed-borders': '罫線を全て破線にする',
                        'fr-none-border': '罫線を全て削除する',
                    },
                    paragraphStyles: {
                        'fr-text-gray': 'テキストの色をグレーにする',
                        'fr-text-bordered': 'テキストの上下に水平線を引く',
                        'fr-text-spaced': '文字の間隔を空ける',
                        'fr-text-uppercase': '大文字にする'
                    },
                    fontFamily:{
                        '游ゴシック体, Yu Gothic, sans-serif': '游ゴシック体',
                        '游明朝体, Yu Mincho, serif': '游明朝体',
                        "'Kosugi', sans-serif": 'Kosugi',
                        "'Kosugi Maru', sans-serif": 'Kosugi Maru',
                        "'Sawarabi Gothic', sans-serif": 'さわらびゴシック',
                        "'Sawarabi Mincho', sans-serif": 'さわらび明朝',
                        "'Noto Sans JP', sans-serif": 'Noto Sans JP',
                        "'Noto Serif JP', serif": 'Noto Serif JP',
                        "'M PLUS 1p', sans-serif": 'M PLUS 1p',
                        "'M PLUS Rounded 1c', sans-serif": 'M PLUS Rounded 1c',
                        'Courier, sans-serif': 'Courier',
                        'Georgia, serif': 'Georgia',
                        'Helvetica, Arial, sans-serif': 'Helvetica',
                        'Impact, Charcoal, sans-serif': 'Impact',
                        'Lato, sans-serif': 'Lato',
                        'Montserrat, sans-serif': 'Montserrat',
                        'Oswald, sans-serif': 'Oswald',
                        'sans-serif': 'sans-serif',
                        'Tahoma, Geneva, sans-serif': 'Tahoma',
                        "'Times New Roman', Times, serif": 'Times New Roman',
                        'Verdana, Geneva, sans-serif': 'Verdana',
                    },
                    toolbarButtonsXS:{
                        'moreText': {
                            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting'],
                            'buttonsVisible': 0
                        },
                        'moreParagraph': {
                            'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeblockColor', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                            'buttonsVisible': 0
                        },
                        'moreRich': {
                            'buttons': ['insertImage', 'blockButton',  'insertColumn', /*'insertVideo',*/ ,'PDFButton', 'newPage', 'insertLink', 'insertTable', 'insertHR'],
                            'buttonsVisible': 0
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    },
                    toolbarButtonsSM:{
                        'moreText': {
                            'buttons': ['bold', 'italic', "underline", 'fontFamily', 'fontSize', 'textColor', 'strikeThrough', 'backgroundColor', 'clearFormatting'],
                            'buttonsVisible': 6
                        },
                        'moreParagraph': {
                            'buttons': ['codeblockColor', 'alignLeft', 'alignCenter', 'alignRight', 'paragraphFormat', 'paragraphStyle', 'alignJustify', 'formatOL', 'formatUL', 'lineHeight', 'outdent', 'indent', 'quote'],
                            'buttonsVisible': 4
                        },
                        'moreRich': {
                            'buttons': ['insertImage', 'blockButton',  'insertColumn', /*'insertVideo',*/ ,'PDFButton', 'newPage', 'insertLink', 'insertTable', 'insertHR'],
                            'buttonsVisible': 3
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    },
                    toolbarButtons:{
                        'moreText': {
                            'buttons': ['bold', 'italic', "underline", 'fontFamily', 'fontSize', 'textColor', 'strikeThrough', 'backgroundColor', 'clearFormatting'],
                            'buttonsVisible': 6
                        },
                        'moreParagraph': {
                            'buttons': ['codeblockColor','alignLeft', 'alignCenter', 'alignRight', 'paragraphFormat', 'paragraphStyle', 'alignJustify', 'formatOL', 'formatUL', 'lineHeight', 'outdent', 'indent', 'quote'],
                            'buttonsVisible': 4
                        },
                        'moreRich': {
                            'buttons': ['insertImage', 'blockButton',  'insertColumn', /*'insertVideo',*/ ,'PDFButton', 'newPage', 'insertLink', 'insertTable', 'insertHR'],
                            'buttonsVisible': 3
                        },
                        'moreMisc': {
                            'buttons': ['undo', 'redo'],
                            'align': 'right',
                            'buttonsVisible': 2
                        }
                    },
                    ...events
                }}
            />
        </div>
    );
}

export default FroalaEditorComponent;
