import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";

// component
import { Button, Menu, MenuItem, Typography, InputLabel, Select, FormControl, Paper, Box } from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
import PreviewDialog from '../PreviewDialog';
import FixedPhrase from '../FixedPhrase';
import PressKit from "./PressKit";
import File from "./File";
import TestSendDialog from "../TestSendDialog";
import Greeting from "./Greeting";

// style
import style from "./mail.module.scss";

// model
import { ReserveCustomModel } from "../../../../../model/PressReleaseReserveModel";

// actions
import * as ActionCreators from "../../../../../actions/PressReleaseReserve/ActionCreator";

// state
import { RootState } from "../../../../../reducers";

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const companySelector = (state: RootState) => state.company;
const memberSelector = (state: RootState) => state.member;

interface ContentsProps {
	item: ReserveCustomModel,
	isLocked: boolean,
}

const useStyles = makeStyles({
	address: {
		lineHeight: "30px"
	},
	headerAction: {
		margin: "5px 0 0 auto"
	},
	block: {
		width: "100%",
		display: "flex",
	},
	action: {
		marginLeft: 10
	},
	text: {
		padding: 10,
		minHeight: 200
	},
	textareaField: {
		paddingTop: 20,
		minHeight: 200
	},
	panelRoot: {
		boxShadow: 'none',
		border: '1px solid #e2e9e5',
	},
	mailBody: {
		display: "flex",
		alignItems: 'center',
	},
	greeting: {
		padding: '0px 10px;',
	},
	sampleButton: {
		marginLeft: "auto",
		marginBottom: 10
	},
	fileBox: {
		width: '100%',
	}
});


const Main: React.FC<ContentsProps> = ({ item, isLocked }) => {

	const classes = useStyles();
	const dispatch = useDispatch();
	const pressRelease = useSelector(pressReleaseSelector);
	const reserve = useSelector(pressReleaseReserveSelector);
	const company = useSelector(companySelector);
	const member = useSelector(memberSelector);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [menuTarget, setMenuTarget] = React.useState<null | string>('from_email');
	const [anchorTempleMenu, setAnchorTempleMenu] = React.useState<null | HTMLElement>(null);
	const [preview, setPreview] = React.useState<boolean>(false);
	const [testSend, setTestSend] = React.useState<boolean>(false);

	const active: number = reserve.active;
	const auto: any = reserve.data.auto;
	const custom: Array<any> = reserve.data.custom;
	const isCustomMediaActive = active > 0 ? true : false;
	const targetMedia = isCustomMediaActive ? custom.filter(media => media.id === active)[0] : auto;
	const isTargetMediaChecked = targetMedia.is_send === true;
	const testSendButtonEnable = isCustomMediaActive && isTargetMediaChecked && !isLocked;

	const isOnlyPublisher = (item.publisher && !!item.name === false && !!item.last_name === false) ? true : false;

	useEffect(() => {
		// // setCategoryDialog(true)
		// //　初期値
		if (reserve.data.fetched === true && company.email) {
			if (!item.from_email) {
				dispatch(ActionCreators.setPressReleaseReserveCustom.request({ id: item.id, from_email: company.email }));
			}
		}
	}, [reserve.data.fetched, company]);

	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, target: string) => {
		setAnchorEl(event.currentTarget);
		setMenuTarget(target)
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleOpenTemplateMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		handleCloseMenu()
		setAnchorTempleMenu(event.currentTarget);
	};

	const handleCopyClick = () => {
		if (menuTarget) dispatch(ActionCreators.setPressReleaseReserveCopy.request({ key: menuTarget, value: item[menuTarget] }));
		setAnchorEl(null);
	};

	const handleEmailChange = (event) => {
		const target: any = event.target
		dispatch(ActionCreators.setPressReleaseReserveCustom.request({ id: item.id, [target.name]: target.value }));
	};

	let hasEdit = pressRelease.role.edit;
	const { isReserveEdit } = pressRelease.detail;

	return (
		<div className={style.root}>
			<Paper classes={{ root: classes.panelRoot }}>
				<ul className={style.form}>
					<li>
						<InputLabel>宛先</InputLabel>
						<Box className={classes.block}>
							<Typography variant="h5" className={classes.address}>
								{isOnlyPublisher ? item.publisher : <>{item.name}&nbsp;{item.last_name}&nbsp;{item.first_name}</>}
							</Typography>
							<Box className={classes.headerAction}>
								<Button
									className={classes.action}
									size="small"
									color="primary"
									variant="outlined"
									onClick={() => setTestSend(true)}
									disabled={!testSendButtonEnable}
								>
									テスト配信
								</Button>
								<Button
									className={classes.action}
									size={"small"}
									color="primary"
									variant="outlined"
									onClick={() => setPreview(true)}>プレビュー</Button>
							</Box>
						</Box>
					</li>
					<li>
						<InputLabel>送信元</InputLabel>
						<FormControl variant="outlined" className={classes.block}>
							<Select
								value={item.from_email || company.email}
								onChange={handleEmailChange}
								name="from_email"
								disabled={!hasEdit || !isReserveEdit || isLocked}>
								<MenuItem value={company.email}>{company.email}<span className={style.name}>企業アドレス</span></MenuItem>
								{member.list.results.map((user, key) =>
									<MenuItem value={user.email} key={key}>
										{user.email}
										<span className={style.name}>{user.last_name}{user.first_name}</span>
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</li>
					<li>
						<InputLabel>件名</InputLabel>
						<TextField multiline
							className={classes.block}
							variant="outlined"
							type="textarea"
							name="mail_title"
							placeholder="〇〇のお知らせ"
							onChange={handleEmailChange}
							value={item.mail_title || ""}
							helperText={item.mail_title ? "" : "入力してください"}
							disabled={!hasEdit || !isReserveEdit || isLocked}
						/>
					</li>
					<li>
						<Box className={classes.mailBody}>
							<InputLabel>本文</InputLabel>
							<Button variant="outlined" color="primary" size={"small"} onClick={handleOpenTemplateMenu} className={classes.sampleButton}>
								定型文から入力
							</Button>
						</Box>
						<div className={style.main}>
							<Typography variant={"body1"} className={classes.greeting}>
								<Greeting item={item} />
							</Typography>
							<TextField multiline
								className={classes.text}
								type="textarea"
								name="mail_body"
								placeholder="本文"
								onChange={handleEmailChange}
								value={item.mail_body || ""}
								InputProps={{
									classes: {
										input: classes.textareaField,
									},
								}}
								helperText={item.mail_body ? "" : "入力してください"}
								disabled={!hasEdit || !isReserveEdit || isLocked}
							/>
							<PressKit
								date={reserve.data.released_at}
								prefix={company.prefix} />
						</div>
					</li>
					<li>
						<InputLabel>添付ファイル</InputLabel>
						<Box className={classes.fileBox}>
							<File />
						</Box>
					</li>
				</ul>
			</Paper>
			<PreviewDialog isAuto={false} isOpen={preview} closeHandle={() => setPreview(false)} id={item.id} />
			<TestSendDialog isOpen={testSend} closeHandle={() => setTestSend(false)} />
			<FixedPhrase handleClose={() => setAnchorTempleMenu(null)} mailBody={item.mail_body} anchorTempleMenu={anchorTempleMenu} />
		</div>

	);
}

export default Main;

