import React from "react";
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// model
import { PressReleaseBodyModel } from "../../../model/PressReleaseModel";
import {makeStyles} from "@material-ui/core/styles";

//CSS
import 'froala-editor/css/froala_editor.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/css/froala_editor.pkgd.css';
import 'froala-editor/css/plugins.pkgd.css';
import 'froala-editor/css/third_party/embedly.css';
import 'froala-editor/css/third_party/font_awesome.css';
import 'froala-editor/css/third_party/spell_checker.css';
import './editor_plugins_style.css';

interface IProps {
    body: PressReleaseBodyModel ,
    isCard?: boolean,
    src?: string
}

const useStyles = makeStyles({
    root: {
        height: "calc( 100vh - 68px );",
        maxWidth: 780,
        margin: "auto",
        width: "100%",
        fontSize: 14,
        color: "#333",
        '& header': {
            border: "none"
        },
        '& header::before': {
            content: 'none',
        },
        '& footer': {
            border: "none"
        },
        '& footer::before': {
            content: 'none',
        },
    }
});

const Preview: React.FC<IProps> = ({ body }) => {

    const classes = useStyles();
    const { html, css }  = body

    return (
        <div className={classes.root}>
            <div className="froala_editor">
                <div className="fr-box fr-ltr fr-basic fr-top">
                    <div className="fr-wrapper">
                        <div className="fr-element fr-view">
                            <div dangerouslySetInnerHTML={{__html: `${html}`}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preview;
