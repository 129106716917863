import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//style
import style from "./history.module.scss";
//component
import Avatar from "../../../../Common/Avatar";
import DateTime from "../../../../Common/DateTime";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import PreviewDialog from "../../../../Common/PreviewDialog";
import Pagination from "../../../../Common/Pagination";
//img
import ImageEllipsis from "../../../../../assets/icon_ellipsis.svg";
//action
import * as PressReleaseActionCreators from "../../../../../actions/PressRelease/ActionCreator";
//model
import { PressReleaseHistoryListModel } from "../../../../../model/PressReleaseModel";
//config
import { PRESS_RELEASE_HISTORY_LIST_DISPLAY_COUNT } from "../../../../../config/list_display_count";
// state
import { RootState } from "../../../../../reducers";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;
const firebaseSelector = (state: RootState) => state.firebase;

interface IProps {
    pageType: string,
}

const History: React.FC<IProps> = ({ pageType }) => {
    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(PressReleaseActionCreators.getPressReleaseHistoryList.request({ offset: page }))
    }, [page])

    return (
        <>
            <ul className={style.list}>
                {pressRelease.history.list.results.map((row, index) => (
                    <HistoryItem key={index} item={row} pageType={pageType} />
                ))}
            </ul>
            {pressRelease.history.list.results.length > 0 && <div className={style.pagination}>
                <Pagination
                    activePage={page}
                    totalCount={pressRelease.history.list.count}
                    handlePageChange={(n: number) => setPage(n)}
                    displayCount={PRESS_RELEASE_HISTORY_LIST_DISPLAY_COUNT}
                />
            </div>}
        </>
    );
}


interface HistoryItemProps {
    item: PressReleaseHistoryListModel,
    pageType: string,
}

const HistoryItem: React.FC<HistoryItemProps> = ({ item, pageType }) => {

    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const me = useSelector(meSelector);
    const accessData = useSelector(firebaseSelector);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [previewDialog, setPreviewDialog] = React.useState(false);
    const [convertButton, setConvertButton] = React.useState<boolean>(false);
    const [isLocked, setIsLocked] = React.useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setPreviewDialog(false)
    };

    const handleOpenPreviewDialog = (id) => {
        setAnchorEl(null);
        setPreviewDialog(true)
        dispatch(PressReleaseActionCreators.getPressReleaseHistoryDetails.request({ press_id: pressRelease.detail.press_id, history_id: id }));
    };

    const handleRevert = () => {
        handleClose()
        dispatch(PressReleaseActionCreators.postPressReleaseRevert.request());
    }

    //バージョン戻しボタンの表示・非表示に必要
    useEffect(() => {
        if (pressRelease.detail.history.id !== item.id) {
            if (pageType === 'PAGE_DIFF') {
                setConvertButton(true);
            } else if (pageType === 'PAGE_EDIT') {
                if (pressRelease.detail.status === 0 || pressRelease.detail.status === 2) {
                    setConvertButton(true);
                }
            } else {
                setConvertButton(false);
            }
        } else {
            setConvertButton(false);
        }
    }, []);

    useEffect(() => {
        const data = accessData.data[pressRelease.detail.press_id];

        if (data && data.edit && data.edit.editor) {
            if (data.edit.editor === me.uuid) {
                setIsLocked(false);
            } else {
                setIsLocked(true);
            }
        } else {
            setIsLocked(false);
        }
    }, [accessData.data[pressRelease.detail.press_id]]);

    return (
        <li>
            <div className={style.body}>
                <div className={style.bodyImage}>
                    <Avatar src={item.user.img} name={item.user.last_name} active={true} size="small" color={item.user.color_cd} />
                </div>
                <div className={style.bodyContent}>
                    <p className={style.info}>
                        {item.user.last_name}{item.user.first_name}
                        <span className={style.datetime}><DateTime datetime={item.created_at} /></span></p>
                    <p>保存しました</p>
                </div>
                <div className={style.actions}>
                    <IconButton onClick={handleClick} className={style.iconMenu}>
                        <img src={ImageEllipsis} />
                    </IconButton>
                </div>
            </div>
            <Menu
                elevation={1}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={style.popupMenu}
            >
                <MenuItem onClick={() => handleOpenPreviewDialog(item.id)}>
                    このバージョンを確認
                </MenuItem>
                <PreviewDialog isHistory={true} isOpen={previewDialog} closeHandle={() => setPreviewDialog(false)} handleAction={handleRevert} handleActionText={(convertButton && !isLocked) ? "このバージョンに戻す" : ""} />
            </Menu>
        </li>
    );
}

export default History;
