import { put, takeLatest, select, call } from 'redux-saga/effects'
import firebase from 'firebase/app';
import { db } from '../configureFirebase';
import BriefingActionType from '../actions/Briefing/ActionType';
import * as ActionCreators from '../actions/Briefing/ActionCreator';
import * as PressReleaseActionCreators from '../actions/PressRelease/ActionCreator';
import { selectGroup, selectMe, selectRouter, selectBriefing } from './selector';
import { push } from 'connected-react-router';
import { fetchDelete, fetchGet, fetchPost, fetchPostForm } from './fetch';
import _ from 'lodash';
import { BRIEFING_PAGE_TYPES as PAGE_TYPES, QUERY_TYPES } from '../config/briefing';
import { BriefingAnswerBodyModel, BriefingAnswerContentModel } from '../model/BriefingModel';
import { stopSubmit } from 'redux-form';

const getDocRef = (id?: string) => {
    if (id) return db.collection('Briefing').doc(id);
    else return db.collection('Briefing').doc();
}

function* getBriefingDetail(action: ReturnType<typeof ActionCreators.getBriefingDetail.request>) {
    try {
        const { id } = action.payload;
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/briefing/${id}/`);
        if (data && !error) {
            yield put(ActionCreators.getBriefingDetail.success(Object.assign({ ...data })));
        } else {
            yield put(push("/briefing/"));
        }
    } catch (e) {
        yield put(ActionCreators.getBriefingDetail.failure(e));
    }
}

function* getBriefingDetailAnonymous(action: ReturnType<typeof ActionCreators.getBriefingDetailAnonymous.request>) {
    try {
        const { id, pageType, getError } = action.payload;
        const docRef = getDocRef(id);
        const group = yield select(selectGroup);
        const data = yield docRef.get().then(function (doc) {
            return doc.data();
        });
        if (data) {
            yield put(ActionCreators.getBriefingDetail.success(Object.assign({
                id,
                body: {
                    ...data,
                    updated_at: data.updated_at.toDate(),
                    created_at: data.created_at.toDate(),
                },
            })));
        } else if (pageType == PAGE_TYPES.ANONYMOUS_ANSWER && getError) {
            getError('エラーが発生しました');
        } else if (pageType == PAGE_TYPES.ANSWER) {
            yield put(push(`/briefing/${group.selectedId}/received/`));
        }
    } catch (e) {
        yield put(ActionCreators.getBriefingDetailAnonymous.failure(e));
    }
}

function* postBriefingContent(action: ReturnType<typeof ActionCreators.postBriefingContent.request>) {
    try {
        const request = action.payload;
        const group = yield select(selectGroup);
        const briefing = yield select(selectBriefing);
        const { queries } = briefing.detail.body;
        const field = {
            name: request.name,
            queries: queries,
        }
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/briefing`, field);
        if (data && !error) {
            yield put(ActionCreators.postBriefingContent.success());
            yield put(push(`/briefing/${group.selectedId}/edit/${data.id}`));
        }
    } catch (e) {
        yield put(ActionCreators.postBriefingContent.failure(e));
    }
}

function* patchBriefingContent(action: ReturnType<typeof ActionCreators.patchBriefingContent.request>) {
    const { id, fields, success } = action.payload;
    try {
        const me = yield select(selectMe);
        const briefing = yield select(selectBriefing);
        const { queries, updated_by } = briefing.detail.body;
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        updated_by.push(me.uuid);
        const field = {
            queries: queries,
            ...fields,
            updated_at: timestamp,
            updated_by: updated_by.filter((x, i) => updated_by.indexOf(x) === i),
        }
        const err = yield getDocRef(id).update(field).catch(err => err);
        yield put(ActionCreators.patchBriefingContent.success());
        if (!err) {
            yield put(ActionCreators.getBriefingDetail.request({ id }));
            if (success) success();
        }
    } catch (e) {
        yield put(ActionCreators.patchBriefingContent.failure(e))
    }
}

function* postBriefingAnswer(action: ReturnType<typeof ActionCreators.postBriefingAnswer.request>) {
    const { id: docId, answerId, is_temp } = action.payload
    try {
        const group = yield select(selectGroup);
        const me = yield select(selectMe);
        const { detail, email } = yield select(selectBriefing);
        const router = yield select(selectRouter);
        const formData = new FormData();
        formData.append('is_temp', String(is_temp));
        formData.append('email', email);
        if (answerId) formData.append('answerId', answerId);
        detail.body.queries.map((query, index) => {
            if (query.type === QUERY_TYPES.IMAGE) {
                if (query.file && query.file.__proto__ === File.prototype) {
                    formData.append(index, query.file);
                    formData.append(`${index}_question`, query.question);
                } else {
                    formData.append(index, JSON.stringify(query));
                }
            } else {
                formData.append(index, JSON.stringify(query));
            }
        })
        const [data, error] = yield call(fetchPostForm, `briefing_answer/${docId}`, formData);
        if (data && !error) {
            if (!router.location.search) {
                if (me.uuid) {
                    yield put(push(`/briefing/${group.selectedId}/answer/${docId}?i=${data.id}`));
                } else {
                    yield put(push(`/form/${docId}?i=${data.id}`));
                }
            }
        }
        yield put(ActionCreators.postBriefingAnswer.success());
    } catch (e) {
        yield put(ActionCreators.postBriefingAnswer.failure(e));
    }
}

function* getBriefingPublicInfo(action: ReturnType<typeof ActionCreators.getBriefingPublicInfo.request>) {
    try {
        const request = action.payload;
        const docRef = getDocRef(request.id);
        const answers: BriefingAnswerBodyModel[] = [];
        yield docRef.collection('answers').where('is_temp', '==', false).orderBy('updated_at', 'desc').get()
            .then(function (querySnapshot) {
                querySnapshot.docs.forEach(doc => {
                    const { is_temp, queries, updated_at, updated_by, created_by } = doc.data();
                    answers.push({
                        is_temp, queries, updated_by, created_by, updated_at: updated_at.toDate(),
                    });
                });
            });
        yield put(ActionCreators.getBriefingPublicInfo.success({ answers }));
    } catch (e) {
        yield put(ActionCreators.getBriefingPublicInfo.failure(e));
    }
}

function* postBriefingSendMail(action: ReturnType<typeof ActionCreators.postBriefingSendMail.request>) {
    try {
        const me = yield select(selectMe);
        const group = yield select(selectGroup);
        const { detail, email } = yield select(selectBriefing);

        const { members, title, answerId, postSuccess, postError } = action.payload;
        const docId = detail.id;
        const url = `groups/${group.selectedId}/briefing_mail`;

        const payload = {
            doc_id: docId,
            from_name: `${me.last_name} ${me.first_name}`,
            from_email: me.email,
            members: members,
            title: title,
            answer_id: answerId,
            group_id: detail.body.groupId,
        }
        const [data, error] = yield call(fetchPost, url, payload);
        if (data && !error) {
            yield put(ActionCreators.postBriefingSendMail.success());
            yield put(ActionCreators.getBriefingQty.request({ groupId: detail.body.groupId }));
            yield put(ActionCreators.getBriefingDetail.request({ id: docId }));
            yield put(ActionCreators.getBriefingAnswerInfo.request({ docId, email }));
            postSuccess && postSuccess();
        } else if (postError) {
            postError(error.error);
        }
    } catch (e) {
        yield put(ActionCreators.postBriefingSendMail.failure(e));
    }
}

function* getBriefingAnswerInfo(action: ReturnType<typeof ActionCreators.getBriefingAnswerInfo.request>) {
    try {
        const { docId, answerId, email } = action.payload;
        const group = yield select(selectGroup);
        const list: BriefingAnswerContentModel[] = [];
        const router = yield select(selectRouter);

        if (answerId) {
            const answerRef = getDocRef(docId).collection('answers').doc(answerId);
            const data = yield answerRef.get().then(function (doc) {
                return doc.data();
            });
            if (data) list.push({ id: answerId, body: data });
            else yield put(push(router.location.pathname));
        } else {
            const answerRef = getDocRef(docId).collection('answers').where('created_by', '==', email);
            yield answerRef.get().then(function (querySnapshot) {
                querySnapshot.forEach(doc => {
                    const { is_temp, created_by, updated_by, updated_at, queries } = doc.data();
                    list.push({
                        id: doc.id,
                        body: {
                            is_temp, created_by, updated_by, queries, updated_at: updated_at.toDate(),
                        }
                    });
                });
            });
        }
        if (list.length > 0) {
            if (!answerId) {
                if (group.selectedId) {
                    yield put(push(`/briefing/${group.selectedId}/answer/${docId}?i=${list[0].id}`));
                } else {
                    yield put(push(`/form/${docId}?i=${list[0].id}`));
                }
            }
            yield put(ActionCreators.getBriefingAnswerInfo.success(Object.assign({}, list[0])));
            yield put(ActionCreators.setBriefingQueries.request(list[0].body.queries));
        }
    } catch (e) {
        yield put(ActionCreators.getBriefingAnswerInfo.failure(e));
    }
}

function* deleteBriefingContent(action: ReturnType<typeof ActionCreators.deleteBriefingContent.request>) {
    try {
        const { id, success } = action.payload;
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchDelete, `groups/${group.selectedId}/briefing/${id}/`);
        if (data && !error) {
            yield put(ActionCreators.deleteBriefingContent.success());
            if (success) success();
        }
    } catch (e) {
        yield put(ActionCreators.deleteBriefingContent.failure(e));
    }
}

function* getBriefingQty(action: ReturnType<typeof ActionCreators.getBriefingQty.request>) {
    try {
        const request = action.payload;
        const [data, error] = yield call(fetchGet, `groups/${request.groupId}/briefing_qty/`);
        if (data && !error) {
            yield put(ActionCreators.getBriefingQty.success(data));
        }
    } catch (e) {
        yield put(ActionCreators.getBriefingQty.failure(e));
    }
}

function* makeHtmlFromBriefing(action: ReturnType<typeof ActionCreators.makeHtmlFromBriefing.request>) {
    try {
        const request = action.payload;
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/briefing_html`,
            { title: request.body.name, queries: request.body.queries }
        );
        if (data && !error) {
            yield put(PressReleaseActionCreators.setPressReleaseTemplate.request(data));
            yield put(push(`/press_release/${group.selectedId}/create/`));
        }
        yield put(ActionCreators.makeHtmlFromBriefing.success());
    } catch (e) {
        yield put(ActionCreators.makeHtmlFromBriefing.failure(e));
    }
}

function* postBriefingAnswerConfirm(action: ReturnType<typeof ActionCreators.postBriefingAnswerConfirm.request>) {
    try {
        const { id, postError, email } = action.payload;
        const [data, error] = yield call(fetchPost, `briefing_auth/${id}`, { email });
        if (data && !error) {
            yield put(ActionCreators.getBriefingDetailAnonymous.request({ id }));
            yield put(ActionCreators.postBriefingAnswerConfirm.success(email));
        } else {
            if (postError) postError(error[0]);
            yield put(stopSubmit('BRIEFING_CONFIRM', Object.assign(error, { _error: error[0] })));
        }
    } catch (e) {
        yield put(ActionCreators.postBriefingAnswerConfirm.failure(e));
    }
}
const BriefingSaga = [
    takeLatest(BriefingActionType.GET_BRIEFING_DETAIL_REQUEST, getBriefingDetail),
    takeLatest(BriefingActionType.GET_BRIEFING_DETAIL_ANONYMOUS_REQUEST, getBriefingDetailAnonymous),
    takeLatest(BriefingActionType.POST_BRIEFING_CONTENT_REQUEST, postBriefingContent),
    takeLatest(BriefingActionType.PATCH_BRIEFING_CONTENT_REQUEST, patchBriefingContent),
    takeLatest(BriefingActionType.POST_BRIEFING_ANSWER_REQUEST, postBriefingAnswer),
    takeLatest(BriefingActionType.GET_BRIEFING_PUBLIC_INFO_REQUEST, getBriefingPublicInfo),
    takeLatest(BriefingActionType.POST_BRIEFING_SEND_MAIL_REQUEST, postBriefingSendMail),
    takeLatest(BriefingActionType.GET_BRIEFING_ANSWER_INFO_REQUEST, getBriefingAnswerInfo),
    takeLatest(BriefingActionType.DELETE_BRIEFING_CONTENT_REQUEST, deleteBriefingContent),
    takeLatest(BriefingActionType.GET_BRIEFING_QTY_REQUEST, getBriefingQty),
    takeLatest(BriefingActionType.MAKE_HTML_FROM_BRIEFING_REQUEST, makeHtmlFromBriefing),
    takeLatest(BriefingActionType.POST_BRIEFING_ANSWER_CONFIRM_REQUEST, postBriefingAnswerConfirm),
];

export default BriefingSaga;
