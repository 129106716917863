import * as ActionCreators from "../actions/OrderInfo/ActionCreator";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {fetchGet} from "./fetch";
import OrderInfoActionType from "../actions/OrderInfo/ActionType";
import { selectGroup } from "./selector";
import { OrderInfoListRequestModel } from "../model/OrderInfoModel";


function* getOrderInfo(action: ReturnType<typeof ActionCreators.getOrderInfoList.request>) {
    try {
        const group = yield select(selectGroup);
        const request:OrderInfoListRequestModel = action.payload;
		const offset = request.offset <= 1 ? 0 : (request.offset * 10) - 10
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/order_info/?offset=${offset}&limit=${request.limit || 10}`);
        if(data && !error){
            yield put(ActionCreators.getOrderInfoList.success(Object.assign({}, {offset: request.offset}, data)));
        }
    }catch(e){
        yield put(ActionCreators.getOrderInfoList.failure(e))
    }

}

const OrderInfoSaga = [
    takeLatest(OrderInfoActionType.GET_ORDER_INFO_LIST_REQUEST, getOrderInfo)
]

export default OrderInfoSaga;
