import React from "react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja";

import { Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import "../../Common/DatePicker/datepicker.module.scss"

import calendarImage from '../../../assets/icon_calendar_gray.svg';

const useStyles = makeStyles({
	error: {
		marginLeft: 10,
	},
	datePicker: {
		width: 140,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top 9px left 8px',
		backgroundSize: '17px auto',
		backgroundImage: `url(${calendarImage})`,
	},
	releaseDateStyle: {
		color: '#E95050',
	},
	body: {
		margin: '7px 10px 0px 0px',
		fontWeight: 'bold',
		color: '#6d7470',
		fontSize: 16
	},
	selectRoot : {
		fontWeight: 'bold',
		color: '#6d7470',
		fontSize: 16
	},
	select: {
		paddingRight: '20px !important'
	}
});

interface Props {
    datetime: Date
    handleChangeDatetime: (dt: Date) => void
	defaultDate: Date
}

const RadarDatePicker: React.FC<Props> = ({ datetime, handleChangeDatetime , defaultDate}) => {
	const classes = useStyles();

    const editDatetime = (event: React.ChangeEvent<{ value: any }>) => {
        const changedDatetime = new Date(event[0]);
        handleChangeDatetime(changedDatetime);
    };

    return (
        <>
            <Flatpickr
                date='true'
                value={defaultDate}
                className={classes.datePicker}
                options={{
                    defaultValue: {},
                    locale: Japanese,
                }}
                onChange={(e) => editDatetime(e)}
            />
            {!datetime && <Typography color={"error"} className={classes.error}>日時を選択してください。</Typography>}
        </>
    );
};

export default RadarDatePicker;
