import React from 'react';
import Title from '../Title'
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/editor/code/image01.png';
import Image02 from '../../../assets/help/editor/code/image02.png';
import Image03 from '../../../assets/help/editor/code/image03.png';

const Block = () => {
	return (
		<div className={style.box}>
			<Title title={"5. 見出し"}/>
			<Typography gutterBottom variant={"body1"}>
				メニューから、カラーパレットをひらきます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				カーソルを置いた行に背景色がつきます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				ゴミ箱からスタイルをリセットすることができます。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
		</div>
	);
};

export default Block
