import React from 'react';
import { useDispatch } from 'react-redux';

// component
import {
    makeStyles, Typography, Table, TableHead, TableRow, TableCell,
    TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@material-ui/core';
import TextField from '../../Field/TextFieldUnderline';
import RadioAnswer from '../Answer/Radio';
import ImageAnswer from '../Answer/Image';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// model
import { BriefingBodyModel, BriefingQueryModel } from '../../../model/BriefingModel';

// config
import { QUERY_TYPES } from '../../../config/briefing';

interface DialogProps {
    isOpen: boolean,
    body: BriefingBodyModel,
    id: string,
    handleClose,
}

const PreviewDialog: React.FC<DialogProps> = ({ isOpen, body, id, handleClose }) => {
    const classes = useStyles();
    return (
        <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.root }}>
            <DialogTitle>プレビュー</DialogTitle>
            <DialogContent>
                <AnswerTable body={body} id={id} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PreviewDialog;

interface Props {
    body: BriefingBodyModel,
    id: string,
}

const AnswerTable: React.FC<Props> = ({ body, id }) => {
    const classes = useStyles();

    return (
        <div className={classes.tableRoot}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellQuestion}>
                            <Typography variant='h6' color='primary'>Q</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellAnswer}>
                            <Typography variant='h6' color='primary'>A</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <AnswerTableBody body={body} />
            </Table>
        </div>
    );
}

const AnswerTableBody = ({ body }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <TableBody>
            {body.queries.map((query, index) => {
                const handleChangeText = (text: string) => {
                    const queries: BriefingQueryModel[] = [...body.queries];
                    queries[index].text = text;
                    dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
                }
                const handleChangeRadio = (id: number) => {
                    const queries: BriefingQueryModel[] = [...body.queries];
                    queries[index].selected = id;
                    dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
                }
                return (
                    <TableRow key={index}>
                        <TableCell className={classes.tableCellQuestion}>
                            <Typography variant='body1' className={classes.tableBodyText}>{query.question}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellAnswer}>
                            {query.type === QUERY_TYPES.TEXT &&
                                <TextField value={query.text} multiline handleChange={handleChangeText} />}
                            {query.type === QUERY_TYPES.RADIO &&
                                <RadioAnswer value={query.selected} choices={query.choices} handleChange={handleChangeRadio} />}
                            {query.type === QUERY_TYPES.IMAGE &&
                                <ImageAnswer file={query.file} body={body} index={index} />}
                        </TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
    );
}

const useStyles = makeStyles({
    root: {
        width: '70%',
        maxWidth: 800,
        minWidth: 400,
    },
    tableCellQuestion: {
        width: '50%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#f8fbf9',
    },
    tableCellAnswer: {
        width: '50%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    tableBodyText: {
        fontSize: 12,
    },
    tableRoot: {
        marginTop: 8,
    },
})
