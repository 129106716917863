import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PanelLayout from "../../components/Layout/Panel";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {history} from '../../configureStore';

interface Props extends RouteComponentProps<void> {}

const PassWordRegisteredPage: React.FC<Props> = () => {

	const classes = useStyles();

	const handleClick = () => {
		history.push('/sign_in/')
	}

	return (
		<PanelLayout>
			<Typography variant="h1" component="h2" gutterBottom>
				パスワードを再設定しました。
			</Typography>
			<Button
				variant="contained"
				size="large"
				color="primary"
				onClick={handleClick}
			>
				ログインする
			</Button>
		</PanelLayout>
	);
}

export default PassWordRegisteredPage;

const useStyles = makeStyles({
	toLoginPage: {
		fontSize: 16,
		fontWeight: "bold",
	}
})
