import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";

//action
import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";

import { RootState } from "../../../../reducers";

//model
import { PressKitFirestoreModel } from '../../../../model/PressKitModel'

//style
import style from '../dialog.module.scss'

const pressKitSelector = (state: RootState) => state.pressKit;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;

const PressKitDeleteDialog: React.FC = () => {

    const dispatch = useDispatch();
    const pressKit = useSelector(pressKitSelector);
    const firestorePressKits = useSelector(firestorePressKitsSelector);

    const targetPressKitId: string = pressKit.add.deleteTargetPressKitId;

    const getTargetPressKit = (targetPressKitId: string) => {
        const defaultTargetPressKit: PressKitFirestoreModel = {
            id: '',
            title: '',
            order: 0,
            isDelete: false,
            createdAt: undefined
        }

        const targetPressKit: PressKitFirestoreModel | undefined = firestorePressKits ?
                    firestorePressKits.find(pressKit => pressKit.id == targetPressKitId)
                    : defaultTargetPressKit

        if (targetPressKit == undefined) {
            return defaultTargetPressKit
        }
        return targetPressKit
    }

    const targetPressKit = getTargetPressKit(targetPressKitId)

    const handleClose = () => {
        dispatch(PressKitActionCreators.setPressKitDeleteDialog(false));
        dispatch(PressKitActionCreators.setPressKitDeleteTarget(''));
    };

    const handleDelete = () => {
        dispatch(PressKitActionCreators.logicalDeletePressKit.request(targetPressKitId))
        dispatch(PressKitActionCreators.setSelectedPressKit(''))
        dispatch(PressKitActionCreators.setPressKitDeleteDialog(false))
    }

    return (
        <Dialog open={pressKit.add.pressKitDeleteDialog} onClose={handleClose}>
            <DialogTitle className={style.header}>
                <p>以下のプレスキットを削除します。よろしいですか？</p>
            </DialogTitle>
            <DialogContent className={style.content}>
                <Typography>{targetPressKit.title}</Typography>
            </DialogContent>
            <DialogActions className={style.actions}>
                <Button onClick={handleClose} variant="outlined" size="large" color="primary">
                    キャンセル
                </Button>
                <Button onClick={handleDelete} className={style.button} variant='contained' size='large' color='primary' >
                    削除
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PressKitDeleteDialog
