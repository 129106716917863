import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import store from 'store';
import { push } from "connected-react-router";
import { PAGE_EDIT, PAGE_REVIEW } from "../../config/page_type";

// action
import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";

// components
import { createStyles, makeStyles, Theme, withTheme } from "@material-ui/core/styles";
import GEditor from "../../components/PressRelease/Review";
import Layout from "./Layout";

// utils
import { checkEditRole, checkReviewRole } from "../../util/checkAuthority";

// state
import { RootState } from "../../reducers";
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const routerSelector = (state: RootState) => state.router;

type PageProps = {} & RouteComponentProps<{ groupId: string, pressReleaseId: string }>;

const ReviewPage: React.FC<PageProps> = props => {
	const dispatch = useDispatch();
	const pressRelease = useSelector(pressReleaseSelector);
	const me = useSelector(meSelector);
	const group = useSelector(groupSelector);
	const router = useSelector(routerSelector);
	useEffect(() => {
		const { pressReleaseId } = props.match.params
		if (pressReleaseId) {
			store.set('path', `${router.location.pathname}${router.location.search}`);
			dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: pressReleaseId }));
		}
		dispatch(PressReleaseActionCreators.setPressReleasePageType.request(PAGE_REVIEW));
	}, []);

	// useEffect(()=> {
	// 	const {press_id, status}  = pressRelease.detail
	// 	if (group.selectedId && press_id && status) {
	// 		dispatch(push(createPressReleaseURL(group.selectedId, press_id, status)))
	// 	}
	// }, [group.selectedId, pressRelease.detail.status]);

	useEffect(() => {
		if (me.uuid && pressRelease.detail.fetched === true) {
			dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(checkEditRole(pressRelease, me)));
			dispatch(PressReleaseActionCreators.setPressReleaseReviewRole.request(checkReviewRole(pressRelease.detail.review.member, me)));
		}
	}, [me, pressRelease.detail, pressRelease.creators]);


	useEffect(() => {
		if (me.uuid && pressRelease.detail.fetched === true) {
			// 校閲権限チェック
			const hasReview = (pressRelease.detail.review.member || []).filter(member => member.user.uuid === me.uuid);
			if (hasReview.length && hasReview[0].history) {
				dispatch(PressReleaseActionCreators.getPressReleaseHistoryDetails.request({ press_id: pressRelease.detail.press_id, history_id: hasReview[0].history }));
			} else {
				dispatch(PressReleaseActionCreators.getPressReleaseHistoryDetails.request({ press_id: pressRelease.detail.press_id, history_id: pressRelease.detail.review.history }));
			}
		}
	}, [me.uuid, pressRelease.detail.fetched]);

	return (
		<Layout page={PAGE_REVIEW}>
			<GEditor />
		</Layout>
	);
}

export default withTheme(ReviewPage);

