import * as StatusConfig from "../config/status_type";

export const STATUS_LABEL = ['編集', `確認`, '配信設定', `確認`, '配信待ち', '配信済み'];
export const STATUS_SKIP_LABEL = ['編集', `確認  (スキップ)`, '配信設定', `確認 (スキップ)`, '配信待ち', '配信済み'];

// 表示用意 status
export const SetStatus = (status) => {

    let label = '';
    let color = "";
    let code = 0;

    switch (status) {
        case 0:
        case 2:
            label = `プレスリリース 編集`;
            color = "#84D5DA";
            code = 0;
            break;
        case 1:
            label = `プレスリリース 確認`;
            color = "#E7A82E";
            code = 1;
            break;
        case 3:
        case 6:
            label = `配信設定 編集`;
            color = "#84D5DA";
            code = 2;
            break;
        case 5:
            label = `配信設定　確認`;
            color = "#E7A82E";
            code = 3;
            break;
        case 7:
            label = STATUS_LABEL[4];
            color = "#ff7d33";
            code = 4;
            break;
        case 8:
            label = STATUS_LABEL[5];
            color = "#6BA1D3";
            code = 5;
            break;

    }

    return  {
        label,
        color,
        code,
    }
};



// page URL
export const createPressReleaseURL = (group, id, status) => {

    let link = '';

    switch (status) {
        case 0:
        case 2:
            link = `/press_release/${group}/detail/${id}/edit`;
            break;
        case 1:
            link = `/press_release/${group}/detail/${id}/review`;
            break;
        case 3:
        case 5:
        case 6:
        case 7:
        case 8:
            link = `/press_release/${group}/reserve/${id}/`;
            break;
    }

    return  link
};




