import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// style
import style from "./editor_and_viewer_icon.module.scss";

// component
import Avatar from "../../../../Common/Avatar";
import { Typography } from "@material-ui/core";

// state
import { RootState } from "../../../../../reducers";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const memberSelector = (state: RootState) => state.member;

interface IconProps {
	editorId: string,
	viewerIdList: string[],
}

interface EditorInfoProps {
	img: string,
	colorCode: string,
	lastName: string,
}

interface ViewerInfoProps {
	uuid: string,
	img: string,
	colorCode: string,
	lastName: string,
}

const EditorAndViewerIcon: React.FC<IconProps> = ({editorId, viewerIdList}) => {
	const pressRelease = useSelector(pressReleaseSelector);
	const member = useSelector(memberSelector);
	const {create_user, creators} = pressRelease.detail;
	const {pageType} = pressRelease;
	const [editorInfo, setEditorInfo] = useState<EditorInfoProps>({img: "", colorCode: "", lastName: ""});
	const [viewerInfo, setViewerInfo] = useState<ViewerInfoProps[]>([]);

	useEffect(() => {
		if (editorId && create_user && creators) {
			if (editorId === create_user.uuid) {
				setEditorInfo({img: create_user.img, colorCode: create_user.color_cd, lastName: create_user.last_name});
			} else {
				creators.forEach((creator) => {
					if (editorId === creator.uuid) {
						setEditorInfo({img: creator.img, colorCode: creator.color_cd, lastName: creator.last_name});
					}
				});
			}
		}
	}, [editorId]);

	useEffect(() => {
		if (viewerIdList && editorId) {
			viewerIdList = viewerIdList.filter(id => id !== editorId);
		}
		let viewerList: ViewerInfoProps[] = [];
		// 配信設定は編集権限がなくても入れてしまうため、PR編集と配信設定で処理を分ける
		if (pageType === "PAGE_EDIT") {
			if (viewerIdList && create_user && creators) {
				viewerIdList.forEach((id) => {
					if (id === create_user.uuid) {
						viewerList.push({uuid: id, img: create_user.img, colorCode: create_user.color_cd, lastName: create_user.last_name});
					} else {
						creators.forEach((creator) => {
							if (id === creator.uuid) {
								viewerList.push({uuid: id, img: creator.img, colorCode: creator.color_cd, lastName: creator.last_name});
							}
						});
					}
				});
				setViewerInfo(viewerList);
			}
		} else if (pageType === "PAGE_RESERVE") {
			if (viewerIdList && member.list.results) {
				viewerIdList.forEach((id) => {
					member.list.results.forEach((member) => {
						if (id === member.uuid) {
							viewerList.push({uuid: id, img: member.img, colorCode: member.color_cd, lastName: member.last_name});
						}
					});
				});
				setViewerInfo(viewerList);
			}
		}
	}, [viewerIdList]);

	return (
		<div className={style.icons}>
			{editorId &&
				<div className={style.editorBlock}>
					<Typography variant="caption">編集者</Typography>
					<div className={style.editorIcon}>
						<Avatar src={editorInfo.img} name={editorInfo.lastName} size="medium" color={editorInfo.colorCode} />
					</div>
				</div>
			}
			{viewerInfo.length > 0 &&
				<div className={style.viewerBlock}>
					<Typography variant="caption">閲覧者</Typography>
					<div className={style.viewerIcons}>
						{viewerInfo.map((viewer, index) => {
							if (index < 3) {
								return <Avatar key={index} src={viewer.img} name={viewer.lastName} size="medium" color={viewer.colorCode} />
							}
						})}
						{viewerInfo.length > 3 &&
							<Typography variant="body2">
								他{viewerInfo.length - 3}名
							</Typography>
						}
					</div>
				</div>
			}
		</div>
	);
};

export default EditorAndViewerIcon;
