import React, {ReactNode} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

export interface Props {
    children: ReactNode,
    page?: string,
}

const Title: React.FC<Props> = ({ children, page }) => {
    const classes = useStyles();
    const titleStyle = page === "TOP_PAGE" ? classes.topPageTitle : classes.root;

    return (
      <Typography variant="h1" component="h1" gutterBottom className={titleStyle}>
          {children}
      </Typography>
    );
}


export default Title

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center"
    },
    topPageTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
