import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { push } from 'connected-react-router'
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, ListItem, Divider, List, Collapse, ListItemText, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { options } from './help';

import style from './help.module.scss';

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
const routerSelector = (state: RootState) => state.router;

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        marginTop: 40,
        width: 250,
        border: "1px solid #ddd",
        height: "calc(100vh - 110px)",
        overflow: "scroll",
    },
    list: {
        marginBottom: 20
    },
    link: {
        display: "block"
    },
    active: {
        display: "block",
        borderBottom: "2px solid #2eb964"
    },
    activeText: {
        fontWeight: "bold",
        color: "#2eb964"
    },
    subText: {
        fontSize: 11
    },
    nested: {
        paddingLeft: 10,
    },
    panel: {
        background: '#fff',
        border: "none"
    },
    PanelDetails: {
        padding: 20,
        flexDirection: "column",
    },
    panelSummary: {
        background: '#e2e9e5',
        fontWeight: "bold",
        color: "#4f4f4f",
        borderBottom: "1px solid #ddd",
        padding: "0 10px",
        flexDirection: "row-reverse",
    },
});


const HelpList = () => {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>("4");
    const router = useSelector(routerSelector);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const rootPath = router.location.pathname.split('/');
        let number = 0
        options.map(function (element, index) {
            if (element.url.indexOf(rootPath[2]) > -1) {
                number = index
            }
        });
        setExpanded(`${number}`)
    }, []);

    return (
        <Box className={classes.root}>
            {options.map((option, index) => (
                <NestedList list={option} key={index} index={index} handleChange={handleChange} expanded={expanded} />
            ))}
        </Box>
    );
};

export default HelpList


interface Props {
    handleChange,
    expanded: string | false
    index: number,
    list: {
        label: string,
        child: { url: string, label: string, subLabel?: string }[]
    }
}


const NestedList: React.FC<Props> = ({ list, index, expanded, handleChange }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useSelector(routerSelector);
    const rootPath = `${router.location.pathname}${router.location.hash}`;
    const [activeURL, setActiveURL] = React.useState<string>(rootPath);


    //メニューのアクティブを変化
    const rootOptions = {
        root: null, //ビューポートをルート要素
        rootMargin: "-50% 0px", //交差の判定基準
        threshold: 0
    };

    const doWhenIntersect = (entries) => {
        // 交差検知をしたもののなかで、isIntersectingがtrueのものを色を変えるactivateIndexに渡す
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                activateIndex(entry.target);
            }
        });
    };

    const activateIndex = (element) => {
        const splitURIList: string[] = element.baseURI.split(/[/#]/);
        const pageName: string = splitURIList[splitURIList.length - 2];
        const currentURL: string = `/help/${pageName}#${element.previousElementSibling.id}`;

        list.child.forEach(option => {
            if (option.url === currentURL) {
                setActiveURL(option.url);
            }
        });
    };

    useEffect(() => {
        setActiveURL(rootPath);

        const boxes: any = document.querySelectorAll(`.${style.box}`);
        const convertedTypeArrayBoxes: any = [].slice.call(boxes);

        if (convertedTypeArrayBoxes.length > 0) {
            const observer = new IntersectionObserver(doWhenIntersect, rootOptions);

            //それぞれのboxを監視する
            convertedTypeArrayBoxes.forEach(box => {
                observer.observe(box);
            });
        }
    }, [router.location.pathname]);

    return (
        <>
            <Accordion elevation={0} className={classes.panel} expanded={expanded === `${index}`} onChange={handleChange(`${index}`)}>
                <AccordionSummary
                    onClick={() => {
                        dispatch(push(list.child[0].url))
                        const help = document.getElementById('helpLayoutContents');
                        if (help) help.scrollTop = 0;
                    }}
                    className={classes.panelSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    {list.label}
                </AccordionSummary>
                <AccordionDetails className={classes.PanelDetails}>
                    {list.child.map((option, index) => {
                        const isActive: boolean = activeURL === option.url;
                        const linkClass: string = isActive ? classes.active : classes.link;
                        const textClass: string = isActive ? classes.activeText : "";

                        return <List component="div" disablePadding key={index}>
                            <Link to={option.url} className={linkClass}>
                                <ListItem>
                                    <div>
                                        <Typography display={"block"} className={textClass}>{option.label}</Typography>
                                        {option.subLabel && <Typography variant={"caption"} className={classes.subText}>{option.subLabel}</Typography>}
                                    </div>
                                </ListItem>
                            </Link>
                            <Divider />
                        </List>
                    })}
                </AccordionDetails>
            </Accordion>
        </>
    );
};
