import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { Grid } from "@material-ui/core";


import { RootState } from "../../reducers";
import * as AnalyticsActionCreators from "../../actions/Analytics/ActionCreator";

import { MONTH_FILTER, SUMMARY } from "../../config/analytics_config";

import AnalyticsLayout from "../../pages/Analytics/Layout";

import MixChart from "../../components/Analytics/Chart/MixChart";
import DoughnutChart from "../../components/Analytics/Chart/DoughnutChart";
import {
    generateMediaCategoryColors,
    generateMediaTypeColors,
    generateMediaTypeLabel
} from "../../components/Analytics/Chart/DoughnutChart/color";
import { CategoryOption, openMailOptions } from "../../components/Analytics/Chart/MixChart/options";

const AnalyticsSelector = (state: RootState) => state.analytics;

interface Props extends RouteComponentProps<void> {
}

const SummaryPage: React.FC<Props> = (props) => {
    const analytics = useSelector(AnalyticsSelector)

    return (
        <AnalyticsLayout page={SUMMARY}>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <MixChart
                        title={"配信数と開封率"}
                        lineData={analytics.summary.openMail.percentage}
                        barData={analytics.summary.openMail.media_count}
                        labels={analytics.summary.openMail.label}
                        option={openMailOptions}
                    />
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                >
                    <DoughnutChart title={"配信カテゴリ"} data={analytics.summary.category.count}
                        labels={analytics.summary.category.label}
                        colors={generateMediaCategoryColors(analytics.summary.category.label)}
                        options={CategoryOption}
                    />
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                >
                    <DoughnutChart title={"配信メディア種別"} data={analytics.summary.media.mediaType.count} labels={generateMediaTypeLabel(analytics.summary.media.mediaType.label)}
                        colors={generateMediaTypeColors(analytics.summary.media.mediaType.label)}
                        options={CategoryOption}
                    />
                </Grid>
            </Grid>
        </AnalyticsLayout>
    )
}

export default SummaryPage;
