import React from 'react';
import Create from "./Create";
import Creator from "./Creator";
import ReviewRequest from "./ReviewRequest";
import Review from "./Review";
import ReviewResult from "./ReviewResult";
import Comment from "./Comment";
import Label from "./Label";

//style
import style from "../help.module.scss";

const PressRelease = () => {

	return (
		<article className={style.root}>
			<section><a id="1" className={style.anchor}/><Create/></section>
			<section><a id="2" className={style.anchor}/><Creator/></section>
			<section><a id="3" className={style.anchor}/><ReviewRequest/></section>
			<section><a id="4" className={style.anchor}/><Review/></section>
			<section><a id="5" className={style.anchor}/><ReviewResult/></section>
			<section><a id="6" className={style.anchor}/><Comment/></section>
			<section><a id="7" className={style.anchor}/><Label /></section>
		</article>
	);
};

export default PressRelease

