import React, { useEffect, useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { NavLink } from "react-router-dom";

// config
import { MEDIA_TYPE_LIST } from "../../config/media_type";
import { CUSTOM_MEDIA_LIST_DISPLAY_COUNT } from "../../config/list_display_count";
import { API_URL } from "../../config";

// model
import { MediaModel } from "../../model/MediaModel";

// actions
import * as ActionCreators from "../../actions/Media/ActionCreator";

// component
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

// component
import TableFrame from "../Common/TableFrame";
import Avatar from "../Common/Avatar";
import Pagination from "../Common/Pagination";
import AppDialog from "../Common/AppDialog";
import CsvImportDialog from "./CSV/Dialog";

// state
import { RootState } from "../../reducers";

// style
import style from "../PressRelease/List/press_release_list.module.scss";

// image
import ImageEllipsis from "../../assets/icon_ellipsis.svg";
import IconMedia from "../../assets/guide/media.svg";
// import { downloadPDF } from "../../util/File";

const mediaSelector = (state: RootState) => state.media;
const groupSelector = (state: RootState) => state.group;
const companySelector = (state: RootState) => state.company;

const MediaList: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const media = useSelector(mediaSelector);
  const group = useSelector(groupSelector);

  useEffect(() => {
    if (group.selectedId)
      dispatch(ActionCreators.getMediaList.request({ offset: 0 }));
  }, [group.selectedId]);

  const handlePageChange = (pageNumber) => {
    dispatch(ActionCreators.getMediaList.request({ offset: pageNumber }));
  };
  const handleChange = (event) => setSearch(event.target.value);

  const [search, setSearch] = useState<string>("");
  const [list, setList] = useState<MediaModel[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isOpenAppDialog, setIsOpenAppDialog] = useState(false);

  useEffect(() => {
    if (media.list.results.length > 0) {
      if (search.length > 0) {
        const searchResults = media.list.results.filter((item) => {
          return ~item.name.indexOf(search);
        });
        setList(searchResults);
      } else {
        setList(media.list.results);
      }
    }
  }, [media.list.results, search]);

  if (media.list.results.length > 0) {
    return (
      <>
        <Head handleChange={handleChange} search={search} media={media} />
        <TableFrame>
          <MediaTable list={media.list.results} />
        </TableFrame>
        <div className={style.pagination}>
          <Pagination
            activePage={media.list.offset > 0 ? media.list.offset : 1}
            totalCount={media.list.count}
            handlePageChange={handlePageChange}
            displayCount={CUSTOM_MEDIA_LIST_DISPLAY_COUNT}
          />
        </div>
      </>
    );
  } else {
    return (
      <div className={classes.noData}>
        <div>
          <div className={classes.noDataImg}>
            <img src={IconMedia} />
          </div>
          <Typography variant="h4" gutterBottom>
            メディアリストを一元管理しましょう
            <br />
            プレスリリースの配信が簡単になり、
            <br />
            メールの開封確認ができます
            <br />
          </Typography>
          <Box className={classes.head}>
            <a href={`${API_URL}csv_format/`} className={classes.link}>
              csvフォーマットをダウンロード
            </a>
          </Box>
          <Box className={classes.head}>
            <Button
              variant="contained"
              color="primary"
              className={classes.newButton}
              onClick={() => dispatch(push("/media/create/"))}
            >
              新規作成
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.newButton}
              onClick={() => setOpen(true)}
            >
              csvファイルをインポート
            </Button>
          </Box>
          <Grid container justify="flex-end" style={{ marginTop: "15px" }}>
            <Link
              component="button"
              variant="body2"
              color="error"
              underline="always"
              onClick={() => {
                setIsOpenAppDialog((prev) => !prev);
              }}
            >
              csvインポート方法について
            </Link>
          </Grid>
        </div>
        <CsvImportDialog isOpen={open} handleClose={() => setOpen(false)} />
        <AppDialog
          isOpen={isOpenAppDialog}
          closeHandle={() => {
            setIsOpenAppDialog(false);
          }}
          text={"csvインポート方法について"}
          content={
            "メディア種別は、下記の 0〜6 の番号をご指定ください。<br /><br />0 : その他<br />1 : 新聞<br />2 : WEB<br />3 : TV<br />4 : ラジオ<br />5 : 書籍/雑誌<br />6 : WEB/書籍"
          }
          type={"promoteSavingAction"}
        />
      </div>
    );
  }
};

export default MediaList;

const MediaTable = ({ list }) => {
  const classes = useStyles();
  return (
    <section>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell style={{ width: "5%" }}></TableCell>
            <TableCell style={{ width: "25%" }}>メディア名</TableCell>
            <TableCell style={{ width: "20%" }}>企業名</TableCell>
            <TableCell style={{ width: "15%" }}>氏名</TableCell>
            <TableCell style={{ width: "5%" }}>メディア種別</TableCell>
            <TableCell style={{ width: "25%" }}>備考</TableCell>
            <TableCell style={{ width: "5%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => (
            <List key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </section>
  );
};

interface ListProps {
  row: MediaModel;
}

const List: FC<ListProps> = ({ row }) => {
  const dispatch = useDispatch();
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(ActionCreators.deleteMedia.request({ id: row.id }));
    setDeleteDialog(false);
  };

  const handleEdit = () => {
    dispatch(push(`/media/${row.id}/edit/`));
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Avatar
            src=""
            name={row.name || row.publisher || row.last_name}
            size="medium"
          />
        </TableCell>

        <TableCell>{row.name}</TableCell>
        <TableCell>{row.publisher}</TableCell>
        <TableCell component="th" scope="row">
          <p>
            {row.last_name_kana} {row.first_name_kana}
          </p>
          <p>
            {row.last_name} {row.first_name}
          </p>
        </TableCell>
        <TableCell>{MEDIA_TYPE_LIST[row.media_type || 0]}</TableCell>
        <TableCell>{row.memo}</TableCell>
        <TableCell>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <img src={ImageEllipsis} />
          </IconButton>
          <Menu
            elevation={1}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setDeleteDialog(true);
                handleClose();
              }}
            >
              削除
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleEdit();
                handleClose();
              }}
            >
              編集
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <AppDialog
        isOpen={deleteDialog}
        closeHandle={() => setDeleteDialog(false)}
        mainHandle={handleDelete}
        text="削除しますか"
        ButtonText="削除"
      />
    </>
  );
};

const Head = ({ handleChange, search, media }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const company = useSelector(companySelector);
  const { plan } = company;
  const mediaLimit = plan.media_limit;

  const [open, setOpen] = useState(false);
  const [displayButton, setDisplayButton] = useState<boolean>(false);
  const [isOpenAppDialog, setIsOpenAppDialog] = useState(false);

  useEffect(() => {
    if (mediaLimit) {
      setDisplayButton(media.list.count < mediaLimit);
    } else {
      setDisplayButton(true);
    }
  }, [mediaLimit, media.list]);

  return (
    <div>
      <Box className={classes.head}>
        <a href={`${API_URL}csv_format/`} className={classes.link}>
          csvフォーマットをダウンロード
        </a>
      </Box>
      {displayButton ? (
        <>
          <Box className={classes.head}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.newButton}
              onClick={() => dispatch(push("/media/create/"))}
            >
              新規作成
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.newButton}
              onClick={() => setOpen(true)}
            >
              csvファイルをインポート
            </Button>
          </Box>
          <Grid container justify="flex-end" style={{ marginTop: "15px" }}>
            <Link
              component="button"
              variant="body2"
              color="error"
              underline="always"
              onClick={() => {
                setIsOpenAppDialog((prev) => !prev);
              }}
            >
              csvインポート方法について
            </Link>
          </Grid>
        </>
      ) : (
        <Box className={classes.upgradeBox}>
          <Typography variant="caption" component="p">
            登録できるメディア数が上限に達しています。
            <br />
            メディアの追加を行うには、プランのアップグレードが必要です。
            <br />
          </Typography>
          <NavLink exact to={"/setting/plan"}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.upgradeButton}
            >
              プランのアップグレード
            </Button>
          </NavLink>
        </Box>
      )}
      {/*<Box className={classes.searchBox}>*/}
      {/*    <TextField*/}
      {/*        placeholder="検索"*/}
      {/*        onChange={handleChange}*/}
      {/*        variant="outlined"*/}
      {/*        value={search}*/}
      {/*        className={classes.searchField}*/}
      {/*        InputProps={{*/}
      {/*            startAdornment: (*/}
      {/*                <InputAdornment position="start">*/}
      {/*                    <SearchIcon />*/}
      {/*                </InputAdornment>*/}
      {/*            ),*/}
      {/*            endAdornment: (*/}
      {/*                <InputAdornment position="end">*/}
      {/*                    {search && (*/}
      {/*                        <IconButton className={classes.cancelIcon} onClick={() => handleChange('')}>*/}
      {/*                            <CancelIcon />*/}
      {/*                        </IconButton>)}*/}
      {/*                </InputAdornment>*/}
      {/*            ),*/}
      {/*            classes: {*/}
      {/*                input: classes.searchInput,*/}
      {/*                adornedEnd: classes.adornend,*/}
      {/*            },*/}
      {/*        }}*/}
      {/*   />*/}
      {/*</Box>*/}
      <CsvImportDialog isOpen={open} handleClose={() => setOpen(false)} />
      <AppDialog
        isOpen={isOpenAppDialog}
        closeHandle={() => {
          setIsOpenAppDialog(false);
        }}
        text={"csvインポート方法について"}
        content={
          "メディア種別は、下記の 0〜6 の番号をご指定ください。<br /><br />0 : その他<br />1 : 新聞<br />2 : WEB<br />3 : TV<br />4 : ラジオ<br />5 : 書籍/雑誌<br />6 : WEB/書籍"
        }
        type={"promoteSavingAction"}
      />
    </div>
  );
};

const useStyles = makeStyles({
  noData: {
    marginTop: 100,
    textAlign: "center",
    marginBottom: 30,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  noDataImg: {
    marginBottom: 30,
  },
  createButton: {
    width: 400,
  },
  cancelIcon: {
    padding: 7,
  },
  head: {
    display: "flex",
    justifyContent: "flex-end",
  },
  newButton: {
    marginRight: 10,
    height: 35,
  },
  searchBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
  },
  searchField: {
    width: 200,
  },
  searchInput: {
    height: 15,
    padding: "10px 0",
  },
  adornend: {
    paddingRight: 5,
  },
  tableHead: {
    backgroundColor: "#eaeaea",
  },
  link: {
    textDecoration: "underline",
    margin: "10px 5px",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  upgradeBox: {
    textAlign: "right",
  },
  upgradeButton: {
    marginTop: 5,
  },
});
