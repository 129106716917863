import {OptionRequestModel, PaymentModel} from "../model/PaymentModel";
import {combineReducers} from "redux";
import createReducer from "./createReducer";
import PaymentActionType from "../actions/Payment/ActionType";
import PaymentAction from "../actions/Payment/Action";
import OptionActionType from "../actions/Option/ActionType";
import ContactActionType from "../actions/Contact/ActionType";

const initialState: PaymentModel = {
  editUrl: "",
  paymentUrl: "",
  card: {
    number: "",
    expire: "",
    index: 0
  },
  nextChargeDate: "-",
  activeServiceDate: "-"
};

const info = createReducer<PaymentModel>(initialState, {
  [PaymentActionType.GET_CARD_EDIT_URL_SUCCEEDED](
    state: PaymentModel,
    action: PaymentAction
  ) {
    const result: any = action.payload;
    return {
      ...state,
      editUrl: result
    };
  },

  [PaymentActionType.GET_CARD_INFO_SUCCEEDED](
    state: PaymentModel,
    action: PaymentAction
  ) {
    const result: any = action.payload;
    return {
      ...state,
      card: result
    };
  },
  [PaymentActionType.POST_PLAN_SUCCEEDED](
    state: PaymentModel,
    action: PaymentAction
  ) {
    return {
      ...state
    };
  },
  [PaymentActionType.POST_TICKET_PAYMENT_SUCCEEDED](
    state: PaymentModel,
    action: PaymentAction
  ) {
    return {
      ...state,
      paymentUrl: action.payload
    };
  }
});

const fetching = createReducer<boolean>(false, {
  [PaymentActionType.POST_OPTION_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.POST_OPTION_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.POST_OPTION_FAILED](_state, _action) {
    return false;
  },
  [PaymentActionType.PATCH_OPTION_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.PATCH_OPTION_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.PATCH_OPTION_FAILED](_state, _action) {
    return false;
  },
  [PaymentActionType.DELETE_OPTION_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.DELETE_OPTION_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.DELETE_OPTION_FAILED](_state, _action) {
    return false;
  },
  [PaymentActionType.POST_PLAN_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.POST_PLAN_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.POST_PLAN_FAILED](_state, _action) {
    return false;
  },
  [PaymentActionType.PATCH_PLAN_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.PATCH_PLAN_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.PATCH_PLAN_FAILED](_state, _action) {
    return false;
  },
  [PaymentActionType.DELETE_PLAN_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.DELETE_PLAN_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.DELETE_PLAN_FAILED](_state, _action) {
    return false;
  },
  [PaymentActionType.POST_TICKET_PAYMENT_REQUEST](_state, _action) {
    return true;
  },
  [PaymentActionType.POST_TICKET_PAYMENT_SUCCEEDED](_state, _action) {
    return false;
  },
  [PaymentActionType.POST_TICKET_PAYMENT_FAILED](_state, _action) {
    return false;
  },
  [ContactActionType.POST_CONTACT_REQUEST](_state, _action) {
    return true;
  },
  [ContactActionType.POST_CONTACT_SUCCEEDED](_state, _action) {
    return false;
  },
  [ContactActionType.POST_CONTACT_FAILED](_state, _action) {
    return false;
  }
});

export const payment = combineReducers({
  info,
  fetching
});
