import React from "react"

//material-ui
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles"

//component
import TableFrame from "../Common/TableFrame";
import SettingTitle from "../Common/SettingTitle";

//model
import {CompanyGroupsContentModel} from "../../model/CompanyModel";


interface MemberQtyTableProps {
    groups: CompanyGroupsContentModel[]
}

export const MemberQtyTable: React.FC<MemberQtyTableProps> = ({groups}) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.memberQtyTitle}>
                <SettingTitle text={"企業内のプロジェクト別メンバー数"}/>
            </div>
            <TableFrame>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "80%"}}>プロジェクト名</TableCell>
                            <TableCell style={{width: "20%"}}>メンバー数</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.map((row, index) => (
                            <List key={index} row={row}/>
                        ))}
                    </TableBody>
                </Table>
            </TableFrame>
        </>
    );
}


interface ListProps {
    row: CompanyGroupsContentModel
}

const List: React.FC<ListProps> = ({row}) => {
    return (
        <>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.member_qty}</TableCell>
            </TableRow>
        </>
    )
}

const useStyles = makeStyles({
    memberQtyTitle: {
        marginTop: 30
    }
})
