import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Fab from "@material-ui/core/Fab";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {NavLink} from "react-router-dom";

interface Props {
    url: string,
    placement?: "bottom"
}

const HelpFab: React.FC<Props> = ({ url, placement='top'}) => {
    const classes = useStyles();

    return (
        <NavLink target="_blank" to={url} className={classes.root}>
            <Tooltip title="使い方" placement={placement} arrow>
                <Fab size="small" color="primary" className={classes.fab}>
                    <HelpOutlineIcon className={classes.icon}/>
                </Fab>
            </Tooltip>
        </NavLink>
    );
}   


export default HelpFab;

const useStyles = makeStyles({
    root: {
        marginLeft: 10,
        padding: "5px 0",
        display: "block"
    },
    fab: {
        width: 30,
        height: 30,
        minHeight: 30
    },
    icon: {
        fontSize: 28,
    },


});
