import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { SetStatus, STATUS_LABEL, STATUS_SKIP_LABEL} from '../../../../util/Status';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {RootState} from "../../../../reducers";
import { useSelector } from "react-redux";
const companySelector = (state: RootState) => state.company;

interface StatusProps {
    status: number | undefined,
    review,
    type: string,
}

const Status: React.FC<StatusProps> = ({ status, review, type }) => {
    const classes = StatusStyles();
    const [statusInfo, setStatusInfo] = React.useState({ code: 0, label: '', color: '' });
    useEffect(() => {
        setStatusInfo(SetStatus(status))
    }, [status]);

    if (type === 'header') {
        return (
            <StatusBar status={statusInfo.code} />
        )
    } else {
        return (
            <p>　({statusInfo.label})</p>
        );
    }
}

const StatusBar = ({ status }) => {
    const classes = headerStatusStyles();
    const company = useSelector(companySelector);
    const steps = getSteps(company.is_review_skip)

    return (
        <div className={classes.headerStep}>
            {steps.map((label, index) => (
                <React.Fragment key={index}>
                    <div className={index == status ? `${classes.activeLabel}` : `${classes.normalLabel}`}>
                        <p>{label}</p>
                    </div>
                    {index < STATUS_LABEL.length -1  && (<p className={classes.arrow}><ArrowForwardIosIcon /></p>)}
                </React.Fragment>
            ))}
        </div>
    )
}

const headerStatusStyles = makeStyles({
    headerStep: {
        display: 'flex',
    },
    arrow: {
        padding: '0 5px',
        marginTop: 2,
        color: '#c8c8c8',
        fontSize: 13,
    },
    activeLabel: {
        fontSize: 12,
        overflow: "hidden",
        backgroundColor: '#2EB964',
        color: '#fff',
        padding: '2px 10px',
        borderRadius: 15,
        textAlign: 'center',
        height: 18,
    },
    normalLabel: {
        fontSize: 12,
        overflow: "hidden",
        backgroundColor: '#dfdfdf',
        color: '#777',
        padding: '2px 10px',
        borderRadius: 15,
        textAlign: 'center',
        height: 18,
    }
})

const StatusStyles = makeStyles({
    root: {
        fontSize: 12,
        color: '#fff',
        padding: '2px 10px',
        textAlign: 'center',
        fontWeight: "bold",
        backgroundColor: '#aaa',
        margin: 5,
        width: 140,
    },
});

export default Status

function getSteps(skipFlag) {
    const skip = skipFlag? '（スキップ）': '';
    return ['編集中', `校閲・承認${skip}`, '配信設定', `確認待ち${skip}`, '配信待ち', '配信済み'];
}
