import React from "react";

//material-ui
import {Typography} from "@material-ui/core";

//component
import {TicketQtyView} from "./TicketQty";

//model
import {TicketQtyModel} from "../../../../../model/TicketModel";

interface Props {
  ticketQty: TicketQtyModel;
}

export const ReserveConfirm: React.FC<Props> = ({ticketQty}) => {
  return (
    <>
      <Typography>残り配信数を1回消費して予約を確定します。</Typography>
      <TicketQtyView ticketQty={ticketQty} />
    </>
  );
};
