import React from 'react';

//component
import Title from '../Title';
import SubTitle from "../SubTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/pressRelease/label/image01.png';
import Image02 from '../../../assets/help/pressRelease/label/image02.png';
import Image03 from '../../../assets/help/pressRelease/label/image03.png';
import Image04 from '../../../assets/help/pressRelease/label/image04.png';
import Image05 from '../../../assets/help/pressRelease/label/image05.png';
import Image06 from '../../../assets/help/pressRelease/label/image06.png';
import Image07 from '../../../assets/help/pressRelease/label/image07.png';


const Label = () => {
	return (
		<>
			<div className={style.box}>
				<Title title={"7. ラベル機能"}/>
				<SubTitle step={1} title={"ラベルを作成"}/>
				<Typography gutterBottom variant={"body1"}>
					「新規作成」ボタンを押下します。<br/>
				</Typography>
				<div className={style.img}>
					<img src={Image01}/>
				</div>
				<div className={style.img}>
					<img src={Image02}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					名前、色、説明を入力して、「保存する」ボタン押下で保存されます。
				</Typography>
				<div className={style.img}>
					<img src={Image03}/>
				</div>
				<div className={style.img}>
					<img src={Image04}/>
				</div>
				<SubTitle step={2} title={"プレスリリースにラベルを紐付ける"}/>
				<Typography gutterBottom variant={"body1"}>
					プレスリリースのメニューから「ラベルを管理」ボタンを押下すると、選択画面が開きます。
				</Typography>
				<div className={style.img}>
					<img src={Image05}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					プレスリリースに紐付けたいラベルにチェックをつけてください。
				</Typography>
				<div className={style.img}>
					<img src={Image06}/>
				</div>
				<div className={style.img}>
					<img src={Image07}/>
				</div>
			</div>
		</>
	);
};

export default Label;
