import React from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from '@material-ui/core';

// state
import { RootState } from "../../../reducers";
const radarWordsSelector = (state: RootState) => state.radar.words;
const optionRadarQuantitySelector = (state: RootState) => state.company.option.radar.quantity;


const OverWordContent: React.FC = () => {
    const classes = useStyles();
    const optionRadarQuantity = useSelector(optionRadarQuantitySelector);
    const radarWords = useSelector(radarWordsSelector);

    return (
        <>
            <Typography variant={"h4"} gutterBottom>
                登録している検索ワード数が上限を超過しています
            </Typography>
            <Typography variant={"h6"} gutterBottom>
                検索ワード契約数： {optionRadarQuantity}<br />
                登録している検索ワード数： {radarWords.length}
            </Typography>
            <Typography variant={"h4"} gutterBottom>
                利用するには、<br />
                ・オプションの追加(検索ワード数の追加)<br />
                ・登録している検索ワードを削除<br />
                上記のいずれかを行う必要があります。
            </Typography>

            <NavLink exact to={"/setting/plan/"} className={classes.button}>
                <Button variant="contained" size="large" color="primary">
                    オプションの追加
                </Button>
            </NavLink>
            <NavLink exact to={"/setting/radar/"}>
                <Button variant="outlined" size="large" color="primary">
                    検索ワード設定画面へ
                </Button>
            </NavLink>
        </>
    );
};

export default OverWordContent;

const useStyles = makeStyles({
    button: {
        marginRight: 15,
    },
});
