import Froalaeditor from 'froala-editor';
import CloseIcon from '../../../assets/icon_close_gray.svg';
import {RootState} from "../../../reducers";
import {useDispatch, useSelector} from "react-redux";

const pressReleaseSelector = (state: RootState) => state.pressRelease;
import * as PressReleaseActionCreators from "../../../actions/PressRelease/ActionCreator";
import {useEffect, useState} from "react";
import * as BlockActionCreators from "../../../actions/Block/ActionCreator";

Object.assign(Froalaeditor.POPUP_TEMPLATES, {
    'pdf.popup':'[_BUTTONS_][_CUSTOM_LAYER_]'
});


const FroalaPdfPlugin = (editor) => {

    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const [isOpen, setOpen] = useState<boolean>(false);

    useEffect(() => {
        init();
    }, [pressRelease.detail, isOpen === true]);

    const init = () => {
        const headerRepeatCheckbox:HTMLInputElement = document.querySelector('.block-header-repeat-check') as HTMLInputElement;
        const footerRepeatCheckbox:HTMLInputElement = document.querySelector('.block-footer-repeat-check') as HTMLInputElement;
        if (headerRepeatCheckbox) headerRepeatCheckbox.checked = pressRelease.detail.is_header_loop;
        if (footerRepeatCheckbox) footerRepeatCheckbox.checked = pressRelease.detail.is_footer_loop;
    }


    Froalaeditor.PLUGINS.pdf = (editor) => {

        // Create custom popup.
        function initPopup () {
            // Popup buttons.

            let popupButtons = '<div class="fr-buttons popup-header">'+
                '<h4>PDF設定</h4>'+
                `<button class="popup-close-button"><img src="${CloseIcon}"></button>`+
                '</div>';

            let popupContent =
                '<div class="popup-content">'+
                `<div class="popup-content-input"><label><input name="header-repeat" type="checkbox" class="block-header-repeat-check">ヘッダーを全ページに挿入</label></div>` +
                `<div class="popup-content-input"><label><input name="footer-repeat" type="checkbox" class="block-footer-repeat-check">フッターを全ページに挿入</label></div>` +
                '</div>';
                '</div>';

            // Load popup template.
            const template = {
                buttons: popupButtons,
                custom_layer: popupContent
            };

            // Create popup.
            const $popup = editor.popups.create('pdf.popup', template);

            // ヘッダー繰り返し
            const headerRepeatCheckbox = document.getElementsByClassName('block-header-repeat-check');
            headerRepeatCheckbox[0].addEventListener('click', () => {
                const header:any = document.getElementsByName('header-repeat');
                dispatch(PressReleaseActionCreators.setHeaderLoop(header[0].checked));
            }, false);

            // フッター繰り返し
            const footerRepeatCheckbox = document.getElementsByClassName('block-footer-repeat-check');
            footerRepeatCheckbox[0].addEventListener('click', () => {
                const footer:any = document.getElementsByName('footer-repeat');
                dispatch(PressReleaseActionCreators.setFooterLoop(footer[0].checked));
            }, false);

            // popup閉じる
            const closeButton = document.getElementsByClassName('popup-close-button');
            for(let i = 0; i < closeButton.length; i++){
                closeButton[i].addEventListener('click', () => {
                    hidePopup();
                }, false);
            }

            return $popup;
        }

        // Show the popup
        function showPopup () {
            // Get the popup object defined above.
            let $popup = editor.popups.get('pdf.popup');

            // If popup doesn't exist then create it.
            // To improve performance it is best to create the popup when it is first needed
            // and not when the editor is initialized.
            if (!$popup) $popup = initPopup();

            // Set the editor toolbar as the popup's container.
            editor.popups.setContainer('pdf.popup', editor.$tb);

            // This will trigger the refresh event assigned to the popup.
            // editor.popups.refresh('blockInsert.popup');

            // This custom popup is opened by pressing a button from the editor's toolbar.
            // Get the button's object in order to place the popup relative to it.
            let $btn = editor.$tb.find('.fr-command[data-cmd="PDFButton"]');

            // Set the popup's position.
            let left = $btn.offset().left + $btn.outerWidth() / 2;
            //var top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
            // Show the custom popup.
            // The button's outerHeight is required in case the popup needs to be displayed above it.
            editor.popups.show('pdf.popup', left, top, $btn.outerHeight());
            setOpen(true)

        }

        // popup 閉じる
        function hidePopup () {
            editor.popups.hide('pdf.popup');
            setOpen(false)
        }


        const element:any = document.querySelector('.fr-wrapper .fr-element');
        if (element) {
            element.addEventListener("blur", () => {
                insertHeaderFooter()
            });
        }

        const table = '<table class="" style="width: 100%;"><tbody><tr><td style="width: 25.0000%;">&nbsp;&nbsp;</td><td style="width: 25.0000%;">&nbsp;&nbsp;</td><td style="width: 25.0000%;">&nbsp;&nbsp;</td><td style="width: 25.0000%;">&nbsp;&nbsp;</td></tr></tbody></table>'

        // header footer　タグがない場合は追加
        function insertHeaderFooter () {

            //header 挿入
            const headerTag = element.querySelector('.header');
            if (headerTag)  {
                if (!headerTag.querySelector('table')) {
                    headerTag.innerHTML = table;
                }
                const headerPrev = headerTag.previousElementSibling;

                if (headerPrev) {
                    // headerより上の要素を削除
                    const parentNode = headerTag.parentNode;
                    parentNode.removeChild(headerPrev);
                }
            } else {
                const header:any= document.createElement("div");
                header.className = "header";
                header.innerHTML = table;
                var firstElementChild = element.firstElementChild;
                element.insertBefore(header, firstElementChild);
            }

            // footer挿入
            const footerTag = element.querySelector('.footer');
            if (footerTag) {
                if (!footerTag.querySelector('table')) {
                    footerTag.innerHTML = table;
                }
                const footerPrev = footerTag.nextElementSibling;
                if (footerPrev) {
                    // footer
                    if (footerPrev.outerHTML !== '<p><br></p>') {
                        const parentNode = footerTag.parentNode;
                        parentNode.removeChild(footerPrev);
                    }
                }
            } else {
                const footer:any= document.createElement("div");
                footer.className = "footer";
                footer.innerHTML = table;
                const br:any= document.createElement("br");
                element.appendChild(footer);
                element.appendChild(br);
            }
        }

        // Methods visible outside the plugin.
        return {
            showPopup: showPopup,
            hidePopup: hidePopup,
            insertHeaderFooter: insertHeaderFooter
        }
    };



};

export default FroalaPdfPlugin;

