import React from 'react';
import Create from "./Create";
import Download from "./Download";
import Send from "./Send";

//style
import style from "../help.module.scss";

const PressRelease = () => {

	return (
		<article className={style.root}>
			<section><a id="1" className={style.anchor}/><Create/></section>
			<section><a id="2" className={style.anchor}/><Download/></section>
			<section><a id="3" className={style.anchor}/><Send/></section>
		</article>
	);
};

export default PressRelease

