import React from 'react';

// components
import { TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// config
import { theme } from '../../withRoot';

interface Props {
    value: string,
    multiline?: boolean,
    handleChange,
}

const TextFieldUnderline: React.FC<Props> = ({ value, handleChange, multiline }) => {
    const classes = useStyles();
    return (
        <TextField
            value={value}
            multiline={multiline}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                handleChange(e.target.value);
            }}
            InputProps={{
                classes: {
                    input: classes.input,
                },
            }}
        />
    );
}

export default TextFieldUnderline;

const useStyles = makeStyles({
    input: {
        fontSize: 12,
        padding: 4,
        borderBottom: '1px solid #e0e0e0',
        '&:focus': {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
        }
    },
});
