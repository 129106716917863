enum MovieActionType {
	GET_MOVIE_REQUEST = "GET_MOVIE_REQUEST",
	GET_MOVIE_SUCCEEDED = "GET_MOVIE_SUCCEEDED",
	GET_MOVIE_FAILED = "GET_MOVIE_FAILED",
	POST_MOVIE_REQUEST = "POST_MOVIE_REQUEST",
	POST_MOVIE_SUCCEEDED = "POST_MOVIE_SUCCEEDED",
	POST_MOVIE_FAILED = "POST_MOVIE_FAILED",
    DELETE_MOVIE_REQUEST = "DELETE_MOVIE_REQUEST",
    DELETE_MOVIE_SUCCEEDED = "DELETE_MOVIE_SUCCEEDED",
    DELETE_MOVIE_FAILED = "DELETE_MOVIE_FAILED",
}

export default MovieActionType;

