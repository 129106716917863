// actions
import TicketActionType from '../actions/Ticket/ActionType';
import TicketAction from '../actions/Ticket/Action';

// models
import { TicketQtyModel, TicketModel } from "../model/TicketModel";

// state
import createReducer from "./createReducer";

const initialStateTicketQty: TicketQtyModel = {
	default: {
		available: 0,
		reserved: 0
	},
	non_default: {
		available: 0,
		reserved: 0
	}
}

const initialState: TicketModel = {
	qty: initialStateTicketQty
}

export const ticket = createReducer<TicketModel>(initialState, {
	[TicketActionType.GET_TICKET_QTY_SUCCEEDED](state: TicketModel, action: TicketAction) {
		const result: any = action.payload
		return {
			qty:{
				"default": result.free,
				"non_default": result.paid,
			}
		}
	}
})
