import React from 'react';
import Title from '../Title';
import SubTitle from "../SubTitle";
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/send/waiting/image01.png';

const Create = () => {
	return (
		<div className={style.box}>
			<Title title={"4. 配信待ち"}/>
			<Typography gutterBottom variant={"body1"}>
				配信時刻になると、配信が開始します。通常は5分程度で配信が完了します。<br/>
				「配信設定を修正する」ボタンから配信設定の修正ができます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
		</div>
	);
};

export default Create;

