import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/editor/headerfooter/image01.png';
import Image02 from '../../../assets/help/editor/headerfooter/image02.png';
import Image03 from '../../../assets/help/editor/headerfooter/image03.png';
import Image04 from '../../../assets/help/editor/headerfooter/image04.png';

const Block = () => {
	return (
		<div className={style.box}>
			<Title title={"2.ヘッダーフッター"}/>
			<Typography gutterBottom variant={"body1"}>
				エディタのヘッダーフッター領域は、PDFのヘッダーフッターとして出力されます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				メニューから繰り返しの設定を変更することができます。
			</Typography>
			<Typography gutterBottom variant={"body1"}>
				全てのページでヘッダーフッターを繰り返す設定になっています。<br/>
				1ページ目のみ表示したい場合は、チェックをはずしてください。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
		</div>
	);
};

export default Block
