import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database';
import { createFirestoreInstance } from 'redux-firestore'
import { firebaseConfig, stgFirebaseConfig } from './config/firebase_config'

try {
    switch (process.env.REACT_APP_TARGET_ENV) {
        case "production":
            firebase.initializeApp(firebaseConfig);
            break;
        default:
            firebase.initializeApp(stgFirebaseConfig);
    }
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
} catch (e) {
    console.error('Error in firebase initializeApp')
}

export const db = firebase.firestore()


export const configureFirebase = (appStore) => {
    const rrfConfig = {}
    const rrfProps = {
        firebase,
        config: rrfConfig,
        dispatch: appStore.dispatch,
        createFirestoreInstance
    }
    return rrfProps
}

export const rtdb = firebase.database();
