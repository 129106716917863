import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RouteComponentProps } from "react-router-dom";

// components
import AppLayout from "../../components/Layout/App";
import List from "../../components/PressRelease/List/";

import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";
import * as PressReleaseTagActionCreators from "../../actions/PressReleaseTag/ActionCreator";
import * as MemberActionCreators from "../../actions/Member/ActionCreator";
import * as PressReleaseReserveActionCreators from "../../actions/PressReleaseReserve/ActionCreator";

import { RootState } from "../../reducers";
const pressReleaseListSelector = (state: RootState) => state.pressReleaseList;
const pressGroupSelector = (state: RootState) => state.group;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

type PageProps = {} & RouteComponentProps<{}>;

const ListPage: React.FC<PageProps> = props => {

	const dispatch = useDispatch();
	const { search } = useSelector(pressReleaseListSelector);
	const group = useSelector(pressGroupSelector);
	const reserve = useSelector(pressReleaseReserveSelector);

	useEffect(() => {
		if (group.selectedId) {
			dispatch(PressReleaseActionCreators.getPressReleaseList.request({ offset: 1 }));
			dispatch(PressReleaseTagActionCreators.getPressReleaseTagList.request({ offset: 1, limit: 100 }));
			dispatch(MemberActionCreators.getMemberList.request({ offset: 1, limit: 100 }));
		}
	}, [group.selectedId]);

	useEffect(() => {
		dispatch(PressReleaseReserveActionCreators.clearPressReleaseReserveState.request());
	}, [reserve]);

	return (
		<AppLayout>
			<List list={search} isArchive={false} isPublic={false} />
		</AppLayout>
	);
}

export default ListPage;
