import React from "react";
import {useDispatch} from "react-redux";
import {Typography, Chip} from "@material-ui/core";

//action
import * as ActionCreators from "../../../actions/Topic/ActionCreator";

//style
import style from "./topicList.module.scss";

interface Props {
    words: Array<string>,
    year: number,
    month: number
}

export const Words: React.FC<Props> = ({words, year, month}) => {
    const dispatch = useDispatch();

    const makeHandleChipClick = (word) => () => {
        dispatch(ActionCreators.setSelectedTopicWord.request({word, year, month}))
        dispatch(ActionCreators.postTopicWord.request({word, year, month}))
    }

    return (
        <>
            {words.map((word, index) => (
                <Chip
                    key={index}
                    className={style.word}
                    label={word}
                    clickable={true}
                    onClick={makeHandleChipClick(word)}
                />
            ))}
        </>
    )
}
