import ActionType from './ActionType';
import { createAsyncAction } from 'typesafe-actions';
import {
	BriefingDetailModel, BriefingFileModel, GetBriefingAnswerInfoRequestModel, GetBriefingDetailRequestModel,
	BriefingListModel, BriefingPublicInfoModel, PatchBriefingContentModel,
	PostBriefingAnswerRequestModel, PostBriefingContentModel, PostBriefingSendMailRequestModel,
	BriefingQueryModel, DeleteBriefingContentModel, BriefingQtyModel, HtmlFromBriefingRequestModel, BriefingAnswerContentModel, PostBriefingAnswerConfirmRequestModel, GetBriefingQtyRequestModel
} from '../../model/BriefingModel';

export const getBriefingDetail = createAsyncAction(
	ActionType.GET_BRIEFING_DETAIL_REQUEST,
	ActionType.GET_BRIEFING_DETAIL_SUCCEEDED,
	ActionType.GET_BRIEFING_DETAIL_FAILED,
)<GetBriefingDetailRequestModel, BriefingDetailModel, Error>();

export const getBriefingDetailAnonymous = createAsyncAction(
	ActionType.GET_BRIEFING_DETAIL_ANONYMOUS_REQUEST,
	ActionType.GET_BRIEFING_DETAIL_ANONYMOUS_SUCCEEDED,
	ActionType.GET_BRIEFING_DETAIL_ANONYMOUS_FAILED,
)<GetBriefingDetailRequestModel, BriefingDetailModel, Error>();

export const getBriefingList = createAsyncAction(
	ActionType.GET_BRIEFING_LIST_REQUEST,
	ActionType.GET_BRIEFING_LIST_SUCCEEDED,
	ActionType.GET_BRIEFING_LIST_FAILED,
)<void, BriefingListModel, Error>();

export const getBriefingListTemplate = createAsyncAction(
	ActionType.GET_BRIEFING_LIST_TEMPLATE_REQUEST,
	ActionType.GET_BRIEFING_LIST_TEMPLATE_SUCCEEDED,
	ActionType.GET_BRIEFING_LIST_TEMPLATE_FAILED,
)<void, BriefingListModel, Error>();

export const getBriefingListReceived = createAsyncAction(
	ActionType.GET_BRIEFING_LIST_RECEIVED_REQUEST,
	ActionType.GET_BRIEFING_LIST_RECEIVED_SUCCEEDED,
	ActionType.GET_BRIEFING_LIST_RECEIVED_FAILED,
)<void, BriefingListModel, Error>();

export const postBriefingContent = createAsyncAction(
	ActionType.POST_BRIEFING_CONTENT_REQUEST,
	ActionType.POST_BRIEFING_CONTENT_SUCCEEDED,
	ActionType.POST_BRIEFING_CONTENT_FAILED,
)<PostBriefingContentModel, void, Error>();

export const patchBriefingContent = createAsyncAction(
	ActionType.PATCH_BRIEFING_CONTENT_REQUEST,
	ActionType.PATCH_BRIEFING_CONTENT_SUCCEEDED,
	ActionType.PATCH_BRIEFING_CONTENT_FAILED,
)<PatchBriefingContentModel, void, Error>();

export const deleteBriefingContent = createAsyncAction(
	ActionType.DELETE_BRIEFING_CONTENT_REQUEST,
	ActionType.DELETE_BRIEFING_CONTENT_SUCCEEDED,
	ActionType.DELETE_BRIEFING_CONTENT_FAILED,
)<DeleteBriefingContentModel, void, Error>();

export const postBriefingAnswer = createAsyncAction(
	ActionType.POST_BRIEFING_ANSWER_REQUEST,
	ActionType.POST_BRIEFING_ANSWER_SUCCEEDED,
	ActionType.POST_BRIEFING_ANSWER_FAILED,
)<PostBriefingAnswerRequestModel, void, Error>();

export const getBriefingAnswerInfo = createAsyncAction(
	ActionType.GET_BRIEFING_ANSWER_INFO_REQUEST,
	ActionType.GET_BRIEFING_ANSWER_INFO_SUCCEEDED,
	ActionType.GET_BRIEFING_ANSWER_INFO_FAILED,
)<GetBriefingAnswerInfoRequestModel, BriefingAnswerContentModel, Error>();

export const setBriefingTemplate = createAsyncAction(
	ActionType.SET_BRIEFING_TEMPLATE_REQUEST,
	ActionType.SET_BRIEFING_TEMPLATE_SUCCEEDED,
	ActionType.SET_BRIEFING_TEMPLATE_FAILED,
)<BriefingDetailModel, void, Error>();

export const postBriefingFileUpload = createAsyncAction(
	ActionType.POST_BRIEFING_FILE_UPLOAD_REQUEST,
	ActionType.POST_BRIEFING_FILE_UPLOAD_SUCCEEDED,
	ActionType.POST_BRIEFING_FILE_UPLOAD_FAILED,
)<BriefingFileModel, void, Error>();

export const getBriefingPublicInfo = createAsyncAction(
	ActionType.GET_BRIEFING_PUBLIC_INFO_REQUEST,
	ActionType.GET_BRIEFING_PUBLIC_INFO_SUCCEEDED,
	ActionType.GET_BRIEFING_PUBLIC_INFO_FAILED,
)<GetBriefingDetailRequestModel, BriefingPublicInfoModel, Error>();

export const postBriefingSendMail = createAsyncAction(
	ActionType.POST_BRIEFING_SEND_MAIL_REQUEST,
	ActionType.POST_BRIEFING_SEND_MAIL_SUCCEEDED,
	ActionType.POST_BRIEFING_SEND_MAIL_FAILED,
)<PostBriefingSendMailRequestModel, void, Error>();

export const setBriefingQueries = createAsyncAction(
	ActionType.SET_BRIEFING_QUERIES,
	'',
	'',
)<BriefingQueryModel[], void, Error>();

export const setBriefingSorting = createAsyncAction(
	ActionType.SET_BRIEFING_SORTING,
	'',
	'',
)<boolean, void, Error>();

export const getBriefingQty = createAsyncAction(
	ActionType.GET_BRIEFING_QTY_REQUEST,
	ActionType.GET_BRIEFING_QTY_SUCCEEDED,
	ActionType.GET_BRIEFING_QTY_FAILED,
)<GetBriefingQtyRequestModel, BriefingQtyModel, Error>();

export const makeHtmlFromBriefing = createAsyncAction(
	ActionType.MAKE_HTML_FROM_BRIEFING_REQUEST,
	ActionType.MAKE_HTML_FROM_BRIEFING_SUCCEEDED,
	ActionType.MAKE_HTML_FROM_BRIEFING_FAILED,
)<HtmlFromBriefingRequestModel, void, Error>();

export const postBriefingAnswerConfirm = createAsyncAction(
	ActionType.POST_BRIEFING_ANSWER_CONFIRM_REQUEST,
	ActionType.POST_BRIEFING_ANSWER_CONFIRM_SUCCEEDED,
	ActionType.POST_BRIEFING_ANSWER_CONFIRM_FAILED,
)<PostBriefingAnswerConfirmRequestModel, string, Error>();
