import React, { useState } from "react";
import { useDispatch } from "react-redux";

// components
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Divider, MenuItem, Menu, Tooltip } from "@material-ui/core";
import ListDetailDialog from './Dialog';
import AppDialog from "../../Common/AppDialog";
import MediaListNameDialog from "../ListNameDialog";

// image
import ImageEllipsis from '../../../assets/icon_ellipsis.svg';

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

const MediaTable = ({ list }) => {
    const classes = useStyles();
    return (
        <section>
            <Table className={classes.root}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell style={{ width: '40%' }}>リスト名</TableCell>
                        <TableCell style={{ width: '20%' }}>媒体数</TableCell>
                        <TableCell style={{ width: '33%' }}>備考</TableCell>
                        <TableCell style={{ width: '7%' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item, index) => (
                        <MediaList key={index} item={item} />
                    ))}
                </TableBody>
            </Table>
        </section>
    );
}

export default MediaTable;

const MediaList = ({ item }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClose = () => setAnchorEl(null);
    const [listNameDialog, setListNameDialog] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

    const handleRenameFavoriteList = (name: string, setErrorMessage) => {
        dispatch(MediaActionCreators.patchFavoriteMediaList.request({
            name: name,
            id: item.list_id,
            postSuccess: () => {
                setListNameDialog(false)
            },
            postError: (text: string) => setErrorMessage(text),
        }))
    }

    const handleDeleteFavoriteList = () => {
        dispatch(MediaActionCreators.deleteFavoriteMediaList.request({ id: item.list_id }));
        setDeleteDialog(false);
    }

    const [listDetailDialog, setListDetailDialog] = useState<boolean>(false);

    return (
        <>
            <TableRow>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.length}件</TableCell>
                <TableCell><Tooltip arrow title={item.memo}><p className={classes.memo}>{item.memo}</p></Tooltip></TableCell>
                <TableCell>
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <img src={ImageEllipsis} />
                    </IconButton>
                    <Menu
                        elevation={1}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => {
                            setListDetailDialog(true)
                            handleClose()
                        }}>詳細</MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            setListNameDialog(true);
                            handleClose();
                        }}>名前を変更</MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            setDeleteDialog(true)
                            handleClose()
                        }}>削除</MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
            <MediaListNameDialog
                isOpen={listNameDialog}
                handleSave={handleRenameFavoriteList}
                handleClose={() => setListNameDialog(false)}
            />
            <AppDialog
                isOpen={deleteDialog}
                closeHandle={() => setDeleteDialog(false)}
                mainHandle={handleDeleteFavoriteList}
                ButtonText='削除'
                text='削除しますか'
            />
            <ListDetailDialog
                isOpen={listDetailDialog}
                item={item}
                handleClose={() => setListDetailDialog(false)}
            />
        </>
    );
}

const useStyles = makeStyles({
    root: {
        tableLayout: 'fixed',
    },
    tableHead: {
        backgroundColor: '#eaeaea',
    },
    memo: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
})
