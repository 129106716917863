export const pressReleaseType = [
    {
        id: 0,
        label: 'デザイン',
        sub: [
            {id: 101, label: '空白'},
            {id: 102,label:'テーブル'},
            {id: 103,label:'リスト'},
            {id: 104,label:'画像 + テキスト'},
        ]
    },
    {
        id: 4,
        label: 'イベント案内',
        sub: [
            { id: 400, label: 'イベント・展示会の告知・案内'},
            { id: 401, label: 'イベント・展示会の報告'},
            { id: 402, label: 'イベント登壇の告知・案内'},
            { id: 403, label: 'イベント協賛のお知らせ'},
            { id: 404, label: 'セミナー告知・案内'},
            { id: 405, label: 'コンテストの告知・案内'},
        ]
    },
    {
        id: 3,
        label: '調査系',
        sub: [
            { id: 300, label: 'ユーザー利用度調査'},
            { id: 301, label: '意識調査・アンケート' },
            // { id: 302, label: 'ランキング発表' }
        ]
    },
    {
        id: 1,
        label: '製品・サービス系',
        sub: [
            { id: 1, label: '新サービス・新製品リリース(提供開始)' },
            // { id: 2, label: 'サービス・製品アップデート・リニューアル' },
            { id: 5, label: 'サービス・製品の取り扱い店・流通拡大' },
            { id: 13, label: 'サービス・製品のプレゼント・キャンペーン' },
            { id: 14, label: 'サービス・製品の海外展開'},
            { id: 15, label: 'サービス・製品の受賞のおしらせ' },
            { id: 16, label: 'サービス・製品の販売○周年のお知らせ' },
            { id: 17, label: 'サービス・製品の導入実績・採用実績' },
        ]
    },
    {
        id: 2,
        label: '店舗業態',
        sub: [
            { id: 200, label: '新店舗の開店のお知らせ'},
            { id: 201, label: '店舗のプレオープン' },
            { id: 202, label: '新メニューの提供開始' },
        ]
    },
    {
        id: 5,
        label: '企業活動',
        sub: [
            { id: 500, label: '社内制度・福利厚生'},
            { id: 501, label: 'ロゴ変更' },
            { id: 502, label: '業務提携' },
            { id: 503, label: '資金調達' },
        ]
    },

]
