import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from "react";
import * as ReactDOM from "react-dom";
import ReduxRoot from "./ReduxRoot";
import 'core-js/stable'
import 'cross-fetch/polyfill';

const rootEl = document.getElementById("root");
ReactDOM.render(<ReduxRoot />, rootEl);

// comment in for PWA with service worker in production mode
// registerServiceWorker();
