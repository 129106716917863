enum PressReleaseTagActionType {
    GET_PRESS_RELEASE_TAG_LIST_REQUEST = "GET_PRESS_RELEASE_TAG_LIST_REQUEST",
    GET_PRESS_RELEASE_TAG_LIST_SUCCEEDED = "GET_PRESS_RELEASE_TAG_LIST_SUCCEEDED",
    GET_PRESS_RELEASE_TAG_LIST_FAILED = "GET_PRESS_RELEASE_TAG_LIST_FAILED",

    POST_PRESS_RELEASE_TAG_REQUEST = "POST_PRESS_RELEASE_TAG_REQUEST",
    POST_PRESS_RELEASE_TAG_SUCCEEDED = "POST_PRESS_RELEASE_TAG_SUCCEEDED",
    POST_PRESS_RELEASE_TAG_FAILED = "POST_PRESS_RELEASE_TAG_FAILED",

    PATCH_PRESS_RELEASE_TAG_REQUEST = "PATCH_PRESS_RELEASE_TAG_REQUEST",
    PATCH_PRESS_RELEASE_TAG_SUCCEEDED = "PATCH_PRESS_RELEASE_TAG_SUCCEEDED",
    PATCH_PRESS_RELEASE_TAG_FAILED = "PATCH_PRESS_RELEASE_TAG_FAILED",

    DELETE_PRESS_RELEASE_TAG_REQUEST = "DELETE_PRESS_RELEASE_TAG_REQUEST",
    DELETE_PRESS_RELEASE_TAG_SUCCEEDED = "DELETE_PRESS_RELEASE_TAG_SUCCEEDED",
    DELETE_PRESS_RELEASE_TAG_FAILED = "DELETE_PRESS_RELEASE_TAG_FAILED",

    SET_PRESS_RELEASE_TAG_FORM = "SET_PRESS_RELEASE_TAG_FORM",

    RESET_PRESS_RELEASE_TAG_FORM = 'RESET_PRESS_RELEASE_TAG_FORM',

    POST_PRESS_INFO_TAG_RELATION_REQUEST = 'POST_PRESS_INFO_TAG_RELATION_REQUEST',
    POST_PRESS_INFO_TAG_RELATION_SUCCEEDED = 'POST_PRESS_INFO_TAG_RELATION_SUCCEEDED',
    POST_PRESS_INFO_TAG_RELATION_FAILED = 'POST_PRESS_INFO_TAG_RELATION_FAILED',

    DELETE_PRESS_INFO_TAG_RELATION_REQUEST = 'DELETE_PRESS_INFO_TAG_RELATION_REQUEST',
    DELETE_PRESS_INFO_TAG_RELATION_SUCCEEDED = 'DELETE_PRESS_INFO_TAG_RELATION_SUCCEEDED',
    DELETE_PRESS_INFO_TAG_RELATION_FAILED = 'DELETE_PRESS_INFO_TAG_RELATION_FAILED',

}

export default PressReleaseTagActionType;

