
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography } from "@material-ui/core";


interface TicketCardProps {
    ticketName: string
    count: number
}



const TicketCard: React.FC<TicketCardProps> = ({ ticketName, count }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card} >
            <Typography variant={"h2"} className={classes.name} >{ticketName}</Typography>
            <div className={classes.info}>
                <div className={classes.counter}>
                    <span className={classes.operator}>×</span>
                    <span className={classes.count}>{count}</span>
                </div>
            </div>
        </Card>

    )
}

export default TicketCard;

const useStyles = makeStyles({
    card: {
        margin: "24px",
        padding: "20px",
        width: "232px",
        height: "144px",
        backgroundColor: "#f3f7f4"
    },
    name: {
        fontSize: "16px"
    },
    info: {
        display: "flex",
        position: "relative",
        top: "48px",
        justifyContent: "flex-end"

    },
    counter: {
        borderRadius: "20px",
        backgroundColor: "#2eb964",
        width: "60px",
        padding: "2px",
        textAlign: "center"
    },
    operator: {
        color: "white",
        fontSize: "16px",
        fontWeight: "bold",
        marginRight: "5px"
    },
    count: {
        color: "white",
        fontSize: "20px",
        fontWeight: "bold"
    }

});
