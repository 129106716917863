import {Switch, Route, RouteComponentProps} from "react-router";
import * as React from "react";
import './app.module.scss';

// page
import AlertDialog from "./components/Common/AlertDialog";
import HelpLayout from "./components/Layout/Help";
import Help from "./pages/Help";
import Links from "./pages/Links";
import Terms from "./pages/Links/Terms";
import Policy from "./pages/Links/Policy";
import Sctl from "./pages/Links/Sctl";
import {connect} from "react-redux";
import withRoot from "./withRoot";
import {withWidth} from "@material-ui/core";

interface Props extends RouteComponentProps<void> {}

const App: React.FC<Props> = () => {

	return (
		<>
			<HelpLayout>
				<AlertDialog/>
				<Switch>
					<Route exact={true} path="/links" component={Links}/>
					<Route exact={true} path="/terms" component={Terms}/>
					<Route exact={true} path="/stcl" component={Sctl}/>
					<Route exact={true} path="/policy" component={Policy}/>
					<Route path="/help" component={Help}/>
				</Switch>
			</HelpLayout>
		</>
	);
}


export default connect(null)(withRoot(withWidth()(App)));
