import React from "react";
import {useSelector} from "react-redux";

//material-ui
import {Dialog, DialogActions, DialogTitle, DialogContent, Button} from "@material-ui/core";

//component
import {TicketConfirmDialogContent} from "./Content";

//model
import {RootState} from "../../../../reducers";

//config
import {PAGE_RESERVE} from "../../../../config/page_type";
import {
    STATUS_ACCEPT,
    STATUS_PUBLIC_ACCEPT,
    STATUS_PUBLIC_REJECT,
    STATUS_PUBLIC_REVIEW,
    STATUS_REVIEW
} from "../../../../config/status_type";

const ticketQtySelector = (state: RootState) => state.ticket.qty;


interface Props {
    isOpen: boolean
    handleClick: () => void
    handleClose: () => void
    page: string
    status: number
    isReviewSkip: boolean
}

export const TicketConfirmDialog: React.FC<Props> = (
    {isOpen, handleClick, handleClose, page, status, isReviewSkip}
) => {
    const ticketQty = useSelector(ticketQtySelector);
    const totalAvailableTicketQty = ticketQty['default']['available'] + ticketQty['non_default']['available'];
    const [title, buttonDisabled] = makeTitleAndDisabled(page, status, isReviewSkip, totalAvailableTicketQty);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <TicketConfirmDialogContent
                    page={page}
                    status={status}
                    isReviewSkip={isReviewSkip}
                    ticketQty={ticketQty}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
                    キャンセル
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={handleClick}
                    disabled={buttonDisabled}
                >
                    決定
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const makeTitleAndDisabled = (page: string, status: number, isReviewSkip: boolean, totalAvailableTicketQty: number): [string, boolean] => {
    if (page === PAGE_RESERVE && (status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) && isReviewSkip) {
        //予約確定する
        return ['予約確定', totalAvailableTicketQty === 0]
    } else if (page === PAGE_RESERVE && status === STATUS_PUBLIC_ACCEPT) {
        //配信設定を修正する
        return ['配信設定の修正', false]
    } else if (page === PAGE_RESERVE && status === STATUS_PUBLIC_REVIEW && !isReviewSkip) {
        //配信予約のレビューを承認する
        return ['配信予約を承認', false]
    }
    return ['確認ダイアログ', true]
}
