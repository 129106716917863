import React, { useEffect } from "react";
import { useState } from "react";
import {useDispatch, useSelector} from "react-redux";

// component
import AddDialog from "./AddDialog";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import AvatarChip from "../../../../../../Common/AvatarChip";

// style
import style from "./create_user.module.scss";

// img
import ImageEllipsis from "../../../../../../../assets/icon_ellipsis.svg";

// actions
import * as ActionCreators from "../../../../../../../actions/PressRelease/ActionCreator";

// state
import { RootState } from "../../../../../../../reducers";
import {PressReleaseMemberModel} from "../../../../../../../model/PressReleaseModel";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;

const CreateUser: React.FC = () => {

	const dispatch = useDispatch();
    const [addDialog, setAddDialog] = useState(false);
	const pressRelease = useSelector(pressReleaseSelector);
	const me = useSelector(meSelector);
    const hasEdit = pressRelease.role.edit;

	useEffect(() => {
		if (pressRelease.detail.press_id) dispatch(ActionCreators.getPressReleaseCreator.request());
	}, [pressRelease.detail.press_id]);

	return (
		<>
			<ul className={style.list}>
				<li>
					<AvatarChip user={pressRelease.detail.create_user}　component={<span className={style.creator}>作成者</span>}/>
				</li>
				{pressRelease.creators.map( (creator, index) =>
					<ListMenu creator={creator} key={index} />

				)}
			</ul>
			{hasEdit &&
				<div className={style.add}>
					<Button variant="outlined" size="small" onClick={() => setAddDialog(true)}>
						追加
					</Button>
				</div>
			}
			<AddDialog isOpen={addDialog} closeHandle={() => setAddDialog(false)}/>
		</>
	);
};

export default CreateUser;


interface ListLayoutItemProps {
	creator: {
		id: string
	}
}

const ListMenu: React.FC<ListLayoutItemProps> = ({ creator }) => {

	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		dispatch(ActionCreators.deletePressReleaseCreator.request(creator.id));
		setAnchorEl(null);
	};

	return (
		<li>
			<AvatarChip user={creator} component={<IconButton onClick={handleClick} className={style.iconMenu}>
				<img src={ImageEllipsis}/>
			</IconButton>}/>
			<Menu
				elevation={1}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className={style.popupMenu}
			>
				<MenuItem onClick={handleDelete}>
					削除
				</MenuItem>
			</Menu>
		</li>
	);
}


