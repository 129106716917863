import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import * as SagaMonitorCreators from "./actions/SagaMonitor/ActionCreator";
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'

export const history = createBrowserHistory()

export default () => {

	const bindMiddleware = () => {
		if (process.env.NODE_ENV !== 'production') {
			const { composeWithDevTools } = require('redux-devtools-extension')
			return composeWithDevTools(compose(
				applyMiddleware(
					routerMiddleware(history),
					sagaMiddleware
				),
			))
		}
		return applyMiddleware(
			routerMiddleware(history),
			sagaMiddleware
		)
	}

	// この二つの F は saga 全体を監視して、通信中か否かを判断する為に使用
	const watchEffectEnd = (effectId, res) => {
		store.dispatch(SagaMonitorCreators.deleteEffect.request(effectId))
	};

	const sagaMonitor = {
		effectRejected: (e) => {
			store.dispatch(SagaMonitorCreators.deleteEffect.request(e.effectId))
		},
		effectTriggered: (event) => {
			if (event.effect.type === 'CALL') {
				store.dispatch(SagaMonitorCreators.setEffect.request(event.effectId))
			}
		},
		effectResolved: watchEffectEnd,
	}


	const sagaMiddleware = createSagaMiddleware({sagaMonitor: sagaMonitor})

	const store = createStore (
		createRootReducer(history),
		bindMiddleware()
	);

	sagaMiddleware.run(rootSaga);
	return store;
}
