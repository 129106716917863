import React from 'react';
import { useSelector } from 'react-redux';

// state
import { RootState } from '../../../reducers';

// components
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const mediaSelector = (state: RootState) => state.media;
const pressReleaseSelector = (state: RootState) => state.pressRelease;

const MediaMenu = ({ handleSetAutoListPage, handleSetCustomListPage }) => {
    const classes = useStyles();
    const reserve = useSelector(pressReleaseReserveSelector);
    const { status } = useSelector(pressReleaseSelector).detail;
    const media = useSelector(mediaSelector);
    const listId = reserve.data.auto.list_id;
    return (
        <List className={classes.root}>
            <ListItem className={classes.listItem}>
                <ListItemText primaryTypographyProps={{ className: classes.default }} primary='メディアリスト' />
            </ListItem>
            <ListItem button className={classes.nested} onClick={() => handleSetAutoListPage()} disabled={(status === 5 || status >= 7) && listId > 0}>
                <ListItemText primaryTypographyProps={{ className: classes.default }} primary='AI自動選定リスト' className={listId === 0 ? classes.active : ''} />
            </ListItem>
            <List component="div" disablePadding>
                {media.favoriteList.results.map((list, index) => (
                    <ListItem key={index} button className={classes.nested} onClick={() => handleSetCustomListPage(list.list_id)} disabled={(status === 5 || status >= 7) && listId != list.list_id}>
                        <ListItemText primaryTypographyProps={{ className: classes.default }}
                            primary={list.name} className={listId === list.list_id ? classes.active : ''} />
                    </ListItem>
                ))}
            </List>
            <Divider />
        </List>
    )
}

export default MediaMenu;

const useStyles = makeStyles({
    root: {
        width: 180,
        overflowY: 'scroll',
    },
    listItem: {
        background: '#F9FBF9',
        color: '#6D7470',
        borderBottom: '0.1rem solid #E2E9E5',
        height: 30,
    },
    nested: {
        background: '#fff',
    },
    default: {
        padding: '5px 10px',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    active: {
        backgroundColor: '#E3F5EA',
        borderRadius: 4,
        width: '100%',
        display: 'flex',
    },
});
