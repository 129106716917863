import React from "react";
import {Route, RouteComponentProps, Switch} from "react-router-dom";

import Registration from "../../components/Help/Registration";
import PressRelease from "../../components/Help/PressRelease";
import Editor from "../../components/Help/Editor";
import PressKit from "../../components/Help/PressKit";
import Send from "../../components/Help/Send";
import Company from "../../components/Help/Setting/Company";
import Service from "../../components/Help/Setting/Service";
import User from "../../components/Help/Setting/User";
import Project from "../../components/Help/Setting/Project";
import Member from "../../components/Help/Member"
import Media from "../../components/Help/Media";
import WebPage from "../../components/Help/WebPage";
import Export from "../../components/Help/Export";
import RadarKeyword from "../../components/Help/Setting/RadarKeyword";
import PlanAndTicket from "../../components/Help/Setting/PlanAndTicket";
import Payment from "../../components/Help/Setting/Payment";
import Topic from "../../components/Help/Topic";
import DashBoard from "../../components/Help/DashBoard";
import Briefing from "../../components/Help/Briefing";
import Radar from "../../components/Help/Radar";

import Menu from "../../components/Help/Menu";

interface Props extends RouteComponentProps<void> {

}

const Help: React.FC<Props> = () => {

    return (
        <>
            <Switch>
                <Menu>
                    <Route path="/help/registration" component={Registration}/>
                    <Route path="/help/editor" component={Editor}/>
                    <Route path="/help/press_release" component={PressRelease}/>
                    <Route path="/help/press_kit" component={PressKit}/>
                    <Route path="/help/send" component={Send}/>
                    <Route path="/help/company" component={Company}/>
                    <Route path="/help/project" component={Project}/>
                    <Route path="/help/service" component={Service}/>
                    <Route path="/help/user" component={User}/>
                    <Route path="/help/member" component={Member}/>
                    <Route path="/help/media" component={Media}/>
                    <Route path="/help/web_page" component={WebPage}/>
                    <Route path="/help/export" component={Export}/>
                    <Route path="/help/radar_keyword" component={RadarKeyword}/>
                    <Route path="/help/plan" component={PlanAndTicket}/>
                    <Route path="/help/payment" component={Payment}/>
                    <Route path="/help/topic" component={Topic}/>
                    <Route path="/help/dashboard" component={DashBoard}/>
                    <Route path="/help/briefing" component={Briefing}/>
                    <Route path="/help/radar" component={Radar}/>
                </Menu>
            </Switch>
        </>
    );
}

export default Help;
