import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import { CircularProgress, Typography } from '@material-ui/core';
import AppLayout from "../../components/Layout/App";
import TemplateList from '../../components/Briefing/List/Template';
import { makeStyles } from "@material-ui/core/styles";

// img

// actions
import * as BriefingActionCreators from '../../actions/Briefing/ActionCreator';

// config

// state
import { RootState } from '../../reducers';
const briefingListSelector = (state: RootState) => state.briefingList;
const groupSelector = (state: RootState) => state.group;

const SelectTemplate: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const briefingList = useSelector(briefingListSelector);
    const group = useSelector(groupSelector);

    useEffect(() => {
        if (group.selectedId) {
            dispatch(BriefingActionCreators.getBriefingListTemplate.request());
            dispatch(BriefingActionCreators.getBriefingQty.request({ groupId: group.selectedId }));
        }
    }, [group.selectedId]);

    const templateList = briefingList.template.results;

    return (
        <AppLayout>
            <div className={classes.title}>
                <h2 className={classes.titleText}>ブリーフィング</h2>
            </div>
            <Typography>
                テンプレートを選択してください
                    </Typography>
            {templateList.length > 0 ?
                <TemplateList list={templateList} />
                :
                <div className={classes.circularProgress}>
                    <CircularProgress />
                </div>}
        </AppLayout>
    );
}

export default SelectTemplate;

const useStyles = makeStyles({
    title: {
        marginBottom: 20,
        color: '#616763',
    },
    titleText: {
        fontSize: 22,
    },
    upgradeButton: {
        margin: '20px 0 40px',
    },
    briefingImg: {
        margin: "30px auto",
        width: 250,
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        padding: '200px 0',
    },
})
