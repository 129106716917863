import React from 'react';
import Title from '../Title'
import Typography from "@material-ui/core/Typography";
import PageTitle from "../PageTitle";

//style
import style from "../help.module.scss";

//img
import Image01 from '../../../assets/help/export/image01.png';
import Image02 from '../../../assets/help/export/image02.png';
import Image03 from '../../../assets/help/export/image03.png';
import Image04 from '../../../assets/help/export/image04.png';

const Export = () => {
	return (
		<article className={style.root}>
			<a id="1" className={style.anchor}/>
			<div className={style.box}>
				<PageTitle title={"HTML&PDF出力"}/>
				<Title title={"1.HTML&PDF出力"}/>
				<Typography gutterBottom variant={"body1"}>
					右パネルの「HTML&amp;PDF出力」ボタンを押下します。
				</Typography>
				<div className={style.img}>
					<img src={Image01}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					プレスリリースのHTMLタグのコピーとPDFのダウンロードが行えるダイアログが出現します。
				</Typography>
				<div className={style.img}>
					<img src={Image02}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					「クリップボードにコピー」ボタンを押下することで、HTMLタグがクリップボードにコピーされます。<br/>
					コピーしたHTMLタグは、bodyタグの中に貼り付けてください。<br/>
					自社用リリースページ等にお使いください。
				</Typography>
				<div className={style.img}>
					<img src={Image03}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					「PDFダウンロード」ボタンを押下することで、新しいタブでPDFが開きます。<br/>
				</Typography>
				<div className={style.img}>
					<img src={Image04}/>
				</div>
			</div>
		</article>
	);
};

export default Export;

