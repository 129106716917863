import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

// components
import { BasicDropzone } from '../../Common/Dropzone';
import { Button, Typography, Box, CircularProgress } from '@material-ui/core';

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

// state
import { RootState } from '../../../reducers';
import { makeStyles } from "@material-ui/core/styles";

// img
import UploadIcon from '../../../assets/csv_upload_icon.svg';


const csvSelector = (state: RootState) => state.media.csv;
const mediaSelector = (state: RootState) => state.media;
const companySelector = (state: RootState) => state.company;

const Content = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState<0 | 1>(0);

    const handlePostCsvFile = (files) => {
        setPage(1);
        dispatch(MediaActionCreators.postCsvImport.request({ data: files[0] }))
    }

    return (
        <>
            {page === 0 && <BasicDropzone uploadActionDispatcher={handlePostCsvFile} text='インポート' img={UploadIcon} />}
            {page === 1 && <ImportResult handleBackPage={() => setPage(0)} />}
        </>
    );
}

export default Content;

const ImportResult = ({ handleBackPage }) => {
    const classes = useStyles();
    const media = useSelector(mediaSelector);
    const company = useSelector(companySelector);
    const { fetched, result, error } = useSelector(csvSelector);
    const { success, required, duplicate, invalid } = result;
    const { plan } = company;
    const mediaLimit = plan.media_limit;
    const [displayButton, setDisplayButton] = useState<boolean>(false);

    useEffect(() => {
        if (mediaLimit) {
            setDisplayButton(media.list.count < mediaLimit);
        } else {
            setDisplayButton(true);
        }
    }, [media.list]);

    return (
        <Box className={classes.root}>
            {fetched ?
                <>
                    <Box>
                        {success.length === 0 && required.length === 0 && duplicate.length === 0 && invalid.length === 0 && !error &&
                            <Message text='メディアのデータが見つかりませんでした' is_error={true} />}
                        {success.length > 0 && <Message text={`${success.length}件のメディアを登録しました。`} is_error={false} />}
                        {required.length > 0 && <Message text={`${required.join(',')}行目は、必須項目が入力されていません。`} is_error={true} />}
                        {duplicate.length > 0 && <Message text={`${duplicate.join(',')}行目のメールアドレスのメディアは既に登録されています。`} is_error={true} />}
                        {invalid.length > 0 && <Message text={`${invalid.join(',')}行目は、入力値が正しくありません。`} is_error={true} />}
                        {error && <Message text={error} is_error={true} />}
                    </Box>
                    {displayButton ?
                        <Button variant='outlined' color='primary' className={classes.backButton} onClick={handleBackPage}>
                            続けてインポートする
                        </Button>
                        :
                        <Box className={classes.upgradeBox}>
                            <Typography variant="body1">
                                登録できるメディア数が上限に達しています。<br />
                                メディアの追加を行うには、プランのアップグレードが必要です。<br />
                            </Typography>
                            <NavLink exact to={"/setting/plan"}>
                                <Button variant="contained" size="large" color="primary" className={classes.upgradeButton}>
                                    プランのアップグレード
                                </Button>
                            </NavLink>
                        </Box>
                    }
                </>
                :
                <CircularProgress className={classes.circularProgress} />
            }
        </Box>
    );
}

const Message = ({ text, is_error }) => {
    const classes = useStyles();
    return (
        <Typography variant='body1' className={classes.message}>
            ・<span className={is_error ? classes.error : ''}>{text}</span>
        </Typography>
    );
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 30,
    },
    message: {
        marginBottom: 15,
    },
    error: {
        marginBottom: 15,
        color: 'red',
    },
    backButton: {
        margin: '0 auto',
    },
    circularProgress: {
        margin: '0 auto',
    },
    upgradeBox: {
        textAlign: 'center',
        marginTop: 10
    },
    upgradeButton: {
        marginTop: 5
    }
})
