import ActionType from './ActionType';

import { PlanListModel } from '../../model/PlanModel';
import { createAsyncAction } from 'typesafe-actions';

export const getPlanList = createAsyncAction(
	ActionType.GET_PLAN_LIST_REQUEST,
	ActionType.GET_PLAN_LIST_SUCCEEDED,
	ActionType.GET_PLAN_LIST_FAILED
)<void, PlanListModel, Error>();

