import { PdfHTML } from "./template/PdfHTML";
import { PdfHeader } from "./template/PdfHeader";
import { PdfFooter } from "./template/PdfFooter";


const changeImageToBg = (images) => {
    images.forEach((el) => {
        const parentNode = el.parentNode;
        const newChild = document.createElement('div');
        const src = el.getAttribute('src');
        newChild.style.height = el.style.height;
        newChild.style.width = el.style.width;
        newChild.style.backgroundImage = `url( ${src} )`;
        newChild.style.backgroundSize = `contain`;
        const classList:[] = el.classList;
        const list = Array.from(classList);
        list.map(item => {
            newChild.classList.add(item)
        })
        parentNode.replaceChild(newChild, el);
    });
}

//tableのwidthとmarginの単位を%からpxに変換
const setTableStyle = (tables) => {
    [...tables].forEach(item => {
        if (item.style.width === '100%') {
            item.style.width = '684px';
        } else {
            const splitWidth = item.style.width.split('%');
            const parsedIntWidth: number = parseInt(splitWidth[0]);
            const width: number = 684 * (parsedIntWidth / 100);
            item.style.width = `${width}px`;

            if (item.style.marginLeft) {
                const openParenthesisIndex: number = item.style.marginLeft.indexOf('(');
                const percentIndex: number = item.style.marginLeft.indexOf('%');
                const slicedMarginLeft: string = item.style.marginLeft.slice(openParenthesisIndex + 1, percentIndex);

                const parsedIntMarginLeft: number = parseInt(slicedMarginLeft);
                const marginLeft: number = 684 * (parsedIntMarginLeft / 100);
                item.style.marginLeft = `${marginLeft}px`;
            }
            if (item.style.marginRight) {
                const openParenthesisIndex: number = item.style.marginRight.indexOf('(');
                const percentIndex: number = item.style.marginRight.indexOf('%');
                const slicedMarginRight: string = item.style.marginRight.slice(openParenthesisIndex + 1, percentIndex);

                const parsedIntMarginRight: number = parseInt(slicedMarginRight);
                const marginRight: number = 684 * (parsedIntMarginRight / 100);
                item.style.marginRight = `${marginRight}px`;
            }
        }
    });
}

export const createPDFDom = (html, css, is_header_loop, is_footer_loop) => {

    const tempEl = document.createElement('div');
    tempEl.innerHTML = html;

    const headerTag:HTMLElement = tempEl.querySelector('.header') as HTMLElement;
    const footerTag:HTMLElement = tempEl.querySelector('.footer') as HTMLElement;

    const headerTagTable:any = headerTag.querySelectorAll("table");
    setTableStyle(headerTagTable);
    const footerTagTable:any = footerTag.querySelectorAll("table");
    setTableStyle(footerTagTable);

    const headerImages:any = headerTag.querySelectorAll('img');
    changeImageToBg(headerImages)
    const footerImages:any = footerTag.querySelectorAll('img');
    changeImageToBg(footerImages)

    const header:string = PdfHeader.replace(/{{HTML}}/g, headerTag.outerHTML).replace(/{{style}}/g, '');
    const footer:string = PdfFooter.replace(/{{HTML}}/g, footerTag.outerHTML).replace(/{{style}}/g, '');
    const headerParent:HTMLElement = headerTag.parentNode as HTMLElement;
    const footerParent:HTMLElement = footerTag.parentNode as HTMLElement;
    headerParent.removeChild(headerTag);
    footerParent.removeChild(footerTag);

    const mainElement:any = tempEl.querySelectorAll("table");
    setTableStyle(mainElement);

    const main:string = PdfHTML.replace(/{{HTML}}/g, tempEl.outerHTML).replace(/{{style}}/g, '');

    const pdfHtml = {
            "main": main,
            "main_css": "",
            "header": header,
            "header_css": "header {position: fixed;  top: 0;  left: 15px;  width: 100%;}",
            "footer": footer,
            "footer_css": "footer {position: fixed;  bottom: 0;  left: 15px;  width: 100%;}",
            "is_header_loop": is_header_loop,
            "is_footer_loop": is_footer_loop,
            "side_margin": 1,
            "extra_vertical_margin": 38
    };
    return pdfHtml;
};
