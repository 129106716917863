import React from 'react';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Tooltip } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';

interface Props {
    users: string[],
}

const UsersAvatar: React.FC<Props> = ({ users }) => {
    const classes = useStyles();
    let notLoginUserCount = 0;

    const otherUserNames = users.slice(3).map(user => {
        if (user) return user
        else notLoginUserCount += 1;
    });
    if (notLoginUserCount) otherUserNames.push(`${notLoginUserCount}名の未ログインユーザー`);
    const joinedOtherUserNames = otherUserNames.filter(v => v).join('\n');

    return (
        <div className={classes.avatarGroup}>
            <AvatarGroup max={4} classes={{
                avatar: classes.avatar,
            }}>
                {users.map((user, index) => (
                    <Tooltip key={index} title={user} arrow>
                        <Avatar src={user} alt={user} />
                    </Tooltip>
                ))}
            </AvatarGroup>
            {joinedOtherUserNames &&
                <Tooltip title={<span className={classes.otherTooltip}>{joinedOtherUserNames}</span>} arrow>
                    <div className={classes.others}></div>
                </Tooltip>}
        </div>
    );
}

export default UsersAvatar;

const useStyles = makeStyles({
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    others: {
        height: 35,
        width: 35,
        zIndex: 0,
        marginLeft: -35,
        borderRadius: 20,
    },
    otherTooltip: {
        whiteSpace: 'pre-line',
    },
    avatar: {
        height: 35,
        width: 35,
    },
})
