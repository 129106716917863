import ActionType from './ActionType';
import { createAsyncAction } from 'typesafe-actions';

//model
import {
	PostTopicWordRequestPayloadModel, TopicSelectedModel,
	TopicWordSearchResultContentModel
} from "../../model/TopicModel";

export const getProceedingsTopics = createAsyncAction(
	ActionType.GET_PROCEEDINGS_TOPICS_REQUEST,
	ActionType.GET_PROCEEDINGS_TOPICS_SUCCEEDED,
	ActionType.GET_PROCEEDINGS_TOPICS_FAILED
)<void, void, Error>();

export const postTopicWord = createAsyncAction(
	ActionType.POST_TOPIC_WORD_REQUEST,
	ActionType.POST_TOPIC_WORD_SUCCEEDED,
	ActionType.POST_TOPIC_WORD_FAILED
)<PostTopicWordRequestPayloadModel, Array<TopicWordSearchResultContentModel>, Error>();

export const setSelectedTopicWord = createAsyncAction(
	ActionType.SET_SELECTED_TOPIC_WORD,
	'',
	''
)<TopicSelectedModel, void, void>();

export const setLoading = createAsyncAction(
	ActionType.SET_TOPIC_LOADING,
	'',
	''
)<boolean, void, void>();
