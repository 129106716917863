import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchDelete, fetchGet, fetchPatch, fetchPost } from './fetch';
import { selectGroup, selectPressRelease, selectPressReleaseList, selectRouter } from './selector';

// action
import PressReleaseTagActionType from '../actions/PressReleaseTag/ActionType';
import * as ActionCreators from '../actions/PressReleaseTag/ActionCreator';
import * as PressReleaseActionCreators from '../actions/PressRelease/ActionCreator';
// config
import { theme } from '../withRoot';

function* getPressReleaseTagList(action: ReturnType<typeof ActionCreators.getPressReleaseTagList.request>) {
    try {
        const group = yield select(selectGroup);
        const request: any = action.payload;
        const displayCount = request.limit || 10;
        let offset = request.offset;
        offset = offset <= 1 ? 0 : (offset - 1) * displayCount;
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/tags/?offset=${offset}&limit=${displayCount}`);
        yield put(ActionCreators.getPressReleaseTagList.success(Object.assign({}, { offset: request.offset }, data, { displayCount })));
    } catch (e) {
        yield put(ActionCreators.getPressReleaseTagList.failure(e));
    }
}

function* postPressReleaseTag(action: ReturnType<typeof ActionCreators.postPressReleaseTag.request>) {
    try {
        const group = yield select(selectGroup);
        const request: any = action.payload;
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/tags`, request);
        yield put(ActionCreators.postPressReleaseTag.success());
        if (!error) {
            yield put(ActionCreators.resetPressReleaseTagForm.request());
        }
        const router = yield select(selectRouter);
        const pressRelease = yield select(selectPressRelease);
        const is_detail = !!(router.location.pathname.indexOf(pressRelease.detail.press_id) > -1 ? 1 : 0);
        yield put(ActionCreators.getPressReleaseTagList.request({ offset: 0 }));
    } catch (e) {
        yield put(ActionCreators.postPressReleaseTag.failure(e));
    }
}

function* deletePressReleaseTag(action: ReturnType<typeof ActionCreators.deletePressReleaseTag.request>) {
    try {
        const group = yield select(selectGroup);
        const request: any = action.payload;
        const [data, error] = yield call(fetchDelete, `groups/${group.selectedId}/tags/${request.id}/`);
        yield put(ActionCreators.deletePressReleaseTag.success());
        yield put(ActionCreators.getPressReleaseTagList.request({ offset: 0 }));
    } catch (e) {
        yield put(ActionCreators.deletePressReleaseTag.failure(e));
    }
}

function* patchPressReleaseTag(action: ReturnType<typeof ActionCreators.patchPressReleaseTag.request>) {
    try {
        const group = yield select(selectGroup);
        const request: any = action.payload;
        const [data, error] = yield call(fetchPatch, `groups/${group.selectedId}/tags/${request.id}`, request);
        yield put(ActionCreators.patchPressReleaseTag.success());
        if (!error) {
            yield put(ActionCreators.resetPressReleaseTagForm.request());
        }
        yield put(ActionCreators.getPressReleaseTagList.request({ offset: 0 }));
    } catch (e) {
        yield put(ActionCreators.patchPressReleaseTag.failure(e));
    }
}

function* postPressInfoTagRelation(action: ReturnType<typeof ActionCreators.postPressInfoTagRelation.request>) {
    try {
        const group = yield select(selectGroup);
        const request: any = action.payload;
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/press_info/${request.press_id}/tags`, request);
        yield put(ActionCreators.postPressInfoTagRelation.success());
        const router = yield select(selectRouter);
        const is_detail = !!(router.location.pathname.indexOf(request.press_id) > -1 ? 1 : 0);
        if (!is_detail) {
            yield put(PressReleaseActionCreators.getPressReleaseListSuitableForCurrentPage.request());
        }
    } catch (e) {
        yield put(ActionCreators.postPressInfoTagRelation.failure(e));
    }
}

function* deletePressInfoTagRelation(action: ReturnType<typeof ActionCreators.deletePressInfoTagRelation.request>) {
    try {
        const group = yield select(selectGroup);
        const request: any = action.payload;
        const [data, error] = yield call(fetchDelete, `groups/${group.selectedId}/press_info/${request.press_id}/tags/${request.tag_id}/`);
        yield put(ActionCreators.deletePressInfoTagRelation.success());
        const router = yield select(selectRouter);
        const is_detail = !!(router.location.pathname.indexOf(request.press_id) > -1 ? 1 : 0);
        if (!is_detail) {
            yield put(PressReleaseActionCreators.getPressReleaseListSuitableForCurrentPage.request());
        }
    } catch (e) {
        yield put(ActionCreators.deletePressInfoTagRelation.failure(e));
    }
}

const pressReleaseTagSaga = [
    takeLatest(PressReleaseTagActionType.GET_PRESS_RELEASE_TAG_LIST_REQUEST, getPressReleaseTagList),
    takeLatest(PressReleaseTagActionType.POST_PRESS_RELEASE_TAG_REQUEST, postPressReleaseTag),
    takeLatest(PressReleaseTagActionType.DELETE_PRESS_RELEASE_TAG_REQUEST, deletePressReleaseTag),
    takeLatest(PressReleaseTagActionType.PATCH_PRESS_RELEASE_TAG_REQUEST, patchPressReleaseTag),
    takeLatest(PressReleaseTagActionType.POST_PRESS_INFO_TAG_RELATION_REQUEST, postPressInfoTagRelation),
    takeLatest(PressReleaseTagActionType.DELETE_PRESS_INFO_TAG_RELATION_REQUEST, deletePressInfoTagRelation),
];

export default pressReleaseTagSaga;
