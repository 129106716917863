import React from 'react';
import { useSelector } from 'react-redux'

// component
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import Table from './Table';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

interface IProps { }

const Answer: React.FC<IProps> = () => {
    const classes = useStyles()
    const { body, id, fetched } = useSelector(briefingSelector).detail;

    return (
        <div className={classes.container}>
            <div className={classes.inner}>
                {body.name &&
                    <Typography className={classes.title}>
                        タイトル：<span className={classes.name}>{body.name}</span>
                    </Typography>}
                {fetched ?
                    <div className={classes.table}>
                        <Table body={body} id={id} />
                    </div>
                    :
                    <div className={classes.circularProgress}>
                        <CircularProgress />
                    </div>}
            </div>
        </div>
    )
}

export default Answer;

const useStyles = makeStyles({
    container: {
        margin: '0 auto',
        width: '100%',
    },
    inner: {
        width: '100%',
        maxWidth: 1000,
        padding: '68px 24px',
        margin: '0 auto',
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        padding: '200px 0',
    },
    title: {
        padding: "10px 0",
    },
    name: {
        color: "#6D7470",
        fontSize: 18,
        fontWeight: "bold",
    },
    table: {
        overflowX: "scroll",
    },
});
