import { createAsyncAction } from "typesafe-actions";
import ActionType from "./ActionType"
import {OrderInfoListRequestModel, OrderInfoListModel,} from "../../model/OrderInfoModel";


export const getOrderInfoList = createAsyncAction(
    ActionType.GET_ORDER_INFO_LIST_REQUEST,
    ActionType.GET_ORDER_INFO_LIST_SUCCEEDED,
    ActionType.GET_ORDER_INFO_LIST_FAILED
)<OrderInfoListRequestModel, OrderInfoListModel, Error>();
