import React from "react";
import {
  Field,
  reduxForm,
  InjectedFormProps,
  startSubmit,
  stopSubmit,
  reset
} from "redux-form";
import {push} from "connected-react-router";

//actions
import * as ContactActionCreators from "../../actions/Contact/ActionCreator";

// components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "../Field/TextField";
import SettingTitle from "../Common/SettingTitle";
import {useDispatch, useSelector} from "react-redux";

// style
import style from "./form.module.scss";

// reducers
import {RootState} from "../../reducers";

const companySelector = (state: RootState) => state.company;
const meSelector = (state: RootState) => state.me;

const PlanApplyForm: React.FC<InjectedFormProps> = props => {
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const me = useSelector(meSelector);
  const {handleSubmit, submitting, error, locationState} = props;

  const onSubmitPlanApply = (values: any) => {
    dispatch(startSubmit("PLAN_APPLY"));

    const postValues = {
      first_name: me.first_name,
      last_name: me.last_name,
      email: me.email,
      company: company.name,
      phone: company.tel,
      type: `${locationState.planName}プランへ変更`,
      text: values.text ? values.text : "",
      successHandler,
      failedHandler
    };

    dispatch(ContactActionCreators.postContact.request(postValues));
  };

  const successHandler = () => {
    dispatch(stopSubmit("PLAN_APPLY"));
    dispatch(reset("PLAN_APPLY"));
    dispatch(push(`/setting/plan/apply/complete/`));
  };

  const failedHandler = () => {
    dispatch(
      stopSubmit("PLAN_APPLY", {
        _error: "申請ができませんでした。\n時間を置いて、再度お試しください。"
      })
    );
  };

  return (
    <div className={style.root}>
      <SettingTitle text="プランの変更申請" />
      <form onSubmit={handleSubmit(onSubmitPlanApply)}>
        <p className={style.planDesc}>
          ログインしたメールアドレスに、担当より通常1営業日以内にご連絡いたします。
        </p>
        <ul className={style.item}>
          <li>
            <label>変更後のプラン</label>
            <div className={style.form}>
              {locationState && locationState.planName ? (
                <p className={style.planDesc}>{locationState.planName}プラン</p>
              ) : (
                <p className={style.planDesc}>プランが選択されていません。</p>
              )}
            </div>
          </li>
          <li>
            <label>備考入力 (任意)</label>
            <div className={style.form}>
              <Field name="text" type="textarea" component={TextField} />
            </div>
          </li>
        </ul>
        {error && (
          <Typography
            align="center"
            color="error"
            style={{whiteSpace: "pre-wrap", marginTop: 15}}
          >
            {error}
          </Typography>
        )}
        <div className={style.shortActions}>
          <Button
            variant="contained"
            size="large"
            type="submit"
            color="primary"
            disabled={submitting || !locationState || !locationState.planName}
          >
            申請する
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "PLAN_APPLY",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(PlanApplyForm);
