import { imageFileExtensionList } from "../config/fileIcons";

export const exportFile = (payload) => {
    const data = JSON.stringify(payload);
    const blob = new Blob([data], {type: "text/plain"});
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.target = '_blank';
    a.download = 'template.json';
    a.click();
}

export const getJSON = (callback, template) => {
    const req = new XMLHttpRequest();
    req.onreadystatechange = function() {
        if(req.readyState == 4 && req.status == 200){
            if(req.responseText) {
                var data = JSON.parse(req.responseText)
                callback(data)
            }

        }
    };
    req.open("GET", `/template/${template}.json`, false);
    req.send(null);
}

export const getFileType =(file): string => {
    const splitArr = file.filename.split(".");
    const fileExtension = splitArr[splitArr.length - 1];
    const type = file.type ? file.type : fileExtension;
    return type;
};

export const isImage = (fileType: string): boolean => {
    if (imageFileExtensionList.indexOf(fileType) == -1) {
        return false;
    }
    return true;
};

const reduceDigit = (size: number): any => {
    const strSize = String(size);
    if (strSize.length > 4) {
        const reducedSize = Number(strSize.slice(0, -1));
        return reduceDigit(reducedSize);
    }
    return size;
};

export const convertFileSize = (size: number): string => {
    if (size < 1000) {
        return `${size}B`;
    }
    const kb = size / 1000;
    const mb = kb / 1000;
    const gb = mb / 1000;
    if (kb < 1000) {
        const reducedKbSize = reduceDigit(kb);
        return `${reducedKbSize}KB`;
    } else if (mb < 1000) {
        const reducedMbSize = reduceDigit(mb);
        return `${reducedMbSize}MB`;
    } else if (gb < 1000) {
        const reducedGbSize = reduceDigit(gb);
        return `${reducedGbSize}GB`;
    }
    return `${Math.round(gb)}GB`;
};

export const downloadPDF = (payload) => {
    const a = document.createElement("a");
    a.href = payload;
    a.click();
};
