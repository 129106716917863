import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// components
import Layout from "./EditLayout";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "../../components/Briefing/Edit";
import Header from '../../components/Briefing/Common/Header';

// config
import { BRIEFING_PAGE_TYPES, BRIEFING_STATUS } from '../../config/briefing';

// state
import { RootState } from '../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

const BriefingEditPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const briefing = useSelector(briefingSelector);

  useEffect(() => {
    if (briefing.detail.body.status != BRIEFING_STATUS.TEMPLATE) {
      dispatch(push("/briefing/"));
    }
  }, [briefing.detail]);

  return (
    <Layout>
      <div className={classes.container}>
        <Header pageType={BRIEFING_PAGE_TYPES.CREATE} />
        <Edit />
      </div>
    </Layout>
  );
}

export default BriefingEditPage;

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
    height: '100%',
  },
})
