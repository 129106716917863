import {takeLatest, put, call, select} from 'redux-saga/effects';
import {fetchGet, fetchPatch, fetchPost, fetchDelete} from './fetch';
import {mapResponseToMonthDates, makeStartAndEndDate, makeDates, makeCurrentMonthDate} from "../util/radar";
import {selectGroup, selectRadar} from "./selector";

//model
import {RadarResultsResponseContentModel, RadarModel} from "../model/RadarModel";

//actions
import RadarActionType from '../actions/Radar/ActionType';
import * as ActionCreators from '../actions/Radar/ActionCreator';

function* getRadarWords(action: ReturnType<typeof ActionCreators.getRadarWords.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/radar_words/`);
        yield put(ActionCreators.getRadarWords.success(data));
    } catch (e) {
        yield put(ActionCreators.getRadarWords.failure(e));
    }
}

function* getRadarResults(action: ReturnType<typeof ActionCreators.getRadarResults.request>) {
    try {
        const {start, end} = action.payload;
        const group = yield select(selectGroup);
        const radar: RadarModel = yield select(selectRadar);
        const [startDate, endDate] = makeStartAndEndDate(start, end)
        // format: YYYY-MM-DD
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/radar_results/?start=${startDate}&end=${endDate}`);
        if (data) {
            const dates = !!radar.selected.start && !!radar.selected.end
                ? makeDates(radar.selected.start, radar.selected.end)
                : makeDates(makeCurrentMonthDate('start'), makeCurrentMonthDate('end'))
            const res = data as Array<RadarResultsResponseContentModel>;
            const mappedItemList = res.map(result => {
                return {
                    radar_word_id: result.radar_word_id,
                    items: mapResponseToMonthDates(result.items, dates)
                }
            })
            yield put(ActionCreators.getRadarResults.success(data));
            yield put(ActionCreators.setIsOverWord(false));
            yield put(ActionCreators.setDisplayItems.request(mappedItemList))
        } else {
            if (error && error.is_over_word) {
                yield put(ActionCreators.setIsOverWord(true));
            }
        }
    } catch (e) {
        yield put(ActionCreators.getRadarResults.failure(e));
    }
}

function* postCreateRadarWord(action: ReturnType<typeof ActionCreators.postCreateRadarWord.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/radar_words`, action.payload)
        if (!!data && !error) {
            yield put(ActionCreators.postCreateRadarWord.success())
            yield put(ActionCreators.getRadarWords.request())
        }
    } catch (e) {
        yield put(ActionCreators.postCreateRadarWord.failure(e))
    }
}

function* radarWordDelete(action: ReturnType<typeof ActionCreators.radarWordDelete.request>) {
    try {
        const group = yield select(selectGroup);
        const targetId = action.payload;
        const [data, error] = yield call(fetchDelete, `groups/${group.selectedId}/radar_words/${targetId}/`)
        if (!!data && !error) {
            yield put(ActionCreators.radarWordDelete.success())
            yield put(ActionCreators.getRadarWords.request())
        }
    } catch (e) {
        yield put(ActionCreators.radarWordDelete.failure(e))
    }
}
function* patchRadarWordUpdate(action: ReturnType<typeof ActionCreators.patchUpdateRadarWord.request>) {
    try {
        const group = yield select(selectGroup);
        const { id: targetId, radar_word, exclude_word } = action.payload;
        const [data, error] = yield call(
            fetchPatch,
            `groups/${group.selectedId}/radar_words/${targetId}`,
            { radar_word, exclude_word }
            )
        yield put(ActionCreators.patchUpdateRadarWord.success())
        yield put(ActionCreators.getRadarWords.request())
    } catch (e) {
        yield put(ActionCreators.patchUpdateRadarWord.failure(e))
    }
}

function* deleteRadarResult(action: ReturnType<typeof ActionCreators.deleteRadarResult.request>) {
    try {
        const group = yield select(selectGroup);
        const targetId = action.payload.id;
        const [data, error] = yield call(fetchDelete, `groups/${group.selectedId}/radar_results/${targetId}/`);
        const radar: RadarModel = yield select(selectRadar);
        const {start, end} = radar.selected
        if (start && end) {
            yield put(ActionCreators.getRadarResults.request({start, end}))
        }
    } catch (e) {
        yield put(ActionCreators.deleteRadarResult.failure(e))
    }
}

const radarSaga = [
    takeLatest(RadarActionType.GET_RADAR_WORDS_REQUEST, getRadarWords),
    takeLatest(RadarActionType.GET_RADAR_RESULTS_REQUEST, getRadarResults),
    takeLatest(RadarActionType.POST_CREATE_RADAR_WORD_REQUEST, postCreateRadarWord),
    takeLatest(RadarActionType.DELETE_RADAR_WORD_REQUEST, radarWordDelete),
    takeLatest(RadarActionType.PATCH_RADAR_WORD_REQUEST, patchRadarWordUpdate),
    takeLatest(RadarActionType.DELETE_RADAR_RESULT_REQUEST, deleteRadarResult)
];

export default radarSaga;
