import React from "react";
import { NavLink } from "react-router-dom";

// components
import { Typography, Button } from "@material-ui/core";


const TrialEnd: React.FC = () => {
	return (
		<>
			<Typography variant="h1" component="h1" gutterBottom>トライアル期間が終了しています</Typography>
			<Typography variant="h6" component="h6" gutterBottom>
				トライアル期間14日が過ぎました<br />
				継続するにはプランのアップグレードが必要です<br />
			</Typography>
			<NavLink exact to={"/setting/plan/"}>
				<Button variant="contained" size="large" color="primary">
					プランのアップグレード
				</Button>
			</NavLink>
		</>
	);
}

export default TrialEnd;
