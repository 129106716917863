enum BlockActionType {
	GET_BLOCK_REQUEST = "GET_BLOCK_REQUEST",
	GET_BLOCK_SUCCEEDED = "GET_BLOCK_SUCCEEDED",
	GET_BLOCK_FAILED = "GET_BLOCK_FAILED",
	POST_BLOCK_REQUEST = "POST_BLOCK_REQUEST",
	POST_BLOCK_SUCCEEDED = "POST_BLOCK_SUCCEEDED",
	POST_BLOCK_FAILED = "POST_BLOCK_FAILED",
	PATCH_BLOCK_REQUEST = "PATCH_BLOCK_REQUEST",
	PATCH_BLOCK_SUCCEEDED = "PATCH_BLOCK_SUCCEEDED",
	PATCH_BLOCK_FAILED = "PATCH_BLOCK_FAILED",
	DELETE_BLOCK_REQUEST = "DELETE_BLOCK_REQUEST",
	DELETE_BLOCK_SUCCEEDED = "DELETE_BLOCK_SUCCEEDED",
	DELETE_BLOCK_FAILED = "DELETE_BLOCK_FAILED",
}

export default BlockActionType;
