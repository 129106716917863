import React from "react";
import {
    Box,
    CircularProgress,
    CircularProgressProps,
    createStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex", overflow: 'auto',
            flexDirection: 'column'

        },
        box: {
            position: "relative",
            display: "inline-flex",
            alignSelf: "center",
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
        label: {
            fontSize: 20,
            fontWeight: "bold"
        }
    }),
);

export function CircularProgressChart(props: CircularProgressProps & { value: number, title: string }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                variant="h5" component="h5"
                gutterBottom>{props.title}</Typography>
            <Box className={classes.box} >
                <CircularProgress
                    variant="static"
                    className={classes.bottom}
                    size={140}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="static"
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={140}
                    thickness={4}
                    {...props}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary" className={classes.label}>
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
} 
