import React from "react";
import { RouteComponentProps } from "react-router-dom";

// components
import { AppLayoutFullWidth } from "../../components/Layout/App";
import PressKitList from "../../components/PressKit/List";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

const companySelector = (state: RootState) => state.company;

type PageProps = {} & RouteComponentProps<{}>;

const PressKitCreatePage: React.FC<PageProps> = props => {

	const company = useSelector(companySelector);

    return (
		<AppLayoutFullWidth>
            <PressKitList/>
		</AppLayoutFullWidth>
    );
}

export default PressKitCreatePage;
