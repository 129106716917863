export const stgFirebaseConfig = {
  apiKey: "AIzaSyAG4hwDEZrUpCi5chhwT2ekqYBahblQFtM",
  authDomain: "harvest-stg-266208.firebaseapp.com",
  databaseURL: "https://harvest-stg-266208.firebaseio.com",
  projectId: "harvest-stg-266208",
  storageBucket: "harvest-stg-266208.appspot.com",
  messagingSenderId: "607521111664",
  appId: "1:607521111664:web:2875a3564364211587beb5"
}

export const firebaseConfig = {
  apiKey: "AIzaSyA7-Ofd8CkOberkSNxQ2wdmY6RlBg400dI",
  authDomain: "harvest-194313.firebaseapp.com",
  databaseURL: "https://harvest-194313.firebaseio.com",
  projectId: "harvest-194313",
  storageBucket: "harvest-194313.appspot.com",
  messagingSenderId: "278295484100",
  appId: "1:278295484100:web:1c2b7b0fdf32490f6b9b91"
};

export const configureFirestoreListen = groupId => {
  if (groupId) {
    const groupIdRef = {
      collection: 'PressKit',
      doc: groupId,
      storeAs: 'groupId'
    }
    return [groupIdRef]
  }
  return []
}
