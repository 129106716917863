import {PaymentHistoryListModel} from "../model/PaymentModel";
import createReducer from "./createReducer";
import PaymentHistoryActionType from "../actions/PaymentHistory/ActionType";
import PaymentHistoryAction from "../actions/PaymentHistory/Action";

const initialStatePaymentHistory: PaymentHistoryListModel = {
    results: [],
    count: 0,
    offset: 0,
}

export const paymentHistory = createReducer<PaymentHistoryListModel>(initialStatePaymentHistory, {
    [PaymentHistoryActionType.GET_PAYMENT_HISTORY_SUCCEEDED](state: PaymentHistoryListModel, action: PaymentHistoryAction) {
        const result: any = action.payload
        return {
            ...state,
            results: result.results,
            offset: result.offset,
            count: result.count,
        }
    },
})
