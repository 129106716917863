import ActionType from './ActionType';
import {createAsyncAction} from 'typesafe-actions';
import {AnalyticsSummaryRequestModel,AnalyticsDetailRequestModel,OpenMailRequestModel} from "../../model/AnalyticsModel";

export const getAnalyticsDetail = createAsyncAction(
    ActionType.GET_ANALYTICS_DETAIL_REQUEST,
    ActionType.GET_ANALYTICS_DETAIL_SUCCEEDED,
    ActionType.GET_ANALYTICS_DETAIL_FAILED
)<AnalyticsDetailRequestModel, void, Error>();

export const getAnalyticsSummary = createAsyncAction(
    ActionType.GET_ANALYTICS_SUMMARY_REQUEST,
    ActionType.GET_ANALYTICS_SUMMARY_SUCCEEDED,
    ActionType.GET_ANALYTICS_SUMMARY_FAILED
)<AnalyticsSummaryRequestModel, void, Error>(); 


export const getOpenMail = createAsyncAction(
    ActionType.GET_OPEN_MAIL_REQUEST,
    ActionType.GET_OPEN_MAIL_SUCCEEDED,
    ActionType.GET_OPEN_MAIL_FAILED
)<OpenMailRequestModel, void, Error>(); 
