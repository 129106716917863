import React from "react";

//material-ui
import {Typography} from "@material-ui/core";

//component
import {TicketQtyView} from "./TicketQty";

//model
import {TicketQtyModel} from "../../../../../model/TicketModel";

interface Props {
  ticketQty: TicketQtyModel;
}

export const ReserveFix: React.FC<Props> = ({ticketQty}) => {
  return (
    <>
      <Typography>予約中の配信設定を修正します。</Typography>
      <Typography>現在予約中の配信数が1回使用可能になります。</Typography>
      <TicketQtyView ticketQty={ticketQty} />
    </>
  );
};
