import React from 'react';

//component
import Title from '../Title';

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//img
import Image01 from '../../../assets/help/media/harvestMediaList/image01.png';
import Image02 from '../../../assets/help/media/harvestMediaList/image02.png';
import Image03 from '../../../assets/help/media/harvestMediaList/image03.png';
import Image04 from '../../../assets/help/media/harvestMediaList/image04.png';
import Image05 from '../../../assets/help/media/harvestMediaList/image05.png';


const HarvestMediaList = () => {
	return (
		<div className={style.box}>
			<Title title={"2.HARVEST配信リスト"}/>
			<Typography gutterBottom variant={"body1"}>
				グループ毎に登録・管理ができます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				HARVEST配信リストを登録すると、プレスリリースの配信設定時に、メールの作成が一括で行えます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
			<div className={style.img}>
				<img src={Image05}/>
			</div>
		</div>
	);
};

export default HarvestMediaList;
