import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
    Tooltip,
    List,
    ListItem,
    Typography,
    Divider,
    ListItemSecondaryAction,
    IconButton,
    makeStyles,
    Box
} from "@material-ui/core";

//model
import { PressReleaseListModel } from "../../../../model/PressReleaseModel";
import { createPressReleaseURL } from "../../../../util/Status";

// component
import Status from "../../Common/Status";
import DateTime from "../../../Common/DateTime";
import ListMenu from "../ListMenu";
import Button from "@material-ui/core/Button";
import TagDialog from "../../Tag/Dialog";

// img
import ImageEllipsis from "../../../../assets/icon_ellipsis.svg";

// actions
import * as PressReleaseActionCreators from '../../../../actions/PressRelease/ActionCreator';

// state
import { RootState } from "../../../../reducers";
import Label from "../../Tag/Label";
const pressGroupSelector = (state: RootState) => state.group;
const routerSelector = (state: RootState) => state.router;
const pressReleaseListSelector = (state: RootState) => state.pressReleaseList;

interface ListLayoutProps {
    list: PressReleaseListModel[],
    handleDeleteDialogOpen,
    handleClone,
    handleArchive,
    handleOpenMailDialog
}

const ListLayout: React.FC<ListLayoutProps> = ({ list, handleDeleteDialogOpen, handleClone, handleArchive, handleOpenMailDialog }) => {

    return (
        <List component="div">
            {list.map((item, index) => (
                <ListLayoutItem
                    item={item}
                    key={index}
                    handleDeleteDialogOpen={handleDeleteDialogOpen}
                    handleClone={handleClone}
                    handleArchive={handleArchive}
                    handleOpenMailDialog={handleOpenMailDialog} />
            ))}
        </List>
    );
}
export default ListLayout;


interface ListLayoutItemProps {
    item: PressReleaseListModel,
    handleDeleteDialogOpen,
    handleClone,
    handleArchive,
    handleOpenMailDialog
}

const ListLayoutItem: React.FC<ListLayoutItemProps> = ({ item, handleDeleteDialogOpen, handleClone, handleArchive, handleOpenMailDialog }) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const router = useSelector(routerSelector);
    const { search, archive, publicList } = useSelector(pressReleaseListSelector);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const group = useSelector(pressGroupSelector);

    const [tagDialog, setTagDialog] = useState<boolean>(false);
    const getPressReleaseList = () => {
        const is_archive = !!(router.location.pathname.indexOf('archive') > -1 ? 1 : 0);
        const is_public = !!(router.location.pathname.indexOf('public') > -1 ? 1 : 0);
        if (is_archive) {
            dispatch(PressReleaseActionCreators.getPressReleaseListArchive.request({ offset: archive.offset }));
        } else if (is_public) {
            dispatch(PressReleaseActionCreators.getPressReleaseListPublic.request({ offset: publicList.offset }));
        } else {
            dispatch(PressReleaseActionCreators.getPressReleaseList.request({ offset: search.offset }));
        }
    }

    return (
        <>
            <ListItem className={classes.text}>
                <Box className={classes.listItemText}>
                    <Box className={classes.primaryBox}>
                        <Typography variant={'body2'}>{item.name}</Typography>
                        <Box className={classes.tags}>
                            {item.tags && item.tags.map((tag, index) => (
                                <Box className={classes.tag} key={index}>
                                    <Label tag={tag} />
                                </Box>))}
                        </Box>
                    </Box>
                    <Box className={classes.secondary}>
                        <DateTime datetime={item.history.created_at} />
                        <Status type="label" review={item.review} status={Number(item.status)} />
                    </Box>
                </Box>
                <ListItemSecondaryAction className={classes.secondary}>
                    {item.status > 3 ?
                        <Button variant="contained" size="small" color="primary" className={classes.button}>
                            <NavLink exact to={`${createPressReleaseURL(group.selectedId, item.press_id, item.status)}`}>
                                配信設定
                        </NavLink>
                        </Button> :
                        <Button variant="outlined" size="small" color="primary" className={classes.button}>
                            <NavLink exact to={`${createPressReleaseURL(group.selectedId, item.press_id, item.status)}`}>
                                編集・確認
                        </NavLink>
                        </Button>}

                    <Button variant="outlined" size="small" className={classes.button}>
                        <NavLink exact to={`/press_release/${group.selectedId}/detail/${item.press_id}`}>
                            プレビュー
                        </NavLink>
                    </Button>
                    <Tooltip title="メニュー" placement="top" arrow>
                        <IconButton edge="end" aria-label="delete" onClick={handleClick} className={classes.button}>
                            <img src={ImageEllipsis} />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                item={item}
                handleDeleteDialogOpen={handleDeleteDialogOpen}
                handleClone={handleClone}
                handleArchive={handleArchive}
                handleTagDialogOpen={() => setTagDialog(true)} />
            <TagDialog isOpen={tagDialog} item={item} handleClose={() => {
                setTagDialog(false);
                getPressReleaseList();
            }} />
        </>
    );
}


const useStyles = makeStyles({
    text: {
        paddingRight: 80
    },
    item: {
        width: '100%',
        padding: '0 8px'
    },
    primary: {
        display: 'flex',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    button: {
        marginLeft: 10
    },
    secondary: {
        display: 'flex',
    },
    primaryBox: {
        display: 'flex',
        width: '70%',
        alignItems: 'center',
        marginTop: 8,
    },
    tags: {
        marginLeft: 15,
        display: 'flex',
        flexWrap: 'wrap',
    },
    tag: {
        margin: '0 5px 5px 0',
    },
    listItemText: {
        padding: '5px 0',
    },
});
