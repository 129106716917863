import React, { useEffect, useState } from "react";
import { push } from 'connected-react-router'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';

// img
import IconEdit from "../../../../assets/editor/icon_edit.svg";
import IconBack from "../../../../assets/off.svg";

// action
import * as PressReleaseActionCreators from "../../../../actions/PressRelease/ActionCreator";
import * as ActionCreators from "../../../../actions/PressReleaseReserve/ActionCreator";
import * as PressReleaseReserveActionCreators from "../../../../actions/PressReleaseReserve/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../../../actions/PressReleaseFirebase/ActionCreator";
import * as PressReleasePublishActionCreators from "../../../../actions/PressReleasePublish/ActionCreator";

// component
import Avatar from "../../../Common/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { exportFile } from "../../../../util/File";
import PreviewDialog from "../../../Common/PreviewDialog";
import ReviewResultDialog from "../ReviewResultDialog";
import NameDialog from "../NameDialog";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import ReviewRequestDialog from "../ReviewRequestDialog";
import ShareDialog from "../ShareDialog";
import Progress from "../../../Common/Progress";
import PressReleaseSkipReviewButton from "./PressReleaseSkipReviewButton";
import PressReleaseEditButton from "./PressReleaseEditButton";
import AppDialog from "../../../Common/AppDialog";
import PressReleasePublishSaveButton from "./PressReleasePublishSaveButton";
import EditorAndViewerIcon from "./EditorAndViewerIcon";
import { TicketConfirmDialog } from "../TicketConfirmDialog";

//style
import style from "./header_menu.module.scss";

// conf
import { PAGE_EDIT, PAGE_REVIEW, PAGE_DIFF, PAGE_RESERVE, PAGE_CREATE, PAGE_DETAIL, PAGE_OGP } from "../../../../config/page_type";
import Status from "../Status";
import { NavLink } from "react-router-dom";
import { checkReviewStatus } from "../../../../util/checkAuthority";
import { setLockEditingInfo } from "../../../../util/lockEditing";
import {
    STATUS_ACCEPT,
    STATUS_PUBLIC_ACCEPT,
    STATUS_PUBLIC_REJECT,
    STATUS_PUBLIC_REVIEW,
    STATUS_PUBLIC_DONE,
} from "../../../../config/status_type";

const meSelector = (state: RootState) => state.me;
const pressGroupSelector = (state: RootState) => state.group;
const companySelector = (state: RootState) => state.company;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const PressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const pressReleasePublishSelector = (state: RootState) => state.pressReleasePublish;
const mediaSelector = (state: RootState) => state.media;
const firebaseSelector = (state: RootState) => state.firebase;

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const isDevelop = window.location.hostname === "localhost" ? true : false;

const useStyles = makeStyles({
    button: {
        marginRight: 10
    },
    arrow: {
        borderLeft: "0.1rem solid rgba(255, 255, 255, 0.3);",
        padding: "5px 1rem",
    },
    statusButton: {
        marginLeft: 10,
        padding: 5
    },
    textareaField: {
        width: 400,
        marginRight: 10
    },
    editIcon: {
        padding: 0,
        marginLeft: 10,
        minHeight: 5,
    },
    noAuthority: {
        marginRight: 10,
        position: "fixed",
        zIndex: 1,
        right: 70,
        top: 54,
        fontSize: 12,
        color: "#E95050",
        fontWeight: "bold"
    },
    skipLabel: {
        marginLeft: 10,
        fontSize: 11,
        textDecoration: "underLine"
    },
    buttonWrapper: {
        position: "relative"
    },
    buttonProgress: {
        color: "#4caf50",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -17,
    },
    backIcon: {
        marginRight: 8
    },
    toolTip: {
        fontSize: 25,
        margin: "100px 0",
        maxWidth: "none"
    }
});

interface IProps {
    page: string
}


const HeaderMenu: React.FC<IProps> = ({ page }) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [menu, setMenuEl] = React.useState<null | HTMLElement>(null);
    const [nameDialog, setNameDialog] = React.useState(false);

    const [previewDialog, setPreviewDialog] = React.useState(false);
    const [reviewPreviewDialog, setReviewPreviewDialog] = React.useState(false);

    const [reviewRequestDialog, setReviewRequestDialog] = React.useState(false);
    const [reviewResultDialog, setReviewResultDialog] = React.useState(false);

    const [reserveReviewRequestDialog, setReserveReviewRequestDialog] = React.useState(false);
    const [reserveReviewResultDialog, setReserveReviewResultDialog] = React.useState(false);

    const [shareDialog, setShareDialog] = React.useState(false);

    const [reviewAcceptConfirmDialog, setReviewAcceptConfirmDialog] = React.useState(false);

    const [promoteSavingActionDialog, setPromoteSavingActionDialog] = React.useState(false);

    const [loading, setLoading] = React.useState<boolean>(false);
    let timer: any;

    const [allowReserveSave, setAllowReserveSave] = React.useState<boolean>(false);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

    const group = useSelector(pressGroupSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const pressReleaseReserve = useSelector(PressReleaseReserveSelector);
    const publish = useSelector(pressReleasePublishSelector);
    const company = useSelector(companySelector);
    const media = useSelector(mediaSelector);
    const reserveSaveLoading = pressReleaseReserve.reserveSaveLoading;
    const { auto, custom, categories, category_details, type } = pressReleaseReserve.data;

    const me = useSelector(meSelector);
    const status = pressRelease.detail.status;
    const saveLoading = pressRelease.saveLoading;

    const publishSaveLoading = publish.saveLoading;

    // 同時編集ロック機能に必要
    const accessData = useSelector(firebaseSelector);
    const lockEditingInfo = setLockEditingInfo(page, accessData.data[pressRelease.detail.press_id], pressRelease, me);
    const { editorUuid, editorName, disabledButton, isToolTipOpen, viewerIdList } = lockEditingInfo;

    useEffect(() => {
        clearTimeout(timer);

        if (saveLoading || reserveSaveLoading || publishSaveLoading) {
            setLoading(true)
            timer = setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [saveLoading, reserveSaveLoading, publishSaveLoading]);

    useEffect(() => {
        if (type && categories.length > 0 && category_details.length > 0) {
            setAllowReserveSave(true);
        } else {
            setAllowReserveSave(false);
        }
    }, [categories, category_details, type]);

    const handleTemplateSave = () => {
        exportFile({
            body: pressRelease.detail.body,
        });
    };

    const handleOpenPreviewDialog = () => {
        handleSave(false, false, false);
        setPreviewDialog(true);
    };

    const handleClosePreviewDialog = () => {
        setPreviewDialog(false);
    };

    // プレスリリース名変更
    const handleSaveName = (name) => {
        if (pressRelease.detail.press_id) {
            dispatch(PressReleaseActionCreators.patchPressReleaseInfo.request({ name }));
            setNameDialog(false)
        } else {
            handleNewSave(name)
        }
    };

    // プレスリリース新規保存
    const handleNewSave = (name) => {
        dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({
            name: name,
            isDiff: false,
            isReview: false,
            isReviewRequest: false
        }));
        setNameDialog(false)
    };

    // プレスリリース保存
    const handleSave = (isDiff, isReview, isReviewRequest) => {
        const appEditor = document.querySelectorAll('#appEditor .fr-view');
        if (appEditor.length) {
            const html = appEditor[0].innerHTML;
            const component: any = [];
            const style = [];
            dispatch(PressReleaseActionCreators.setPressReleaseDetails.request({ body: { html, css: "", style, component } }));

            if (pressRelease.detail.press_id) {
                dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff, isReview, isReviewRequest }));
            } else {
                setNameDialog(true)
            }
        }
    };

    // プレスリリース承認申請
    const handleRequestReview = (selectUser, date, comment) => {
        dispatch(PressReleaseActionCreators.postPressReleaseReviewRequest.request({
            members_list: selectUser,
            deadline_at: date,
            comment: comment,
        }));
        dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
            page: "edit",
            press_id: pressRelease.detail.press_id,
            user_id: ""
        }));
        dispatch(push(`/press_release/${group.selectedId}/detail/${pressRelease.detail.press_id}/review/`));
    };

    // プレスリリース確認依頼　承認
    const handleReviewDone = () => {
        // 依頼する
        dispatch(PressReleaseActionCreators.postPressReleaseReviewResult.request({
            status: 1,
            comment: "",
        }));
        setReviewAcceptConfirmDialog(false)
    };

    // 配信確認依頼　承認
    const handleReserveReviewDone = () => {
        dispatch(PressReleaseReserveActionCreators.postPressReleaseReserveReviewResult.request({
            status: 1,
            comment: "",
        }));
        dispatch(push(`/press_release/${group.selectedId}/detail/${pressRelease.detail.press_id}/`))
    };

    // 配信保存
    const handleReserveSave = () => {
        dispatch(ActionCreators.postPressReleaseReserve.request({
            reviewer: [],
            comment: '',
            is_save: true,
        }));
    };

    // 配信承認申請
    const handleReserveReviewRequest = (selectUser: [], date: Date, comment: string) => {
        dispatch(ActionCreators.postPressReleaseReserve.request({
            reviewer: selectUser,
            deadline_at: date,
            comment: comment,
            is_save: false,
        }));
        dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
            page: "reserve",
            press_id: pressRelease.detail.press_id,
            user_id: "",
        }));
    };

    // 配信確認スキップ
    const handleReserveReviewSkipRequest = () => {
        dispatch(ActionCreators.postPressReleaseReserve.request({
            reviewer: [],
            deadline_at: undefined,
            comment: "",
            is_save: false,
        }));
        dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
            page: "reserve",
            press_id: pressRelease.detail.press_id,
            user_id: "",
        }));
    };


    // プレスリリース承認申請取り下げ
    const handleDeleteRequestReview = () => {
        dispatch(PressReleaseActionCreators.deletePressReleaseReviewRequest.request());
        setMenuEl(null)
    };

    // 配信承認申請取り下げ
    const handleDeleteReserveReview = () => {
        dispatch(ActionCreators.deletePressReleaseReserve.request())
        setMenuEl(null)
    };

    // ステースの変更
    const handleChangeStatus = (status: number) => {
        dispatch(PressReleaseActionCreators.postPressReleaseStatusChange.request(status))
        setMenuEl(null)
    };

    const handleAccessData = () => {
        if (page === PAGE_EDIT) {
            dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
                page: "edit",
                press_id: pressRelease.detail.press_id,
                user_id: me.uuid
            }));
        } else if (page === PAGE_RESERVE) {
            if (status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) {
                dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
                    page: "reserve",
                    press_id: pressRelease.detail.press_id,
                    user_id: me.uuid
                }));
            }
        }

        // 編集可能なPRに入った後、編集不可のPRに入った際、dbに書き込まないようにするためにdispatchする
        dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(false));
        dispatch(push(`/press_release/`));
    };

    const handleReserveButtonClick = () => {
        handleReserveSave()
        setIsConfirmDialogOpen(true)
    }

    //プレスリリースのステータスが配信済みかどうか
    const isPublic: boolean = pressRelease.detail.status >= STATUS_PUBLIC_DONE ? true : false;
    const canEditOGP = pressRelease.detail.status >= STATUS_ACCEPT;

    // プレスリリース編集権限
    const hasEdit = pressRelease.role.edit;

    // 校閲権限
    const hasReview = pressRelease.role.review;

    const isDone = checkReviewStatus(pressRelease.detail.review.member, me);

    // 配信予約確認権限
    let hasReserveReview = (pressReleaseReserve.data.reviewer || []).filter(member => member.user.uuid === me.uuid && member.status != true).length > 0;


    const isSend = auto.is_send || custom.filter(item => item.is_send).length > 0;

    // 予約画面入力チェック
    const customDataErr = custom
        .filter(item => item.is_send).filter(data => {
            return !data.mail_title || !data.mail_body
        })

    const customDataCheck = customDataErr.length === 0 ? true : false;
    const favoriteList = media.favoriteList.results;
    const listId = auto.list_id;
    const idListCheck = auto.list_id === 0 && auto.id_list.length === 0 ||
        (listId > 0 && favoriteList.length > 0 && favoriteList.find(i => i.list_id === media.favoriteList.index).id_list.length === 0);

    let autoDataCheck = true;
    if (auto.is_send && (!auto.mail_title || !auto.mail_body || idListCheck)) {
        autoDataCheck = false
    }

    let publishCheck = true;
    if (publish.is_publish && (!publish.ogp_title || !publish.ogp_description || !publish.ogp_url)) {
        publishCheck = false
    }
    const isDisabled = !isSend || !pressReleaseReserve.data.type || !autoDataCheck || !customDataCheck;

    const handlers = (page: string, status: number, isReviewSkip: boolean) => {
        if (page === PAGE_RESERVE && (status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) && isReviewSkip) {
            //予約確定する
            return () => {
                handleReserveReviewSkipRequest()
                setIsConfirmDialogOpen(false)
            }
        } else if (page === PAGE_RESERVE && status === STATUS_PUBLIC_ACCEPT) {
            //配信設定を修正する
            return () => {
                handleChangeStatus(STATUS_ACCEPT)
                setIsConfirmDialogOpen(false)
            }
        } else if (page === PAGE_RESERVE && status === STATUS_PUBLIC_REVIEW && !isReviewSkip) {
            //配信予約のレビューを承認する
            return () => {
                handleReserveReviewDone()
                setIsConfirmDialogOpen(false)
            }
        }
        return () => setIsConfirmDialogOpen(false)
    }

    const handleMakePublish = () => {
        dispatch(PressReleasePublishActionCreators.postMakePressReleasePublic.request({
            press_id: pressRelease.detail.press_id,
            released_at: pressReleaseReserve.data.released_at,
        }));
    }

    const createActions = () => {
        let act: any = "";

        switch (page) {
            case PAGE_CREATE:
                act = <>
                    {isDevelop &&
                        <Button variant="outlined" size="large" color="primary" onClick={() => handleTemplateSave()}>
                            <p className='js-fetchStore'>テンプレ保存(local)</p>
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={() => setPromoteSavingActionDialog(true)}
                    >プレビュー
                    </Button>
                    <div className={classes.buttonWrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={loading}
                            onClick={() => handleSave(false, false, false)}
                        >保存
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </>
                break;

            case PAGE_EDIT:

                if (status === 1) {
                    act = <>
                        <Typography className={classes.noAuthority}>確認中のため、編集できません</Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleOpenPreviewDialog}
                        >プレビュー
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            disabled={!hasEdit}
                            onClick={handleDeleteRequestReview}>確認依頼取り下げ
                        </Button>
                    </>
                } else if (status < 3) {
                    act = <>
                        {!hasEdit && <Typography className={classes.noAuthority}>編集権限がありません</Typography>}
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleOpenPreviewDialog}
                        >プレビュー
                        </Button>
                        <div className={classes.buttonWrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!hasEdit || loading || disabledButton}
                                onClick={() => handleSave(false, false, false)}>保存
                            </Button>
                            {(loading && hasEdit) && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        {company.is_review_skip ?
                            <PressReleaseSkipReviewButton disabledButton={disabledButton} />
                            :
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={!hasEdit || disabledButton}
                                size="large"
                                onClick={() => {
                                    handleSave(false, false, true);
                                    setReviewPreviewDialog(true);
                                }}>
                                確認依頼を出す
                            </Button>
                        }
                    </>
                } else {
                    act = <>
                        <Button variant="contained" size="large" color={"primary"} className={classes.button}>
                            <NavLink exact
                                to={`/press_release/${group.selectedId}/reserve/${pressRelease.detail.press_id}`}>
                                配信設定
                            </NavLink>
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleOpenPreviewDialog}
                        >プレビュー
                        </Button>
                    </>;
                }

                break;

            case PAGE_DETAIL:

                let action: any = ""

                if (status === 1) {
                    action = <>
                        {!hasEdit && !hasReview && <Typography className={classes.noAuthority}>編集権限がありません</Typography>}
                        <Button variant="contained" size="large" color={"primary"} className={classes.button} disabled={!hasEdit && !hasReview}>
                            <NavLink exact
                                to={`/press_release/${group.selectedId}/detail/${pressRelease.detail.press_id}/review`}>
                                確認
                            </NavLink>
                        </Button>
                    </>;

                } else if (status < 3) {
                    action = <>
                        {!hasEdit && <Typography className={classes.noAuthority}>編集権限がありません</Typography>}
                        <Button variant="contained" size="large" color={"primary"} className={classes.button} disabled={!hasEdit}>
                            <NavLink exact
                                to={`/press_release/${group.selectedId}/detail/${pressRelease.detail.press_id}/edit`}>
                                編集
                            </NavLink>
                        </Button>
                    </>;
                } else {
                    action = <Button variant="contained" size="large" color={"primary"} className={classes.button}>
                        <NavLink exact
                            to={`/press_release/${group.selectedId}/reserve/${pressRelease.detail.press_id}`}>
                            配信設定
                        </NavLink>
                    </Button>
                }

                act = <>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={handleOpenPreviewDialog}
                    >プレビュー
                    </Button>
                    {action}
                </>;

                break;

            case PAGE_DIFF:
                act = <>
                    {!hasEdit && <Typography className={classes.noAuthority}>編集権限がありません</Typography>}
                    <div className={classes.buttonWrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!hasEdit || loading}
                            onClick={() => handleSave(true, false, false)}>
                            保存
                        </Button>
                        {(loading && hasEdit) && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </>;
                break;

            case PAGE_REVIEW:

                if (status === 1) {
                    act = act = <>
                        {!hasReview && <Typography className={classes.noAuthority}>確認中です</Typography>}
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleOpenPreviewDialog}
                        >プレビュー
                        </Button>
                        <div className={classes.buttonWrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!hasReview || isDone || loading}
                                onClick={() => handleSave(false, true, false)}>
                                保存
                            </Button>
                            {(loading && hasReview) && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            disabled={!hasEdit}
                            onClick={handleDeleteRequestReview}>確認依頼取り下げ
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={!hasReview || isDone}
                            onClick={() => {
                                handleSave(false, true, false)
                                setReviewResultDialog(true)
                            }
                            }>修正依頼
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={!hasReview || isDone}
                            onClick={() => setReviewAcceptConfirmDialog(true)}>
                            承認する
                        </Button>
                    </>;

                } else if (status === 2) {
                    act = <>{isDone && <Typography className={classes.noAuthority}>確認完了しています</Typography>}</>;
                } else {
                    act = <>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={handleOpenPreviewDialog}
                        >プレビュー
                        </Button>
                        <Button variant="contained" size="large" color={"primary"} className={classes.button}>
                            <NavLink exact
                                to={`/press_release/${group.selectedId}/reserve/${pressRelease.detail.press_id}`}>
                                配信設定
                            </NavLink>
                        </Button>
                    </>;
                }

                break;

            case PAGE_RESERVE:
                if (status === 3 || status === 6) {
                    act = <>
                        {!hasEdit && <Typography className={classes.noAuthority}>編集権限がありません</Typography>}
                        <PressReleaseEditButton disabled={disabledButton} />
                        {/*<Button variant="outlined" size="large" color="secondary" onClick={handleMakePublish}*/}
                        {/*    disabled={!hasEdit || loading || disabledButton}>*/}
                        {/*    配信せずに公開設定する*/}
                        {/*</Button>*/}
                        <div className={classes.buttonWrapper}>
                            <Button variant="contained" size="large" color="primary" onClick={handleReserveSave}
                                disabled={!allowReserveSave || !hasEdit || loading || disabledButton}>保存</Button>
                            {(loading && hasEdit) && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        {company.is_review_skip ?
                            <Tooltip title="クリックして予約確定させてください。" arrow placement="bottom">
                                <Button variant="contained" size="large" color="secondary"
                                    onClick={handleReserveButtonClick}
                                    disabled={isDisabled || !hasEdit || loading || disabledButton}>予約確定する
                                </Button>
                            </Tooltip>
                            :
                            <Button variant="contained" size="large" color="secondary" onClick={() => {
                                handleReserveSave()
                                setReserveReviewRequestDialog(true)
                            }} disabled={isDisabled || !hasEdit || disabledButton}>確認依頼を出す
                            </Button>
                        }
                    </>
                } else if (status === 5) {
                    act =
                        <>
                            {!hasReserveReview &&
                                <Typography className={classes.noAuthority}>確認中のため、編集できません</Typography>}
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                disabled={!hasEdit}
                                onClick={handleDeleteReserveReview}>確認依頼取り下げ
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="large"
                                disabled={!hasReserveReview}
                                onClick={() => setReserveReviewResultDialog(true)}
                                className={classNames("js-fetchStore")}>修正依頼をする
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                color="secondary"
                                onClick={() => setIsConfirmDialogOpen(true)}
                                disabled={!hasReserveReview}
                            >
                                承認する
                            </Button>
                        </>
                } else if (status === 7) {
                    act =
                        <>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                disabled={!hasEdit}
                                onClick={() => setIsConfirmDialogOpen(true)}>配信設定を修正する
                            </Button>
                        </>
                }

                act = <Box className={style.actions}>{act}</Box>
                break;

            case PAGE_OGP:
                act =
                    <>
                        {hasEdit && !canEditOGP && <Typography className={classes.noAuthority}>編集できません</Typography>}
                        {!hasEdit && <Typography className={classes.noAuthority}>編集権限がありません</Typography>}
                        <div className={classes.buttonWrapper}>
                            <PressReleasePublishSaveButton hasEdit={hasEdit} publishCheck={publishCheck} loading={loading} isPublic={canEditOGP} />
                            {(loading && hasEdit) && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </>;
                break
        }
        return act;
    };

    return (
        <div>
            <Progress />
            <Tooltip title={`${editorName} さんが編集中です`} placement="bottom" open={isToolTipOpen} classes={{ tooltip: classes.toolTip }}>
                <header className={style.header}>
                    <IconButton onClick={handleAccessData} className={classes.backIcon} size="medium">
                        <img src={IconBack} />
                    </IconButton>

                    <div className={style.inner}>
                        <div className={style.name}>
                            <div className={style.text}>{pressRelease.detail.name}</div>
                            {hasEdit &&
                                <IconButton onClick={() => setNameDialog(true)} className={classes.editIcon} size="small">
                                    <img src={IconEdit} className={style.icon} />
                                </IconButton>}
                        </div>

                        <div className={style.status}>
                            <Status type={"header"} review={pressRelease.detail.review}
                                status={pressRelease.detail.status} />
                            {status < 0 && <NavLink to={`/setting/service/edit/`}
                                className={classes.skipLabel}>確認フロースキップ設定</NavLink>}
                        </div>
                    </div>
                    <div className={style.actions}>
                        <EditorAndViewerIcon editorId={editorUuid} viewerIdList={viewerIdList} />
                        {createActions()}
                    </div>
                    <Avatar src={me.img} name={me.last_name} size="medium" color={me.color_cd} />
                </header>
            </Tooltip>

            <PreviewDialog isRevew={true} isHistory={false} isOpen={reviewPreviewDialog} page={page}
                closeHandle={() => setReviewPreviewDialog(false)}
                handleAction={() => {
                    setReviewPreviewDialog(false)
                    setReviewRequestDialog(true)
                }} handleActionText="確認依頼" />

            <PreviewDialog isHistory={false} isOpen={previewDialog} closeHandle={handleClosePreviewDialog} page={page}
                handleAction={null} handleActionText="" />

            {/* プレスリリース */}
            <ReviewResultDialog isReserve={false} isOpen={reviewResultDialog}
                closeHandle={() => setReviewResultDialog(false)} />
            <ReviewRequestDialog
                isOpen={reviewRequestDialog}
                closeHandle={() => setReviewRequestDialog(false)}
                handleReview={handleRequestReview} />

            {/* 配信 */}
            <ReviewResultDialog isReserve={true} isOpen={reserveReviewResultDialog}
                closeHandle={() => setReserveReviewResultDialog(false)} />
            <ReviewRequestDialog
                isOpen={reserveReviewRequestDialog}
                closeHandle={() => setReserveReviewRequestDialog(false)}
                handleReview={handleReserveReviewRequest}
            />

            <NameDialog
                isOpen={nameDialog}
                handleClose={() => setNameDialog(false)}
                handleSave={(name) => handleSaveName(name)}
            />

            <ShareDialog isOpen={shareDialog} closeHandle={() => setShareDialog(false)} />
            <AppDialog isOpen={reviewAcceptConfirmDialog}
                closeHandle={() => setReviewAcceptConfirmDialog(false)}
                mainHandle={handleReviewDone}
                ButtonText={`承認する`}
                text={'確認完了'}
                content={`承認してよろしいですか？
                                <br/>修正内容は保存されません`}
            />

            <AppDialog isOpen={promoteSavingActionDialog}
                closeHandle={() => setPromoteSavingActionDialog(false)}
                text={'プレビューを表示できません'}
                content={`プレビューの表示は、プレスリリースの保存後に可能となります<br/>
                          <br/>
                          このダイアログを閉じて、画面右上にある「保存」ボタンからプレスリリースを保存してください`}
                type={'promoteSavingAction'}
            />
            <TicketConfirmDialog
                isOpen={isConfirmDialogOpen}
                handleClick={handlers(page, status, company.is_review_skip)}
                handleClose={() => setIsConfirmDialogOpen(false)}
                page={page}
                status={status}
                isReviewSkip={company.is_review_skip}
            />
        </div>
    );
};

export default HeaderMenu;

