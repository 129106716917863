import React, {ReactNode} from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";

import IconPoint from "../../assets/icon_point.svg";

export interface Props {
	children: ReactNode
}

const Point: React.FC<Props> = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<img src={IconPoint} className={classes.img}/>
			<div>
				{children}
			</div>
		</div>
	);
};

export default Point;

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			fontSize: 15,
			color: "#333",
			display:  "flex",
			alignItems: "center",
			margin: 20,
			padding: 10,
			backgroundColor: "#FBEED7",
		},
		img: {
			width: "14px !important;",
			marginRight: 10
		}
	}),
);
