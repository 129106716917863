import ActionType from './ActionType';
import {
	PressReleasePublishModel,
	PressReleasePublishRequestModel,
	OGPImageRequestModel,
} from "../../model/PressReleasePublishModel";
import { createAsyncAction } from 'typesafe-actions';

export const getPressReleasePublish = createAsyncAction(
	ActionType.GET_PRESS_RELEASE_PUBLISH_REQUEST,
	ActionType.GET_PRESS_RELEASE_PUBLISH_SUCCEEDED,
	ActionType.GET_PRESS_RELEASE_PUBLISH_FAILED
)<PressReleasePublishRequestModel, PressReleasePublishModel, Error>();

export const postPressReleasePublish = createAsyncAction(
	ActionType.POST_PRESS_RELEASE_PUBLISH_REQUEST,
	ActionType.POST_PRESS_RELEASE_PUBLISH_SUCCEEDED,
	ActionType.POST_PRESS_RELEASE_PUBLISH_FAILED
)<void, void, Error>();

export const setPressReleasePublish = createAsyncAction(
	ActionType.SET_PRESS_RELEASE_PUBLISH,
	"",
	"",
)<PressReleasePublishModel, void, Error>();

export const patchOGPImage = createAsyncAction(
	ActionType.PATCH_OGP_IMAGE_REQUEST,
	ActionType.PATCH_OGP_IMAGE_SUCCEEDED,
	ActionType.PATCH_OGP_IMAGE_FAILED,
)<OGPImageRequestModel, OGPImageRequestModel, Error>();

export const postMakePressReleasePublic = createAsyncAction(
	ActionType.POST_MAKE_PRESS_RELEASE_PUBLIC_REQUEST,
	ActionType.POST_MAKE_PRESS_RELEASE_PUBLIC_SUCCEEDED,
	ActionType.POST_MAKE_PRESS_RELEASE_PUBLIC_FAILED
)<PressReleasePublishRequestModel, void, Error>();
