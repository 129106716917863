import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {push} from "connected-react-router";

// material-ui
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  Button,
  Divider,
  TextField
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

// actions
import * as ContactActionCreators from "../../../../actions/Contact/ActionCreator";

// config
import {calcTax} from "../../../../config/payment_config";

// reducers
import {RootState} from "../../../../reducers";
const paymentSelector = (state: RootState) => state.payment;
const optionSelector = (state: RootState) => state.option;
const meSelector = (state: RootState) => state.me;
const companySelector = (state: RootState) => state.company;

interface ConfirmDialogProps {
  isOpen: boolean;
  closeHandle;
}

const TicketDialog: React.FC<ConfirmDialogProps> = ({isOpen, closeHandle}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {info: payment, fetching} = useSelector(paymentSelector);
  const option = useSelector(optionSelector);
  const company = useSelector(companySelector);
  const me = useSelector(meSelector);

  const [value, setValue] = useState(1);
  const [amount, setAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    const total = value * option.ticket.amount;
    setAmount(total);
    setTax(calcTax(total));
  }, [value]);

  const handleApply = (value: number, text: string) => {
    const postValues = {
      first_name: me.first_name,
      last_name: me.last_name,
      email: me.email,
      company: company.name,
      phone: company.tel,
      type: `配信数を ${value}つ 追加`,
      text: text ? text : "",
      successHandler,
      failedHandler
    };

    dispatch(ContactActionCreators.postContact.request(postValues));
  };

  const successHandler = () => {
    closeHandle();
    dispatch(push(`/setting/plan/apply/complete/`));
  };

  const failedHandler = () => {
    setErrorMessage("申請ができませんでした。時間を置いて、再度お試し下さい。");
  };

  const handleCancel = () => {
    closeHandle();
    setErrorMessage("");
    setValue(1);
    setText("");
  };

  const handleClick = () => {
    dispatch(push("/setting/payment/"));
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>
        <Typography variant="h5">配信数の追加申請</Typography>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        {/* <div className={classes.settingTitle}>内容</div> */}
        <div className={classes.rowM}>
          <Typography variant={"h6"}>追加数</Typography>
          <div className={classes.form}>
            <FormControl className={classes.formControl}>
              <Select
                variant={"outlined"}
                defaultValue={1}
                value={value}
                displayEmpty
                inputProps={{"aria-label": "Without label"}}
                onChange={(e: React.ChangeEvent<{value: any}>) =>
                  setValue(e.target.value)
                }
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.rowM}>
          <Typography variant={"h6"}>備考入力(任意)</Typography>
          <TextField
            className={classes.formControl}
            multiline
            rows={4}
            rowsMax={4}
            variant={"outlined"}
            defaultValue={""}
            value={text}
            onChange={e => {
              setText(e.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <div className={classes.button}>
          {errorMessage && (
            <div>
              <Typography color="error">{errorMessage}</Typography>
            </div>
          )}
          <NavLink exact to={"/setting/plan"}>
            <Button
              variant="outlined"
              size="large"
              onClick={handleCancel}
              disabled={fetching}
            >
              キャンセル
            </Button>
          </NavLink>
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={!value || fetching}
            onClick={() => handleApply(value, text)}
          >
            申請する
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TicketDialog;

const useStyles = makeStyles({
  form: {
    alignItems: "center",
    display: "flex"
  },
  formControl: {
    padding: 10,
    minWidth: 150,
    maxWidth: 350,
    justifyContent: "center"
  },
  rowS: {
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    height: 32,
    alignItems: "center"
  },
  rowM: {
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  settingTitle: {
    background: "#f3f7f4",
    borderRadius: 4,
    color: "#616763",
    fontSize: 14,
    fontWeight: "bold",
    padding: "10px 20px",
    marginBottom: 10
  },
  content: {
    width: 600,
    padding: 36
  },
  action: {
    justifyContent: "space-between"
  },
  button: {
    textAlign: "right",
    width: "100%"
  },
  cardInfo: {
    alignItems: "center",
    display: "flex"
  },
  error: {
    textAlign: "right"
  }
});
