import React from 'react';

// component
import Dropzone from 'react-dropzone';
import { makeStyles, Button, Dialog, DialogContent, DialogActions, Typography, Tooltip, IconButton } from '@material-ui/core';

// icons
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';

// model
import { FileDataModel } from '../../../model/BriefingModel';

interface FileDialogProps {
    isOpen: boolean,
    file: File | FileDataModel | null,
    handleChange,
    closeHandle,
}

const FileDialog: React.FC<FileDialogProps> = ({ isOpen, file, handleChange, closeHandle }) => {
    const classes = useStyles();

    return (
        <Dialog open={isOpen}>
            <DialogContent>
                <Dropzone onDrop={(acceptedFiles) => handleChange(acceptedFiles[0])}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()} className={classes.dropzone}>
                                <input {...getInputProps()} type='file' />
                                <p>ここにファイルをドロップ　または　タップしてファイルを選択</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
                {file && (
                    <div className={classes.fileWrapper}>
                        <div className={classes.file}>
                            <div className={classes.fileIcon}>
                                <InsertDriveFileIcon />
                            </div>
                            <Typography className={classes.fileName}>{file.name}</Typography>
                        </div>
                        <Tooltip title='削除' arrow>
                            <IconButton onClick={handleChange}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' size='large' color='primary' onClick={closeHandle}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
}

export default FileDialog;

const useStyles = makeStyles({
    fileWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    file: {
        display: 'flex',
        alignItems: 'center',
    },
    fileIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
    },
    dropzone: {
        padding: 50,
        fontSize: 13,
    },
    fileName: {
        wordBreak: 'break-word',
    },
});
