import React from "react";
import {useSelector} from "react-redux";
import { RouteComponentProps } from "react-router-dom";

// components
import AppLayout from "../../components/Layout/App";
import {Radar} from '../../components/Radar';

interface Props extends RouteComponentProps<void> { }

function RadarPage(props: Props) {

	return (
		<AppLayout>
			<Radar/>
		</AppLayout>
	);
}

export default RadarPage;
