
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import {
    IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import AppDialog from '../../Common/AppDialog';
import Label from './Label';

// actions
import * as PressReleaseTagActionCreators from '../../../actions/PressReleaseTag/ActionCreator';

// image
import ImageEllipsis from "../../../assets/icon_ellipsis.svg";

// state
import { RootState } from '../../../reducers';
const pressReleaseTagSelecor = (state: RootState) => state.pressReleaseTag;

const TagTable = ({ list }) => (
    <Table>
        <TableHead>
            <TableRow>
                <TableCell>
                    <Typography variant='h6'>{list.length}個のラベル</Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {list.map((item, index) => (
                <TagList key={index} item={item} />
            ))}
        </TableBody>
    </Table>
);

export default TagTable;

export const TagList = ({ item }) => {
    const dispatch = useDispatch();
    const { form } = useSelector(pressReleaseTagSelecor);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);

    const handleClose = () => setAnchorEl(null);

    const handleDelete = () => {
        dispatch(PressReleaseTagActionCreators.deletePressReleaseTag.request({ id: item.id }));
        setDeleteDialog(false);
    }

    const handleSetEditTag = () => {
        dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request({
            isOpen: true,
            id: item.id,
            name: item.name,
            description: item.description,
            bg_color: item.bg_color,
        }));
    }

    return (
        <>
            <TableRow>
                <TableCell style={{ width: '30%' }}>
                    <Label tag={item} />
                </TableCell>
                <TableCell style={{ width: '65%' }}>{item.description}</TableCell>
                <TableCell style={{ width: '5%' }}>
                    <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <img src={ImageEllipsis} />
                    </IconButton>
                    <Menu
                        elevation={1}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem disabled={form.isOpen} onClick={() => {
                            handleSetEditTag();
                            handleClose();
                        }}>
                            編集
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setDeleteDialog(true)
                            handleClose();
                        }}>
                            削除
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
            <AppDialog isOpen={deleteDialog}
                closeHandle={() => setDeleteDialog(false)}
                mainHandle={handleDelete} text='削除しますか' ButtonText="削除" />
        </>
    );
}
