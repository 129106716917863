import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// actions
import * as MemberActionCreators from "../../actions/Member/ActionCreator";
// models
import { MemberModel } from "../../model/MemberModel";

// component
import { Menu, MenuItem, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import AppDialog from "../Common/AppDialog";
import Pagination from "../Common/Pagination";
import TableFrame from "../Common/TableFrame";
import Avatar from "../Common/Avatar";
import InviteDialog from "./InviteDialog";

// state
import { RootState } from "../../reducers";

//image
import ImageEllipsis from "../../assets/icon_ellipsis.svg";

const meSelector = (state: RootState) => state.me;
const memberSelector = (state: RootState) => state.member;
const pressGroupSelector = (state: RootState) => state.group;
const companySelector = (state: RootState) => state.company;


const MemberList: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const member = useSelector(memberSelector);
    const group = useSelector(pressGroupSelector);
    const company = useSelector(companySelector);

    const [displayButton, setDisplayButton] = useState<boolean>(false);
    const { plan, total_member } = company;
    const memberLimit = plan.member_limit;
    const totalSeat = company.plan.member_limit + company.option.member.quantity;
    const seatLefts = totalSeat - company.total_member;

    useEffect(() => {
        if (group.selectedId) dispatch(MemberActionCreators.getMemberList.request({ offset: 0 }));
    }, [group.selectedId]);

    useEffect(() => {
        if (memberLimit) {
            setDisplayButton(total_member < totalSeat);
        } else {
            setDisplayButton(true);
        }
    }, [memberLimit, total_member, totalSeat]);


    const handlePageChange = (pageNumber) => {
        dispatch(MemberActionCreators.getMemberList.request({ offset: pageNumber }))
    }

    const [inviteDialog, setInviteDialog] = React.useState(false);

    return (
        <>
            <div className={classes.head}>
            {displayButton ?
                <Button variant="outlined" size="medium" color="primary"
                        className={classes.newButton} onClick={() => setInviteDialog(true)}>
                    メンバー招待
                </Button>
                :
                <>
                    <Typography variant="caption" component="p">
                            登録できるメンバー数が上限に達しています。<br />
                            メンバーの追加を行うには、プランのアップグレードまたはオプション追加が必要です。<br />
                    </Typography>
                    <NavLink exact to={"/setting/plan"}>
                        <Button variant="contained" size="large" color="primary" className={classes.upgradeButton}>
                            プラン・オプションのアップグレード
                        </Button>
                    </NavLink>
                </>
            }
            </div>
        <div className={classes.headInfo}>
            <Typography gutterBottom color={"error"} variant={"body1"}>
                HARVEST内で使用します。外部には表示されません
            </Typography>
            <div>
                <Typography gutterBottom variant={"body1"}>
                        残りのメンバー枠 : <span className={classes.count}>{seatLefts}</span>
                </Typography>
                <Typography gutterBottom variant={"body2"}>(購入分メンバー枠 : <span className={classes.count}>{company.option.member.quantity}</span>)</Typography>
            </div>
            </div>
            <TableFrame>
                <MemberTable member={member} />
            </TableFrame>
            {member.list.count > 10 && (
                <div className={classes.pagination}>
                    <Pagination
                        activePage={member.list.offset > 0 ? member.list.offset : 1}
                        totalCount={member.list.count}
                        handlePageChange={handlePageChange}
                    />
                </div>
            )}
            <InviteDialog isOpen={inviteDialog} closeHandle={() => setInviteDialog(false)} />
        </>
    );
};

export default MemberList;

const MemberTable = ({ member }) => {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: "40px" }}></TableCell>
                    <TableCell>名前</TableCell>
                    <TableCell>部署</TableCell>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>電話番号</TableCell>
                    {/*<TableCell>権限</TableCell>*/}
                    {/*<TableCell></TableCell>*/}
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {member.list.results.map((row, index) => (
                    <List key={index} row={row} />
                ))}
            </TableBody>
        </Table>
    );
};


interface ListProps {
    row: MemberModel,
}

const List: React.FC<ListProps> = ({ row }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const me = useSelector(meSelector);
    const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        dispatch(MemberActionCreators.deleteMember.request(row.uuid));
        setDeleteDialog(false)
    };

    const handleChangePermission = (permission) => {
        dispatch(MemberActionCreators.patchMember.request({ uuid: row.uuid, permission: permission }))
    }

    return (
        <>
            <TableRow className={row.is_activate ? "" : classes.notActivate}>
                <TableCell>
                    <Avatar src={row.img} name={row.last_name} size="medium" color={row.color_cd} />
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.is_activate ?
                        <>
                            <p>{row.last_name_kana} {row.first_name_kana}</p>
                            <p>{row.last_name} {row.first_name}</p>
                        </>
                        :
                        "本登録待ち"
                    }
                </TableCell>
                <TableCell>
                    {row.department}
                </TableCell>
                <TableCell>
                    {row.email}
                </TableCell>
                <TableCell>
                    {row.tel}
                </TableCell>
                {/*<TableCell>*/}
                {/*	<PermissionSelect permission={row.permission} handleChange={handleChangePermission}/>*/}
                {/*</TableCell>*/}

                <TableCell>
                    {me.uuid !== row.uuid &&
                        <>
                            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                                <img src={ImageEllipsis} />
                            </IconButton>
                            <Menu
                                elevation={1}
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => {
                                    setDeleteDialog(true)
                                    handleClose();
                                }}>
                                    削除
                            </MenuItem>
                            </Menu>
                        </>
                    }
                </TableCell>

            </TableRow>
            <AppDialog isOpen={deleteDialog}
                closeHandle={() => setDeleteDialog(false)}
                mainHandle={handleDelete} text='削除しますか' ButtonText="削除" />
        </>
    );
};

const useStyles = makeStyles({
    notActivate: {
        background: '#eee'
    },
    head: {
        marginBottom: 20,
        textAlign: "right",
    },
    headInfo:{
        display: "flex",
        justifyContent: "space-between" 
    },
    count:{
        fontWeight: "bold" 
    },
    newButton: {
        marginRight: 10,
        marginBottom: 10,
        height: 35,
    },
    pagination: {
        marginTop: 10,
    },
    upgradeButton: {
        marginTop: 5
    },

});
