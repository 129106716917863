import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

// component
import { Typography, TextField } from "@material-ui/core";
import SettingTitle from "../../Common/SettingTitle";
import TableFrame from "../../Common/TableFrame";
import CreateGroup from "./Create";

//model
import { GroupListModel } from "../../../model/GroupModel";

//style
import style from "./group.module.scss";

// state
import { RootState } from "../../../reducers";
import * as GroupActionCreators from "../../../actions/Group/ActionCreator";
import { makeStyles } from "@material-ui/core/styles";

const companySelector = (state: RootState) => state.company;

const ListLayout: React.FC = () => {

    const company = useSelector(companySelector);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { groups, plan } = company;
    const projectLimit = plan.project_limit;

    const [createButton, setCreateButton] = useState<boolean>(false);
    const [createForm, setCreateForm] = useState<boolean>(false);

    const handleSaveGroup = (name) => {
        dispatch(GroupActionCreators.postGroup.request({ name: name }))
        setCreateForm(false);
    };

    useEffect(() => {
        if (projectLimit) {
            setCreateButton(groups.length < projectLimit);
        } else {
            setCreateButton(true);
        }
    }, [projectLimit, groups]);

    return (
        <>
            <SettingTitle text="プロジェクト一覧" />

            <Typography variant="caption">
                プロジェクト毎にプレスリリース・プレスキット・メンバーの管理ができます。<br />
                サービス毎にプロジェクトを分けると便利にご活用いただけます。
            </Typography>

            <div className={classes.addProject}>
                {createButton ?
                    <Button variant="contained" size="large" color="primary"
                        onClick={() => setCreateForm(true)} disabled={createForm}>
                        新規プロジェクト追加
                    </Button>
                    :
                    <>
                        <Typography variant="caption" component="p">
                            作成できるプロジェクト数が上限に達しています。<br />
                            プロジェクトの追加を行うには、プランのアップグレードが必要です。<br />
                        </Typography>
                        <NavLink exact to={"/setting/plan"}>
                            <Button variant="contained" size="large" color="primary" className={classes.upgradeButton}>
                                プランのアップグレード
                            </Button>
                        </NavLink>
                    </>
                }
            </div>

            {createForm &&
                <CreateGroup
                    handleClose={() => setCreateForm(false)}
                    handleSave={(name) => handleSaveGroup(name)} />
            }

            <TableFrame>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: "25%" }}>プロジェクト名</TableCell>
                            <TableCell style={{ width: "60%" }}>プレスリリースURL<span className={style.unique}>※企業内で一意である必要があります。</span></TableCell>
                            <TableCell style={{ width: "15%" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.map((item, index) => (
                            <ListLayoutItem item={item} key={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableFrame>
        </>
    );
}
export default ListLayout;

const useStyles = makeStyles({
    addProject: {
        textAlign: "right",
        marginTop: 10
    },
    upgradeButton: {
        marginTop: 5
    }
});




interface ListLayoutItemProps {
    item: GroupListModel,
}

const ListLayoutItem: React.FC<ListLayoutItemProps> = ({ item }) => {

    const dispatch = useDispatch();
    const [form, setForm] = useState(false);
    const [name, setName] = useState("");
    const [prefix, setPrefix] = useState("")
    const company = useSelector(companySelector)

    const handleSave = () => {
        dispatch(GroupActionCreators.patchGroup.request({ uuid: item.uuid, name, prefix }))
        setForm(false)
    };

    useEffect(() => {
        if (form) {
            setName(item.name);
            setPrefix(item.prefix)
        }
    }, [form]);

    return (
        <TableRow>
            {form ?
                <>
                    <TableCell style={{ width: "30%" }}>
                        <TextField multiline
                            placeholder="プロジェクト名"
                            value={name}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </TableCell>
                    <TableCell style={{ width: "65%" }}>
                        {company.prefix ?
                            <Box className={style.url}>
                                <Typography className={style.url_first_half}>
                                    https://pr.harvest.site/press_release/{company.prefix}/projects/
                            </Typography>
                                <TextField placeholder="mlabs"
                                    value={prefix}
                                    variant="outlined"
                                    onChange={(e) => setPrefix(e.target.value)}
                                />
                            </Box>
                            :
                            <Box className={style.url}>
                                <Typography>企業のURLを設定すると編集できます。</Typography>
                            </Box>}
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                        <Button variant="contained" size="large" color="primary" onClick={handleSave}
                            disabled={!name}>
                            保存
                        </Button>
                    </TableCell>
                </>
                :
                <>
                    <TableCell style={{ width: "30%" }}>
                        {item.name}
                    </TableCell>
                    <TableCell style={{ width: "65%" }}>
                        {item.prefix ?
                            <Box className={style.url}>
                                <Typography className={style.url_first_half}>
                                    https://pr.harvest.site/press_release/{company.prefix}/projects/
                                </Typography>
                                <span style={{ fontWeight: "bold", fontSize: 16 }}>{item.prefix}</span>
                            </Box>
                            :
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <Typography>
                                    未設定
                                </Typography>
                            </Box>
                        }
                    </TableCell>
                    <TableCell style={{ width: "5%" }}>
                        <Button variant="outlined" size="large" color="primary" onClick={() => setForm(true)}>
                            編集
                        </Button>
                    </TableCell>
                </>}
        </TableRow >
    );
}

