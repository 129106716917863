import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from 'store'

import { LS_KEY_LIST_LAYOUT } from '../../../config/localstrage'
import { LAYOUT_TYPE_CARD } from '../../../config/layout_type'

// actions
import * as PressReleaseActionCreators from "../../../actions/PressRelease/ActionCreator";

// component
import { Typography } from '@material-ui/core/';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import HelpFab from "../../Common/HelpFab";
import Title from "../../Common/Title";
import SwitchLayoutRadios from './SwitchLayoutRadios'
import ListLayout from './ListLayout'
import CardLayout from './CardLayout'
import AppDialog from "../../Common/AppDialog";
import Pagination from "../../Common/Pagination";
import MaileOpenDialog from "../Common/MaileOpenDialog";
import SwitchFilter from "./SwitchFilter";
import CreateDialog from "../Common/CreateDialog";
import TrialEnd from "../../Common/TrialEnd";
import OverMember from "../../Common/OverMember";

// model
import { ListModel } from "../../../model/ListModel";

// style
import style from "./press_release_list.module.scss";
import ImagePressRelease from "../../../assets/guide/press_release.svg";

// state
import { RootState } from "../../../reducers";
const companySelector = (state: RootState) => state.company;
const routerSelector = (state: RootState) => state.router;
const isOverMemberSelector = (state: RootState) => state.member.isOverMember;

interface IProps {
    isArchive: boolean,
    isPublic: boolean,
    list: ListModel,
}

const PressReleaseList: React.FC<IProps> = ({ list, isArchive, isPublic }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [switchLayout, setSwitchLayout] = React.useState(LAYOUT_TYPE_CARD);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [mailOpenDialog, setMailOpenDialog] = useState(null);
    const [deleteId, setDeleteId] = useState('');
    const [status, setStatus] = useState(0);
    const { isFreePlanExpired } = useSelector(companySelector);
    const router = useSelector(routerSelector);
    const isOverMember = useSelector(isOverMemberSelector);

    useEffect(() => {
        if (store.get(LS_KEY_LIST_LAYOUT)) setSwitchLayout(store.get(LS_KEY_LIST_LAYOUT));
    }, []);

    // switch card or list
    const handleLayoutSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSwitchLayout(value);
        store.set(LS_KEY_LIST_LAYOUT, value)
    };

    // 削除
    const handleDeleteDialogOpen = (id) => {
        setDeleteDialog(true)
        setDeleteId(id)
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialog(false)
        setDeleteId('')
    };

    const handleDelete = () => {
        dispatch(PressReleaseActionCreators.deletePressReleaseDetails.request({ press_id: deleteId }));
        setDeleteDialog(false)
    };

    // 複製
    const handleClone = (body, name, is_header_loop, is_footer_loop) => {
        dispatch(PressReleaseActionCreators.postPressReleaseClone.request({
            body: { ...body },
            name: name,
            is_header_loop: is_header_loop,
            is_footer_loop: is_footer_loop,
        }));
    };

    // アーカイブ
    const handleArchive = (id) => {
        dispatch(PressReleaseActionCreators.patchPressReleaseArchive.request({ press_id: id, archive: !isArchive }));
    };

    // ページング
    const handlePageChange = (pageNumber) => {
        const is_archive = !!(router.location.pathname.indexOf('archive') > -1 ? 1 : 0)
        const is_public = !!(router.location.pathname.indexOf('public') > -1 ? 1 : 0)
        if (is_archive) {
            dispatch(PressReleaseActionCreators.getPressReleaseListArchive.request({ offset: pageNumber }))
        } else if (is_public) {
            dispatch(PressReleaseActionCreators.getPressReleaseListPublic.request({ offset: pageNumber }))
        } else {
            dispatch(PressReleaseActionCreators.getPressReleaseList.request({ offset: pageNumber }));
        }
    }

    // ページング
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setStatus(newValue)
        dispatch(PressReleaseActionCreators.getPressReleaseList.request({ offset: 0 }));
    }

    const handleOpenCreateDialog = () => {
        dispatch(PressReleaseActionCreators.setPressReleaseCreateDialog.request(true));
    }

    return (
        <section className={style.root}>
            <div className={style.head}>
                <Title>
                    プレスリリース<HelpFab url={'/help/press_release#1'} />
                </Title>
                {!isFreePlanExpired && !isOverMember &&
                    <div className={style.filter}>
                        <div className={classes.filterBox}>
                            <Button variant="contained" size="large" color="primary" onClick={handleOpenCreateDialog} className={classes.newButton}>
                                新規作成
                            </Button>
                            <SwitchFilter />
                            <SwitchLayoutRadios type={switchLayout} handleChange={handleLayoutSwitch} />
                        </div>
                    </div>
                }
            </div>
            {// トライアル期間終了
                isFreePlanExpired && <TrialEnd />
            }
            {// メンバー数超過
                !isFreePlanExpired && isOverMember && <OverMember />
            }
            {!isFreePlanExpired && !isOverMember && (
                list.results.length > 0 ?
                    <>
                        <div>
                            {/*{!archive && <StatusTab value={status} handleChange={handleTabChange}/>}*/}
                            <div className={style.list}>
                                {switchLayout === 'card' ?
                                    <CardLayout
                                        list={list.results}
                                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                                        handleClone={handleClone}
                                        handleArchive={handleArchive}
                                        handleOpenMailDialog={setMailOpenDialog}
                                    />
                                    :
                                    <ListLayout
                                        list={list.results}
                                        handleDeleteDialogOpen={handleDeleteDialogOpen}
                                        handleClone={handleClone}
                                        handleArchive={handleArchive}
                                        handleOpenMailDialog={setMailOpenDialog}
                                    />
                                }
                            </div>
                        </div>
                        {list.results.length > 0 &&
                            <div className={style.pagination}>
                                <Pagination
                                    activePage={list.offset}
                                    totalCount={list.count}
                                    handlePageChange={handlePageChange}
                                    displayCount={list.displayCount}
                                />
                            </div>
                        }
                        <MaileOpenDialog isOpen={Boolean(mailOpenDialog)} id={mailOpenDialog}
                            handleClose={() => setMailOpenDialog(null)} />
                        <AppDialog isOpen={deleteDialog} closeHandle={handleDeleteDialogClose} mainHandle={handleDelete}
                            text='削除しますか' ButtonText="削除" />
                    </>
                    :
                    <div className={classes.noData}>
                        <div>
                            <div className={classes.noDataImg}><img src={ImagePressRelease} /></div>
                            <Typography variant="h4" gutterBottom>
                                プレスリリースを作成・配信してみましょう
                            </Typography>
                            <Button variant="contained" color="primary" size={"large"} className={classes.createButton} onClick={() => {
                                dispatch(PressReleaseActionCreators.setPressReleaseCreateDialog.request(true));
                            }}>
                                新規作成する
                            </Button>
                        </div>
                    </div>
            )}
            <CreateDialog />
        </section>
    )
}

export default PressReleaseList;


const useStyles = makeStyles({
    noData: {
        marginTop: 20,
        textAlign: "center",
        marginBottom: 30,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    noDataImg: {
        margin: "30px auto",
        width: 400
    },
    createButton: {
        marginTop: 20,
        width: 400,
    },
    filterBox: {
        display: 'flex',
    },
    newButton: {
        marginRight: 15,
    },
});
