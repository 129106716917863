import * as  ActionCreators from '../actions/Analytics/ActionCreator';
import * as  PressReleaseActionCreators from '../actions/PressRelease/ActionCreator';

import { fetchGet } from "./fetch";
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { selectGroup } from "./selector";
import AnalyticsActionActionType from "../actions/Analytics/ActionType";


function* getAnalyticsDetail(action: ReturnType<typeof ActionCreators.getAnalyticsDetail.request>) {
    try {
        let { press_id } = action.payload
        const group = yield select(selectGroup)
        yield put(PressReleaseActionCreators.getSendPressReleaseList.request())
        const [data, error] = yield call(fetchGet, `press_info/${press_id}/detail/`)
        yield put(ActionCreators.getAnalyticsDetail.success(data))
    } catch (e) {
        yield put(ActionCreators.getAnalyticsDetail.failure(e))
    }

}


function* getAnalyticsSummary(action: ReturnType<typeof ActionCreators.getAnalyticsSummary.request>) {
    try {
        const group = yield select(selectGroup)
        yield put(PressReleaseActionCreators.getSendPressReleaseList.request())
        const { from, to } = action.payload
        const timezoneOffsetNum = -new Date().getTimezoneOffset()
        // timezoneOffsetは"+0900"のようなtimezone情報
        // "+"をdjangoに渡すときにエンコードする必要があるため"%2B"にする
        const timezoneOffset = (timezoneOffsetNum >= 0 ? "%2B" : "-") + ("0000" + (timezoneOffsetNum / 60 * 100)).slice(-4)

        const fromDateString = `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}T00:00:00.000` + timezoneOffset
        const toDateString = `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}T00:00:00.000` + timezoneOffset
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/analytics/summary/?from=${fromDateString}&to=${toDateString}`)
        yield put(ActionCreators.getAnalyticsSummary.success(data))

    } catch (e) {
        yield put(ActionCreators.getAnalyticsSummary.failure(e))
    }

}
function* getOpenMail(action: ReturnType<typeof ActionCreators.getOpenMail.request>) {
    try {
        let { press_id } = action.payload
        const [data, error] = yield call(fetchGet, `press_info/${press_id}/analytics/open_mail/`)
        yield put(ActionCreators.getOpenMail.success(data))
    } catch (e) {
        yield put(ActionCreators.getOpenMail.failure(e))
    }

}



const AnalyticsSaga = [
    takeLatest(AnalyticsActionActionType.GET_ANALYTICS_DETAIL_REQUEST, getAnalyticsDetail),
    takeLatest(AnalyticsActionActionType.GET_ANALYTICS_SUMMARY_REQUEST, getAnalyticsSummary),
    takeLatest(AnalyticsActionActionType.GET_OPEN_MAIL_REQUEST, getOpenMail),
]

export default AnalyticsSaga;
