import React from 'react';

//component
import {RadarListItem} from "./ListItem";
import {ListHeader} from "./ListHeader"

//model
import {RadarResultContentModel, RadarWordModel, RadarSelectedModel} from "../../../model/RadarModel";

//style
import style from "../radar.module.scss";

interface Props {
    targetWord: RadarWordModel | undefined
    selectedDate: string
    list: Array<RadarResultContentModel>
}


export const ListLayout: React.FC<Props> = ({targetWord, selectedDate, list}) => {
    return (
        <div className={style.root}>
            <ListHeader qty={list.length} word={targetWord} selectedDate={selectedDate}/>
            <ul className={style.searchResultListRoot}>
                {list.map((item, index) => (
                    <RadarListItem item={item} key={index}/>
                ))}
            </ul>
        </div>
    )
}
