import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// style
import style from "./share_dialog.module.scss";

// component
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, Typography, DialogActions, FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import Button from "@material-ui/core/Button";

// state
import { RootState } from "../../../../reducers";

//action
import * as ActionCreators from "../../../../actions/PressReleaseReserve/ActionCreator";

// model
import { MediaCategoryModel, MediaCategoryDetailModel } from "../../../../model/MediaModel";

const mediaSelector = (state: RootState) => state.media;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

interface ShareDialogProps {
    isOpen: boolean,
    closeHandle,
}

const useStyles = makeStyles({
    content: {
        width: 1000,
        overflow: "scroll",
        paddingTop: 20,
        backgroundColor: '#fff',
    },
    chip: {
        float: "left",
        margin: "0 8px 8px 0"
    },
    mediaLabel: {
        fontSize: 13,
    },
});

const CategoryDialog: React.FC<ShareDialogProps> = ({ isOpen, closeHandle }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const reserve = useSelector(pressReleaseReserveSelector);
    const { type } = reserve.data;
    const [categories, setCategories] = React.useState('');
    const [details, setDetails] = React.useState('');

    const handleChangeTag = (id: number) => {
        let list: any = categories.split(',')
        if (list.indexOf(String(id)) >= 0) list = list.filter(item => item !== String(id));
        else if (list.length <= 2) list.push(id);
        setCategories(list.join(','));
    };

    const handleChangeDetailTag = (id: number) => {
        let list: any = details.split(',');
        if (list.indexOf(String(id)) >= 0) list = list.filter(item => item != String(id));
        else list.push(id);
        setDetails(list.join(','));
    }

    const handlePostCategory = () => {
        const list = categories.split(',').filter(v => v)
        const detail = details.split(',').filter(v => v)

        if (type) {
            dispatch(ActionCreators.removePressReleaseReserveAutoMediaAll.request());
            dispatch(ActionCreators.postPressReleaseMediaRecommend.request({ categories: list, type: type, details: detail, count: 0 }));
        } else {
            dispatch(ActionCreators.setPressReleaseReserveCategory.request({ categories: list, details: detail }));
        }
        closeHandle()
    };

    const handleAddAllCheck = (category: MediaCategoryModel) => {
        let list: any = details.split(',');
        category.details.map((detail: MediaCategoryDetailModel, index: number) => list.push(detail.id));
        list = list.filter(function (x, i, self) { return self.indexOf(x) === i });
        setDetails(list.join(','));
    }

    const handleRemoveCheck = (category: MediaCategoryModel) => {
        let list: any = details.split(',');
        category.details.map((detail: MediaCategoryDetailModel, index: number) => {
            list.filter(n => {
                n !== detail.id;
            });
        });
        list = list.filter(function (x, i, self) { return self.indexOf(x) === i });
        setDetails(list.join(','));
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                カテゴリを選択してください
                <Typography variant="caption" display={"block"}>選択した情報とプレスリリースの内容から配信先の自動選定を行います。</Typography>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <CategorySelect
                    categories={categories}
                    details={details}
                    handleChangeTag={handleChangeTag}
                    handleChangeDetailTag={handleChangeDetailTag}
                    handleAddAllCheck={handleAddAllCheck}
                    handleRemoveCheck={handleRemoveCheck}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" color="primary" onClick={closeHandle}>
                    キャンセル
                </Button>
                <Button variant="contained" size="large" color="primary" onClick={handlePostCategory}
                    disabled={!categories.length || !details.length}>
                    設定する
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CategoryDialog;

export const CategorySelect = ({
    categories, details, handleChangeTag, handleChangeDetailTag, handleAddAllCheck, handleRemoveCheck
}) => {
    const classes = useStyles();
    const media = useSelector(mediaSelector);
    return (
        <>
            <section className={style.item}>
                <p className={style.subTitle}>大カテゴリ<br />(2つまで)</p>
                <ul className={style.form}>
                    {media.categories.results.map((tag, index) => {
                        const list = categories.split(',')
                        return <li key={index} className={classes.chip}>
                            {list.indexOf(String(tag.id)) >= 0 ?
                                <Chip
                                    color="primary"
                                    label={tag.name}
                                    onClick={() => handleChangeTag(tag.id)}
                                    deleteIcon={<DoneIcon />}
                                />
                                :
                                <Chip
                                    label={tag.name}
                                    onClick={() => handleChangeTag(tag.id)}
                                />
                            }
                        </li>
                    })}
                </ul>
            </section>
            <section className={style.subItem}>
                <p className={style.subCategory}>小カテゴリ</p>
                <ul className={style.form}>
                    {media.categories.results.map((category, index) => {
                        const list = categories.split(',')
                        if (list.indexOf(String(category.id)) >= 0) {
                            return (
                                <CategoryDetailSelect key={index} category={category} details={details}
                                    handleChangeDetailTag={handleChangeDetailTag} handleAddAllCheck={handleAddAllCheck} handleRemoveCheck={handleRemoveCheck} />
                            );
                        }
                    })}
                    {categories.length === 0 && <p className={style.noCategory}>大カテゴリを選択してください</p>}
                </ul>
            </section>
        </>
    )
}

export const CategoryDetailSelect = ({ category, details, handleChangeDetailTag, handleAddAllCheck, handleRemoveCheck }) => {
    const classes = useStyles();

    return (
        <li>
            <section className={style.smallCategory}>
                <div className={style.smallCategoryHead}>
                    <Typography variant='h5' className={style.smallCategoryLabel}>{category.name}</Typography>
                    <CheckButtons handleAddAllCheck={handleAddAllCheck} handleRemoveCheck={handleRemoveCheck}
                        category={category} details={details} />
                </div>
                <Divider />
                <ul className={style.smallCategoryForm}>
                    {category.details.map((tag, index) => {
                        const list = details.split(',')
                        return (
                            <li className={style.mediaItem} key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={list.indexOf(String(tag.id)) >= 0}
                                            onChange={() => handleChangeDetailTag(tag.id)}
                                            value={tag.id}
                                            color="primary"
                                        />
                                    }
                                    label={tag.name}
                                    classes={{ label: classes.mediaLabel }}
                                />
                            </li>
                        )
                    })}
                </ul>
            </section>
        </li>
    );
}

const CheckButtons = ({ handleAddAllCheck, handleRemoveCheck, category, details }) => {
    const [value, setValue] = useState<boolean>(false);

    useEffect(() => {
        category.details.map(detail => {
            if (details.split(',').indexOf(detail.id.toString()) < 0) setValue(false);
        })
    }, [category, details, value])

    const handleChange = () => {
        if (value) {
            handleRemoveCheck(category);
            setValue(false);
        } else {
            handleAddAllCheck(category);
            setValue(true);
        }
    }

    return (
        <div className={style.checkBox}>
            <Checkbox checked={value} color='primary' onClick={() => handleChange()} />
            <Typography variant='body2'>全てにチェックをつける</Typography>
        </div>
    )
}
