import ActionType from './ActionType';
import { createStandardAction } from 'typesafe-actions';

export const setErrorMessage = createStandardAction(
	ActionType.SET_ERROR_MESSAGE
)<string>();

export const setErrorDialog = createStandardAction(
	ActionType.SET_ERROR_DIALOG
)<boolean>();

export const cleanUpErrorState = createStandardAction(
	ActionType.CLEAN_UP_ERROR_STATE
)<void>();
