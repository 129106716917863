import React from 'react';

//component
import {HasNoTicket} from "./HasNoTicket";
import {ReserveConfirm} from "./ReserveConfirm";
import {ReserveFix} from "./ReserveFix";
import {ReviewAccept} from "./ReviewAccept";

//model
import {TicketQtyModel} from "../../../../../model/TicketModel";

//config
import {
    STATUS_ACCEPT,
    STATUS_PUBLIC_REVIEW,
    STATUS_PUBLIC_REJECT,
    STATUS_PUBLIC_ACCEPT
} from "../../../../../config/status_type";
import {PAGE_RESERVE} from "../../../../../config/page_type";

interface Props {
    page: string
    status: number
    isReviewSkip: boolean
    ticketQty: TicketQtyModel
}

export const TicketConfirmDialogContent: React.FC<Props> = ({page, status, isReviewSkip, ticketQty}) => {
    const totalAvailableTicketQty = ticketQty['default']['available'] + ticketQty['non_default']['available']
    if (page === PAGE_RESERVE && (status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) && isReviewSkip) {
        //予約確定する
        if (totalAvailableTicketQty === 0) return <HasNoTicket ticketQty={ticketQty}/>
        return <ReserveConfirm ticketQty={ticketQty}/>
    } else if (page === PAGE_RESERVE && status === STATUS_PUBLIC_ACCEPT) {
        //配信設定を修正する
        return <ReserveFix ticketQty={ticketQty}/>
    } else if (page === PAGE_RESERVE && status === STATUS_PUBLIC_REVIEW && !isReviewSkip) {
        //配信予約のレビューを承認する
        return <ReviewAccept ticketQty={ticketQty}/>
    }
    return <></>
}
