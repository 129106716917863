import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {push} from 'connected-react-router';
import moment from "moment";

import {
    selectDisplayItems,
    endDateLessThanEqualStartDate,
    targetIndexToDate
} from '../../util/radar'

//material-ui
import {Button, Typography} from '@material-ui/core';

//component
import {Chart} from "./Chart";
import {ListLayout} from "./List/ListLayout";
import RadarDatePicker from "./DatePicker";
import {WordSelector} from "./WordSelector";
import Title from "../Common/Title";
import HelpFab from "../Common/HelpFab";
import DisabledOption from "../Common/DisabledOption";
import OverWordContent from "./OverWord";

//action
import * as RadarActionCreators from '../../actions/Radar/ActionCreator';

//model
import {RootState} from "../../reducers"
import {SelectedContentModel} from "../../model/RadarModel";

//style
import style from "./radar.module.scss";
import {makeStyles} from "@material-ui/core/styles";

const radarStateSelector = (state: RootState) => state.radar;
const companySelector = (state: RootState) => state.company;
const groupSelector = (state: RootState) => state.group;

export const Radar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const company = useSelector(companySelector);
    const group = useSelector(groupSelector);
    const isCompanyFetched = !!company.uuid;
    const {
        words,
        selected,
        displayItems,
        targetIndex,
        targetWordId,
        datePickerSelected,
        isOverWord,
    } = useSelector(radarStateSelector);
    const isRadarAvailable: boolean = company.option.radar.isAvailable;
    const isRadarWordQtyZero: boolean = words.length === 0;

    const selectedDisplayItems = selectDisplayItems(targetWordId, displayItems)
    const items = targetIndex != null && selectedDisplayItems.length >= targetIndex ? selectedDisplayItems[targetIndex] : []
    const buttonDisabled = datePickerSelected.start === null
        || datePickerSelected.end === null
        || endDateLessThanEqualStartDate(datePickerSelected.start, datePickerSelected.end)
        || !isRadarAvailable
    const targetWord = words.find(word => word.id === targetWordId);
    const selectedDate = targetIndexToDate(targetIndex, selected);
    const isDisabled = isCompanyFetched && !isRadarAvailable;

    const now = new Date();

    const handleChangeStartDatetime = (dt: Date) => {
        dispatch(RadarActionCreators.setDatePickerStartSelected.request({
            year: dt.getFullYear(),
            month: dt.getMonth(),
            date: dt.getDate()
        }))
    }

    const handleChangeEndDatetime = (dt: Date) => {
        dispatch(RadarActionCreators.setDatePickerEndSelected.request({
            year: dt.getFullYear(),
            month: dt.getMonth(),
            date: dt.getDate()
        }))
    }

    const handleDatePickerButtonClick = () => {
        dispatch(RadarActionCreators.setSelectedDate.request(datePickerSelected))
    }

    const handleEditClick = () => {
        dispatch(push('/setting/radar/'))
    }

    useEffect(() => {
        if (words.length > 0) {
            dispatch(RadarActionCreators.setTargetWordId.request(words[0].id))
        }
    }, [company.uuid, group.selectedId, words])

    //初回描画時とgroupが変更されたときは現在の月が選択されている状態にする
    useEffect(() => {
        if (company.uuid && selected.start == null && selected.end == null) {
            const start = moment().startOf('month')
            const end = moment().endOf('month')
            const data = {
                start: {
                    year: start.get('year'),
                    month: start.get('month'),
                    date: start.get('date')
                },
                end: {
                    year: end.get('year'),
                    month: end.get('month'),
                    date: end.get('date')
                }
            }
            dispatch(RadarActionCreators.setSelectedDate.request(data));
            dispatch(RadarActionCreators.setDatePickerStartSelected.request(data.start))
            dispatch(RadarActionCreators.setDatePickerEndSelected.request(data.end))
        }
    }, [company.uuid, group.selectedId])

    //選択されている年月が変更されるたびに結果を取得する
    useEffect(() => {
        const {start, end} = selected;
        if (!!start && !!end) {
            const data = {start, end}
            dispatch(RadarActionCreators.getRadarResults.request(data))
        }
    }, [selected.start, selected.end])

    useEffect(() => {
        if (targetIndex === null && targetWordId && selectedDisplayItems.length > 0) {
            const idx = selectedDisplayItems.findIndex(item => item.length > 0);
            if (idx > 0) dispatch(RadarActionCreators.setTargetIndex.request(idx))
        }
    }, [group.selectedId, displayItems])

    const objToDate = (obj: SelectedContentModel) => {
        return new Date(obj.year, obj.month, obj.date)
    }

    return (
        <>
            <Title>
                記事化レーダー<HelpFab url={'/help/radar#1'}/>
            </Title>
            {isDisabled ?
                <DisabledOption optionName={"記事化レーダー"} />
                :
                isOverWord ?
                    <OverWordContent />
                    :
                    <>
                        <div className={style.container}>
                            <div className={style.dateSelect}>
                                <div>
                                    <Typography>from</Typography>
                                    <RadarDatePicker
                                        datetime={now}
                                        handleChangeDatetime={handleChangeStartDatetime}
                                        defaultDate={datePickerSelected.start ? objToDate(datePickerSelected.start) : now}
                                    />
                                </div>
                                <div>
                                    <Typography>to</Typography>
                                    <RadarDatePicker
                                        datetime={now}
                                        handleChangeDatetime={handleChangeEndDatetime}
                                        defaultDate={datePickerSelected.end ? objToDate(datePickerSelected.end) : now}
                                    />
                                </div>
                                <Button
                                    className={classes.searchButton}
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={handleDatePickerButtonClick}
                                    disabled={buttonDisabled}
                                >
                                    検索
                                </Button>
                            </div>
                            <div className={style.wordSelect}>
                                {!isRadarWordQtyZero && <WordSelector/>}
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    onClick={handleEditClick}
                                    disabled={!isRadarAvailable}
                                >
                                    {isRadarWordQtyZero ? "検索ワードを登録する" : "検索ワードを編集"}
                                </Button>
                            </div>
                        </div>
                        <Chart/>
                        <div className={style.infoContainer}>
                            {targetWord !== undefined &&
                            <>
                                <div className={style.word}>
                                    <Typography variant="h6">検索ワード:</Typography>
                                    <Typography variant="h5" className={style.value}>{targetWord.radar_word}</Typography>
                                </div>
                                <div className={style.word}>
                                    <Typography variant="h6">除外ワード:</Typography>
                                    <Typography variant="h5" className={style.value}>{targetWord.exclude_word}</Typography>
                                </div>
                            </>
                            }
                            {/*<Button*/}
                            {/*    className={style.csvButton}*/}
                            {/*    variant="contained"*/}
                            {/*    size="large"*/}
                            {/*    color="primary"*/}
                            {/*>*/}
                            {/*    CSVダウンロード*/}
                            {/*</Button>*/}
                        </div>
                        <ListLayout targetWord={targetWord} selectedDate={selectedDate} list={items}/>
                    </>
            }
        </>
    )
}

const useStyles = makeStyles({
    searchButton: {
        height: 42
    }
})
