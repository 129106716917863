import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import store from "store";
import { useDispatch, useSelector } from "react-redux";

// components
import AppLayout from "../../components/Layout/App";
import Guide from "../../components/Guide/List";
import { push } from "connected-react-router";
import { RootState } from "../../reducers";

const groupSelector = (state: RootState) => state.group;

interface Props extends RouteComponentProps<void> { }

function HomePage(props: Props) {
	const dispatch = useDispatch();
	const group = useSelector(groupSelector);

	useEffect(() => {
		const briefingId = store.get("briefingId");
		const answerId = store.get("answerPath");
		const answerPath = answerId ? `?i=${answerId}` : "";
		if (briefingId && group.selectedId) {
			store.remove("briefingId");
			store.remove("answerPath");
			dispatch(push(`/briefing/${group.selectedId}/answer/${briefingId}/${answerPath}`));
		}
	}, [group.selectedId]);

	return (
		<AppLayout>
			<Guide />
		</AppLayout>
	);
}

export default HomePage;
