import React from "react";
import { useSelector } from "react-redux";

import { convertPressKitIdToTitle } from "../../../../../util/reserve";

//material-ui
import { Button, Typography, Chip } from "@material-ui/core";

//model
import { RootState } from "../../../../../reducers";
import { CompanyModel } from "../../../../../model/CompanyModel";
import style from "./attachedPressKit.module.scss";
import {PressKitFirestoreModel} from "../../../../../model/PressKitModel";

const pressKitIdSelector = (state: RootState): Array<string> => state.pressReleaseReserve.data.press_kit_id;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;

interface Company extends CompanyModel {
  prefix: string,
}
interface Props {
  company: Company,
  hasEdit: boolean,
  isReserveEdit: boolean,
  handleDialogOpen: (isOpen: true) => void,
  isLocked: boolean,
}

export const AttachPressKit: React.FC<Props> = ({ company, hasEdit, isReserveEdit, handleDialogOpen, isLocked }) => {
  const attachedPressKitIdList = useSelector(pressKitIdSelector);
  const pressKitListInState = useSelector(firestorePressKitsSelector);
  const isPressKitSelected = attachedPressKitIdList.length > 0 ? true : false;

  const pressKitTitleList = convertPressKitIdToTitle(attachedPressKitIdList, pressKitListInState);
  return (
    <>
    {isPressKitSelected ? (
      <div className={style.container}>
        <ul className={style.titleList}>
          {pressKitTitleList.map((title, index) => (
            <li key={index} className={style.listItem}>
              <Chip
                  className={style.pressKitTitle}
                  variant={"outlined"}
                  label={title}
              />

            </li>
          ))}
        </ul>
        <Button
          className={style.button}
          variant="outlined"
          size="medium"
          color="primary"
          disabled={ !company.prefix || !hasEdit || !isReserveEdit || isLocked }
          onClick={() => handleDialogOpen(true)}
        >
          選択する
        </Button>
      </div>
    ) : (
      <Button
        variant="contained"
        size="large"
        color="primary"
        disabled={ !company.prefix || !hasEdit || !isReserveEdit || isLocked }
        onClick={() => handleDialogOpen(true)}
      >
        プレスキットを選択
      </Button>
    )}
    </>
  )
};
