import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from '@material-ui/core';
import UsersAvatar from './UsersAvatar';
import SendMailDialog from '../Dialog/SendMail';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// config
import { BRIEFING_STATUS } from '../../../config/briefing';
import { FREE } from '../../../config/payment_config';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;
const routerSelector = (state: RootState) => state.router;
const meSelector = (state: RootState) => state.me;
const companySelector = (state: RootState) => state.company;

const AnswerHeader: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useSelector(routerSelector);
    const { plan } = useSelector(companySelector);
    const me = useSelector(meSelector);
    const { detail, answerInfo } = useSelector(briefingSelector);
    const { body, id: briefingId, fetched } = detail;
    const [shareDialog, setShareDialog] = useState(false);

    const location: any = router.location;
    const answerId = location.query.i || '';

    const isButtonDisabled = !fetched || body.status != BRIEFING_STATUS.PUBLIC;

    const handleSave = (flag: { is_temp: boolean }) => {
        dispatch(BriefingActionCreators.postBriefingAnswer.request({ id: briefingId, answerId, ...flag }));
    }

    return (
        <>
            <div className={me.uuid ? classes.root : classes.anonymousRoot}>
                <Typography variant="h5" className={classes.name}>ブリーフィング</Typography>
                <div className={classes.buttons}>
                    {fetched && body.status != BRIEFING_STATUS.PUBLIC &&
                        <Typography variant="h6" className={classes.error}>
                            公開されていないため回答できません
                        </Typography>}
                    <div className={classes.avatars}>
                        <UsersAvatar users={answerInfo.body.updated_by} />
                    </div>
                    {answerInfo.body.is_temp ?
                        <>
                            <Button variant="outlined" color="primary" disabled={isButtonDisabled}
                                className={classes.button} onClick={() => handleSave({ is_temp: true })}>
                                下書き保存
                            </Button>
                            <Button variant="contained" color="primary" disabled={isButtonDisabled}
                                className={classes.button} onClick={() => handleSave({ is_temp: false })}>
                                回答を送信
                            </Button>
                        </>
                        :
                        <Button variant='contained' color='primary' disabled={isButtonDisabled}
                            className={classes.button} onClick={() => handleSave({ is_temp: false })}>
                            回答を更新
                        </Button>}
                    {(answerInfo.body.created_by === me.email && plan.code != FREE) &&
                        <Button variant='contained' color='secondary'
                            disabled={isButtonDisabled || !answerId}
                            onClick={() => setShareDialog(true)}>
                            共同編集
                        </Button>}
                </div>
            </div>
            <SendMailDialog isOpen={shareDialog} title={body.name} handleClose={() => setShareDialog(false)} type="share" />
        </>
    );
}

export default AnswerHeader;

const useStyles = makeStyles({
    root: {
        height: 60,
        borderBottom: '1px solid #E2E9E5',
        backgroundColor: '#fff',
        zIndex: 30,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        width: 'calc(100% - 240px)',
        alignItems: 'center',
        padding: '0 20px',
    },
    anonymousRoot: {
        height: 60,
        borderBottom: '1px solid #E2E9E5',
        backgroundColor: '#fff',
        zIndex: 30,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        left: 0,
        right: 0,
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    button: {
        marginRight: 10,
    },
    avatars: {
        marginRight: 7,
    },
    error: {
        color: 'red',
        marginRight: 7,
    },
})
