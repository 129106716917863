import React from "react";
import { PressReleaseModel } from "../../../model/PressReleaseModel";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";

//コンポーネント
import FroalaEditorComponent from "../../Common/Editor/FroalaEditor";

interface IProps {
    detail: PressReleaseModel
}

const groupSelector = (state: RootState) => state.group;

const EditorComponent: React.FC<IProps> = props => {
    const group = useSelector(groupSelector);

    const { detail } = props;
    const { html, css }  = detail.body;

    if (group.selectedId) {
        return(
            <FroalaEditorComponent
                css={css}
                html={html}
                groupSelectedId={group.selectedId}
            />
        );
    } else {
        return(
            <div>

            </div>
        );
    }
}

export default EditorComponent;
