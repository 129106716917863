enum RadarActionType {
	GET_RADAR_WORDS_REQUEST = "GET_RADAR_WORDS_REQUEST",
	GET_RADAR_WORDS_SUCCEEDED = "GET_RADAR_WORDS_SUCCEEDED",
	GET_RADAR_WORDS_FAILED = "GET_RADAR_WORDS_FAILED",

	GET_RADAR_RESULTS_REQUEST = "GET_RADAR_RESULTS_REQUEST",
	GET_RADAR_RESULTS_SUCCEEDED = "GET_RADAR_RESULTS_SUCCEEDED",
	GET_RADAR_RESULTS_FAILED = "GET_RADAR_RESULTS_FAILED",

	SET_RADAR_SELECTED_DATE = "SET_RADAR_SELECTED_DATE",

	SET_RADAR_DISPLAY_ITEMS = "SET_RADAR_DISPLAY_ITEMS",

	SET_RADAR_DISPLAY_MODE = "SET_RADAR_DISPLAY_MODE",

	SET_TARGET_INDEX = "SET_TARGET_INDEX",

	SET_TARGET_WORD_ID = "SET_TARGET_WORD_ID",

	SET_DATE_PICKER_START_SELECTED = "SET_DATE_PICKER_START_SELECTED",
	SET_DATE_PICKER_END_SELECTED = "SET_DATE_PICKER_END_SELECTED",

	DELETE_RADAR_WORD_REQUEST = "DELETE_RADAR_WORD_REQUEST",
	DELETE_RADAR_WORD_SUCCEEDED = "DELETE_RADAR_WORD_SUCCEEDED",
	DELETE_RADAR_WORD_FAILED = "DELETE_RADAR_WORD_FAILED",

	POST_CREATE_RADAR_WORD_REQUEST = "POST_CREATE_RADAR_WORD_REQUEST",
	POST_CREATE_RADAR_WORD_SUCCEEDED = "POST_CREATE_RADAR_WORD_SUCCEEDED",
	POST_CREATE_RADAR_WORD_FAILED = "POST_CREATE_RADAR_WORD_FAILED",

	PATCH_RADAR_WORD_REQUEST = "PATCH_RADAR_WORD_REQUEST",
	PATCH_RADAR_WORD_SUCCEEDED = "PATCH_RADAR_WORD_SUCCEEDED",
	PATCH_RADAR_WORD_FAILED = "PATCH_RADAR_WORD_FAILED",

	DELETE_RADAR_RESULT_REQUEST = "DELETE_RADAR_RESULT_REQUEST",
	DELETE_RADAR_RESULT_SUCCEEDED = "DELETE_RADAR_RESULT_SUCCEEDED",
	DELETE_RADAR_RESULT_FAILED = "DELETE_RADAR_RESULT_FAILED",

	SET_IS_OVER_WORD = "SET_IS_OVER_WORD",
}

export default RadarActionType;

