import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// component
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Box, Button, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

// state
import { RootState } from '../../../reducers';
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const mediaSelector = (state: RootState) => state.media;

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

interface Props {
    handleSearch,
}

const Search: React.FC<Props> = ({ handleSearch }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const media = useSelector(mediaSelector);

    const handleChangeSearch = (item) => {
        dispatch(MediaActionCreators.setSearch.request(item))
    }

    return (
        <Box className={classes.root}>
            <TextField
                value={media.search.text}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeSearch({ text: e.target.value })}
                variant="outlined"
                placeholder='検索'
                className={classes.textField}
                InputProps={{
                    classes: {
                        input: classes.input,
                        adornedEnd: classes.adornedEnd,
                    },
                    endAdornment: (
                        <IconButton onClick={() => {
                            handleChangeSearch({ text: '', is_result: false });
                            handleSearch();
                        }}>
                            <CancelIcon />
                        </IconButton>
                    ),
                }}
            />
            <Button variant='outlined' color='primary' onClick={() => {
                handleChangeSearch({ is_result: true })
                handleSearch();
            }}>
                検索
            </Button>
        </Box>
    );
}

export default Search;

const useStyles = makeStyles({
    root: {
        display: 'flex',
        width: 275,
        justifyContent: 'space-between',
        marginRight: 15,
    },
    textField: {
        width: 200,
    },
    input: {
        padding: 10,
    },
    adornedEnd: {
        padding: 0,
    },
    textFieldDisabled: {
        backgroundColor: 'whitesmoke',
    },
})
