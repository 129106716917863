import React from 'react';
import Avatar from '../Avatar';
import DateTime from '../DateTime';
import style from "./avater_chip.module.scss";
import classNames from 'classnames';
import IconInfo from "../../../assets/icon_info_orange.svg";
import IconCheck from "../../../assets/icon_check_blue.svg";

interface SortList {
    user,
    component?,
    message?,
    color?: "yellow" | "blue" | "default",
    updated_at?,
}


const Chip: React.FC<SortList> = ({user, component=<></>, message=<></>, color="default", updated_at}) => {

    let bgColor = style.default;

    if (color === "yellow") {
        bgColor = style.yellow
    } else if (color === "blue") {
        bgColor = style.blue
    }

    return (
        <div className={classNames(style.root,bgColor)}>
            <div className={style.inner}>
                <Avatar src={user.img} name={user.last_name} size="small" color={user.color_cd}/>
                <div className={style.info}>
                    <p className={style.name}>{user.last_name} {user.first_name} </p>
                </div>
                <div className={style.action}>
                    {component}
                    {color === "yellow" && <p className={style.title}><img src={IconInfo}/>修正依頼あり</p>}
                    {color === "blue" &&  <p className={style.title}><img src={IconCheck}/>確認済み</p>}
                </div>
            </div>
            {message && <div className={style.message}>{message}</div>}
            {updated_at && <div className={style.date}><DateTime datetime={updated_at} isFormat={true}/></div>}
        </div>
    );
}


export default Chip
