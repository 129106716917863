import React from 'react';
import { useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import Content from './Content';
import SendList from './SendList';

// model
import { PageTypeModel } from '../../../model/BriefingModel';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

interface Props {
    pageType: PageTypeModel,
}

const Detail: React.FC<Props> = ({ pageType }) => {
    const classes = useStyles();
    const { detail } = useSelector(briefingSelector);

    return (
        <section className={classes.root}>
            {detail.body.name &&
                <Typography className={classes.title}>
                    タイトル：<span className={classes.name}>{detail.body.name}</span>
                </Typography>}
            <div className={classes.inner}>
                <div className={classes.content}>
                    <Content />
                </div>
                <div className={classes.sendList}>
                    <SendList pageType={pageType} />
                </div>
            </div>
        </section>
    );
}

export default Detail;

const useStyles = makeStyles({
    root: {
        padding: '70px 15px',
        margin: '0 auto',
        maxWidth: 1000,
    },
    inner: {
        display: 'flex',
    },
    content: {
        margin: '0 5px 0 10px',
        width: '70%',
    },
    sendList: {
        margin: '0 10px 0 5px',
        width: '30%',
    },
    title: {
        padding: 5,
    },
    name: {
        color: "#6D7470",
        fontSize: 18,
        fontWeight: "bold",
    },
});
