import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import classNames from 'classnames';

// component
import { makeStyles, TableRow, TableCell, TableBody, Box, IconButton, ListItemIcon, Tooltip } from '@material-ui/core';
import TextField from '../../../Field/TextFieldUnderline';
import RadioQuery from '../Radio';
import QueryTypeSelect from '../TypeSelect';

// icons
import DeleteIcon from '@material-ui/icons/Delete';
import DragHandleIcon from '@material-ui/icons/DragHandle';

// actions
import * as BriefingActionCreators from '../../../../actions/Briefing/ActionCreator';

// model
import { BriefingBodyModel, BriefingQueryModel } from '../../../../model/BriefingModel';

// config
import { QUERY_TYPES } from '../../../../config/briefing';

// state
import { RootState } from '../../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

const SortableEditTableBody = SortableContainer(
    (props: { body: BriefingBodyModel }) => {
        const { body } = props;
        return (
            <TableBody>
                {body.queries.map((query, index) => (
                    <SortableEditTableRow key={index} query={query} body={body} index={index} queryIndex={index} />
                ))}
            </TableBody>
        );
    }
);

export default SortableEditTableBody;

interface BodyProps {
    body: BriefingBodyModel,
}

export const EditTableBody: React.FC<BodyProps> = ({ body }) => (
    <TableBody>
        {body.queries.map((query, index) => (
            <EditTableRow key={index} query={query} body={body} index={index} />
        ))}
    </TableBody>
);

const SortableEditTableRow = SortableElement(
    (props: { query: BriefingQueryModel, body: BriefingBodyModel, queryIndex: number }) => {
        const { query, body, queryIndex } = props;
        return (
            <EditTableRow query={query} body={body} index={queryIndex} />
        );
    }
);

interface RowProps {
    query: BriefingQueryModel,
    body: BriefingBodyModel,
    index: number,
}

const EditTableRow: React.FC<RowProps> = ({ query, body, index }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isSorting } = useSelector(briefingSelector);

    const handleRemoveQuery = () => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries.splice(index, 1);
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    const handleChangeQuestion = (question: string) => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries[index].question = question;
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    const handleChangeType = (type: string) => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries[index].type = type;
        if (type === QUERY_TYPES.RADIO) queries[index].choices = [''];
        else queries[index].choices = [];
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    return (
        <TableRow>
            <TableCell className={classes.tableCellType}>
                <div className={classNames(classes.tableCellTypeInner, isSorting ? classes.actionLock : '')}>
                    <QueryTypeSelect query={query} handleChangeType={handleChangeType} />
                </div>
            </TableCell>
            <TableCell className={classes.tableCellQuestion}>
                <Box className={classNames(classes.tableCellQuestionInner, isSorting ? classes.actionLock : '')}>
                    <div className={classes.question}>
                        <TextField value={query.question} multiline
                            handleChange={handleChangeQuestion} />
                        {query.type === QUERY_TYPES.RADIO &&
                            <RadioQuery
                                choices={query.choices}
                                body={body}
                                index={index} />}
                    </div>
                    <Tooltip title='削除' arrow>
                        <IconButton onClick={handleRemoveQuery}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </TableCell>
        </TableRow>
    );
}

const useStyles = makeStyles({
    tableCellQuestionInner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    tableCellQuestion: {
        width: '80%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    question: {
        width: 'calc(100% - 45px)',
    },
    tableCellType: {
        width: '20%',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#f8fbf9',
    },
    tableCellTypeInner: {
        display: 'flex',
        alignItems: 'center',
    },
    drag: {
        marginRight: 5,
    },
    icon: {
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        height: 30,
        borderRadius: 15,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    actionLock: {
        pointerEvents: 'none',
    },
});
