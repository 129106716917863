import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import className from 'classnames'
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

// component
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import SelectLayout from "./SelectLayout";
import TemplateList from "./TemplateList";
import History from "./History";

//img
import IconClose from '../../../../assets/icon_close_gray.svg'
import IconArrow from '../../../../assets/icon_arrow_gray.svg'

//action
import * as PressReleaseActionCreators from "../../../../actions/PressRelease/ActionCreator";

// style
import style from "./create_dialog.module.scss";

import { RootState } from "../../../../reducers";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressGroupSelector = (state: RootState) => state.group;
const dialogSelector = (state: RootState) => state.dialog;


const useStyles = makeStyles({
    root: {
        width: 926,
        height: 530,
        background: '#F9FBF9',
        display: 'flex',
        justifyContent: 'center',
        padding: 0
    },
    mobile: {
        width: 375,
        height: 300
    },
    close: {
        position: 'absolute',
        right: 5,
        top: 5,
    },
    title: {
        height: 64,
        background: '#fff',
        justifyContent: 'center'
    },
    radio: {
        padding: 5,
        textAlign: 'center'
    },
    pdf: {
        width: '100%',
        height: '100%'
    },
});

const CreateDialog: React.FC = () => {

    const classes = useStyles();
    const [templateIndex, setTemplateIndex] = useState(400);
    const [historyTemplate, setHistoryTemplate] = useState(0);

    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const group = useSelector(pressGroupSelector);

    useEffect(() => {
        if (pressRelease.create.dialog) handleLoadTemplate(templateIndex)
    }, [pressRelease.create.dialog]);

    const handleSetTemplate = () => {
        dispatch(PressReleaseActionCreators.setPressReleaseTemplate.request(pressRelease.create.template));
        dispatch(push(`/press_release/${group.selectedId}/create/`))
        handleClose()
    };

    const handleClose = () => {
        setTemplateIndex(400)
        setHistoryTemplate(0)
        dispatch(PressReleaseActionCreators.setPressReleaseCreateDialog.request(false));
    };

    const handleLoadTemplate = (path) => {
        dispatch(PressReleaseActionCreators.getPressReleaseTemplate.request(`/template/press_release/${path}.json`))
        setTemplateIndex(path)
    }

    return (
        <Dialog
            open={pressRelease.create.dialog}
            onEscapeKeyDown={handleClose}
        >
            <DialogTitle className={classes.title}>
                <div className={style.header}>
                    テンプレートを選択
                </div>
                <IconButton aria-label="close" onClick={handleClose} className={classes.close}>
                    <img src={IconClose} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.root}>
                <TemplateList handleLoadTemplate={handleLoadTemplate} templateIndex={templateIndex} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="large" color="primary" onClick={() => handleSetTemplate()}>
                    このテンプレートで作成
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateDialog
