import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from 'classnames';

// component
import { Box, Typography, Paper, Link } from "@material-ui/core/";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from "@material-ui/core/styles";
import CustomMain from "./Mail/Custom";
import AutoMain from "./Mail/Auto";
import Side from "./Side";
import Alert from '../../../Common/Alert';
import MediaDialog from "../../../Media/Reserve/Dialog";
import { AttachPressKit } from "./AttachPressKit";
import { AttachPressKitDialog } from "../../Common/AttachPressKitDialog";
import CategoryDialog from "../../Common/CategoryDialog";
import DatePicker from "../../../Common/DatePicker";
import Category from "./Category";
import ReleaseType from "./ReleaseType";
import ReleaseTypeDialog from "../../Common/ReleaseTypeDialog";
import Tips from "../../../Common/Tips";

import * as ActionCreators from "../../../../actions/PressReleaseReserve/ActionCreator";
import * as PressReleaseAIActionCreators from "../../../../actions/PressReleaseAI/AIActionCreator";
import * as MediaActionCreators from '../../../../actions/Media/ActionCreator';
import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../../../actions/PressReleaseFirebase/ActionCreator";
import * as TicketActionCreators from "../../../../actions/Ticket/ActionCreator";

//img
import robot from "../../../../assets/icon_robot_green.svg";

// state
import { RootState } from "../../../../reducers";
import { getPdfDownloadUrl } from "../../../../config/press_release_pdf";

const pressGroupSelector = (state: RootState) => state.group;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const pressReleaseAISelector = (state: RootState) => state.pressReleaseAI;
const companySelector = (state: RootState) => state.company;
const meSelector = (state: RootState) => state.me;
const firebaseSelector = (state: RootState) => state.firebase;

const useStyles = makeStyles({
    root: {
        backgroundColor: "#f5f5f5",
        padding: '10px 20px',
        maxWidth: 1100,
        margin: '0 auto',
        width: '100%',
    },
    content: {
        display: "flex",
        marginBottom: 50,
    },
    side: {
        maxWidth: 250,
        marginRight: 10,
        flex: 1,
    },
    main: {
        flex: 2,
    },
    sideTitle: {
        margin: '20px 10px 10px',
        lineHeight: '33px',
        height: 33,
    },
    sideSubTitle: {
        margin: '5px 10px',
    },
    info: {
        background: "#fff",
        padding: 10,
        margin: "10px 0",
        border: '1px solid #e2e9e5',
        boxShadow: 'none',
    },
    item: {
        display: "flex",
        padding: "10px 20px",
        alignItems: "center"
    },
    label: {
        width: 160,
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    headline: {
        width: "100%",
        margin: "20px 10px 10px",
        display: "flex",
        alignItems: "center",
    },
    icon: {
        width: 15,
        marginRight: 10
    },
    allOpenCheckBox: {
        marginLeft: "auto",
    },
    sendTitle: {
        display: "flex",
        alignItems: "center",
        marginTop: 10
    },
    formControlLabel: {
        fontSize: 12
    },
    titleBar: {
        background: "#616763",
        color: "#fff",
        fontSize: 14,
        padding: 10
    },
    link: {
        color: "#2EB964",
        textDecoration: "underline"
    },
    pdfInfo: {
        display: 'flex',
        padding: '0 20px',
    },
    titleSuggest: {
        display: "flex",
        backgroundColor: "#fffbdd",
        border: "solid 2px #d8d9d8",
        borderRadius: 3,
        margin: "15px 10px",
        padding: "8px 8px 3px 8px",
    },
    robotIcon: {
        alignSelf: "center",
        padding: 8,
        margin: "0 15px 0 5px",
        backgroundColor: "#fff",
        height: 40,
    },
    body2: {
        marginBottom: 10,
    },
    pressKit: {
        width: "100%",
        flex: 1
    },
    wordList: {
        display: 'flex',
        alignItems: "center",
        flexWrap: "wrap",
    },
    word: {
        margin: "0 5px 5px 0",
        backgroundColor: "#fff",
    },
    pdf: {
        wordBreak: "break-all",
        marginTop: 5,
        background: "#F8FBF9",
        borderRadius: 4,
        padding: 10,
        display: "flex",
        cursor: "pointer",
        border: "1px solid #ccc",
        marginLeft: 20,
        alignItems: 'center',
    },
    pdfOpen: {
        color: '#494b47',
        marginLeft: 40,
        textDecoration: 'underline',
    },
    pdfDownload: {
        color: '#494b47',
        marginLeft: 30,
        textDecoration: 'underline',
    },
    notDownloadPdfLink: {
        pointerEvents: 'none',
    },
});


const Index: React.FC = () => {

    const classes = useStyles();
    const pressRelease = useSelector(pressReleaseSelector);
    const company = useSelector(companySelector);
    const group = useSelector(pressGroupSelector);
    const pressReleaseAI = useSelector(pressReleaseAISelector);
    const me = useSelector(meSelector);
    const reserve = useSelector(pressReleaseReserveSelector);
    const accessData = useSelector(firebaseSelector);

    const [categoryDialog, setCategoryDialog] = useState(false);
    const [titleSuggest, setTitleSuggest] = useState<string>('');
    const titleSuggestList: any = pressReleaseAI.titleSuggest.result;
    const [pressKitDialog, setPressKitDialog] = React.useState(false);
    const [releaseTypeDialog, setReleaseTypeDialog] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [editor, setEditor] = useState<string>("");
    const [logoutTimerId, setLogoutTimerId] = useState<number>(0);
    let logoutTimer: any;

    const dispatch = useDispatch();

    useEffect(() => {
        if (pressRelease.detail.press_id) {
            dispatch(ActionCreators.getPressReleaseReserve.request({ press_id: pressRelease.detail.press_id }));
            dispatch(PressReleaseAIActionCreators.postPressReleaseTitleSuggest.request());
        }
    }, [pressRelease.detail]);

    useEffect(() => {
        if (titleSuggestList.length > 0) {
            setTitleSuggest(titleSuggestList.join(', '));
        }
    }, [titleSuggestList]);

    const handleChangeDatetime = (m) => {
        if (m) dispatch(ActionCreators.setPressReleaseReserveDatetime.request(m));
    };

    // プレスリリース編集権限
    let hasEdit = pressRelease.role.edit;
    const { isReserveEdit, press_id } = pressRelease.detail;

    const customSendList = reserve.data.custom.filter(item => item.is_send === true);

    const isSend = reserve.data.auto.is_send || customSendList.length > 0;

    // 配信保存
    const handleReserveSave = async () => {
        await dispatch(ActionCreators.postPressReleaseReserve.request({
            reviewer: [],
            comment: '',
            is_save: true,
        }));
    };

    const handleAttachPressKitDialogOpen = (isOpen: true) => {
        setPressKitDialog(isOpen)
    };

    const isError = reserve.data.customError || reserve.data.autoError

    const [alert, setAlert] = useState(false)

    useEffect(() => {
        if ( !company.is_review_skip && (reserve.data.reviewer || []).filter(member => member.user.uuid === me.uuid && member.status === null).length > 0) setAlert(true);
    }, [reserve.data.reviewer])

    const [mediaMenuDialog, setMediaMenuDialog] = useState(false);

    useEffect(() => {
        if (group.selectedId) {
            dispatch(MediaActionCreators.getFavoriteMediaList.request({ offset: 1 }))
            dispatch(PressKitActionCreators.syncFirestorePressKit.request({ groupId: group.selectedId }))
        }
    }, [group.selectedId])

    const pdfDownloadUrl = getPdfDownloadUrl(pressRelease.detail.press_id);

    const data = accessData.data[pressRelease.detail.press_id];

    // 配信設定内のボタンを無効化するために必要
    useEffect(() => {
        if (data && data.reserve && data.reserve.editor) {
            setEditor(data.reserve.editor);
            if (data.reserve.editor === me.uuid) {
                setIsLocked(false);
            } else {
                setIsLocked(true);
            }
        } else {
            setEditor("");
            setIsLocked(false);

            // ロック解除後に配信設定を最新状態にするために必要
            if (data && data.reserve && !data.reserve.editor && editor) {
                dispatch(ActionCreators.getPressReleaseReserve.request({press_id: pressRelease.detail.press_id}));
            }
        }
    }, [data]);

    // 編集後、一定時間経過で編集者情報を削除するために必要
    useEffect(() => {
        if (data && data.reserve && data.reserve.editor && data.reserve.editor === me.uuid) {
            clearTimeout(logoutTimerId);

            logoutTimer = setTimeout(() => {
                dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseEditorData.request(pressRelease.detail.press_id));

                const activeElement: any = document.activeElement;
                if (activeElement) {
                    activeElement.blur();
                }
            }, 600000);
            setLogoutTimerId(logoutTimer);
        }
    }, [reserve.data, reserve.active]);

    const handlePostEditorData = () => {
        if (hasEdit && data.reserve && !data.reserve.editor){
            dispatch(PressReleaseFirebaseActionCreators.postPressReleaseEditorData.request());
        }
    };

    return (
        <>
            {alert && (
                <Alert variant="filled" severity="warning" title={'確認依頼がきています。'} onClose={() => setAlert(false)}>
                    修正がある場合は、「修正依頼」ボタンを押してください。<br />
                    修正がない場合は、「承認する」ボタンを押してください。<br />
                </Alert>
            )}
            <div className={classes.root}>
                <Paper className={classes.info}>
                    <div className={classes.pdfInfo}>
                        <Typography variant='h6' className={classes.title}>
                            プレスリリースPDF
                        </Typography>
                        <a href={`${pdfDownloadUrl}`} className={classNames(classes.pdfOpen, !press_id && classes.notDownloadPdfLink)} target='_blank'>
                            <Typography variant={"body1"}>
                                開く
                            </Typography>
                        </a>
                        <a href={`${pdfDownloadUrl}?is_download=1`} className={classNames(classes.pdfDownload, !press_id && classes.notDownloadPdfLink)}>
                            <Typography variant={"body1"}>
                                ダウンロード
                            </Typography>
                        </a>
                    </div>
                </Paper>
                <Typography variant="h5" className={classes.headline}>
                    1. 配信設定
                </Typography>

                <Paper className={classes.info} onFocus={handlePostEditorData}>
                    <Box className={classes.item}>
                        <div className={classes.label}>
                            <Typography variant="h6" className={classes.title}>
                                配信予約時間
                                <Tips>
                                    即時配信はできません。配信前日までに予約をおこなってください。<br/>
                                    配信後は<Link href="/dashboard" color={"secondary"} target="_blank" rel="noopener">ダッシュボード</Link>から開封率の確認ができます。
                                </Tips>
                            </Typography>
                        </div>
                        <DatePicker datetime={reserve.data.released_at}
                            edit={hasEdit && isReserveEdit && !isLocked}
                            isReserve={true}
                            handleChangeDatetime={handleChangeDatetime} />
                    </Box>
                    <Divider />
                    <Box className={classes.item}>
                        <div className={classes.label}>
                            <Typography variant="h6" className={classes.title}>
                                リリースの種類
                            </Typography>
                        </div>
                        <ReleaseType handleOpenDialog={() => setReleaseTypeDialog(true)} hasEdit={hasEdit} isReserveEdit={isReserveEdit} isLocked={isLocked}/>
                    </Box>
                    <Divider />
                    <Box className={classes.item}>
                        <div className={classes.label}>
                            <Typography variant="h6" className={classes.title}>
                                配信カテゴリ
                            </Typography>
                        </div>
                        <Category handleOpenDialog={() => setCategoryDialog(true)} isLocked={isLocked}/>
                    </Box>
                    <Divider />
                    <Box className={classes.item}>
                        <div className={classes.label}>
                            <Typography variant="h6" className={classes.title}>
                                プレスキット
                                <Tips>
                                    プレスリリース配信時に、関連する素材やファイルをメディアに共有することができ、<br/>
                                    メディアとのやりとりがスムーズにします。<br/><br/>
                                    作成は
                                    <Link href="/press_kit" color={"secondary"} target="_blank" rel="noopener">
                                        こちら
                                    </Link>
                                </Tips>
                            </Typography>
                        </div>
                        <Box className={classes.pressKit}>
                            <AttachPressKit
                                company={company}
                                hasEdit={hasEdit}
                                isReserveEdit={isReserveEdit}
                                handleDialogOpen={handleAttachPressKitDialogOpen}
                                isLocked={isLocked}
                            />
                            <Typography variant="caption" className={classes.title}>
                                ※本文にURLを挿入します。
                                    </Typography>
                            {!company.prefix &&
                                <Typography variant="body2">URLが<NavLink to={`/setting/service/edit/`} className={classes.link}>設定</NavLink>されていません。</Typography>
                            }
                        </Box>
                    </Box>
                </Paper>
                <Typography variant="h5" className={classes.headline}>
                    2. メディア選択・メール作成
                </Typography>

                {titleSuggest &&
                    <Box className={classes.titleSuggest}>
                        <img src={robot} className={classes.robotIcon} />
                        <Box>
                            <Typography variant="body2" className={classes.body2}>件名、本文に入れるおすすめワード</Typography>
                            <Typography variant="body1">{titleSuggest}</Typography>
                        </Box>
                    </Box>
                }
                <div className={classes.content} onFocus={handlePostEditorData}>
                    <Box className={classes.side}>
                        <Side handleReserveSave={handleReserveSave} isLocked={isLocked}/>
                    </Box>
                    <Box className={classes.main}>
                        {reserve.active === 0 && <AutoMain handleOpenMediaDialog={() => setMediaMenuDialog(true)} isLocked={isLocked} />}
                        {reserve.data.custom.map((item, index) => {
                            if (item.id == reserve.active) return <CustomMain item={item} key={index} isLocked={isLocked} />
                        })}
                    </Box>
                </div>
                <ReleaseTypeDialog
                    isOpen={releaseTypeDialog}
                    closeHandle={() => setReleaseTypeDialog(false)}
                />
                <CategoryDialog
                    isOpen={categoryDialog}
                    closeHandle={() => setCategoryDialog(false)}
                />
                <AttachPressKitDialog
                    isOpen={pressKitDialog}
                    closeHandle={() => setPressKitDialog(false)}
                />
                <MediaDialog
                    isOpen={mediaMenuDialog}
                    closeHandle={() => setMediaMenuDialog(false)}
                />
            </div >
        </>
    );
};

export default Index;

const nodataStyles = makeStyles({
    root: {
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
});

const Nodata: React.FC = () => {

    const classes = nodataStyles();

    return (
        <Typography variant={"h6"} className={classes.root}>
            配信メディアが選択されていません。<br />
            左のチェックボックスから選択してください。
        </Typography>
    );
};
