import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import AppLayout from "../../components/Layout/App";
import List from "../../components/Briefing/List";

// actions
import * as BriefingActionCreators from '../../actions/Briefing/ActionCreator';

// state
import { RootState } from '../../reducers';
import { BRIEFING_PAGE_TYPES } from '../../config/briefing';
const briefingListSelector = (state: RootState) => state.briefingList;
const briefingGroupSelector = (state: RootState) => state.group;

const ListPage: React.FC = () => {
  const dispatch = useDispatch();
  const list = useSelector(briefingListSelector);
  const group = useSelector(briefingGroupSelector);

  useEffect(() => {
    if (group.selectedId) {
      dispatch(BriefingActionCreators.getBriefingList.request());
    }
  }, [group.selectedId]);

  return (
    <AppLayout>
      <List list={list.working} pageType={BRIEFING_PAGE_TYPES.LIST_EDIT} />
    </AppLayout>
  );
}

export default ListPage;
