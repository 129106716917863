import React, { FC } from "react";
import style from "./thumbnail.module.scss";
import { isImage, getFileType } from "../../../util/File";
import { getIcon } from "../../../config/fileIcons";
import classnames from "classnames";

export const Thumbnail: FC<any> = ({ file }) => {
  const fileType = getFileType(file);
  const src = isImage(fileType) ? file.url : getIcon(fileType);
  return (
    <div className={classnames(style.imgContainer, isImage(fileType)? style.background: "")}>
      <img className={style.thumbnail} src={src} />
    </div>
  )
};
