import React from 'react';

//component
import Title from '../Title';
import SubTitle from "../SubTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/send/review/image01.png';
import Image02 from '../../../assets/help/send/review/image02.png';
import Image03 from '../../../assets/help/send/review/image03.png';
import Image04 from '../../../assets/help/send/review/image04.png';
import Image05 from '../../../assets/help/send/review/image05.png';


const Review = () => {
	return (
		<div className={style.box}>
			<Title title={"3. 内容確認（確認フロー機能ONの場合）"}/>
			<SubTitle step={1} title={"確認依頼がきているものを表示"}/>
			<Typography gutterBottom variant={"body1"}>
				右上の絞り込み機能から「確認者」で自分の名前を選択し、「条件を適用する」ボタンを押下することで、確認依頼がきているものが表示されます。<br/>
				表示されたカードを押下して、配信設定の内容を確認します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<SubTitle step={2} title={"承認する"}/>
			<Typography gutterBottom variant={"body1"}>
				指摘事項がない場合、「承認する」ボタンを押下します。<br/>
				全ての確認者が承認すると、依頼者は配信設定に進むことができます。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<SubTitle step={3} title={"指摘事項あり"}/>
			<Typography gutterBottom variant={"body1"}>
				依頼者に差し戻すには、「修正依頼」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				ダイアログが表示されます。<br/>
				修正内容を入力後「修正依頼を出す」ボタンを押下します。<br/>
				依頼者にメールで通知されます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
			<SubTitle step={4} title={"確認の完了"}/>
			<Typography gutterBottom variant={"body1"}>
				確認が完了すると、右パネルの表示が切り替わります。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image05}/>
			</div>
		</div>
	);
};

export default Review;
