import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Button, Tooltip} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";

import {RootState} from "../../../../reducers";
import {PressKitFirestoreFileModel, SortHistoryContentModel} from "../../../../model/PressKitModel";

import style from "./sortButton.module.scss";
import IconDone from "../../../../assets/icon_done.svg";
import IconSort from "../../../../assets/icon_sort.svg";
import IconSortDisabled from "../../../../assets/icon_sort_disabled.svg";

const sortingSelector = (state: RootState): boolean => state.pressKit.edit.sorting;
const pressKitSortingSelector = (state: RootState): boolean => state.pressKit.edit.pressKitSorting;
const firestoreFilesSelector = (state: RootState): Array<PressKitFirestoreFileModel> => state.pressKit.firestore.files;
const viewFileSelector = (state: RootState): Array<PressKitFirestoreFileModel> => state.pressKit.edit.viewFile;
const sortHistorySelector = (state: RootState): Array<SortHistoryContentModel> => state.pressKit.edit.sortHistory;

const useStyles = makeStyles({
    root: {
        height: 29,
        minWidth: 29
    }
})

export const SortButton: React.FC<any> = () => {
    const fileSorting = useSelector(sortingSelector);
    const pressKitSorting = useSelector(pressKitSortingSelector)
    const firestoreFiles = useSelector(firestoreFilesSelector);
    const viewFile = useSelector(viewFileSelector);
    const sortHistory = useSelector(sortHistorySelector);
    const dispatch = useDispatch();

    const classes = useStyles();
    const isNotExistsFiles = firestoreFiles.length === 0 ? true : false;

    const cleanUp = () => {
        dispatch(PressKitActionCreators.setPressKitSorting(false))
        dispatch(PressKitActionCreators.setFileSorting(false))
        dispatch(PressKitActionCreators.setViewFile([]))
        dispatch(PressKitActionCreators.setViewPressKit([]))
        dispatch(PressKitActionCreators.clearSortHistory())
    }

    const handleSortStart = () => {
        dispatch(PressKitActionCreators.setViewFile(firestoreFiles))
        dispatch(PressKitActionCreators.setFileSorting(true))
    }

    const handleSortEnd = () => {
        const isNotSorted = sortHistory.length == 0 ? true : false;
        if (isNotSorted) {
            return cleanUp()
        }
        const data = {
            sortedList: viewFile,
            sortHistory
        }
        dispatch(PressKitActionCreators.firestoreFileSort.request(data))
    }

    const handler = fileSorting ? handleSortEnd : handleSortStart;
    const variant = fileSorting ? 'contained' : 'outlined';
    const tooltipText = fileSorting ? '順番を適用する' : 'ファイルを並べ替える';
    const sortIcon = pressKitSorting ? IconSortDisabled : IconSort;
    const icon = fileSorting ? IconDone : sortIcon;

    useEffect(() => {
        cleanUp()
    }, [])

    return (
        <div className={style.buttonContainer}>
            <Tooltip title={tooltipText} placement="top">
                <span>
                    <Button
                        className={classes.root}
                        onClick={handler}
                        variant={variant}
                        size='small'
                        color='primary'
                        disabled={pressKitSorting || isNotExistsFiles}
                    >
                        <img src={icon}/>
                    </Button>
                </span>
            </Tooltip>
        </div>
    )
};
