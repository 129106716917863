enum PressReleasePublishActionType {
  GET_PRESS_RELEASE_PUBLISH_REQUEST = 'GET_PRESS_RELEASE_PUBLISH_REQUEST',
  GET_PRESS_RELEASE_PUBLISH_SUCCEEDED = 'GET_PRESS_RELEASE_PUBLISH_SUCCEEDED',
  GET_PRESS_RELEASE_PUBLISH_FAILED = 'GET_PRESS_RELEASE_PUBLISH_FAILED',

  POST_PRESS_RELEASE_PUBLISH_REQUEST = 'POST_PRESS_RELEASE_PUBLISH_REQUEST',
  POST_PRESS_RELEASE_PUBLISH_SUCCEEDED = 'POST_PRESS_RELEASE_PUBLISH_SUCCEEDED',
  POST_PRESS_RELEASE_PUBLISH_FAILED = 'POST_PRESS_RELEASE_PUBLISH_FAILED',

  SET_PRESS_RELEASE_PUBLISH = 'SET_PRESS_RELEASE_PUBLISH',

  PATCH_OGP_IMAGE_REQUEST = 'PATCH_OGP_IMAGE_REQUEST',
  PATCH_OGP_IMAGE_SUCCEEDED = 'PATCH_OGP_IMAGE_SUCCEEDED',
  PATCH_OGP_IMAGE_FAILED = 'PATCH_OGP_IMAGE_FAILED',

  POST_MAKE_PRESS_RELEASE_PUBLIC_REQUEST = 'POST_MAKE_PRESS_RELEASE_PUBLIC_REQUEST',
  POST_MAKE_PRESS_RELEASE_PUBLIC_SUCCEEDED = 'POST_MAKE_PRESS_RELEASE_PUBLIC_SUCCEEDED',
  POST_MAKE_PRESS_RELEASE_PUBLIC_FAILED = 'POST_MAKE_PRESS_RELEASE_PUBLIC_FAILED'
}

export default PressReleasePublishActionType;
