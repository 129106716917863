import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { push } from 'connected-react-router';
import { RouteComponentProps } from 'react-router-dom';

// components
import { makeStyles } from "@material-ui/core/styles";
import BriefingAnswer from "../../components/Briefing/Answer";
import Layout from './EditLayout';
import AnswerHeader from '../../components/Briefing/Common/AnswerHeader';
import BriefingConfirm from "../../components/Briefing/Confirm";
import { Typography } from '@material-ui/core';

// icons
import IconBriefing from '../../assets/guide/briefing.svg';

// actions
import * as BriefingActionCreators from '../../actions/Briefing/ActionCreator';
import * as MemberActionCreators from '../../actions/Member/ActionCreator';

import { RootState } from '../../reducers';
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;
const routerSelector = (state: RootState) => state.router;
const briefingSelector = (state: RootState) => state.briefing;

type PageProps = {} & RouteComponentProps<{ formId: string }>;

const BriefingAnswerPage: React.FC<PageProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const group = useSelector(groupSelector);
    const router = useSelector(routerSelector);
    const me = useSelector(meSelector);
    const { email, detail } = useSelector(briefingSelector);
    const location: any = router.location;
    const answerId = location.query.i || '';
    const answerPath = store.get('answerPath');
    const { formId } = props.match.params;
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (group.selectedId) {
            if (answerPath) {
                dispatch(push(`/briefing/${group.selectedId}/answer/${formId}?i=${answerPath}`));
                store.remove('answerPath');
            }
        }
    }, [group.selectedId, answerPath]);

    useEffect(() => {
        if (group.selectedId) {
            dispatch(MemberActionCreators.getMemberList.request({ offset: 0, limit: 100 }));
            if (me.email) {
                dispatch(BriefingActionCreators.postBriefingAnswerConfirm.request({
                    id: formId,
                    email: me.email,
                    postError: (err: string) => setErrorMessage(err)
                }));
            }
        }
    }, [group.selectedId, me.email]);

    useEffect(() => {
        if (detail.body.groupId) {
            dispatch(BriefingActionCreators.getBriefingQty.request({ groupId: detail.body.groupId }));
        }
    }, [detail.body.groupId]);

    useEffect(() => {
        if (!store.get("token")) {
            store.set('path', `${router.location.pathname}${router.location.search}`);
        }
    }, []);

    useEffect(() => {
        if (email) {
            dispatch(BriefingActionCreators.getBriefingAnswerInfo.request({
                docId: formId, email, answerId
            }));
        }
    }, [email, answerId]);

    return (
        <Layout>
            <div className={classes.header}>
                <AnswerHeader />
            </div>
            {errorMessage ?
                <div className={classes.errorMessage}>
                    <Typography variant="h6" className={classes.text}>
                        {errorMessage}
                    </Typography>
                    <img src={IconBriefing} className={classes.img} />
                </div>
                :
                <BriefingAnswer />}
        </Layout>
    );
}

export default BriefingAnswerPage;

const useStyles = makeStyles({
    root: {
        overflow: "scroll",
        height: '100%',
    },
    header: {
        width: '100%',
        margin: '0 auto',
    },
    errorMessage: {
        display: 'flex',
        justifyContent: 'center',
        padding: '110px 50px',
        flexDirection: 'column',
        maxWidth: 530,
        margin: '0 auto',
        alignItems: 'center',
    },
    text: {
        padding: '50px 0',
    },
    img: {
        width: 230,
    },
})

export const BriefingGuestAnswerPage: React.FC<PageProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { detail, email } = useSelector(briefingSelector);
    const router = useSelector(routerSelector);
    const { formId } = props.match.params;
    const location: any = router.location;
    const answerId = location.query.i || '';

    useEffect(() => {
        const token = store.get("token");
        const groupId = store.get("groupId");
        if (token && groupId) {
            dispatch(push(`/briefing/${groupId}/answer/${formId}/`));
        }
    }, []);

    const handleSubmit = (values) => {
        dispatch(BriefingActionCreators.postBriefingAnswerConfirm.request({
            id: formId,
            email: values.email,
        }));
    }

    useEffect(() => {
        if (email) {
            dispatch(BriefingActionCreators.getBriefingAnswerInfo.request({
                docId: formId, email, answerId
            }));
        }
    }, [email, answerId]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <AnswerHeader />
            </div>
            {detail.fetched || email ?
                <BriefingAnswer />
                :
                <BriefingConfirm onSubmit={handleSubmit} />}
        </div>
    )
}
