import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import dayjs from "dayjs";

import { Chip, Grid, Card, Typography, Divider, CardHeader, CardContent } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { RootState } from "../../reducers";

import AnalyticsLayout from "../../pages/Analytics/Layout";

import * as AnalyticsActionCreators from "../../actions/Analytics/ActionCreator";

import MediaTable from "../../components/Analytics/Table/MediaTable";
import { CircularProgressChart } from "../../components/Analytics/Chart/CircularProgress";
import DoughnutChart from "../../components/Analytics/Chart/DoughnutChart";
import { generateMediaTypeColors, generateMediaTypeLabel } from "../../components/Analytics/Chart/DoughnutChart/color";
import { CategoryOption } from "../../components/Analytics/Chart/MixChart/options";

import { DETAIL } from "../../config/analytics_config";

const useStyles = makeStyles({
    info: {
        display: "flex",
        justifyContent: "space-between"
    },
    date: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        fontSize: 15
    },
    subInfo: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        justifyContent: "flex-end"
    },
    subInfoText: {
        marginLeft: 20,
        fontSize: 16
    },
    chip: {
        marginLeft: "10px",
    },
    card: {
        height: "100%"
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    content: {
        width: 300,
        textAlign: "center"
    },
    chart: {
        width: 200,
        textAlign: "center"
    }
})


type PageProps = {} & RouteComponentProps<{ pressReleaseId: string }>;
const AnalyticsSelector = (state: RootState) => state.analytics;
const groupSelector = (state: RootState) => state.group;


const DetailPage: React.FC<PageProps> = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const analytics = useSelector(AnalyticsSelector)
    const group = useSelector(groupSelector);
    const pressReleaseId = props.match.params.pressReleaseId

    useEffect(() => {
        if (group.selectedId) {
            dispatch(AnalyticsActionCreators.getAnalyticsDetail.request({
                "press_id": pressReleaseId,
            }))
        }
    }, [pressReleaseId, group.selectedId])

    return (
        <AnalyticsLayout page={DETAIL}>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <div className={classes.info}>
                        <Typography
                            variant="h5"
                            gutterBottom>{analytics.detail.name}</Typography>
                        <Typography className={classes.date}>
                            配信日時：{dayjs(analytics.detail.released_at).format('YYYY/MM/DD HH:mm')}
                        </Typography>
                    </div>

                    <div className={classes.subInfo}>
                        <Typography variant="body1" className={classes.subInfoText}>
                            配信カテゴリ：
                        </Typography>
                        {analytics.detail.category.map((label, index) => (
                            <Chip key={index} className={classes.chip} size="small" label={label} />
                        ))
                        }
                    </div>

                    <Divider />
                </Grid>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <div className={classes.content}>
                                <Typography variant="h5" component="h6" gutterBottom>
                                    HARVESTメディアリスト
                                </Typography>
                                <Typography variant="h3" component="h3">
                                    {analytics.detail.media.mediaCount}
                                </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className={classes.content}>
                                <Typography variant="h5" component="h6" gutterBottom>
                                    企業メディアリスト
                            </Typography>
                                <Typography variant="h3" component="h3">
                                    {analytics.detail.media.customMediaCount}
                                </Typography>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className={classes.content}>
                                <Typography variant="h5" component="h6" gutterBottom>
                                    合計配信先数
                            </Typography>
                                <Typography variant="h3" component="h3">
                                    {analytics.detail.media.mediaCount + analytics.detail.media.customMediaCount}
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                >
                    <Card className={classes.card}>
                        <CardHeader title={<Typography variant="h1" component="h1">{"開封率"}</Typography>} />
                        <CardContent className={classes.cardContent}>
                            <div className={classes.chart}>
                                <CircularProgressChart value={analytics.detail.openMail.media} title={"HARVESTメディアリスト"} />
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className={classes.chart}>
                                <CircularProgressChart value={analytics.detail.openMail.custom_media} title={"企業メディアリスト"} />
                            </div>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                >
                    <DoughnutChart title={"配信先メディア種別"} data={analytics.detail.media.mediaType.count}
                        labels={generateMediaTypeLabel(analytics.detail.media.mediaType.label)}
                        colors={generateMediaTypeColors(analytics.detail.media.mediaType.label)}
                        options={CategoryOption}

                    />
                </Grid>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xl={12}
                    xs={12}
                >
                    <MediaTable data={analytics.detail.mediaList} />
                </Grid>
            </Grid>
        </AnalyticsLayout>

    )
}

export default DetailPage;
