import createReducer from "./createReducer";
import { OptionListModel } from "../model/OptionModel";
import * as ActionCreators from "../actions/Option/ActionCreator";
import OptionActionType from "../actions/Option/ActionType";


const initialOptionState  = {
    id: 0,
    name: "",
    amount: 0
}

const initialStateOption: OptionListModel = {
    radar: initialOptionState ,
    member: initialOptionState,
    ticket: initialOptionState,
}

export const option = createReducer<OptionListModel>(initialStateOption, {
    [OptionActionType.GET_OPTION_SUCCEEDED](state: OptionListModel, action: ReturnType<typeof ActionCreators.getOptionList.success>) {
        const result = action.payload
        return {
            ...result
        }
    }
})
