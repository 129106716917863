import React from "react";
import { RouteComponentProps } from "react-router-dom";

// components
import { AppLayoutFullWidth } from "../../components/Layout/App";
import ProceedingsTopics from "../../components/Topic/proceedings"

interface Props extends RouteComponentProps<void> { }

function TopicPage(props: Props) {

	return (
		<AppLayoutFullWidth>
			<ProceedingsTopics/>
		</AppLayoutFullWidth>
	);
}

export default TopicPage;
