import React from 'react';
import Title from '../Title'
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/member/image01.png';
import Image02 from '../../../assets/help/member/image02.png';
import {Simulate} from "react-dom/test-utils";
import PageTitle from "../PageTitle";
import Point from "../Point";


const Member = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"メンバー管理"}/>
                <Point>
                    社内メンバーと共同作業が可能です。
                </Point>
                <Title title={"1.メンバー管理"}/>
                <Typography gutterBottom color={"error"} variant={"body1"}>
                    HARVEST内で使用します。外部には表示されません。
                </Typography>

                <Typography gutterBottom variant={"body1"}>
                    メンバーを追加すると、共同編集や、確認依頼を出すことができます。<br/>
                    「メンバー招待」ボタンから追加することができます。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
                <Typography gutterBottom variant={"body1"}>
                    メールアドレスを入力後、「招待メールを送る」ボタンを押下してください。<br/>
                    招待メールが送信されます。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>
            </div>
        </article>
    );
};

export default Member;
