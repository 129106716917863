enum AssetActionType {
	GET_ASSET_REQUEST = "GET_ASSET_REQUEST",
	GET_ASSET_SUCCEEDED = "GET_ASSET_SUCCEEDED",
	GET_ASSET_FAILED = "GET_ASSET_FAILED",
	POST_ASSET_REQUEST = "POST_ASSET_REQUEST",
	POST_ASSET_SUCCEEDED = "POST_ASSET_SUCCEEDED",
	POST_ASSET_FAILED = "POST_ASSET_FAILED",
    DELETE_ASSET_REQUEST = "DELETE_ASSET_REQUEST",
    DELETE_ASSET_SUCCEEDED = "DELETE_ASSET_SUCCEEDED",
    DELETE_ASSET_FAILED = "DELETE_ASSET_FAILED",
}

export default AssetActionType;

