export const pressKitType = [
    {
        id: 3,
        title: '会社関連',
        sub: [
            { id: 1, title: '企業情報 '},
            { id: 2, title: 'ロゴ' },
        ]
    },
    // {
    //     id: 1,
    //     title: '製品・サービス',
    //     sub: [
    //         { id: 10, title: '製品・サービス紹介' },
    //         { id: 11, title: '製品・サービスロゴ' },
    //     ]
    // },
    {
        id: 2,
        title: '人物',
        sub: [
            { id: 20, title: '社長・役員プロフィール'},
            { id: 21, title: '社員紹介' },
        ]
    }
]
