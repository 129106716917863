import React from 'react';
import Title from '../Title'
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/briefing/confirm/image01.png';
import Image02 from '../../../assets/help/briefing/confirm/image02.png';
import Image03 from '../../../assets/help/briefing/confirm/image03.png';


const Confirm = () => {
    return (
        <div className={style.box}>
            <Title title={"2. 回答状況の確認"}/>
            <Typography gutterBottom variant={"body1"}>
                ブリーフィング一覧の「回答状況を確認する」ボタンもしくは、編集画面右上の「回答を確認する」ボタンから、回答状況の確認ができます。
            </Typography>
            <div className={style.img}>
                <img src={Image01}/>
            </div>
            <div className={style.img}>
                <img src={Image02}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                画面右上の「プレスリリースを作成」ボタンを押下することで、回答内容を元にプレスリリースを作成することができます。
            </Typography>
            <div className={style.img}>
                <img src={Image03}/>
            </div>
        </div>
    );
};

export default Confirm;
