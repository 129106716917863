import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";

//component
import { makeStyles } from "@material-ui/core/styles";
import FroalaEditorComponent from "../../Common/Editor/FroalaEditor";
import Alert from "../../Common/Alert";

//state
import { RootState } from "../../../reducers";


const pressReleaseSelector = (state: RootState) => state.pressRelease;
const groupSelector = (state: RootState) => state.group;
const meSelector = (state:RootState) => state.me;

const EditorComponent: React.FC = () => {
    const classes = useStyles();
    const pressRelease = useSelector(pressReleaseSelector);
    const group = useSelector(groupSelector);
    const me = useSelector(meSelector);

    const { history, review } = pressRelease;
    const { html, css } = history.body;
    const [alert, setAlert] = useState<boolean>(false);


    useEffect(() => {
        if (review.members.filter(member => member.user.uuid === me.uuid && member.status != true).length > 0) setAlert(true)
    }, [review.members]);


    if (group.selectedId && html) {
        return(
            <div className={classes.root}>
                {alert &&
                    <Alert variant="filled" severity="warning" title={'確認依頼がきています。'} onClose={()=> setAlert(false)}>
                      修正がある場合は、「修正依頼」ボタンを押してください。<br/>
                      修正がない場合は、「承認する」ボタンを押してください。<br/>
                    </Alert>
                }
                <FroalaEditorComponent
                    css={css}
                    html={html}
                    groupSelectedId={group.selectedId}
                />
            </div>
        );
    } else {
        return(
            <div>

            </div>
        );
    }
}

export default EditorComponent;


const useStyles = makeStyles({
    root: {
        width: "100%"
    }
});
