import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

// components
import PressKitListPage from "./List";

type PageProps = {} & RouteComponentProps<{}>;

const PressKitPage: React.FC<PageProps> = props => {

    return (
        <>
            <Switch>
                <Route exact={true} path="/press_kit/" component={PressKitListPage} />
            </Switch>
         </>
    );
}

export default PressKitPage;
