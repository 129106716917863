import React, {useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";
import {Box, Link} from "@material-ui/core";
import {options} from '../../components/Help/help'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const Links: React.FC = () => {

    const classes = useStyles();

    return (
        <article className={classes.root}>
            <section>
                <Typography variant="h2" gutterBottom className={classes.title}>
                    サービスについて
                </Typography>
                <ul className={classes.links}>
                    <li>
                        <Link color="inherit" href="https://harvest.site/about" target="blank" className={classes.link}>
                            HARVESTとは？
                        </Link>
                    </li>
                    <li>
                        <Link href="https://harvest.site/pricing" target="blank" className={classes.link}>
                            料金プラン
                        </Link>
                    </li>
                    <li>
                        <Link href="https://harvest.site/feature" target="blank" className={classes.link}>
                            機能一覧
                        </Link>
                    </li>
                    <li>
                        <NavLink to="/terms" target="blank" className={classes.link}>
                            利用規約
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/policy" target="blank" className={classes.link}>
                            プライバシーポリシー
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/stcl" target="blank" className={classes.link}>
                            特定商取引法の表示引法の表示
                        </NavLink>
                    </li>
                    <li>
                        <Link href="https://harvest.site/contact" target="blank" className={classes.link}>
                            お問い合わせ
                        </Link>
                    </li>
                </ul>
            </section>

            <section>
                <Typography variant="h2" gutterBottom className={classes.title}>
                    ナレッジ
                </Typography>
                <ul className={classes.appLinks}>
                    <li>
                        <a href='https://blog.harvest.site/' target="_blank">
                            <Button variant="contained" color="primary">
                                ブログ
                            </Button>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/HARVEST1126" target="blank">
                            <Button variant="contained" color="primary">
                                公式Twitter
                            </Button>
                        </a>
                    </li>
                </ul>
            </section>

            <section>
                <Typography variant="h2" gutterBottom className={classes.title}>
                    運営会社
                </Typography>
                <ul>
                    <li>
                        <Link href="https://m-lab.inc/" target="blank" className={classes.link}>
                            株式会社 m-Lab
                        </Link>
                    </li>

                </ul>
            </section>

        </article>
    );
};

export default Links;

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            margin: "120px 100px 0",
        },
        title: {
            marginTop: 30,
            background: "#f3f7f4",
            padding: "10px 20px",
            borderRadius: 4
        },
        links: {
            "& > li": {
                marginTop: 10,
            }
        },
        appLinks: {
            display: "flex",
            flexWrap: "wrap",
            "& > li": {
                marginRight: 15,
                marginBottom: 10
            }
        },
        link: {
            textDecoration: "underline",
            fontSize: 16,
            color: "#666"
        },
        button: {
            fontWeight: "bold"
        }
    })
);
