import {takeLatest, put, call} from 'redux-saga/effects';
import {fetchGet, fetchPost} from './fetch';

//actions
import TopicActionType from '../actions/Topic/ActionType';
import * as ActionCreators from '../actions/Topic/ActionCreator';

function* getProceedings(action: ReturnType<typeof ActionCreators.getProceedingsTopics.request>) {
    try {
        const [data, error] = yield call(fetchGet, `jiji/proceedings/`)
        yield put(ActionCreators.getProceedingsTopics.success(data))
    } catch (e) {
        yield put(ActionCreators.getProceedingsTopics.failure(e))
    }
}

function* postTopicWord(action: ReturnType<typeof ActionCreators.postTopicWord.request>) {
    try {
        const [data, error] = yield call(fetchPost, 'jiji/word_search', action.payload);
        yield put(ActionCreators.postTopicWord.success(data))
    } catch (e) {
        yield put(ActionCreators.postTopicWord.failure(e));
    }
}

const topicSaga = [
    takeLatest(TopicActionType.GET_PROCEEDINGS_TOPICS_REQUEST, getProceedings),
    takeLatest(TopicActionType.POST_TOPIC_WORD_REQUEST, postTopicWord)
];

export default topicSaga;
