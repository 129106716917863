import React from "react";
import { useSelector } from "react-redux";
import {Field, reduxForm, InjectedFormProps} from "redux-form";

// component
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button';
import TextField from "../Field/TextField";
import RadioButtonField from "../Field/RadioButtonField";
import SettingTitle from "../Common/SettingTitle";

// style
import style from "./form.module.scss";

// state
import {RootState} from "../../reducers";
import { Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const companySelector = (state: RootState) => state.company;

const ServiceEditForm: React.FC<InjectedFormProps> = props => {

    const classes = useStyles();
    const company = useSelector(companySelector);
    const {handleSubmit, submitting, pristine, error} = props
    const {plan} = company;
    const isReviewSkipLimit = plan.is_review_skip_limit;
    const publicPageLimit = plan.public_page_limit;

    return (
        <div className={style.root}>
            <SettingTitle text="サービス情報"/>
            <form onSubmit={handleSubmit}>
                {error && <p className={style.error}>{error}</p>}
                <ul className={style.item}>
                    <li>
                        <label>URL</label>
                        <div>
                            <div className={style.column}>
                                <div>
                                    <Typography variant={"h6"}>プレスリリース：https://pr.harvest.site/press_release/<span className={style.prefix}>{company.prefix}</span></Typography>
                                    <Typography variant={"h6"}>プレスキット：https://pr.harvest.site/press_kit/<span className={style.prefix}> {company.prefix}</span></Typography>
                                </div>
                                {!company.prefix &&
                                        <div  className={classes.prefix}>
                                            <Field name="prefix" type="text" component={TextField}
                                           disabled={company.prefix ? true : false} label={"mlab"}/>
                                          <Typography variant={"caption"} className={classes.caption}>英数とアンダースコア・変更不可</Typography>
                                        </div>
                                }
                            </div>
                        </div>
                    </li>
                    <li>
                        <label>プレスリリース<br/>のWEBページ公開</label>
                        <div>
                            <div className={style.form}>
                                <Field name="is_public_page" component={RadioButtonField}>
                                    <FormControlLabel value={"true"} control={<Radio color={"primary"}/>} label="公開する" disabled={publicPageLimit}/>
                                    <FormControlLabel value={"false"} control={<Radio color={"primary"}/>} label="公開しない" disabled={publicPageLimit}/>
                                </Field>
                            </div>
                            <Typography variant={"caption"} color={"error"}>
                                {publicPageLimit ?
                                    `${company.plan.name}プランをご利用中のため、変更できません`
                                    :
                                    'プレスリリース毎にも公開設定可能です。'
                                }
                            </Typography>
                        </div>
                    </li>
                    <li>
                        <label>プレスリリース<br/>の確認依頼フロー機能</label>
                        <div>
                            <div className={style.form}>
                                <Field name="is_review_skip" component={RadioButtonField}>
                                    <FormControlLabel value={"false"} control={<Radio color={"primary"}/>} label="ON" disabled={isReviewSkipLimit}/>
                                    <FormControlLabel value={"true"} control={<Radio color={"primary"}/>} label="OFF" disabled={isReviewSkipLimit}/>
                                </Field>
                            </div>
                            {isReviewSkipLimit && <Typography variant={"caption"} color={"error"}>{company.plan.name}プランをご利用中のため、変更できません</Typography>}
                        </div>
                    </li>
                    <li>
                        <label>プレスリリース配信用<br/>メールアドレス</label>
                        <div className={style.form}>
                            <Field name="email" type="email" component={TextField} label={"pr@malbs.jp"}/>
                        </div>
                    </li>
                </ul>
                <div className={style.shortActions}>
                    <Button variant="contained" size="large" type="submit" color="primary"
                            disabled={pristine || submitting}>
                        更新
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default reduxForm({
    form: 'COMPANY_EDIT',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true
})(ServiceEditForm);

const useStyles = makeStyles(() => ({
    prefix: {
        marginLeft: 10
    },
    caption: {
        marginTop: 10,
        display: "block"
    }
}));
