import React from 'react';
import { useSelector } from 'react-redux';

// component
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import EditTable from './Table';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

const Edit: React.FC = () => {
    const classes = useStyles()
    const briefing = useSelector(briefingSelector);
    const { fetched, body } = briefing.detail;

    return (
        <>
            {fetched ?
                <div className={classes.editor}>
                    {body.name &&
                        <Typography className={classes.title}>
                            タイトル：<span className={classes.name}>{body.name}</span>
                        </Typography>}
                    <EditTable />
                </div>
                :
                <div className={classes.circularProgress}>
                    <CircularProgress />
                </div>}
        </>
    );
}

export default Edit;

const useStyles = makeStyles({
    editor: {
        width: '100%',
        maxWidth: 700,
        padding: '68px 24px',
        margin: '0 auto',
    },
    circularProgress: {
        position: 'absolute',
        top: '40%',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    title: {
        padding: "10px 0",
    },
    name: {
        color: "#6D7470",
        fontSize: 18,
        fontWeight: "bold",
    },
});
