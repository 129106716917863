import Froalaeditor from 'froala-editor';
import CloseIcon from '../../../assets/icon_close_gray.svg';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../reducers";
import * as BrockType from "../../../config/block_type";
import {useEffect, useState} from "react";
import * as BlockActionCreators from "../../../actions/Block/ActionCreator";

//img
import iconTrash from '../../../assets/icon_trash.svg';

Object.assign(Froalaeditor.POPUP_TEMPLATES, {
    'block.popup': '[_BUTTONS_][_CUSTOM_LAYER_]'
});

const blockSelector = (state: RootState) => state.block;

const FroalaBlockPlugin = (editor) => {

    const dispatch = useDispatch();
    const block = useSelector(blockSelector);

    const setLabel = (type) => {
        let label = ""
        switch (type) {
            case BrockType.BLOCK_TYPE_HEADER:
                label = "ヘッダーパーツ";
                break;
            case BrockType.BLOCK_TYPE_FOOTER:
                label = "フッターパーツ";
                break;
            default:
                label = "テキストパーツ";
        }
        return label
    };


    useEffect(() => {

        let block_buttons = '<div>';
        block.results.map((item, index) => {
            block_buttons +=
                `<p>${setLabel(item.type)}</p>` +
                `<div class="block-item" data-html='${item.content}'>` +
                '<div class="block-buttons">';

                if (item.type === BrockType.BLOCK_TYPE_TEXT) {
                    block_buttons += `<button class="block-insert-button">カーソル位置に挿入</button>`
                }
                if (item.type === BrockType.BLOCK_TYPE_HEADER) {
                    block_buttons += `<button class="block-insert-header-button">ヘッダーに挿入</button>`
                }
                if (item.type === BrockType.BLOCK_TYPE_FOOTER) {
                    block_buttons += `<button class="block-insert-footer-button" data-id="${item.id}">フッターに挿入</button>`
                }
                block_buttons += `<button class="block-delete-button" data-id="${item.id}"><img src="${iconTrash}"/></button>` +
                '</div>' +
                `<div class="block-element">${item.content}</div>` +
                '</div>'
        });
        block_buttons += '</div>';
        const element: any = document.querySelector('.block-items');
        if (element) element.innerHTML = block_buttons;

        if (block.results.length) {
            // 挿入
            const popup:HTMLElement = document.querySelector('.fr-popup') as HTMLElement;
            const insertButtons = document.getElementsByClassName('block-insert-button');
            for (let i = 0; i < insertButtons.length; i++) {
                insertButtons[i].addEventListener('click', () => {
                    const node: any = insertButtons[i].closest('.block-item');
                    editor.html.insert(node.dataset.html);
                    if ( popup ) popup.classList.remove("fr-active")
                }, false);
            }

            // 削除
            const deleteButtons = document.getElementsByClassName('block-delete-button');
            for (let i = 0; i < deleteButtons.length; i++) {
                deleteButtons[i].addEventListener('click', () => {
                    // 削除処理
                    const node: any = deleteButtons[i];
                    dispatch(BlockActionCreators.deleteBlock.request({id: node.dataset.id}));
                }, false);
            }

            // ヘッダー
            const headerButtons = document.getElementsByClassName('block-insert-header-button');
            for (let i = 0; i < headerButtons.length; i++) {
                headerButtons[i].addEventListener('click', () => {
                    const header: HTMLElement = document.querySelector('#appEditor .fr-view .header') as HTMLElement;
                    const node: any = headerButtons[i].closest('.block-item');
                    header.outerHTML = node.dataset.html;
                    if ( popup ) popup.classList.remove("fr-active")
                }, false);
            }

            // フッター
            const footerButtons = document.getElementsByClassName('block-insert-footer-button');
            for (let i = 0; i < footerButtons.length; i++) {
                footerButtons[i].addEventListener('click', () => {
                    const footer: HTMLElement = document.querySelector('#appEditor .fr-view .footer') as HTMLElement;
                    const node: any = footerButtons[i].closest('.block-item');
                    footer.outerHTML = node.dataset.html;
                    if ( popup ) popup.classList.remove("fr-active")
                }, false);
            }

        }
    }, [block]);


    Froalaeditor.PLUGINS.block = (editor) => {


        // Create custom popup.
        function initPopup(block) {

            let popup_buttons = '<div class="fr-buttons popup-header">' +
                '<h4>カスタムブロック<span>よく使う文章のブロックは保存すると、便利です。</span></h4>' +
                `<button class="popup-close-button"><img src="${CloseIcon}"></button>` +
                '</div>';

            let block_buttons =
                '<div class="popup-caption">' +
                '<div><button id="parts-event-save" class="button">選択中のテキストを保存</button></div>' +
                '<div><button id="parts-event-header" class="button">現在のヘッダーを保存</button></div>' +
                '<div><button id="parts-event-footer" class="button">現在のフッターを保存</button></div>' +
                '</div>' +
                '<div class="popup-content block-items">' +
                '</div>';

            const template = {
                buttons: popup_buttons,
                custom_layer: block_buttons
            };

            const $popup = editor.popups.create('block.popup', template);

            // popup閉じる
            const closeButton = document.getElementsByClassName('popup-close-button');
            for (let i = 0; i < closeButton.length; i++) {
                closeButton[i].addEventListener('click', () => {
                    hidePopup();
                }, false);
            }

            // テキスト保存
            const saveButton = document.getElementById('parts-event-save');
            if (saveButton) {
                saveButton.addEventListener('click', () => {
                    const selection = window.getSelection();
                    // 1つ目の選択範囲のDOMをDocumentFragmentにコピー
                    const dom = selection.getRangeAt(0).cloneContents();
                    const header: any = dom.querySelector('.header');
                    const footer: any = dom.querySelector('.footer');

                    if (header) {
                        header.classList.remove("header");
                    }

                    if (footer) {
                        footer.classList.remove("footer");
                    }

                    var div = document.createElement('div');
                    div.appendChild(dom.cloneNode(true));
                    dispatch(BlockActionCreators.postBlock.request({title: "text", content: div.innerHTML, type: BrockType.BLOCK_TYPE_TEXT}));
                }, false);
            }

            // ヘッダー保存
            const headerButton = document.getElementById('parts-event-header');
            if (headerButton) {
                headerButton.addEventListener('click', () => {
                    const div = document.createElement('div');
                    const header: HTMLElement = document.querySelector('#appEditor .fr-view .header') as HTMLElement;
                    div.appendChild(header.cloneNode(true));
                    dispatch(BlockActionCreators.postBlock.request({title: "header", content: div.innerHTML, type: BrockType.BLOCK_TYPE_HEADER}));
                }, false);
            }

            // フッター保存
            const footerButton = document.getElementById('parts-event-footer');
            if (footerButton) {
                footerButton.addEventListener('click', () => {
                    const div = document.createElement('div');
                    const footer: HTMLElement = document.querySelector('#appEditor .fr-view .footer') as HTMLElement;
                    div.appendChild(footer.cloneNode(true));
                    dispatch(BlockActionCreators.postBlock.request({title: "footer", content: div.innerHTML, type: BrockType.BLOCK_TYPE_FOOTER}));
                }, false);
            }

            return $popup;
        }

        // Show the popup
        function showPopup() {

            let $popup = editor.popups.get('block.popup');
            if (!$popup) $popup = initPopup([]);

            editor.popups.setContainer('block.popup', editor.$tb);
            let $btn = editor.$tb.find('.fr-command[data-cmd="blockButton"]');

            let left = $btn.offset().left - 500;
            editor.popups.show('block.popup', left, top, $btn.outerHeight());

        }

        // Hide the custom popup.
        function hidePopup() {
            editor.popups.hide('block.popup');
        }

        // Methods visible outside the plugin.
        return {
            showPopup: showPopup,
            hidePopup: hidePopup,
        }
    };


};

export default FroalaBlockPlugin;

