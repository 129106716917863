import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { push } from "connected-react-router";

import { RootState } from '../../../../reducers';
const groupSelector = (state: RootState) => state.group;

interface Props {
    contents: [{ "uuid": string, "name": string }],
    value: string,
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles({
    container: {
        minWidth: 400,
    },
    icon: {
        fontSize: "2.0rem"
    }
})



const PressFilter: React.FC<Props> = ({ contents, value }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const group = useSelector(groupSelector);

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        const value = event.target.value
        if (value === "") {
            dispatch(push(`/press_release/${group.selectedId}/dashboard/`))
        } else {
            dispatch(push(`/press_release/${group.selectedId}/dashboard/${value}/detail/`))
        }
    }




    return (
        <FormControl variant='outlined' className={classes.container}>
            <InputLabel
                shrink
            >プレスリリース</InputLabel>
            <Select
                inputProps={{
                    classes: {
                        icon: classes.icon
                    }
                }}
                disabled={!group.selectedId}
                value={value}
                onChange={handleChange}
                MenuProps={MenuProps}
                displayEmpty
            >
                {contents.map((item, i) => (
                    <MenuItem
                        key={item.uuid}
                        value={item.uuid}
                    >

                        <ListItemText secondary={item.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default PressFilter;
