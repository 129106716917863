export const PANEL_TYPE_INFO = 'PANEL_TYPE_INFO'
export const PANEL_TYPE_COMMENT = 'PANEL_TYPE_COMMENT'
export const PANEL_TYPE_HISTORY = 'PANEL_TYPE_HISTORY'
export const PANEL_TYPE_EDITOR = 'PANEL_TYPE_EDITOR'
export const PANEL_TYPE_EMAIL = 'PANEL_TYPE_EMAIL'
export const PANEL_TYPE_ACTIVE_LOG = 'PANEL_TYPE_ACTIVE_LOG'
export const PANEL_TYPE_MEMBER = 'PANEL_TYPE_MEMBER'
export const PANEL_TYPE_AI = 'PANEL_TYPE_AI'
export const PANEL_TYPE_DOWNLOAD = 'PANEL_TYPE_DOWNLOAD'
export const PANEL_TYPE_TAG = 'PANEL_TYPE_TAG'
