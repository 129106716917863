import React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";

// pages
import ListPage from "./List";
import ReceivedListPage from './Received';
import EditPage from "./Edit";
import AnswerPage from './Answer';
import CreatePage from './Create';
import PublicDetailPage from './Detail';
import SelectTemplate from "./Template";

type PageProps = {} & RouteComponentProps<{}>;

const BriefingPage: React.FC<PageProps> = () => {
    return (
        <>
            <Switch>
                <Route exact={true} path="/briefing/" component={ListPage} />
                <Route exact={true} path="/briefing/:groupId/received/" component={ReceivedListPage} />
                <Route exact={true} path="/briefing/:groupId/select/" component={SelectTemplate} />
                <Route exact={true} path="/briefing/:groupId/create/" component={CreatePage} />
                <Route exact={true} path="/briefing/:groupId/edit/:formId" component={EditPage} />
                <Route exact={true} path="/briefing/:groupId/public/:formId" component={PublicDetailPage} />
                <Route exact={true} path="/briefing/:groupId/answer/:formId" component={AnswerPage} />
            </Switch>
        </>
    );
}

export default BriefingPage;
