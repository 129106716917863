import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

// material-ui
import {makeStyles} from "@material-ui/core/styles";
import {
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Card,
  Box,
  Link
} from "@material-ui/core";

// components
import SettingTitle from "../../Common/SettingTitle";
import ConfirmDialog from "./ConfirmDialog";
import TicketInfo from "../Ticket/TicketInfo";
import OptionList from "../Option";
import RegisterCardDialog from "../Payment/RegisterCardDialog";

// actions
import * as PlanActionCreator from "../../../actions/Plan/ActionCreator";

// img
import check_circle from "../../../assets/check_circle.svg";

// config
import {
  SPOT,
  STANDARD,
  PREMIUM,
  ENTERPRISE
} from "../../../config/payment_config";

// state
import {RootState} from "../../../reducers";
import {PlanInfo} from "../../../model/PlanModel";

const companySelector = (state: RootState) => state.company;
const paymentSelector = (state: RootState) => state.payment.info;
const planSelector = (state: RootState) => state.plan;

const Plan = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const payment = useSelector(paymentSelector);
  const plan = useSelector(planSelector);

  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [planInfo, setPlanInfo] = React.useState<PlanInfo>({
    code: 0,
    name: "",
    amount: 0
  });

  useEffect(() => {
    dispatch(PlanActionCreator.getPlanList.request());
  }, []);

  const handleOpenConfirmDialog = plan => {
    setPlanInfo({code: plan.code, name: plan.name, amount: plan.amount});
    setConfirmDialog(true);
  };

  const CheckMark = () => <img src={check_circle} />;

  const CurrentPlan = () => (
    <Typography variant="h5" color="primary" className={classes.currentPlan}>
      現在のプラン
    </Typography>
  );

  return (
    <>
      <SettingTitle text="プラン/オプション" />
      <TicketInfo />
      <OptionList />
      <div className={classes.header}>
        <Typography variant={"h1"} className={classes.headPlan}>
          プラン
        </Typography>
        <Typography variant={"body1"}>
          機能について詳しく知りたい方は、
          <NavLink to="/features/" className={classes.link}>
            こちら
          </NavLink>
        </Typography>
      </div>
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell style={{width: "28%"}}></TableCell>
              <TableCell align="center" style={{width: "18%"}}>
                <Typography variant="h5">スタート</Typography>
                <Box className={classes.chargeBox}>
                  <Typography className={classes.charge}>3万</Typography>{" "}
                  円/月(税込)
                </Box>
                {/* TODO: バックエンドから返却されるスタートプランのデータを確認して、後で修正する */}
                {company.plan.code === SPOT ? (
                  <CurrentPlan />
                ) : (
                  <ChangePlanButton plan={plan.spot} />
                )}
              </TableCell>
              <TableCell align="center" style={{width: "18%"}}>
                <Typography variant="h5">スタンダード</Typography>
                <Box className={classes.chargeBox}>
                  <Typography className={classes.charge}>6万</Typography>{" "}
                  円/月(税込)
                </Box>
                {company.plan.code === STANDARD ? (
                  <CurrentPlan />
                ) : (
                  <ChangePlanButton plan={plan.standard} />
                )}
              </TableCell>
              <TableCell align="center" style={{width: "18%"}}>
                <Typography variant="h5">プレミアム</Typography>
                <Box className={classes.chargeBox}>
                  <Typography className={classes.charge}>15万</Typography>{" "}
                  円/月(税込)
                </Box>
                {company.plan.code === PREMIUM ? (
                  <CurrentPlan />
                ) : (
                  <ChangePlanButton plan={plan.premium} />
                )}
              </TableCell>
              <TableCell align="center" style={{width: "18%"}}>
                <Typography variant="h5">エンタープライズ</Typography>
                <Box className={classes.chargeBox}>
                  <Typography className={classes.charge}>
                    お問い合わせ
                  </Typography>
                </Box>
                {company.plan.code === ENTERPRISE ? (
                  <CurrentPlan />
                ) : (
                  <Link
                    href="https://harvest.site/contact"
                    target="_blank"
                    underline="none"
                    rel="noopener"
                  >
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      className={classes.button}
                    >
                      お問い合わせ
                    </Button>
                  </Link>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">契約期間</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">最低契約期間</TableCell>
              <TableCell align="center">12ヶ月</TableCell>
              <TableCell align="center">6ヶ月</TableCell>
              <TableCell align="center">6ヶ月</TableCell>
              <TableCell align="center">お問い合わせ</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">ユーザー数</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">ユーザー数制限(※1)</TableCell>
              <TableCell align="center">1人</TableCell>
              <TableCell align="center">3人</TableCell>
              <TableCell align="center">5人</TableCell>
              <TableCell align="center">お問い合わせ</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">プロジェクト</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">作成数</TableCell>
              <TableCell align="center">1つ</TableCell>
              <TableCell align="center">3つ</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">プレスリリース</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">作成数</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">配信数</TableCell>
              <TableCell align="center">1回/月</TableCell>
              <TableCell align="center">10回/月</TableCell>
              <TableCell align="center">30回/月</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">配信メディア数</TableCell>
              <TableCell colSpan={4} align="center">
                HARVESTが保有しているメディアから選定されたメディア数
                <br />+<br />
                ご契約者様のメールリストを加えることが可能です。
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">送信先AI自動選定</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">AI文章チェック</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">送信元メールアドレス指定</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">PDF出力</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">HTML出力</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">
                自社プレスリリースWEBページ生成
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">履歴管理</TableCell>
              <TableCell align="center">ー</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">申請・承認ワークフロー機能</TableCell>
              <TableCell align="center">ー</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">企業メディアリスト</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">登録数</TableCell>
              <TableCell align="center">50件</TableCell>
              <TableCell align="center">100件</TableCell>
              <TableCell align="center">300件</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">プレスキット</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">フォルダ作成数</TableCell>
              <TableCell align="center">1つ</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">ファイル保存総容量</TableCell>
              <TableCell align="center">100MB</TableCell>
              <TableCell align="center">1GB</TableCell>
              <TableCell align="center">3GB</TableCell>
              <TableCell align="center">10GB</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">ブリーフィングシート</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">作成数</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">メール配信数</TableCell>
              <TableCell align="center">50通/月</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">未来トピック</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">閲覧</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">記事化レーダー</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">キーワード数</TableCell>
              <TableCell colSpan={4} align="center">
                オプション
                <br />
                1キーワードにつき5,500円/月(税込)
              </TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">セキュリティ</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">IP制限</TableCell>
              <TableCell align="center">ー</TableCell>
              <TableCell align="center">ー</TableCell>
              <TableCell align="center">ー</TableCell>
              <TableCell align="center">オプション</TableCell>
            </TableRow>
            <TableRow className={classes.band}>
              <TableCell>
                <Typography variant="h5">サポート</Typography>
              </TableCell>
              <TableCell colSpan={4}></TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">メールサポート</TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
              <TableCell align="center">
                <CheckMark />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.annotationBox}>
        <Typography variant="body1" gutterBottom>
          (※1) 1人あたり1,100円/月(税込)でユーザーの追加が可能です。
        </Typography>
      </Box>
      {payment.card.number ? (
        <ConfirmDialog
          planInfo={planInfo}
          isOpen={confirmDialog}
          closeHandle={() => setConfirmDialog(false)}
        />
      ) : (
        <RegisterCardDialog
          isOpen={confirmDialog}
          closeHandle={() => setConfirmDialog(false)}
        />
      )}
    </>
  );
};

export default Plan;

const ChangePlanButton = ({plan}) => {
  const classes = useStyles();

  return (
    <NavLink
      exact
      to={{pathname: "/setting/plan/apply/", state: {planName: plan.name}}}
    >
      <Button
        color="primary"
        size="large"
        variant="contained"
        className={classes.button}
      >
        変更する
      </Button>
    </NavLink>
  );
};

const useStyles = makeStyles({
  band: {
    backgroundColor: "#f3f7f4"
  },
  tableRow: {
    "& > th:not(:last-child), & > td:not(:last-child)": {
      borderRight: "1px solid #e0e0e0"
    },
    "& > td": {
      fontSize: 14
    }
  },
  currentPlan: {
    marginTop: 10
  },
  button: {
    marginTop: 10
  },
  chargeBox: {
    marginTop: 5
  },
  charge: {
    fontSize: 16,
    display: "inline"
  },
  annotationBox: {
    margin: "15px 0"
  },
  annotationText: {
    marginLeft: "2em",
    lineHeight: 1.7
  },
  link: {
    fontSize: 14,
    fontWeight: "bold",
    textDecoration: "underline"
  },
  header: {
    display: "flex",
    alignItems: "baseline"
  },
  headPlan: {
    marginRight: 20
  }
});
