import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageDialog from "../../../Common/ImageDialog";

//icon
import CameraAltIcon from '@material-ui/icons/CameraAlt';

//action
import * as ActionCreators from "../../../../actions/PressReleasePublish/ActionCreator";

// state
import { RootState } from "../../../../reducers";
import { Typography, Box, IconButton } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const pressReleasePublishSelector = (state: RootState) => state.pressReleasePublish;
const companySelector = (state: RootState) => state.company;


const useStyles = makeStyles({
    root: {
        position: "relative",
        width: "100%",
    },
    content: {
        display: "flex",
        padding: 10,
        margin: 20,
        border: "1px solid #ddd",
    },
    inputTitle: {
        width: "100%",
        marginBottom: 10,
        fontWeight: "bold"
    },
    inputText: {
        width: "100%",
    },
    textareaField: {
        minHeight: 60
    },
    form: {
        marginLeft: 10,
        paddingBottom: 15
    },
    imgBox: {
        position: "relative",
        width: 180,
        height: 180,
        backgroundColor: "#ddd",
    },
    img: {
        width: "100%",
        maxWidth: 180,
        maxHeight: 180,
    },
    noImg: {
        display: "flex",
        width: 180,
        height: 180,
        fontSize: 13,
        justifyContent: "center",
        alignItems: "center",
    },
    camera: {
        position: "absolute",
        bottom: "-5px",
        right: "-5px",
    },
    url: {
        position: "absolute",
        bottom: 25,
        left: 220,
        fontSize: 11
    }
});

interface editAuthorityProps {
    hasEdit: boolean,
}

const Index: React.FC<editAuthorityProps> = ({ hasEdit }) => {

    const classes = useStyles();
    const pressReleasePublish = useSelector(pressReleasePublishSelector);
    const company = useSelector(companySelector);
    const dispatch = useDispatch();
    const [OGPImageDialog, setOGPImageDialog] = React.useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent) => {
        const target: any = event.target
        dispatch(ActionCreators.setPressReleasePublish.request({ [target.name]: target.value }));
    };

    const handleUpload = (crop) => {
        dispatch(ActionCreators.patchOGPImage.request(crop));
    };

    return (
        <div className={classes.root}>
            <Box className={classes.content}>
                <div className={classes.imgBox}>
                    {pressReleasePublish.ogp_url
                        ? <img src={pressReleasePublish.ogp_url} className={classes.img} />
                        : <div className={classes.noImg}>画像が設定されていません</div>
                    }
                    {hasEdit &&
                        <div className={classes.camera} onClick={() => setOGPImageDialog(true)}>
                            <IconButton aria-label="delete">
                                <CameraAltIcon fontSize={"large"} />
                            </IconButton>
                        </div>
                    }
                </div>

                <Box className={classes.form}>
                    <TextField multiline
                        className={classes.inputTitle}
                        type="textarea"
                        name="ogp_title"
                        placeholder="タイトル"
                        onChange={handleChange}
                        value={pressReleasePublish.ogp_title}
                        helperText={pressReleasePublish.ogp_title ? "" : "入力してください"}
                        disabled={!hasEdit}
                    />
                    <TextField multiline
                        className={classes.inputText}
                        type="textarea"
                        name="ogp_description"
                        placeholder="概要"
                        onChange={handleChange}
                        value={pressReleasePublish.ogp_description}
                        InputProps={{
                            classes: {
                                input: classes.textareaField,
                            },
                        }}
                        helperText={pressReleasePublish.ogp_description ? "" : "入力してください"}
                        disabled={!hasEdit}
                    />
                </Box>
                <Typography variant={"caption"} className={classes.url}>https://pr.harvest.site/press_release/{company.prefix}/YYYYMMDD</Typography>
            </Box>
            <ImageDialog isOpen={OGPImageDialog} closeHandle={() => setOGPImageDialog(false)} handleUpload={handleUpload} />
        </div>
    );
};

export default Index;
