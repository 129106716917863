import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {push} from "connected-react-router";

// material-ui
import {Button, Typography} from "@material-ui/core";

// components
import SettingTitle from "../../Common/SettingTitle";
import DateTime from "../../Common/DateTime";
import TableFrame from "../../Common/TableFrame";
import CancelDialog from "../Plan/CancelDialog";

// config
import {settingPaymentStatus} from "../../../config/payment_config";

//style
import style from "./payment.module.scss";

// state
import {RootState} from "../../../reducers";
const ticketSelector = (state: RootState) => state.ticket;
const companySelector = (state: RootState) => state.company;
const planSelector = (state: RootState) => state.plan;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props;
  return <div hidden={value !== index}>{value === index && children}</div>;
};

const ListLayout: React.FC = () => {
  const ticket = useSelector(ticketSelector);
  const company = useSelector(companySelector);
  const plan = useSelector(planSelector);
  const dispatch = useDispatch();
  const [cancelDialog, setCancelDialog] = useState(false);

  const CancelButton: React.FC = () => {
    const can_cancel_plan = company.can_cancel_plan;
    return (
      <Button
        disabled={!can_cancel_plan}
        onClick={() => {
          setCancelDialog(true);
        }}
      >
        {can_cancel_plan ? "解約" : "キャンセルリクエスト済み"}
      </Button>
    );
  };

  return (
    <>
      <SettingTitle text="ご契約情報" />
      <TableFrame>
        <ul>
          <li className={style.item}>
            <div className={style.itemContent}>
              <label>プラン</label>
              <Typography>{`${company.plan.name}プラン`}</Typography>
            </div>
            {company.plan.code != plan.free.code && <CancelButton />}
          </li>
          <li className={style.item}>
            <div className={style.itemContent}>
              <label>購入配信数</label>
              <Typography>{ticket.qty.non_default.available}</Typography>
            </div>
          </li>
          <li className={style.item}>
            <div className={style.itemContent}>
              <label>追加メンバー枠</label>
              <Typography>{company.option.member.quantity}</Typography>
            </div>
            {!!company.option.member.validFromNext && (
              <Button
                onClick={() => dispatch(push("/setting/option/member/remove/"))}
              >
                削除
              </Button>
            )}
          </li>
          <li className={style.item}>
            <div className={style.itemContent}>
              <label>検索ワード</label>
              <Typography>{company.option.radar.quantity}</Typography>
            </div>
            {!!company.option.radar.validFromNext && (
              <Button
                onClick={() => dispatch(push("/setting/option/radar/remove/"))}
              >
                削除
              </Button>
            )}
          </li>
        </ul>
      </TableFrame>
      <CancelDialog
        isOpen={cancelDialog}
        closeHandle={() => setCancelDialog(false)}
      />
    </>
  );
};
export default ListLayout;

interface ListLayoutItemProps {
  item;
}

const HistoryListLayoutItem: React.FC<ListLayoutItemProps> = ({item}) => {
  return (
    <li className={style.list}>
      <ul className={style.inner}>
        <li className={style.payDate}>
          <DateTime
            datetime={item.charge_date}
            isFormat={true}
            format={"YYYY/MM/DD"}
          />
        </li>
        <li className={style.planName}>{item.overview}</li>
        <li className={style.servicePeriod}>
          {item.active_date && (
            <>
              <DateTime
                datetime={item.charge_date}
                isFormat={true}
                format={"YYYY/MM/DD"}
              />
              <span> ~</span>
              <DateTime
                datetime={item.active_date}
                isFormat={true}
                format={"YYYY/MM/DD"}
              />
            </>
          )}
        </li>
        <li className={style.payStatus}>{settingPaymentStatus(item.status)}</li>
        <li className={style.amount}>
          ¥{(item.amount + item.tax).toLocaleString()}
        </li>
        {/* <li className={style.menu}>
                    <Button variant="outlined" size="small" color="primary">
                        領収書
                    </Button>
                </li> */}
      </ul>
    </li>
  );
};

const OrderInfoListLayoutItem: React.FC<ListLayoutItemProps> = ({item}) => {
  return (
    <li className={style.list}>
      <ul className={style.inner}>
        <li className={style.payDate}>
          <DateTime
            datetime={item.created_at}
            isFormat={true}
            format={"YYYY/MM/DD"}
          />
        </li>
        <li className={style.content}>{item.content}</li>
        <li className={style.content}>
          {`¥${(item.amount + item.tax).toLocaleString()}`}
        </li>
        {/* <li className={style.menu}>
                    <Button variant="outlined" size="small" color="primary">
                        領収書
                    </Button>
                </li> */}
      </ul>
    </li>
  );
};
