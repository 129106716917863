import { call, put, takeLatest, select } from 'redux-saga/effects';
import { fetchGet, fetchPost, fetchPatch, fetchDelete } from "./fetch";
import PaymentActionType from "../actions/Payment/ActionType";
import * as ActionCreators from '../actions/Payment/ActionCreator';
import * as CompanyActionCreators from '../actions/Company/ActionCreator';
import * as OrderInfoActionCreators from '../actions/OrderInfo/ActionCreator';
import * as TicketActionCreators from '../actions/Ticket/ActionCreator';
import * as ErrorActionCreators from '../actions/Error/ActionCreator';



import { selectGroup } from "./selector";
import { push } from 'connected-react-router';

function* getCardEditUrl(action: ReturnType<typeof ActionCreators.getCardEditURL.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `gmo/${group.selectedId}/card_edit_url/`);
        yield put(ActionCreators.getCardEditURL.success(data));
    } catch (e) {
        yield put(ActionCreators.getCardEditURL.failure(e))
    }
}

function* getCardInfo(action: ReturnType<typeof ActionCreators.getCardInfo.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `gmo/${group.selectedId}/card_info/`);
        yield put(ActionCreators.getCardInfo.success(data));
    } catch (e) {
        yield put(ActionCreators.getCardInfo.failure(e))
    }

}

function* postPlan(action: ReturnType<typeof ActionCreators.postPlan.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPost, `gmo/${group.selectedId}/plan`, action.payload);
        if (data && !error) {
            yield put(ActionCreators.postPlan.success(data));
            yield put(CompanyActionCreators.getCompany.request());
            yield put(OrderInfoActionCreators.getOrderInfoList.request({ offset: 0 }));
            yield put(TicketActionCreators.getTicketQty.request());
        }
        yield put(ActionCreators.postPlan.failure(error))
    } catch (e) {
        yield put(ActionCreators.postPlan.failure(e))
    }

}

function* patchPlan(action: ReturnType<typeof ActionCreators.patchPlan.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPatch, `gmo/${group.selectedId}/plan`, action.payload);
        if (data && !error) {
            yield put(ActionCreators.patchPlan.success(data));
            yield put(CompanyActionCreators.getCompany.request());
            yield put(OrderInfoActionCreators.getOrderInfoList.request({ offset: 0 }));
            yield put(TicketActionCreators.getTicketQty.request());
        }
        yield put(ActionCreators.patchPlan.failure(error));
    } catch (e) {
        yield put(ActionCreators.patchPlan.failure(e))
    }

}

function* deletePlan(action: ReturnType<typeof ActionCreators.deletePlan.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchDelete, `gmo/${group.selectedId}/plan/`);
        if (data && !error) {
            yield put(ActionCreators.deletePlan.success(data));
            yield put(CompanyActionCreators.getCompany.request());
            yield put(OrderInfoActionCreators.getOrderInfoList.request({ offset: 0 }));
            yield put(push('/setting/plan/'))
        }
        yield put(ActionCreators.deletePlan.failure(error))

    } catch (e) {
        yield put(ActionCreators.deletePlan.failure(e))
    }
}

function* postTicketPayment(action: ReturnType<typeof ActionCreators.postTicketPayment.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPost, `gmo/${group.selectedId}/ticket`, action.payload);
        if (data && !error) {
            yield put(ActionCreators.postTicketPayment.success())
            yield put(TicketActionCreators.getTicketQty.request())
            yield put(push('/setting/plan/'))
        }
    } catch (e) {
        yield put(ActionCreators.postTicketPayment.failure(e))
    }

}

function* postOption(action: ReturnType<typeof ActionCreators.postOption.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPost, `gmo/${group.selectedId}/option`, action.payload);
        yield put(ActionCreators.postOption.success(data))
        yield put(CompanyActionCreators.getCompany.request());
        yield put(OrderInfoActionCreators.getOrderInfoList.request({ offset: 0 }));
        yield put(push('/setting/plan/'))

    } catch (e) {
        yield put(ActionCreators.postOption.failure(e))
        yield put(push('/setting/plan/'))
    }

}

function* patchOption(action: ReturnType<typeof ActionCreators.patchOption.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPatch, `gmo/${group.selectedId}/option`, action.payload);
        if (data && !error) {
            yield put(ActionCreators.patchOption.success(data));
            yield put(CompanyActionCreators.getCompany.request());
            yield put(OrderInfoActionCreators.getOrderInfoList.request({ offset: 0 }));
            yield put(push('/setting/plan/'))
        } else {
            yield put(ErrorActionCreators.setErrorMessage(error.error));
            yield put(ErrorActionCreators.setErrorDialog(true));
            yield put(ActionCreators.patchOption.failure(error))
        }

    } catch (e) {
        yield put(ActionCreators.patchOption.failure(e))
        yield put(push('/setting/plan/'))

    }
}

function* deleteOption(action: ReturnType<typeof ActionCreators.deleteOption.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchDelete, `gmo/${group.selectedId}/option/`, action.payload);
        yield put(ActionCreators.deleteOption.success(data))
        yield put(CompanyActionCreators.getCompany.request());
        yield put(OrderInfoActionCreators.getOrderInfoList.request({ offset: 0 }));
        yield put(push('/setting/plan/'))

    } catch (e) {
        yield put(ActionCreators.deleteOption.failure(e))
        yield put(push('/setting/plan/'))
    }
}



const PaymentSaga = [
    takeLatest(PaymentActionType.GET_CARD_EDIT_URL_REQUEST, getCardEditUrl),
    takeLatest(PaymentActionType.GET_CARD_INFO_REQUEST, getCardInfo),
    takeLatest(PaymentActionType.POST_PLAN_REQUEST, postPlan),
    takeLatest(PaymentActionType.PATCH_PLAN_REQUEST, patchPlan),
    takeLatest(PaymentActionType.DELETE_PLAN_REQUEST, deletePlan),
    takeLatest(PaymentActionType.POST_TICKET_PAYMENT_REQUEST, postTicketPayment),
    takeLatest(PaymentActionType.POST_OPTION_REQUEST, postOption),
    takeLatest(PaymentActionType.PATCH_OPTION_REQUEST, patchOption),
    takeLatest(PaymentActionType.DELETE_OPTION_REQUEST, deleteOption),

]

export default PaymentSaga;
