import React from "react";

//model
import { ProceedingsDataContentModel } from "../../../model/TopicModel";

//material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
    List,
    ListItem,
    Typography,
} from "@material-ui/core";

//component
import { Words } from "./Words";

//style
import style from "./topicList.module.scss";


interface Props {
    item: ProceedingsDataContentModel
}

export const TopicListItem: React.FC<Props> = ({ item }) => {
    const classes = useStyles();
    const { year, month, display_words } = item;
    const listTitle = `${year}年${month}月`

    return (
        <li>
            <List className={style.topicListItem}>
                <ListItem className={classes.content}>
                    <Typography className={classes.title}>{listTitle}</Typography>
                    <div className={style.wordsContainer}>
                        <Words words={display_words || []} year={year} month={month} />
                    </div>
                </ListItem>
            </List>
        </li>
    )
}

const useStyles = makeStyles({
    title: {
        fontSize: 18,
        minWidth: 150,
        marginTop: "auto",
        marginBottom: 10
    },
    content: {
        minHeight: 80,
        display: "inline",
    }
})

