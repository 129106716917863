import React from "react";

//material-ui
import { List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//model
import { TopicWordSearchResultContentModel } from "../../../model/TopicModel";

//style
import style from "./searchResult.module.scss";

interface Props {
    item: TopicWordSearchResultContentModel
}

export const SearchResultListItem: React.FC<Props> = ({ item }) => {
    const classes = useStyles();

    return (
        <li className={style.listContent}>
            <List className={style.searchResultListItem}>
                <ListItem className={style.content}>
                    <a href={item.link} target="_blank" className={classes.link}>
                        <Typography className={classes.title}>{item.title}</Typography>
                        <Typography className={classes.source}>{item.source.title}</Typography>
                    </a>
                </ListItem>
            </List>
        </li>
    )
}

const useStyles = makeStyles({
    link: {
        width: "100%",
        display: "block"
    },
    title: {
        fontSize: 15,
        minWidth: 150,
        marginTop: "auto",
        marginBottom: "auto"
    },
    source: {
        textAlign: "right",
        fontSize: 12,
    }
})
