import React from 'react';
import Block from "./Block";
import Code from "./Code";
import AI from "./AI";
import HeaderFooter from "./HeaderFooter";
import NewPage from "./NewPage";
import Column from "./Column";

//style
import style from "../help.module.scss";
import PageTitle from "../PageTitle";

const PressRelease = () => {

	return (
		<article className={style.root}>
			<section><a id="1" className={style.anchor}/><AI/></section>
			<section><a id="2" className={style.anchor}/><HeaderFooter/></section>
			<section><a id="3" className={style.anchor}/><NewPage/></section>
			<section><a id="4" className={style.anchor}/><Block/></section>
			<section><a id="5" className={style.anchor}/><Code/></section>
			<section><a id="6" className={style.anchor}/><Column/></section>
		</article>
	);
};

export default PressRelease

