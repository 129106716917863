import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// style
import style from './media_reserve_list.module.scss';

// components
import { makeStyles, Typography, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Search from '../Search';

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';
import * as PressReleaseReserveActionCreators from '../../../actions/PressReleaseReserve/ActionCreator';

// state
import { RootState } from '../../../reducers';

// config
import { MEDIA_TYPE_LIST } from '../../../config/media_type';

const mediaSelector = (state: RootState) => state.media;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

interface Props {
    isEdit: boolean,
    item: any,
}

const MediaSelect: React.FC<Props> = ({ isEdit, item }) => {
    const dispatch = useDispatch();
    const media = useSelector(mediaSelector);

    const handleSearch = () => {
        dispatch(MediaActionCreators.getFavoriteMediaListDetail.request({
            id: item.list_id,
            offset: 0,
            id_list: media.categoryList.id_list
        }));
    }

    return (
        <div className={style.formRootDialog}>
            <div className={style.headerWrapperDialog}>
                <Typography variant='h5' className={style.header}>
                    {`${media.categoryList.count} 媒体`}
                </Typography>
                <Search handleSearch={handleSearch} />
            </div>
            <List isEdit={isEdit} />
        </div>
    );
};

export default MediaSelect;

const List = ({ isEdit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const media = useSelector(mediaSelector);
    const reserve = useSelector(pressReleaseReserveSelector);
    const { categories, category_details, type } = reserve.data;
    const [page, setPage] = useState(1);

    const handlePageChange = (pageNumber: number) => {
        setPage(pageNumber);
        const listId = media.favoriteList.index;
        if (listId === 0) {
            dispatch(PressReleaseReserveActionCreators.postPressReleaseMediaRecommend.request({
                categories: categories,
                details: category_details,
                type: type,
            }))
        } else if (listId > 0) {
            dispatch(MediaActionCreators.getFavoriteMediaListDetail.request({
                id: listId,
                offset: pageNumber,
                id_list: media.categoryList.id_list
            }));
        }
    }

    return (
        <div className={style.listWrapper}>
            <div className={style.listContainer}>
                {media.categoryList.fetched ?
                    media.categoryList.results.length > 0 ?
                        <>
                            <ul className={style.list}>
                                {media.categoryList.results.map((item, index) => (
                                    <ListLayoutItem key={index} item={item} isEdit={isEdit} />
                                ))}
                            </ul>
                        </>
                        : <NoMediaMessage />
                    :
                    <div className={classes.circularProgress}>
                        <CircularProgress />
                    </div>
                }
            </div>
            {media.categoryList.count > 0 && (
                <div className={style.paging}>
                    <Pagination
                        count={Math.ceil(media.categoryList.count / 51)}
                        page={page}
                        onChange={(e: React.ChangeEvent<unknown>, pageNumber: number) => handlePageChange(pageNumber)}
                    />
                </div>
            )}
        </div>
    );
}

interface ListLayoutItemProps {
    item,
    isEdit: boolean,
}

const ListLayoutItem: React.FC<ListLayoutItemProps> = ({ item, isEdit }) => {
    const media = useSelector(mediaSelector);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = () => {
        dispatch(MediaActionCreators.setMediaCategoryIdList.request(item.id));
    }

    return (
        <li className={style.mediaItem}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={media.categoryList.id_list.indexOf(item.id) < 0}
                        onChange={() => handleChange()}
                        value={item.id}
                        color="primary"
                        disabled={!isEdit}
                    />
                }
                className={classes.mediaLabel}
                classes={{ label: classes.mediaInner }}
                label={<a target="_blank" href={item.url} className={classes.link}>{item.name}</a>}
            />
            <Typography className={classes.mediaType}>{MEDIA_TYPE_LIST[item.media_type]}</Typography>
        </li>
    );
}

const emptyDataStyles = makeStyles({
    root: {
        padding: '150px 20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
});

const NoMediaMessage: React.FC = () => {
    const classes = emptyDataStyles();

    return (
        <Typography variant={"h6"} className={classes.root}>
            メディアはありません
        </Typography>
    );
};

const useStyles = makeStyles({
    root: {
        height: 500,
    },
    mediaLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    mediaInner: {
        verticalAlign: 'middle',
    },
    link: {
        color: "#5965b9",
        textDecoration: "underline",
    },
    mediaType: {
        fontSize: 11,
        color: "#999"
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85%',
        width: '100%',
    },
})
