import React from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

//material-ui
import {Typography, Button} from "@material-ui/core";

//component
import {TicketQtyView} from "./TicketQty";

//model
import {TicketQtyModel} from "../../../../../model/TicketModel";

interface Props {
  ticketQty: TicketQtyModel;
}

export const HasNoTicket: React.FC<Props> = ({ticketQty}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(push("/setting/plan"));
  };

  return (
    <>
      <Typography>残り配信数が0のため、配信予約できません。</Typography>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={handleClick}
      >
        追加申請する
      </Button>
      <TicketQtyView ticketQty={ticketQty} />
    </>
  );
};
