import React from 'react';
import {NavLink} from "react-router-dom";

//component
import Title from '../Title';
import PageTitle from "../PageTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/setting/company/image01.png';
import Image02 from '../../../assets/help/setting/company/image02.png';


const Company = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"各種設定"}/>
                <Title title={"1. 企業情報"}/>
                <Typography gutterBottom variant={"body1"}>
                    <Typography gutterBottom variant={"body1"} color={"error"}>
                        <NavLink target="_blank" to={`/help/web_page#1`} className={style.link}>プレスリリースweb公開ページ</NavLink>、
                        <NavLink target="_blank" to={`/help/press_kit#1`} className={style.link}>プレスキットページ</NavLink>で表示されます。
                    </Typography>
                    右上のプルダウンから「企業設定」を選択します。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
                <Typography gutterBottom variant={"body1"}>
                    「企業情報変更」から内容を修正することができます。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>
            </div>
        </article>
    );
};

export default Company;
