import React from 'react';
import Title from '../Title'
import SubTitle from "../SubTitle";
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/pressRelease/comment/image01.png';
import Image02 from '../../../assets/help/pressRelease/comment/image02.png';
import Image03 from '../../../assets/help/pressRelease/comment/image03.png';
import Image04 from '../../../assets/help/pressRelease/comment/image04.png';


const Create = () => {
	return (
		<>
			<div className={style.box}>
				<Title title={"6. コメント機能"}/>
				<SubTitle step={1} title={"コメント投稿"}/>
				<Typography gutterBottom variant={"body1"}>
					「コメントを追加」ボタン、または吹き出しアイコンを押下します。<br/>
				</Typography>
				<div className={style.img}>
					<img src={Image01}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					コメントを入力後、「コメント」ボタン押下で投稿されます。
				</Typography>
				<div className={style.img}>
					<img src={Image02}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					リストをクリックすると、詳細が表示されます。
				</Typography>
				<div className={style.img}>
					<img src={Image03}/>
				</div>
				<SubTitle step={2} title={"解決済みにする"}/>
				<Typography gutterBottom variant={"body1"}>
					左上の「解決済みにする」ボタンを押下すると、解決済みタブにはいります。<br/>
					返信する場合は、返信の文章を入力後「送信」を押下します。
				</Typography>
				<div className={style.img}>
					<img src={Image04}/>
				</div>
			</div>
		</>
	);
};

export default Create
