import ActionType from '../actions/PressReleaseReserve/ActionType';
import Action from '../actions/PressReleaseReserve/Action';
import {
	PressReleaseReserveReviewModel,
	PressReleaseReserveModel
} from "../model/PressReleaseReserveModel";

import createReducer from "./createReducer";
import { combineReducers } from "redux";
import { send } from 'react-ga';

const _d = new Date();
const tomorrow = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate() + 3, 0, 0, 0);
const nextweek = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate() + 10, 0, 0, 0);

const initialState: PressReleaseReserveModel = {
	custom: [],
	auto: {
		mail_title: "",
		mail_body: "",
		mail_contact: "",
		id_list: [],
		from_email: "",
		is_send: false,
		ai_list: [],
		list_id: 0,
		ai_length: 0,
	},
	categories: [],
	category_details: [],
	type: '',
	released_at: tomorrow,
	deadline_at: tomorrow,
	reviewer: [],
	comment: "",
	fetched: false,
	press_kit_id: [],
};

const data = createReducer<PressReleaseReserveModel>(initialState, {
	[ActionType.GET_PRESS_RELEASE_CUSTOM_MEDIA_SUCCEEDED](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			custom: action.payload.map(i => {
				return {
					...i,
					from_email: '',
					mail_title: '',
					mail_body: '',
				}
			})
		}
	},
	[ActionType.GET_PRESS_RELEASE_RESERVE_REQUEST](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			fetched: false
		}
	},
	[ActionType.GET_PRESS_RELEASE_RESERVE_SUCCEEDED](state: PressReleaseReserveModel, action: Action) {
		let reserve: any = action.payload
		const { custom_medias, auto_media } = action.payload
		// カスタムメディア
		const custom = state.custom
		let customList: any = []
		let autoMedia: any = {}
		if (auto_media) {
			autoMedia = Object.assign(auto_media, { is_send: auto_media.id_list.length > 0 })
			if (auto_media.list_id > 0) autoMedia.id_list = [];
			if (auto_media.list_id === null) autoMedia.list_id = 0;
		} else {
			autoMedia = Object.assign(state.auto, { is_send: true })
		}
		autoMedia.ai_length = state.auto.ai_length;
		if (custom_medias) {
			custom.forEach(media => {
				let item = media;
				let target = custom_medias && custom_medias.filter(item => item.custom_media.id == media.id)
				if (target.length) {
					item = Object.assign(item, { ...target[0] }, { is_send: true }, { id: item.id })
				}
				customList.push(item)
			})
		} else {
			customList = custom
		}
		
		return {
			...state,
			...reserve,
			deadline_at: reserve.deadline_at || tomorrow,
			released_at: reserve.released_at || nextweek,
			reviewer: reserve.reviewer,
			custom: customList,
			auto: { ...autoMedia, ai_list: [] },
			fetched: true,
		}
	},
	[ActionType.POST_PRESS_RELEASE_MEDIA_RECOMMEND_REQUEST](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			fetched: false,
		}
	},
	[ActionType.POST_PRESS_RELEASE_MEDIA_RECOMMEND_SUCCEEDED](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			auto: {
				...state.auto,
				ai_list: action.payload.id_list,
				is_send: true,
				id_list: state.auto.id_list.length > 0 ? state.auto.id_list : action.payload.id_list.map(i => i.id),
				ai_length: action.payload.count,
			},
			categories: action.payload.categories,
			category_details: action.payload.details,
			type: action.payload.type,
			fetched: true,
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_CUSTOM](state: PressReleaseReserveModel, action: Action) {
		const result: any = action.payload
		const custom = state.custom.concat()
		const list = custom.map(item => {
			if (item.id == result.id) {
				return {
					...item,
					...result
				}
			} else {
				return item
			}
		})
		return {
			...state,
			custom: list
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_COPY](state: PressReleaseReserveModel, action: Action) {
		const result: any = action.payload
		const list = state.custom.map(item => {
			return {
				...item,
				[result.key]: result.value
			}
		})
		return {
			...state,
			custom: list,
			auto: {
				...state.auto,
				[result.key]: result.value
			}
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_AUTO_MEDIA](state: PressReleaseReserveModel, action: Action) {
		let list = state.auto.id_list
		if (state.auto.id_list.indexOf(action.payload) >= 0) {
			list = list.filter(item => item !== action.payload);
		} else {
			list.push(action.payload)
		}
		return {
			...state,
			auto: {
				...state.auto,
				id_list: list
			}
		}
	},
	[ActionType.SET_ATTACHED_PRESS_KIT_ID](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			press_kit_id: action.payload,
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_AUTO_MEDIA_LIST](state: PressReleaseReserveModel, action: Action) {
		const request: any = action.payload;
		const list = state.auto.id_list.concat(request.map(i => i.id))
		return {
			...state,
			auto: {
				...state.auto,
				id_list: Array.from(new Set(list))
			}
		}
	},
	[ActionType.GET_PRESS_RELEASE_AUTO_MEDIA_SUCCEEDED](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			auto: action.payload
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_AUTO](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			auto: {
				...state.auto,
				...action.payload
			}
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_DATETIME](state: Date, action: Action) {
		return {
			...state,
			released_at: action.payload,
		}
	},
	[ActionType.ADD_PRESS_RELEASE_RESERVE_MEDIA_ALL](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			auto: {
				...state.auto,
				id_list: action.payload,
			},
		}
	},
	[ActionType.REMOVE_PRESS_RELEASE_RESERVE_MEDIA_ALL](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			auto: {
				...state.auto,
				id_list: [],
			},
		}
	},
	[ActionType.SET_MEDIA_LIST_ID](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			auto: {
				...state.auto,
				list_id: action.payload,
			},
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_CATEGORY](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			categories: action.payload.categories,
			category_details: action.payload.details,
		}
	},
	[ActionType.SET_PRESS_RELEASE_RESERVE_TYPE](state: PressReleaseReserveModel, action: Action) {
		return {
			...state,
			type: action.payload,
		}
	},
	[ActionType.CLEAR_PRESS_RELEASE_RESERVE_STATE](state: PressReleaseReserveModel, action: Action) {
		return initialState
	},
});


const initialStateReview: PressReleaseReserveReviewModel = {
	deadline_at: tomorrow,
	reviewer: [],
	comment: "",
	is_save: false,
};

const review = createReducer<PressReleaseReserveReviewModel>(initialStateReview, {
	[ActionType.SET_PRESS_RELEASE_RESERVE_REVIEW](state: Date, action: Action) {
		return action.payload
	},
});

const active = createReducer<number>(0, {
	[ActionType.SET_PRESS_RELEASE_RESERVE_ACTIVE](state: number, action: Action) {
		return action.payload
	},
	[ActionType.CLEAR_PRESS_RELEASE_RESERVE_STATE](state: number, action: Action) {
		return 0
	},
});

const reserveSaveLoading = createReducer(false, {
	[ActionType.SET_PRESS_RELEASE_RESERVE_SAVE_LOADING](state, action) {
		return action.payload
	},
});

export const pressReleaseReserve = combineReducers({
	data,
	review,
	active,
	reserveSaveLoading,
});


