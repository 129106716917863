import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/";


import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Container, Grid } from "@material-ui/core";

import AppLayout from '../../components/Layout/App';
import HeaderMenu from '../../components/Analytics/Common/HeaderMenu';
import Title from '../../components/Common/Title';
import HelpFab from '../../components/Common/HelpFab';



const AnalyticsSelector = (state: RootState) => state.analytics;

type Props = {children: ReactNode,page: string}

const useStyles = makeStyles({
    loading: {
        position: "absolute",
        width: 120,
        height: 160,
        left: "50%",
        top: "50%",
        margiLeft: "-60px",
        marginTop: "-80px"
    }
})

const AnalyticsLayout: React.FC<Props> = ({children,page}) => {
    const classes = useStyles();
    const analytics = useSelector(AnalyticsSelector);


    return (
        <AppLayout>
            <Container>
                <>
                    <Title>
                        ダッシュボード<HelpFab url={'/help/dashboard#1'}/>
                    </Title>
                    <HeaderMenu page={page} />
                    {analytics.loading ? (<div className={classes.loading}><CircularProgress /></div>) : (
                        <>
                        {children}
                        </>
                    )}
                </>
            </Container>
        </AppLayout>
    )
}
export default AnalyticsLayout;
