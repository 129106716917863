// prettier-ignore
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { withWidth } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { ConnectedRouter, push } from "connected-react-router";
import store from 'store';

import { history } from "./configureStore";

import Routes from "./Routes";
import AppRoutes from "./AppRoutes";
import withRoot from "./withRoot";
import SignInPage from "./pages/Sign/SignIn";
import SignUpPage from "./pages/Sign/SignUp";
import SignUpActivatePage from "./pages/Sign/SignUpActivate";
import SignUpTemporaryPage from "./pages/Sign/SignUpTemporary";
import PassWordResetPage from "./pages/Reset/Password";
import PassWordResetRegisterPage from "./pages/Reset/NewPassword";
import SendResetMailPage from "./pages/Reset/SendResetMail";
import PassWordRegisteredPage from "./pages/Reset/PasswordRegistered";

import './app.module.scss';
import { RootState } from "./reducers";
import Help from "./pages/Help/App";

const routerSelector = (state: RootState) => state.router;

interface Props extends RouteComponentProps<void> {}

const App: React.FC<Props> = () => {

	const router = useSelector(routerSelector);
	const dispatch = useDispatch();
	const firestoreToken = store.get('firestore_token');

	ReactGA.initialize('UA-156316749-3');

	useEffect(()=> {
		if (process.env.NODE_ENV === "production") {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, [router.location]);

	return (
		<ConnectedRouter history={history}>
			<Switch>
				<Route exact={true} path="/sign_in" component={SignInPage}/>
				<Route exact={true} path="/sign_up" component={SignUpPage}/>
				<Route exact={true} path="/activate/:id" component={SignUpActivatePage}/>
				<Route exact={true} path="/sign_up/temporary/" component={SignUpTemporaryPage}/>
				<Route exact={true} path="/sign_up/invite/:id" component={SignUpTemporaryPage}/>
				<Route exact={true} path="/reset/password/" component={PassWordResetPage}/>
				<Route exact={true} path="/reset/password/send" component={SendResetMailPage}/>
				<Route exact={true} path="/reset/password/register" component={PassWordResetRegisterPage}/>
				<Route exact={true} path="/reset/password/registered" component={PassWordRegisteredPage}/>
				<Route exact={true} path="/unsubscribe_mail" component={Routes}/>
				<Route exact={true} path="/terms" component={Routes}/>
				<Route exact={true} path="/stcl" component={Routes}/>
				<Route exact={true} path="/policy" component={Routes}/>
				<Route exact={true} path="/links" component={Routes}/>
				<Route path="/app/help" component={Help}/>
				<Route path="/help" component={Routes}/>
				<Route path="/" component={AppRoutes}/>
			</Switch>
		</ConnectedRouter>
	);
}

export default connect(null)(withRoot(withWidth()(App)));
