import React from 'react';
import CompanyMediaList from "./CompanyMediaList";
import HarvestMediaList from "./HarvestMediaList";

//style
import style from "../help.module.scss";

const Media = () => {

	return (
		<article className={style.root}>
			<section><a id="1" className={style.anchor}/><CompanyMediaList/></section>
			<section><a id="2" className={style.anchor}/><HarvestMediaList/></section>
		</article>
	);
};

export default Media;
