import React from "react";
import {useSelector, useDispatch} from "react-redux";

// material-ui
import {makeStyles} from "@material-ui/core/styles";
import {Dialog, DialogActions, DialogTitle, DialogContent, Button, Typography} from "@material-ui/core";

//component
import AddRadarWordForm from "../../Form/AddRadarWordForm";

//action
import * as ActionCreators from '../../../actions/Radar/ActionCreator';


interface AddRadarWordDialogProps {
    isOpen: boolean,
    closeHandle: () => void,
}

export const AddRadarWordDialog: React.FC<AddRadarWordDialogProps> = ({isOpen, closeHandle}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleSubmit = (values) => {
        const word = values.radarWord;
        const excludeWord = values.excludeWord ? values.excludeWord : "";
        dispatch(ActionCreators.postCreateRadarWord.request({
            radarWord: word,
            excludeWord: excludeWord
        }))
        closeHandle()
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeHandle}
        >
            <DialogTitle>
                検索ワードを追加する
            </DialogTitle>
            <DialogContent className={classes.content}>
                <AddRadarWordForm onSubmit={handleSubmit}/>
            </DialogContent>
            <DialogActions className={classes.action}>
                <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={closeHandle}
                >
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles({
    content: {
        padding: 20,
        fontSize: 14,
        minWidth: 424,
    },
    action: {
        padding: '15px 10px'
    },
});
