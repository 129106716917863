import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/editor/block/image01.png';
import Image02 from '../../../assets/help/editor/block/image02.png';
import Image03 from '../../../assets/help/editor/block/image03.png';

const Block = () => {
	return (
		<div className={style.box}>
			<Title title={"4. ブロックの登録・挿入・削除"}/>
			<Typography gutterBottom variant={"body1"}>
				登録したいテキストを選択します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				メニューのアイコンをクリックすると、ポップアップが表示されます。<br/>
				「選択中のテキストを保存」ボタンを押すと、リストに追加されます。
			</Typography>
			<Typography gutterBottom variant={"body1"}>
				リストの挿入ボタンから、カーソル位置に挿入されます。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				ヘッダーフッターも同様に登録・挿入が可能です。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
		</div>
	);
};

export default Block
