import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";

// components
import {
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import BaseMenu from "./BaseMenu";
import AppDialog from "../Common/AppDialog";
import GroupSelect from "./GroupSelect";

// config
import {getMenuList} from "./menuList";

// icons
import ImageLogoIcon from "../../assets/menu/logo_icon.svg";

// state
import {RootState} from "../../reducers";
const groupSelector = (state: RootState) => state.group;
const companySelector = (state: RootState) => state.company;
const ticketSelector = (state: RootState) => state.ticket;

const Menu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const group = useSelector(groupSelector);
  const company = useSelector(companySelector);
  const {default: defaultTickets, non_default: nonDefaultTickets} = useSelector(
    ticketSelector
  ).qty;
  const availableTickets =
    defaultTickets.available + nonDefaultTickets.available;
  const reservedTickets = defaultTickets.reserved + nonDefaultTickets.reserved;
  const currentGroup = group.results.find(
    ({uuid}) => uuid === group.selectedId
  );
  const menuList = getMenuList(
    group.selectedId,
    company.prefix,
    currentGroup && currentGroup.prefix ? currentGroup.prefix : ""
  );

  const [logoutDialog, setLogoutDialog] = useState(false);

  const handleLogout = () => {
    setLogoutDialog(false);
    dispatch(push("/sign_in/"));
  };

  return (
    <section className={classes.section}>
      <List className={classes.list}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <ListItem className={classes.titleItem}>
            <ListItemIcon
              className={classes.icon}
              onClick={() => dispatch(push("/"))}
            >
              <img src={ImageLogoIcon} className={classes.img} />
            </ListItemIcon>
            <GroupSelect />
          </ListItem>
          <Divider />
          {menuList.map((item, i) => (
            <BaseMenu
              name={item.name}
              img={item.img}
              path={item.path}
              subMenu={item.subMenu}
              key={i}
              handleOpenLogoutDialog={() => setLogoutDialog(true)}
            />
          ))}
        </Drawer>
      </List>
      <Box className={classes.planInfo}>
        <Box className={classes.planInner}>
          <Typography variant="h6" className={classes.planName}>
            {company.plan.name && `${company.plan.name}プラン`}
          </Typography>
          <NavLink to="/setting/plan/">
            <Button
              variant="outlined"
              color="primary"
              disabled={!company.plan.name}
            >
              変更
            </Button>
          </NavLink>
        </Box>
        <Box className={classes.planInner}>
          <Typography variant="h6" className={classes.tickets}>
            今月の残り配信数：{availableTickets}回<br />
            （予約中：{reservedTickets}）
          </Typography>
          <NavLink to="/setting/plan/">
            <Button
              variant="outlined"
              color="primary"
              disabled={!company.plan.name}
            >
              追加
            </Button>
          </NavLink>
        </Box>
      </Box>
      <AppDialog
        isOpen={logoutDialog}
        closeHandle={() => setLogoutDialog(false)}
        mainHandle={handleLogout}
        text="ログアウトしますか"
        ButtonText="ログアウト"
      />
    </section>
  );
};

export default Menu;

const useStyles = makeStyles({
  links: {
    padding: "3px 30px"
  },
  link: {
    color: "#6d7470",
    fontSize: 12,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  section: {
    height: "100%",
    borderRight: "1px solid #d5dbd5",
    width: 240
  },
  drawer: {
    width: 240,
    flexShrink: 0
  },
  drawerPaper: {
    width: 240,
    backgroundColor: "#f3f9f2",
    top: "auto",
    height: "calc(100% - 123px)"
  },
  list: {
    backgroundColor: "#f3f9f2",
    height: "calc(100% - 123px)"
  },
  img: {
    width: 26,
    maxHeight: 26
  },
  planInfo: {
    borderTop: "1px solid #d5dbd5",
    padding: "8px 10px",
    backgroundColor: "#fff",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    width: 239
  },
  planName: {
    width: 130,
    fontSize: 12
  },
  planInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 5px"
  },
  titleItem: {
    display: "flex",
    width: "100%",
    padding: "0px 12px",
    alignItems: "center"
  },
  icon: {
    marginRight: 5,
    padding: 10,
    "&:hover": {
      cursor: "pointer"
    }
  },
  tickets: {
    fontSize: 12
  }
});
