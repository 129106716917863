const BASE_URL = 'https://storage.googleapis.com'
const STG_BUCKET_NAME = 'harvest_press_stg'
const PROD_BUCKET_NAME = 'harvest_press'

let BUCKET_NAME
switch (process.env.REACT_APP_TARGET_ENV) {
  case "local":
  case "development":
  case "staging":
    BUCKET_NAME = STG_BUCKET_NAME
    break
  case "production":
    BUCKET_NAME = PROD_BUCKET_NAME
    break
  default:
    BUCKET_NAME = STG_BUCKET_NAME
}
const GCS_URL = `${BASE_URL}/storage/v1/b/${BUCKET_NAME}/o`
const GCS_UPLOAD_URL = `${BASE_URL}/upload/storage/v1/b/${BUCKET_NAME}/o?uploadType=media&name=`

const generateFolder = (folders: string[]) => {
  let folderStructure = ''

  folders.forEach(folder => {
    folderStructure += `${folder}/`
  })
  return folderStructure
}

const generateGcsUrl = (requestType: 'upload' | 'delete' | 'update' | 'list') => (folders: string[], fileName?: string) => {
  const folder = generateFolder(folders)

  switch (requestType) {
    case 'upload':
      return `${GCS_UPLOAD_URL}${folder}${fileName}`
    case 'delete':
    case 'update':
      return `${GCS_URL}/${folder}${fileName}`
    case 'list':
      return `${GCS_URL}/${folder}`
    default:
      return ''
  }
}

export const generateUploadUrl = generateGcsUrl('upload')
export const generateDeleteUrl = generateGcsUrl('delete')
export const generateUpdateUrl = generateGcsUrl('update')
export const generateListUrl = generateGcsUrl('list')
