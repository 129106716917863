import ActionType from './ActionType';
import { PressReleaseAccessDataRequestModel } from "../../model/PressReleaseFirebaseModel";
import { createAsyncAction } from 'typesafe-actions';

export const postPressReleaseAccessData = createAsyncAction(
	ActionType.POST_PRESS_RELEASE_ACCESS_DATA_REQUEST,
	ActionType.POST_PRESS_RELEASE_ACCESS_DATA_SUCCEEDED,
	ActionType.POST_PRESS_RELEASE_ACCESS_DATA_FAILED,
)<PressReleaseAccessDataRequestModel, void, Error>();

export const deletePressReleaseAccessData = createAsyncAction(
	ActionType.DELETE_PRESS_RELEASE_ACCESS_DATA_REQUEST,
	ActionType.DELETE_PRESS_RELEASE_ACCESS_DATA_SUCCEEDED,
	ActionType.DELETE_PRESS_RELEASE_ACCESS_DATA_FAILED,
)<PressReleaseAccessDataRequestModel, void, Error>();

export const postPressReleaseEditorData = createAsyncAction(
	ActionType.POST_PRESS_RELEASE_EDITOR_DATA_REQUEST,
	ActionType.POST_PRESS_RELEASE_EDITOR_DATA_SUCCEEDED,
	ActionType.POST_PRESS_RELEASE_EDITOR_DATA_FAILED,
)<void, void, Error>();

export const deletePressReleaseEditorData = createAsyncAction(
	ActionType.DELETE_PRESS_RELEASE_EDITOR_DATA_REQUEST,
	ActionType.DELETE_PRESS_RELEASE_EDITOR_DATA_SUCCEEDED,
	ActionType.DELETE_PRESS_RELEASE_EDITOR_DATA_FAILED,
)<string, void, Error>();

export const disconnectRealtimeDatabase = createAsyncAction(
	ActionType.DISCONNECT_REALTIME_DATABASE_REQUEST,
	ActionType.DISCONNECT_REALTIME_DATABASE_SUCCEEDED,
	ActionType.DISCONNECT_REALTIME_DATABASE_FAILED
)<PressReleaseAccessDataRequestModel, void, Error>();
