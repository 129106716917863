import ActionType from './ActionType';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

//model
import {
	RadarWordModel,
	RadarResultsRequestModel,
	RadarResultsResponseContentModel,
	RadarSelectedModel,
	RadarDisplayMode,
	MappedRadarResultModel,
	SelectedContentModel,
	PostCreateRadarWordRequestModel,
	DeleteRadarResultRequestModel,
} from "../../model/RadarModel";

export const getRadarWords = createAsyncAction(
	ActionType.GET_RADAR_WORDS_REQUEST,
	ActionType.GET_RADAR_WORDS_SUCCEEDED,
	ActionType.GET_RADAR_WORDS_FAILED,
)<void, Array<RadarWordModel>, Error>();

export const getRadarResults = createAsyncAction(
	ActionType.GET_RADAR_RESULTS_REQUEST,
	ActionType.GET_RADAR_RESULTS_SUCCEEDED,
	ActionType.GET_RADAR_RESULTS_FAILED,
)<RadarResultsRequestModel, Array<RadarResultsResponseContentModel>, Error>();

export const setSelectedDate = createAsyncAction(
	ActionType.SET_RADAR_SELECTED_DATE,
	'',
	''
)<RadarSelectedModel, void, void>();

export const setDisplayItems = createAsyncAction(
	ActionType.SET_RADAR_DISPLAY_ITEMS,
	'',
	''
)<Array<MappedRadarResultModel>, void, void>();

export const setDisplayMode = createAsyncAction(
	ActionType.SET_RADAR_DISPLAY_MODE,
	'',
	''
)<RadarDisplayMode, void, void>();

export const setTargetIndex = createAsyncAction(
	ActionType.SET_TARGET_INDEX,
	'',
	''
)<number, void, void>();

export const setTargetWordId = createAsyncAction(
	ActionType.SET_TARGET_WORD_ID,
	'',
	''
)<number, void, void>();

export const setDatePickerStartSelected = createAsyncAction(
	ActionType.SET_DATE_PICKER_START_SELECTED,
	'',
	''
)<SelectedContentModel, void, void>();

export const setDatePickerEndSelected = createAsyncAction(
	ActionType.SET_DATE_PICKER_END_SELECTED,
	'',
	''
)<SelectedContentModel, void, void>();

export const radarWordDelete = createAsyncAction(
	ActionType.DELETE_RADAR_WORD_REQUEST,
	ActionType.DELETE_RADAR_WORD_SUCCEEDED,
	ActionType.DELETE_RADAR_WORD_FAILED,
)<number, void, Error>();

export const postCreateRadarWord = createAsyncAction(
	ActionType.POST_CREATE_RADAR_WORD_REQUEST,
	ActionType.POST_CREATE_RADAR_WORD_SUCCEEDED,
	ActionType.POST_CREATE_RADAR_WORD_FAILED,
)<PostCreateRadarWordRequestModel, void, Error>();

export const patchUpdateRadarWord = createAsyncAction(
	ActionType.PATCH_RADAR_WORD_REQUEST,
	ActionType.PATCH_RADAR_WORD_SUCCEEDED,
	ActionType.PATCH_RADAR_WORD_FAILED,
)<RadarWordModel, void, Error>();

export const deleteRadarResult = createAsyncAction(
	ActionType.DELETE_RADAR_RESULT_REQUEST,
	ActionType.DELETE_RADAR_RESULT_SUCCEEDED,
	ActionType.DELETE_RADAR_RESULT_FAILED,
)<DeleteRadarResultRequestModel, void, Error>();

export const setIsOverWord = createStandardAction(
	ActionType.SET_IS_OVER_WORD
)<boolean>();
