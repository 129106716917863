import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import style from "./comment.module.scss";

// models
import {
    PressReleaseCommentListModel
} from "../../../../../model/PressReleaseModel";

//action
import * as PressReleaseActionCreators from "../../../../../actions/PressRelease/ActionCreator";

// components
import CommentForm from "../../../../Form/Comment";
import ReplyForm from "../../../../Form/Reply";
import Avatar from "../../../../Common/Avatar";
import DateTime from "../../../../Common/DateTime";
import {Button, Tabs, Tab, makeStyles, IconButton, ListItem, Tooltip} from "@material-ui/core";

// img
import IconComment from "../../../../../assets/icon_comment_green.svg";
import IconPlusComment from "../../../../../assets/editor/panel_plus_comment.svg";

// state
import {RootState} from "../../../../../reducers";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;

const useStyles = makeStyles({
    tabs: {
        height: "4rem",
    },
    tab: {
        fontSize: "1.2rem",
        minWidth: "10rem",
        height: "4rem",
    },
    newCommentButton: {
        margin: "30px auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


interface SortList {
    index: string,
    comment: PressReleaseCommentListModel
}

const Comment: React.FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = React.useState(0);
    const pressRelease = useSelector(pressReleaseSelector);

    const handleNewComment = () => {
        dispatch(PressReleaseActionCreators.setPressReleaseCommentForm.request({
            position: "",
            quote: "",
            isDisplayForm: true,
        }));
    };

    // tab
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
        if (newValue === 0) {
            dispatch(PressReleaseActionCreators.getPressReleaseCommentList.request());
        } else {
            dispatch(PressReleaseActionCreators.getPressReleaseCommentDoneList.request());
        }
    };

    // useEffect(() => {
    //     let list: SortList[] = [];
    //     pressRelease.comment.list.unDone.map(comment => {
    //         const index = pressRelease.detail.body.html.indexOf(`id="${comment.position}"`);
    //         list.push({index, comment});
    //     })
    //     const sort: SortList[] = _.orderBy(list, ['index'], ['asc']);
    //     setCommentList(sort);
    // }, [pressRelease.comment.list.unDone]);


    const noComment = <Button variant="outlined" color="primary" className={classes.newCommentButton} onClick={handleNewComment} >
                        <img src={IconPlusComment}/>コメントを追加
                        </Button>

    return (
        <div className={style.root}>
            <Tabs
                classes={{root: classes.tabs}}
                value={tabIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="未解決" classes={{root: classes.tab}}/>
                <Tab label="解決済み" classes={{root: classes.tab}}/>
            </Tabs>

            <div className={style.actions}>
                <Tooltip title="新規コメント" arrow>
                    <IconButton onClick={handleNewComment} className={style.iconMenu}>
                        <img src={IconPlusComment}/>
                    </IconButton>
                </Tooltip>
            </div>

            {pressRelease.comment.form.isDisplayForm &&
            <>
              <div className={style.form}><CommentForm/></div>
              <div className={style.overlay}/>
            </>
            }

            {tabIndex === 0 ?
                <div>
                    {pressRelease.comment.list.unDone.length ?
                        <ul className={style.list}>
                            {pressRelease.comment.list.unDone.map((row, index) => {
                                return <li key={index}>
                                    <CommentItem
                                        comment={row}
                                        handleSubmit={""}
                                        isDone={row.is_done}/>
                                </li>;

                            })}
                        </ul>
                        : noComment}
                </div>
                :
                <ul className={style.list}>
                    {pressRelease.comment.list.done.map((row, index) => (
                        <li key={index}>
                            <CommentItem
                                comment={row}
                                handleSubmit={""}
                                isDone={row.is_done}/>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
};

export default Comment;


interface CommentItemProps {
    comment,
    handleSubmit,
    isDone: boolean
}


const CommentItem: React.FC<CommentItemProps> = ({comment, handleSubmit, isDone}) => {

    const dispatch = useDispatch();
    const [display, setDisplay] = React.useState(false);
    const [done, setDone] = React.useState(isDone);
    const pressRelease = useSelector(pressReleaseSelector);
    const me = useSelector(meSelector);
    const selectId = pressRelease.comment.select.id;

    const handleChange = () => {
        dispatch(PressReleaseActionCreators.patchPressReleaseComment.request({
            id: comment.id,
            is_done: !isDone,
        }));
        setDone(!done);
    };

    const handleHighlight = () => {
        // if (comment.position !== selectId || !selectId) {
        // 	dispatch(PressReleaseActionCreators.setPressReleaseCommentHover.request(comment.position));
        // }
    };
    const handleHighlightOut = () => {
        //dispatch(PressReleaseActionCreators.setPressReleaseCommentHover.request(""));
    };

    return (
        <div className={classNames(style.items, comment.position === selectId ? style.select : "")}
             onMouseOver={() => handleHighlight()}
             onMouseLeave={() => handleHighlightOut()}>
            {display ?
                <div>
                    <div className={style.header}>
                        {!isDone &&
                        <div className={style.button}>
                          <Button color="primary" onClick={handleChange} size="medium">
                            解決済みにする
                          </Button>
                        </div>}
                    </div>
                    <div className={style.body} onClick={() => setDisplay(false)}>
                        <Avatar src={comment.user.img} name={comment.user.last_name} size="small"
                                color={comment.user.color_cd}/>
                        <div className={style.bodyContent}>
                            <div className={style.info}>
                                <span className={style.name}>{comment.user.last_name} {comment.user.first_name}</span>
                                <span className={style.date}><DateTime datetime={comment.created_at}/></span>
                            </div>
                            <div className={style.text} dangerouslySetInnerHTML={{__html: `${comment.text}`}}></div>
                        </div>
                    </div>
                    {comment.reply.map((item, index) => (
                        <ReplyItem
                            key={index}
                            item={item}/>
                    ))}
                    {!isDone &&
                    <div className={classNames(style.body, style.replyForm)}>
                      <Avatar src={me.img} name={me.last_name} size="small" color={me.color_cd}/>
                      <div className={style.bodyContent}>
                        <ReplyForm onSubmit={handleSubmit} id={comment.id}/>
                      </div>
                    </div>
                    }
                </div>
                :
                <div onClick={() => setDisplay(true)}>
                    <div className={style.body}>
                        <Avatar src={comment.user.img} name={comment.user.last_name} size="small"
                                color={comment.user.color_cd}/>
                        <div className={style.bodyContent}>
                            <div className={style.text} dangerouslySetInnerHTML={{__html: `${comment.text}`}}></div>
                        </div>
                    </div>
                    {(comment.reply.length > 0) &&
                    <p className={style.reply}><img src={IconComment}/>{comment.reply.length}</p>}
                </div>}
        </div>
    );
};


const ReplyItem = ({item}) => {

    return (
        <div className={style.body}>
            <div className={style.bodyImage}>
                <Avatar src={item.user.img} name={item.user.last_name} size="small" color={item.user.color_cd}/>
            </div>
            <div className={style.bodyContent}>
                <div className={style.info}>
                    <span className={style.name}> {item.user.last_name} {item.user.first_name}</span>
                    <span className={style.date}><DateTime datetime={item.created_at}/></span>
                </div>
                <p className={style.text}>{item.text}</p>
            </div>
        </div>
    );
};
