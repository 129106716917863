import {AnalyticsModel, MediaModel, OpenMailModel} from "../model/AnalyticsModel";
import createReducer from "./createReducer";
import AnalyticsActionType from "../actions/Analytics/ActionType";
import AnalyticsAction from "../actions/Analytics/Action"


const initialState: AnalyticsModel = {
    summary: {
        openMail: {
            label: [],
            media_count: 0,
            percentage: 0
        },
        media: {
            mediaCount:0,
            customMediaCount:0,
            mediaType: {
                label: [],
                count: [],
            }
        },
        category: {
            label: [],
            count: [],
        }
    },
    detail: {
        press_id: "",
        name: "",
        released_at: null,
        category: [],
        mediaList: [],
        media: {
            mediaCount:0,
            customMediaCount:0,
            mediaType: {
                label: [],
                count: [],
            },
        },
        openMail: {
            custom_media:0,
            media:0
        },
    },
    loading: false
}

export const analytics = createReducer<AnalyticsModel>(initialState, {

        [AnalyticsActionType.GET_ANALYTICS_DETAIL_REQUEST](state: AnalyticsModel, action: AnalyticsAction) {
            return {
                ...state,
                loading: true
            }
        },


        [AnalyticsActionType.GET_ANALYTICS_DETAIL_SUCCEEDED](state: AnalyticsModel, action: AnalyticsAction) {
            const result: any = action.payload
            return {
                ...state,
                detail: {
                    press_id:result.detail.uuid,
                    name: result.detail.name,
                    released_at: result.detail.released_at,
                    category: result.detail.category,
                    media: {
                        mediaCount:result.detail.media_count,
                        customMediaCount:result.detail.custom_media_count,
                        mediaType:result.media_type.media_type,
                    },
                    mediaList: result.custom_media_list,
                    openMail:{
                        custom_media:result.open_mail.custom_media.percentage,
                        media:result.open_mail.media.percentage,
                    }
                },
                loading: false
            }
        },


        [AnalyticsActionType.GET_ANALYTICS_SUMMARY_REQUEST](state: AnalyticsModel, action: AnalyticsAction) {
            return {
                ...state,
                loading: true
            }
        },

        [AnalyticsActionType.GET_ANALYTICS_SUMMARY_SUCCEEDED](state: AnalyticsModel, action: AnalyticsAction) {
            const result: any = action.payload
            return {
                ...state,

                summary: {
                    openMail: result.open_mail,
                    media: {
                        mediaType: {
                            label: result.media.media_type.label,
                            count: result.media.media_type.count
                        },
                    },
                    category: result.category
                },
                loading: false
            }

        },
        [AnalyticsActionType.GET_OPEN_MAIL_REQUEST](state: AnalyticsModel, action: AnalyticsAction) {
            return {
                ...state,
                detail:{
                    openMail:{
                        percentage:"-"
                    }
                },
                loading: true
            }
        },
        [AnalyticsActionType.GET_OPEN_MAIL_SUCCEEDED](state: AnalyticsModel, action: AnalyticsAction) {
            const result: any = action.payload
            return {
                ...state,
                detail:{
                    openMail:result
                },
                loading: false
            }
        },
    }
) 
