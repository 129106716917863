enum CompanyActionType {
	GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST",
	GET_COMPANY_SUCCEEDED = "GET_COMPANY_SUCCEEDED",
	GET_COMPANY_FAILED = "GET_COMPANY_FAILED",
	POST_COMPANY_REQUEST = "POST_COMPANY_REQUEST",
	POST_COMPANY_SUCCEEDED = "POST_COMPANY_SUCCEEDED",
	POST_COMPANY_FAILED = "POST_COMPANY_FAILED",
	PATCH_COMPANY_REQUEST = "PATCH_COMPANY_REQUEST",
	PATCH_COMPANY_SUCCEEDED = "PATCH_COMPANY_SUCCEEDED",
	PATCH_COMPANY_FAILED = "PATCH_COMPANY_FAILED",
	PATCH_LOGO_REQUEST = "PATCH_LOGO_REQUEST",
	PATCH_LOGO_SUCCEEDED = "PATCH_LOGO_SUCCEEDED",
	PATCH_LOGO_FAILED = "PATCH_LOGO_FAILED",
	SET_IS_FREE_PLAN_EXPIRED = "SET_IS_FREE_PLAN_EXPIRED",
}

export default CompanyActionType;

