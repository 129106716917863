import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import store from 'store';
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import { useFirebaseConnect } from "react-redux-firebase";
import 'firebase/database';

// action
import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";
import * as ReservePressReleaseActionCreators from "../../actions/PressReleaseReserve/ActionCreator";
import * as MediaActionCreators from "../../actions/Media/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../actions/PressReleaseFirebase/ActionCreator";

// components
import Layout from "./Layout";
import Reserve from "../../components/PressRelease/Reserve";

// util
import {createPressReleaseURL} from "../../util/Status";
import {checkEditRole, checkReviewRole} from "../../util/checkAuthority";

// config
import {PAGE_RESERVE} from "../../config/page_type";
import {STATUS_ACCEPT, STATUS_PUBLIC_REJECT} from "../../config/status_type";

// state
import {RootState} from "../../reducers";
const groupSelector = (state: RootState) => state.group;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;
const routerSelector = (state: RootState) => state.router;
const firebaseDataSelector = (state: RootState) => state.firebase.data;

type PageProps = {} & RouteComponentProps<{ groupId: string, pressReleaseId: string }>;

const ReservePage: React.FC<PageProps> = props => {

    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const group = useSelector(groupSelector);
    const me = useSelector(meSelector);
    const route = useSelector(routerSelector);
    const accessData = useSelector(firebaseDataSelector);
    const {press_id, status} = pressRelease.detail;

    // ウィンドウorタブを削除した時に、該当するユーザーの情報をdbから削除するのに必要
    useEffect(() => {
        if ((status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) && accessData && accessData[press_id] && accessData[press_id].reserve) {
            dispatch(PressReleaseFirebaseActionCreators.disconnectRealtimeDatabase.request({
                page: "reserve",
                press_id: press_id,
                user_id: me.uuid,
            }));
        }
    }, [document.querySelectorAll("#root")]);

    useEffect(()=> {
        const pressReleaseId = props.match.params.pressReleaseId
        if (pressReleaseId){
            dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({press_id: pressReleaseId}));
            dispatch(MediaActionCreators.getMediaCategories.request({ offset: 0, limit: 1500 }));
        }
        dispatch(PressReleaseActionCreators.setPressReleasePageType.request(PAGE_RESERVE));
    }, []);

    useEffect(()=> {
        if (me.uuid && pressRelease.detail.fetched === true) {
            dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(checkEditRole(pressRelease, me)));
            dispatch(PressReleaseActionCreators.setPressReleaseReviewRole.request(checkReviewRole(pressRelease.detail.review.member, me)));
        }
    }, [me, pressRelease.detail, pressRelease.creators]);

    useFirebaseConnect(`${pressRelease.detail.press_id}/reserve`);

    // RealtimeDatabaseにアクセス時間を記録する
    useEffect(() => {
        if (press_id && (status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) && me.uuid) {
            dispatch(PressReleaseFirebaseActionCreators.postPressReleaseAccessData.request({
                page: "reserve",
                press_id : press_id,
                user_id: me.uuid
            }));
        }
    }, [press_id, status, me.uuid]);

    // ブラウザバックで前ページに戻った場合に、RealtimeDatabaseへアクセスデータを削除しにいくのに必要
    useEffect(() => {
        if (route.action === "POP" && !route.location.pathname.includes("/reserve")) {
            if (status === STATUS_ACCEPT || status === STATUS_PUBLIC_REJECT) {
                dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
                    page: "reserve",
                    press_id : press_id,
                    user_id: me.uuid
                }));
            }

            // 編集可能なPRに入った後、編集不可のPRに入った際、dbに書き込まないようにするためにdispatchする
            dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(false));
        }
    }, [route]);

    // useEffect(()=> {
    //     const {press_id, status}  = pressRelease.detail
    //     if (group.selectedId && press_id) {
    //         console.log(status)
    //         dispatch(push(createPressReleaseURL(group.selectedId, press_id, status)))
    //     }
    // }, [group.selectedId, pressRelease.detail.status]);

    return (
        <Layout page={PAGE_RESERVE}>
            <Reserve/>
        </Layout>
    );
};

export default ReservePage;
