import React from "react";

//component
import {TopicListItem} from "./ListItem";

//model
import {ProceedingsDataContentModel} from "../../../model/TopicModel";

//style
import style from "./topicList.module.scss";

interface Props {
    list: Array<ProceedingsDataContentModel>
}

export const TopicListLayout: React.FC<Props> = ({list}) => {

    return (
        <ul className={style.topicListRoot}>
            {list.map((item, index) => (
                <TopicListItem item={item} key={index}/>
            ))}
        </ul>
    );
};
