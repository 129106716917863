import React from 'react';

// component
import { makeStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import TextField from '../../Field/TextFieldUnderline';
import RadioAnswer from '../Answer/Radio';

// icons
import ImagePDF from "../../../assets/icon_pdf.svg";

// model
import { BriefingBodyModel, FileDataModel } from '../../../model/BriefingModel';

// config
import { QUERY_TYPES } from '../../../config/briefing';

interface TableProps {
    body: BriefingBodyModel,
    id: string,
}

const AnswerTable: React.FC<TableProps> = ({ body, id }) => {
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellQuestion}>
                        <Typography variant='h6' color='primary'>Q</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCellAnswer}>
                        <Typography variant='h6' color='primary'>A</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <AnswerTableBody body={body} />
        </Table>
    )
}

export default AnswerTable;

interface BodyProps {
    body: BriefingBodyModel,
}

const AnswerTableBody: React.FC<BodyProps> = ({ body }) => {
    const classes = useStyles();

    return (
        <TableBody>
            {body.queries.map((query, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell className={classes.tableCellQuestion}>
                            <Typography variant='body1' className={classes.tableBodyText}>{query.question}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellAnswer}>
                            {query.type === QUERY_TYPES.TEXT &&
                                <div className={classes.preventTouch}>
                                    <TextField value={query.text} multiline handleChange />
                                </div>}
                            {query.type === QUERY_TYPES.RADIO &&
                                <div className={classes.preventTouch}>
                                    <RadioAnswer value={query.selected} choices={query.choices} handleChange />
                                </div>}
                            {query.type === QUERY_TYPES.IMAGE &&
                                <ImageAnswer file={query.file} />}
                        </TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
    );
}

const useStyles = makeStyles({
    tableCellQuestion: {
        width: '50%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#f8fbf9',
    },
    tableCellAnswer: {
        width: '50%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    tableBodyText: {
        fontSize: 12,
    },
    preventTouch: {
        pointerEvents: 'none',
    },
    fileDownload: {
        display: 'flex',
        wordBreak: "break-all",
        background: "#F8FBF9",
        borderRadius: 4,
        padding: 10,
        cursor: "pointer",
        border: "1px solid #ccc",
    },
    icon: {
        marginRight: 10,
    },
})

interface IProps {
    file: FileDataModel | File | null,
}

const ImageAnswer: React.FC<IProps> = ({ file }) => {
    const classes = useStyles();

    return (
        <div>
            {file ?
                <a href={"path" in file ? file.path : ''} className={classes.fileDownload}>
                    <img src={ImagePDF} className={classes.icon} />
                    <Typography variant={"body1"}>
                        {file.name}
                    </Typography>
                </a>
                : <Typography>回答なし</Typography>}
        </div>
    );
}
