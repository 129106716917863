import ImageMedia from "../../assets/menu/media.svg";
import ImageMember from "../../assets/menu/members.svg";
import ImageSettings from "../../assets/menu/settings.svg";
import ImageBriefing from "../../assets/menu/briefing_sheet.svg";
import ImageTopic from "../../assets/menu/icon_topic.svg";
import ImageDashboard from "../../assets/menu/analytics_outlined.svg";
import ImagePressKit from "../../assets/menu/press_kit.svg";
import ImagePressRelease from "../../assets/menu/press_release.svg";
import ImageRadar from "../../assets/menu/radar_icon.svg";
import ImageAccount from "../../assets/menu/account_circle.svg";
import ImageHelp from "../../assets/menu/help.svg";

interface MenuItemModel {
    path: string,
    name: string,
    img: string,
    subMenu?: SubMenuItemModel[],
}

interface SubMenuItemModel {
    path: string,
    name: string,
    isBlank?: boolean,
}

export const getMenuList = (groupId: string, companyPrefix: string, groupPrefix?: string): MenuItemModel[] => {
    return [
        {
            path: "/press_release/",
            name: "プレスリリース",
            img: ImagePressRelease,
            subMenu: [
                {
                    path: `${groupId ? `/press_release/${groupId}/dashboard/` : "/press_release/"}`,
                    name: "ダッシュボード",
                },
                {
                    path: `/press_release/`,
                    name: "作成中",
                },
                {
                    path: `${groupId ? `/press_release/${groupId}/public/` : "/press_release/"}`,
                    name: "配信済み",
                },
                {
                    path: `${groupId ? `/press_release/${groupId}/archive/` : "/press_release/"}`,
                    name: "アーカイブ",
                },
                {
                    path: `${groupId ? `/press_release/${groupId}/labels/` : "/press_release/"}`,
                    name: "ラベル",
                },
                {
                    path: `${companyPrefix ? `https://pr.harvest.site/press_release/${companyPrefix}` : ""}`,
                    name: "企業webページ",
                    isBlank: true,
                },
                {
                    path: `${companyPrefix && groupPrefix ? `https://pr.harvest.site/press_release/${companyPrefix}/projects/${groupPrefix}` : ""}`,
                    name: "プロジェクトwebページ",
                    isBlank: true,
                },
            ]
        },
        {
            path: "/press_kit/",
            name: "プレスキット",
            img: ImagePressKit,
        },
        {
            path: "/briefing/",
            name: "ブリーフィング",
            img: ImageBriefing,
            subMenu: [
                {
                    path: "/briefing/",
                    name: "ブリーフィング",
                },
                {
                    path: `${groupId ? `/briefing/${groupId}/received/` : "/briefing/"}`,
                    name: "回答リクエスト",
                },
            ]
        },
        {
            path: "/topic/",
            name: "未来トピック",
            img: ImageTopic,
        },
        {
            path: "/radar/",
            name: "記事化レーダー",
            img: ImageRadar,
        },
        {
            path: "/media/",
            name: "メディア",
            img: ImageMedia,
            subMenu: [
                {
                    path: "/media/",
                    name: "企業メディアリスト",
                },
                {
                    path: "/media/list/",
                    name: "HARVEST配信リスト",
                },
            ]
        },
        {
            path: "/member/",
            name: "メンバー",
            img: ImageMember,
        },
        {
            path: "/other/",
            name: "その他",
            img: ImageHelp,
            subMenu: [
                {
                    path: "/help/press_release#1",
                    name: "ヘルプ",
                    isBlank: true,
                },
                {
                    path: "/",
                    name: "HARVESTでできること",
                },
                {
                    path: "/features/",
                    name: "機能一覧",
                },
                {
                    path: "/terms",
                    name: "利用規約",
                    isBlank: true,
                },
                {
                    path: "/stcl",
                    name: "プライバシーポリシー",
                    isBlank: true
                },
                {
                    path: "https://harvest.site/contact",
                    name: "お問い合わせ",
                    isBlank: true
                }
            ]
        }
    ]
}
