import { SortHistoryContentModel } from "../model/PressKitModel";

const sortHistoryInterpretation = (sortHistory: Array<SortHistoryContentModel>, modelArrayLength: number) => {
  const modelArr = makeInitialModelArray(modelArrayLength);
  sortHistory.forEach(history => {
    const { oldIndex, newIndex } = history;
    arrayInsert(oldIndex, newIndex, modelArr);
  })
  return modelArr
}

const makeInitialModelArray = (length: number) => {
  return Array(length).fill(0).map((v,i)=>i);
}

const arrayInsert = (oldIndex: number, newIndex: number, modelArray: Array<number>) => {
  if (oldIndex > newIndex) {
    modelArray.splice(newIndex, 0, modelArray[oldIndex])
    modelArray.splice(oldIndex + 1, 1)
    return modelArray
  }
  modelArray.splice(newIndex + 1, 0, modelArray[oldIndex])
  modelArray.splice(oldIndex, 1)
  return modelArray
}

interface updateListContentModel {
  currentIndex: number;
  nextIndex: number;
}

export const makeUpdateList = (orderedListLength: number, sortHistory: Array<SortHistoryContentModel>) => {
  const modelArray = sortHistoryInterpretation(sortHistory, orderedListLength);
  const changeList = modelArray.map((element, index) => {
    if (element !== index) {
      return {
        currentIndex: element,
        nextIndex: index
      }
    }
  })
  const filteredChangeList = changeList.filter(v => v)
  return filteredChangeList as Array<updateListContentModel>
}
