import React from 'react';

//component
import Title from '../Title';
import SubTitle from "../SubTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/pressRelease/review/image01.png';
import Image02 from '../../../assets/help/pressRelease/review/image02.png';
import Image03 from '../../../assets/help/pressRelease/review/image03.png';
import Image04 from '../../../assets/help/pressRelease/review/image04.png';
import Image05 from '../../../assets/help/pressRelease/review/image05.png';


const Review = () => {
	return (
		<div className={style.box}>
			<Title title={"4. 承認・修正依頼（確認フロー機能ONの場合）"}/>
			<SubTitle step={1} title={"確認依頼がきているものを表示"}/>
			<Typography gutterBottom variant={"body1"}>
				右上の絞り込み機能から「確認者」で自分の名前を選択し、「条件を適用する」ボタンを押下することで、確認依頼がきているものが表示されます。<br/>
				表示されたカードを押下して、プレスリリースの内容を確認します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<SubTitle step={2} title={"承認する"}/>
			<Typography gutterBottom variant={"body1"}>
				指摘事項がない場合、「承認」ボタンを押下します。<br/>
				全ての確認者が承認すると、依頼者は配信設定に進むことができます。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<SubTitle step={3} title={"指摘事項あり"}/>
			<Typography gutterBottom variant={"body1"}>
				内容を修正した場合、「保存」ボタンで一時保存できます。<br/>
				依頼者に差し戻すには、「修正依頼」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				ダイアログが表示されます。<br/>
				メッセージを入力後「修正依頼を出す」ボタンを押下します。<br/>
				依頼者にメールで通知されます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
			<SubTitle step={4} title={"確認の完了"}/>
			<Typography gutterBottom variant={"body1"}>
				確認が完了すると、右パネルの表示が切り替わります。<br/>
				修正依頼がきている場合で、確認者が内容を修正している場合は、「修正依頼あり」のリンクが表示されます。<br/>
				リンクを押下すると、修正内容確認画面へ遷移します。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image05}/>
			</div>
		</div>
	);
};

export default Review;
