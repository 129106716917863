import { Switch, Route } from "react-router";
import * as React from "react";
import store from 'store'

// page
import AlertDialog from "./components/Common/AlertDialog";
import ErrorDialog from "./components/Common/ErrorDialog";
import DashboardPage from "./pages/Analytics";
import TopicPage from "./pages/Topic";
import RadarPage from "./pages/Radar";
import PressReleasePage from "./pages/PressRelease/";
import SettingPage from "./pages/Setting";
import PressKitPage from "./pages/PressKit";
import BriefingPage from "./pages/Briefing";
import UserPage from "./pages/User";
import MemberPage from "./pages/Member";
import MediaPage from "./pages/Media";
import ResetSendMail from "./pages/Reset/SendMail";
import InitialSetting from "./pages/InitialSetting";
import { BriefingGuestAnswerPage } from "./pages/Briefing/Answer";
import ErrorSorryPage from "./pages/Error/Sorry";
import HomePage from "./pages/Home/Home";
import FeaturesPage from "./pages/Features";



import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import * as GroupActionCreators from "./actions/Group/ActionCreator";
import * as MeActionCreators from "./actions/Me/ActionCreator";
import * as CompanyActionCreators from "./actions/Company/ActionCreator";
import * as PressKitActionCreators from "./actions/PressKit/ActionCreator";
import * as SignActionCreators from "./actions/Sign/ActionCreator";
import * as RadarActionCreators from "./actions/Radar/ActionCreator";
import * as TicketActionCreators from "./actions/Ticket/ActionCreator";
import * as PlanActionCreators from "./actions/Plan/ActionCreator";
import * as OptionActionCreators from "./actions/Option/ActionCreator";


import { RootState } from "./reducers";

import { useFirestoreConnect } from 'react-redux-firebase'
import { configureFirestoreListen } from './config/firebase_config'

const groupSelector = (state: RootState) => state.group;
const firebaseAuthIsEmptySelector = (state: RootState): boolean => state.firebase.auth.isEmpty;
const routerSelector = (state: RootState) => state.router;
const companySelector = (state: RootState) => state.company;

function AuthRoutes() {

	const group = useSelector(groupSelector);
	const company = useSelector(companySelector);
	const firebaseAuthIsEmpty = useSelector(firebaseAuthIsEmptySelector);
	const dispatch = useDispatch();

	useEffect(() => {
		if (firebaseAuthIsEmpty) {
			dispatch(SignActionCreators.firebaseLogin.request());
		}
		dispatch(GroupActionCreators.getGroupList.request());
	}, []);

	useEffect(() => {
		if (group.selectedId) {
			dispatch(MeActionCreators.getMe.request());
			dispatch(CompanyActionCreators.getCompany.request());
			dispatch(PlanActionCreators.getPlanList.request());
			dispatch(OptionActionCreators.getOptionList.request());
			dispatch(RadarActionCreators.getRadarWords.request());
		}
	}, [group.selectedId]);

	useEffect(() => {
		if (!firebaseAuthIsEmpty) {
			dispatch(PressKitActionCreators.syncFirestorePressKit.request({ groupId: group.selectedId }));
		}
	}, [group.selectedId, firebaseAuthIsEmpty])

	useEffect(() => {
		if (company.uuid) {
			dispatch(TicketActionCreators.getTicketQty.request())
		}
	}, [company.uuid, group.selectedId])


	const groupId = store.get('groupId')
	useFirestoreConnect(configureFirestoreListen(groupId))

	return (
		<>
			<AlertDialog />
			<ErrorDialog />
			<Switch>
				<Route exact={true} path="/" component={HomePage} />
				<Route path="/dashboard/" component={DashboardPage} />
				<Route path="/create/" component={DashboardPage} />
				<Route path="/topic/" component={TopicPage} />
				<Route path="/radar/" component={RadarPage} />
				<Route path="/press_release/" component={PressReleasePage} />
				<Route path="/press_kit/" component={PressKitPage} />
				<Route path="/briefing/" component={BriefingPage} />
				<Route path="/user" component={UserPage} />
				<Route exact={true} path="/reset/send_mail/" component={ResetSendMail} />
				<Route path="/initial_setting/" component={InitialSetting} />
				<Route path="/setting/" component={SettingPage} />
				<Route path="/media/" component={MediaPage} />
				<Route path="/member/" component={MemberPage} />
				<Route path="/features/" component={FeaturesPage} />

				<Route exact={true} path="/sorry" component={ErrorSorryPage} />
				<Route exact={true} path="/unauthorized" component={ErrorSorryPage} />
				{/* <Route component={HomePage}/> */}
			</Switch>

		</>
	);
}

function AnonymousRoutes() {
	const dispatch = useDispatch();
	useEffect(() => {
		const token = store.get("token");
		const groupId = store.get("groupId");
		if (!token || !groupId) {
			dispatch(SignActionCreators.firebaseLoginAnonymously.request());
		}
	}, []);

	return (
		<Switch>
			<Route exact={true} path="/form/:formId/" component={BriefingGuestAnswerPage} />
		</Switch>
	);
}

function AppRoutes() {
	const router = useSelector(routerSelector);
	if (router.location.pathname.indexOf('form') >= 0) {
		return (
			<AnonymousRoutes />
		);
	} else {
		return (
			<AuthRoutes />
		);
	}
}

export default AppRoutes;
