import React from "react";

// component
import {Button, Chip, makeStyles, Typography} from "@material-ui/core/";

import {useSelector} from "react-redux";

import {RootState} from "../../../../../reducers";
import AppDialog from "../../../../Common/AppDialog";

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const mediaSelector = (state: RootState) => state.media;

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: "center",
        flex: 1
    },
    tags: {
        marginRight: 10
    },
    category: {
        display: 'flex',
        alignItems: "center",
        marginTop: 5
    },
    chip: {
        marginRight: 5
    },
    button: {
        marginLeft: "auto"
    },
    required: {
        marginLeft: 30,
    },
});


interface IProps {
    handleOpenDialog,
    isLocked: boolean,
}


const Category: React.FC<IProps> = ({handleOpenDialog, isLocked}) => {
    const classes = useStyles();
    const reserve = useSelector(pressReleaseReserveSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const media = useSelector(mediaSelector);
    const [dialog, setDialog] = React.useState(false);

    // プレスリリース編集権限
    let hasEdit = pressRelease.role.edit;
    const {isReserveEdit} = pressRelease.detail;

    return (
        <>
            {reserve.data.categories.length > 0 ?
                <div className={classes.root}>
                    <div className={classes.tags}>
                        <ul className={classes.category}>
                            {reserve.data.categories.map((tag, index) => {
                                const target = media.categories.results.filter(item => item.id === Number(tag))
                                return <li key={index}>
                                    {target.length &&
                                    <Chip
                                      className={classes.chip}
                                      variant={"outlined"}
                                      label={target[0].name}
                                    />
                                    }
                                </li>
                            })}
                        </ul>
                    </div>

                    <Button variant="outlined" size={"small"} color={"primary"} onClick={() => setDialog(true)}
                            className={classes.button} disabled={ !hasEdit || !isReserveEdit || isLocked　}>
                        再設定
                    </Button>
                </div>
                :
                <>
                    <Button variant="contained" color="primary" size={"large"} onClick={() => handleOpenDialog()} disabled={!hasEdit || isLocked}>
                        配信カテゴリを選択
                    </Button>
                    <Typography color={"error"} className={classes.required}>カテゴリを選択してください</Typography>
                </>
            }

            <AppDialog isOpen={dialog} closeHandle={() => setDialog(false)} mainHandle={() => {
                setDialog(false)
                handleOpenDialog()
            }} text='自動選定送信先が変更になりますがよろしいですか？' ButtonText="再設定"/>
        </>
    );
};
export default Category;
