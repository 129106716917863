import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Table,
    TableBody, TableCell, TableRow, TableHead, Typography, IconButton, Tooltip
} from '@material-ui/core';
import Pagination from '../../Common/Pagination';
import CreateLabel from './Create';
import Label from './Label';
import Checkbox from './CheckBox';

// img
import IconClose from '../../../assets/icon_close_gray.svg';

// actions
import * as PressReleaseActionCreators from '../../../actions/PressRelease/ActionCreator';
import * as PressReleaseTagActionCreators from '../../../actions/PressReleaseTag/ActionCreator';

// state
import { RootState } from '../../../reducers';
const pressReleaseTagSelector = (state: RootState) => state.pressReleaseTag;

const TagDialog = ({ isOpen, item, handleClose }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const pressTag = useSelector(pressReleaseTagSelector);
    const { id, name, description, bg_color } = pressTag.form;
    const isCreateOpen = pressTag.form.isOpen;

    const postPressReleaseTagRequest = () => {
        dispatch(PressReleaseTagActionCreators.postPressReleaseTag.request({
            name: name,
            description: description,
            bg_color: bg_color,
        }));
    }

    const handleCreateOpen = () => {
        dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request({ isOpen: true, id: 0 }));
    }

    const handleCreateClose = () => {
        dispatch(PressReleaseTagActionCreators.resetPressReleaseTagForm.request());
    }

    const handlePageChange = (pageNumber) => {
        handleCreateClose();
        dispatch(PressReleaseTagActionCreators.getPressReleaseTagList.request({ offset: pageNumber }));
    }

    return (
        <Dialog open={isOpen} onEscapeKeyDown={() => handleClose()}>
            <DialogTitle className={classes.title} disableTypography>
                <Typography variant='h6'>ラベルを選択してください</Typography>
                <div className={classes.buttons}>
                    <Button variant="outlined" size="medium" color="primary" disabled={isCreateOpen} onClick={handleCreateOpen}>
                        新規作成
                    </Button>
                    <Tooltip title='閉じる' className={classes.close} arrow>
                        <IconButton aria-label="close" onClick={() => handleClose()}>
                            <img src={IconClose} />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent className={classes.content}>
                {isCreateOpen &&
                    <div className={classes.createLabel}>
                        <CreateLabel
                            handleCreateClose={handleCreateClose}
                            handleSave={postPressReleaseTagRequest}
                        />
                    </div>}
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>ラベル</TableCell>
                            <TableCell>説明</TableCell>
                            <TableCell align="right">チェックをつけてください</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pressTag.list.results.map((tag, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ width: '30%' }}>
                                    <div className={classes.labelWrapper}>
                                        <Label tag={tag} />
                                    </div>
                                </TableCell>
                                <TableCell style={{ width: '65%' }}>{tag.description}</TableCell>
                                <TableCell style={{ width: '5%' }} align="right">
                                    <Checkbox item={item} tag={tag} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {pressTag.list.results.length > 0 &&
                    <div className={classes.pagination}>
                        <Pagination
                            activePage={pressTag.list.offset > 0 ? pressTag.list.offset : 1}
                            totalCount={pressTag.list.count}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                }
            </DialogContent>
        </Dialog>
    );
}

export default TagDialog;

const useStyles = makeStyles({
    content: {
        height: 600,
        minWidth: 650,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    tagItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tagInner: {
        margin: '6.5px 0',
        display: 'flex',
    },
    pagination: {
        marginTop: 20,
    },
    createLabel: {
        marginBottom: 5,
    },
    labelWrapper: {
        margin: '6px 0',
    },
    close: {
        marginLeft: 12,
        width: 36,
        height: 36,
    },
    buttons: {
        display: 'flex',
    },
})
