import React from 'react';
import PageTitle from '../PageTitle';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from "../../../assets/help/webPage/image01.png";
import Image02 from "../../../assets/help/webPage/image02.png";
import Image03 from "../../../assets/help/webPage/image03.png";
import Image04 from "../../../assets/help/webPage/image04.png";
import Image05 from "../../../assets/help/webPage/image05.png";
import Image06 from "../../../assets/help/webPage/image06.png";
import Image07 from "../../../assets/help/webPage/image07.png";
import Image08 from "../../../assets/help/webPage/image08.png";
import Image09 from "../../../assets/help/webPage/image09.png";
import Image10 from "../../../assets/help/webPage/image10.png";

const Index = () => {
	return (
		<article className={style.root}>
			<a id="1" className={style.anchor}/>
			<div className={style.box}>
				<PageTitle title={"webページ"}/>
				<Title title={"1.webページ設定"}/>
				<SubTitle step={1} title={"webページ設定画面へ移動する"}/>
				<Typography gutterBottom variant={"body1"}>
					カードのメニューにある「WEBページ設定」ボタンからwebページ設定画面へ移動します。
				</Typography>
				<div className={style.img}>
					<img src={Image01}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					「WEBページ設定」では、プレスリリースのwebページの設定を確認・編集することができます。<br/>
					※ 編集は、プレスリリースが配信済みの場合に行うことができます。
				</Typography>
				<div className={style.img}>
					<img src={Image02}/>
				</div>

				<SubTitle step={2} title={"公開設定をする"}/>
				<Typography gutterBottom variant={"body1"}>
					プレスリリースをwebで公開するか、公開しないかを選択します。<br/>
					公開するを選択すると、webページが自動生成されます。<br/>
					自社のプレスリリースページとしてご活用いただけます。
				</Typography>
				<div className={style.img}>
					<img src={Image03}/>
				</div>

				<SubTitle step={3} title={"webページの情報を設定する(step2で公開するを選択した場合)"}/>
				<Typography gutterBottom variant={"body1"}>
					SNSでシェアした際に表示されるwebページの情報を設定します。
				</Typography>
				<div className={style.img}>
					<img src={Image04}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					① 画像を設定する<br/>
					カメラのアイコンを押下します。
				</Typography>
				<div className={style.img}>
					<img src={Image05}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					「ファイルを選択」ボタンから画像を選択し、「追加」ボタンを押下します。
				</Typography>
				<div className={style.img}>
					<img src={Image06}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					選択した画像が表示されます。
				</Typography>
				<div className={style.img}>
					<img src={Image07}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					② ページのタイトルを入力する
				</Typography>
				<div className={style.img}>
					<img src={Image08}/>
				</div>
				<Typography gutterBottom variant={"body1"}>
					③ ページの概要を入力する
				</Typography>
				<div className={style.img}>
					<img src={Image09}/>
				</div>

				<SubTitle step={4} title={"保存する"}/>
				<Typography gutterBottom variant={"body1"}>
					右上の「保存」ボタンから編集内容を保存することができます。
				</Typography>
				<div className={style.img}>
					<img src={Image10}/>
				</div>
			</div>
		</article>
	);
};

export default Index
