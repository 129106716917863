import React from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import TextField from "../Field/TextField";
import CheckBoxField from "../Field/CheckBoxField";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import style from "./form.module.scss";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";

//state
import {RootState} from "../../reducers";
import {ListItem, Tooltip, Typography, IconButton} from "@material-ui/core";
import { Errors, Values } from "../../model/FormModel";
import PasswordText from "../Common/PasswordText";
import ImageHelp from "../../assets/menu/help.svg";
import {NavLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
const stateSelector = (state: RootState) => state;

const validate = (values: Values) => {
	const errors: Errors = {};
	const requiredFields = [
		'email',
		'tel',
		'password',
		'password2',
		'agree',
	]
	requiredFields.forEach(field => {
		if (!values[field]) {
			errors[field] = '必須です'
		}
	})

	if (values["password"]  !== values["password2"] ) {
		errors["password2"] = 'パスワードが一致しません'
	}

	return errors;
};


const useStyles = makeStyles({
	link: {
		textDecoration: "underline",
		padding: "0 5px",
		fontWeight: "bold"
	}
});


const SignUpForm: React.FC<InjectedFormProps> = ({ handleSubmit, submitting, pristine, error }) => {

	const classes = useStyles();
	const state = useSelector(stateSelector);
	const selector = formValueSelector('SIGN_UP');
	let agree = selector(state, 'agree')
	const label = <Typography variant="caption">
		<Typography variant="caption" color={"primary"}>
			<a target="_blank" href={`/terms`} className={classes.link}>利用規約</a></Typography>に同意する</Typography>

	return (
		<div className={style.root}>
			<form onSubmit={handleSubmit}>
				{error && <p className={style.error}>{error}</p>}
				<ul className={style.panelItem}>
					<li>
						<p className={style.headline}>企業情報</p>
					</li>
					<li>
						<label>企業名</label>
						<div className={style.form}>
							<div className={style.field}>
								<Field name="name" type="text" component={TextField} label={"株式会社m-Lab"}/>
								<div className={style.nav}>
									<NavLink target="_blank" to={`/help/web_page#1`} className={style.link}>プレスリリースweb公開ページ</NavLink>、
									<NavLink target="_blank" to={`/help/press_kit#1`} className={style.link}>プレスキットページ</NavLink>で表示されます。
								</div>
							</div>
						</div>
					</li>
					<li>
						<label>電話番号</label>
						<div className={style.form}>
							<div className={style.field}>
								<Field name="tel" type="text" component={TextField} label={"000-0000-0000"}/>
								<div className={style.nav}>
									<NavLink target="_blank" to={`/help/web_page#1`} className={style.link}>プレスリリースweb公開ページ</NavLink>、
									<NavLink target="_blank" to={`/help/press_kit#1`} className={style.link}>プレスキットページ</NavLink>で表示されます。
								</div>
							</div>
						</div>
					</li>
					<li>
						<label>プレスリリース配信用メールアドレス</label>
						<div className={style.form}>
							<div className={style.field}>
								<Field name="pr_email" type="pr_email" component={TextField} label={"pr@malbs.jp"}/>
								<p className={style.nav}>あとから変更可。</p>
							</div>
						</div>
					</li>
					<li>
						<p className={style.headline}>個人情報<span className={style.nav}>※ HARVEST内で使用します。外部には表示されません</span></p>
					</li>
					<li>
						<label>
							氏名
						</label>
						<div className={style.field}>
							<ul className={style.form}>
								<li><Field name="last_name" type="text" component={TextField} label={"ハーベスト"}/></li>
								<li><Field name="first_name" type="text" component={TextField} label={"太郎"}/></li>
							</ul>
						</div>
					</li>
					<li>
						<label>かな</label>
						<div className={style.field}>
							<ul className={style.form}>
								<li>
									<Field name="last_name_kana" type="text" component={TextField} label={"はーべすと"}/>
								</li>
								<li><Field name="first_name_kana" type="tel" component={TextField} label={"たろう"}/></li>
							</ul>
						</div>
					</li>
					<li>
						<label>個人メールアドレス<br/></label>
						<div className={style.field}>
							<Field name="email" type="text" component={TextField} label="メールアドレス"/>
						</div>
					</li>
					<li>
						<label>パスワード</label>
						<div className={style.form}>
							<Field name="password" type="password" component={TextField} label="パスワード"/>
						</div>

					</li>
					<li>
						<label>確認用パスワード</label>
						<div className={style.form}>
							<Field name="password2" type="password" component={TextField} label="確認用パスワード"/>
						</div>
					</li>
					<li className={style.passwordText}>
						<PasswordText/>
					</li>
					<li className={style.agree}>
						<Field name="agree" component={CheckBoxField} label={label}/>
					</li>
				</ul>
				<div className={style.button}>
					<Button variant="contained" size="large" type="submit" color="primary" disabled={pristine || submitting || !agree}>
						アカウント作成
					</Button>
				</div>
			</form>
		</div>
	);
};

export default reduxForm({
	validate,
	form: "SIGN_UP",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(SignUpForm);
