import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import {
    Box, Checkbox, Divider, Paper, Radio, RadioGroup, Typography
} from '@material-ui/core';
import Label from '../../Tag/Label';

// model
import { PressListFilterModel } from '../../../../model/PressReleaseModel';

//actions
import * as PressReleaseActionCreators from '../../../../actions/PressRelease/ActionCreator';

// config
import { PressFilterTypes } from '../../../../config/press_filter_type';

// state
import { RootState } from '../../../../reducers';
const pressReleaseListSelector = (state: RootState) => state.pressReleaseList;

interface FilterPanelProps {
    items: any[],
    type: PressListFilterModel,
}

const FilterPanel: React.FC<FilterPanelProps> = ({ items, type }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pressList = useSelector(pressReleaseListSelector);

    const handleChange = (value: string, checked: boolean) => {
        if (type === PressFilterTypes.tag) {
            dispatch(PressReleaseActionCreators.setPressReleaseFilter.request({ tag: Number(value) }));
        } else {
            dispatch(PressReleaseActionCreators.setPressReleaseFilter.request({
                [type]: checked ? '' : value
            }));
        }
    }

    return (
        <Paper className={classes.filterPanel} elevation={0}>
            <Typography variant='h6' className={classes.filterTitle}>
                {type === PressFilterTypes.creator && '編集者'}
                {type === PressFilterTypes.reviewer && '確認者'}
                {type === PressFilterTypes.tag && 'ラベル'}
            </Typography>
            <Box className={classes.detailRoot}>
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        {type === PressFilterTypes.tag ?
                            <TagItem checked={pressList.filter[type].indexOf(item.id) >= 0}
                                item={item} handleChange={handleChange} />
                            :
                            item.is_activate &&
                            <MemberItem checked={pressList.filter[type] === item.uuid} item={item} handleChange={handleChange} />}
                    </React.Fragment>
                ))}
            </Box>
        </Paper>
    );
}

export default FilterPanel;

const TagItem = ({ checked, item, handleChange }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.filterItem}>
                <Box>
                    <Label tag={item} />
                </Box>
                <Checkbox
                    checked={checked}
                    onClick={(e) => handleChange(item.id, checked)}
                    value={item.id}
                />
            </div>
        </>
    );
}

const MemberItem = ({ checked, item, handleChange }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.filterItem}>
                <Typography>
                    {item.last_name} {item.first_name}
                </Typography>
                <RadioGroup>
                    <Radio
                        value={item.uuid}
                        checked={checked}
                        onClick={(e) => handleChange(item.uuid, checked)} />
                </RadioGroup>
            </div>
        </>
    );
}

const useStyles = makeStyles({
    filterItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '2px 15px',
    },
    filterTitle: {
        padding: '4px 10px',
        borderBottom: "1px solid #ccc"
    },
    filterPanel: {
        margin: '7px 0',
        width: 200,
    },
    detailRoot: {
        padding: 0,
        maxHeight: 400,
        overflowY: 'scroll',
    },
});
