import createReducer from "./createReducer";
import {combineReducers} from "redux";

//action
import TopicActionType from '../actions/Topic/ActionType';
import TopicAction from '../actions/Topic/Action';
import * as ActionCreators from '../actions/Topic/ActionCreator';

//model
import {
	ProceedingsModel,
	TopicSelectedModel,
	TopicWordSearchResultContentModel
} from "../model/TopicModel";
import {ActionType} from "../model/model";

const initialStateProceedings: ProceedingsModel = {
	isFetched: false,
	data: []
}

const proceedings = createReducer(initialStateProceedings, {
	[TopicActionType.GET_PROCEEDINGS_TOPICS_SUCCEEDED](state: ProceedingsModel, action: TopicAction) {
		return {
			...state,
			isFetched: true,
			data: action.payload
		}
	}
})


const initialStateTopicSelected: TopicSelectedModel = {
	word: '',
	year: 0,
	month: 0
}

const selected = createReducer(initialStateTopicSelected, {
	[TopicActionType.SET_SELECTED_TOPIC_WORD](state: TopicSelectedModel, action: ReturnType<typeof ActionCreators.setSelectedTopicWord.request>) {
		return action.payload;
	}
})

const initialStateRSSResult: Array<TopicWordSearchResultContentModel> = []

const rss = createReducer(initialStateRSSResult, {
	[TopicActionType.POST_TOPIC_WORD_SUCCEEDED](state: Array<TopicWordSearchResultContentModel>, action: ReturnType<typeof ActionCreators.postTopicWord.success>) {
		return action.payload
	}
})

const loading = createReducer(false, {
	[TopicActionType.SET_TOPIC_LOADING](state: boolean, action: ReturnType<typeof ActionCreators.setLoading.request>) {
		return action.payload;
	}
})

export const topic = combineReducers({
    proceedings,
	selected,
	rss,
	loading
});

