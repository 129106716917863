import React from 'react';
import { useSelector } from 'react-redux';

// component
import { makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';

// model
import { BriefingQueryModel } from '../../../model/BriefingModel';

// config
import { QUERY_TYPES } from '../../../config/briefing';

import { RootState } from '../../../reducers';
const BriefingSelector = (state: RootState) => state.briefing;

interface Props {
    query: BriefingQueryModel,
    handleChangeType,
}

const QueryTypeSelect: React.FC<Props> = ({ query, handleChangeType }) => {
    const classes = useStyles();
    const { isSorting } = useSelector(BriefingSelector);

    return (
        <FormControl className={classes.root}>
            <Select
                value={query.type}
                className={classes.select}
                classes={{
                    select: classes.input
                }}
                variant='outlined'
                color='primary'
                disabled={isSorting}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    handleChangeType(String(e.target.value));
                }}>
                <MenuItem value={QUERY_TYPES.TEXT}>テキスト</MenuItem>
                <MenuItem value={QUERY_TYPES.RADIO}>ラジオ</MenuItem>
                <MenuItem value={QUERY_TYPES.IMAGE}>画像</MenuItem>
            </Select>
        </FormControl>
    );
}

export default QueryTypeSelect;

const useStyles = makeStyles({
    select: {
        minWidth: 95,
    },
    root: {
        width: 80,
    },
    input: {
        paddingTop: 10,
        paddingBottom: 10,
    },
})
