import React from "react";
import { NavLink } from "react-router-dom";

// components
import { Typography, Button } from "@material-ui/core";

interface DisabledOptionProps {
    optionName: string,
}

const DisabledOption: React.FC<DisabledOptionProps> = ({ optionName }) => {
    return (
        <>
            <Typography variant="h4" component="h4" gutterBottom>
                {optionName}が無効になっています<br />
                利用するにはオプションの追加が必要です<br />
            </Typography>
            <NavLink exact to={"/setting/plan/"}>
                <Button variant="contained" size="large" color="primary">
                    オプションの追加
                </Button>
            </NavLink>
        </>
	);
}

export default DisabledOption;
