import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";

// style
import style from '../../components/PressRelease/List/press_release_list.module.scss';

// components
import { makeStyles, Button, Typography } from '@material-ui/core';
import TableFrame from '../../components/Common/TableFrame';
import MediaListNameDialog from '../../components/Media/ListNameDialog';
import MediaTable from '../../components/Media/Detail/Table';
import CreateListDialog from '../../components/Media/CreateList/Dialog';
import Pagination from '../../components/Common/Pagination';

// state
import { RootState } from '../../reducers';

// actions
import * as MediaActionCreators from '../../actions/Media/ActionCreator';

const groupSelector = (state: RootState) => state.group;
const mediaSelector = (state: RootState) => state.media;
const companySelector = (state: RootState) => state.company;
const planSelector = (state: RootState) => state.plan;

const ListPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const group = useSelector(groupSelector);
    const media = useSelector(mediaSelector);
    const company = useSelector(companySelector);
    const planInfo = useSelector(planSelector);

    const limitPlans = [planInfo.free.code];
    const isLimit = limitPlans.includes(company.plan.code);

    useEffect(() => {
        if (group.selectedId) {
            dispatch(MediaActionCreators.getFavoriteMediaList.request({ offset: 1 }));
            dispatch(MediaActionCreators.getMediaCategories.request({ offset: 1, limit: 1500 }));
        }
    }, [group.selectedId]);

    const handlePageChange = (pageNumber) => {
        dispatch(MediaActionCreators.getFavoriteMediaList.request({ offset: pageNumber }))
    }

    const [createListDialog, setCreateListDialog] = useState<boolean>(false);
    const [listNameDialog, setListNameDialog] = useState<boolean>(false);

    const handlePostFavoriteList = (name: string, setErrorMessage) => {
        dispatch(MediaActionCreators.postFavoriteMediaList.request({
            name: name, media: media.categoryList.id_list,
            postSuccess: () => {
                setListNameDialog(false);
                setCreateListDialog(false);
            },
            postError: (text) => setErrorMessage(text),
        }));
    }

    return (
        <>
            {media.favoriteList.results.length > 0 ? (
                <>
                    <div className={classes.headRoot}>
                        <Button variant='outlined' color='primary' className={classes.newButton} onClick={() => setCreateListDialog(true)}>
                            新規作成
                        </Button>
                    </div>
                    <TableFrame>
                        <MediaTable list={media.favoriteList.results} />
                    </TableFrame>
                    <div className={style.pagination}>
                        <Pagination
                            activePage={media.favoriteList.offset}
                            totalCount={media.favoriteList.count}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                </>
            ) : (
                    <div className={classes.noData}>
                        {isLimit ?
                            <>
                                <Typography variant='h4' gutterBottom>
                                    {company.plan.name}プランでは、HARVEST配信リストを利用できません<br/>
                                    利用するには、プランのアップグレードが必要です
                                </Typography>
                                <NavLink exact to={"/setting/plan"}>
                                    <Button variant='contained' color='primary' size='large'>
                                        プランのアップグレード
                                    </Button>
                                </NavLink>
                            </>
                            :
                            <>
                                <Typography variant='h4' gutterBottom>
                                    プレスリリースを送信したいメディアを<br/>リストに登録して管理しましょう
                                </Typography>
                                <Button variant='contained' color='primary' size='large' onClick={() => setCreateListDialog(true)}>
                                    メディアリストを作成する
                                </Button>
                            </>
                        }
                    </div>
                )}
            <CreateListDialog isOpen={createListDialog} handleClose={() => setCreateListDialog(false)} handleOpenListNameDialog={() => setListNameDialog(true)} />
            <MediaListNameDialog
                isOpen={listNameDialog}
                handleSave={handlePostFavoriteList}
                handleClose={() => setListNameDialog(false)}
            />
        </>
    )
}

export default ListPage;

const useStyles = makeStyles({
    noData: {
        marginTop: 100,
        textAlign: "center",
    },
    headRoot: {
        display: 'flex',
        justifyContent: "flex-end",
    },
    newButton: {
        marginRight: 10,
        height: 35,
    },
})
