import React, { ReactNode } from "react";

// components
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Menu from '../../components/Menu';
import Progress from "../../components/Common/Progress";

interface Props {
    children: ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Menu />
            </Drawer>
            <div className={classes.content}>
                <Progress size={"large"} />
                <main className={classes.main}>
                    {children}
                    {/*<Footer/>*/}
                </main>
            </div>
        </div>
    );
}
export default Layout;

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
            margin: 0,
            overflow: 'hidden'
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            height: "100vh",
            overflow: "scroll",
        },
        main: {
            margin: "auto",
            minHeight: '100%',
            // backgroundColor: '#d2e8b9',
            position: 'relative',
        },
    }),
);
