import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { getJSON } from "../../../../util/File";
import store from 'store'

//material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
    Typography,
} from "@material-ui/core";

//component
import AddPressKitForm from '../../../Form/AddPressKitForm'

//action
import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";

import { RootState } from "../../../../reducers";

//style
import style from '../dialog.module.scss'

const pressKitSelector = (state: RootState) => state.pressKit;

const AddPressKitDialog: React.FC = () => {
    const dispatch = useDispatch();
    const pressKit = useSelector(pressKitSelector);

    const handleClose = () => {
        dispatch(PressKitActionCreators.setAddPressKitDialog.request(false));
    };

    const handleSubmit = (values) => {
        dispatch(PressKitActionCreators.addPressKit.request(values.pressKitName))
        handleClose()
    }

    return (
        <Dialog open={pressKit.add.dialog} onClose={handleClose}>
            <DialogTitle className={style.header}>
                プレスキットの名前を入力してください
            </DialogTitle>
            <DialogContent>
            <AddPressKitForm onSubmit={handleSubmit} className={style.form}/>
            </DialogContent>
        </Dialog>
    );
}

export default AddPressKitDialog
