import Froalaeditor from 'froala-editor';

Object.assign(Froalaeditor.POPUP_TEMPLATES, {
    'cellPadding.popup':'[_BUTTONS_][_CUSTOM_LAYER_]'
});

Object.assign(Froalaeditor.DEFAULTS, {
    buttons: ["cellPaddingBack", "|"]
});

export default Froalaeditor.PLUGINS.cellPadding = (editor) => {
    let target:any;

    function initPopup() {
        let popupButtons: string = '<div class="fr-buttons fr-tabs">\n'.concat(editor.button.buildList(editor.opts.buttons), '\n</div>');
        let popupLayers: string = '<div class="fr-cell-padding-layer fr-active fr-layer" id="fr-cell-padding-layer"><div class="fr-input-line"><input maxlength="3" id="fr-set-table-cells-padding-layer-1" type="text" placeholder="セルの余白" tabIndex="1" aria-required="true"></div><div class="fr-input-unit"><p>px</p></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="customCellPadding" tabIndex="2" role="button">OK</button></div><div class="error-message" style="visibility: hidden;"><p>エラーメッセージ</p></div></div>';
        const template = {
            buttons: popupButtons,
            custom_layer: popupLayers,
        };

        const $popup = editor.popups.create('cellPadding.popup', template);

        return $popup;
    };

    function showCellPaddingPopup() {
        target = editor.selection.blocks();
        let $popup = editor.popups.get('cellPadding.popup');

        if (!$popup) $popup = initPopup();
        editor.popups.setContainer('cellPadding.popup', editor.$sc);

        let $btn = editor.$sc.find('.fr-command[data-cmd="cellPadding"]');

        let left = $btn.offset().left + $btn.outerWidth() / 2;
        let top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

        const paddingInput = editor.popups.get("cellPadding.popup").find(".fr-cell-padding-layer input");
        if (target[0].style.padding) {
            const splitStringArray = target[0].style.padding.split("px")
            paddingInput.val(splitStringArray[0]);
        } else {
            if (target[0].offsetParent.offsetParent.classList.contains("header") || target[0].offsetParent.offsetParent.classList.contains("footer")) {
                paddingInput.val("0");
            } else {
                paddingInput.val("5");
            }
        }

        const errorMessageBlock = editor.popups.get("cellPadding.popup").find(".error-message");
        if (errorMessageBlock[0].style.visibility === 'visible') {
            errorMessageBlock[0].style.visibility = 'hidden';
        }

        editor.popups.show('cellPadding.popup', left, top, $btn.outerHeight());
    };

    function customCellPadding() {
        const paddingInput = editor.popups.get("cellPadding.popup").find(".fr-cell-padding-layer input");
        const errorMessageBlock = editor.popups.get("cellPadding.popup").find(".error-message");

        if (paddingInput.val().length > 0) {
            const matchArray = paddingInput.val().match(/[0-9]/g);

            if (matchArray && (paddingInput.val().length === matchArray.length)){
                if (matchArray.length === 1 || (matchArray.length > 1 && matchArray[0] !== "0")) {
                    handleCellPaddingChange(paddingInput.val(), target);
                } else {
                    showErrorMessage(errorMessageBlock, "lead zero padding");
                }
            } else {
                showErrorMessage(errorMessageBlock, "not half-width number");
            }
        } else {
            showErrorMessage(errorMessageBlock, "empty");
        }
    };

    function back() {
        editor.popups.hide("cellPadding.popup");
        editor.table.back();
    };

    function handleCellPaddingChange(value, target) {
        target[0].offsetParent.childNodes[0].childNodes.forEach(tr => {
            tr.childNodes.forEach(td => {
                if (td.style.padding) {
                    td.style.padding = '';
                }
                td.style.cssText += `padding: ${value}px !important;`;
            })
        });

        back();
    };

    function showErrorMessage(errorMessageBlock, errorDetail) {
        if (errorDetail === "lead zero padding") {
            errorMessageBlock[0].outerHTML = '<div class="error-message" style="visibility: visible;"><p>先頭に0を入力しないでください</p></div>';
        } else {
            errorMessageBlock[0].outerHTML = '<div class="error-message" style="visibility: visible;"><p>半角数字を入力してください</p></div>';
        }
    };

    return {
        showCellPaddingPopup: showCellPaddingPopup,
        customCellPadding: customCellPadding,
        back: back,
    };
};
