import React, { useEffect, useState } from 'react';

// components
import { makeStyles } from "@material-ui/core/styles";

// config
import { blackText, whiteText } from '../../../config/press_tag_colors';

// utils
import { checkColorCode } from './utils';

const LabelWrapper = ({ tag }) => {
    const [textIsBlack, setTextIsBlack] = useState<boolean>(false);
    const txtColor = textIsBlack ? blackText : whiteText;

    useEffect(() => {
        const { isError, isBright } = checkColorCode(tag.bg_color);
        setTextIsBlack(isBright);
    }, [tag.bg_color]);

    return (
        <Label name={tag.name} txtColor={txtColor} bgColor={tag.bg_color} />
    );
}

export default LabelWrapper;

export const Label = ({ name, bgColor, txtColor }) => {
    const classes = useStyles();
    return (
        <div className={classes.label} style={{
            color: txtColor, backgroundColor: bgColor,
            border: txtColor === blackText ? '1px solid rgba(0, 0, 0, 0.12)' : ''
        }}>
            <p>{name ? name : 'ラベル'}</p>
        </div>
    );
}

const useStyles = makeStyles({
    label: {
        fontSize: 14,
        overflow: "hidden",
        padding: '2px 10px',
        borderRadius: 15,
        textAlign: 'center',
        height: 24,
        display: 'inline-block',
        marginBottom: '-5.5px',
    },
})
