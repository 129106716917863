import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Switch, Route } from "react-router";

// components
import List from "../../components/Media/List";
import AppLayout from "../../components/Layout/App";
import ListPage from "./List";
import CreatePage from "./Create";
import EditPage from "./Edit"
import Title from "../../components/Common/Title";
import HelpFab from "../../components/Common/HelpFab";

interface Props extends RouteComponentProps<void> { }

const MediaListPage: React.FC<Props> = props => {
	const path = props.location.pathname
	return (
		<AppLayout>
			<Title>
				メディアリスト<HelpFab url={'/help/media#1'}/>
			</Title>
			<Switch>
				<Route exact={true} path="/media/" component={List} />
				<Route exact={true} path="/media/create/" component={CreatePage} />
				<Route exact={true} path="/media/:id/edit/" component={EditPage} />
				<Route exact={true} path="/media/list/" component={ListPage} />
			</Switch>
		</AppLayout>
	);
}

export default MediaListPage;
