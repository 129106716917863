import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// style
import style from '../detail_info.module.scss';

//components
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import Label from '../../../../Tag/Label';

// actions
import * as PressReleaseTagActionCreators from '../../../../../../actions/PressReleaseTag/ActionCreator';
import * as PressReleaseActionCreators from '../../../../../../actions/PressRelease/ActionCreator';

// state
import { RootState } from '../../../../../../reducers';
import TagDialog from '../../../../Tag/Dialog';

const pressReleaseTagSelector = (state: RootState) => state.pressReleaseTag;
const groupSelector = (state: RootState) => state.group;
const pressReleaseSelector = (state: RootState) => state.pressRelease;

const Tag = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const pressRelease = useSelector(pressReleaseSelector);
    const { list } = useSelector(pressReleaseTagSelector);
    const group = useSelector(groupSelector);
    const [tagDialog, setTagDialog] = useState<boolean>(false);

    useEffect(() => {
        if (group.selectedId) {
            dispatch(PressReleaseTagActionCreators.getPressReleaseTagList.request({ offset: 1 }));
        }
        () => {
            getPressReleaseDetails();
        }
    }, [group.selectedId])

    const handleSetTagEditForm = () => {
        dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request({ isOpen: true, id: 0 }));
    }

    const getPressReleaseDetails = () => {
        dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: pressRelease.detail.press_id }));
    }

    return (
        <section className={style.item}>
            <p className={style.title}>ラベル</p>
            {list.results.length > 0 ?
                <Box className={classes.tagBox}>
                    <Box className={classes.tags}>
                        {pressRelease.detail.tags.map((tag, index) => (
                            <Box className={classes.tag} key={index}>
                                <Label tag={tag} />
                            </Box>))}
                        {pressRelease.detail.tags.length === 0 &&
                            <Typography variant='body1'>選択されていません</Typography>}
                    </Box>
                    <Box className={classes.editButton}>
                        <Button variant="outlined" size="small" onClick={() => setTagDialog(true)}>
                            編集
                        </Button>
                    </Box>
                </Box>
                :
                <div className={classes.noData}>
                    <Typography variant="body1">
                        プレスリリースにラベルをつけて<br />管理しましょう
                    </Typography>
                    <Button variant="contained" color="primary" size={"large"} className={classes.newCreateButton}
                        onClick={() => {
                            setTagDialog(true);
                            handleSetTagEditForm();
                        }}>
                        新規作成する
                    </Button>
                </div>}
            <TagDialog isOpen={tagDialog} item={pressRelease.detail} handleClose={() => {
                setTagDialog(false);
                getPressReleaseDetails();
            }} />
        </section >
    )
}

export default Tag;

const useStyles = makeStyles({
    content: {
        padding: '15px 30px',
    },
    tableBody: {
        backgroundColor: 'inherit',
    },
    addButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 20px',
    },
    noData: {
        padding: 30,
        textAlign: 'center',
    },
    newCreateButton: {
        marginTop: 20,
    },
    tagBox: {
        padding: 16,
    },
    tags: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 7px',
    },
    tag: {
        margin: '4px 2px',
    },
    editButton: {
        marginTop: 10,
        textAlign: 'right'
    },
})
