enum AssetActionType {
	POST_PASSWORD_RESET_REQUEST = "POST_PASSWORD_RESET_REQUEST",
	POST_PASSWORD_RESET_SUCCEEDED = "POST_PASSWORD_RESET_SUCCEEDED",
	POST_PASSWORD_RESET_FAILED = "POST_PASSWORD_RESET_FAILED",

	POST_PASSWORD_RESET_REGISTER_REQUEST = "POST_PASSWORD_RESET_REGISTER_REQUEST",
	POST_PASSWORD_RESET_REGISTER_SUCCEEDED = "POST_PASSWORD_RESET_REGISTER_SUCCEEDED",
	POST_PASSWORD_RESET_REGISTER_FAILED = "POST_PASSWORD_RESET_REGISTER_FAILED",
}

export default AssetActionType;

