import {MEDIA_TYPE_LIST,MEDIA_TYPE_COLOR_LIST,MEDIA_CATEGORY_COLOR_LIST} from "../../../../config/media_type";


export function generateMediaCategoryColors(labels: string[]) {
    const colors :string[] = []
    labels.forEach((label) => {
        const color = MEDIA_CATEGORY_COLOR_LIST[label]
        colors.push(color)
    })
    return colors
}

export function generateMediaTypeColors(labels: string[]) {
    const colors :string[] = []
    labels.forEach((label) => {
        const color = MEDIA_TYPE_COLOR_LIST[label]
        colors.push(color)
    })
    return colors
}



export function generateMediaTypeLabel (labels: number[]){
    const list: string[] = []
    labels.forEach((label) => {
        list.push(MEDIA_TYPE_LIST[label])
    })
    return list
} 
