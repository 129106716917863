import { takeLatest, put, select } from "redux-saga/effects";
import { selectPressRelease, selectFirebase, selectMe } from "./selector";
import firebase from "firebase/app";
import { rtdb } from "../configureFirebase";

// action
import PressReleaseFirebaseActionType from "../actions/PressReleaseFirebase/ActionType";
import * as ActionCreators from "../actions/PressReleaseFirebase/ActionCreator";

function* postPressReleaseAccessData(action: ReturnType<typeof ActionCreators.postPressReleaseAccessData.request>){
	const page = action.payload.page;
	const pressId = action.payload.press_id;
	const userId = action.payload.user_id;
	const accessTime = firebase.database.ServerValue.TIMESTAMP;

	try {
		if (page === "edit") {
			yield rtdb.ref(`${pressId}/edit/users`).update({[userId] : accessTime});
		} else {
			yield rtdb.ref(`${pressId}/reserve/users`).update({[userId] : accessTime});
		}
		yield put(ActionCreators.postPressReleaseAccessData.success());
	} catch (e) {
		yield put(ActionCreators.postPressReleaseAccessData.failure(e));
	}
}

function* deletePressReleaseAccessData(action: ReturnType<typeof ActionCreators.deletePressReleaseAccessData.request>){
	const accessData = yield select(selectFirebase);
	const page = action.payload.page;
	const pressId = action.payload.press_id;
	const userId = action.payload.user_id;

	try {
		if (page === "edit") {
			if (userId) {
				yield rtdb.ref(`${pressId}/edit/users/${userId}`).remove();

				if (accessData.data[pressId].edit.editor === userId) {
					yield put(ActionCreators.deletePressReleaseEditorData.request(pressId));
				}
			} else {
				yield rtdb.ref(`${pressId}/edit`).remove();
			}
		} else {
			if (userId) {
				yield rtdb.ref(`${pressId}/reserve/users/${userId}`).remove();

				if (accessData.data[pressId].reserve.editor === userId) {
					yield put(ActionCreators.deletePressReleaseEditorData.request(pressId));
				}
			} else {
				yield rtdb.ref(`${pressId}/reserve`).remove();
			}
		}
		yield put(ActionCreators.deletePressReleaseAccessData.success());
	} catch (e) {
		yield put(ActionCreators.deletePressReleaseAccessData.failure(e));
	}
}

function* postPressReleaseEditorData(action: ReturnType<typeof ActionCreators.postPressReleaseEditorData.request>){
	const accessData = yield select(selectFirebase);
	const pressRelease = yield select(selectPressRelease);
	const me = yield select(selectMe);
	const pressId = pressRelease.detail.press_id;
	const userId = me.uuid;
	const page: string = pressRelease.pageType;

	try {
		if (page === "PAGE_EDIT") {
			if (!accessData.data[pressId].edit.editor) {
				yield rtdb.ref(`${pressId}/edit`).update({editor : userId});
			}
		} else {
			if (!accessData.data[pressId].reserve.editor) {
				yield rtdb.ref(`${pressId}/reserve`).update({editor : userId});
			}
		}
		yield put(ActionCreators.postPressReleaseEditorData.success());
	} catch (e) {
		yield put(ActionCreators.postPressReleaseEditorData.failure(e));
	}
}

function* deletePressReleaseEditorData(action: ReturnType<typeof ActionCreators.deletePressReleaseEditorData.request>){
	try {
		const pressRelease = yield select(selectPressRelease);
		const page: string = pressRelease.pageType;

		if (page === "PAGE_EDIT") {
			yield rtdb.ref(`${action.payload}/edit/editor`).remove();
		} else {
			yield rtdb.ref(`${action.payload}/reserve/editor`).remove();
		}
		yield put(ActionCreators.deletePressReleaseEditorData.success());
	} catch (e) {
		yield put(ActionCreators.deletePressReleaseEditorData.failure(e));
	}
}

function* disconnectRealtimeDatabase(action: ReturnType<typeof ActionCreators.disconnectRealtimeDatabase.request>){
	const accessData = yield select(selectFirebase);
	const page = action.payload.page;
	const pressId = action.payload.press_id;
	const userId = action.payload.user_id;
	let editorRef: firebase.database.Reference | undefined;
	let userRef: firebase.database.Reference;

	try {
		if (page === "edit") {
			if (accessData.data[pressId].edit.editor === userId) {
				editorRef = rtdb.ref(`${pressId}/edit/editor`);
			}
			userRef = rtdb.ref(`${pressId}/edit/users/${userId}`);
		} else {
			if (accessData.data[pressId].reserve.editor === userId) {
				editorRef = rtdb.ref(`${pressId}/reserve/editor`);
			}
			userRef = rtdb.ref(`${pressId}/reserve/users/${userId}`);
		}

		if (editorRef) {
			yield editorRef.onDisconnect().remove();
		}
		yield userRef.onDisconnect().remove();
		yield put(ActionCreators.disconnectRealtimeDatabase.success());
	} catch(e) {
		yield put(ActionCreators.disconnectRealtimeDatabase.failure(e));
	}
}

const pressReleaseFirebaseSaga = [
	takeLatest(PressReleaseFirebaseActionType.POST_PRESS_RELEASE_ACCESS_DATA_REQUEST, postPressReleaseAccessData),
	takeLatest(PressReleaseFirebaseActionType.DELETE_PRESS_RELEASE_ACCESS_DATA_REQUEST, deletePressReleaseAccessData),
	takeLatest(PressReleaseFirebaseActionType.POST_PRESS_RELEASE_EDITOR_DATA_REQUEST, postPressReleaseEditorData),
	takeLatest(PressReleaseFirebaseActionType.DELETE_PRESS_RELEASE_EDITOR_DATA_REQUEST, deletePressReleaseEditorData),
	takeLatest(PressReleaseFirebaseActionType.DISCONNECT_REALTIME_DATABASE_REQUEST, disconnectRealtimeDatabase),
];

export default pressReleaseFirebaseSaga;
