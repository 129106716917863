import React from 'react';

// component
import { Menu, MenuItem, Divider } from '@material-ui/core';

interface ListLayoutItemProps {
    anchorEl: null | HTMLElement,
    handleDeleteDialogOpen,
    handleRenameDialogOpen,
    handleClose,
}

const ListMenu: React.FC<ListLayoutItemProps> = ({
    handleDeleteDialogOpen, handleRenameDialogOpen, handleClose, anchorEl
}) => {
    return (
        <Menu
            elevation={1}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => {
                handleRenameDialogOpen();
                handleClose();
            }}>名前を変更</MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                handleDeleteDialogOpen();
                handleClose();
            }}>削除</MenuItem>
        </Menu>
    );
}

export default ListMenu;
