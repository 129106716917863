import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

// component
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Box, IconButton, Tooltip, TextField } from '@material-ui/core';
import MediaSelect from './Select';
import AppDialog from '../../Common/AppDialog';
import CreateIcon from '@material-ui/icons/Create';

// state
import { RootState } from '../../../reducers';

const mediaSelector = (state: RootState) => state.media;

const ListDetailDialog = ({ isOpen, item, handleClose }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const media = useSelector(mediaSelector);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [saveDialog, setSaveDialog] = useState<boolean>(false);
    const [memoEdit, setMemoEdit] = useState<boolean>(false);
    const [value, setValue] = useState<string>(item.memo);
    const [error, setError] = useState<string>("");

    const handleUpdateMemo = () => {
        setError("");
        dispatch(MediaActionCreators.patchFavoriteMediaList.request({
            id: item.list_id, memo: value,
            postSuccess: () => setMemoEdit(false),
            postError: (text) => setError(text),
        }));
    }

    const getListDetail = () => {
        dispatch(MediaActionCreators.setFavoriteMediaList.request(item.list_id));
        dispatch(MediaActionCreators.getFavoriteMediaListDetail.request({ id: item.list_id, offset: 0 }));
    }

    const handleResetCategoryIdList = () => {
        dispatch(MediaActionCreators.removeMediaCategoryIdListAll.request());
    }

    const handleResetSearch = () => {
        dispatch(MediaActionCreators.setSearch.request({ text: '', is_result: false }));
    }

    const handleUpdateFavoriteList = () => {
        dispatch(MediaActionCreators.patchFavoriteMediaList.request({
            id: item.list_id,
            media: media.categoryList.id_list,
        }));
        setSaveDialog(false);
        setIsEdit(false);
        handleResetCategoryIdList();
    }

    const handleExit = () => {
        setIsEdit(false);
        setMemoEdit(false);
        setValue('');
        handleResetSearch();
    }

    return (
        <Dialog open={isOpen} fullWidth onEntering={() => getListDetail()}
            onExit={() => handleExit()}
            onEscapeKeyDown={() => {
                handleClose();
                handleExit();
            }}>
            <DialogTitle disableTypography className={classes.title}>
                <Typography variant='h5'>{item.name}</Typography>
                <Typography variant='body1' className={classes.error}>{error}</Typography>
                {memoEdit ?
                    <Box className={classes.memo}>
                        <TextField
                            value={value}
                            variant='outlined'
                            onChange={(e) => setValue(e.target.value)}
                            placeholder='メモ'
                            autoFocus
                        />
                        <Button className={classes.cancelButton} variant='outlined' color='primary' size='large' onClick={() => {
                            setValue(item.memo)
                            setMemoEdit(false);
                            setError("");
                        }}>
                            キャンセル
                        </Button>
                        <Button className={classes.button} variant='contained' color='secondary' size='large' onClick={() => {
                            handleUpdateMemo();
                        }}>
                            保存
                        </Button>
                    </Box>
                    :
                    <Box className={classes.memo}>
                        <Tooltip arrow title={item.memo}><Typography className={classes.ellipsis}>{item.memo}</Typography></Tooltip>
                        <IconButton onClick={() => setMemoEdit(true)} disabled={isEdit}>
                            <Tooltip arrow title={item.memo ? '編集する' : 'メモを追加する'}>
                                <CreateIcon />
                            </Tooltip>
                        </IconButton>
                    </Box>}
            </DialogTitle>
            <DialogContent className={classes.root}>
                <MediaSelect isEdit={isEdit} item={item} />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button variant='outlined' color='primary' onClick={() => {
                    handleClose();
                    setIsEdit(false);
                    handleResetCategoryIdList();
                }}>
                    閉じる
                </Button>
                <Box>
                    {isEdit &&
                        <Button variant='outlined' color='primary' onClick={() => {
                            setIsEdit(false);
                            handleResetCategoryIdList();
                        }}>
                            キャンセル
                        </Button>}
                    {isEdit ?
                        <Button variant='contained' color='secondary' disabled={media.categoryList.id_list.length === 0}
                            onClick={() => setSaveDialog(true)}>
                            保存する
                        </Button>
                        :
                        <Button variant='contained' color='secondary' disabled={memoEdit} onClick={() => setIsEdit(true)}>
                            編集する
                        </Button>}
                </Box>
            </DialogActions>
            <AppDialog
                isOpen={saveDialog}
                closeHandle={() => setSaveDialog(false)}
                mainHandle={handleUpdateFavoriteList}
                text='選択されていないメディアは削除されますがよろしいですか？'
                ButtonText='保存する'
            />
        </Dialog>
    )
}

export default ListDetailDialog;

const useStyles = makeStyles({
    root: {
        height: 600,
    },
    title: {
        justifyContent: 'space-between',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    memo: {
        display: 'flex',
        alignItems: 'center',
        width: '50%',
    },
    button: {
        marginLeft: 5,
    },
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    cancelButton: {
        marginLeft: 5,
        width: 140,
    },
    error: {
        color: 'red',
    },
})
