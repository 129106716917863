import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {PAGE_DETAIL} from "../../config/page_type";

// action
import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";

// components
import Detail from "../../components/PressRelease/Detail";
import Layout from "./Layout";

// state
import {RootState} from "../../reducers";

import {checkEditRole, checkReviewRole} from "../../util/checkAuthority";

const meSelector = (state: RootState) => state.me;
const pressReleaseSelector = (state: RootState) => state.pressRelease;

type PageProps = {} & RouteComponentProps<{groupId:string, pressReleaseId: string}>;

const DetailPage: React.FC<PageProps> = props => {
    const dispatch = useDispatch();
    const pressRelease = useSelector(pressReleaseSelector);
    const me = useSelector(meSelector);

    useEffect(() => {
        const pressReleaseId = props.match.params.pressReleaseId
        if (pressReleaseId){
            dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({press_id: pressReleaseId}));
        }
        dispatch(PressReleaseActionCreators.setPressReleasePageType.request(PAGE_DETAIL));
    }, []);

    useEffect(()=> {
        if (me.uuid && pressRelease.detail.fetched === true) {
            dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(checkEditRole(pressRelease, me)));
            dispatch(PressReleaseActionCreators.setPressReleaseReviewRole.request(checkReviewRole(pressRelease.detail.review.member, me)));
        }
    }, [me, pressRelease.detail, pressRelease.creators]);

	return (
		<Layout page={PAGE_DETAIL}>
			<Detail
				detail={pressRelease.detail}/>
		</Layout>
	);
}

export default DetailPage;
