import React from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

// components
import {Box} from "@material-ui/core";

// icons
import HomeIcon from "@material-ui/icons/Home";

// style
import style from "./style.module.scss";

// state
import {RootState} from "../../../reducers";
const routerSelector = (state: RootState) => state.router;

const PAGE_TYPES = {
  PRESS_RELEASE: "press_release",
  PRESS_KIT: "press_kit",
  MEMBER: "member",
  MEDIA: "media",
  SETTING: "setting",
  USER: "user",
  BRIEFING: "briefing",
  DASHBOARD: "dashboard",
  TOPIC: "topic",
  FEATURES: "features",
  RADAR: "radar"
};

const Breadcrumb: React.FC = () => {
  const router = useSelector(routerSelector);
  const rootPath = router.location.pathname.split("/");
  const pageType = rootPath[1];

  return (
    <Box className={style.box}>
      <Box className={style.box}>
        <NavLink to="/" className={style.homeButton}>
          <HomeIcon className={style.homeIcon} />
          <p className={style.homeLink}>TOP</p>
        </NavLink>
        {pageType === PAGE_TYPES.DASHBOARD && (
          <BaseBreadcrumb path={rootPath} name="ダッシュボード" />
        )}
        {pageType === PAGE_TYPES.TOPIC && (
          <BaseBreadcrumb path={rootPath} name="未来トピック" />
        )}
        {pageType === PAGE_TYPES.PRESS_RELEASE && (
          <BaseBreadcrumb path={rootPath} name="プレスリリース" />
        )}
        {pageType === PAGE_TYPES.PRESS_KIT && (
          <BaseBreadcrumb path={rootPath} name="プレスキット" />
        )}
        {pageType === PAGE_TYPES.MEMBER && (
          <BaseBreadcrumb path={rootPath} name="メンバー" />
        )}
        {pageType === PAGE_TYPES.MEDIA && (
          <BaseBreadcrumb path={rootPath} name="メディアリスト" />
        )}
        {pageType === PAGE_TYPES.BRIEFING && (
          <BaseBreadcrumb path={rootPath} name="ブリーフィング" />
        )}
        {pageType === PAGE_TYPES.FEATURES && (
          <BaseBreadcrumb path={rootPath} name="機能一覧" />
        )}
        {pageType === PAGE_TYPES.RADAR && (
          <BaseBreadcrumb path={rootPath} name="記事化レーダー" />
        )}

        {pageType === PAGE_TYPES.SETTING && <Setting path={rootPath} />}
        {pageType === PAGE_TYPES.USER && <User path={rootPath} />}
      </Box>
    </Box>
  );
};

export default Breadcrumb;

interface BreadcrumbSectionProps {
  path: string[];
  name?: string;
}

const BaseBreadcrumb: React.FC<BreadcrumbSectionProps> = ({path, name}) => {
  const link = `${path[1]}`;
  return (
    <>
      <div className={style.slash}>/</div>
      <div className={style.link}>
        <NavLink to={link}>{name}</NavLink>
      </div>
    </>
  );
};

const Setting: React.FC<BreadcrumbSectionProps> = ({path}) => {
  const link = `/${path[1]}/${path[2]}`;
  return (
    <>
      <div className={style.slash}>/</div>
      {(!Boolean(path[2]) || path[2] == "company") && (
        <NavLink to={link} className={style.link}>
          企業情報
        </NavLink>
      )}
      <div className={style.link}>
        {path[2] === "service" && <NavLink to={link}>サービス設定</NavLink>}
        {path[2] === "radar" && <NavLink to={link}>記事化レーダー設定</NavLink>}
        {path[2] === "plan" && <NavLink to={link}>プラン設定</NavLink>}
        {path[2] === "payment" && <NavLink to={link}>ご契約情報</NavLink>}
      </div>
    </>
  );
};

const User: React.FC<BreadcrumbSectionProps> = ({path}) => {
  const link = `/${path[1]}/${path[2]}`;
  return (
    <>
      <div className={style.slash}>/</div>
      <div className={style.link}>
        {path[2] === "me" && <NavLink to={link}>個人設定</NavLink>}
        {path[2] === "password" && <NavLink to={link}>パスワード設定</NavLink>}
        {path[2] === "group" && <NavLink to={link}>参加プロジェクト</NavLink>}
      </div>
    </>
  );
};
