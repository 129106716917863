export const PdfHeader = `
<html lang="ja">
<head>
<meta charset="utf-8">
<title>HARVEST</title>
<meta name="viewport" content="width=device-width">
<meta name="keywords" content="">
<meta name="description" content="">
<link href="https://fonts.googleapis.com/css2?family=Kosugi&family=Kosugi+Maru&family=Lato&family=M+PLUS+1p&family=M+PLUS+Rounded+1c&family=Montserrat&family=Noto+Sans+JP&family=Noto+Serif+JP&family=Oswald&family=Sawarabi+Gothic&family=Sawarabi+Mincho&display=swap" rel="stylesheet" />
</head>
<style>


/* app.module.scss-------------------------------- */
html, body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  color: #222222;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: auto;
  background-color: #fff !important;
  overscroll-behavior: none;
}
dl, dd, dt {
  margin: 0;
  padding: 0;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
}
input:-webkit-autofill, textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

h1, h2, h3, h4, h5, h6, p, div, a {
  margin: 0;
  word-wrap: break-word;
}
a {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
a:hover {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}
i {
  display: inline-block;
}
main {
  width: 100%;
}
iframe {
  border: none;
}




/* editor_plugins_style.css -------------------------------- */
@media (max-width: 767px) {
  .fr-view .fr-dii.fr-fil, .fr-view img.fr-dii.fr-fir {
      display: block !important;
      float: none !important;
      margin: 10px auto !important;
      vertical-align: top !important;
  }
}
.fr-view img.fr-dii.fr-fil {
      margin: 10px 25px 10px 0 !important;
}
.fr-view img.fr-dii.fr-fir {
      margin: 10px 0 10px 25px !important;
}
.fr-view img.fr-dib {
  margin: 10px auto !important;
}


/* エディタの縁取り*/
.fr-box.fr-basic .fr-wrapper {
    border: none;
}

/*カラーパレットのレイアウト*/
.fr-popup .fr-color-set.fr-selected-set {
    padding: 20px !important;
}
.fr-color-set.fr-table-colors {
  padding: 20px !important;
}

/*テーブルのスタイル*/
.fr-view table {
    table-layout: fixed;
}

.fr-view table td, .fr-view table th {
  border: 1px solid #999 !important;
  padding: 5px !important;
  min-width: 5px;
  height: 100%;
}
.fr-element table td.fr-selected-cell, .fr-element table th.fr-selected-cell {
  border: 1px double #0098f7 !important;
}
.fr-view table.fr-dashed-borders td, .fr-view table.fr-dashed-borders th {
  border-style: dashed !important;
}
.fr-view table td.fr-highlighted, .fr-view table th.fr-highlighted {
  border: 1px double red !important;
}
.fr-view table td.fr-thick, .fr-view table th.fr-thick {
  border-width: 2px !important;
}
.fr-view table.fr-none-border td, .fr-view table.fr-none-border th {
  border: none !important;
}

/*水平線のスタイル*/
.fr-view hr {
    margin:5px 0;
    border-bottom: 1px solid #333;
    border-top:none;
    height:1px;
    background:none;
    page-break-after: auto;
}

/*箇条書きのスタイル*/
.fr-view ul {
    list-style: initial;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

/* コードブロック */
.fr-view ul.codeblock li:first-child, .fr-view ol.codeblock li:first-child {
  padding-top: 5px;
}
.fr-view ul.codeblock li:last-child, .fr-view ol.codeblock li:last-child {
  padding-bottom: 5px;
}
.fr-view .codeblock:not(ul):not(ol) {
  padding: 10px 15px;
}

/* カラム */
.column2, .column3, .column4 {
  display: inline-block;
  vertical-align: top;
  padding: 5px;
}
.column2 {
  width: 50%;
}
.column3 {
  width: 33.3333%;
}
.column4 {
  width: 25%;
}


/* header footer　--------------------------------*/
.fr-view .header::after {
    content: "";
    display: block;
    clear: both;
}
.fr-view .footer::after {
    content: "";
    display: block;
    clear: both;
}
.fr-view .header table,
.fr-view .header table th,
.fr-view .header table td ,
.fr-view .footer table,
.fr-view .footer table th,
.fr-view .footer table td {
  border: none !important;
}
.fr-view .header .fr-dib,
.fr-view .footer .fr-dib {
    margin: 0 auto !important;
}

</style>
    <body>
        <header>	
                <div class="">
                    <div class="fr-view">
                        {{HTML}}
                    </div>
                </div>
         </header>
	</body>
</html>
`
