import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchPost, fetchGet, fetchDelete } from "./fetch";
import {
    selectPressRelease,
    selectPressReleaseReserve,
    selectGroup,
    selectMedia,
    selectCompany,
    selectPressReleasePublish
} from "./selector";

// action
import ActionType from '../actions/PressReleaseReserve/ActionType';
import PressReleaseActionType from '../actions/PressRelease/ActionType';
import * as ActionCreators from '../actions/PressReleaseReserve/ActionCreator';
import * as PressReleaseActionCreators from '../actions/PressRelease/ActionCreator';
import * as MediaActionCreators from '../actions/Media/ActionCreator';
import * as ErrorActionCreators from '../actions/Error/ActionCreator';
import * as TicketActionCreators from '../actions/Ticket/ActionCreator';
import {PressReleasePublishModel} from "../model/PressReleasePublishModel";

const now = new Date()

// 詳細取得
function* getPressReleaseReserve(action: ReturnType<typeof ActionCreators.getPressReleaseReserve.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/custom_medias/?limit=1000&offset=0/`);
        yield put(ActionCreators.getPressReleaseCustomMedia.success(data.results))
        let { press_id } = action.payload
        const [data2, error2] = yield call(fetchGet, `groups/${group.selectedId}/press_info/${press_id}/reserve/`);
        if (data2.auto_media.list_id > 0) {
            yield put(MediaActionCreators.setFavoriteMediaList.request(data2.auto_media.list_id));
            const list = data2.auto_media.id_list.concat()
            yield put(MediaActionCreators.addMediaFavoriteListAll.request(list))
        }
        yield put(ActionCreators.getPressReleaseReserve.success(data2))
    } catch (e) {
        yield put(ActionCreators.getPressReleaseReserve.failure(e));
    }
}

// 保存
function* postPressReleaseReserve(action: ReturnType<typeof ActionCreators.postPressReleaseReserve.request>) {

    try {
        const group = yield select(selectGroup);
        const pressRelease = yield select(selectPressRelease);
        const reserve = yield select(selectPressReleaseReserve);
        const media = yield select(selectMedia);
        const company = yield select(selectCompany);
        let { press_id } = pressRelease.detail
        const request: any = action.payload;
        let auto: any = {}
        const listId = reserve.data.auto.list_id;
        if (reserve.data.auto.is_send) {
            auto = reserve.data.auto;
            if (listId === 0) auto = { ...auto, id_list: reserve.data.auto.id_list }
            if (listId > 0) auto = { ...auto, id_list: media.favoriteList.results.find(i => i.list_id === listId).id_list }
        } else {
            auto = {
                ...reserve.data.auto,
                id_list: [],
            }
        }
        const publish: PressReleasePublishModel = yield select(selectPressReleasePublish); // 後々予約の画面からとる
        const send = {
            auto_media: { ...auto, from_email: auto.from_email || company.email },
            custom_media: reserve.data.custom.filter(item => item.is_send === true).map(item => {
                return { ...item, from_email: item.from_email || company.email }
            }),
            released_at: reserve.data.released_at,
            comment: request.comment,
            reviewer: request.reviewer || [],
            is_save: request.is_save,
            press_kit_id: reserve.data.press_kit_id,
            is_publish: publish.is_publish ? true : false,
            press_id: publish.press_id,
            ogp_url: publish.ogp_url,
            ogp_title: publish.ogp_title,
            ogp_description: publish.ogp_description
        };

        if (request.deadline_at) Object.assign(send, { deadline_at: request.deadline_at })
        yield put(ActionCreators.setPressReleaseReserveSaveLoading(true))
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/press_info/${press_id}/reserve`, send);
        yield put(TicketActionCreators.getTicketQty.request());
        yield put(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: press_id }))
    } catch (e) {
        yield put(ActionCreators.postPressReleaseReserve.failure(e));
    }
}

// 削除
function* deletePressReleaseReserve(action: ReturnType<typeof ActionCreators.deletePressReleaseReserve.request>) {

    try {
        const group = yield select(selectGroup);
        const pressRelease = yield select(selectPressRelease);
        let { press_id } = pressRelease.detail
        yield call(fetchDelete, `groups/${group.selectedId}/press_info/${press_id}/reserve/latest`);
        yield put(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: press_id }))
    } catch (e) {
        yield put(ActionCreators.deletePressReleaseReserve.failure(e));
    }
}


// 自動選定メディアレコメンド
function* postPressReleaseMediaRecommend(action: ReturnType<typeof ActionCreators.postPressReleaseMediaRecommend.request>) {
    try {
        const group = yield select(selectGroup);
        const pressRelease = yield select(selectPressRelease);
        const media = yield select(selectMedia);
        const request = Object.assign(action.payload, { uuid: pressRelease.detail.press_id, search: media.search.text })
        const [data, error] = yield call(fetchPost, `media_recommend`, request);
        yield put(ActionCreators.postPressReleaseMediaRecommend.success({
            id_list: data.result,
            categories: request.categories,
            type: request.type,
            details: request.details,
            count: data.count,
        }));

    } catch (e) {
        yield put(ActionCreators.postPressReleaseMediaRecommend.failure(e));
    }
}

// 全メディア取得
function* getAutoMediaList(action: ReturnType<typeof ActionCreators.getPressReleaseAutoMedia.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/custom_medias/?limit=100&offset=0/`);
        yield put(ActionCreators.getPressReleaseAutoMedia.success(data.results))
    } catch (e) {
        yield put(ActionCreators.getPressReleaseAutoMedia.failure(e));
    }
}


// 承認結果
function* postPressReleaseReserveReviewResult(action: ReturnType<typeof ActionCreators.postPressReleaseReserveReviewResult.request>) {
    try {
        const group = yield select(selectGroup);
        const pressRelease = yield select(selectPressRelease);
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/press_info/${pressRelease.detail.press_id}/reserve_status`, action.payload);
        yield put(TicketActionCreators.getTicketQty.request());
        yield put(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: pressRelease.detail.press_id }))
    } catch (e) {
        yield put(ActionCreators.postPressReleaseReserveReviewResult.failure(e));
    }
}

function* setPressReleaseReserveSaveLoading(action) {
    yield put(ActionCreators.setPressReleaseReserveSaveLoading(false))
}

//テスト配信
function* postMailCheck(action: ReturnType<typeof ActionCreators.postMailCheck.request>) {
    const { active } = action.payload;
    const isCustomMedia = active !== 0;
    try {
        const group = yield select(selectGroup);
        const pressRelease = yield select(selectPressRelease);
        const reserve = yield select(selectPressReleaseReserve);
        const company = yield select(selectCompany);
        const { press_id } = pressRelease.detail;
        const auto = reserve.data.auto;

        let send = { press_kit_id: reserve.data.press_kit_id }
        if (isCustomMedia) {
            const targetCustomMediaId = active;
            const customMedia = reserve.data.custom.filter(item => item.id === targetCustomMediaId)[0]
            send['custom_media'] = { ...customMedia, from_email: customMedia.from_email || company.email }
        } else {
            send['auto_media'] = { ...auto, from_email: auto.from_email || company.email }
        }
        const [data, error] = yield call(fetchPost, `groups/${group.selectedId}/press_info/${press_id}/mail_check`, send)
        if (data && !error) {
            yield put(ActionCreators.postMailCheck.success(send))
        } else {
            yield put(ErrorActionCreators.setErrorMessage(error.error))
            yield put(ErrorActionCreators.setErrorDialog(true))
            yield put(ActionCreators.postMailCheck.failure(error.error))
        }
    } catch (e) {
        yield put(ActionCreators.postMailCheck.failure(e));
    }
}

const pressReleaseReserveSaga = [
    takeLatest(ActionType.GET_PRESS_RELEASE_RESERVE_REQUEST, getPressReleaseReserve),
    takeLatest(ActionType.POST_PRESS_RELEASE_RESERVE_REQUEST, postPressReleaseReserve),
    takeLatest(ActionType.POST_PRESS_RELEASE_MEDIA_RECOMMEND_REQUEST, postPressReleaseMediaRecommend),
    takeLatest(ActionType.DELETE_PRESS_RELEASE_RESERVE_REQUEST, deletePressReleaseReserve),
    takeLatest(ActionType.GET_PRESS_RELEASE_AUTO_MEDIA_REQUEST, getAutoMediaList),
    takeLatest(ActionType.POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_REQUEST, postPressReleaseReserveReviewResult),
    takeLatest(PressReleaseActionType.GET_PRESS_RELEASE_DETAILS_REQUEST, setPressReleaseReserveSaveLoading),
    takeLatest(ActionType.POST_MAIL_CHECK_REQUEST, postMailCheck),
];

export default pressReleaseReserveSaga;
