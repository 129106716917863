import React from "react";
import {useSelector} from "react-redux";
import { RouteComponentProps } from "react-router-dom";

// components
import MemberList from "../../components/Member/List";
import AppLayout from "../../components/Layout/App";
import HelpFab from "../../components/Common/HelpFab";
import Title from "../../components/Common/Title";
import {MemberQtyTable} from "../../components/Member/MemberQtyList";

//model
import {RootState} from "../../reducers";
import {CompanyGroupsContentModel} from "../../model/CompanyModel";

interface Props extends RouteComponentProps<void> {}

const companyGroupSelector = (state: RootState) => state.company.groups;

const Member: React.FC<Props> = (props) => {
	const companyGroup: CompanyGroupsContentModel[] = useSelector(companyGroupSelector) || [];

	return (
		<AppLayout>
			<Title>
				メンバー情報<HelpFab url={'/help/member#1'}/>
			</Title>
			<MemberList/>
			<MemberQtyTable groups={companyGroup}/>
		</AppLayout>
	);
}

export default Member;
