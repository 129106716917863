import { takeLatest, put, call } from 'redux-saga/effects';
import { startSubmit, stopSubmit, reset } from 'redux-form'
import { push } from "connected-react-router";
import ResetActionType from '../actions/Reset/ActionType';
import * as ActionCreators from '../actions/Reset/ActionCreator';
import { fetchPost } from "./fetch";


function* postResetPassword(action: ReturnType<typeof ActionCreators.postResetPassword.request>) {
	yield put(startSubmit('RESET_PASSWORD'))
	try {
		const { email } = action.payload;
		const [data, error] = yield call(fetchPost, `password/reset`, { email });
		if (data && !error) {
			yield put(stopSubmit('RESET_PASSWORD'))
			yield put(ActionCreators.postResetPassword.success());
			yield put(push("/reset/password/send"));
		} else {
			yield put(stopSubmit('RESET_PASSWORD', {'email': error.error}))
		}
	} catch (e) {
		yield put(ActionCreators.postResetPassword.failure(e))
	}
};

function* postResetPasswordRegister(action: ReturnType<typeof ActionCreators.postResetPasswordRegister.request>) {
	yield put(startSubmit('NEW_PASSWORD'))
	try {
		const [data, error] = yield call(fetchPost, `password/reset/confirm`, action.payload)
		if (data && !error) {
			yield put(startSubmit('NEW_PASSWORD'))
			yield put(ActionCreators.postResetPasswordRegister.success());
			yield put(push('/reset/password/registered'))
		} else {
			error['password1'] = error['new_password2']
			delete error['new_password2']
			yield put(stopSubmit('NEW_PASSWORD', error))
		}

	} catch (e) {
		yield put(ActionCreators.postResetPasswordRegister.failure(e))
	}
}

const resetPasswordSaga = [
	takeLatest(ResetActionType.POST_PASSWORD_RESET_REQUEST, postResetPassword),
	takeLatest(ResetActionType.POST_PASSWORD_RESET_REGISTER_REQUEST, postResetPasswordRegister),
	];

export default resetPasswordSaga;
