import React, {ReactNode} from 'react';
import { makeStyles } from '@material-ui/core/styles';

export interface Props {
    children: ReactNode
}

const TableFrame: React.FC<Props> = ({children}) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrap}>{children}</div>
        </div>
    );
}

export default TableFrame;

const useStyles = makeStyles({
    root: {
        padding: 10,
        background: "#f3f7f4",
        overflowX: "scroll",
        marginTop: 20
    },
    wrap: {
        background: "#fff"
    },
});
