enum MeActionType {
	GET_ME_REQUEST = "GET_ME_REQUEST",
	GET_ME_SUCCEEDED = "GET_ME_SUCCEEDED",
	GET_ME_FAILED = "GET_ME_FAILED",
	PATCH_ME_REQUEST = "PATCH_ME_REQUEST",
	PATCH_ME_SUCCEEDED = "PATCH_ME_SUCCEEDED",
	PATCH_ME_FAILED = "PATCH_ME_FAILED",
    PATCH_PROFILE_IMAGE_REQUEST = "PATCH_PROFILE_IMAGE_REQUEST",
    PATCH_PROFILE_IMAGE_SUCCEEDED = "PATCH_PROFILE_IMAGE_SUCCEEDED",
    PATCH_PROFILE_IMAGE_FAILED = "PATCH_PROFILE_IMAGE_FAILED",

	PATCH_PASSWORD_REQUEST = "PATCH_PASSWORD_REQUEST",
	PATCH_PASSWORD_SUCCEEDED = "PATCH_PASSWORD_SUCCEEDED",
	PATCH_PASSWORD_FAILED = "PATCH_PASSWORD_FAILED",
}

export default MeActionType;

