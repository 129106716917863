import {OrderInfoListModel} from "../model/OrderInfoModel";
import createReducer from "./createReducer";
import OrderInfoActionType from "../actions/OrderInfo/ActionType";
import OrderInfoAction from "../actions/OrderInfo/Action";

const initialStateOrderInfo: OrderInfoListModel = {
    results: [],
    count: 0,
    offset: 0,
}

export const orderInfo = createReducer<OrderInfoListModel>(initialStateOrderInfo, {
    [OrderInfoActionType.GET_ORDER_INFO_LIST_SUCCEEDED](state: OrderInfoListModel, action: OrderInfoAction) {
        const result: any = action.payload
        return {
            ...result
        }
    },
})
