import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from '@material-ui/core';
import NameDialog from '../Dialog/Name';
import SendMailDialog from '../Dialog/SendMail';
import PreviewDialog from '../Dialog/Preview';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// model
import { PageTypeModel } from '../../../model/BriefingModel';

// config
import { BRIEFING_PAGE_TYPES as PAGE_TYPES, BRIEFING_STATUS } from '../../../config/briefing';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;
const groupSelector = (state: RootState) => state.group;

interface Props {
    pageType: PageTypeModel,
}

const Header: React.FC<Props> = ({ pageType }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { detail, isSorting } = useSelector(briefingSelector);
    const group = useSelector(groupSelector);
    const { body, id } = detail;
    const [nameDialog, setNameDialog] = useState(false);
    const [mailDialog, setMailDialog] = useState(false);
    const [previewDialog, setPreviewDialog] = useState(false);

    const handlePost = (name: string) => {
        dispatch(BriefingActionCreators.postBriefingContent.request({ name }));
    }

    const handlePatch = () => {
        dispatch(BriefingActionCreators.patchBriefingContent.request({ id }));
    }

    const handleClick = () => {
        if (id) handlePatch();
        else setNameDialog(true);
    }

    const handlePush = (path: string) => {
        dispatch(push(`/briefing/${group.selectedId}/${path}/${id}/`));
    }

    const handleMakeHtmlFromBriefing = () => {
        dispatch(BriefingActionCreators.makeHtmlFromBriefing.request({ body, id }));
    }

    return (
        <>
            <div className={classes.root}>
                <Typography variant="h5" className={classes.name}>ブリーフィング</Typography>
                <div className={classes.info}>
                    <div className={classes.buttons}>
                        {(pageType == PAGE_TYPES.PUBLIC && body.status == BRIEFING_STATUS.PUBLIC) &&
                            <Button variant="contained" color="primary" disabled={!detail.fetched}
                                className={classes.button} onClick={handleMakeHtmlFromBriefing}>
                                プレスリリースを作成
                            </Button>}
                        {(pageType === PAGE_TYPES.CREATE || pageType === PAGE_TYPES.EDIT) &&
                            <Button variant="outlined" color="secondary" disabled={!detail.fetched || isSorting}
                                className={classes.button} onClick={() => setPreviewDialog(true)}>
                                プレビュー
                            </Button>}
                        {pageType == PAGE_TYPES.PUBLIC &&
                            <Button variant="outlined" color="primary" disabled={!detail.fetched}
                                className={classes.button} onClick={() => handlePush("edit")}>
                                編集する
                            </Button>}
                        {pageType == PAGE_TYPES.EDIT && body.status === BRIEFING_STATUS.PUBLIC &&
                            <Button variant="outlined" color="primary" disabled={!detail.fetched}
                                className={classes.button} onClick={() => handlePush("public")}>
                                回答を確認する
                            </Button>}
                        {(pageType == PAGE_TYPES.CREATE || pageType == PAGE_TYPES.EDIT) &&
                            <Button variant="contained" color="primary" disabled={!detail.fetched || isSorting}
                                className={classes.button} onClick={handleClick}>
                                保存
                            </Button>}
                        {(pageType == PAGE_TYPES.EDIT || pageType == PAGE_TYPES.PUBLIC) &&
                            <Button variant='contained' color='secondary' disabled={!detail.fetched || isSorting}
                                onClick={() => setMailDialog(true)}>
                                回答を依頼
                            </Button>}
                    </div>
                </div>
            </div>
            <NameDialog isOpen={nameDialog} handleClose={() => setNameDialog(false)}
                defaultName={body.name} handlePost={handlePost} />
            <SendMailDialog isOpen={mailDialog} handleClose={() => setMailDialog(false)} title={body.name} type="send" />
            <PreviewDialog isOpen={previewDialog} handleClose={() => setPreviewDialog(false)}
                body={body} id={id} />
        </>
    );
}

export default Header;

const useStyles = makeStyles({
    root: {
        height: 60,
        width: 'calc(100% - 240px)',
        borderBottom: '1px solid #E2E9E5',
        backgroundColor: '#fff',
        zIndex: 30,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 15px',
    },
    name: {
        lineHeight: '58px',
        fontSize: 18,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    info: {
        display: 'flex',
        padding: '0 10px',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        marginRight: 10,
    },
    publicButton: {
        padding: '9.5px 15px',
    },
})
