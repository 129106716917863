import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// config
import { pressReleaseType } from "../../../../config/press_release_type";

// style
import style from "./release_type_dialog.module.scss";

// component
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Select, FormControl, Typography, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";

// state
import { RootState } from "../../../../reducers";

//action
import * as ActionCreators from "../../../../actions/PressReleaseReserve/ActionCreator";

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

interface ShareDialogProps {
	isOpen: boolean,
	closeHandle,
}

const useStyles = makeStyles({
	content: {
		width: 1000,
		overflow: "scroll",
		paddingTop: 20,
		backgroundColor: '#fff',
	}
});

const ReleaseTypeDialog: React.FC<ShareDialogProps> = ({ isOpen, closeHandle }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const reserve = useSelector(pressReleaseReserveSelector);
	const { categories, category_details } = reserve.data;
	const [type, setType] = useState<number>(0);

	const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
		setType(event.target.value as number);
	};

	const handlePostType = () => {
		if (categories.length > 0 && category_details.length > 0) {
			dispatch(ActionCreators.removePressReleaseReserveAutoMediaAll.request());
			dispatch(ActionCreators.postPressReleaseMediaRecommend.request({ categories: categories, type: type, details: category_details, count: 0 }));
		} else {
			dispatch(ActionCreators.setPressReleaseReserveType.request(type));
		}
		closeHandle()
	};

	return (
		<Dialog open={isOpen}>
			<DialogTitle>
				リリースの種類を選択してください
				<Typography variant="caption" display={"block"}>選択した情報とプレスリリースの内容から配信先の自動選定を行います。</Typography>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<section className={style.item}>
					<p className={style.subTitle}>種類</p>
					<FormControl variant="outlined" className={style.form}>
						<Select
							native
							id="demo-customized-select-native"
							value={type}
							onChange={handleChangeType}
						>
							<option label="選択してください。" value=""></option>
							{pressReleaseType.map((category, num) => (
								<optgroup key={num} label={category.label}>
									{category.sub.map((item, index) => (
										<option key={index} label={item.label} value={item.id}></option>
									))}
								</optgroup>
							))}
						</Select>
					</FormControl>
				</section>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" size="large" color="primary" onClick={closeHandle}>
					キャンセル
				</Button>
				<Button variant="contained" size="large" color="primary" onClick={handlePostType}
					disabled={!type}>
					設定する
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ReleaseTypeDialog;
