enum GroupActionType {
	GET_GROUP_LIST_REQUEST = "GET_GROUP_LIST_REQUEST",
	GET_GROUP_LIST_SUCCEEDED = "GET_GROUP_LIST_SUCCEEDED",
	GET_GROUP_LIST_FAILED = "GET_GROUP_LIST_FAILED",

	POST_GROUP_REQUEST = "POST_GROUP_REQUEST",
	POST_GROUP_SUCCEEDED = "POST_GROUP_SUCCEEDED",
	POST_GROUP_FAILED = "POST_GROUP_FAILED",

	PATCH_GROUP_REQUEST = "PATCH_GROUP_REQUEST",
	PATCH_GROUP_SUCCEEDED = "PATCH_GROUP_SUCCEEDED",
	PATCH_GROUP_FAILED = "PATCH_GROUP_FAILED",

	SET_SELECTED_ID = "SET_SELECTED_ID",
}

export default GroupActionType;

