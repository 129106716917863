import React from "react";
import clsx from "clsx";

import {
    Chip,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Typography,
    Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core/styles";
import { MEDIA_TYPE_LIST } from "../../../../config/media_type";
import dayjs from "dayjs";
import { theme } from "../../../../withRoot";


interface Props {
    category?: number,
    data
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        table: {
            marginTop: "10px"
        },
        cell: {
            padding: 16,
            minWidth: 250,
        },
        cellFont: {
            fontWeight: "bold"
        }
    }));


const MediaTable: React.FC<Props> = ({ data }) => {
    const classes = useStyles();
    const fixTitle = clsx(classes.cell, classes.cellFont)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Card className={classes.paper}>
            <Typography component="h1" variant="h1">開封確認</Typography>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={fixTitle}>メディア名</TableCell>
                        <TableCell className={fixTitle}>種別</TableCell>
                        <TableCell className={fixTitle}>開封時刻</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data ? (data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <TableRow key={index}>
                            <TableCell className={classes.cell}>{row.name}</TableCell>
                            <TableCell className={classes.cell}>{MEDIA_TYPE_LIST[row.media_type]}</TableCell>
                            <TableCell className={classes.cell}>{row.opened_at ? (dayjs(row.opened_at).format('YYYY/MM/DD HH:mm')) : ("-")}</TableCell>
                        </TableRow>
                    ))) :
                        (<div><h3>No data</h3></div>)}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Card>
    )

}

export default MediaTable;
