import React from "react";
import { useSelector } from "react-redux";
import { createReleasedAt, createPublicPagePressKit } from "../../../../../util/createUrl";
import { generatePressKitList } from "../../../../../util/reserve";
import classNames from 'classnames';

// component
import { makeStyles, Typography } from "@material-ui/core/";

// img
import ImagePDF from "../../../../../assets/icon_pdf.svg";
import LinkIcon from "../../../../../assets/link_icon.svg";

import { RootState } from "../../../../../reducers";
import { PressKitFirestoreModel } from "../../../../../model/PressKitModel";
import { getPdfDownloadUrl } from "../../../../../config/press_release_pdf";

const pressReleaseIdSelector = (state: RootState): string => state.pressRelease.detail.press_id;
const pressKitIdSelector = (state: RootState): Array<string> => state.pressReleaseReserve.data.press_kit_id;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;

const useStyles = makeStyles({
	pdf: {
		wordBreak: "break-all",
		marginTop: 5,
		background: "#F8FBF9",
		borderRadius: 4,
		padding: 10,
		display: "flex",
		cursor: "pointer",
		border: "1px solid #ccc",
		width: 150,
	},
	icon: {
		marginRight: 10
	},
	pdfInfo: {
		display: 'flex',
	},
	notDownloadPdfLink: {
		pointerEvents: 'none',
	},
});



const File: React.FC = () => {
	const classes = useStyles();
	const press_id = useSelector(pressReleaseIdSelector);
	const pdfDownloadUrl = getPdfDownloadUrl(press_id);

	return (
		<div>
			<a href={`${pdfDownloadUrl}?is_download=1`} className={classNames(classes.pdf, !press_id && classes.notDownloadPdfLink)}>
				<Typography variant={"body1"} className={classes.pdfInfo}>
					<img src={ImagePDF} className={classes.icon} />プレスリリース
				</Typography>
			</a>
		</div>
	);
};
export default File;
