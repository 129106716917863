import { takeLatest, put, call, select } from 'redux-saga/effects';

import { selectCompany } from "./selector";
import { fetchGet } from "./fetch";

// actions
import TicketActionType from "../actions/Ticket/ActionType";
import * as TicketActionCreators from '../actions/Ticket/ActionCreator';

function* getTicketQty(action: ReturnType<typeof TicketActionCreators.getTicketQty.request>) {
	try {
		const company = yield select(selectCompany);
		const [data, error] = yield call(fetchGet, `ticket/${company.uuid}/qty/`)
		yield put(TicketActionCreators.getTicketQty.success(data))
	} catch (e) {
		yield put(TicketActionCreators.getTicketQty.failure(e))
	}
}

const ticketSaga = [
	takeLatest(TicketActionType.GET_TICKET_QTY_REQUEST, getTicketQty)
];

export default ticketSaga;

