import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import Content from './Content';
import { makeStyles } from "@material-ui/core/styles";

import IconClose from '../../../assets/icon_close_gray.svg';

const CsvImportDialog = ({ isOpen, handleClose }) => {
    const classes = useStyles();
    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                ファイルからインポート
                <IconButton aria-label="close" onClick={() => handleClose()} className={classes.close}>
                    <img src={IconClose} />
                </IconButton>

            </DialogTitle>
            <DialogContent className={classes.content}>
                <Content />
            </DialogContent>
        </Dialog>
    );
}

export default CsvImportDialog;

const useStyles = makeStyles({
    content: {
        minWidth: 600,
        minHeight: 150,
    },
    close: {
        position: "absolute",
        right: 13,
        top: 13,
    },
})
