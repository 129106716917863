import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Card, Typography, CardHeader, CardContent } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

interface Props {
    title: string,
    data: number[],
    labels: string[],
    colors:string[],
    options: {},
}

const useStyles = makeStyles({
        paper: {
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            height: "100%",
            minHeight: "300px"
        },
        message: {
            margin: "50px",
            textAlign: "center"
        }
    }
);

const DoughnutChart: React.FC<Props> = ({ title, data, labels, colors, options }) => {
    const classes = useStyles();
    const datasets = {
        labels: labels,
        datasets: [{
            data: data,
            backgroundColor: colors,
        }]
    };

    return (
        <Card className={classes.paper}>
            <CardHeader title={<Typography variant="h1" component="h1">{title}</Typography>} />
            <CardContent>
                {data.length === 0 ?
                    (
                        <Typography variant={"caption"} display={"block"} className={classes.message}>データがありません</Typography>
                    ) :
                    (
                        <Doughnut
                            height={250}
                            width={450}
                            data={datasets}
                            options={options}
                        />)
                }
            </CardContent>
        </Card>

    )
};

export default DoughnutChart;
