import { PageTypesModel, PageTypeModel } from "../model/BriefingModel";

export const QUERY_TYPES = {
    TEXT: 'text',
    RADIO: 'radio',
    IMAGE: 'image',
}

export const BRIEFING_STATUS = {
    EDIT: 0,
    ARCHIVE: 1,
    PUBLIC: 2,
    TEMPLATE: 3,
}

export const BRIEFING_PAGE_TYPES: PageTypesModel = {
    LIST_EDIT: 'LIST_EDIT',
    LIST_RECEIVED: 'LIST_RECEIVED',
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    PUBLIC: 'PUBLIC',
    ANSWER: 'ANSWER',
    ANONYMOUS_ANSWER: 'ANONYMOUS_ANSWER',
}

export const createBriefingPath = (groupId: string, briefingId: string, pageType: PageTypeModel) => {
    switch (pageType) {
        case BRIEFING_PAGE_TYPES.LIST_EDIT:
            return `/briefing/${groupId}/edit/${briefingId}/`;
        case BRIEFING_PAGE_TYPES.LIST_RECEIVED:
            return `/briefing/${groupId}/answer/${briefingId}/`;
        default:
            return "";
    }
}
