import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { NavLink } from "react-router-dom";


//component
import {Button, Typography} from "@material-ui/core";
import SettingTitle from "../../Common/SettingTitle";
import TableFrame from "../../Common/TableFrame";
// style
import style from "./detail.module.scss";

// state
import {RootState} from "../../../reducers";
import Link from "@material-ui/core/Link";
const companySelector = (state: RootState) => state.company;

const CompanyDetail: React.FC = () => {
    const company = useSelector(companySelector);

    return (
        <>
            <SettingTitle text="企業情報"/>
            <div className={style.action}>
                <NavLink exact to={`/setting/company/edit/`}>
                    <Button variant="contained" size="large" color="primary">
                        企業情報変更
                    </Button>
                </NavLink>
            </div>
            <Typography variant={"caption"} component={"p"} color={"error"}>
                <NavLink target="_blank" to={`/help/web_page#1`} className={style.link}>プレスリリース公開ページ</NavLink>、<br/>
                <NavLink target="_blank" to={`/help/press_kit#2`} className={style.link}>プレスキットダウンロードページ</NavLink>で表示されます。
            </Typography>
            <TableFrame>
            <ul className={style.content}>
                <li className={style.item}>
                    <label>企業名</label>
                    <div className={style.text}>
                        <p>{company.name}</p>
                    </div>
                </li>
                <li className={style.item}>
                    <label>郵便番号</label>
                    <div className={style.text}>
                        <p>{company.postal_code}</p>
                    </div>
                </li>
                <li className={style.item}>
                    <label>住所</label>
                    <div className={style.text}>
                        <p>{company.address}</p>
                    </div>
                </li>
                <li className={style.item}>
                    <label>電話番号</label>
                    <div className={style.text}>
                        <p>{company.tel}</p>
                    </div>
                </li>
                <li className={style.item}>
                    <label>FAX</label>
                    <div className={style.text}>
                        <p>{company.fax}</p>
                    </div>
                </li>
                <li className={style.item}>
                    <label>コーポレートサイト</label>
                    <div className={style.text}>
                        <p>{company.url}</p>
                    </div>
                </li>
                <li className={style.item}>
                    <label>ロゴ</label>
                    {company.img ?
                        <div>
                            <img src={company.img} className={style.logo}/>
                        </div>
                        :
                        <div className={style.noLogo}></div>
                    }
                </li>
            </ul>
            </TableFrame>
        </>
    );
}

export default CompanyDetail;
