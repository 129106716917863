import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// components
import { List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const checkIsMatched = (pathname: string, path: string): boolean => {
    return pathname.startsWith(path) || pathname.startsWith(path.slice(0, path.length - 1));
}

interface ListProps {
    subMenu: Array<{
        path: string,
        name: string,
        isBlank?: boolean,
    }>,
    pathname: string,
    handleOpenLogoutDialog?: () => void,
}

const SubMenuList: React.FC<ListProps> = ({ subMenu, pathname, handleOpenLogoutDialog }) => {
    const classes = useStyles();
    return (
        <List className={classes.menuList}>
            {subMenu.map((item, i) => (
                <React.Fragment key={i}>
                    <SubMenuItem item={item} pathname={pathname}
                        handleOpenLogoutDialog={handleOpenLogoutDialog} />
                </React.Fragment>
            ))}
        </List>
    );
}

export default SubMenuList;

const SubMenuItem = ({ item, pathname, handleOpenLogoutDialog }) => {
    const classes = useStyles();
    const isSubMatched = item.path == "/" ?
        (pathname == "/" || pathname == "") :
        checkIsMatched(pathname, item.path) && pathname.length <= item.path.length;

    if (item.name == "ログアウト") {
        return (
            <ListItem className={classes.subMenuItem}>
                <Typography onClick={handleOpenLogoutDialog}
                    className={classNames(classes.subName, isSubMatched ? classes.selectedItem : "")}>
                    {item.name}
                </Typography>
            </ListItem>
        )
    } else if (Boolean(item.path)) {
        return (
            <ListItem className={classes.subMenuItem}>
                {item.isBlank ?
                    <Typography onClick={() => window.open(item.path, "_blank")}
                        className={classNames(classes.subName, isSubMatched ? classes.selectedItem : "")}>
                        {item.name}
                    </Typography>
                    :
                    <NavLink to={item.path} className={classes.link}>
                        <Typography className={classNames(classes.subName, isSubMatched ? classes.selectedItem : "")}>
                            {item.name}
                        </Typography>
                    </NavLink>}
            </ListItem>
        );
    } else return <></>
}

export const activeMenuBackgroundColor = "#e2e9e5";

const useStyles = makeStyles({
    selectedItem: {
        backgroundColor: activeMenuBackgroundColor,
    },
    ticketInfo: {
        justifyContent: "center",
        padding: "15px 10px",
    },
    menuList: {
        width: "100%",
        padding: 0,
    },
    link: {
        width: "100%",
        display: "flex",
    },
    subMenuItem: {
        width: "100%",
        padding: 0,
        cursor: "pointer",
    },
    subName: {
        width: "100%",
        padding: "8px 45px",
        transition: "0.1s",
        fontSize: 13,
        "&:hover": {
            backgroundColor: activeMenuBackgroundColor,
        },
    },
})




