import React from 'react';
import { useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

// state
import { RootState } from '../../../../reducers';
const memberSelector = (state: RootState) => state.member;
const meSelector = (state: RootState) => state.me;

interface SelectProps {
    selected: string[],
    handleChange: (email: string, checked: boolean) => void,
}

const MemberSelect: React.FC<SelectProps> = ({ selected, handleChange }) => {
    const classes = useStyles();
    const members = useSelector(memberSelector).list.results;
    const me = useSelector(meSelector);
    const membersExceptMyself = members.filter(({ email }) => email != me.email);

    return (
        <ul>
            {membersExceptMyself.length > 0
                ?
                <>
                    <li className={classes.listDiscription}>
                        <Typography>送信したいメンバーにチェックをつけてください</Typography>
                    </li>
                    {membersExceptMyself.map((member, index) => {
                        const checked = selected.indexOf(member.email) >= 0;
                        return (
                            <li key={index} className={classes.member}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.checkBox}
                                            checked={checked}
                                            onChange={() => handleChange(member.email, checked)}
                                            value={member.email}
                                            color="primary"
                                        />
                                    }
                                    label={<Typography>{member.last_name} {member.first_name}</Typography>}
                                />
                            </li>
                        )
                    })}
                </>
                :
                <Typography className={classes.listDiscription}>送信できるメンバーがいません</Typography>}
        </ul>
    )
}

export default MemberSelect;

const useStyles = makeStyles({
    checkBox: {
        marginLeft: 14,
    },
    member: {
        margin: 10,
    },
    listDiscription: {
        padding: 5,
    },
});
