import React from 'react';
import Title from '../Title';
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/send/reviewRequest/image01.png';
import Image02 from '../../../assets/help/send/reviewRequest/image02.png';
import Image03 from '../../../assets/help/send/reviewRequest/image03.png';

const Create = () => {
	return (
		<div className={style.box}>
			<Title title={"2. 確認依頼（確認フロー機能ONの場合）"}/>
			<SubTitle step={1} title={"確認依頼を出す"}/>
			<Typography gutterBottom variant={"body1"}>
				右上の「確認依頼を出す」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<SubTitle step={2} title={"必須項目の入力"}/>
			<Typography gutterBottom variant={"body1"}>
				ダイアログが表示されます。<br/>
				全ての項目を入力後、「確認依頼」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<SubTitle step={3} title={"確認依頼の取り下げ"}/>
			<Typography gutterBottom variant={"body1"}>
				画面右上の「確認依頼の取り下げ」ボタンから取り下げることができます。<br/>
				確認者には、メールで通知されます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
		</div>
	);
};

export default Create
