enum PressReleaseFirebaseActionType {
	POST_PRESS_RELEASE_ACCESS_DATA_REQUEST = "POST_PRESS_RELEASE_ACCESS_DATA_REQUEST",
	POST_PRESS_RELEASE_ACCESS_DATA_SUCCEEDED = "POST_PRESS_RELEASE_ACCESS_DATA_SUCCEEDED",
	POST_PRESS_RELEASE_ACCESS_DATA_FAILED = "POST_PRESS_RELEASE_ACCESS_DATA_FAILED",

	DELETE_PRESS_RELEASE_ACCESS_DATA_REQUEST = "DELETE_PRESS_RELEASE_ACCESS_DATA_REQUEST",
	DELETE_PRESS_RELEASE_ACCESS_DATA_SUCCEEDED = "DELETE_PRESS_RELEASE_ACCESS_DATA_SUCCEEDED",
	DELETE_PRESS_RELEASE_ACCESS_DATA_FAILED = "DELETE_PRESS_RELEASE_ACCESS_DATA_FAILED",

	POST_PRESS_RELEASE_EDITOR_DATA_REQUEST = "POST_PRESS_RELEASE_EDITOR_DATA_REQUEST",
	POST_PRESS_RELEASE_EDITOR_DATA_SUCCEEDED = "POST_PRESS_RELEASE_EDITOR_DATA_SUCCEEDED",
	POST_PRESS_RELEASE_EDITOR_DATA_FAILED = "POST_PRESS_RELEASE_EDITOR_DATA_FAILED",

	DELETE_PRESS_RELEASE_EDITOR_DATA_REQUEST = "DELETE_PRESS_RELEASE_EDITOR_DATA_REQUEST",
	DELETE_PRESS_RELEASE_EDITOR_DATA_SUCCEEDED = "DELETE_PRESS_RELEASE_EDITOR_DATA_SUCCEEDED",
	DELETE_PRESS_RELEASE_EDITOR_DATA_FAILED = "DELETE_PRESS_RELEASE_EDITOR_DATA_FAILED",

	DISCONNECT_REALTIME_DATABASE_REQUEST = "DISCONNECT_REALTIME_DATABASE_REQUEST",
	DISCONNECT_REALTIME_DATABASE_SUCCEEDED = "DISCONNECT_REALTIME_DATABASE_SUCCEEDED",
	DISCONNECT_REALTIME_DATABASE_FAILED = "DISCONNECT_REALTIME_DATABASE_FAILED",
}

export default PressReleaseFirebaseActionType
