import React from 'react';

//component
import PageTitle from '../PageTitle';
import Title from '../Title';
import SubTitle from '../SubTitle';
import Point from '../Point';

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/briefing/create/image01.png';
import Image02 from '../../../assets/help/briefing/create/image02.png';
import Image03 from '../../../assets/help/briefing/create/image03.png';
import Image04 from '../../../assets/help/briefing/create/image04.png';
import Image05 from '../../../assets/help/briefing/create/image05.png';


const Create = () => {
    return (
        <div className={style.box}>
            <PageTitle title={"ブリーフィング"}/>
            <Point>
                アンケートを作成し、社内・社外へ回答を依頼をすることができます。<br/>
                定期的にネタを広報に集約して、発信に備えることができます。
            </Point>
            <Title title={"1. 新規作成〜回答依頼"}/>
            <SubTitle step={1} title={"新規作成"}/>
            <Typography gutterBottom variant={"body1"}>
                「新規作成」ボタンからブリーフィングシートの新規作成ができます。
            </Typography>
            <div className={style.img}>
                <img src={Image01}/>
            </div>
            <SubTitle step={2} title={"テンプレート選択"}/>
            <Typography gutterBottom variant={"body1"}>
                「このテンプレートで作成する」ボタンを押下してください。
            </Typography>
            <div className={style.img}>
                <img src={Image02}/>
            </div>
            <SubTitle step={3} title={"編集・保存"}/>
            <Typography gutterBottom variant={"body1"}>
                回答形式、質問内容を編集します。<br/>
                回答形式は、テキスト・ラジオボタン・画像アップロードの3種類があります。<br/>
                質問の追加や、並び替えもできます。<br/>
                画面右上の「保存」ボタンから編集内容を保存することができます。
            </Typography>
            <div className={style.img}>
                <img src={Image03}/>
            </div>
            <SubTitle step={4} title={"回答を依頼"}/>
            <Typography gutterBottom variant={"body1"}>
                画面右上の「回答を依頼」ボタンから作成したブリーフィングの回答依頼を出すことができます。<br/>
            </Typography>
            <div className={style.img}>
                <img src={Image04}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                回答の依頼は、プロジェクトに参加しているメンバーに依頼する方法と、メールアドレスを入力して依頼する方法の2種類があります。
            </Typography>
            <div className={style.img}>
                <img src={Image05}/>
            </div>
        </div>
    );
};

export default Create;
