import ActionType from './ActionType';
import {
	PasswordResetEmailModel,
	PasswordResetRegisterValueModel,
	PasswordResetRegisterRequestModel
} from '../../model/ResetModel';
import { createAsyncAction } from 'typesafe-actions';

export const postResetPassword = createAsyncAction(
	ActionType.POST_PASSWORD_RESET_REQUEST,
	ActionType.POST_PASSWORD_RESET_SUCCEEDED,
	ActionType.POST_PASSWORD_RESET_FAILED,
)<PasswordResetEmailModel, void, Error>();

export const postResetPasswordRegister = createAsyncAction(
	ActionType.POST_PASSWORD_RESET_REGISTER_REQUEST,
	ActionType.POST_PASSWORD_RESET_REGISTER_SUCCEEDED,
	ActionType.POST_PASSWORD_RESET_REGISTER_FAILED,
)<PasswordResetRegisterRequestModel, void, Error>();
