import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

// components
import SummaryPage from "../../pages/Analytics/Summary";
import DetailPage from "../../pages/Analytics/Detail";


interface Props extends RouteComponentProps<void> {
}

function DashboardPage(props: Props) {
    return (
        <>
            <Switch>
                {/* <Route exact={true} path="/dashboard/" component={SummaryPage} />
                <Route exact={true} path="/dashboard/:pressReleaseId/detail/" component={DetailPage} /> */}
            </Switch>
        </>

    );
}

export default DashboardPage;

