import React, {useEffect, useState} from 'react';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import {Japanese} from 'flatpickr/dist/l10n/ja';
import dayjs from 'dayjs';

import {Typography, FormControl, Select, MenuItem} from '@material-ui/core/';
import {makeStyles} from '@material-ui/core/styles';
import './datepicker.module.scss';

import carenderImage from '../../../assets/icon_calendar_gray.svg';

const now = new Date();
now.setHours(now.getHours() + 3);
now.setMinutes(0);

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setMinutes(0);

const useStyles = makeStyles({
  error: {
    marginLeft: 10
  },
  datePicker: {
    width: 150,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top 9px left 9px',
    backgroundSize: '17px auto',
    backgroundImage: `url(${carenderImage})`
  },
  releaseDateStyle: {
    color: '#E95050'
  },
  body: {
    margin: '7px 10px 0px 0px',
    fontWeight: 'bold',
    color: '#6d7470',
    fontSize: 16
  },
  selectRoot: {
    fontWeight: 'bold',
    color: '#6d7470',
    fontSize: 16
  },
  select: {
    paddingRight: '20px !important'
  }
});

const DatePickerSetting = ({
  edit,
  datetime,
  handleChangeDatetime,
  isReserve
}) => {
  const classes = useStyles();
  const referenceDate = dayjs(datetime);
  let minDate: any = isReserve ? tomorrow : now;
  minDate = dayjs(minDate).format('YYYY-MM-DD');
  const dt = new Date(datetime);
  const hour: number = dt.getHours();
  const minute: number = dt.getMinutes();

  const hourList = [
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22'
  ];
  const [selectHour, setSelectHour] = useState<number>(8);
  const [selectMinute, setSelectMinute] = useState<number>(30);

  // 一度も日時変更しない場合を想定して、最初にhandleChangeDatetimeをしておく
  useEffect(() => {
    if (hour > 6 && hour < 23) {
      if (minute === 0 || minute === 30) {
        dt.setHours(hour);
        dt.setMinutes(minute);
      } else if (minute > 0 && minute < 30) {
        dt.setHours(hour);
        dt.setMinutes(30);
      } else {
        dt.setHours(hour + 1);
        dt.setMinutes(0);
      }
    } else {
      dt.setHours(8);
      dt.setMinutes(30);
    }
    dt.setSeconds(0);
    handleChangeDatetime(dt);
  }, []);

  // selectの表示切り替えに必要
  useEffect(() => {
    setSelectHour(hour);
    setSelectMinute(minute);
  }, [hour, minute]);

  //TODO
  const error = datetime < new Date() ? true : false;

  const editDatetime = (
    event: React.ChangeEvent<{value: any}>,
    type: string
  ) => {
    if (type === 'date') {
      const changedDatetime = new Date(event[0]);
      changedDatetime.setHours(selectHour);
      changedDatetime.setMinutes(selectMinute);
      changedDatetime.setSeconds(0);
      handleChangeDatetime(changedDatetime);
    } else if (type === 'hour') {
      dt.setHours(event.target.value);
      dt.setMinutes(selectMinute);
      dt.setSeconds(0);
      handleChangeDatetime(dt);
    } else {
      dt.setHours(selectHour);
      dt.setMinutes(event.target.value);
      dt.setSeconds(0);
      handleChangeDatetime(dt);
    }
  };

  return (
    <>
      {edit ? (
        <>
          <Flatpickr
            date="true"
            value={dt}
            className={classes.datePicker}
            options={{
              defaultValue: {},
              locale: Japanese,
              minDate: minDate
            }}
            onChange={e => editDatetime(e, 'date')}
          />
          <div className="timepickr">
            <FormControl>
              <Select
                classes={{
                  root: classes.selectRoot,
                  select: classes.select
                }}
                defaultValue={8}
                value={selectHour}
                onChange={e => editDatetime(e, 'hour')}
              >
                {hourList.map((hour, key) => {
                  const value: number =
                    hour[0] === '0' ? parseInt(hour[1]) : parseInt(hour);
                  return (
                    <MenuItem value={value} key={key}>
                      {hour}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Typography variant="body1" classes={{body1: classes.body}}>
              ：
            </Typography>
            <FormControl>
              <Select
                classes={{
                  root: classes.selectRoot,
                  select: classes.select
                }}
                defaultValue={30}
                value={selectMinute}
                onChange={e => editDatetime(e, 'minute')}
              >
                <MenuItem value={0}>{'00'}</MenuItem>
                <MenuItem value={30}>{'30'}</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/*{error && <Typography color={"error"} className={classes.error}>現在より未来の時刻を設定してください</Typography>}*/}
          {!datetime && (
            <Typography color={'error'} className={classes.error}>
              日時を選択してください。
            </Typography>
          )}
        </>
      ) : (
        <Typography variant={'h6'} className={classes.releaseDateStyle}>
          {referenceDate.format('YYYY年MM月DD日 HH:mm')}
        </Typography>
      )}
    </>
  );
};

export default DatePickerSetting;
