import React, { useEffect } from "react";
import { createStyles, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Terms: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom>
        HARVESTサービス利用規約
      </Typography>
      HARVESTサービス利用規約（以下「本規約」といいます。）には、株式会社m-Lab（以下「当社」といいます。）の提供する本サービスのご利用にあたり、利用希望者及び会員の皆様に遵守していただかなければならない事項及び当社と利用希望者及び会員の皆様との間の権利義務関係が定められております。
      <br />
      本サービスをご利用になる方は、本規約に同意する前に、必ず全文をお読み下さいますようお願い致します。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第1条 適用
      </Typography>
      1.本規約は、本サービスの利用に関する当社と利用希望者及び会員との間の権利義務関係を定めることを目的とし、利用希望者及び会員と当社の間の本サービスの利用に関わる一切の関係に適用されます。
      <br />
      <br />
      2.当社が当社ウェブサイト上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第2条 定義
      </Typography>
      本規約において使用する以下の用語は、各々に定める意味を有するものとします。
      <br />
      <br />
      (1)本サービスとは、当社が提供する「HARVEST」という名称のサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
      <br />
      (2)当社ウェブサイトとは、そのドメインが「harvest.site」及び「app.harvest.site」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）及び付随するウェブサイトを意味します。
      <br />
      (3)利用希望者とは、第3条において定義された「利用希望者」を意味します。
      <br />
      (4)登録情報とは、第3条において定義された「登録情報」を意味します。
      <br />
      (5)会員とは、第3条に基づき本サービスの会員としての登録がなされた法人を意味します。
      <br />
      (6)知的財産権とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
      <br />
      (7)管理用アカウントとは、会員に対して本サービスが最初に作成するアカウントを意味します。
      <br />
      (8)メンバーとは、会員に対してメールアドレス等の情報を提供することで、本サービスで会員が利用を認められている範囲内で、本サービスを共有できる者を意味します。また、
      メンバーには管理用アカウントを持つ会員も含むものとします。
      <br />
      (9)メディアとは、報道機関に限定されず、ウェブディア、新聞、雑誌、テレビ及びラジオなどの発信する能力を備えた媒体を意味します。
      <br />
      (10)プレスリリースとは、メディアに知らせるために会員から発信する情報を意味します。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第3条 利用登録
      </Typography>
      1.本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
      <br />
      <br />
      2.本サービスは法人向けサービスであり、個人事業主の方はご利用いただけません。
      <br />
      <br />
      3.利用希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
      <br />
      <br />
      4.当社は、第1項に基づき申込を行った利用希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
      <br />
      <br />
      (1)本規約に違反するおそれがあると当社が判断した場合
      <br />
      (2)当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
      <br />
      (3)過去に本サービスの利用の登録を取り消された者である場合
      <br />
      (4)反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
      <br />
      (5)その他、当社が登録を適当でないと判断した場合
      <br />
      <br />
      5.利用希望者の登録の申請手続きにおいて管理用アカウントの作成が完了した時点で、利用希望者を会員とみなします。また、管理用アカウントの作成が完了した時点において、サービス利用契約（以下「利用契約」といいます。）が会員と当社の間に成立します。
      <br />
      <br />
      6.会員が登録申請した日を利用開始日とします。
      <br />
      <br />
      7.会員は、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第4条 サービスの利用
      </Typography>
      会員は、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用し、プレスリリース原稿（テキスト、動画、画像、音声等を含むがこれに限らない。以下同じ）の作成及びメディアに対するプレスリリースの配信を行うことができます。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第5条 メンバーの管理
      </Typography>
      1.会員は、メンバー全てに、本規約を遵守させる義務を負います。会員は、メンバーが本サービスを利用する前に、メンバーに本規約及びプライバシーポリシーの内容について伝えるとともに、メンバーが本規約に同意し、遵守することの誓約を得なければなりません。
      <br />
      <br />
      2.会員は、全てのメンバーが本サービスを利用することについて、全ての責任を負うものとします。メンバーの行為が原因で生じたクレームなどにより、当社に損害（弁護士費用その他の費用負担や賠償金の支払いを含みます）が発生した場合、会員は当社の損害を補償するものとします。
      <br />
      <br />
      3.会員は、本サービスのメンバー情報に含まれる個人情報の利用及び管理について、すべて会員の責任により取扱うものとします。当社はかかる個人情報の利用及び管理について責任を負いません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第6条 アカウントの管理
      </Typography>
      1.会員は、本サービスで使用されるログイン用のメールアドレスとそれに紐づくパスワード（管理用アカウントのみではなく、メンバーのものも含みます。以下「アカウント」といいます。）を会員の責任で管理及び保管するものとします。
      <br />
      <br />
      2.アカウントの管理不十分、使用上の過誤、第三者の使用等による損害の責任は会員が負うものとし、当社は一切の責任を負いません。
      <br />
      <br />
      3.会員は、アカウントが盗まれ、又は第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第7条 会員情報、個人情報の取扱い
      </Typography>
      1.当社は原則として、本サービスで開示される会員情報以外の会員情報について、本利用規約に別段の定めがある場合を除き、事前の同意なく第三者に対し開示することはありません。ただし、公的機関からの照会及び当社が法令によって開示義務を負う場合などはこの限りではありません。
      <br />
      <br />
      2.当社は、本サービスの向上、請求及び当社のマーケティングなどの目的で会員情報の集計及び分析などをすることができるものとします。
      <br />
      <br />
      3.当社は、当社のプライバシーポリシーに従い、会員情報及びメンバー情報を管理します。
      <br />
      <br />
      4.会員は、本サービスで取得した個人情報について、本サービスのためだけに使用するものとし、個人情報保護法の定めに従い個人情報を管理するものとします。また、必要に応じて適切に本人の同意を得るものとします。
      <br />
      <br />
      5.会員が本サービスの利用を終了した場合、当社は当該会員が本サービスの利用を終了した日から1年後に当該会員のアカウントを削除します。ただし、会員の情報のうち、第2項のマーケティングなどの目的で使用する、個人及び法人を特定できない情報については、利用の終了後もなお当社が保有し、使用し続けるものとし、会員はこれを予め承諾します。
      <br />
      <br />
      6.本条の規定は、本サービス終了後も有効に存続するものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第8条 外部委託
      </Typography>
      当社は、本サービスの運営について、必要に応じて当社の業務の全部又は一部を外部委託することができるものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第9条 利用料金及び支払方法
      </Typography>
      1.会員は、本サービスの利用料金（以下、「利用料金」という。）として、会員が本サービスのスタートアッププランを選択した場合は当社ウェブサイトに定める利用料金を支払うものとし、会員が本サービスのエンタープライズプランを選択した場合は、別途当社が書面により定めた金額を支払うものとします。支払方法については、クレジットカード決済又は銀行振込のいずれかにより支払うものとし、支払手数料その他の支払いに必要な手数料は会員が負担するものとします。また、利用料は、日割り計算による減額をしないものとします。
      <br />
      <br />
      2.クレジット決済については、当月分の利用料金を前月25日にお支払いいただきます。また、クレジットカードによるお支払いについて、当社は決済に係る取扱いを決済代行事業者に委託しているため、会員の支払情報または決済に関する情報を保有しておらず、かかる情報の取扱いまたは当社に起因しない決済に関する不具合等について一切の責任を負いません。
      <br />
      <br />
      3.銀行振込みによる支払いについては、当社の発行する請求書をもとに、当月分の利用料金を翌月末日までにお支払いいただきます。
      <br />
      <br />
      4.会員がサービスプランを変更した場合、変更のあった月から新しい利用料金が適用されます。この場合においても、利用料金は、日割り計算をしないものとします。
      <br />
      <br />
      5.本サービスでは、サービス利用後のキャンセルのお取り扱いは行わないものとします。
      <br />
      <br />
      6.会員が本サービスで入力した決済手段又は金融機関の情報が第三者に利用されたこと、若しくは入力情報の内容が不正確であったこと又は当社が本条に基づく措置を行ったこともしくは行わなかったことによって会員に生じた損害に関して、当社は一切責任を負わないものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第10条 契約期間
      </Typography>
      会員に対する本サービスの利用期限は、本サービスの終了日又はユーザーに対する本契約の解約若しくは解除の日までとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第11条 禁止行為等
      </Typography>
      1.会員は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
      <br />
      <br />
      (1)本規約の規定に違反する行為
      <br />
      (2)当社、他の会員その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、著作権、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
      <br />
      (3)会員が、社外の第三者に本サービスを使用させる行為
      <br />
      (4)当社又は第三者を誹謗中傷する行為
      <br />
      (5)犯罪行為に関連する行為又は公序良俗に反する行為
      <br />
      (6)猥褻な情報又は青少年に有害な情報を送信する行為
      <br />
      (7)法令又は会員若しくは会員が所属する業界団体の内部規則に違反する行為
      <br />
      (8)コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
      <br />
      (9)本サービスに関し利用しうる情報を改ざんする行為
      <br />
      (10)当社が定める一定のデータ容量以上のデータを、本サービスを通じて送信する行為
      <br />
      (11)当社による本サービスの運営を妨害するおそれのある行為
      <br />
      (12)その他当社が不適切と判断する行為
      <br />
      <br />
      2.会員又は会員が発行したログインIDを利用した者による情報の送信行為が前項第1号から第11号までの各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、会員に通知し改善を依頼します。改善依頼後も会員に改善が見られない場合、又は会員の改善を待つ時間的猶予がない場合、当社は、会員による本サービス上の情報の全部又は一部を削除し、またアカウントを使用できなくすることができるものとします。当社は、本項に基づき当社が行った措置によって会員及び第三者に生じた損害について一切の責任を負いません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第12条 本サービスの停止等
      </Typography>
      1.当社は、以下のいずれかに該当する場合には、会員に事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
      <br />
      <br />
      (1)本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
      <br />
      (2)コンピューター、通信回線等が事故により停止した場合
      <br />
      (3)火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
      <br />
      (4)外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
      <br />
      (5)その他、当社が停止又は中断を必要と判断した場合
      <br />
      <br />
      2.当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第13条 設備の負担等
      </Typography>
      1.本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、会員の費用と責任において行うものとします。
      <br />
      <br />
      2.会員は自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
      <br />
      <br />
      3.会員は、本サービスの利用中に、会員が保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は会員に発生したかかる損害について一切責任を負わないものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第14条 権利帰属
      </Typography>
      1.本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
      <br />
      <br />
      2.会員は、本規約に基づき、本サービスに関する使用権を取得します。また、本サービスにおいて作成されたプレスリリース原稿の知的財産権等は、当該プレスリリースを作成した者に帰属しますが、それ以外の本サービスにおける知的財産権等は、全て当社に帰属します。
      <br />
      <br />
      3.会員は、会員が作成したプレスリリース原稿をメディアに配信することにつき、当社に対して予め許諾するものとします。
      <br />
      <br />
      4.会員は、プレスリリース原稿について、自らが送信することについての適法な権利を有していること、及び当該原稿が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
      <br />
      <br />
      5.会員に帰属する知的財産権の管理は、会員において行うものとし、第三者から当社に対し、知的財産権侵害の申立てがあった場合は、第19条（会員の賠償等の責任）第3項に基づいて会員が対処するものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第15条 プレスリリース原稿の利用
      </Typography>
      1.当社は、会員が提供したプレスリリース原稿を、会員に対する本サービスの提供目的に限り利用し、当社の本サービスの学習用モデルに学習させることはありません。
      <br />
      <br />
      2.当社は、会員が提供したプレスリリース原稿を、法令に基づく開示が求められた場合及び会員の事前の書面等による同意がない限り、第三者に開示または提供いたしません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第16条 反社会的勢力の排除について
      </Typography>
      1.会員は本サービスを利用するにあたって、自身が反社会的勢力（「暴力団員による不当な行為の防止等に関する法律」に定義する暴力団及びその関係団体をいうほか、総会屋、社会運動や政治運動を標ぼうするゴロ、特殊知能暴力団等、その他反社会的勢力の総称をいい、本規約では以後「反社会的勢力」といいます）ではないことを表明し、将来にわたっても、所属もしくは該当、又は関与していないことを確約するものとします。
      <br />
      <br />
      2.当社は、会員が反社会的勢力に所属もしくは該当する、又は関与していると判断した場合、当該会員に事前に通知等を行うことなく、当該会員による本サービスの利用停止、当該会員の会員資格の取消しなど必要な措置をとることができるものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第17条 退会
      </Typography>
      1.本サービスでは、無料サービスを使用している会員は、いつでも退会できます。また、退会により登録の抹消が行われた時点で、本サービスの全部が利用できなくなるものとします。
      <br />
      <br />
      2.会員が月の途中で本契約を解約した場合であっても、当月分の利用料金は支払わなければなりません。また解除された月について、会員が既にその月の利用料金の支払いを済ませていた場合であっても、当社はその月の利用料金を返還せず、日割り計算による減額も行わないものとします。
      <br />
      <br />
      3.退会後に予定していたプレスリリースが発信されなかったこと、又は発信されたことについて、当社は一切の責任を負いません。
      <br />
      <br />
      4.退会後の会員情報の取扱については、第7条(会員情報、個人情報の取扱い)の規定に従うものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第18条 登録取消等
      </Typography>
      1.当社は、会員が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該会員について本サービスの利用を一時的に停止し、又は会員としての登録を取り消すことができます。
      <br />
      <br />
      (1)本規約のいずれかの条項に違反した場合
      <br />
      (2)登録情報に虚偽の事実があることが判明した場合
      <br />
      (3)手段の如何を問わず、本サービスの運営を妨害した場合
      <br />
      (4)支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
      <br />
      (5)自ら振出し、若しくは引受けた手形若しくは小切手につき、不渡りの処分を受けた場合、又は手形交換所の取引停止処分その他これに類する措置を受けたとき
      <br />
      (6)差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
      <br />
      (7)租税公課の滞納処分を受けた場合
      <br />
      (8)法人の代表取締役が死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
      <br />
      (9)第3条（利用登録）第4項各号に該当する場合
      <br />
      (10)その他、当社が会員としての登録の継続を適当でないと判断した場合
      <br />
      <br />
      2.会員が月の途中で本契約を解除された場合であっても、当月分の利用料金は支払わなければなりません。また解除された月について、会員が既にその月の利用料金の支払いを済ませていた場合であっても、当社はその月の利用料金を返還せず、日割り計算による減額も行わないものとします。
      <br />
      <br />
      3.前項各号のいずれかの事由に該当した場合、会員は、当社に対して負っている債務の一切について当然に期限の利益を失います。また、会員が債務の支払を遅滞した場合、会員は登録抹消された日の翌日から起算して年14.6％の割合による遅延損害金を当社に支払うものとします。
      <br />
      <br />
      4.当社は、本条に基づき当社が行った行為により会員及び第三者に生じた損害について一切の責任を負いません。
      <br />
      <br />
      5.本条の基づく解除後に予定していたプレスリリースが発信されなかったこと、又は発信されたことについて、当社は一切の責任を負いません。
      <br />
      <br />
      6.本条に基づく登録抹消後の会員情報の取扱については、第7条(会員情報、個人情報の取扱い)の規定に従うものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第19条 保証の否認及び免責
      </Typography>
      1.当社は、会員が本サービスを利用することについて如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
      <br />
      <br />
      2.会員は、本サービスを利用することが、会員に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、会員による本サービスの利用が、会員に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
      <br />
      <br />
      3.本サービス又は当社ウェブサイトに関連して会員と第三者との間において生じた取引、連絡、紛争等については、会員の責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
      <br />
      <br />
      4.本サービスを通じて会員が第三者に行ったプレスリリースに関連して会員と第三者との間において生じた取引、連絡、紛争等については、会員の責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
      <br />
      <br />
      5.会員によって作成するプレスリリース原稿については、会員の責任において保存を行ってください。当社は本サービスで作成されたプレスリリース原稿について、保存の義務を負いません。
      <br />
      <br />
      6.本サービスは、インターネット環境に合わせるため及び利便性の向上を目的として、日々仕様が変化していきます。仕様が日々変わっていくことについて、会員は予め承知するものとします。
      <br />
      <br />
      7.当社は、本サービスに関連して会員及び第三者が被った損害について、一切賠償の責任を負いません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第20条 会員の賠償等の責任
      </Typography>
      1.会員は、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
      <br />
      <br />
      2.会員が、本サービスに関連して他の会員その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、会員の費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
      <br />
      <br />
      3.会員による本サービスの利用に関連して、当社が、他の会員その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、会員は当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第21条 秘密保持
      </Typography>
      当社及び会員は本サービスに関連して相手方が自己に対して秘密に取り扱うことを求めて開示した非公知の情報について、相手方の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第22条 本規約等の変更及び本サービスの終了
      </Typography>
      1.当社は、本サービスの内容を自由に変更できるものとします。
      <br />
      <br />
      2.当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更した場合には、会員に当該変更内容を通知します。
      <br />
      <br />
      3.当社は、当社の都合により、本サービスの提供を、一定期間の予告をもって終了することができます。当社が本サービスの提供を終了する場合、当社は事前に会員に対して通知するものとします。
      <br />
      <br />
      4.本規約の変更及び終了により会員又は第三者に発生したすべての損害について、当社は一切の責任を負いません。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第23条 連絡/通知
      </Typography>
      1.本サービスに関する問い合わせその他会員から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から会員に対する連絡又は通知は、当社の定める方法で行うものとします。
      <br />
      <br />
      2.会員は、本サービスへの登録申請の際に当社に提供した会員のメールアドレスまたは電話番号が会員に対する連絡先として有効であることを当社に対し保証するものとします。
      <br />
      <br />
      当社が当該メールアドレス宛てに会員に対する通知を行った場合、又は当該電話番号宛に電話した場合において、当社の責に帰さない事由により連絡が到達しなかったとしても、会員はこれについて何ら異議を述べないものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第24条 本規約の譲渡等
      </Typography>
      1.会員は、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく会員としての権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      <br />
      <br />
      2.当社は本サービスにかかる事業を第三者に譲渡（事業譲渡、会社分割その他態様の如何を問わないものとします。）した場合には、当該譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに会員の登録情報その他の情報を当該譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき本項において予め同意したものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第25条 分離可能性
      </Typography>
      本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び会員は、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第26条 準拠法及び管轄裁判所
      </Typography>
      本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        第27条 協議解決
      </Typography>
      当社及び会員は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
      <br />
      <br />
      2020年7月1日 制定
      <br />
      2020年9月17日 変更・施行
      <br />
      2023年12月15日 変更・施行
    </div>
  );
};

export default Terms;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: "80px 100px 0",
      fontSize: 14,
    },
  })
);
