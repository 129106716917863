import React from 'react';
import Create from "./Create";
import ReviewRequest from "./ReviewRequest";
import Review from "./Review";
import Waiting from "./Waiting";

//style
import style from "../help.module.scss";

const Send = () => {

	return (
		<article className={style.root}>
			<section><a id="1" className={style.anchor}/><Create/></section>
			<section><a id="2" className={style.anchor}/><ReviewRequest/></section>
			<section><a id="3" className={style.anchor}/><Review/></section>
			<section><a id="4" className={style.anchor}/><Waiting/></section>
		</article>
	);
};

export default Send;
