import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";
import { styleSheet } from "../../../../config/export_html_stylesheet";
import { Controlled as CodeMirror } from 'react-codemirror2'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { downloadPDF } from "../../../../util/File";

//jsFile
import "codemirror/lib/codemirror.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";

//css
import "codemirror/lib/codemirror.css";
import "./download_dialog.css";

//img
import IconClose from '../../../../assets/icon_close_gray.svg';

//state
import {RootState} from "../../../../reducers";
import { getPdfDownloadUrl } from "../../../../config/press_release_pdf";

const pressReleaseSelector = (state: RootState) => state.pressRelease;

interface DownloadDialogProps {
	isOpen: boolean,
	closeHandle,
	html: string,
	pageType: string,
	exportHtmlLimit: boolean,
}

const useStyles = makeStyles({
	root: {
		position: "relative",
		width: 926,
		height: 530,
		padding: 0
	},
	noContent: {
		position: "relative",
		width: 424,
		padding: 20,
		fontSize: 14,
		textAlign: 'center',
		marginBottom: 20,
	},
	close: {
		position: 'absolute',
		right: 5,
		top: 5,
	},
	title: {
		padding: '0',
		background: '#F3F7F4',
		display: "flex",
		justifyContent: "center",
	},
	action: {
		borderTop: '1px solid #E2E9E5'
	},
	customFontSize: {
		fontSize: 15,
	},
	explanation: {
		maxWidth: 350,
		fontSize: 16,
		whiteSpace: "pre",
	},
});

const DownloadDialog: React.FC<DownloadDialogProps> = ({ isOpen, closeHandle, html, pageType, exportHtmlLimit }) => {

	const classes = useStyles();
	const pressRelease = useSelector(pressReleaseSelector);
	const [htmlTag, setHtmlTag] = React.useState<string>("");
	const [openTip, setOpenTip] = React.useState<boolean>(false);
	const [explanationTip, setExplanationTip] = React.useState<boolean>(false);
	let timer: any;

	const contentClass = (exportHtmlLimit || pageType === 'PAGE_CREATE') ? classes.noContent : classes.root;

	useEffect(() => {
		if (isOpen) {
			if (exportHtmlLimit || pageType === 'PAGE_CREATE') {
				setExplanationTip(false);
			} else {
				setExplanationTip(true);
			}
		}
	}, [isOpen]);

	useEffect(() => {
		if (exportHtmlLimit || pageType === 'PAGE_CREATE') {
			setHtmlTag(html);
		} else {
			if (html) {
				const htmlMixed: string = styleSheet +
					`<div class="froala_editor"><div class="fr-box fr-ltr fr-basic fr-top"><div class="fr-wrapper"><div class="fr-element fr-view">` +
					html +
					`</div></div></div></div>`;
				setHtmlTag(htmlMixed.replace(/\r?\n/g,""));
			}
		}
	}, [html]);

	useEffect(() => {
		clearTimeout(timer);

		if (openTip === true) {
			timer = setTimeout(() => {
				setOpenTip(false);
			}, 1500);
		}
	}, [openTip]);

	const handleDownloadPDF = () => {
		const url = getPdfDownloadUrl(pressRelease.detail.press_id);
		downloadPDF(`${url}?is_download=1`);
	};

	return (
		<Dialog open={isOpen}>
			<Tooltip
				title={`bodyタグの中に貼りつけてください。\n自社用リリースページ等にお使いください。`}
				classes={{ tooltip: classes.explanation }}
				placement="bottom"
				open={explanationTip}
				disableHoverListener
			>
				<DialogTitle className={classes.title}>
					{exportHtmlLimit || pageType === 'PAGE_CREATE' ? 'HTMLを出力できません' : 'HTMLプレビュー'}
					<IconButton aria-label="close" onClick={() => closeHandle()} className={classes.close}>
						<img src={IconClose}/>
					</IconButton>
				</DialogTitle>
			</Tooltip>
			<DialogContent className={contentClass}>
				{exportHtmlLimit || pageType === 'PAGE_CREATE' ?
					<div className='main' dangerouslySetInnerHTML={{__html : htmlTag}} />
					:
					<CodeMirror 
						value={htmlTag}
						options={{
							lineNumbers: true,
							lineWrapping: true,
							mode: 'text/html',
							readOnly: true,
						}}
						onBeforeChange={(editor, data, value) => {
						}}
					/>
				}
			</DialogContent>
			<DialogActions className={classes.action}>
				<Button variant="outlined" size="large" color="primary" onClick={closeHandle}>
					閉じる
				</Button>
				{pageType !== 'PAGE_CREATE' &&
					<Tooltip
						title="PDFを生成中です"
						classes={{ tooltip: classes.customFontSize }}
						placement="top"
						open={pressRelease.PDF.loading}
						arrow
						disableHoverListener
					>
						<Button variant="contained" size="large" color="secondary" onClick={handleDownloadPDF} disabled={pressRelease.PDF.loading}>
							PDFダウンロード
						</Button>
					</Tooltip>
				}
				{!exportHtmlLimit && pageType !== 'PAGE_CREATE' &&
					<Tooltip
						title="コピーしました"
						classes={{ tooltip: classes.customFontSize }}
						placement="top"
						open={openTip}
						arrow
						disableHoverListener
					>
						<CopyToClipboard text={htmlTag}>
							<Button variant="contained" size="large" color="primary"
								onClick={() => {
									setExplanationTip(false);
									setOpenTip(true);
								}}
								disabled={openTip}
							>
								クリップボードにコピー
							</Button>
						</CopyToClipboard>
					</Tooltip>
				}
			</DialogActions>
		</Dialog>
	);
};

export default DownloadDialog;

