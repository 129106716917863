import React from 'react';

//material-ui
import {Dialog, DialogActions, DialogTitle, DialogContent, Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//image
import NoticeImage from "../../../assets/topic_notice_img.png";

interface Props {
    isOpen: boolean,
    handleClose: () => void,
}

export const TopicNoticeDialog: React.FC<Props> = ({isOpen, handleClose}) => {
    const classes = useStyles()

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle className={classes.header}>
                <p className={classes.title}>注意事項</p>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <img src={NoticeImage} className={classes.image}/>
                <Typography className={classes.text}>画像のような警告が表示される場合があります。</Typography>
            </DialogContent>
            <DialogActions className={classes.action}>
                <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles({
    root: {
        width: 424,
        height: 126
    },
    header: {
        minWidth: 424,
        display: 'flex',
        alignItems: 'center',
        color: '#6D74707',
        fontWeight: 'bold',
        background: '#F3F7F4',
        padding: '0 20px',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16
    },
    content: {
        padding: 20,
        fontSize: 14,
        minWidth: 424,
    },
    action: {
        padding: '15px 10px'
    },
    button: {
        background: '#E95050',
        '&:hover': {
            background: '#E95050',
            opacity: 0.7,
        }
    },
    image: {
        border: '1px solid #EAEAEA',
        borderRadius: 8
    },
    text: {
        marginTop: 20
    }
});
