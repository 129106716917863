import IconTemplate from "../../assets/feature/template.svg";
import IconEditor from "../../assets/feature/editor.svg";
import IconFont from "../../assets/feature/font.svg";
import IconCustomBlock from "../../assets/feature/block.svg";
import IconPDF from "../../assets/feature/pdf.svg";
import IconHTML from "../../assets/feature/html.svg";
import IconRobot from "../../assets/feature/robot.svg";
import IconComment from "../../assets/feature/comment.svg";
import IconHistory from "../../assets/feature/history.svg";
import IconReview from "../../assets/feature/review.svg";
import IconCoEdit from "../../assets/feature/co_edit.svg";
import IconSend from "../../assets/feature/send.svg";
import IconWeb from "../../assets/feature/web.svg";
import IconPressKit from "../../assets/feature/press_kit.svg";
import IconBriefing from "../../assets/feature/briefing.svg";
import IconMedia from "../../assets/feature/media.svg";
import IconMember from "../../assets/feature/member.svg";
import IconTopic from "../../assets/feature/topic.svg";
import IconDashBoard from "../../assets/feature/dashboard.svg";
import IconRadar from "../../assets/feature/radar.svg";

export const FeaturesList = [
	{
		title: "プレスリリース",
		subTitle: "メディアに積極的に発信しよう！",
		contents: [
			{
				icon: IconTemplate,
				title: "豊富なテンプレート",
				text: "デザイン別、プレスリリースのテーマ別に多数のテンプレートを用意",
			},
			{
				icon: IconEditor,
				title: "柔軟なエディタ",
				text: "直感的に操作できるエディタで美しいプレスリリースを簡単に作成",
			},
			{
				icon: IconFont,
				title: "フォント",
				text: "Google Fonts対応",
			},
			{
				icon: IconCustomBlock,
				title: "カスタムブロック",
				text: "よく使う文章ブロックを登録管理、文書作成を簡単に",
			},
			{
				icon: IconPDF,
				title: "綺麗なPDF出力",
				text: "画像や罫線、文字を美しく表現可能",
			},
			{
				icon: IconHTML,
				title: "HTML出力",
				text: "レスポンシブ対応",
			},
			{
				icon: IconRobot,
				title: "校閲AI",
				text: "見落としがちな表記揺れや揺らぎをAIがチェック",
			},
			{
				icon: IconComment,
				title: "コメント",
				text: "ブラッシュアップもスムーズに",
			},
			{
				icon: IconHistory,
				title: "履歴管理",
				text: "以前のバージョンに戻すことも簡単",
			},
			{
				icon: IconReview,
				title: "確認フロー",
				text: "校閲から承認、配信までのプロセスを可視化",
			},
			{
				icon: IconCoEdit,
				title: "共同編集",
				text: "クラウド上のデータを共同編集",
			},
			{
				icon: IconRobot,
				title: "AI配信先選定",
				text: "AIがプレスリリースの内容に合った配信先をレコメンド",
			},
			{
				icon: IconSend,
				title: "予約配信",
				text: "楽々作業を予約してミスなく配信",
			},
			{
				icon: IconWeb,
				title: "プレスリリースwebページ",
				text: "自社用リリースページとして活用が可能",
			},
		],
	},
	{
		title: "プレスキット",
		subTitle: "取材用の素材を一つに集約、高画質な画像や動画の受け渡しも簡単に",
		contents: [
			{
				icon: IconPressKit,
				title: "取材用素材管理",
				text: "画像、動画、ファイル、なんでもアップロードが可能",
			},
		],
	},
	{
		title: "ブリーフィングシート",
		subTitle: "社内のネタを広報に集約して、発信に備える",
		contents: [
			{
				icon: IconBriefing,
				title: "アンケート作成・送信",
				text: "作成したアンケートは、社内・社外へ送信が可能",
			},
		],
	},
	{
		title: "その他の機能",
		subTitle: "企業活動や事業の効率化を図る",
		contents: [
			{
				icon: IconMedia,
				title: "メディアリスト管理",
				text: "メディアリストのマスター管理に便利、配信も楽々",
			},
			{
				icon: IconMember,
				title: "メンバー管理",
				text: "一緒に作業するメンバーを管理",
			},
			{
				icon: IconTopic,
				title: "未来トピック",
				text: "未来のネタを収集してニュースを作る！",
			},
			{
				icon: IconDashBoard,
				title: "ダッシュボード",
				text: "プレスリリースの配信先数やメールの開封率をグラフで確認",
			},
			{
				icon: IconRadar,
				title: "記事化レーダー",
				text: "登録したキーワードから記事化した件数を確認",
			},
		],
	},
];

