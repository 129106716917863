import React from 'react';
import PageTitle from "../PageTitle";
import Title from '../Title';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/dashboard/image01.png';


const DashBoard = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"ダッシュボード"}/>
                <Title title={"1. ダッシュボード"}/>
                <Typography gutterBottom variant={"body1"}>
                    配信済みのプレスリリースの配信先数やメール開封率、配信カテゴリ、配信メディア種別をグラフで確認できます。<br/>
                    プレスリリース毎や月別、週間でデータを確認することも可能です。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
            </div>
        </article>
    );
};

export default DashBoard;
