export const convertPressKitIdToTitle = (attachedPressKitIdList: Array<string>, pressKitListInState: Array<any>) => {
  const titleList: Array<string> = [];
  attachedPressKitIdList.forEach(id => {
    pressKitListInState.forEach(pressKit => {
      if (pressKit.id === id) {
        titleList.push(pressKit.title)
      }
    })
  })
  return titleList;
};

export const generatePressKitList = (attachedPressKitIdList: Array<string>, pressKitListInFireStoreState: Array<any>) => {
  const pressKitList: Array<any> = [];
  attachedPressKitIdList.forEach(id => {
    pressKitListInFireStoreState.forEach(pressKit => {
      if (pressKit.id === id) {
        pressKitList.push(pressKit)
      }
    })
  })
  return pressKitList;
};
