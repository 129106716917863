import React, {ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import {push} from "connected-react-router";
import {NavLink} from "react-router-dom";

// component
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import Progress from "../../Common/Progress";
import Breadcrumb from "../../Common/Breadcrumb";
import Menu from "../../Menu";
import Avatar from "../../Common/Avatar";
import AppDialog from "../../Common/AppDialog";
import {activeMenuBackgroundColor} from "../../Menu/SubMenu";

// icons
import ImageSettings from "../../../assets/menu/settings.svg";
import ImageAccount from "../../../assets/menu/account_circle.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// State
import {RootState} from "../../../reducers";
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;

export interface Props {
  children: ReactNode;
  isBriefingAnswer?: boolean;
}

const AppLayout: React.FC<Props> = ({children, isBriefingAnswer}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const group = useSelector(groupSelector);
  const me = useSelector(meSelector);

  useEffect(() => {
    // 初期設定未完了の場合
    if (
      (group.fetched && !group.results.length) ||
      (me.uuid && !me.first_name && !me.last_name)
    ) {
      dispatch(push("/initial_setting/"));
    }
  }, [group.fetched, me]);

  return (
    <div className={classes.root}>
      <Menu />
      <div
        className={isBriefingAnswer ? classes.briefingContent : classes.content}
      >
        <Progress size={"large"} />
        <div className={classes.inner}>
          <main className={classes.main}>
            <div className={classes.breadcrumb}>
              <Breadcrumb />
              <Settings />
            </div>
            {children}
            {/*<Footer/>*/}
          </main>
        </div>
      </div>
    </div>
  );
};
export default AppLayout;

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      margin: 0,
      overflow: "hidden"
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#f3f9f2"
    },
    briefingContent: {
      flexGrow: 1,
      height: "100vh",
      overflow: "scroll"
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "scroll"
    },
    inner: {
      padding: theme.spacing(3)
    },
    innerFullWidth: {
      height: "100%"
    },
    main: {
      maxWidth: 1000,
      margin: "auto"
    },
    mainFullWidth: {
      maxWidth: "100%",
      margin: "auto"
    },
    breadcrumbFullWidth: {
      margin: "24px auto",
      position: "relative",
      height: 44,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "calc(100% - 48px)"
    },
    breadcrumb: {
      marginBottom: 15,
      display: "flex",
      justifyContent: "space-between",
      height: 50,
      position: "relative"
    },
    settings: {
      display: "flex",
      zIndex: 10,
      height: 44
    },
    subName: {
      width: "100%",
      fontSize: 12,
      padding: "10px 15px",
      transition: "0.1s",
      "&:hover": {
        backgroundColor: activeMenuBackgroundColor
      }
    },
    subItem: {
      cursor: "pointer",
      padding: 0
    },
    subLink: {
      width: "100%"
    },
    subMenu: {
      width: "100%"
    },
    settingAccordion: {
      border: "1px solid #e3e9e3",
      position: "absolute",
      zIndex: 10,
      right: 180,
      width: 145
    },
    accountAccordion: {
      border: "1px solid #e3e9e3",
      position: "absolute",
      zIndex: 10,
      right: 0,
      width: 160
    },
    accordionSummary: {
      display: "flex",
      alignItems: "center"
    },
    accordionSummaryInner: {
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
      marginRight: 10
    },
    icon: {
      margin: 0
    }
  })
);

export const AppLayoutFullWidth: React.FC<Props> = ({children}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <Menu />
      </Drawer>
      <div className={classes.content}>
        <Progress size={"large"} />
        <div className={classes.innerFullWidth}>
          <div className={classes.breadcrumbFullWidth}>
            <Breadcrumb />
            <Settings />
          </div>
          <main className={classes.mainFullWidth}>
            {children}
            {/*<Footer/>*/}
          </main>
        </div>
      </div>
    </div>
  );
};

const settingSubMenu = [
  {
    path: "/setting/company/",
    name: "企業設定"
  },
  {
    path: "/setting/service/",
    name: "サービス"
  },
  {
    path: "/setting/radar/",
    name: "記事化レーダー設定"
  },
  {
    path: "/setting/plan/",
    name: "プラン/オプション"
  },
  {
    path: "/setting/payment/",
    name: "ご契約情報"
  }
];

const accountSubMenu = [
  {
    path: "/user/me/",
    name: "個人設定"
  },
  {
    path: "/user/password/edit/",
    name: "パスワード設定"
  },
  {
    path: "/user/group/",
    name: "参加プロジェクト"
  },
  {
    path: "#",
    name: "ログアウト"
  }
];

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [isCompanyExpanded, setIsCompanyExpanded] = useState(false);
  const [isAccountExpanded, setIsAccountExpanded] = useState(false);
  const me = useSelector(meSelector);

  const handleLogout = () => {
    setLogoutDialog(false);
    dispatch(push("/sign_in/"));
  };

  return (
    <div className={classes.settings}>
      <Accordion
        className={classes.settingAccordion}
        expanded={isCompanyExpanded}
      >
        <ClickAwayListener onClickAway={() => setIsCompanyExpanded(false)}>
          <AccordionSummary
            className={classes.accordionSummary}
            onClick={() => setIsCompanyExpanded(!isCompanyExpanded)}
          >
            <div className={classes.accordionSummaryInner}>
              <ListItemIcon className={classes.icon}>
                <img src={ImageSettings} />
              </ListItemIcon>
              <Typography variant="h6">企業情報</Typography>
            </div>
            <ExpandMoreIcon />
          </AccordionSummary>
        </ClickAwayListener>
        <AccordionDetails>
          <List className={classes.subMenu}>
            {settingSubMenu.map((item, i) => (
              <SubMenuItem item={item} key={i} />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.accountAccordion}
        expanded={isAccountExpanded}
      >
        <ClickAwayListener onClickAway={() => setIsAccountExpanded(false)}>
          <AccordionSummary
            className={classes.accordionSummary}
            onClick={() => setIsAccountExpanded(!isAccountExpanded)}
          >
            <div className={classes.accordionSummaryInner}>
              <ListItemIcon className={classes.icon}>
                {me.img ? (
                  <Avatar src={me.img} name={me.name} size="small" />
                ) : (
                  <img src={ImageAccount} />
                )}
              </ListItemIcon>
              <Typography variant="h6">アカウント</Typography>
            </div>
            <ExpandMoreIcon />
          </AccordionSummary>
        </ClickAwayListener>
        <AccordionDetails>
          <List className={classes.subMenu}>
            {accountSubMenu.map((item, i) => (
              <SubMenuItem
                key={i}
                item={item}
                handleOpenLogoutDialog={() => setLogoutDialog(true)}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <AppDialog
        isOpen={logoutDialog}
        closeHandle={() => setLogoutDialog(false)}
        mainHandle={handleLogout}
        text="ログアウトしますか"
        ButtonText="ログアウト"
      />
    </div>
  );
};

interface IProps {
  item: any;
  handleOpenLogoutDialog?: () => void;
}

const SubMenuItem: React.FC<IProps> = ({item, handleOpenLogoutDialog}) => {
  const classes = useStyles();
  if (item.name == "ログアウト") {
    return (
      <ListItem className={classes.subItem}>
        <Typography
          className={classes.subName}
          onClick={handleOpenLogoutDialog}
        >
          {item.name}
        </Typography>
      </ListItem>
    );
  } else {
    return (
      <ListItem className={classes.subItem}>
        <NavLink to={item.path} className={classes.subLink}>
          <Typography className={classes.subName}>{item.name}</Typography>
        </NavLink>
      </ListItem>
    );
  }
};
