import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import NameChangeForm from '../../../Form/NameChangeForm'

//action
import * as PressKitActionCreators from '../../../../actions/PressKit/ActionCreator'

//material-ui
import {
  Divider,
  Menu,
  MenuItem,
  Typography,
  Popover,
  TextField
} from '@material-ui/core'

const groupIdSelector = state => state.group.selectedId
const pressKitIdSelector = state => state.pressKit.add.selectedPressKit

interface PopoverFormProps {
  displayNameEl: HTMLElement | null,
  handleCloseMenu: any,
  handleClosePopover: any,
  handleSubmit: any,
  name?: string,
  title?: string,
}

const PopoverForm: React.FC<PopoverFormProps> = ({ displayNameEl, handleCloseMenu, handleClosePopover, handleSubmit, name, title }) => {
  const isPressKit = !!title
  const targetName = name || title

  const getWidth = (isPressKit: boolean, targetName?: string) => {
    const defaultWidth = isPressKit ? 245 : 500
    if (!targetName) {
      return defaultWidth
    }

    const onewordPx = 14
    const nameLength = targetName.length
    const width = nameLength * onewordPx

    if (isPressKit && width < defaultWidth) {
      return defaultWidth
    }
    if (!isPressKit && width < defaultWidth) {
      return defaultWidth
    }
    return width
  }

  const width = getWidth(isPressKit, targetName)

  return (
    <Popover
      anchorEl={displayNameEl}
      open={Boolean(displayNameEl)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClosePopover}
      onEntered={handleCloseMenu}
    >
      <NameChangeForm onSubmit={handleSubmit} width={width} />
    </Popover>
  )
}

interface ListMenuItemProps {
  anchorEl: HTMLElement | null,
  handleClose: any,
  handleEditClick: any,
  handleDeleteClick: any,
}

export const ListMenuItem: React.FC<ListMenuItemProps> = ({ anchorEl, handleClose, handleEditClick, handleDeleteClick }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      keepMounted
    >
      <MenuItem onClick={handleEditClick}>
        <Typography>名前を変更</Typography>
      </MenuItem>
      <Divider/>
      <MenuItem onClick={handleDeleteClick}>
        <Typography color='error'>削除</Typography>
      </MenuItem>
    </Menu>
  )
}

interface ListMenuProps {
  item: any,
  anchorEl: HTMLElement | null,
  handleClose: any,
}

export const ListMenu: React.FC<ListMenuProps> = ({ item, anchorEl, handleClose }) => {

  const dispatch = useDispatch()
  const groupId = useSelector(groupIdSelector)
  const pressKitId = useSelector(pressKitIdSelector)
  const [displayNameEl, setDisplayNameEl] = useState<HTMLElement | null>(null)

  const handleEditClick = ()  => {
    const targetId = item.id
    const displayName = item.displayName
    const element = document.getElementById(targetId)
    dispatch(PressKitActionCreators.setPressKitEditTarget({ targetName: displayName }))
    setDisplayNameEl(element)
    handleClose()
  }

  const handleClosePopover = () => {
    setDisplayNameEl(null)
    dispatch(PressKitActionCreators.setPressKitEditTarget({ targetName: '' }))
  }

  const handleSubmit = (values) => {
    const newName = values.nameChange
    const fileId = item.id
    dispatch(PressKitActionCreators.fileNameEdit.request({ newName, groupId, pressKitId, fileId }))
    dispatch(PressKitActionCreators.setPressKitEditTarget({ targetName: '' }))
    handleClosePopover()
  }

  const handleDeleteClick = () => {
    const targetDBId = item.dbId
    dispatch(PressKitActionCreators.deletePressKitUploadFile.request(targetDBId))
    handleClose()
  }

  return (
    <>
    <ListMenuItem
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleEditClick={handleEditClick}
      handleDeleteClick={handleDeleteClick}
    />
    <PopoverForm
      displayNameEl={displayNameEl}
      handleCloseMenu={handleClose}
      handleClosePopover={handleClosePopover}
      handleSubmit={handleSubmit}
      name={item.displayName}
    />
    </>
  )
}

export const PressKitListMenu: React.FC<ListMenuProps> = ({ item, anchorEl, handleClose }) => {

  const dispatch = useDispatch()
  const groupId = useSelector(groupIdSelector)
  const pressKitId = item.id
  const [displayNameEl, setDisplayNameEl] = useState<HTMLElement | null>(null)

  const handleEditClick = ()  => {
    const targetId = item.id
    const displayName = item.title
    const element = document.getElementById(targetId)
    dispatch(PressKitActionCreators.setPressKitEditTarget({ targetName: displayName }))
    setDisplayNameEl(element)
    handleClose()
  }

  const handleClosePopover = () => {
    setDisplayNameEl(null)
    dispatch(PressKitActionCreators.setPressKitEditTarget({ targetName: '' }))
  }

  const handleSubmit = (values) => {
    const newName = values.nameChange
    dispatch(PressKitActionCreators.pressKitNameEdit.request({ newName, groupId, pressKitId }))
    dispatch(PressKitActionCreators.setPressKitEditTarget({ targetName: '' }))
    handleClosePopover()
  }

  const handleDeleteClick = () => {
    const targetId = item.id
    dispatch(PressKitActionCreators.setPressKitDeleteTarget(targetId))
    dispatch(PressKitActionCreators.setPressKitDeleteDialog(true))
    handleClose()
  }

  return (
    <>
    <ListMenuItem
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleEditClick={handleEditClick}
      handleDeleteClick={handleDeleteClick}
    />
    <PopoverForm
      displayNameEl={displayNameEl}
      handleCloseMenu={handleClose}
      handleClosePopover={handleClosePopover}
      handleSubmit={handleSubmit}
      title={item.title}
    />
    </>
  )
}
