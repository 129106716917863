import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";

// models
import { SignInModel } from "../../model/SignModel";
import { PasswordResetRegisterValueModel, PasswordResetRegisterRequestModel } from "../../model/ResetModel";
import { RootState } from "../../reducers";
// actions
import * as SignActionCreators from "../../actions/Sign/ActionCreator";
import * as ResetActionCreators from "../../actions/Reset/ActionCreator";
// components
import ResetPasswordRegisterForm from "../../components/Form/ResetPasswordRegister";
import PanelLayout from "../../components/Layout/Panel";
import { Link, Typography } from "@material-ui/core";

const locationSelector = state => state.router.location;

interface Props extends RouteComponentProps<void> {}

const NewPassword: React.FC<Props> = () => {

	const dispatch = useDispatch();
	const location = useSelector(locationSelector);
	const query = location.query;

	const handleSubmit = (values: PasswordResetRegisterValueModel) => {
		const { password1, password2 } = values;
		const passwords = { new_password1: password1, new_password2: password2 };
		const data: PasswordResetRegisterRequestModel = Object.assign({}, query, passwords);
		dispatch(ResetActionCreators.postResetPasswordRegister.request(data));
	}

	return (
		<PanelLayout>
			<Typography variant="h1" component="h2" gutterBottom>
				パスワードを再設定
			</Typography>
			<Typography variant="caption" gutterBottom>
				新しいパスワードを設定してください。
			</Typography>
			<ResetPasswordRegisterForm onSubmit={handleSubmit} />
			<Typography variant="caption" gutterBottom>
				アカウントをお持ちの方は<Typography variant="caption" color={"primary"}><NavLink exact to={`/sign_in/`}>ログイン</NavLink></Typography>へ
			</Typography>
		</PanelLayout>
	);
}

export default NewPassword;
