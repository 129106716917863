import React from "react";
import {useSelector} from "react-redux";

// component
import AvatarChip from "../../../../../Common/AvatarChip";

import {makeStyles, Typography} from "@material-ui/core/";

// state
import {RootState} from "../../../../../../reducers";

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
    button: {
        marginTop: '1rem',
        width: '72px',
        borderRadius: '6.2rem'
    },
    item: {
        marginBottom: 10
    }
});


interface IProps {
}

const Reviewer: React.FC<IProps> = () => {

    const classes = useStyles();
    const pressReleaseReserve = useSelector(pressReleaseReserveSelector);

    return (
        <div className={classes.root}>
            {pressReleaseReserve.data.reviewer ?
                <ul>
                    {pressReleaseReserve.data.reviewer.map((item, index) => (
                        <Member member={item} key={index}/>
                    ))}
                </ul>
                : ''}
        </div>
    );
};

export default Reviewer;

interface ReviewProps {
    member: {
        user,
        status,
        comment,
        updated_at
    }
}

const Member: React.FC<ReviewProps> = ({member}) => {

    const classes = useStyles();

    return (
        <li className={classes.item}>
            {member.status === null &&
                <AvatarChip user={member.user} message={<p>未確認</p>}/>
            }

            {member.status === true &&
            <AvatarChip user={member.user} color={"blue"}/>
            }
            {member.status === false &&
                <AvatarChip user={member.user} color={"yellow"} updated_at={member.updated_at} message={
                    <>
                        {member.comment && <Typography variant="body1">{member.comment}</Typography>}
                    </>
                }/>
            }
        </li>
    );
};
