import React from 'react';
import PageTitle from "../PageTitle";
import Title from '../Title';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/radar/image01.png';


const Radar = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"記事化レーダー"}/>
                <Title title={"1. 記事化レーダー"}/>
                <Typography gutterBottom variant={"body1"}>
                    <Typography gutterBottom variant={"body1"} color={"error"}>
                       事前にオプションを追加する必要があります。
                    </Typography>
                    登録した検索キーワード・除外キーワードを元に、検出された記事数をグラフで確認することができます。<br/>
                    検出された記事を検索することもできます。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
            </div>
        </article>
    );
};

export default Radar;
