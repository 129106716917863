import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// components
import AppLayout from "../../components/Layout/App";
import List from "../../components/Briefing/List";

// actions
import * as BriefingActionCreators from '../../actions/Briefing/ActionCreator'

// config
import { BRIEFING_PAGE_TYPES } from '../../config/briefing';

// state
import { RootState } from '../../reducers';
const briefingListSelector = (state: RootState) => state.briefingList;
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;

const ListPage: React.FC = () => {
    const dispatch = useDispatch();
    const list = useSelector(briefingListSelector);
    const group = useSelector(groupSelector);
    const me = useSelector(meSelector);
    useEffect(() => {
        if (group.selectedId && me.email) {
            dispatch(BriefingActionCreators.getBriefingListReceived.request())
        }
    }, [group.selectedId, me.email])

    return (
        <AppLayout>
            <List list={list.received} pageType={BRIEFING_PAGE_TYPES.LIST_RECEIVED} />
        </AppLayout>
    );
}

export default ListPage;
