import React from 'react';

//component
import PageTitle from "../PageTitle";
import Title from '../Title';

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/setting/radar/image01.png';
import Image02 from '../../../assets/help/setting/radar/image02.png';


const RadarKeyword = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"各種設定"}/>
                <Title title={"5. 記事化レーダー設定"}/>
                <Typography gutterBottom variant={"body1"}>
                    <Typography gutterBottom variant={"body1"} color={"error"}>
                       事前にオプションを追加する必要があります。
                    </Typography>
                    右上のプルダウンから「記事化レーダー設定」を選択します。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
                <Typography gutterBottom variant={"body1"}>
                    記事化レーダーで使用する検索キーワード・除外キーワードの設定・編集ができます。<br/>
                    既存のキーワードを編集した場合、翌日から変更の結果が反映されます。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>
            </div>
        </article>
    );
};

export default RadarKeyword;
