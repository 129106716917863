import { TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';

interface IProps {
    value: string,
    label?: string,
    autoFocus?: boolean,
    handleChange,
    fontSize?: 'medium' | 'small'
}

const SimpleTextField: React.FC<IProps> = ({ value, handleChange, label = '', autoFocus, fontSize = 'medium' }) => {
    const classes = useStyles();

    return (
        <TextField
            value={value}
            onChange={handleChange}
            variant="outlined"
            placeholder={label}
            autoFocus={autoFocus}
            InputProps={{
                classes: {
                    input: `${classes.input}${fontSize === 'small' && 'font-size:11px;'}`,
                },
            }}
        />
    );
}

export default SimpleTextField;

const useStyles = makeStyles({
    input: {
        padding: 10,
    },
})
