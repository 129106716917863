enum PressReleaseReserveActionType {

	POST_PRESS_RELEASE_MEDIA_RECOMMEND_REQUEST = "POST_PRESS_RELEASE_MEDIA_RECOMMEND_REQUEST",
	POST_PRESS_RELEASE_MEDIA_RECOMMEND_SUCCEEDED = "POST_PRESS_RELEASE_MEDIA_RECOMMEND_SUCCEEDED",
	POST_PRESS_RELEASE_MEDIA_RECOMMEND_FAILED = "POST_PRESS_RELEASE_MEDIA_RECOMMEND_FAILED",

	GET_PRESS_RELEASE_CUSTOM_MEDIA_REQUEST = "GET_PRESS_RELEASE_CUSTOM_MEDIA_REQUEST",
	GET_PRESS_RELEASE_CUSTOM_MEDIA_SUCCEEDED = "GET_PRESS_RELEASE_CUSTOM_MEDIA_SUCCEEDED",
	GET_PRESS_RELEASE_CUSTOM_MEDIA_FAILED = "GET_PRESS_RELEASE_CUSTOM_MEDIA_FAILED",

	GET_PRESS_RELEASE_AUTO_MEDIA_REQUEST = "GET_PRESS_RELEASE_AUTO_MEDIA_REQUEST",
	GET_PRESS_RELEASE_AUTO_MEDIA_SUCCEEDED = "GET_PRESS_RELEASE_AUTO_MEDIA_SUCCEEDED",
	GET_PRESS_RELEASE_AUTO_MEDIA_FAILED = "GET_PRESS_RELEASE_AUTO_MEDIA_FAILED",

	GET_PRESS_RELEASE_RESERVE_REQUEST = "GET_PRESS_RELEASE_RESERVE_REQUEST",
	GET_PRESS_RELEASE_RESERVE_SUCCEEDED = "GET_PRESS_RELEASE_RESERVE_SUCCEEDED",
	GET_PRESS_RELEASE_RESERVE_FAILED = "GET_PRESS_RELEASE_RESERVE_FAILED",

	POST_PRESS_RELEASE_RESERVE_REQUEST = "POST_PRESS_RELEASE_RESERVE_REQUEST",
	POST_PRESS_RELEASE_RESERVE_SUCCEEDED = "POST_PRESS_RELEASE_RESERVE_SUCCEEDED",
	POST_PRESS_RELEASE_RESERVE_FAILED = "POST_PRESS_RELEASE_RESERVE_FAILED",

	DELETE_PRESS_RELEASE_RESERVE_REQUEST = "DELETE_PRESS_RELEASE_RESERVE_REQUEST",
	DELETE_PRESS_RELEASE_RESERVE_SUCCEEDED = "DELETE_PRESS_RELEASE_RESERVE_SUCCEEDED",
	DELETE_PRESS_RELEASE_RESERVE_FAILED = "DELETE_PRESS_RELEASE_RESERVE_FAILED",

	SET_PRESS_RELEASE_RESERVE_CUSTOM = "SET_PRESS_RELEASE_RESERVE_CUSTOM",
    SET_PRESS_RELEASE_RESERVE_COPY = "SET_PRESS_RELEASE_RESERVE_COPY",
	SET_PRESS_RELEASE_RESERVE_AUTO = "SET_PRESS_RELEASE_RESERVE_AUTO",
	SET_PRESS_RELEASE_RESERVE_DATETIME = "SET_PRESS_RELEASE_RESERVE_DATETIME",
	SET_PRESS_RELEASE_RESERVE_ACTIVE = "SET_PRESS_RELEASE_RESERVE_ACTIVE",
	SET_PRESS_RELEASE_RESERVE_REVIEW = "SET_PRESS_RELEASE_RESERVE_REVIEW",

	POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_REQUEST = "POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_REQUEST",
	POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_SUCCEEDED = "POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_SUCCEEDED",
	POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_FAILED = "POST_PRESS_RELEASE_RESERVE_REVIEW_RESULT_FAILED",

	SET_ATTACHED_PRESS_KIT_ID = 'SET_ATTACHED_PRESS_KIT_ID',

	SET_PRESS_RELEASE_RESERVE_AUTO_MEDIA = "SET_PRESS_RELEASE_RESERVE_AUTO_MEDIA",

	SET_PRESS_RELEASE_RESERVE_SAVE_LOADING = "SET_PRESS_RELEASE_RESERVE_SAVE_LOADING",

	SET_PRESS_RELEASE_RESERVE_AUTO_MEDIA_LIST = 'SET_PRESS_RELEASE_RESERVE_AUTO_MEDIA_LIST',

	ADD_PRESS_RELEASE_RESERVE_MEDIA_ALL = 'ADD_PRESS_RELEASE_RESERVE_MEDIA_ALL',

	REMOVE_PRESS_RELEASE_RESERVE_MEDIA_ALL = 'REMOVE_PRESS_RELEASE_RESERVE_MEDIA_ALL',

	SET_MEDIA_LIST_ID = 'SET_MEDIA_LIST_ID',

	POST_MAIL_CHECK_REQUEST = 'POST_MAIL_CHECK_REQUEST',
	POST_MAIL_CHECK_SUCCEEDED = 'POST_MAIL_CHECK_SUCCEEDED',
	POST_MAIL_CHECK_FAILED = 'POST_MAIL_CHECK_FAILED',

	SET_PRESS_RELEASE_RESERVE_CATEGORY = 'SET_PRESS_RELEASE_RESERVE_CATEGORY',
	SET_PRESS_RELEASE_RESERVE_TYPE = 'SET_PRESS_RELEASE_RESERVE_TYPE',

	CLEAR_PRESS_RELEASE_RESERVE_STATE = 'CLEAR_PRESS_RELEASE_RESERVE_STATE',
}

export default PressReleaseReserveActionType

