import { takeLatest, put, call, select, takeEvery, all } from 'redux-saga/effects'
import { selectFirestore, selectGroup, selectPressKit, selectMe } from './selector'
import store from 'store'
import { fetchPostForm, fetchDelete } from './fetch'
import { makeUpdateList } from '../util/Sort';

//action
import PressKitActionType from '../actions/PressKit/ActionType'
import * as ActionCreators from '../actions/PressKit/ActionCreator'

//firestore
import firebase from 'firebase/app'
import { db } from '../configureFirebase'

//model
import { RootState } from "../reducers"
import { PressKitFirestoreFileModel, PressKitFirestoreModel } from '../model/PressKitModel'

const selectFirestoreFiles = (state: RootState) => state.pressKit.firestore.files;
const selectFirestorePressKits = (state:  RootState) => state.pressKit.firestore.pressKits;
const selectSorting = (state: RootState) => state.pressKit.edit.sorting;
const selectPressKitSorting = (state: RootState) => state.pressKit.edit.pressKitSorting;

function* addPressKit(action) {
  const groupId = store.get('groupId')
  const createdAt = firebase.firestore.FieldValue.serverTimestamp()
  const order = yield getOrderFromFirestore(groupId)
  const field = {
    order: order,
    title: action.payload,
    isDelete: false,
    createdAt: createdAt
  }
  try{
    yield db.collection('PressKit').doc(groupId).collection('pressKits').add(field)
    yield put(ActionCreators.addPressKit.success())
    yield put(ActionCreators.syncFirestorePressKit.request({ groupId }))
  } catch (error) {
    yield put(ActionCreators.addPressKit.failure(error))
  }
}

function* createGroupIdDoc(action) {
  const createdAt = firebase.firestore.FieldValue.serverTimestamp()
  const field = { createdAt }
  const group = yield select(selectGroup)
  const groupId = group.selectedId
  try {
    yield db.collection('PressKit').doc(groupId).set(field)
    yield put(ActionCreators.createGroupIdDoc.success())
  } catch (error) {
    yield put(ActionCreators.createGroupIdDoc.failure(error))
  }
}

function* fileUpload(action: ReturnType<typeof ActionCreators.postPressKitFileUpload.request>) {
  try{
    const group = yield select(selectGroup)
    const formData = new FormData();
    const request = action.payload;
    if (request.file) formData.append('file', request.file);
    if (request.name) formData.append('name', request.name);
    const [data,error] = yield call(fetchPostForm, `groups/${group.selectedId}/presskit_file`, formData);
    const actionOrder = action.payload.actionOrder
    const size = action.payload.file.size
    const { file: url, name: displayName, id: dbId } = data
    const filename = urlToFilename(url)

    yield put(ActionCreators.postPressKitFileUpload.success({ actionOrder }))
    yield put(ActionCreators.registerFirestore.request({ dbId, filename, displayName, url, size }))
  } catch (e) {
    yield put(ActionCreators.postPressKitFileUpload.failure(e))
  }
}

const urlToFilename = (url: string) => {
  const splitArr = url.split('/')
  const _filename = splitArr[6]
  const filename = _filename.split('?Expires')[0]
  return filename
}

const getOrderFromFirestore = async (groupId: string, pressKitId?: string) => {
  if (pressKitId) {
    const filesColRef = db.collection('PressKit').doc(groupId)
                  .collection('pressKits').doc(pressKitId).collection('files')
    const fileOrder = await performGetOrder(filesColRef)
    return fileOrder
  }
  const pressKitColRef = db.collection('PressKit').doc(groupId).collection('pressKits')
  const pressKitOrder = await performGetOrder(pressKitColRef)
  return pressKitOrder
}

const performGetOrder = async (colRef: ReturnType<typeof db.collection>) => {
  const query = colRef.orderBy('order', 'desc').limit(1)
  const querySnapshot = await query.get()
  const doc = querySnapshot.docs[0]
  if (!doc || !doc.exists) return 0
  const data = doc.data()
  const order: number = data.order
  return order + 1
}

function* registerFirestore(action: ReturnType<typeof ActionCreators.registerFirestore.request>) {
  const pressKit = yield select(selectPressKit)
  const group = yield select(selectGroup)
  const me = yield select(selectMe)
  const firestoreFiles: Array<PressKitFirestoreFileModel> = yield select(selectFirestoreFiles)
  const selectedPressKit = pressKit.add.selectedPressKit
  const groupId = group.selectedId
  const createdAt = firebase.firestore.FieldValue.serverTimestamp()
  const order: number = yield getOrderFromFirestore(groupId, selectedPressKit)
  const createUser = `${me.last_name} ${me.first_name}`
  const values = { createdAt, order, createUser }
  const registerField = Object.assign({}, action.payload, values)

  try {
    yield db.collection('PressKit').doc(groupId).collection('pressKits').doc(selectedPressKit)
            .collection('files').doc().set(registerField)
    yield put(ActionCreators.registerFirestore.success())
    yield put(ActionCreators.syncFirestoreFiles.request({ groupId, pressKitId: selectedPressKit }))
  } catch (e) {
    yield put(ActionCreators.registerFirestore.failure(e))
  }
}

function* fileNameEdit(action) {
  const { newName, groupId, pressKitId, fileId } = action.payload

  try {
    yield db.collection('PressKit').doc(groupId).collection('pressKits').doc(pressKitId)
            .collection('files').doc(fileId).update({displayName: newName})
    yield put(ActionCreators.fileNameEdit.success())
    yield put(ActionCreators.syncFirestoreFiles.request({ groupId, pressKitId }))
  } catch(error) {
    yield put(ActionCreators.fileNameEdit.failure(error))
  }
}

function* pressKitNameEdit(action) {
  const { newName, groupId, pressKitId } = action.payload

  try {
    yield db.collection('PressKit').doc(groupId).collection('pressKits').doc(pressKitId).update({title: newName})
    yield put(ActionCreators.pressKitNameEdit.success())
    yield put(ActionCreators.syncFirestorePressKit.request({ groupId }))
  } catch(error) {
    yield put(ActionCreators.pressKitNameEdit.failure(error))
  }
}

function* deleteFile(action: ReturnType<typeof ActionCreators.deletePressKitUploadFile.request>) {
  try{
    const group = yield select(selectGroup)
    const id = action.payload
    const [data, error] = yield call(fetchDelete, `groups/${group.selectedId}/presskit_file/${id}/`)
    yield put(ActionCreators.deleteFirestoreFile.request(data))
    yield put(ActionCreators.deletePressKitUploadFile.success())
  } catch (e) {
    yield put(ActionCreators.deletePressKitUploadFile.failure(e))
  }
}

function* deleteFirestore(action: ReturnType<typeof ActionCreators.deleteFirestoreFile.request>) {
  const { id: dbId } = action.payload
  const firestore = yield select(selectFirestore)
  const pressKit = yield select(selectPressKit)
  const group = yield select(selectGroup)
  const firestoreFiles: Array<PressKitFirestoreFileModel> = yield select(selectFirestoreFiles)
  const selectedPressKit = pressKit.add.selectedPressKit
  const groupId = group.selectedId
  const targetFile = firestoreFiles.find(file => file.dbId == dbId)

  try {
    const targetId = targetFile ? targetFile.id : undefined
    yield db.collection('PressKit').doc(groupId).collection('pressKits').doc(selectedPressKit)
            .collection('files').doc(targetId).delete()
    yield put(ActionCreators.deleteFirestoreFile.success())
    yield put(ActionCreators.syncFirestoreFiles.request({ groupId, pressKitId: selectedPressKit }))
  } catch(e) {
    yield put(ActionCreators.deleteFirestoreFile.failure(e))
  }
}

function* logicalDeletePressKit(action: ReturnType<typeof ActionCreators.logicalDeletePressKit.request>) {
  const targetPressKitId = action.payload
  const group = yield select(selectGroup)
  const groupId = group.selectedId
  try {
    yield db.collection('PressKit').doc(groupId).collection('pressKits').doc(targetPressKitId)
          .update({isDelete: true})
    yield put(ActionCreators.logicalDeletePressKit.success(targetPressKitId))
    yield put(ActionCreators.syncFirestorePressKit.request({ groupId }))
  } catch (e) {
    yield put(ActionCreators.logicalDeletePressKit.failure(e))
  }
}

function* firestoreFileSort(action: ReturnType<typeof ActionCreators.firestoreFileSort.request>) {
  const pressKit = yield select(selectPressKit);
  const group = yield select(selectGroup);
  const firestoreFiles: Array<PressKitFirestoreFileModel> = yield select(selectFirestoreFiles);
  const selectedPressKitId = pressKit.add.selectedPressKit;
  const groupId = group.selectedId;
  const { sortedList, sortHistory } = action.payload;
  const sortedArrayLength = sortedList.length;
  const updateList = makeUpdateList(sortedArrayLength, sortHistory);
  const baseRef = db.collection('PressKit').doc(groupId).collection('pressKits').doc(selectedPressKitId).collection('files');
  try {
    const batch = db.batch();
    updateList.forEach(updateInfo => {
      const { currentIndex, nextIndex } = updateInfo;
      const file = firestoreFiles[currentIndex]
      const order = firestoreFiles[nextIndex].order
      batch.update(baseRef.doc(file.id), { order })
    });
    yield batch.commit();
    yield put(ActionCreators.firestoreFileSort.success());
    yield put(ActionCreators.syncFirestoreFiles.request({ groupId, pressKitId: selectedPressKitId }));
  } catch (e) {
    yield put(ActionCreators.firestoreFileSort.failure(e));
    yield put(ActionCreators.cleanUpSortState.request());
  }
}

function* firestorePressKitSort(action: ReturnType<typeof ActionCreators.firestorePressKitSort.request>) {
  const group = yield select(selectGroup);
  const firestoreFiles: Array<PressKitFirestoreFileModel> = yield select(selectFirestoreFiles);
  const firestorePressKits: Array<PressKitFirestoreModel> = yield select(selectFirestorePressKits);
  const groupId = group.selectedId;
  const { sortedList, sortHistory } = action.payload;
  const sortedArrayLength = sortedList.length;
  const updateList = makeUpdateList(sortedArrayLength, sortHistory);
  const baseRef = db.collection('PressKit').doc(groupId).collection('pressKits');
  try {
    const batch = db.batch()
    updateList.forEach(updateInfo => {
      const { currentIndex, nextIndex } = updateInfo;
      const pressKit = firestorePressKits[currentIndex]
      const order = firestorePressKits[nextIndex].order
      batch.update(baseRef.doc(pressKit.id), { order })
    })
    yield batch.commit();
    yield put(ActionCreators.firestorePressKitSort.success())
    yield put(ActionCreators.syncFirestorePressKit.request({ groupId }))
  } catch (e) {
    yield put(ActionCreators.firestorePressKitSort.failure(e))
    yield put(ActionCreators.cleanUpSortState.request())
  }
}

function* changeSelectedPressKit(action: ReturnType<typeof ActionCreators.setSelectedPressKit>) {
  const group = yield select(selectGroup)
  const groupId = group.selectedId
  const pressKitId = action.payload
  yield put(ActionCreators.syncFirestoreFiles.request({ groupId, pressKitId }))
}

function* syncFirestoreFiles(action: ReturnType<typeof ActionCreators.syncFirestoreFiles.request>) {
  const sorting: boolean = yield select(selectSorting);
  const { groupId, pressKitId } = action.payload
  try {
    const files: Array<PressKitFirestoreFileModel> = yield call(getPressKitFiles, groupId, pressKitId)
    yield put(ActionCreators.setPressKitFirestoreFiles(files))
    yield put(ActionCreators.syncFirestoreFiles.success())
  } catch (e) {
    yield put(ActionCreators.syncFirestoreFiles.failure(e))
  }
  if (sorting) yield put(ActionCreators.cleanUpSortState.request())
}

const getPressKitFiles = async (groupId: string, pressKitId: string) => {
  const filesRef = db.collection('PressKit').doc(groupId).collection('pressKits').doc(pressKitId).collection('files')
  const querySnapShot = await filesRef.orderBy('order', 'desc').get()
  const files: Array<any> = []
  querySnapShot.forEach(doc => {
    const data = Object.assign({}, { id: doc.id }, doc.data())
    files.push(data)
  })
  return files
}

function* syncFirestorePressKit(action: ReturnType<typeof ActionCreators.syncFirestorePressKit.request>) {
  const pressKitSorting: boolean = yield select(selectPressKitSorting)
  const { groupId } = action.payload;
  try{
    const pressKits: Array<PressKitFirestoreModel> = yield call(getPressKits, groupId)
    yield put(ActionCreators.setPressKitFirestore(pressKits))
    yield put(ActionCreators.syncFirestorePressKit.success())
  } catch(e) {
    yield put(ActionCreators.syncFirestorePressKit.failure(e))
  }
  if (pressKitSorting) yield put(ActionCreators.cleanUpSortState.request())
}

const getPressKits = async (groupId: string) => {
  const pressKitsRef = db.collection('PressKit').doc(groupId).collection('pressKits')
  const querySnapShot = await pressKitsRef.orderBy('order', 'desc').where('isDelete', '==', false).get()
  const pressKits: Array<any> = []
  querySnapShot.forEach(doc => {
    const data = Object.assign({}, {id: doc.id}, doc.data())
    pressKits.push(data)
  })
  return pressKits
}

function* cleanUpFileSortState(action: ReturnType<typeof ActionCreators.cleanUpSortState.request>) {
  yield put(ActionCreators.setFileSorting(false))
  yield put(ActionCreators.setPressKitSorting(false))
  yield put(ActionCreators.setViewFile([]))
  yield put(ActionCreators.setViewPressKit([]))
  yield put(ActionCreators.clearSortHistory())
  yield put(ActionCreators.cleanUpSortState.success())
}

const PressKitFirebaseSaga = [
  takeLatest(PressKitActionType.ADD_PRESS_KIT_REQUEST, addPressKit),
  takeLatest(PressKitActionType.CREATE_GROUP_ID_DOC_REQUEST, createGroupIdDoc),
  takeEvery(PressKitActionType.POST_PRESS_KIT_FILE_UPLOAD_REQUEST, fileUpload),
  takeLatest(PressKitActionType.REGISTER_FIRESTORE_AFTER_UPLOAD_REQUEST, registerFirestore),
  takeLatest(PressKitActionType.PRESS_KIT_FILENAME_EDIT_REQUEST, fileNameEdit),
  takeLatest(PressKitActionType.PRESS_KIT_NAME_EDIT_REQUEST, pressKitNameEdit),
  takeLatest(PressKitActionType.DELETE_PRESS_KIT_UPLOAD_FILE_REQUEST, deleteFile),
  takeLatest(PressKitActionType.DELETE_FIRESTORE_FILE_REQUEST, deleteFirestore),
  takeLatest(PressKitActionType.LOGICAL_DELETE_PRESSKIT_REQUEST, logicalDeletePressKit),
  takeLatest(PressKitActionType.FIRESTORE_FILE_SORT_REQUEST, firestoreFileSort),
  takeLatest(PressKitActionType.FIRESTORE_PRESS_KIT_SORT_REQUEST, firestorePressKitSort),
  takeLatest(PressKitActionType.SET_SELECTED_PRESS_KIT, changeSelectedPressKit),
  takeLatest(PressKitActionType.SYNC_PRESS_KIT_FIRESTORE_FILES_REQUEST, syncFirestoreFiles),
  takeLatest(PressKitActionType.SYNC_PRESS_KIT_FIRESTORE_REQUEST, syncFirestorePressKit),
  takeLatest(PressKitActionType.CLEANUP_SORT_STATE_REQUEST, cleanUpFileSortState),
]

export default PressKitFirebaseSaga
