import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";

//コンポーネント
import FroalaEditorComponent from "../../Common/Editor/FroalaEditor";

interface IProps {
    html: HTMLElement
}

const groupSelector = (state: RootState) => state.group;

const EditorComponent: React.FC<IProps> = props => {
    const group = useSelector(groupSelector);

    const { html } = props;

    if (group.selectedId && html) {
        return(
            <div>
                <FroalaEditorComponent
                    html={html}
                    groupSelectedId={group.selectedId}
                />
            </div>
        );
    } else {
        return(
            <div>

            </div>
        );
    }
}

export default EditorComponent;
