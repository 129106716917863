// react-chart-js2のグラフのoptionを定義

import { defaults } from 'react-chartjs-2';

defaults.global.legend.labels.usePointStyle = true;


export const openMailOptions = {
    legend: {
        display: true
    },
    tooltips: {
        mode: 'label',
        callbacks: {
            label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label === '開封率') {
                    label += `: ${Math.round(tooltipItem.yLabel * 100) / 100} %`;
                } else {
                    label += `: ${Math.round(tooltipItem.yLabel * 100) / 100}件`;
                }
                return label
            },
        }
    },
    scales: {
        xAxes: [
            {
                barThickness: 25,
                display: true,
                gridLines: {
                    display: false
                },

            }
        ],
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                    display: false
                },
                scaleLabel: {
                    display: true,
                    labelString: "配信先数(件)",
                },
                ticks: {
                    beginAtZero: true
                }

            },
            {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-axis-2',
                gridLines: {
                    display: false
                },
                scaleLabel: {
                    display: true,
                    labelString: "開封率(%)",
                },
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
};

export const CategoryOption = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
        position: "right"

    },
    tooltips: {
        mode: 'label',
        callbacks: {
            label: function (tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
                label += '件'
                return label
            },
        }
    },
}
