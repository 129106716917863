import React, { useEffect, useState } from 'react';

// components
import { Button, makeStyles, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import SimpleTextField from '../../Field/SimpleTextField';

interface Props {
    isOpen: boolean,
    handleClose,
    defaultName?: string,
    handlePost,
}

const NameDialog: React.FC<Props> = ({ isOpen, handleClose, defaultName = '', handlePost }) => {
    const classes = useStyles();
    const [name, setName] = useState('');

    useEffect(() => {
        if (defaultName) setName(defaultName);
    }, [defaultName])

    return (
        <Dialog open={isOpen} onClose={() => {
            handleClose();
            setName("");
        }}>
            <DialogTitle>名前を入力してください</DialogTitle>
            <DialogContent className={classes.content}>
                <SimpleTextField value={name} autoFocus={true} handleChange={(e) => setName(e.target.value)} />
            </DialogContent>
            <DialogActions className={classes.actions}> 
                <div className={classes.buttons}>
                    <Button variant="outlined" color="primary" size='small'
                        className={classes.button} onClick={handleClose}>
                        キャンセル
                    </Button>
                    <Button type='submit' variant="contained" color="primary" size='small'
                        className={classes.button} onClick={() => handlePost(name)}>
                        保存
                    </Button>
                </div>
            </DialogActions>
        </Dialog >
    );
}

export default NameDialog;

const useStyles = makeStyles({
    root: {
        width: 600,
        height: 350,
    },
    content: {
        width: 400,
        heigth: 50,
    },
    actions: {
        padding: '0 8px',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 8,
    },
    button: {
        padding: '0 10px',
        margin: '10px 10px 0 auto',
        fontSize: 12,
        height: 40,
    },
})
