import createReducer from "./createReducer";
import {combineReducers} from "redux";

//action
import ActionType from "../actions/Radar/ActionType";
import RadarAction from "../actions/Radar/Action";

//model
import {
    RadarWordModel,
    RadarResultsResponseContentModel,
    RadarSelectedModel,
    RadarDisplayMode,
    MappedRadarResultModel,
    DatePickerSelected,
} from "../model/RadarModel";

const words = createReducer([], {
    [ActionType.GET_RADAR_WORDS_SUCCEEDED](state: Array<RadarWordModel>, action: RadarAction) {
        return action.payload
    }
})

const initialStateResult: Array<RadarResultsResponseContentModel> = []

const result = createReducer(initialStateResult, {
    [ActionType.GET_RADAR_RESULTS_SUCCEEDED](state: Array<RadarResultsResponseContentModel>, action: RadarAction) {
        return action.payload
    }
})

const initialStateSelected: RadarSelectedModel = {
    start: null,
    end: null
}

const selected = createReducer(initialStateSelected, {
    [ActionType.SET_RADAR_SELECTED_DATE](state: RadarSelectedModel, action, RadarAction) {
        return {
            ...action.payload
        }
    }
})

const displayItems = createReducer([], {
    [ActionType.SET_RADAR_DISPLAY_ITEMS](state: Array<MappedRadarResultModel>, action: RadarAction) {
        return action.payload
    }
})

//TODO: これいらないかも
const displayMode = createReducer('month', {
    [ActionType.SET_RADAR_DISPLAY_MODE](state: RadarDisplayMode, action: RadarAction) {
        return action.payload
    }
})

const targetWordId = createReducer(null, {
    [ActionType.SET_TARGET_WORD_ID](state: number, action: RadarAction) {
        return action.payload
    }
})

const targetIndex = createReducer(null, {
    [ActionType.SET_TARGET_INDEX](state: number, action: RadarAction) {
        return action.payload
    }
})

const initialStateDatePickerSelected: DatePickerSelected = {
    start: null,
    end: null
}

const datePickerSelected = createReducer(initialStateDatePickerSelected, {
    [ActionType.SET_DATE_PICKER_START_SELECTED](state: DatePickerSelected, action: RadarAction) {
        return {
            ...state,
            start: action.payload
        }
    },
    [ActionType.SET_DATE_PICKER_END_SELECTED](state: DatePickerSelected, action: RadarAction) {
        return {
            ...state,
            end: action.payload
        }
    }
})

const isOverWord = createReducer(false, {
    [ActionType.SET_IS_OVER_WORD](state: boolean, action: RadarAction) {
        return action.payload
    }
})


export const radar = combineReducers({
    words,
    result,
    selected,
    displayItems,
    displayMode,
    targetWordId,
    targetIndex,
    datePickerSelected,
    isOverWord,
});
