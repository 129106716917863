import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// component
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

interface NameDialogProps {
    isOpen: boolean,
    handleSave,
    handleClose,
}

const MediaListNameDialog: React.FC<NameDialogProps> = ({ isOpen, handleSave, handleClose }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [name, setName] = useState<string>('');
    return (
        <Dialog open={isOpen} onEscapeKeyDown={handleClose} onExited={() => {
            setName('')
            setErrorMessage('')
        }}>
            <DialogTitle>
                メディアリストのタイトルを入力してください。<br />
            </DialogTitle>
            <DialogContent className={classes.content}>
                <TextField multiline
                    placeholder="タイトルを入力"
                    value={name}
                    variant="outlined"
                    autoFocus
                    onChange={(e) => setName(e.target.value)}
                />
                <Typography className={classes.errorMessage}>{errorMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" color="primary" onClick={() => handleClose()}>
                    キャンセル
				</Button>
                <Button variant="contained" size="large" color="primary" onClick={() => handleSave(name, setErrorMessage)} disabled={!name}>
                    作成
				</Button>
            </DialogActions>
        </Dialog>
    );
}

export default MediaListNameDialog


const useStyles = makeStyles({
    content: {
        width: 424,
        padding: 20,
        paddingBottom: 5,
    },
    errorMessage: {
        color: 'red',
        paddingTop: 10,
    },
});



