import React from 'react';
import {FieldProps} from '../../model/FormModel';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from "@material-ui/core/styles";

const CheckBoxField: React.FC<FieldProps> = ({input, label, type, meta: {touched, error, warning}}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Checkbox
                color="primary"
                {...input}
                name={input.name}
                checked={input.checked}
                onChange={input.onChange}
                value={input.id}
            />
            {label}
            {touched && (
                <div style={{color: 'red', fontSize: '80%'}}>
                    <p>{(error && <span>{error}</span>) || (warning && <span>{warning}</span>)}</p>
                </div>
            )}
        </div>
    );
};

export default CheckBoxField;

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center"
    }
});
