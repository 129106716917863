import React from "react";
import {useSelector, useDispatch} from "react-redux";

//material-ui
import {Select, InputLabel, MenuItem, FormControl, makeStyles} from '@material-ui/core';

//action
import * as ActionCreators from '../../../actions/Radar/ActionCreator';

//model
import {RootState} from "../../../reducers";

//style
import style from "../radar.module.scss";

const radarWordsSelector = (state: RootState) => state.radar.words;
const targetWordIdSelector = (state: RootState) => state.radar.targetWordId;

interface Props {}

export const WordSelector: React.FC<Props> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const radarWords = useSelector(radarWordsSelector);
    const targetWordId = useSelector(targetWordIdSelector);

    const handleChange = (event) => {
        dispatch(ActionCreators.setTargetWordId.request(event.target.value))
    }

    return (
        <FormControl variant="outlined" className={style.formContainer}>
            <InputLabel
                id="radar-word-select-label"
            >
                検索ワード
            </InputLabel>
            <Select
                className={classes.select}
                id="radar-word-select"
                value={targetWordId || ''}
                onChange={handleChange}
            >
                {radarWords.map(item => (
                    <MenuItem value={item.id} key={item.id}>{item.radar_word}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const useStyles = makeStyles(() => ({
	select: {
	    minWidth: 120
    }
}));
