import { put, takeLatest, select, call } from 'redux-saga/effects'
import { db } from '../configureFirebase';
import BriefingActionType from '../actions/Briefing/ActionType';
import * as ActionCreators from '../actions/Briefing/ActionCreator';
import * as MemberActionCreators from '../actions/Member/ActionCreator';
import * as CompanyActionCreators from '../actions/Company/ActionCreator';
import { selectGroup, selectMe } from './selector';
import { BriefingDetailModel } from '../model/BriefingModel';
import { fetchGet, getJson } from './fetch';
import { BRIEFING_STATUS } from '../config/briefing';

function* getBriefingList(_action: ReturnType<typeof ActionCreators.getBriefingList.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `groups/${group.selectedId}/briefing/`);
        if (data && !error) {
            yield put(ActionCreators.getBriefingList.success({ results: data.results }));
            yield put(CompanyActionCreators.setIsFreePlanExpired(false));
            yield put(MemberActionCreators.setIsOverMember(false));
        } else {
            if (error) {
                if (error.is_free_plan_expired) {
                    yield put(CompanyActionCreators.setIsFreePlanExpired(true));
                } else if (error.is_over_member) {
                    yield put(MemberActionCreators.setIsOverMember(true));
                }
            }
        }
    } catch (e) {
        yield put(ActionCreators.getBriefingList.failure(e));
    }
}

function* getBriefingListTemplate(_action: ReturnType<typeof ActionCreators.getBriefingListTemplate.request>) {
    try {
        const group = yield select(selectGroup)
        const data1 = yield call(getJson, '/template/briefing/1.json');
        const data2 = yield call(getJson, '/template/briefing/2.json');
        const data3 = yield call(getJson, '/template/briefing/3.json');
        const data4 = yield call(getJson, '/template/briefing/4.json');
        const data5 = yield call(getJson, '/template/briefing/5.json');
        const list = [data1, data2, data3, data4, data5];
        yield put(ActionCreators.getBriefingListTemplate.success({ results: list }))
        const docRef = db.collection('Briefing').where('groupId', '==', `${group.selectedId}`).where('status', '==', BRIEFING_STATUS.TEMPLATE)
        yield docRef.orderBy('updated_at', 'desc')
            .get().then(function (querySnapshot) {
                querySnapshot.docs.forEach(doc => {
                    list.push(Object.assign({
                        body: {
                            ...doc.data(),
                            updated_at: doc.data().updated_at.toDate(),
                        },
                        id: doc.id,
                    }));
                });
            })
    } catch (e) {
        console.error(e)
        yield put(ActionCreators.getBriefingListTemplate.failure(e))
    }
}

function* getBriefingListReceived(_action: ReturnType<typeof ActionCreators.getBriefingListReceived.request>) {
    try {
        const me = yield select(selectMe);
        const list: BriefingDetailModel[] = [];
        const docRef = db.collection('Briefing').where('send_list', 'array-contains', me.email);
        yield docRef.orderBy('updated_at', 'desc')
            .get().then(function (querySnapshot) {
                querySnapshot.docs.forEach(doc => {
                    list.push(Object.assign({
                        body: {
                            ...doc.data(),
                            updated_at: doc.data().updated_at.toDate(),
                        },
                        id: doc.id,
                    }));
                })
            })
        yield put(ActionCreators.getBriefingListReceived.success({ results: list }));
        yield put(CompanyActionCreators.setIsFreePlanExpired(false));
        yield put(MemberActionCreators.setIsOverMember(false));
    } catch (e) {
        yield put(ActionCreators.getBriefingListReceived.failure(e));
    }
}

const BriefingListSaga = [
    takeLatest(BriefingActionType.GET_BRIEFING_LIST_REQUEST, getBriefingList),
    takeLatest(BriefingActionType.GET_BRIEFING_LIST_TEMPLATE_REQUEST, getBriefingListTemplate),
    takeLatest(BriefingActionType.GET_BRIEFING_LIST_RECEIVED_REQUEST, getBriefingListReceived),
];

export default BriefingListSaga;
