import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// component
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Card, CardContent, Typography } from '@material-ui/core';
import ListMenu from '../ListMenu';
import Avatar from '../../../Common/Avatar';
import DateTime from '../../../Common/DateTime';
import NameDialog from '../../Dialog/Name';
import AnswerTable from '../../Answer/Table';
import { makeStyles } from "@material-ui/core/styles";
import AppDialog from '../../../Common/AppDialog';

// model
import { BriefingDetailModel, PageTypeModel } from '../../../../model/BriefingModel';

// icons
import MoreVertIcon from '@material-ui/icons/MoreVert';

// style
import style from './card_layout.module.scss';

// actions
import * as BriefingActionCreators from '../../../../actions/Briefing/ActionCreator';

// config
import { BRIEFING_PAGE_TYPES as PAGE_TYPES, BRIEFING_STATUS } from '../../../../config/briefing';

// state
import { RootState } from '../../../../reducers';
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;

interface ListLayoutProps {
    list: BriefingDetailModel[],
    pageType: PageTypeModel,
}

const ListLayout: React.FC<ListLayoutProps> = ({ list, pageType }) => {
    return (
        <ul className={style.card}>
            {list.map((item, index) => (
                <ListLayoutItems
                    item={item}
                    key={index}
                    pageType={pageType}
                />
            ))}
        </ul>
    )
}

interface ListLayoutItemProps {
    item: BriefingDetailModel,
    pageType: PageTypeModel,
}

const ListLayoutItems: React.FC<ListLayoutItemProps> = ({ item, pageType }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const group = useSelector(groupSelector);
    const me = useSelector(meSelector);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [nameDialog, setNameDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => setAnchorEl(null);

    const handlePostRename = (name: string) => {
        dispatch(BriefingActionCreators.patchBriefingContent.request({
            id: item.id,
            fields: { name: name, queries: item.body.queries },
            success: () => {
                getBriefingList();
                setNameDialog(false);
                handleClose();
            },
        }));
    }

    const getBriefingList = () => {
        dispatch(BriefingActionCreators.getBriefingList.request());
    }

    const handleDelete = () => {
        dispatch(BriefingActionCreators.deleteBriefingContent.request({
            id: item.id,
            success: () => getBriefingList(),
        }));
        setDeleteDialog(false);
        handleClose();
    }

    const editLink = `/briefing/${group.selectedId}/edit/${item.id}/`;
    const publicLink = `/briefing/${group.selectedId}/public/${item.id}/`;
    const answerLink = `/briefing/${group.selectedId}/answer/${item.id}/`;

    return (
        <li className={style.list}>
            <Card className={style.inner}>
                <CardContent className={style.card_footer}>
                    <div className={style.overlay}>
                        {pageType === PAGE_TYPES.LIST_RECEIVED ?
                            <NavLink exact to={answerLink} className={style.answer_open}>
                                回答する
                            </NavLink>
                            :
                            <>
                                <NavLink exact to={editLink} className={style.open}>
                                    編集する
                                </NavLink>
                                <NavLink exact to={publicLink} className={style.open}>
                                    回答状況を確認する
                                </NavLink>
                            </>}
                    </div>
                    <div className={style.bg}>
                        <AnswerTable body={item.body} id={item.id} />
                    </div>
                    <Box className={classes.cardInfo}>
                        <Box className={classes.cardTitle}>
                            <Typography variant='h6' className={style.author}>{item.body.name}</Typography>
                            <Typography variant='body1' className={style.answer_count}>
                                {item.body.status === BRIEFING_STATUS.EDIT && '未公開'}
                                {item.body.status === BRIEFING_STATUS.PUBLIC &&
                                    (pageType == PAGE_TYPES.LIST_EDIT ?
                                        `${item.body.answer_list.length} / ${item.body.send_list.length} 件の回答`
                                        :
                                        item.body.answer_list.indexOf(me.email) >= 0 ? "回答済み" : "未回答")}
                            </Typography>
                        </Box>
                        <Box className={classes.cardInner}>
                            <Box className={classes.avator}>
                                <Avatar src='' name={item.body.created_by.last_name} size='menu' />
                            </Box>
                            <Box className={classes.subHeader}>
                                <Typography className={classes.author}>
                                    {item.body.created_by.last_name} {item.body.created_by.first_name}
                                </Typography>
                                <DateTime datetime={dayjs(item.body.updated_at).format('YYYY/MM/DD HH:mm')} />
                            </Box>
                            {pageType === PAGE_TYPES.LIST_EDIT &&
                                <Tooltip title="メニュー" placement="top" arrow>
                                    <IconButton onClick={handleClick} className={style.iconMenu}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Tooltip>}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <ListMenu
                anchorEl={anchorEl}
                handleDeleteDialogOpen={() => setDeleteDialog(true)}
                handleRenameDialogOpen={() => setNameDialog(true)}
                handleClose={handleClose}
            />
            <NameDialog isOpen={nameDialog} handleClose={() => setNameDialog(false)}
                defaultName={item.body.name} handlePost={handlePostRename} />
            <AppDialog isOpen={deleteDialog}
                closeHandle={() => setDeleteDialog(false)}
                mainHandle={handleDelete} text='削除しますか' ButtonText="削除" />
        </li>
    )
}

export default ListLayout;

const useStyles = makeStyles({
    author: {
        fontSize: 12,
    },
    cardInfo: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        background: '#f8fbf9',
        width: '100%',
        borderTop: '1px solid #e2e9e5',
        padding: '8px 16px',
        height: 75,
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    subHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: 20,
    },
    cardInner: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
        height: 39,
    },
    avator: {
        width: 28,
        marginRight: 10,
    },
});
