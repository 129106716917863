import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import * as config from "../../../../config/panel_type";
import * as pageConfig from "../../../../config/page_type";

import Comment from "./Commnet";
import History from "./History";
import Info from "./Info";
import Email from "./Email";
import ActiveLog from "./ActiveLog";
import AI from "./AI";
import HelpFab from "../../../Common/HelpFab";
import DownloadDialog from "../DownloadDialog";

import { RadioGroup, FormControl, makeStyles, Tooltip, IconButton } from "@material-ui/core/";
import Radio from "@material-ui/core/Radio";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';


//action
import * as PressReleaseActionCreators from "../../../../actions/PressRelease/ActionCreator";
import * as PressReleaseAIActionCreators from "../../../../actions/PressReleaseAI/AIActionCreator";
import * as TicketActionCreators from "../../../../actions/Ticket/ActionCreator";

//img
import IconInfoOff from "../../../../assets/editor/panel_info_off.svg";
import IconHistoryOff from "../../../../assets/editor/panel_history_off.svg";
import IconCommentOff from "../../../../assets/editor/panel_comment_off.svg";
import IconRobotOff from "../../../../assets/editor/panel_robot_off.svg";
import IconDownloadOff from "../../../../assets/icon_download.svg";

// state
import { RootState } from "../../../../reducers";
import { STATUS_PUBLIC_REJECT, STATUS_REJECT } from "../../../../config/status_type";

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseAISelector = (state: RootState) => state.pressReleaseAI;
const companySelector = (state: RootState) => state.company;

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "row",
        borderLeft: "0.1rem solid #E2E9E5;",
        marginLeft: "auto",
        minWidth: 362,
        zIndex: 1,
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)"
    },
    closeRoot: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        zIndex: 1,
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2)"
    },
    inner: {
        overflowY: 'scroll',
        height: "calc(100vh - 70px)",
        "-ms-overflow-style": "none",
        "scrollbar-width": "none"
    },
    closeContent: {
        transform: 'translateX(312px)',
        transition: '.01s',
        zIndex: -10,
        width: 0,
        marginLeft: 50,
    },
    menu: {
        background: "#616763",
        width: 50,
        position: 'fixed',
        top: 70,
        right: 0,
        height: '100%',
    },
    content: {
        width: "31.2rem",
        background: "#F3F7F4",
        height: "100vh",
        overflow: "hidden",
        zIndex: -10,
        transition: '.01s',
    },
    radio: {
        width: "100%",
        borderRadius: 4,
        transition: "none",
        margin: "5px auto",
        padding: "10px 0",
        "&:hover": {
            backgroundColor: "none",
        },
    },
    checked: {
        background: "#3A3D3B",
    },
    icon: {
        position: "relative",
        margin: "0px auto",
        width: 42
    },
    active: {
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: "#E95050",
        position: "absolute",
        top: 4,
        right: "-1px",
        zIndex: 100
    },
    menuButton: {
        padding: 7.0625,
        margin: '5px auto',
        borderRadius: 4,
    },
});

interface IProps {
    editor?: any,
    pageType: string
}

const Panel: React.FC<IProps> = ({ editor, pageType }) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [switchPanel, setSwitchPanel] = React.useState(config.PANEL_TYPE_INFO);
    const [aiNoti, setAiNoti] = React.useState<boolean>(false);
    const pressRelease = useSelector(pressReleaseSelector);
    const pressReleaseAI = useSelector(pressReleaseAISelector);
    const company = useSelector(companySelector);
    const [open, setOpen] = useState<boolean>(false);
    const [infoActive, setInfoActive] = useState<boolean>(false);
    const [downloadDialog, setDownloadDialog] = useState<boolean>(false);

    const { plan } = company;
    const editHistoryLimit = plan.edit_history_limit;
    const exportHtmlLimit = plan.export_html_limit;
    const [exportHTML, setExportHTML] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSwitchPanel(value);
        fetchData(value);
        if (value === config.PANEL_TYPE_DOWNLOAD) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        store.set('menuClose', true)
    };

    const handleDialogOpen = (pageType) => {
        const appEditor = document.querySelectorAll('#appEditor .fr-view');
        if (appEditor.length) {
            const html = appEditor[0].innerHTML;
            const css: string = "";
            const component: any = [];
            const style = [];
            dispatch(PressReleaseActionCreators.setPressReleaseDetails.request({ body: { html, css, style, component } }));

            if (pageType === pageConfig.PAGE_EDIT && pressRelease.detail.status !== 1) {
                dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff: false, isReview: false, isReviewRequest: false }));
            } else if (pageType === pageConfig.PAGE_DIFF) {
                dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff: true, isReview: false, isReviewRequest: false }));
            } else if (pageType === pageConfig.PAGE_REVIEW) {
                dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({ isDiff: false, isReview: true, isReviewRequest: false }));
            }
        }

        setDownloadDialog(true);
    };

    useEffect(() => {
        pressReleaseAI.check.fetched ? setAiNoti(true) : setAiNoti(false);
    }, [pressReleaseAI.check.fetched, pressRelease.detail.fetched]);

    useEffect(() => {
        if ([STATUS_REJECT, STATUS_PUBLIC_REJECT].indexOf(pressRelease.detail.status) > -1 || !store.get('menuClose')) {
            setInfoActive(true);
            handleOpen()
        }
    }, [pressRelease.detail.status]);

    useEffect(() => {
        if (downloadDialog) {
            if (pageType !== pageConfig.PAGE_CREATE && pressRelease.detail.status < 3) {
                dispatch(PressReleaseActionCreators.postPressReleasePDF.request({ isDownload: true }));
            }
        }
    }, [downloadDialog]);

    // html&pdf出力ダイアログのコンテンツ部分に表示するものをセット
    useEffect(() => {
        let content: string = '';
        if (exportHtmlLimit) {
            content = `${plan.name}プランではHTML出力機能をご利用いただけません`;
        } else {
            if (pageType === pageConfig.PAGE_CREATE) {
                content = `HTMLの出力は、プレスリリースを保存後に可能となります<br/>
                           <br/>
                           このダイアログを閉じて、画面右上にある「保存」ボタンからプレスリリースを保存してください`
            } else if (pageType === pageConfig.PAGE_REVIEW) {
                content = pressRelease.history.body.html;
            } else {
                content = pressRelease.detail.body.html;
            }
        }
        setExportHTML(content);
    }, [plan, pressRelease.detail, pressRelease.history]);

    const fetchData = (value) => {
        switch (value) {
            case config.PANEL_TYPE_COMMENT:
                dispatch(PressReleaseActionCreators.getPressReleaseCommentList.request());
                break;
            case config.PANEL_TYPE_AI:
                const appEditor = document.querySelectorAll('#appEditor .fr-view');
                dispatch(PressReleaseAIActionCreators.postPressReleaseCheck.request(appEditor[0]));
                break;
        }
    };

    // const init = () => {
    //     let panel = ''
    //     setSwitchPanel(config.PANEL_TYPE_INFO)
    //     fetchData(config.PANEL_TYPE_INFO)
    // };

    let pdf: string = "";
    if (pressRelease.detail.status < 3) {
        if (pressRelease.PDF.created) {
            pdf = pressRelease.PDF.url;
        }
    } else {
        pdf = pressRelease.detail.pdf;
    }

    const info =
        <Tooltip title="プロパティ" arrow>
            <div className={classes.icon} onClick={handleOpen}>
                {infoActive && <span className={classes.active} />}
                <Radio
                    classes={{ root: classes.radio, checked: classes.checked }}
                    checked={switchPanel === config.PANEL_TYPE_INFO}
                    onChange={handleChange}
                    value={config.PANEL_TYPE_INFO}
                    color="default"
                    name="layout"
                    icon={<img src={IconInfoOff} />}
                    checkedIcon={<img src={IconInfoOff} />}
                />
            </div>
        </Tooltip>;

    const history = <Tooltip title="作業履歴" arrow>
        <div className={classes.icon} onClick={handleOpen}>
            <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
                checked={switchPanel === config.PANEL_TYPE_HISTORY}
                onChange={handleChange}
                value={config.PANEL_TYPE_HISTORY}
                color="default"
                name="layout"
                icon={<img src={IconHistoryOff} />}
                checkedIcon={<img src={IconHistoryOff} />}
            /></div>
    </Tooltip>;

    const comment = <Tooltip title="コメント" arrow>
        <div className={classes.icon} onClick={handleOpen}>
            <Radio
                classes={{ root: classes.radio, checked: classes.checked }}
                checked={switchPanel === config.PANEL_TYPE_COMMENT}
                onChange={handleChange}
                value={config.PANEL_TYPE_COMMENT}
                color="default"
                name="layout"
                icon={<img src={IconCommentOff} />}
                checkedIcon={<img src={IconCommentOff} />}
            />
        </div>
    </Tooltip>;

    const ai =
        <Tooltip title="AI校閲" arrow>
            <div className={classes.icon} onClick={handleOpen}>
                {aiNoti && <span className={classes.active} />}
                <Radio
                    classes={{ root: classes.radio, checked: classes.checked }}
                    checked={switchPanel === config.PANEL_TYPE_AI}
                    onChange={handleChange}
                    value={config.PANEL_TYPE_AI}
                    color="default"
                    name="layout"
                    icon={<img src={IconRobotOff} />}
                    checkedIcon={<img src={IconRobotOff} />}
                />
            </div>
        </Tooltip>;

    const download =
        <Tooltip title="HTML&amp;PDF出力" arrow>
            <div className={classes.icon} onClick={() => handleDialogOpen(pageType)}>
                <Radio
                    classes={{ root: classes.radio, checked: classes.checked }}
                    checked={switchPanel === config.PANEL_TYPE_DOWNLOAD}
                    onChange={handleChange}
                    value={config.PANEL_TYPE_DOWNLOAD}
                    color="default"
                    name="layout"
                    icon={<img src={IconDownloadOff} />}
                    checkedIcon={<img src={IconDownloadOff} />}
                />
            </div>
        </Tooltip>;

    let html: any = "";

    const menu = <Tooltip title={open ? '閉じる' : 'メニューを開く'} arrow>
        {open ?
            <IconButton onClick={handleClose} className={classes.menuButton}>
                <CloseIcon fontSize="large" style={{ color: 'white' }} />
            </IconButton>
            : <IconButton onClick={handleOpen} className={classes.menuButton}>
                <MenuIcon fontSize="large" style={{ color: 'white' }} />
            </IconButton>}
    </Tooltip>

    switch (pageType) {
        case pageConfig.PAGE_CREATE:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/press_release#1'} placement="bottom" />
            </RadioGroup>;
            break;
        case pageConfig.PAGE_EDIT:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {comment}
                {ai}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/press_release#1'} placement="bottom" />
            </RadioGroup>;
            break;
        case pageConfig.PAGE_DIFF:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {comment}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/press_release#1'} placement="bottom" />
            </RadioGroup>;
            break;
        case pageConfig.PAGE_REVIEW:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {comment}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/press_release#1'} placement="bottom" />
            </RadioGroup>;
            break;
        case pageConfig.PAGE_RESERVE:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {comment}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/send#1'} placement="bottom" />
            </RadioGroup>;
            break;
        case pageConfig.PAGE_OGP:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {comment}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/web_page#1'} placement="bottom" />
            </RadioGroup>;
            break;
        default:
            html = <RadioGroup className={classes.menu}>
                {menu}
                {info}
                {comment}
                {!editHistoryLimit && history}
                {download}
                <HelpFab url={'/help/press_release#1'} placement="bottom" />
            </RadioGroup>;
    }

    return (
        <>
            <FormControl className={open ? classes.root : classes.closeRoot} id="panelTab">
                <div className={open ? classes.content : classes.closeContent}>
                    <PanelContent type={switchPanel} editor={editor} pageType={pageType} />
                </div>
                {html}
            </FormControl>

            <DownloadDialog
                isOpen={downloadDialog}
                closeHandle={() => setDownloadDialog(false)}
                html={exportHTML}
                pageType={pageType}
                exportHtmlLimit={exportHtmlLimit}
            />
        </>
    );
};

export default Panel;

interface ListLayoutItemsProps {
    type: string,
    editor: any,
    pageType: string,
}

const PanelContent: React.FC<ListLayoutItemsProps> = ({ type, editor, pageType }) => {

    const classes = useStyles();

    let html: any = "";
    switch (type) {
        case config.PANEL_TYPE_INFO:
            html = <div className={classes.inner}><Info /></div>;
            break;
        case config.PANEL_TYPE_COMMENT:
            html = <div className={classes.inner}><Comment /></div>;
            break;
        case config.PANEL_TYPE_HISTORY:
            html = <div className={classes.inner}><History pageType={pageType} /></div>;
            break;
        case config.PANEL_TYPE_EMAIL:
            html = <div className={classes.inner}><Email /></div>;
            break;
        case config.PANEL_TYPE_ACTIVE_LOG:
            html = <div className={classes.inner}><ActiveLog /></div>;
            break;
        case config.PANEL_TYPE_AI:
            html = <div className={classes.inner}><AI /></div>;
            break;
    }

    return (
        <div>
            {html}
        </div>
    );
};
