import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';

import { Divider, Typography, Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { RootState } from '../../../../reducers';
import * as PaymentActionCreator from '../../../../actions/Payment/ActionCreator';

import { calcTax } from '../../../../config/payment_config';
import { getNextChargeDate } from '../../../../util/payment';
import { PlanInfo } from '../../../../model/PlanModel';

interface ConfirmDialogProps {
    isOpen: boolean;
    planInfo: PlanInfo;
    closeHandle;
}

const paymentSelector = (state: RootState) => state.payment;
const planSelector = (state: RootState) => state.plan;
const companySelector = (state: RootState) => state.company;


const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ planInfo, isOpen, closeHandle }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const plan = useSelector(planSelector);
    const { info: payment, fetching } = useSelector(paymentSelector);
    const company = useSelector(companySelector);
    const nextChargeDate = getNextChargeDate();
    const tax = calcTax(planInfo.amount);

    const handleClose = () => {
        dispatch(push('/setting/plan'));
    };

    const handleClick = (planCode: number) => {
        if (company.plan.code == plan.free.code) {
            dispatch(PaymentActionCreator.postPlan.request({ "planCode": planCode, "index": payment.card.index }));
        } else {
            dispatch(PaymentActionCreator.patchPlan.request({ "planCode": planCode, "index": payment.card.index }));
        }
        dispatch(push('/setting/plan/'));
        closeHandle(false);
    };
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Typography variant='h5'>プラン購入内容確認</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
                <div className={classes.settingTitle}>内容</div>
                <div className={classes.text}>
                    <Typography variant={'h6'}>プラン</Typography>
                    <Typography variant={'h6'}>{`${planInfo.name}プラン`}</Typography>
                </div>
                <div className={classes.text}>
                    <Typography variant={'h6'}>支払い方法</Typography>
                    <Typography variant={'h6'}>{payment.card.number}</Typography>
                </div>
                <div className={classes.text}>
                    <Typography variant={'h6'}>次回請求日</Typography>
                    <Typography variant={'h6'}>{nextChargeDate}</Typography>
                </div>
                <div className={classes.settingTitle}>ご請求</div>
                <div className={classes.text}>
                    <Typography variant={'h6'}>小計(税抜)</Typography>
                    <Typography
                        variant={'h6'}>{`¥${planInfo.amount.toLocaleString()}`}</Typography>
                </div>
                <div className={classes.text}>
                    <Typography variant={'h6'}>消費税</Typography>
                    <Typography variant={'h6'}>{`¥${tax.toLocaleString()}`}</Typography>
                </div>
                <Divider />
                <div className={classes.text}>
                    <Typography variant={'h5'}>合計</Typography>
                    <Typography variant={'h5'}>{`¥${(planInfo.amount + tax).toLocaleString()}`}</Typography>
                </div>
                <div className={classes.caption}>
                    <Typography variant={'body2'}>
                        ※現在登録中のクレジットカードを使用して自動更新されます。<br />
						請求先クレジットカードの変更は
						<NavLink exact to={'/setting/payment'} className={classes.link}>お支払い設定画面</NavLink>
						にて可能です。<br />
						プランはキャンセルしない限り、更新期間ごとに請求がなされます。<br />
						キャンセルをできるのは自動更新の前日までです。<br />
						プランの変更は月１回までです。
					</Typography>
                </div>
            </DialogContent>
            <DialogActions className={classes.action}>
                {!company.can_change_plan && <Typography variant='h6' className={classes.error} color='error'>プラン変更は月1回までです。</Typography>}
                <div className={classes.button}>
                    <NavLink exact to={'/setting/plan'}>
                        <Button variant='outlined' size='large' onClick={closeHandle} disabled={fetching}>
                            キャンセル
						</Button>
                    </NavLink>
                    <NavLink exact to={'/setting/plan'}>
                        <Button variant='contained' size='large' color='primary' disabled={!company.can_change_plan || fetching} onClick={() => handleClick(planInfo.code)}>
                            確定
						</Button>
                    </NavLink>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;

const useStyles = makeStyles({
    settingTitle: {
        background: '#f3f7f4',
        borderRadius: 4,
        color: '#616763',
        fontSize: 14,
        fontWeight: 'bold',
        padding: '10px 20px',
        marginBottom: 10
    },
    title: {
        color: '#f8fbf9'
    },
    content: {
        width: 600,
        padding: 36
    },
    text: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 16
    },
    caption: {
        marginTop: 36
    },
    action: {
        justifyContent: "space-between"
    },
    error: {
        marginLeft: 36
    },
    button: {
        marginLeft: "auto"
    },
    link: {
        fontWeight: "bold"
    }
});
