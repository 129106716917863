enum PaymentActionType {
    //カード編集画面リンク
    GET_CARD_EDIT_URL_REQUEST = "GET_CARD_EDIT_URL_REQUEST",
    GET_CARD_EDIT_URL_SUCCEEDED = "GET_CARD_EDIT_URL_SUCCEEDED",
    GET_CARD_EDIT_URL_FAILED = "GET_CARD_EDIT_URL_FAILED",
    //カード情報
    GET_CARD_INFO_REQUEST = "GET_CARD_INFO_REQUEST",
    GET_CARD_INFO_SUCCEEDED = "GET_CARD_INFO_SUCCEEDED",
    GET_CARD_INFO_FAILED = "GET_CARD_INFO_FAILED",
    //プラン契約
    POST_PLAN_REQUEST = "POST_PLAN_REQUEST",
    POST_PLAN_SUCCEEDED = "POST_PLAN_SUCCEEDED",
    POST_PLAN_FAILED = "POST_PLAN_FAILED",
    // プラン変更
    PATCH_PLAN_REQUEST = "PATCH_PLAN_REQUEST",
    PATCH_PLAN_SUCCEEDED = "PATCH_PLAN_SUCCEEDED",
    PATCH_PLAN_FAILED = "PATCH_PLAN_FAILED",
    // プランキャンセル
    DELETE_PLAN_REQUEST = "DELETE_PLAN_REQUEST",
    DELETE_PLAN_SUCCEEDED = "DELETE_PLAN_SUCCEEDED",
    DELETE_PLAN_FAILED = "DELETE_PLAN_FAILED",

    // 決済画面リンク
    POST_TICKET_PAYMENT_REQUEST= "POST_TICKET_PAYMENT_REQUEST",
    POST_TICKET_PAYMENT_SUCCEEDED= "POST_TICKET_PAYMENT_SUCCEEDED",
    POST_TICKET_PAYMENT_FAILED= "POST_TICKET_PAYMENT_FAILED",
    // オプションの決済登録
    POST_OPTION_REQUEST= "POST_OPTION_REQUEST",
    POST_OPTION_SUCCEEDED= "POST_OPTION_SUCCEEDED",
    POST_OPTION_FAILED= "POST_OPTION_FAILED",

    // オプション追加
    PATCH_OPTION_REQUEST= "PATCH_OPTION_REQUEST",
    PATCH_OPTION_SUCCEEDED= "PATCH_OPTION_SUCCEEDED",
    PATCH_OPTION_FAILED= "PATCH_OPTION_FAILED",

    // オプション削除
    DELETE_OPTION_REQUEST= "DELETE_OPTION_REQUEST",
    DELETE_OPTION_SUCCEEDED= "DELETE_OPTION_SUCCEEDED",
    DELETE_OPTION_FAILED= "DELETE_OPTION_FAILED",
}

export default PaymentActionType
