import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    variant: 'filled' | 'outlined' | 'standard',
    severity: 'error' | 'info' | 'success' | 'warning',
    title: string,
    onClose: (event: any) => void,
    children: ReactNode
}

const AppAlert: React.FC<Props> = ({ variant,  severity, onClose, children, title }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Alert elevation={1} className={classes.alert} variant={variant} severity={severity} action={
                <IconButton onClick={onClose} color="inherit" size="small" aria-label="Close">
                    <CloseIcon fontSize="large"/>
                </IconButton>
            }>
                <AlertTitle className={classes.title}>{title}</AlertTitle>
                {children}
            </Alert>
        </div>
    );
}   


export default AppAlert;

const useStyles = makeStyles({
    root: {
        position: "fixed",
        top: 70,
        right: 50,
        zIndex: 100,
        padding: 20,
    },
    alert: {
        fontSize: 14,
        fontWeight: "bold",
    },
    title: {
        color: "#fff",
        fontSize: 17,
        fontWeight: "bold",
    }
});
