import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {push} from 'connected-react-router'

// action
import * as PressReleaseActionCreators from "../../../../../actions/PressRelease/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../../../../actions/PressReleaseFirebase/ActionCreator";

// component
import Button from "@material-ui/core/Button";
import PreviewDialog from "../../../../Common/PreviewDialog";

import {RootState} from "../../../../../reducers";
const pressGroupSelector = (state: RootState) => state.group;
const pressReleaseSelector = (state: RootState) => state.pressRelease;

interface SkipReviewButtonProps {
    disabledButton: boolean
}

const PressReleaseSkipReviewButton: React.FC<SkipReviewButtonProps> = ({disabledButton}) => {

    const dispatch = useDispatch();
    const group = useSelector(pressGroupSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const [previewDialog, setPreviewDialog] = useState<boolean>(false);

    const handleClick = () => {

        //保存
        dispatch(PressReleaseActionCreators.postPressReleaseDetails.request({
            isDiff: false,
            isReview: false,
            isReviewRequest: false}));

        //PDF生成
        dispatch(PressReleaseActionCreators.postPressReleasePDF.request({isDownload: false}));

        //確認スキップ処理
        dispatch(PressReleaseActionCreators.postPressReleaseReviewRequest.request({
            members_list: [],
            deadline_at: "",
            comment: "",
        }));

        // RealtimeDatabaseのeditを削除
        dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
            page: "edit",
            press_id: pressRelease.detail.press_id,
            user_id: "",
        }));

        setPreviewDialog(false);
        dispatch(push(`/press_release/${group.selectedId}/reserve/${pressRelease.detail.press_id}/`))
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={!pressRelease.role.edit || disabledButton}
                onClick={() => setPreviewDialog(true)}>
                配信設定へ進む
            </Button>
            <PreviewDialog isRevew={false} isHistory={false} isOpen={previewDialog}
                closeHandle={() => setPreviewDialog(false)} defaultLayout={"PREVIEW_TYPE_PDF"}
                handleAction={handleClick} handleActionText={"配信設定へ進む"}/>
        </>
    );
};

export default PressReleaseSkipReviewButton;

