import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// component
import { makeStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import TextField from '../../Field/TextFieldUnderline';
import RadioAnswer from './Radio';
import ImageAnswer from './Image';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// model
import { BriefingBodyModel, BriefingQueryModel } from '../../../model/BriefingModel';

// config
import { QUERY_TYPES } from '../../../config/briefing';

// state
import { RootState } from '../../../reducers';
const routerSelector = (state: RootState) => state.router;
const briefingListSelector = (state: RootState) => state.briefingList;
const briefingSelector = (state: RootState) => state.briefing;
const groupSelector = (state: RootState) => state.group;

interface TableProps {
    body: BriefingBodyModel,
    id: string,
}

const AnswerTable: React.FC<TableProps> = ({ body, id }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellQuestion}>
                            <Typography variant='h6' color='primary'>Q</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellAnswer}>
                            <Typography variant='h6' color='primary'>A</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <AnswerTableBody body={body} />
            </Table>
        </div>
    )
}

export default AnswerTable;

interface BodyProps {
    body: BriefingBodyModel,
}

const AnswerTableBody: React.FC<BodyProps> = ({ body }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <TableBody>
            {body.queries.map((query, index) => {
                const handleChangeText = (text: string) => {
                    const queries: BriefingQueryModel[] = [...body.queries];
                    queries[index].text = text;
                    dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
                }
                const handleChangeRadio = (id: number) => {
                    const queries: BriefingQueryModel[] = [...body.queries];
                    queries[index].selected = id;
                    dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
                }
                return (
                    <TableRow key={index}>
                        <TableCell className={classes.tableCellQuestion}>
                            <Typography variant='body1' className={classes.tableBodyText}>{query.question}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellAnswer}>
                            {query.type === QUERY_TYPES.TEXT &&
                                <TextField value={query.text} multiline handleChange={handleChangeText} />}
                            {query.type === QUERY_TYPES.RADIO &&
                                <RadioAnswer value={query.selected} choices={query.choices} handleChange={handleChangeRadio} />}
                            {query.type === QUERY_TYPES.IMAGE &&
                                <ImageAnswer file={query.file} body={body} index={index} />}
                        </TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
    );
}

const useStyles = makeStyles({
    tableCellQuestion: {
        width: '40%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#f8fbf9',
    },
    tableCellQuestionInner: {
        display: 'flex',
    },
    tableCellAnswer: {
        width: '60%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    tableBodyText: {
        fontSize: 12,
    },
    root: {
        marginTop: 8,
    },
})
