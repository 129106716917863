import createReducer from "./createReducer";
import PlanActionType from "../actions/Plan/ActionType";
import { PlanListModel } from "../model/PlanModel";
import * as ActionCreators from "../actions/Plan/ActionCreator";

const planInitialState =  {
    id:0,
    code: 0,
    name: "",
    amount: 0,
    member_limit: 0,
    project_limit: 0,
    media_limit: 0,
    folder_limit: 0,
    file_size_limit: 0,
    default_ticket: 0,
    is_review_skip_limit: false,
    edit_history_limit: false,
    export_html_limit: false,
    public_page_limit: false,
    briefing_content_limit: 0,
    briefing_mail_limit: 0
}

const initialStatePlan: PlanListModel = {
    free: planInitialState,
    spot: planInitialState,
    partnership: planInitialState,
    standard: planInitialState,
    premium: planInitialState,
    enterprise: planInitialState,
}

export const plan = createReducer<PlanListModel>(initialStatePlan, {
    [PlanActionType.GET_PLAN_LIST_SUCCEEDED](state: PlanListModel, action:ReturnType<typeof ActionCreators.getPlanList.success>
        ) {
        const result = action.payload
        return {
            ...result
        }
    }
})
