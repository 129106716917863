import React from 'react';

//component
import Title from '../Title';
import PageTitle from "../PageTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/setting/project/image01.png';
import Image02 from '../../../assets/help/setting/project/image02.png';


const Project = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"各種設定"}/>
                <Title title={"3. プロジェクト設定"}/>
                <Typography gutterBottom variant={"body1"}>
                    右上のプルダウンから「プロジェクト設定」を選択します。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
                <Typography gutterBottom variant={"body1"}>
                    プレスリリース、プレスキット、メンバーをプロジェクト毎に管理できます。<br/>
                    「新規プロジェクト追加」から、プロジェクトの追加を行うことができます。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>
            </div>
        </article>
    );
};

export default Project;
