import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// style
import style from '../Content/media_select_dialog.module.scss';

// state
import { RootState } from '../../../reducers';

// components
import { Typography, makeStyles, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

// actions
import * as PressReleaseReserveActionCreators from '../../../actions/PressReleaseReserve/ActionCreator';
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

// config
import { MEDIA_TYPE_LIST } from '../../../config/media_type';

const mediaSelector = (state: RootState) => state.media;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

const List = ({ page, handlePageChange }) => {
    const classes = useStyles();
    const media = useSelector(mediaSelector);
    const reserve = useSelector(pressReleaseReserveSelector);
    const listId = reserve.data.auto.list_id;
    const fetched =
        listId === 0 ? reserve.data.fetched : media.favoriteList.fetched
    const favoriteList = media.favoriteList.results;
    const mediaList =
        listId === 0 ? reserve.data.auto.ai_list :
            favoriteList.length > 0 && media.categoryList.results;
    const count = listId === 0 ? reserve.data.auto.ai_list.length : media.categoryList.count;

    return (
        <div className={style.listWrapper}>
            <div className={style.listContainer}>
                {fetched ?
                    mediaList.length > 0 ?
                        <>
                            <ul className={style.list}>
                                {listId === 0 ?
                                    mediaList.slice(51 * (page - 1), 51 * page).map((item, index) => (
                                        <ListLayoutItem key={index} item={item} />
                                    )) :
                                    mediaList.map((item, index) => (
                                        <ListLayoutItem key={index} item={item} />
                                    ))}
                            </ul>
                        </>
                        : <NoMediaMessage />
                    :
                    <div className={classes.circularProgress}>
                        <CircularProgress />
                    </div>
                }
            </div>
            {count > 0 && (
                <div className={style.paging}>
                    <Pagination
                        count={Math.ceil(count / 51)}
                        page={page}
                        onChange={(e: React.ChangeEvent<unknown>, pageNumber: number) => handlePageChange(pageNumber)} />
                </div>
            )}
        </div>
    );
}

export default List;

const useStyles = makeStyles({
    mediaLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    mediaInner: {
        verticalAlign: 'middle',
    },
    link: {
        color: "#5965b9",
        textDecoration: "underline",
    },
    mediaType: {
        fontSize: 11,
        color: "#999"
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85%',
        width: '100%',
    },
})

interface ListLayoutItemProps {
    item,
}

const ListLayoutItem: React.FC<ListLayoutItemProps> = ({ item }) => {
    const reserve = useSelector(pressReleaseReserveSelector);
    const { status } = useSelector(pressReleaseSelector).detail;
    const media = useSelector(mediaSelector);
    const listId = reserve.data.auto.list_id;
    const hasEdit = useSelector(pressReleaseSelector).role.edit;
    const favoriteList = media.favoriteList.results;
    const list =
        listId === 0 ? reserve.data.auto.id_list :
            favoriteList.length > 0 && favoriteList.find(i => i.list_id === listId).id_list || [];
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = () => {
        if (listId === 0) dispatch(PressReleaseReserveActionCreators.setPressReleaseReserveAutoMedia.request(item.id));
        if (listId > 0) dispatch(MediaActionCreators.setFavoriteMedia.request(item.id));
    }

    return (
        <li className={style.mediaItem}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={list.indexOf(item.id) >= 0}
                        onChange={() => handleChange()}
                        value={item.id}
                        color="primary"
                        disabled={!hasEdit || status === 5 || status >= 7}
                    />
                }
                className={classes.mediaLabel}
                classes={{ label: classes.mediaInner }}
                label={<a target="_blank" href={item.url} className={classes.link}>{item.name}</a>}
            />
            <Typography className={classes.mediaType}>{MEDIA_TYPE_LIST[item.media_type]}</Typography>
        </li>
    );
}

const emptyDataStyles = makeStyles({
    root: {
        padding: '150px 20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const NoMediaMessage: React.FC = () => {
    const classes = emptyDataStyles();

    return (
        <Typography variant={"h6"} className={classes.root}>
            メディアはありません
        </Typography>
    );
};
