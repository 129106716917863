import {all} from "redux-saga/effects";
import pressReleaseSaga from "./PressReleaseSaga";
import pressReleaseListSaga from "./PressReleaseListSaga";
import PressReleaseInviteSaga from "./PressReleaseInviteSaga";
import PressReleaseReserveSaga from "./PressReleaseReserveSaga";
import PressReleasePublishSaga from "./PressReleasePublishSaga";
import PressReleaseAISaga from "./PressReleaseAISaga";

import pressKitSaga from "./PressKitSaga";
import BlockSaga from "./BlockSaga";
import AnalyticsSaga from "./AnalyticsSaga";
import AssetSaga from "./AssetSaga";
import SignSaga from "./SignSaga";
import CompanySaga from "./CompanySaga";
import MeSaga from "./MeSaga";
import MemberSaga from "./MemberSaga";
import MediaSaga from "./MediaSaga";
import MovieSaga from "./MovieSaga";
import GroupSaga from "./GroupSaga";
import FeedbackSaga from "./FeedbackSaga";
import PressKitFirestoreSaga from "./PressKitFirestoreSaga";
import PaymentSaga from "./PaymentSaga";
import PaymentHistory from "./PaymentHistorySaga";
import ResetPasswordSaga from "./ResetPasswordSaga";
import pressReleaseTagSaga from "./PressReleaseTagSaga";
import topicSaga from "./TopicSaga";
import PressReleaseFirebaseSaga from "./PressReleaseFirebaseSaga";
import ticketSaga from "./TicketSaga";
import BriefingSaga from "./BriefingSaga";
import BriefingListSaga from "./BriefingListSaga";
import PlanSaga from "./PlanSaga";
import radarSaga from "./RadarSaga";
import OptionSaga from "./OptionSaga";
import OrderInfoSaga from "./OrderInfoSaga";
import ContactSaga from "./ContactSaga";

function* rootSaga() {
  yield all([
    ...AnalyticsSaga,
    ...AssetSaga,
    ...pressReleaseSaga,
    ...pressReleaseListSaga,
    ...PressReleaseInviteSaga,
    ...PressReleaseReserveSaga,
    ...PressReleaseAISaga,
    ...PressReleasePublishSaga,
    ...pressReleaseTagSaga,
    ...pressKitSaga,
    ...BlockSaga,
    ...SignSaga,
    ...CompanySaga,
    ...MeSaga,
    ...MemberSaga,
    ...MediaSaga,
    ...MovieSaga,
    ...GroupSaga,
    ...FeedbackSaga,
    ...PressKitFirestoreSaga,
    ...PaymentSaga,
    ...PaymentHistory,
    ...ResetPasswordSaga,
    ...topicSaga,
    ...PressReleaseFirebaseSaga,
    ...ticketSaga,
    ...BriefingSaga,
    ...BriefingListSaga,
    ...PlanSaga,
    ...radarSaga,
    ...OptionSaga,
    ...OrderInfoSaga,
    ...ContactSaga
  ]);
}

export default rootSaga;
