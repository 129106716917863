enum PressReleaseActionType {
    GET_PRESS_RELEASE_LIST_REQUEST = "GET_PRESS_RELEASE_LIST_REQUEST",
    GET_PRESS_RELEASE_LIST_SUCCEEDED = "GET_PRESS_RELEASE_LIST_SUCCEEDED",
    GET_PRESS_RELEASE_LIST_FAILED = "GET_PRESS_RELEASE_LIST_FAILED",

	GET_PRESS_RELEASE_LIST_PUBLIC_REQUEST = "GET_PRESS_RELEASE_LIST_PUBLIC_REQUEST",
	GET_PRESS_RELEASE_LIST_PUBLIC_SUCCEEDED = "GET_PRESS_RELEASE_LIST_PUBLIC_SUCCEEDED",
	GET_PRESS_RELEASE_LIST_PUBLIC_FAILED = "GET_PRESS_RELEASE_LIST_PUBLIC_FAILED",

	GET_PRESS_RELEASE_LIST_ARCHIVE_REQUEST = "GET_PRESS_RELEASE_LIST_ARCHIVE_REQUEST",
	GET_PRESS_RELEASE_LIST_ARCHIVE_SUCCEEDED = "GET_PRESS_RELEASE_LIST_ARCHIVE_SUCCEEDED",
	GET_PRESS_RELEASE_LIST_ARCHIVE_FAILED = "GET_PRESS_RELEASE_LIST_ARCHIVE_FAILED",

	GET_PRESS_RELEASE_DETAILS_REQUEST = "GET_PRESS_RELEASE_DETAILS_REQUEST",
	GET_PRESS_RELEASE_DETAILS_SUCCEEDED = "GET_PRESS_RELEASE_DETAILS_SUCCEEDED",
	GET_PRESS_RELEASE_DETAILS_FAILED = "GET_PRESS_RELEASE_DETAILS_FAILED",

	POST_PRESS_RELEASE_DETAILS_REQUEST = "POST_PRESS_RELEASE_DETAILS_REQUEST",
	POST_PRESS_RELEASE_DETAILS_SUCCEEDED = "POST_PRESS_RELEASE_DETAILS_SUCCEEDED",
	POST_PRESS_RELEASE_DETAILS_FAILED = "POST_PRESS_RELEASE_DETAILS_FAILED",

	PATCH_PRESS_RELEASE_INFO_REQUEST = "PATCH_PRESS_RELEASE_INFO_REQUEST",
	PATCH_PRESS_RELEASE_INFO_SUCCEEDED = "PATCH_PRESS_RELEASE_INFO_SUCCEEDED",
	PATCH_PRESS_RELEASE_INFO_FAILED = "PATCH_PRESS_RELEASE_INFO_FAILED",

    POST_PRESS_RELEASE_REVERT_REQUEST = "POST_PRESS_RELEASE_REVERT_REQUEST",
    POST_PRESS_RELEASE_REVERT_SUCCEEDED = "POST_PRESS_RELEASE_REVERT_SUCCEEDED",
    POST_PRESS_RELEASE_REVERT_FAILED = "POST_PRESS_RELEASE_REVERT_FAILED",

    PATCH_PRESS_RELEASE_ARCHIVE_REQUEST = "PATCH_PRESS_RELEASE_ARCHIVE_REQUEST",
    PATCH_PRESS_RELEASE_ARCHIVE_SUCCEEDED = "PATCH_PRESS_RELEASE_ARCHIVE_SUCCEEDED",
    PATCH_PRESS_RELEASE_ARCHIVE_FAILED = "PATCH_PRESS_RELEASE_ARCHIVE_FAILED",

    DELETE_PRESS_RELEASE_DETAILS_REQUEST = "DELETE_PRESS_RELEASE_DETAILS_REQUEST",
    DELETE_PRESS_RELEASE_DETAILS_SUCCEEDED = "DELETE_PRESS_RELEASE_DETAILS_SUCCEEDED",
    DELETE_PRESS_RELEASE_DETAILS_FAILED = "DELETE_PRESS_RELEASE_DETAILS_FAILED",

	POST_PRESS_RELEASE_PDF_REQUEST = "POST_PRESS_RELEASE_PDF_REQUEST",
	POST_PRESS_RELEASE_PDF_SUCCEEDED = "POST_PRESS_RELEASE_PDF_SUCCEEDED",
	POST_PRESS_RELEASE_PDF_FAILED = "POST_PRESS_RELEASE_PDF_FAILED",

	// history
	GET_PRESS_RELEASE_HISTORY_LIST_REQUEST = "GET_PRESS_RELEASE_HISTORY_LIST_REQUEST",
	GET_PRESS_RELEASE_HISTORY_LIST_SUCCEEDED = "GET_PRESS_RELEASE_HISTORY_LIST_SUCCEEDED",
	GET_PRESS_RELEASE_HISTORY_LIST_FAILED = "GET_PRESS_RELEASE_HISTORY_LIST_FAILED",

	GET_PRESS_RELEASE_HISTORY_DETAILS_REQUEST = "GET_PRESS_RELEASE_HISTORY_DETAILS_REQUEST",
	GET_PRESS_RELEASE_HISTORY_DETAILS_SUCCEEDED = "GET_PRESS_RELEASE_HISTORY_DETAILS_SUCCEEDED",
	GET_PRESS_RELEASE_HISTORY_DETAILS_FAILED = "GET_PRESS_RELEASE_HISTORY_DETAILS_FAILED",

	GET_PRESS_RELEASE_HISTORY_DETAILS_LATEST_REQUEST = "GET_PRESS_RELEASE_HISTORY_DETAILS_LATEST_REQUEST",
	GET_PRESS_RELEASE_HISTORY_DETAILS_LATEST_SUCCEEDED = "GET_PRESS_RELEASE_HISTORY_DETAILS_LATEST_SUCCEEDED",
	GET_PRESS_RELEASE_HISTORY_DETAILS_LATEST_FAILED = "GET_PRESS_RELEASE_HISTORY_DETAILS_LATEST_FAILED",

	// comment
	GET_PRESS_RELEASE_COMMENT_LIST_REQUEST = "GET_PRESS_RELEASE_COMMENT_LIST_REQUEST",
	GET_PRESS_RELEASE_COMMENT_LIST_SUCCEEDED = "GET_PRESS_RELEASE_COMMENT_LIST_SUCCEEDED",
	GET_PRESS_RELEASE_COMMENT_LIST_FAILED = "GET_PRESS_RELEASE_COMMENT_LIST_FAILED",

	POST_PRESS_RELEASE_COMMENT_REQUEST = "POST_PRESS_RELEASE_COMMENT_REQUEST",
	POST_PRESS_RELEASE_COMMENT_SUCCEEDED = "POST_PRESS_RELEASE_COMMENT_SUCCEEDED",
	POST_PRESS_RELEASE_COMMENT_FAILED = "POST_PRESS_RELEASE_COMMENT_FAILED",

	PATCH_PRESS_RELEASE_COMMENT_REQUEST = "PATCH_PRESS_RELEASE_COMMENT_REQUEST",
	PATCH_PRESS_RELEASE_COMMENT_SUCCEEDED = "PATCH_PRESS_RELEASE_COMMENT_SUCCEEDED",
	PATCH_PRESS_RELEASE_COMMENT_FAILED = "PATCH_PRESS_RELEASE_COMMENT_FAILED",

	DELETE_PRESS_RELEASE_COMMENT_REQUEST = "DELETE_PRESS_RELEASE_COMMENT_REQUEST",
	DELETE_PRESS_RELEASE_COMMENT_SUCCEEDED = "DELETE_PRESS_RELEASE_COMMENT_SUCCEEDED",
	DELETE_PRESS_RELEASE_COMMENT_FAILED = "DELETE_PRESS_RELEASE_COMMENT_FAILED",

	GET_PRESS_RELEASE_COMMENT_DONE_LIST_REQUEST = "GET_PRESS_RELEASE_COMMENT_DONE_LIST_REQUEST",
	GET_PRESS_RELEASE_COMMENT_DONE_LIST_SUCCEEDED = "GET_PRESS_RELEASE_COMMENT_DONE_LIST_SUCCEEDED",
	GET_PRESS_RELEASE_COMMENT_DONE_LIST_FAILED = "GET_PRESS_RELEASE_COMMENT_DONE_LIST_FAILED",

	POST_PRESS_RELEASE_REPLY_REQUEST = "POST_PRESS_RELEASE_REPLY_REQUEST",
    POST_PRESS_RELEASE_REPLY_SUCCEEDED = "POST_PRESS_RELEASE_REPLY_SUCCEEDED",
    POST_PRESS_RELEASE_REPLY_FAILED = "POST_PRESS_RELEASE_REPLY_FAILED",

	SET_PRESS_RELEASE_DETAILS = "SET_PRESS_RELEASE_DETAILS",
	SET_PRESS_RELEASE_TEMPLATE = "SET_PRESS_RELEASE_TEMPLATE",

	SET_PRESS_RELEASE_COMMENT_POSITION = "SET_PRESS_RELEASE_COMMENT_POSITION",

    SET_PRESS_RELEASE_COMMENT_SORT = "SET_PRESS_RELEASE_COMMENT_SORT",

    POST_PRESS_RELEASE_CLONE_REQUEST = "POST_PRESS_RELEASE_CLONE_REQUEST",
    POST_PRESS_RELEASE_CLONE_SUCCEEDED = "POST_PRESS_RELEASE_CLONE_SUCCEEDED",
    POST_PRESS_RELEASE_CLONE_FAILED = "POST_PRESS_RELEASE_CLONE_FAILED",

	GET_PRESS_RELEASE_DIFF_HISTORY_DETAILS_REQUEST = "GET_PRESS_RELEASE_DIFF_HISTORY_DETAILS_REQUEST",
	GET_PRESS_RELEASE_DIFF_HISTORY_DETAILS_SUCCEEDED = "GET_PRESS_RELEASE_DIFF_HISTORY_DETAILS_SUCCEEDED",
	GET_PRESS_RELEASE_DIFF_HISTORY_DETAILS_FAILED = "GET_PRESS_RELEASE_DIFF_HISTORY_DETAILS_FAILED",

	SET_PRESS_RELEASE_DETAILS_DIFF = "SET_PRESS_RELEASE_DETAILS_DIFF",

    GET_PRESS_RELEASE_ONETIME_PASSWORD_REQUEST = "GET_PRESS_RELEASE_ONETIME_PASSWORD_REQUEST",
	GET_PRESS_RELEASE_ONETIME_PASSWORD_SUCCEEDED = "GET_PRESS_RELEASE_ONETIME_PASSWORD_SUCCEEDED",
	GET_PRESS_RELEASE_ONETIME_PASSWORD_FAILED = "GET_PRESS_RELEASE_CLONE_FAILED",

	SET_PRESS_RELEASE_CREATE_DIALOG = "SET_PRESS_RELEASE_CREATE_DIALOG",

	GET_PRESS_RELEASE_TEMPLATE_REQUEST = "GET_PRESS_RELEASE_TEMPLATE_REQUEST",
	GET_PRESS_RELEASE_TEMPLATE_SUCCEEDED = "GET_PRESS_RELEASE_TEMPLATE_SUCCEEDED",
	GET_PRESS_RELEASE_TEMPLATE_FAILED = "GET_PRESS_RELEASE_TEMPLATE_FAILED",

	GET_PRESS_RELEASE_TEMPLATE_LIST_REQUEST = "GET_PRESS_RELEASE_TEMPLATE_LIST_REQUEST",
	GET_PRESS_RELEASE_TEMPLATE_LIST_SUCCEEDED = "GET_PRESS_RELEASE_TEMPLATE_LIST_SUCCEEDED",
	GET_PRESS_RELEASE_TEMPLATE_LIST_FAILED = "GET_PRESS_RELEASE_TEMPLATE_LIST_FAILED",

	GET_PRESS_RELEASE_REVIEW_INFO_REQUEST = "GET_PRESS_RELEASE_REVIEW_INFO_REQUEST",
	GET_PRESS_RELEASE_REVIEW_INFO_SUCCEEDED = "GET_PRESS_RELEASE_REVIEW_INFO_SUCCEEDED",
	GET_PRESS_RELEASE_REVIEW_INFO_FAILED = "GET_PRESS_RELEASE_REVIEW_INFO_FAILED",

	POST_PRESS_RELEASE_REVIEW_REQUEST_REQUEST = "POST_PRESS_RELEASE_REVIEW_REQUEST_REQUEST",
	POST_PRESS_RELEASE_REVIEW_REQUEST_SUCCEEDED = "POST_PRESS_RELEASE_REVIEW_REQUEST_SUCCEEDED",
	POST_PRESS_RELEASE_REVIEW_REQUEST_FAILED = "POST_PRESS_RELEASE_REVIEW_REQUEST_FAILED",

    GET_PRESS_RELEASE_REVIEW_LIST_REQUEST = "GET_PRESS_RELEASE_REVIEW_LIST_REQUEST",
    GET_PRESS_RELEASE_REVIEW_LIST_SUCCEEDED = "GET_PRESS_RELEASE_REVIEW_LIST_SUCCEEDED",
    GET_PRESS_RELEASE_REVIEW_LIST_FAILED = "GET_PRESS_RELEASE_REVIEW_LIST_FAILED",

	POST_PRESS_RELEASE_REVIEW_RESULT_REQUEST = "POST_PRESS_RELEASE_REVIEW_RESULT_REQUEST",
	POST_PRESS_RELEASE_REVIEW_RESULT_SUCCEEDED = "POST_PRESS_RELEASE_REVIEW_RESULT_SUCCEEDED",
	POST_PRESS_RELEASE_REVIEW_RESULT_FAILED = "POST_PRESS_RELEASE_REVIEW_RESULT_FAILED",

	DELETE_PRESS_RELEASE_REVIEW_REQUEST = "DELETE_PRESS_RELEASE_REVIEW_REQUEST",
	DELETE_PRESS_RELEASE_REVIEW_REQUEST_SUCCEEDED = "DELETE_PRESS_RELEASE_REVIEW_REQUEST_SUCCEEDED",
	DELETE_PRESS_RELEASE_REVIEW_REQUEST_FAILED = "DELETE_PRESS_RELEASE_REVIEW_REQUEST_FAILED",

    SET_PRESS_RELEASE_COMMENT_HOVER = "SET_PRESS_RELEASE_COMMENT_HOVER",
    SET_PRESS_RELEASE_COMMENT_DONE = "SET_PRESS_RELEASE_COMMENT_DONE",

	GET_PRESS_RELEASE_CREATOR_REQUEST = "GET_PRESS_RELEASE_CREATOR_REQUEST",
	GET_PRESS_RELEASE_CREATOR_SUCCEEDED = "GET_PRESS_RELEASE_CREATOR_SUCCEEDED",
	GET_PRESS_RELEASE_CREATOR_FAILED = "GET_PRESS_RELEASE_CREATOR_FAILED",

    POST_PRESS_RELEASE_CREATOR_REQUEST = "POST_PRESS_RELEASE_CREATOR_REQUEST",
    POST_PRESS_RELEASE_CREATOR_SUCCEEDED = "POST_PRESS_RELEASE_CREATOR_SUCCEEDED",
    POST_PRESS_RELEASE_CREATOR_FAILED = "POST_PRESS_RELEASE_CREATOR_FAILED",

	DELETE_PRESS_RELEASE_CREATOR_REQUEST = "DELETE_PRESS_RELEASE_CREATOR_REQUEST",
	DELETE_PRESS_RELEASE_CREATOR_SUCCEEDED = "DELETE_PRESS_RELEASE_CREATOR_SUCCEEDED",
	DELETE_PRESS_RELEASE_CREATOR_FAILED = "DELETE_PRESS_RELEASE_CREATOR_FAILED",

	POST_PRESS_RELEASE_STATUS_CHANGE_REQUEST = "POST_PRESS_RELEASE_STATUS_CHANGE_REQUEST",
	POST_PRESS_RELEASE_STATUS_CHANGE_SUCCEEDED = "POST_PRESS_RELEASE_STATUS_CHANGE_SUCCEEDED",
	POST_PRESS_RELEASE_STATUS_CHANGE_FAILED = "POST_PRESS_RELEASE_STATUS_CHANGE_FAILED",

	GET_SEND_PRESS_RELEASE_LIST_REQUEST = "GET_SEND_PRESS_RELEASE_LIST_REQUEST",
	GET_SEND_PRESS_RELEASE_LIST_SUCCEEDED = "GET_SEND_PRESS_RELEASE_LIST_SUCCEEDED",
	GET_SEND_PRESS_RELEASE_LIST_FAILED = "GET_SEND_PRESS_RELEASE_LIST_FAILED",

	SET_PRESS_RELEASE_NAME = "SET_PRESS_RELEASE_NAME",

	SET_PRESS_RELEASE_EDIT_ROLE = "SET_PRESS_RELEASE_EDIT_ROLE",
	SET_PRESS_RELEASE_REVIEW_ROLE = "SET_PRESS_RELEASE_REVIEW_ROLE",

	SET_PRESS_LIST_FILTER = "SET_PRESS_LIST_FILTER",
	RESET_PRESS_LIST_FILTER = "RESET_PRESS_LIST_FILTER",

	SET_SAVE_LOADING = "SET_SAVE_LOADING",

	SET_HEADER_LOOP = "SET_HEADER_LOOP",
	SET_FOOTER_LOOP = "SET_FOOTER_LOOP",

	SET_PRESS_RELEASE_PAGE_TYPE = "SET_PRESS_RELEASE_PAGE_TYPE",

	GET_PRESS_RELEASE_LIST_SUITABLE_FOR_CURRENT_PAGE = "GET_PRESS_RELEASE_LIST_SUITABLE_FOR_CURRENT_PAGE",
}

export default PressReleaseActionType

