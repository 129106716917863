import React, { useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from 'dayjs';
import { RootState } from '../../../../reducers';
import { useSelector } from 'react-redux';

const groupSelector = (state: RootState) => state.group

const useStyles = makeStyles({
    container: {
        width: 175,
        display: 'inline-block',
        marginRight: 5,
    },
    input: {
        width: 175,
    },
    icon: {
        fontSize: "2.0rem"
    }
})

interface TermChoiceModel {
    value: number,
    label: string,
    subtract: { num: number, unit: dayjs.OpUnitType },
}

const termChoices: TermChoiceModel[] = [
    { value: 0, label: "過去7日間", subtract: { num: 6, unit: "day" } },
    { value: 1, label: "過去14日間", subtract: { num: 13, unit: "day" } },
    { value: 2, label: "過去28日間", subtract: { num: 27, unit: "day" } },
    { value: 3, label: "過去3ヶ月間", subtract: { num: 3, unit: "month" } },
    { value: 4, label: "過去6ヶ月間", subtract: { num: 6, unit: "month" } },
    { value: 5, label: "過去12ヶ月間", subtract: { num: 12, unit: "month" } },
]

interface Props {
    handleChange: (from: Date, to: Date) => void
}

const TermFilter: React.FC<Props> = ({ handleChange }) => {
    const classes = useStyles();
    const group = useSelector(groupSelector)
    const [term, setTerm] = React.useState(0);

    useEffect(() => {
        if (!group.selectedId) return
        const today_ = new Date()
        const today = dayjs(`${today_.getFullYear()}-${today_.getMonth() + 1}-${today_.getDate()}`)
        const subtract = termChoices[term].subtract
        handleChange(today.subtract(subtract.num, subtract.unit).toDate(), today.toDate())
    }, [term, group.selectedId])

    return (
        <FormControl variant='outlined' className={classes.container}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={term}
                className={classes.input}
                onChange={(e) => setTerm(e.target.value as number)}
                inputProps={{
                    classes: {
                        icon: classes.icon
                    }
                }}
            >
                {termChoices.map(({ value, label }, i) => (
                    <MenuItem key={i} value={value}>{label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
export default TermFilter; 
