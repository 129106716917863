import { call, put, takeLatest } from 'redux-saga/effects';

import * as ActionCreators from '../actions/Plan/ActionCreator';
import { fetchGet } from './fetch';
import PlanActionType from '../actions/Plan/ActionType';

function* getPlanList(action: ReturnType<typeof ActionCreators.getPlanList.request>) {
    try {
        const [data, error] = yield call(fetchGet, `plan/`);
        if(data && !error){
            yield put(ActionCreators.getPlanList.success(data))
        }else{
            yield put(ActionCreators.getPlanList.failure(error))
        }

    } catch (e) {
        yield put(ActionCreators.getPlanList.failure(e))
    }
}


const PlanSaga = [
    takeLatest(PlanActionType.GET_PLAN_LIST_REQUEST, getPlanList)
]

export default PlanSaga;
