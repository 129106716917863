import React, { useState } from 'react';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Typography } from '@material-ui/core';
import SimpleTextField from '../../../Field/SimpleTextField';

// icons
import DeleteIcon from '@material-ui/icons/Delete';

interface SelectProps {
    selected: string[],
    handleChange,
}

const NonMember: React.FC<SelectProps> = ({ selected, handleChange }) => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    return (
        <ul>
            <li className={classes.listDiscription}>
                <Typography>送信したいメールアドレスを入力して追加ボタンを押してください</Typography>
            </li>
            {selected.map((item: string, index: number) => (
                <li key={index} className={classes.nonMemberItem}>
                    <IconButton onClick={() => handleChange(index, true)}>
                        <DeleteIcon />
                    </IconButton>
                    <Typography>{item}</Typography>
                </li>
            ))}
            <li className={classes.nonMemberItem}>
                <SimpleTextField
                    value={email}
                    label='メール'
                    autoFocus
                    handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setEmail(e.target.value);
                    }} />
                <Button className={classes.addButton} color='primary' variant='contained' disabled={!email}
                    onClick={() => {
                        handleChange(email, false);
                        setEmail('');
                    }}>
                    追加
                </Button>
            </li>
        </ul>
    );
}

export default NonMember;

const useStyles = makeStyles({
    nonMemberItem: {
        display: 'flex',
        alignItems: 'center',
    },
    addButton: {
        margin: '5px 0 5px 10px',
    },
    listDiscription: {
        padding: 5,
    },
})
