import ActionType from './ActionType';
import {
    PressReleaseTagListModel, PressReleaseTagListRequestModel, PressReleaseTagRequestModel,
    PressReleaseTagFormModel, PressInfoTagRelationRequestModel,
} from "../../model/PressReleaseTagModel";

import { createAsyncAction } from 'typesafe-actions';

export const getPressReleaseTagList = createAsyncAction(
    ActionType.GET_PRESS_RELEASE_TAG_LIST_REQUEST,
    ActionType.GET_PRESS_RELEASE_TAG_LIST_SUCCEEDED,
    ActionType.GET_PRESS_RELEASE_TAG_LIST_FAILED
)<PressReleaseTagListRequestModel, PressReleaseTagListModel, Error>();

export const postPressReleaseTag = createAsyncAction(
    ActionType.POST_PRESS_RELEASE_TAG_REQUEST,
    ActionType.POST_PRESS_RELEASE_TAG_SUCCEEDED,
    ActionType.POST_PRESS_RELEASE_TAG_FAILED,
)<PressReleaseTagRequestModel, void, Error>();

export const patchPressReleaseTag = createAsyncAction(
    ActionType.PATCH_PRESS_RELEASE_TAG_REQUEST,
    ActionType.PATCH_PRESS_RELEASE_TAG_SUCCEEDED,
    ActionType.PATCH_PRESS_RELEASE_TAG_FAILED,
)<PressReleaseTagRequestModel, void, Error>();

export const deletePressReleaseTag = createAsyncAction(
    ActionType.DELETE_PRESS_RELEASE_TAG_REQUEST,
    ActionType.DELETE_PRESS_RELEASE_TAG_SUCCEEDED,
    ActionType.DELETE_PRESS_RELEASE_TAG_FAILED,
)<PressReleaseTagRequestModel, void, Error>();

export const setPressReleaseTagForm = createAsyncAction(
    ActionType.SET_PRESS_RELEASE_TAG_FORM,
    '',
    ''
)<PressReleaseTagFormModel, void, void>();

export const resetPressReleaseTagForm = createAsyncAction(
    ActionType.RESET_PRESS_RELEASE_TAG_FORM,
    '',
    ''
)<void, void, void>();

export const postPressInfoTagRelation = createAsyncAction(
    ActionType.POST_PRESS_INFO_TAG_RELATION_REQUEST,
    ActionType.POST_PRESS_INFO_TAG_RELATION_SUCCEEDED,
    ActionType.POST_PRESS_INFO_TAG_RELATION_FAILED,
)<PressInfoTagRelationRequestModel, void, Error>();

export const deletePressInfoTagRelation = createAsyncAction(
    ActionType.DELETE_PRESS_INFO_TAG_RELATION_REQUEST,
    ActionType.DELETE_PRESS_INFO_TAG_RELATION_SUCCEEDED,
    ActionType.DELETE_PRESS_INFO_TAG_RELATION_FAILED,
)<PressInfoTagRelationRequestModel, void, Error>();
