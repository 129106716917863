import React from "react";

interface GreetingProps {
    item: any;
}

export const Greeting: React.FC<GreetingProps> = ({item}) => {
    const {publisher, name, last_name} = item;
    let elm = <></>
    if (publisher && name && last_name) {
        elm = (
            <>
                {publisher}<br/>
                {name}<br/>
                {last_name} 様
            </>
        )
    } else if (name && publisher) {
        elm = (
            <>
                {publisher}<br/>
                {name}<br/>
                ご担当者 様
            </>
        )
    } else if (name && last_name) {
        elm = (
            <>
                {name}<br/>
                ご担当者 様
            </>
        )
    } else if (publisher && last_name) {
        elm = (
            <>
                {publisher}<br/>
                {last_name} 様
            </>
        )
    } else if (publisher) {
        elm = (
            <>
                {publisher} 様<br/>
            </>
        )
    } else if (name) {
        elm = (
            <>
                {name}<br/>
                ご担当者 様
            </>
        )
    } else if (last_name) {
        elm = (
            <>
                {last_name} 様
            </>
        )
    }
    return elm;
}

export default Greeting;
