import ActionType from '../actions/PressReleasePublish/ActionType';
import Action from '../actions/PressReleasePublish/Action';
import {
	PressReleasePublishModel
} from "../model/PressReleasePublishModel";

import createReducer from "./createReducer";

const init: PressReleasePublishModel = {
	press_id: "",
	ogp_url: "",
	ogp_title: "",
	ogp_description: "",
	is_publish: false,
	saveLoading: false,
	released_at: null,
}

export const pressReleasePublish = createReducer<PressReleasePublishModel>(init, {
	[ActionType.GET_PRESS_RELEASE_PUBLISH_SUCCEEDED](state: PressReleasePublishModel, action: Action) {
		return {
			...state,
			...action.payload
		}
	},
	[ActionType.SET_PRESS_RELEASE_PUBLISH](state: PressReleasePublishModel, action: Action) {
		return {
			...state,
			...action.payload
		}
	},
	[ActionType.POST_PRESS_RELEASE_PUBLISH_REQUEST](state: PressReleasePublishModel, action: Action) {
		return {
			...state,
			saveLoading: true,
			...action.payload
		}
	},
	[ActionType.POST_PRESS_RELEASE_PUBLISH_SUCCEEDED](state: PressReleasePublishModel, action: Action) {
		return {
			...state,
			saveLoading: false,
			...action.payload
		}
	},
	[ActionType.PATCH_OGP_IMAGE_SUCCEEDED](state: PressReleasePublishModel, action: Action) {
		const result: any = action.payload
		return {
			...state,
			ogp_url: result.ogp_url
		}
	},
});
