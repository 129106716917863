//　編集権限チェック
export const checkEditRole = (pressRelease, me) => {
    let hasEdit = me.uuid == pressRelease.detail.create_user.uuid ? true : false;
    if (!hasEdit && pressRelease.detail.creators.filter(member => member.uuid === me.uuid).length) hasEdit = true;
    return hasEdit
};

//　確認権限チェック
export const checkReviewRole = (member, me) => {
    return (member || []).filter(member => member.user.uuid === me.uuid).length > 0;
};


// レビュー完了済みかチェック
export const checkReviewStatus = (member, me) => {
    let result = false;
    const list = (member || []).filter(member => member.user.uuid === me.uuid);

    // 未完了:null, 承認:false, 修正依頼: true
    if (list.length > 0 && list[0].status !== null) {
        result = true;
    }
    return result;
};
