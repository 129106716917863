import React, { useState } from 'react';

// components
import { Box, Button, Paper } from '@material-ui/core';
import SimpleTextField from '../../Field/SimpleTextField';
import { makeStyles } from "@material-ui/core/styles";


export const CreateGroup = ({ handleClose, handleSave }) => {
	const classes = useStyles();
	const [name, setName] = useState<string>('');

	return (
		<Box className={classes.createGroupPreview}>
			<Box className={classes.createGroupField}>
				<Box className={classes.nameField}>
					<SimpleTextField
						value={name}
						handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
							setName(e.target.value)
						}}
						label='新規プロジェクト名'
						autoFocus={true}
					/>
				</Box>
			</Box>
			<Box className={classes.buttons}>
				<Button variant='outlined' color='primary' onClick={() => handleClose()} className={classes.button}>取り消す</Button>
				<Button variant='contained' color='primary' onClick={() => handleSave(name)} disabled={!name}
					className={classes.button}>
					保存する
				</Button>
			</Box>
		</Box>
	);
}

const CreateGroupWrapper = ({ handleClose, handleSave }) => {
	const classes = useStyles();
	return (
		<Paper className={classes.createGroupBox} elevation={0} variant={"outlined"}>
			<CreateGroup handleClose={handleClose} handleSave={handleSave} />
		</Paper>
	);
}

export default CreateGroupWrapper;


const useStyles = makeStyles({
	createGroupBox: {
		marginTop: 15,
		padding: 20,
		backgroundColor: '#f3f7f4',
	},
	createGroupPreview: {
		display: 'flex',
		alignItems: "center"
	},
	createGroupField: {
		display: 'flex',
		alignItems: 'center',
	},
	nameField: {
		width: 400,
		marginRight: 10,
	},
	buttons: {
		display: 'flex',
		marginLeft: "auto"
	},
	button: {
		marginRight: 10,
		height: 35,
	},
})
