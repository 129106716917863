import {CompanyModel} from "../../model/CompanyModel";
import IconPressRelease from "../../assets/guide/press_release.svg";
import IconPressKit from "../../assets/guide/press_kit.svg";
import IconMedia from "../../assets/guide/media.svg";
import IconMember from "../../assets/guide/member.svg";
import IconTopic from "../../assets/guide/neta.svg";
import IconBriefing from "../../assets/guide/briefing.svg";
import IconDashBoard from "../../assets/guide/dashboard.svg";
import IconRadar from "../../assets/guide/article_radar.svg";


interface StepContentModel {
	label?: string | undefined,
	text: string,
	linkText: string,
	link: string,
	image?: string | undefined,
}

export interface StepModel {
	type: "onePerson" | "team",
	contents: Array<StepContentModel>,
}

interface SettingStepInfoModel {
	onePersonPRStep: StepModel,
	teamPRStep: StepModel
}


export const settingStepInfo = (company: CompanyModel): SettingStepInfoModel => {
	const {plan} = company;
	const {radar} = company.option;

	const {text: presskitText, link: presskitLink, linkText: presskitLinkText} = settingPresskitStepInfo(plan.code);
	const {text: radarText, link: radarLink, linkText: radarLinkText} = settingRadarStepInfo(radar);
	
	return {
		onePersonPRStep: {
			type: "onePerson",
			contents: [
				{
					label: "プレスリリース作成・配信",
					text: `デザイン別、テーマ別に多数のテンプレートを用意しています。\n
						   HARVEST独自のAIが校閲を行う機能や、プレスリリースの配信先メディアを自動選定する機能もありますので、1人で広報をされている方でも簡単にプレスリリースの作成・配信ができます。`,
					linkText: "プレスリリース一覧へ",
					link: "/press_release/",
					image: IconPressRelease,
				},
				{
					label: "ダッシュボード確認",
					text: `プレスリリース配信後は、ダッシュボードを確認しましょう。\n
						   ダッシュボードでは、プレスリリース毎に配信先数やメールの開封率の確認ができます。
						   週間・月別でデータを確認することも可能です。`,
					linkText: "ダッシュボードへ",
					link: "/dashboard/",
					image: IconDashBoard,
				},
				{
					label: "記事化レーダー確認",
					text: radarText,
					linkText: radarLinkText,
					link: radarLink,
					image: IconRadar,
				},
			]
		},
		teamPRStep: {
			type: "team",
			contents: [
				{
					label: "メンバー招待",
					text: `プロジェクトに新しいメンバーを招待しましょう。\n
						   メンバーを追加することで、プレスリリースの共同編集や確認・修正依頼を出すことができます。`,
					linkText: "メンバー管理画面へ",
					link: "/member/",
					image: IconMember,
				},
				{
					label: "メディアリスト登録",
					text: `メディアリストAI自動選定の他に、メディアリストを簡単に作成することもできます。
						   自社のメディアのリストの登録、カテゴリー毎のリストの登録ができます。\n
						   リストを作成しておくと、配信がスムーズに行えます。`,
					linkText: "メディアリスト管理画面へ",
					link: "/media/",
					image: IconMedia,
				},
				{
					label: "ブリーフィングシート作成・配信",
					text: `アンケートを作成して、社内・社外に簡単に送ることができます。
						   定期的に、広報ネタを収集して発信に備えましょう！\n
						   ブリーフィングシートから、プレスリリースを作成することもできます。`,
					linkText: "ブリーフィングシートへ",
					link: "/briefing/",
					image: IconBriefing,
				},
				{
					label: "プレスキット作成",
					text: presskitText,
					linkText: presskitLinkText,
					link: presskitLink,
					image: IconPressKit,
				},
				{
					label: "プレスリリース作成・配信",
					text: `プレスリリース毎に作成メンバーを追加し、共同編集を行うことができます。\n
						   プレスリリースや配信設定の確認・修正依頼を出すことができ、ブラッシュアップもスムーズに行えます。`,
					linkText: "プレスリリース一覧へ",
					link: "/press_release/",
					image: IconPressRelease,
				},
				{
					label: "ダッシュボード確認",
					text: `プレスリリース配信後は、ダッシュボードの確認を行いましょう。\n
						   ダッシュボードでは、プレスリリース毎に配信先数やメールの開封率の確認が行えます。
						   週間・月別でデータを確認することも可能です。`,
					linkText: "ダッシュボードへ",
					link: "/dashboard/",
					image: IconDashBoard,
				},
				{
					label: "記事化レーダー確認",
					text: radarText,
					linkText: radarLinkText,
					link: radarLink,
					image: IconRadar,
				},
				{
					label: "時事ネタ収集(未来トピック)",
					text: `未来トピックは、HARVEST独自のAIが未来のイベントや法改正などに関連するワードをレコメンドする機能です。
						   未来の月毎に関連するワードから関連する情報を検索し、収集することが可能です。\n
						   将来起こりうる出来事やイベント、法律の改正など、重要な出来事を把握して企業活動や事業の効率化を図りましょう。`,
					linkText: "未来トピックへ",
					link: "/topic/",
					image: IconTopic,
				},
			]
		}
	};
};


const settingPresskitStepInfo = (plan: number): StepContentModel => {
	const baseText = `作成したプレスキットは、プレスリリースとセットで配信することができます。
					  関連する素材やファイルをメディアに共有することができ、メディアとのやりとりがスムーズになります。`;

	const text = plan === 0 ?
					baseText +
					`\n\n無料プランではプレスキットの作成ができません。
					作成するには、プランのアップグレードが必要です。`
					:
					baseText;

	const link = plan === 0 ? "/setting/plan" : "/press_kit/";
	const linkText = plan === 0 ? "プランをアップグレードする" : "プレスキット一覧へ";

	return {
		text,
		link,
		linkText,
	};
};


interface RadarModel {
	isAvailable: boolean,
	quantity: number,
}

const settingRadarStepInfo = (radar: RadarModel): StepContentModel => {
	const baseText = `記事化レーダーは、登録したワードが記事化されている件数を調べることができる機能です。`;

	const text = radar.isAvailable ?
					baseText
					:
					baseText +
					`\n\n現在、記事化レーダーは無効になっています。
					記事化レーダーを利用するには、オプションの追加が必要です。`;

	const link = radar.isAvailable ? "/radar/" : "/setting/plan";
	const linkText = radar.isAvailable ? "記事化レーダーへ" : "オプションを追加する";

	return {
		text,
		link,
		linkText,
	};
};

