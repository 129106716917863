import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import style from "./detail_info.module.scss";

// components
import {Typography, Button, Box} from "@material-ui/core";
import Reviewer from "./Reviewer";
import ReserveReviewer from "./ReserveReviewer";
import Member from "./Member";
import PlanAndTicket from "./PlanAndTicket";

// state
import {RootState} from "../../../../../reducers";
import DeadLine from "../../DeadLine";
import AvatarChip from "../../../../Common/AvatarChip";
import {makeStyles} from "@material-ui/core/styles";
import Tag from "./Tag";

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const companySelector = (state: RootState) => state.company;

const useStyles = makeStyles({
    skipLabel: {
        marginTop: 10,
        fontSize: 12,
        textDecoration: "underLine"
    },
    upgradeBox: {
        marginTop: 5,
        textAlign: 'center'
    }
});

const Info: React.FC = () => {

    const classes = useStyles();
    const pressRelease = useSelector(pressReleaseSelector);
    const pressReleaseReserve = useSelector(pressReleaseReserveSelector);
    const company = useSelector(companySelector);
    const {plan} = company;
    const isReviewSkipLimit = plan.is_review_skip_limit;
    const [settingChange, setSettingChange] = useState<boolean>(false);

    const reviewer = pressReleaseReserve.data.reviewer || []

    useEffect(() => {
        setSettingChange(!isReviewSkipLimit);
    },[isReviewSkipLimit]);

    const upgrade =
        <>
            <Typography variant={"caption"}>
                確認フロー機能の変更を行うには、プランのアップグレードが必要です。
            </Typography>
            <Box className={classes.upgradeBox}>
                <NavLink exact to={"/setting/plan"}>
                    <Button variant="contained" size="large" color="primary">
                        プランのアップグレード
                    </Button>
                </NavLink>
            </Box>
        </>

    const skip =
        <>
            <Typography variant={"body1"} gutterBottom>確認フロー機能OFFの設定になっています。</Typography>
            {settingChange ?
                <>
                    <Typography variant={"caption"}>確認依頼を出したい場合は、</Typography><NavLink to={"/setting/service/edit/"} className={classes.skipLabel}>設定の変更</NavLink>
                    <Typography variant={"caption"}>を行ってください。</Typography>
                </>
                : upgrade
            }
        </>

    const nonSkip =
        <>
            <Typography variant={"body1"} gutterBottom>確認依頼をだしてください。</Typography>
            {settingChange ?
                <>
                    <Typography variant={"caption"}>確認をスキップしたい場合は、</Typography><NavLink to={"/setting/service/edit/"} className={classes.skipLabel}>設定の変更</NavLink>
                    <Typography variant={"caption"}>を行ってください。</Typography>
                </>
                : upgrade
            }
        </>

    return (
        <div className={style.wrap}>
            <PlanAndTicket planName={plan.name} />
            {pressRelease.detail.status > 3 &&
            <section className={style.item}>
              <p className={style.title}>配信確認</p>
              <div className={style.inner}>
                  {
                      reviewer.length > 0 ?
                          <>
                              <div className={style.deadline}>
                                  <DeadLine date={pressReleaseReserve.data.deadline_at}
                                            member={pressReleaseReserve.data.reviewer}
                                            min={false}/>
                              </div>
                              <p className={style.subTitle}>校閲者</p>
                              <ReserveReviewer/>
                              <p className={style.subTitle}>依頼者</p>
                              <AvatarChip user={pressRelease.review.user}
                                          message={<p>{pressReleaseReserve.data.comment}</p>}
                                          updated_at={pressReleaseReserve.data.updated_at}/>
                          </>
                          : company.is_review_skip ? skip : nonSkip
                  }
              </div>
            </section>
            }

            <section className={style.item}>
                <p className={style.title}>プレスリリース確認</p>
                <div className={style.inner}>
                    {
                        pressRelease.detail.status > 0 ?
                            <>
                                {
                                    pressRelease.review.deadline_at ?
                                        <>

                                            <div className={style.deadline}>
                                                <DeadLine date={pressRelease.review.deadline_at}
                                                          member={pressRelease.review.member}
                                                          min={false}/>
                                            </div>
                                            <p className={style.subTitle}>校閲者</p>
                                            <Reviewer/>
                                            <p className={style.subTitle}>依頼者</p>
                                            <AvatarChip user={pressRelease.review.user}
                                                        message={<p>{pressRelease.review.comment}</p>}
                                                        updated_at={pressReleaseReserve.data.updated_at}/>
                                        </>
                                        : skip
                                }
                            </>
                            : company.is_review_skip ? skip : nonSkip
                    }
                </div>
            </section>
            <Tag/>
            <Member/>
        </div>
    );
};

export default Info;

