export const FixedPhraseTemplate  =  [
	{
		title: "挨拶や訪問 未実施",
		text: `初めまして。○○○株式会社 広報部の○○と申します。

			弊社は、ビューティ・ウェルネス領域で独自技術を用いた家電ブランドの開発をしている企業です。
			●月●日に、自宅で手軽なボディメイク効果が期待できるトレーニング機器「（名称）」をリリースいたしました。

			貴誌の「◎◎」のコーナーを拝読し、読者層である40代〜50代の男性にご興味を持っていただけそうな内容だと思い、プレスリリースを送付いたします。

			概要
			「（名称）」は、弊社独自開発の◯◯◯◯機能を使用したトレーニング機器です。
			テレビを観たり読書をしたりしながらの “ながら運動” で手軽にボディメイク効果を見込めることにより、外出自粛による運動不足の解消をはじめとしたヘルスケアをサポートします。

			背景
			新型コロナウイルスの流行により、人が密集する場所への外出やスポーツジムなどの利用を控える動きがあります。通常よりも身体を動かす機会が減っていおり、ヘルスケアの観点から危惧されています。

			ご興味を持っていただけるようであれば、
			ぜひご挨拶も兼ねてご説明の機会をいただきたく存じます。
			ご都合のよろしい日時をいくつかご教示いただけますと幸いです。

			直近の業界の動きなど、ご提供できる情報もまとめてお持ちいたします。

			それでは、どうぞよろしくお願いいたします。`,
	},
	{
		title: "挨拶や訪問済み (BtoB)",
		text: `いつもお世話になっております。○○○○株式会社 広報部の○○です。

			先日Facebookで、お子さまとキャンプを楽しまれている様子を拝見しました！
			私もこの夏キャンプデビューしたので、またお会いしたときに色々とお話したいです^^

			さて、弊社では●月●日に新サービス「（名称）」を発表いたしました。
			プレスリリースを添付いたしますので、お目通しいただけますと幸いです。

			概要
			「（名称）」は、◎◎◎◎をオンラインで貸し借りできるサービスです。
			契約期間に捉われずにリーズナブルな◎◎◎◎を提供できることにより、△△△△の実現を支援します。

			背景
			新型コロナウイルスの拡大によりリモートワークが加速し、△△△△の見直しが進んでいます。一方、◎◎◎◎は長期契約を前提しているため、〜〜〜〜が困難という事情があります。企業にとって、◎◎◎◎に対して不要な費用が発生することが課題となっています。

			もし追加で情報が必要であれば、いつでもお気軽にご連絡ください。

			それでは、どうぞよろしくお願いいたします。`,
	},
	{
		title: "挨拶や訪問済み (BtoC)",
		text: `いつもお世話になっております。○○○○株式会社 広報部の○○です。

			改めて、先日は会食の席にてご一緒させていただき、誠にありがとうございました。
			美味しいお食事とともに為になるお話をお聞きでき、とても実りのある時間となりました。

			さて、弊社では●月●日に新サービス「（名称）」を発表いたしました。
			プレスリリースを添付いたしますので、お目通しいただけますと幸いです。

			概要
			「（名称）」は、◎◎◎◎から商品を直接購入できるアプリです。１点から送料無料で購入でき、商品はユーザーの自宅に届きます。人が集まる場所に外出をしたくない場合でも、家にいながら△△△△を受け取れます。

			背景
			新型コロナウイルスの影響により、外出自粛が続いています。ECサイト等を利用した買い物の需要が増えている中、△△△△も自宅で受け取ることができれば、これまでと変わらず△△△△を使った◻︎◻︎◻︎◻︎を楽しむことができます。

			もし追加で情報が必要であれば、いつでもお気軽にご連絡ください。

			それでは、どうぞよろしくお願いいたします。`,
	},
]
