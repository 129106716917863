import PressKitActionType from '../actions/PressKit/ActionType';
import PressKitAction from '../actions/PressKit/Action';
import {
	PressKitListModel,
	PressKitModel,
	PressKitRequestModel,
	PressKitCreateModel,
	PressKitAddModel,
	PressKitUploadModel,
	PressKitEditModel,
	PressKitFirestoreListModel,
	PressKitFirestoreModel,
	PressKitStatusModel
} from "../model/PressKitModel";
import createReducer from "./createReducer";
import { combineReducers } from "redux";
import { ListModel } from '../model/ListModel'
import { STATUS_EDIT } from '../config/status_type';

const now = new Date()
const initialStateDetail: PressKitModel = {
	id: "",
	body: {
		component: [],
		html: '',
		style: [],
		css: ''
	},
	create_user: {
		first_name: "",
		last_name: "",
        src: "",
        color_cd: "",
	},
	create_at: now,
	name: '',
    fetched: false,
};

const detail = createReducer<PressKitModel>(initialStateDetail, {
	[PressKitActionType.GET_PRESS_KIT_LIST_SUCCEEDED](state: PressKitListModel[], action: PressKitAction) {
		//　一覧開いたときに初期化
		return initialStateDetail
	},
	[PressKitActionType.GET_PRESS_KIT_DETAILS_REQUEST](state: PressKitModel, action: PressKitAction) {
		return {
			...state,
			fetched: false,
		}
	},
	[PressKitActionType.GET_PRESS_KIT_DETAILS_SUCCEEDED](state: PressKitModel, action: PressKitAction) {
		const result:any = action.payload
		return {
            ...result,
            fetched: true,
		}
	},
	[PressKitActionType.POST_PRESS_KIT_DETAILS_REQUEST](state: PressKitRequestModel, action: PressKitAction) {
		return {
			...state,
			fetched: false
		}
	},
    [PressKitActionType.POST_PRESS_KIT_DETAILS_SUCCEEDED](state: PressKitRequestModel, action: PressKitAction) {
		const result:any = action.payload
        return {
            ...state,
            fetched: true,
        }
    },
	[PressKitActionType.SET_PRESS_KIT_DETAILS](state: PressKitModel, action: PressKitAction) {
		const result:any = action.payload
        return {
			...state,
			body: result.body
        }
	},
	[PressKitActionType.SET_PRESS_KIT_NAME](state: PressKitModel, action: PressKitAction) {
		return {
			...state,
			name: action.payload,
		}
	}
});

const initialStatePageList: ListModel = {
	results: [],
	offset: 0,
	count: 0
}


const list = createReducer<ListModel>(initialStatePageList, {
    [PressKitActionType.GET_PRESS_KIT_LIST_SUCCEEDED](state: ListModel, action: PressKitAction) {
        return action.payload
    }
});


const initialStateCreate:  PressKitCreateModel = {
	dialog: false,
	template: {
		body: {
			component: [],
			html: '',
			style: [],
			css: '',
		},
		name: ''
	},
	historyList: []
};


const create = createReducer<PressKitCreateModel>(initialStateCreate, {
	[PressKitActionType.SET_PRESS_KIT_CREATE_DIALOG](state: PressKitCreateModel, action: PressKitAction) {
		return {
			...state,
			dialog :action.payload
		}
	},
	[PressKitActionType.GET_PRESS_KIT_TEMPLATE_SUCCEEDED](state: PressKitCreateModel, action: PressKitAction) {
		return {
			...state,
			template :action.payload
		}
	},
	[PressKitActionType.GET_PRESS_KIT_TEMPLATE_LIST_SUCCEEDED](state: PressKitCreateModel, action: PressKitAction) {
	return {
		...state,
		template :action.payload[0].history,
		historyList :action.payload
	}
}
});

const initialStateAdd: PressKitAddModel = {
	dialog: false,
	selectedPressKit: '',
	pressKitDeleteDialog: false,
	deleteTargetPressKitId: '',
}

const add = createReducer<PressKitAddModel>(initialStateAdd, {
	[PressKitActionType.SET_ADD_PRESS_KIT_DIALOG](state: PressKitAddModel, action: PressKitAction) {
		return {
			...state,
			dialog: action.payload
		}
	},
	[PressKitActionType.SET_SELECTED_PRESS_KIT](state:PressKitAddModel, action: PressKitAction) {
		return {
			...state,
			selectedPressKit: action.payload,
		}
	},
	[PressKitActionType.SET_PRESS_KIT_DELETE_DIALOG](state: PressKitAddModel, action: PressKitAction) {
		return {
			...state,
			pressKitDeleteDialog: action.payload
		}
	},
	[PressKitActionType.SET_PRESS_KIT_DELETE_TARGET](state: PressKitAddModel, action: PressKitAction) {
		return {
			...state,
			deleteTargetPressKitId: action.payload
		}
	}
})

const initialStateUpload: PressKitUploadModel = {
	dialog: false,
}

const upload = createReducer<any>(initialStateUpload, {
	[PressKitActionType.SET_PRESS_KIT_UPLOAD_DIALOG](state: PressKitUploadModel, action: PressKitAction) {
		return {
			...state,
			dialog: action.payload
		}
	},
})

const initialStateEdit: PressKitEditModel = {
	targetName: '',
	sorting: false,
	pressKitSorting: false,
	viewFile: [],
	viewPressKit: [],
	sortHistory: [],
}

const edit = createReducer(initialStateEdit, {
	[PressKitActionType.SET_PRESS_KIT_EDIT_TARGET](state: PressKitEditModel, action: PressKitAction) {
		const { targetName, targetEl } = action.payload
		return {
			...state,
			targetName,
		}
	},
	[PressKitActionType.SET_FILE_SORTING](state: PressKitEditModel, action: PressKitAction) {
		return {
			...state,
			sorting: action.payload,
		}
	},
	[PressKitActionType.SET_PRESS_KIT_SORTING](state: PressKitEditModel, action: PressKitAction) {
		return {
			...state,
			pressKitSorting: action.payload,
		}
	},
	[PressKitActionType.SET_VIEW_FILE](state: PressKitEditModel, action: PressKitAction) {
		return {
			...state,
			viewFile: action.payload,
		}
	},
	[PressKitActionType.SET_VIEW_PRESS_KIT](state: PressKitEditModel, action: PressKitAction) {
		return {
			...state,
			viewPressKit: action.payload,
		}
	},
	[PressKitActionType.APPEND_SORT_HISTORY](state: PressKitEditModel, action: PressKitAction) {
		return {
			...state,
			sortHistory: [...state.sortHistory, action.payload],
		}
	},
	[PressKitActionType.CLEAR_SORT_HISTORY](state: PressKitEditModel, action: PressKitAction) {
		return {
			...state,
			sortHistory: [],
		}
	}
})

const initialStateFirestore: PressKitFirestoreListModel = {
	pressKits: [],
	files: []
}

const firestore = createReducer(initialStateFirestore, {
	[PressKitActionType.SET_FIRESTORE_PRESS_KIT](state: PressKitFirestoreModel, action: PressKitAction) {
		return {
			...state,
			pressKits: action.payload,
		}
	},
	[PressKitActionType.SET_PRESS_KIT_FIRESTORE_FILES](state: PressKitFirestoreModel, action: PressKitAction) {
		return {
			...state,
			files: action.payload,
		}
	}
})

const initialState: PressKitStatusModel = {
	totalFolder: 0,
	totalSize: 0
}

const status = createReducer(initialState, {
	[PressKitActionType.GET_PRESS_KIT_STATUS_SUCCEEDED](state: PressKitStatusModel, action: PressKitAction) {
		const { total_folder, total_size } = action.payload;
		return {
			totalFolder: total_folder,
			totalSize: total_size
		}
	},
})

export const pressKit = combineReducers({
	list,
	detail,
	create,
	add,
	upload,
	edit,
	firestore,
	status,
});
