import React from 'react';
import Title from '../Title';
import SubTitle from "../SubTitle";
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/pressRelease/reviewResult/image01.png';
import Image02 from '../../../assets/help/pressRelease/reviewResult/image02.png';

const Create = () => {
	return (
		<div className={style.box}>
			<Title title={"5. 修正依頼確認・反映（確認フロー機能ONの場合）"}/>
			<SubTitle step={1} title={"修正依頼の確認"}/>
			<Typography gutterBottom variant={"body1"}>
				「修正依頼あり」のリンクを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				確認者の文章、編集中の文章、差分をタブ切り替えで確認することができます。<br/>
				この画面で内容を修正する場合は、「編集中の文章」タブを表示し編集します<br/>
				「保存」ボタン押下で保存することができます。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
		</div>
	);
};

export default Create
