import React from "react";

//component
import {SearchResultListItem} from "./ListItem";
import {SearchResultTitle} from "./SearchResultTitle";

//model
import {TopicWordSearchResultContentModel} from "../../../model/TopicModel";

//style
import style from "./searchResult.module.scss";

interface Props {
    list: Array<TopicWordSearchResultContentModel>
}

export const SearchResultListLayout: React.FC<Props> = ({list}) => {
    return (
        <div className={style.root}>
            <SearchResultTitle/>
            <ul className={style.searchResultListRoot}>
            {list.map((item, index) => (
                <SearchResultListItem item={item} key={index}/>
            ))}
            </ul>
        </div>
    );
};
