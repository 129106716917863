enum ErrorActionType {
	SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',

	SET_ERROR_DIALOG = 'SET_ERROR_DIALOG',

	CLEAN_UP_ERROR_STATE = 'CLEAN_UP_ERROR_STATE'
}

export default ErrorActionType;

