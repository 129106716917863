import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/pressKit/create/image01.png';
import Image02 from '../../../assets/help/pressKit/create/image02.png';
import Image03 from '../../../assets/help/pressKit/create/image03.png';
import Image04 from '../../../assets/help/pressKit/create/image04.png';
import Image05 from '../../../assets/help/pressKit/create/image05.png';
import Point from "../Point";
import PageTitle from "../PageTitle";

const Create = () => {
	return (
		<div className={style.box}>
			<PageTitle title={"プレスキット"}/>
			<Point>
				プレスキット作成して、プレスリリースに添付すると、
				記事用の素材のやりとりが簡単になります。<br/>
				高画質動画、画像、PDF、excel、word様々なファイルのアップロードが可能です。
			</Point>
			<Title title={"1. 作成"}/>
			<SubTitle step={1} title={"フォルダ作成"}/>
			<Typography gutterBottom variant={"body1"}>
				「プレスキットを作成する」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				フォルダ名を入力します。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<SubTitle step={2} title={"ファイルアップロード"}/>
			<Typography gutterBottom variant={"body1"}>
				「ファイルをアップロード」ボタンを押下します。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				ドラック&ドロップ、またはファイルアップロードします。
			</Typography>
			<div className={style.img}>
				<img src={Image04}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				ファイルが表示されたら完成です。
			</Typography>
			<div className={style.img}>
				<img src={Image05}/>
			</div>
		</div>
	);
};

export default Create
