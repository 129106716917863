import React from 'react';
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const HelpTitle = ({title}) => {

	const classes = useStyles();

	return (
		<>
			<Typography variant="h1"gutterBottom className={classes.title}>
				{title}
			</Typography>
		</>
	);
};

export default HelpTitle;

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			color: "#2eb964",
			padding: 10,
			borderBottom: "2px solid #2eb964"
		},
	}),
);
