import moment from "moment";

//model
import {
    DatePickerSelected,
    MappedRadarResultModel,
    RadarResultContentModel,
    SelectedContentModel
} from "../model/RadarModel";

//受け取ったデータをMonthDatesにマッピングする
export const mapResponseToMonthDates = (items: Array<RadarResultContentModel>, monthDates: Array<[number, number, number]>) => {
    //日数分の空配列を用意する。ここにitemsを振り分けていく
    const mapped: Array<Array<RadarResultContentModel>> = monthDates.map(_ => [])
    items.forEach(result => {
        const idx = makeIndex(monthDates, result)
        mapped[idx].push(result)
    })
    return mapped
}

const makeIndex = (monthDates: Array<[number, number, number]>, result: RadarResultContentModel) => {
    const idx = monthDates.findIndex(date => date[0] === result.year && date[1] === result.month - 1 && date[2] === result.date)
    return idx
}

export const selectDisplayItems = (targetWordId: number | null, displayItems: Array<MappedRadarResultModel>) => {
    const targetDisplayItem = !!targetWordId && displayItems.length > 0
        ? displayItems.find(elm => elm.radar_word_id === targetWordId)
        : undefined
    const displayItemIndex = !!targetDisplayItem
        ? displayItems.findIndex(item => item.radar_word_id === targetWordId)
        : null
    const items = displayItemIndex != null && displayItemIndex != -1 && displayItems[displayItemIndex].items
        ? displayItems[displayItemIndex].items
        : []
    return items
}


export const makeStartAndEndDate = (start: SelectedContentModel, end: SelectedContentModel) => {
    return [convertDateObjToStr(start), convertDateObjToStr(end)]
}

const convertDateObjToStr = (date: SelectedContentModel) => {
    return moment(date).format('YYYY-MM-DD')
}

export const endDateLessThanEqualStartDate = (start: SelectedContentModel | null, end: SelectedContentModel | null) => {
    if (start === null || end === null) {
        return false
    }
    const startDateObj = new Date(start.year, start.month, start.date)
    const endDateObj = new Date(end.year, end.month, end.date)
    return startDateObj >= endDateObj
}

const calcDateDiff = (start: SelectedContentModel, end: SelectedContentModel) => {
    const s = moment([start.year, start.month, start.date])
    const e = moment([end.year, end.month, end.date])
    return e.diff(s, 'days')
}

export const makeDates = (start: SelectedContentModel, end: SelectedContentModel): Array<[number, number, number]> => {
    const dateDiff = calcDateDiff(start, end)
    const dateDiffs = [...Array(dateDiff + 1).keys()]
    const dates = dateDiffs.map(diff => {
        const targetDate = moment([start.year, start.month, start.date]).add(diff, 'days')
        return [targetDate.get('year'), targetDate.get('month'), targetDate.get('date')] as [number, number, number]
    })
    return dates
}

export const makeCurrentMonthDate = (type: 'start' | 'end'): SelectedContentModel => {
    const target = type === 'start' ? moment().startOf('month') : moment().endOf('month')
    return {
        year: target.get('year'),
        month: target.get('month'),
        date: target.get('date')
    }
}

export const targetIndexToDate = (targetIndex: number | null, selected: DatePickerSelected) => {
    if (targetIndex === null || selected.start === null) {
        return ''
    }
    const s = moment([selected.start.year, selected.start.month, selected.start.date])
    return s.add(targetIndex, 'days').format("YYYY年MM月DD日")
}
