import React, {useEffect} from "react";
import { withTheme } from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { useFirebaseConnect } from "react-redux-firebase";
import 'firebase/database';

// action
import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";
import * as PressReleaseFirebaseActionCreators from "../../actions/PressReleaseFirebase/ActionCreator";

// components
import GEditor from "../../components/PressRelease/Editor/";
import Layout from "./Layout";

// state
import {RootState} from "../../reducers";
import {PAGE_EDIT} from "../../config/page_type";
import {checkEditRole, checkReviewRole} from "../../util/checkAuthority";
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const routerSelector = (state: RootState) => state.router;
const firebaseDataSelector = (state: RootState) => state.firebase.data;

type PageProps = {} & RouteComponentProps<{groupId:string, pressReleaseId: string}>;

const CreatePage: React.FC<PageProps> = props => {
	const dispatch = useDispatch();
	const pressRelease = useSelector(pressReleaseSelector);
	const me = useSelector(meSelector);
	const group = useSelector(groupSelector);
	const route = useSelector(routerSelector);
	const accessData = useSelector(firebaseDataSelector);

	// ウィンドウorタブを削除した時に、該当するユーザーの情報をdbから削除するのに必要
	useEffect(() => {
		if (accessData && accessData[pressRelease.detail.press_id] && accessData[pressRelease.detail.press_id].edit) {
			dispatch(PressReleaseFirebaseActionCreators.disconnectRealtimeDatabase.request({
				page: "edit",
				press_id: pressRelease.detail.press_id,
				user_id: me.uuid
			}));
		}
	}, [document.querySelectorAll("#appEditor")]);

	useEffect(()=> {
		const pressReleaseId = props.match.params.pressReleaseId
		if (pressReleaseId){
			dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({press_id: pressReleaseId}));
		}
	}, [group.selectedId]);

	useEffect(() => {
		dispatch(PressReleaseActionCreators.setPressReleasePageType.request(PAGE_EDIT));
	}, []);

	useEffect(()=> {
		if (me.uuid && pressRelease.detail.fetched === true) {
			dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(checkEditRole(pressRelease, me)));
			dispatch(PressReleaseActionCreators.setPressReleaseReviewRole.request(checkReviewRole(pressRelease.detail.review.member, me)));
		}
	}, [me, pressRelease.detail, pressRelease.creators]);


	useFirebaseConnect(`${pressRelease.detail.press_id}/edit`);

	// RealtimeDatabaseにアクセス時間を記録する
	useEffect(() => {
		if (pressRelease.detail.press_id && me.uuid && pressRelease.role.edit) {
			dispatch(PressReleaseFirebaseActionCreators.postPressReleaseAccessData.request({
				page: "edit",
				press_id : pressRelease.detail.press_id,
				user_id: me.uuid
			}));
		}
	}, [pressRelease.detail.press_id, pressRelease.role.edit, me.uuid]);

	// ブラウザバックで前ページに戻った場合に、RealtimeDatabaseへアクセスデータを削除しにいくのに必要
	useEffect(() => {
		if (route.action === "POP") {
			if (!route.location.pathname.includes("/edit")) {
				dispatch(PressReleaseFirebaseActionCreators.deletePressReleaseAccessData.request({
					page: "edit",
					press_id : pressRelease.detail.press_id,
					user_id: me.uuid
				}));
			}

			// 編集可能なPRに入った後、編集不可のPRに入った際、dbに書き込まないようにするためにdispatchする
			dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(false));
		}
	}, [route]);

	// useEffect(()=> {
	// 	const {press_id, status}  = pressRelease.detail;
	// 	if (group.selectedId && press_id && status) {
	// 		dispatch(push(createPressReleaseURL(group.selectedId, press_id, status)))
	// 	}
	// }, [group.selectedId, pressRelease.detail.status]);

	return (
		<Layout page={PAGE_EDIT}>
			<GEditor
				detail={pressRelease.detail}/>
		</Layout>
	);
}

export default withTheme(CreatePage);
