import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Checkbox } from '@material-ui/core';

// actions
import * as PressReleaseTagActionCreators from '../../../actions/PressReleaseTag/ActionCreator';

// state
import { RootState } from '../../../reducers';
const pressReleaseTagSelector = (state: RootState) => state.pressReleaseTag;

const CustomCheckbox = ({ item, tag }) => {
    const dispatch = useDispatch();
    const { fetched } = useSelector(pressReleaseTagSelector).list;
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setChecked(Boolean(item.tags.find(({ id }) => id === tag.id)));
    }, [item.tags, tag])

    const handlePostPressTagRelation = () => {
        dispatch(PressReleaseTagActionCreators.postPressInfoTagRelation.request({
            press_id: item.press_id,
            tag_id: tag.id
        }));
    }

    const handleDeletePressTagRelation = () => {
        dispatch(PressReleaseTagActionCreators.deletePressInfoTagRelation.request({
            press_id: item.press_id,
            tag_id: tag.id
        }));
    }

    const handleChange = () => {
        if (checked) handleDeletePressTagRelation();
        else handlePostPressTagRelation();
        setChecked(!checked);
    }

    return (
        <Checkbox
            checked={checked}
            onChange={() => handleChange()}
            value={tag.id}
            disabled={!fetched}
            color="primary"
        />
    );
}

export default CustomCheckbox;
