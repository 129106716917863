import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Box, Button, Paper, Tooltip } from '@material-ui/core';
import SimpleTextField from '../../Field/SimpleTextField';
import { makeStyles } from "@material-ui/core/styles";
import { Label } from './Label';

// utils
import { checkColorCode, generateRandomColorCode } from './utils';

// config
import { defaultBgColor, blackText, whiteText, fixed_colors } from '../../../config/press_tag_colors';
import { theme } from '../../../withRoot';

// actions
import * as PressReleaseTagActionCreators from '../../../actions/PressReleaseTag/ActionCreator';

// image
import CachedIcon from '@material-ui/icons/Cached';

// state
import { RootState } from '../../../reducers';
const pressReleaseTagSelector = (state: RootState) => state.pressReleaseTag;

export const CreateLabel = ({ handleCreateClose, handleSave }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { name, description, bg_color } = useSelector(pressReleaseTagSelector).form;

    const [IsErrorOnBgColor, setIsErrorOnBgColor] = useState<boolean>(false);
    const [bgColor, setBgColor] = useState<string>(theme.palette.primary.main);
    const [textIsBlack, setTextIsBlack] = useState<boolean>(false);

    const handleChangeTextField = (payload) => {
        dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request(payload));
    }

    const txtColor = textIsBlack ? blackText : whiteText;

    useEffect(() => {
        const { isError, isBright } = checkColorCode(bg_color);
        setBgColor(isError ? defaultBgColor : bg_color);
        setTextIsBlack(isBright);
        setIsErrorOnBgColor(isError);
    }, [bg_color]);

    const handleSetRandomColor = () => {
        const randomColorCode = generateRandomColorCode();
        dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request({
            bg_color: randomColorCode,
        }));
    }

    const handleChangeColor = (text: string) => {
        if (text.length < 8) {
            dispatch(PressReleaseTagActionCreators.setPressReleaseTagForm.request({
                bg_color: text.length > 0 ? text : '#',
            }));
        }
    }

    return (
        <>
            <Box className={classes.createLabelPreview}>
                <Box className={classes.labelWrapper}>
                    <Label name={name} txtColor={txtColor} bgColor={bgColor} />
                </Box>
                <Box className={classes.colorField}>
                    <SimpleTextField
                        value={bg_color}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeColor(e.target.value)}
                    />
                </Box>
                <Tooltip title='色をランダムに変更する' arrow className={classes.cachedButton}>
                    <Button style={{ backgroundColor: bgColor }} onClick={() => handleSetRandomColor()}>
                        <CachedIcon style={{ color: txtColor }} />
                    </Button>
                </Tooltip>
                <ColorPanel handleChange={handleChangeColor} />
            </Box>
            <Box className={classes.createLabelField}>
                <Box className={classes.nameField}>
                    <SimpleTextField
                        value={name}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            handleChangeTextField({ name: e.target.value });
                        }}
                        label='ラベル'
                        autoFocus={true}
                    />
                </Box>
                <Box className={classes.descriptionField}>
                    <SimpleTextField
                        value={description}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            handleChangeTextField({ description: e.target.value });
                        }}
                        label='説明'
                    />
                </Box>
                <Box className={classes.buttons}>
                    <Button variant='outlined' color='primary' onClick={handleCreateClose} className={classes.button}>取り消す</Button>
                    <Button variant='contained' color='primary' onClick={handleSave} disabled={IsErrorOnBgColor || !name}
                        className={classes.button}>
                        保存する
                    </Button>
                </Box>
            </Box>
        </>
    );
}

const CreateLabelWrapper = ({ handleCreateClose, handleSave }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.createLabelBox} elevation={0} variant={"outlined"}>
            <CreateLabel handleCreateClose={handleCreateClose} handleSave={handleSave} />
        </Paper>
    );
}

export default CreateLabelWrapper;

const ColorPanel = ({ handleChange }) => {
    const classes = useStyles();
    return (
        <Box className={classes.fixedColors}>
            {fixed_colors.map(color => (
                <Box style={{ backgroundColor: color }} className={classes.fixedColorButton}
                    onClick={() => handleChange(color)} />
            ))}
        </Box>
    )
}

const useStyles = makeStyles({
    createLabelBox: {
        padding: 20,
        backgroundColor: '#f3f7f4',
    },
    createLabelPreview: {
        display: 'flex',
        alignItems: "center"
    },
    labelWrapper: {
        minWidth: 50,
    },
    nameField: {
        width: '200px',
        marginRight: 10,
        maxWidth: 200,
    },
    descriptionField: {
        marginRight: 10,
        flex: 1
    },
    colorField: {
        maxWidth: 100,
        marginLeft: 10,
    },
    buttons: {
        display: 'flex',
        marginLeft: "auto"
    },
    button: {
        marginRight: 10,
        height: 35,
    },
    createLabelField: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
    },
    cachedButton: {
        marginLeft: 10,
    },
    fixedColors: {
        display: 'flex',
        padding: 6,
        backgroundColor: '#fff',
        border: '1px solid #e2e9e5',
        borderRadius: 5,
        marginLeft: 8,
    },
    fixedColorButton: {
        width: 22,
        height: 22,
        margin: '0 2px',
        borderRadius: 6,
        cursor: 'pointer',
    },
})
