import ActionType from './ActionType';
import {
	PressKitModel,
	PressKitRequestModel,
	PressKitListModel,
	PressKitCloneModel,
	PressKitListRequestModel,
	FileUploadRequestModel,
	FileUploadSuccessModel,
	PressKitEditTargetModel,
	PressKitNameEditRequestModel,
	FileNameEditRequestModel,
	DeleteFirestoreFlieRequestModel,
	PressKitFirestoreFileModel,
	SortHistoryContentModel,
	FirestoreFileSortRequestModel,
	SyncFirestoreFilesRequestModel,
	SyncFirestorePressKitRequestModel,
	PressKitFirestoreModel,
	FirestorePressKitSortRequestModel,
	PressKitStatusModel
} from "../../model/PressKitModel";
import { ListModel } from '../../model/ListModel'
import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const getPressKitList = createAsyncAction(
    ActionType.GET_PRESS_KIT_LIST_REQUEST,
    ActionType.GET_PRESS_KIT_LIST_SUCCEEDED,
    ActionType.GET_PRESS_KIT_LIST_FAILED
)<PressKitListRequestModel, ListModel, Error>();

export const getPressKitDetails = createAsyncAction(
	ActionType.GET_PRESS_KIT_DETAILS_REQUEST,
	ActionType.GET_PRESS_KIT_DETAILS_SUCCEEDED,
	ActionType.GET_PRESS_KIT_DETAILS_FAILED
)<PressKitRequestModel, PressKitModel, Error>();

export const postPressKitDetails = createAsyncAction(
	ActionType.POST_PRESS_KIT_DETAILS_REQUEST,
	ActionType.POST_PRESS_KIT_DETAILS_SUCCEEDED,
	ActionType.POST_PRESS_KIT_DETAILS_FAILED
)<void, PressKitRequestModel, Error>();


export const patchPressKitDetails = createAsyncAction(
	ActionType.PATCH_PRESS_KIT_DETAILS_REQUEST,
	ActionType.PATCH_PRESS_KIT_DETAILS_SUCCEEDED,
	ActionType.PATCH_PRESS_KIT_DETAILS_FAILED
)<PressKitRequestModel, PressKitModel, Error>();

export const deletePressKitDetails = createAsyncAction(
    ActionType.DELETE_PRESS_KIT_DETAILS_REQUEST,
    ActionType.DELETE_PRESS_KIT_DETAILS_SUCCEEDED,
    ActionType.DELETE_PRESS_KIT_DETAILS_FAILED
)<PressKitRequestModel, void, Error>();


export const postPressKitClone = createAsyncAction(
    ActionType.POST_PRESS_KIT_CLONE_REQUEST,
    ActionType.POST_PRESS_KIT_CLONE_SUCCEEDED,
    ActionType.POST_PRESS_KIT_CLONE_FAILED
)<PressKitCloneModel, PressKitRequestModel, Error>();

export const patchPressKitArchive = createAsyncAction(
	ActionType.PATCH_PRESS_KIT_ARCHIVE_REQUEST,
	ActionType.PATCH_PRESS_KIT_ARCHIVE_SUCCEEDED,
	ActionType.PATCH_PRESS_KIT_ARCHIVE_FAILED,
)<PressKitRequestModel, void, void>();


export const getPressKitTemplate = createAsyncAction(
	ActionType.GET_PRESS_KIT_TEMPLATE_REQUEST,
	ActionType.GET_PRESS_KIT_TEMPLATE_SUCCEEDED,
	ActionType.GET_PRESS_KIT_TEMPLATE_FAILED
)<string, PressKitModel, Error>();

export const getPressKitTemplateList = createAsyncAction(
	ActionType.GET_PRESS_KIT_TEMPLATE_LIST_REQUEST,
	ActionType.GET_PRESS_KIT_TEMPLATE_LIST_SUCCEEDED,
	ActionType.GET_PRESS_KIT_TEMPLATE_LIST_FAILED
)<void, PressKitListModel[], Error>();

export const patchPressKitName = createAsyncAction(
	ActionType.PATCH_PRESS_KIT_NAME_REQUEST,
	ActionType.PATCH_PRESS_KIT_NAME_SUCCEEDED,
	ActionType.PATCH_PRESS_KIT_NAME_FAILED,
)<void, PressKitListModel[], Error>();


export const setPressKitDetails = createAsyncAction(
	ActionType.SET_PRESS_KIT_DETAILS,
	'',
	'',
)<PressKitModel, void, void>();


export const setPressKitCreateDialog = createAsyncAction(
	ActionType.SET_PRESS_KIT_CREATE_DIALOG,
	'',
	'',
)<boolean, void, void>();

export const setPressKitTemplate = createAsyncAction(
	ActionType.SET_PRESS_KIT_TEMPLATE,
	'',
	'',
)<PressKitModel, void, void>();

export const setPressKitName = createAsyncAction(
	ActionType.SET_PRESS_KIT_NAME,
	'',
	'',
)<PressKitModel, void, void>();

export const setAddPressKitDialog = createAsyncAction(
	ActionType.SET_ADD_PRESS_KIT_DIALOG,
	'',
	'',
)<boolean, void, void>();

export const addPressKit = createAsyncAction(
	ActionType.ADD_PRESS_KIT_REQUEST,
	ActionType.ADD_PRESS_KIT_SUCCEEDED,
	ActionType.ADD_PRESS_KIT_FAILED,
)<string, void, Error>();

export const createGroupIdDoc = createAsyncAction(
	ActionType.CREATE_GROUP_ID_DOC_REQUEST,
	ActionType.CREATE_GROUP_ID_DOC_SUCCEEDED,
	ActionType.CREATE_GROUP_ID_DOC_FAILED,
)<void, void, void>();

export const setPressKitUploadDialog = createAsyncAction(
	ActionType.SET_PRESS_KIT_UPLOAD_DIALOG,
	'',
	'',
)<boolean, void, void>();

export const postPressKitFileUpload = createAsyncAction(
	ActionType.POST_PRESS_KIT_FILE_UPLOAD_REQUEST,
	ActionType.POST_PRESS_KIT_FILE_UPLOAD_SUCCEEDED,
	ActionType.POST_PRESS_KIT_FILE_UPLOAD_FAILED,
)<FileUploadRequestModel, FileUploadSuccessModel, Error>();

export const setSelectedPressKit = createStandardAction(
	ActionType.SET_SELECTED_PRESS_KIT
)<string>()

export const registerFirestore = createAsyncAction(
	ActionType.REGISTER_FIRESTORE_AFTER_UPLOAD_REQUEST,
	ActionType.REGISTER_FIRESTORE_AFTER_UPLOAD_SUCCEEDED,
	ActionType.REGISTER_FIRESTORE_AFTER_UPLOAD_FAILED,
)<any, void, Error>()

export const setPressKitEditTarget = createStandardAction(
	ActionType.SET_PRESS_KIT_EDIT_TARGET
)<PressKitEditTargetModel>()

export const fileNameEdit = createAsyncAction(
	ActionType.PRESS_KIT_FILENAME_EDIT_REQUEST,
	ActionType.PRESS_KIT_FILENAME_EDIT_SUCEEDED,
	ActionType.PRESS_KIT_FILENAME_EDIT_FAILED,
)<FileNameEditRequestModel, void, Error>()

export const pressKitNameEdit = createAsyncAction(
	ActionType.PRESS_KIT_NAME_EDIT_REQUEST,
	ActionType.PRESS_KIT_NAME_EDIT_SUCEEDED,
	ActionType.PRESS_KIT_NAME_EDIT_FAILED,
)<PressKitNameEditRequestModel, void, Error>()

export const deletePressKitUploadFile = createAsyncAction(
	ActionType.DELETE_PRESS_KIT_UPLOAD_FILE_REQUEST,
	ActionType.DELETE_PRESS_KIT_UPLOAD_FILE_SUCCEEDED,
	ActionType.DELETE_PRESS_KIT_UPLOAD_FILE_FAILED,
)<number, void, Error>()

export const deleteFirestoreFile = createAsyncAction(
	ActionType.DELETE_FIRESTORE_FILE_REQUEST,
	ActionType.DELETE_FIRESTORE_FILE_SUCCEEDED,
	ActionType.DELETE_FIRESTORE_FILE_FAILED,
)<DeleteFirestoreFlieRequestModel, void, Error>()

export const setPressKitDeleteDialog = createStandardAction(
	ActionType.SET_PRESS_KIT_DELETE_DIALOG
)<boolean>()

export const setPressKitDeleteTarget = createStandardAction(
	ActionType.SET_PRESS_KIT_DELETE_TARGET
)<string>()

export const logicalDeletePressKit = createAsyncAction(
	ActionType.LOGICAL_DELETE_PRESSKIT_REQUEST,
	ActionType.LOGICAL_DELETE_PRESSKIT_SUCCEEDED,
	ActionType.LOGICAL_DELETE_PRESSKIT_FAILED,
)<string, string, Error>()

export const setFileSorting = createStandardAction(
	ActionType.SET_FILE_SORTING
)<boolean>();

export const setPressKitSorting = createStandardAction(
	ActionType.SET_PRESS_KIT_SORTING
)<boolean>();

export const setViewFile = createStandardAction(
	ActionType.SET_VIEW_FILE
)<Array<PressKitFirestoreFileModel>>();

export const setViewPressKit = createStandardAction(
	ActionType.SET_VIEW_PRESS_KIT
)<Array<PressKitFirestoreModel>>();

export const firestoreFileSort = createAsyncAction(
	ActionType.FIRESTORE_FILE_SORT_REQUEST,
	ActionType.FIRESTORE_FILE_SORT_SUCCEEDED,
	ActionType.FIRESTORE_FILE_SORT_FAILED,
)<FirestoreFileSortRequestModel, void, Error>();

export const firestorePressKitSort = createAsyncAction(
	ActionType.FIRESTORE_PRESS_KIT_SORT_REQUEST,
	ActionType.FIRESTORE_PRESS_KIT_SORT_SUCCEEDED,
	ActionType.FIRESTORE_PRESS_KIT_SORT_FAILED,
)<FirestorePressKitSortRequestModel, void, Error>();

export const appendSortHistory = createStandardAction(
	ActionType.APPEND_SORT_HISTORY
)<SortHistoryContentModel>();

export const clearSortHistory = createStandardAction(
	ActionType.CLEAR_SORT_HISTORY
)<void>();

export const syncFirestoreFiles = createAsyncAction(
	ActionType.SYNC_PRESS_KIT_FIRESTORE_FILES_REQUEST,
	ActionType.SYNC_PRESS_KIT_FIRESTORE_FILES_SUCCEEDED,
	ActionType.SYNC_PRESS_KIT_FIRESTORE_FILES_FAILED,
)<SyncFirestoreFilesRequestModel, void, Error>();

export const setPressKitFirestoreFiles = createStandardAction(
	ActionType.SET_PRESS_KIT_FIRESTORE_FILES
)<Array<PressKitFirestoreFileModel>>();

export const setPressKitFirestore = createStandardAction(
	ActionType.SET_FIRESTORE_PRESS_KIT
)<Array<PressKitFirestoreModel>>();

export const cleanUpSortState = createAsyncAction(
	ActionType.CLEANUP_SORT_STATE_REQUEST,
	ActionType.CLEANUP_SORT_STATE_SUCCEEDED,
	ActionType.CLEANUP_SORT_STATE_FAILED,
)<void, void, Error>();

export const syncFirestorePressKit = createAsyncAction(
	ActionType.SYNC_PRESS_KIT_FIRESTORE_REQUEST,
	ActionType.SYNC_PRESS_KIT_FIRESTORE_SUCCEEDED,
	ActionType.SYNC_PRESS_KIT_FIRESTORE_FAILED,
)<SyncFirestorePressKitRequestModel, void, Error>();

export const getPressKitCurrentStatus = createAsyncAction(
	ActionType.GET_PRESS_KIT_STATUS_REQUEST,
	ActionType.GET_PRESS_KIT_STATUS_SUCCEEDED,
	ActionType.GET_PRESS_KIT_STATUS_FAILED,
)<void, PressKitStatusModel, Error>();
