export const setLockEditingInfo = (page, accessData, pressRelease, me) => {
	let editorUuid: string = "";
	let editorName: string = "";
	let disabledButton: boolean = false;
	let isToolTipOpen: boolean = false;
	let viewerIdList: string[] = [];
	let lastName: string = "";
	let firstName: string = "";
	let editorId: string = "";
	
	if (page === "PAGE_EDIT") {
		if (accessData && accessData.edit && accessData.edit.editor) {
			if (accessData.edit.editor === me.uuid) {
				editorUuid = me.uuid;
				editorName = "";
				disabledButton = false;
				isToolTipOpen = false;
			} else {
				if (accessData.edit.editor === pressRelease.detail.create_user.uuid) {
					lastName = pressRelease.detail.create_user.last_name;
					firstName = pressRelease.detail.create_user.first_name;
					editorId = pressRelease.detail.create_user.uuid;
				} else {
					pressRelease.detail.creators.forEach((creator) => {
						if (accessData.edit.editor === creator.uuid) {
							lastName = creator.last_name;
							firstName = creator.first_name;
							editorId = creator.uuid;
						}
					});
				}
				editorUuid = editorId;
				editorName = lastName + firstName;
				disabledButton = true;
				isToolTipOpen = true;
			}
		} else {
			editorUuid = "";
			editorName = "";
			disabledButton = false;
			isToolTipOpen = false;
		}

		if (accessData && accessData.edit && accessData.edit.users) {
			viewerIdList = viewerIdList.concat(Object.keys(accessData.edit.users));
		} else {
			viewerIdList = [];
		}
	} else if (page === "PAGE_RESERVE") {
		if (accessData && accessData.reserve && accessData.reserve.editor) {
			if (accessData.reserve.editor === me.uuid) {
				editorUuid = me.uuid;
				editorName = "";
				disabledButton = false;
				isToolTipOpen = false;
			} else {
				if (accessData.reserve.editor === pressRelease.detail.create_user.uuid) {
					lastName = pressRelease.detail.create_user.last_name;
					firstName = pressRelease.detail.create_user.first_name;
					editorId = pressRelease.detail.create_user.uuid;
				} else {
					pressRelease.detail.creators.forEach((creator) => {
						if (accessData.reserve.editor === creator.uuid) {
							lastName = creator.last_name;
							firstName = creator.first_name;
							editorId = creator.uuid;
						}
					});
				}
				editorUuid = editorId;
				editorName = lastName + firstName;
				disabledButton = true;
				isToolTipOpen = true;
			}
		} else {
			editorUuid = "";
			editorName = "";
			disabledButton = false;
			isToolTipOpen = false;
		}

		if (accessData && accessData.reserve && accessData.reserve.users) {
			viewerIdList = viewerIdList.concat(Object.keys(accessData.reserve.users));
		} else {
			viewerIdList = [];
		}
	} else {
		viewerIdList = [];
		editorUuid = "";
		editorName = "";
		disabledButton = false;
		isToolTipOpen = false;
	}

	return {
		editorUuid,
		editorName,
		disabledButton,
		isToolTipOpen,
		viewerIdList,
	};
};
