import React from 'react';

//component
import PageTitle from '../PageTitle';
import Title from '../Title';
import SubTitle from '../SubTitle';
import Point from '../Point';

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/send/create/image01.png';
import Image02 from '../../../assets/help/send/create/image02.png';
import Image03 from '../../../assets/help/send/create/image03.png';
import Image04 from '../../../assets/help/send/create/image04.png';
import Image05 from '../../../assets/help/send/create/image05.png';
import Image06 from '../../../assets/help/send/create/image06.png';
import Image07 from '../../../assets/help/send/create/image07.png';
import Image08 from '../../../assets/help/send/create/image08.png';


const Create = () => {
    return (
        <div className={style.box}>
            <PageTitle title={"配信"}/>
            <Point>
                AIがメディアに対してプレスリリースをレコメンドする機能もあり、時間をかけずに配信できます。<br/>
                また、プレスリリースは企業のメールアドレスで配信可能。メディア・記者の目に留まりやすくなり、閲覧可能性がアップします。
            </Point>
            <Title title={"1. 配信設定"}/>
            <SubTitle step={1} title={"必須項目の入力をします。"}/>
            <div className={style.img}>
                <img src={Image01}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                ① 配信時刻を選択
            </Typography>
            <div className={style.img}>
                <img src={Image02}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                ② リリースの種類を選択
            </Typography>
            <div className={style.img}>
                <img src={Image03}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                ③ カテゴリを選択
            </Typography>
            <div className={style.img}>
                <img src={Image04}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                ④ 件名、本文、お問い合わせを入力<br/>
                　 件名、本文に入れるおすすめワードや定型文を準備しております。
            </Typography>
            <div className={style.img}>
                <img src={Image05}/>
            </div>

            <SubTitle step={2} title={"保存する"}/>
            <Typography gutterBottom variant={"body1"}>
                一時保存をすることができます。
            </Typography>
            <div className={style.img}>
                <img src={Image06}/>
            </div>
            <SubTitle step={3} title={"配信予約をする（確認フロー機能OFFの場合）"}/>
            <Typography gutterBottom variant={"body1"}>
                「予約確定する」ボタンを押下すると、配信待ち状態になります。<br/>
                配信時刻になると、配信が開始され、通常は5分程度で配信が完了します。
            </Typography>
            <div className={style.img}>
                <img src={Image07}/>
            </div>
            <SubTitle step={4} title={"確認依頼を出す（確認フロー機能ONの場合）"}/>
            <Typography gutterBottom variant={"body1"}>
                画面右上の「確認依頼を出す」ボタンからメンバーに確認依頼を出すことができます。<br/>
                メンバーはメンバー一覧画面から招待することができます。<br/>
            </Typography>
            <div className={style.img}>
                <img src={Image08}/>
            </div>
        </div>
    );
};

export default Create;
