import React from 'react';
import Title from '../Title'
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/briefing/answer/image01.png';
import Image02 from '../../../assets/help/briefing/answer/image02.png';
import Image03 from '../../../assets/help/briefing/answer/image03.png';


const Answer = () => {
    return (
        <div className={style.box}>
            <Title title={"3. 回答する"}/>
            <Typography gutterBottom variant={"body1"}>
                メニューの「回答リクエスト」から、自分宛に回答依頼が届いているブリーフィングの確認・回答ができます。<br/>
                回答依頼のメールに記載されているURLからでも、回答を行うことができます。
            </Typography>
            <div className={style.img}>
                <img src={Image01}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                画面右上の「下書き保存」ボタンから、回答内容の一時保存ができます。<br/>
                「回答を送信」ボタンで回答を送信します。
            </Typography>
            <div className={style.img}>
                <img src={Image02}/>
            </div>
            <Typography gutterBottom variant={"body1"}>
                回答送信後も、再度回答することができます。<br/>
                回答内容を編集後、画面右上の「回答を更新」ボタンを押下してください。
            </Typography>
            <div className={style.img}>
                <img src={Image03}/>
            </div>
        </div>
    );
};

export default Answer;
