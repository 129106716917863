import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// actions
import * as PressKitActionCreators from "../../../actions/PressKit/ActionCreator";

// component
import { Button, Typography, Divider } from '@material-ui/core/';
import { PressKitListLayout, FilesListLayout } from './ListLayout';
import { SortButton } from '../Button/SortButton';
import { PressKitSortButton } from '../Button/PressKitSortButton';
import PressKitDeleteDialog from '../../PressKit/Create/DeleteDialog';
import PressKitCreateDialog from "../../PressKit/Create/CreateDialog";
import AddPressKitDialog from "../../PressKit/Create/AddPressKitDialog"
import FileUploadDialog from "../../PressKit/Create/FileUploadDialog";

// style
import style from "./press_kit_list.module.scss";

// state
import { RootState } from "../../../reducers";

//img
import IconPressKit from '../.../../../../assets/guide/press_kit.svg'
import HelpFab from "../../Common/HelpFab";
import Title from "../../Common/Title";
import IconAdd from "../../../assets/icon_add.svg";
import IconAddDisabled from "../../../assets/icon_add_disabled.svg";

//model
import { PressKitFirestoreFileModel, PressKitFirestoreModel } from "../../../model/PressKitModel"


const selectedPressKitSelector = (state: RootState) => state.pressKit.add.selectedPressKit;
const groupIdSelector = (state: RootState) => state.firestore.data.groupId;
const companySelector = (state: RootState) => state.company;
const sortingSelector = (state: RootState): boolean => state.pressKit.edit.sorting;
const pressKitSortingSelector = (state: RootState): boolean => state.pressKit.edit.pressKitSorting;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;
const firestoreFilesSelector = (state: RootState): Array<PressKitFirestoreFileModel> => state.pressKit.firestore.files;
const pressKitSelector = (state: RootState) => state.pressKit;

interface IProps {
    archive?: boolean,
}

const PublicPageButton: React.FC = () => {
    const company = useSelector(companySelector);
    const handleClick = () => {
        window.open(`https://pr.harvest.site/press_kit/${company.prefix}`, "_blank")
    }
    return (
        <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleClick}
            disabled={!company.prefix}
        >
            ダウンロードページを見る
        </Button>
    )
}

const Header = ({ handleDialogOpen }) => {
    const company = useSelector(companySelector);

    return (
        <div className={style.header}>
            <Title>
                プレスキット<HelpFab url={'/help/press_kit#1'} />
            </Title>
            <div className={style.buttonContainer}>
                <div className={style.textContainer}>
                    {!company.prefix &&
                        <Typography variant="body2">URLが<NavLink to={`/setting/service/edit/`}
                            className={style.link}>設定</NavLink>されていません。</Typography>
                    }
                </div>
                <PublicPageButton />
            </div>
        </div>
    )
}

const AddPressKitButton: React.FC<any> = ({ handleDialogOpen }) => {
    const fileSorting = useSelector(sortingSelector);
    const pressKitSorting = useSelector(pressKitSortingSelector);
    const sorting = fileSorting || pressKitSorting;
    const addIcon = sorting ? IconAddDisabled : IconAdd;

    return (
        <Button variant="outlined" size="small" color="primary" className={style.button}
            onClick={handleDialogOpen} disabled={sorting}>
            <div className={style.buttonContent}>
                <img src={addIcon} className={style.addButtonIcon} />
                フォルダを追加
            </div>
        </Button>
    )
}

const FileUploadButton: React.FC<any> = ({ handleDialogOpen, isFileEmpty }) => {
    const selectedPressKit = useSelector(selectedPressKitSelector)
    const fileSorting = useSelector(sortingSelector);
    const pressKitSorting = useSelector(pressKitSortingSelector);
    const sorting = fileSorting || pressKitSorting;
    const isNotSelectedPressKit = selectedPressKit ? false : true
    const pressKitList = useSelector(firestorePressKitsSelector);
    const buttonStyle = isFileEmpty ? style.emptyButton : style.button
    const disabled = isNotSelectedPressKit || !pressKitList.length || sorting
    const addIcon = sorting ? IconAddDisabled : IconAdd;
    return (
        <Button
            className={buttonStyle}
            onClick={handleDialogOpen}
            variant="outlined"
            size="small"
            color="primary"
            disabled={disabled}
        >
            <div className={style.buttonContent}>
                <img src={addIcon} className={style.addButtonIcon} />
                ファイルをアップロード
            </div>
        </Button>
    )
}

const MessageIfPressKitIsNotExists = ({ handleDialogOpen, hasFolderLimit, planName }) => {
    return (
        <div className={style.notExistsMessage}>
            {hasFolderLimit ?
                <>
                    <Typography align={"center"} variant={"h4"}>
                        {planName}プランでは、プレスキットを作成できません。<br />
                        作成を行うには、プランのアップグレードが必要です。<br />
                    </Typography>
                    <NavLink exact to={"/setting/plan"}>
                        <Button variant="contained" size="large" color="primary" className={style.button}>
                            プランのアップグレード
                        </Button>
                    </NavLink>
                </>
                :
                <>
                    <img src={IconPressKit} />
                    <Typography align={"center"} variant={"h4"}>プレスキットを作成して、取材用の素材を一つに集約しましょう<br />
                    高画質な画像や動画の受け渡しも簡単になります</Typography>
                    <Button variant="contained" size="large" color="primary" className={style.button}
                        onClick={handleDialogOpen}>
                        プレスキットを作成する
                    </Button>
                </>
            }
        </div>
    )
}

const MessageIfFileIsNotExists = () => {
    return (
        <div className={style.notExistsMessage}>
            <img src={IconPressKit} />
            <Typography align={"center"} variant={"h6"}>ファイルがありません</Typography>
        </div>
    )
}

const PressKitList: React.FC<IProps> = () => {

    const dispatch = useDispatch();
    const groupId = useSelector(groupIdSelector);
    const selectedPressKit = useSelector(selectedPressKitSelector);
    const fileList = useSelector(firestoreFilesSelector);
    const pressKitList = useSelector(firestorePressKitsSelector);
    const company = useSelector(companySelector);
    const currentSelectedPressKit = pressKitList.find(pressKit => pressKit.id == selectedPressKit)
    const pressKitTitle = currentSelectedPressKit ? currentSelectedPressKit.title : ''

    const pressKit = useSelector(pressKitSelector);
    const { totalFolder } = pressKit.status;
    const folderLimit = company.plan.folder_limit;
    const [isLimit, setIsLimit] = useState<boolean>(false);

    const isEmptyFiles = (files: Array<any>) => {
        if (files && files.length > 0) {
            return false
        }
        return true
    }

    const handleFileUploadDialogOpen = () => {
        dispatch(PressKitActionCreators.setPressKitUploadDialog.request(true))
    }

    const handleOpenAddPressKitDialog = () => {
        dispatch(PressKitActionCreators.setAddPressKitDialog.request(true))
    }

    //groupIdのdocがfirestoreに作られていないならば作成する
    //pressKitがselectされていない && プレスキットが存在する -> 初めてプレスキットリストを表示した状態
    //この状態では一番上のプレスキットをselectする
    useEffect(() => {
        if (groupId == null) {
            dispatch(PressKitActionCreators.createGroupIdDoc.request())
        }
        if (selectedPressKit == '' && pressKitList.length > 0) {
            dispatch(PressKitActionCreators.setSelectedPressKit(pressKitList[0].id))
        }
    }, [selectedPressKit, pressKitList.length])

    //cleanup(選択しているプレスキットを解除する)
    useEffect(() => {
        return () => {
            dispatch(PressKitActionCreators.setSelectedPressKit(''))
        }
    }, [])

    //pressKitList.length変更が加わる(追加、削除)度に一番上のプレスキットをselectする
    useEffect(() => {
        if (pressKitList.length > 0) {
            dispatch(PressKitActionCreators.setSelectedPressKit(pressKitList[0].id))
        }
    }, [pressKitList.length])

    // フォルダ数&ファイル数の増減、別企業のプロジェクトへの遷移の度に、現在のプレスキット情報を取得する
    useEffect(() => {
        if (company.uuid) {
            dispatch(PressKitActionCreators.getPressKitCurrentStatus.request());
        }
    }, [pressKitList.length, fileList.length, company.uuid]);

    // プラン毎に制限をかける
    useEffect(() => {
        if (folderLimit) {
            setIsLimit(totalFolder >= folderLimit);
        } else {
            setIsLimit(folderLimit === 0);
        }
    }, [totalFolder, company.uuid]);

    return (
        <section className={style.root}>
            <Header handleDialogOpen={handleFileUploadDialogOpen} />
            <Divider />
            <div className={style.container}>
                {pressKitList.length == 0 &&
                    <MessageIfPressKitIsNotExists handleDialogOpen={handleOpenAddPressKitDialog} hasFolderLimit={isLimit} planName={company.plan.name} />
                }
                {pressKitList.length > 0 &&
                    <div className={style.listContainer}>
                        <div className={style.pressKitListContainer}>
                            <>
                                <Typography className={style.subTitle} variant={"h6"}>
                                    フォルダ
                            <div className={style.buttonContainer}>
                                        {pressKitList.length > 1 && <PressKitSortButton />}
                                        {!isLimit && <AddPressKitButton handleDialogOpen={handleOpenAddPressKitDialog} />}
                                    </div>
                                </Typography>
                                <PressKitListLayout
                                    list={pressKitList}
                                />
                                {isLimit &&
                                    <div className={style.upgradeBox}>
                                        <Typography variant={"body1"}>
                                            作成できるフォルダ数が上限に達しています。<br />
                                    フォルダの追加を行うには、プランのアップグレードが必要です。<br />
                                        </Typography>
                                        <NavLink exact to={"/setting/plan"}>
                                            <Button variant="contained" size="large" color="primary" className={style.upgradeButton}>
                                                プランのアップグレード
                                    </Button>
                                        </NavLink>
                                    </div>
                                }
                            </>
                        </div>
                        <div className={style.fileListContainer}>
                            <Typography className={style.subTitle} variant={"h6"}>
                                ファイル
                        <div className={style.buttonContainer}>
                                    <SortButton />
                                    <FileUploadButton
                                        handleDialogOpen={handleFileUploadDialogOpen}
                                        isFileEmpty={isEmptyFiles(fileList)}
                                    />
                                </div>
                            </Typography>
                            {fileList.length == 0 && selectedPressKit && <MessageIfFileIsNotExists />}
                            {!isEmptyFiles(fileList) && selectedPressKit &&
                                <FilesListLayout
                                    list={fileList}
                                    pressKitTitle={pressKitTitle}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
            <PressKitCreateDialog />
            <AddPressKitDialog />
            <FileUploadDialog />
            <PressKitDeleteDialog />
        </section>
    );
}

export default PressKitList;
