import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import dayjs from 'dayjs';

// component
import {
    List, ListItemText, ListItem, ListItemSecondaryAction,
    Divider, Tooltip, IconButton, Typography, makeStyles, Box
} from '@material-ui/core';
import ListMenu from '../ListMenu';
import AppDialog from '../../../Common/AppDialog';
import NameDialog from '../../Dialog/Name';

// model
import { BriefingDetailModel, PageTypeModel } from '../../../../model/BriefingModel';

// icons
import ImageEllipsis from '../../../../assets/icon_ellipsis.svg';

// actions
import * as BriefingActionCreators from '../../../../actions/Briefing/ActionCreator';

// config
import { BRIEFING_PAGE_TYPES as PAGE_TYPES, BRIEFING_STATUS, createBriefingPath } from '../../../../config/briefing';

// state
import { RootState } from '../../../../reducers'
const groupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;

interface ListLayoutProps {
    list: BriefingDetailModel[],
    pageType: PageTypeModel,
}

const ListLayout: React.FC<ListLayoutProps> = ({ list, pageType }) => {
    return (
        <List component='div'>
            {list.map((item, index) => (
                <ListLayoutItem item={item} key={index} pageType={pageType} />
            ))}
        </List>
    );
}

export default ListLayout;

interface ListLayoutItemProps {
    item: BriefingDetailModel,
    pageType: PageTypeModel,
}

const ListLayoutItem: React.FC<ListLayoutItemProps> = ({ item, pageType }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const group = useSelector(groupSelector);
    const me = useSelector(meSelector);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [nameDialog, setNameDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const handlePostRename = (name: string) => {
        dispatch(BriefingActionCreators.patchBriefingContent.request({
            id: item.id,
            fields: { name: name, queries: item.body.queries },
            success: () => {
                getBriefingList();
                setNameDialog(false);
                handleClose();
            },
        }));
    }

    const getBriefingList = () => {
        dispatch(BriefingActionCreators.getBriefingList.request());
    }

    const handleDelete = () => {
        dispatch(BriefingActionCreators.deleteBriefingContent.request({
            id: item.id,
            success: () => getBriefingList(),
        }));
        handleClose();
    }

    const link = createBriefingPath(group.selectedId, item.id, pageType);

    return (
        <>
            <ListItem button>
                <ListItemText
                    onClick={() => dispatch(push(link))}
                    className={classes.listItemText}
                >
                    <Box className={classes.listItemInner}>
                        <Typography className={classes.text} variant='h6'>{item.body.name}</Typography>
                        <Typography className={classes.text}>{dayjs(item.body.updated_at).format('YYYY/MM/DD HH:mm')}</Typography>
                        <Typography className={classes.text}>{item.body.created_by.last_name} {item.body.created_by.first_name}</Typography>
                        <Typography className={classes.text}>
                            {item.body.status === BRIEFING_STATUS.EDIT && '未公開'}
                            {item.body.status === BRIEFING_STATUS.PUBLIC &&
                                (pageType == PAGE_TYPES.LIST_EDIT ?
                                    `${item.body.answer_list.length} / ${item.body.send_list.length} 件の回答`
                                    :
                                    item.body.answer_list.indexOf(me.email) >= 0 ? "回答済み" : "未回答")}
                        </Typography>
                    </Box>
                </ListItemText>
                <ListItemSecondaryAction>
                    <Tooltip title='メニュー' placement='top' arrow>
                        <IconButton onClick={handleClick}>
                            <img src={ImageEllipsis} />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleRenameDialogOpen={() => setNameDialog(true)}
                handleDeleteDialogOpen={() => setDeleteDialog(true)}
            />
            <NameDialog isOpen={nameDialog} handleClose={() => setNameDialog(false)}
                defaultName={item.body.name} handlePost={handlePostRename} />
            <AppDialog isOpen={deleteDialog}
                closeHandle={() => setDeleteDialog(false)}
                mainHandle={handleDelete} text='削除しますか' ButtonText="削除" />
        </>
    );
}

const useStyles = makeStyles({
    listItemText: {
        padding: '5px 0',
    },
    listItemInner: {
        display: 'flex',
        alignItems: 'center',
        height: 25,
    },
    text: {
        padding: 15,
        width: '25%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
})
