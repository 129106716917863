import React from 'react';
import { PREVIEW_TYPE_DESKTOP, PREVIEW_TYPE_MOBILE, PREVIEW_TYPE_PDF } from "../../../../config/preview_type";
import {useDispatch, useSelector} from "react-redux";

import * as PreviewActionCreators from "../../../../actions/Preview/ActionCreator";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import DockIcon from '@material-ui/icons/Dock';

import { RootState } from "../../../../reducers";
import {makeStyles} from "@material-ui/core/styles";
import PreviewDialog from "../index";
const routerSelector = (state: RootState) => state.router;
const previewSelector = (state: RootState) => state.preview;

interface Props {
    isHistory :boolean,
    page?: string,
}

const PreviewRadios: React.FC<Props> = ({ isHistory, page }) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const router = useSelector(routerSelector);
    const preview = useSelector(previewSelector);

    const handleChange = (event, newAlignment) => {
        if (newAlignment) {
            dispatch(PreviewActionCreators.setPreviewType.request(newAlignment));
        }
    };

    return (
        <div>
            <ToggleButtonGroup
                value={preview}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton value={PREVIEW_TYPE_DESKTOP}>
                    <DesktopMacIcon className={classes.icon}/>
                </ToggleButton>
                <ToggleButton value={PREVIEW_TYPE_MOBILE}>
                    <DockIcon className={classes.icon}/>
                </ToggleButton>
                {(!isHistory && page !== 'PAGE_OGP') &&
                    <ToggleButton value={PREVIEW_TYPE_PDF}>
                      <PictureAsPdfIcon className={classes.icon}/>
                    </ToggleButton>
                }
            </ToggleButtonGroup>
        </div>
    );
}

const useStyles = makeStyles({
    icon: {
        fontSize: 30
    }
});

export default PreviewRadios;
