import { startSubmit, stopSubmit, reset } from 'redux-form'
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchPost, fetchGet } from './fetch';
import { push } from 'connected-react-router'
import store from 'store'
import firebase from "firebase/app";
import 'firebase/auth';

//actions
import SignActionType from '../actions/Sign/ActionType';
import * as ActionCreators from '../actions/Sign/ActionCreator';

function* postSignUp(action: ReturnType<typeof ActionCreators.postSignUp.request>) {
    yield put(startSubmit('SIGN_UP'));
    clearData()
    try {
        const [data, error] = yield call(fetchPost, 'signup', action.payload);
        if (data && !error) {
            yield put(stopSubmit('SIGN_UP'));
            yield put(reset('SIGN_UP'));
            yield put(push('/sign_up/temporary'))
        } else {
            yield put(stopSubmit('SIGN_UP', Object.assign(error, { _error: error.non_field_errors })));
        }
    } catch (e) {
        yield put(ActionCreators.postSignUp.failure(e));
    }
}

function* getSignUpActivate(action: ReturnType<typeof ActionCreators.getSignUpActivate.request>) {
    try {
        const [data, error] = yield call(fetchGet, `activate/${action.payload.id}/`);
        yield put(push('/sign_in'))
    } catch (e) {
        yield put(ActionCreators.getSignUpActivate.failure(e));
    }
}

function* postSignIn(action: ReturnType<typeof ActionCreators.postSignIn.request>) {
    yield put(startSubmit('SIGN_IN'));
    clearData()
    try {
        const [data, error] = yield call(fetchPost, 'api-token-auth', action.payload)
        if (data && !error) {
            store.set('token', data.token)
            store.set('firestore_token', data.firestore_token)
            yield put(stopSubmit('SIGN_IN'));
            yield put(reset('SIGN_IN'));
            const path = store.get('path');
            if (path) {
                yield put(push(path));
                store.remove('path');
            } else {
                yield put(push('/'));
            }
        } else {
            yield put(stopSubmit('SIGN_IN', Object.assign(error, { _error: error.error || error.non_field_errors })));
        }
    } catch (e) {
        yield put(ActionCreators.postSignUp.failure(e));
    }
}

function* firebaseLogin(action: ReturnType<typeof ActionCreators.firebaseLogin.request>) {
    try {
        const token = store.get('firestore_token');
        yield firebase.auth().signInWithCustomToken(token);
        yield put(ActionCreators.firebaseLogin.success());
    } catch (e) {
        yield put(ActionCreators.firebaseLogin.failure(e));
    }
}

function* firebaseLoginAnonymously(_action: ReturnType<typeof ActionCreators.firebaseLoginAnonymously.request>) {
    clearData();
    try {
        yield firebase.auth().signInAnonymously();
        yield call(firebase.auth().onAuthStateChanged(function (user) { }));
        yield put(ActionCreators.firebaseLoginAnonymously.success());
    } catch (e) {
        yield put(ActionCreators.firebaseLoginAnonymously.failure(e));
    }
}

const signSaga = [
    takeLatest(SignActionType.POST_SIGN_UP_REQUEST, postSignUp),
    takeLatest(SignActionType.GET_SIGN_UP_ACTIVATE_REQUEST, getSignUpActivate),
    takeLatest(SignActionType.POST_SIGN_IN_REQUEST, postSignIn),
    takeLatest(SignActionType.FIREBASE_LOGIN_REQUEST, firebaseLogin),
    takeLatest(SignActionType.FIREBASE_LOGIN_ANONYMOUSLY_REQUEST, firebaseLoginAnonymously),
];

const clearData = () => {
    store.remove('token')
    store.remove('groupId')
    store.remove('firestore-token')
}

export default signSaga;
