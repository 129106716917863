import React, { useEffect } from "react";

// component
import CreateUser from "./CreateUser";


// style
import style from "../detail_info.module.scss";

// state
import { RootState } from "../../../../../../reducers";
import AvatarChip from "../../../../../Common/AvatarChip";
import {useDispatch, useSelector} from "react-redux";
import ImageEllipsis from "../../../../../../assets/icon_ellipsis.svg";
import { push } from "connected-react-router";
import _ from "../../Commnet";
import * as ActionCreators from "../../../../../../actions/PressRelease/ActionCreator";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;


const Member: React.FC = () => {

	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<section className={style.item}>
				<p className={style.title}>編集メンバー</p>
				<div className={style.inner}>
					<CreateUser/>
					{/*<div className={style.inner}>*/}
					{/*	<p className={style.subTitle}>ゲストメンバー</p>*/}
					{/*	<Typography variant="caption" gutterBottom>当プレスリリースのみ閲覧可能となります、また承認申請を出すことができます。</Typography>*/}
					{/*	<GuestUser/>*/}
					{/*</div>*/}
				</div>
			</section>
		</>
	);
}


export default Member;
