import React from 'react';
import { useSelector } from 'react-redux';

// style
import style from '../Content/media_select_dialog.module.scss';

// component
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// state
import { RootState } from '../../../reducers';

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const mediaSelector = (state: RootState) => state.media;
const companySelector = (state: RootState) => state.company;
const planSelector = (state: RootState) => state.plan;

const Header = ({ openListNameDialog }) => {
    const classes = useStyles();
    const reserve = useSelector(pressReleaseReserveSelector);
    const media = useSelector(mediaSelector);
    const company = useSelector(companySelector);
    const planInfo = useSelector(planSelector);
    const listId = reserve.data.auto.list_id;
    const favoriteList = media.favoriteList.results.find(i => i.list_id === listId);
    const { plan } = company;
    const limitPlans = [planInfo.free.code];
    const isLimit = limitPlans.includes(plan.code);

    return (
        <div className={style.header}>
            <Typography variant={'h4'} className={classes.listName}>
                {listId === 0 && `AI自動選定のリスト${reserve.data.auto.id_list.length} / ${reserve.data.auto.ai_length}媒体`}
                {listId > 0 && favoriteList && `選択中のリスト・${favoriteList.name} ${favoriteList.id_list.length} / ${favoriteList.length}媒体`}
            </Typography>
            <div className={classes.dialogButtons}>
                {(listId === 0 && !isLimit) && (
                    <Button variant='outlined' color='primary' size='medium' onClick={openListNameDialog} className={classes.button}>
                        名前をつけてリストを保存
                    </Button>)}
            </div>
        </div>
    );
}

export default Header;

const useStyles = makeStyles({
    listName: {
        margin: '12px 15px',
    },
    dialogButtons: {
        marginRight: 10,
    },
    button: {
        margin: 6.5,
    },
})
