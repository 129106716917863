import React from "react";
import {useSelector} from "react-redux";

// component
import AvatarChip from "../../../../../Common/AvatarChip";
import Avatar from "../../../../../Common/Avatar";

import {makeStyles, Typography} from "@material-ui/core/";

// state
import {RootState} from "../../../../../../reducers";

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressGroupSelector = (state: RootState) => state.group;


const Reviewer: React.FC = () => {

    const pressRelease = useSelector(pressReleaseSelector);

    return (
        <div>
            {pressRelease.detail.review.id ?
                <ul>
                    {pressRelease.review.members.map((item, index) => (
                        <Member member={item} key={index}/>
                    ))}
                </ul>
                : ''}
        </div>
    );
};

export default Reviewer;

interface ReviewProps {
    member,
}

const Member: React.FC<ReviewProps> = ({member}) => {

    const pressRelease = useSelector(pressReleaseSelector);
    const group = useSelector(pressGroupSelector);
    const classes = useStyles();

    return (
        <li className={classes.item}>
            {member.status === null &&
                <AvatarChip user={member.user} message={<p>未確認</p>}/>
            }

            {member.status === true &&
                <AvatarChip user={member.user} color={"blue"}/>
            }

            {member.status === false &&
            <>
                {member.history && pressRelease.role.edit?
                    <>
                        <AvatarChip user={member.user} color={"yellow"} updated_at={member.updated_at} message={
                            <a href={`/press_release/${group.selectedId}/diff/${pressRelease.detail.press_id}/${member.history}`}>
                                {member.comment && <Typography variant="body1">{member.comment}</Typography>}
                            </a>
                        }/>

                    </>
                    :
                    <>
                        <AvatarChip user={member.user} color={"yellow"} updated_at={member.updated_at} message={
                            <>
                                {member.comment && <Typography variant="body1">{member.comment}</Typography>}
                            </>
                        }/>
                    </>
                }
            </>
            }
        </li>
    );
};

const useStyles = makeStyles({
    item: {
        marginBottom: 10
    }
});

