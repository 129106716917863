import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

// component
import { Button, Box, makeStyles, Typography, Menu, MenuItem } from "@material-ui/core/";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { FixedPhraseTemplate } from "./template";


const useStyles = makeStyles({
	head: {
		padding: 10,
	},
	root: {
		borderTop: "1px solid #E9EFEB",
		width: 500,
		border: "none",
		borderRadius: 0,
	},
	body: {
		background: "#F8FBF9",
		padding: 10,
	},
	action: {
		display: "flex",
		justifyContent: "flex-end",
		marginBottom: 10
	},
	menuTitle: {
		paddingTop: 0,
		paddingBottom: 0,
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "baseline",
		"&:focus" : {
			outline: "none",
		},
	},
	menuItem: {
		paddingTop: 0,
		paddingBottom: 0,
		whiteSpace: "pre-wrap",
		"&:focus" : {
			outline: "none",
		},
	},
	paper: {
		width: 500,
	},
	explanation: {
		paddingBottom: 10,
		paddingLeft: 5,
	}
});

interface FixedPhraseProps {
	handleClose,
	mailBody: string,
	anchorTempleMenu: null | HTMLElement,
}

const FixedPhrase: React.FC<FixedPhraseProps> = ({handleClose, mailBody, anchorTempleMenu}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState<number | false>(0);
	const [menuList, setMenuList] = React.useState<any>(FixedPhraseTemplate);
	const [menuLeftPosition, setMenuLeftPosition] = React.useState<number>(190);

	useEffect(() => {
		if (Boolean(anchorTempleMenu)) {
			if (anchorTempleMenu) {
				setMenuLeftPosition(anchorTempleMenu.offsetLeft);
			}

			//本文とテンプレートの文章が完全に同じ場合、メニューから非表示にするためのfilter
			const templatelist = FixedPhraseTemplate.filter((template) => template.text.replace(/\s/g,"") !== mailBody.replace(/\s/g,""));
			setMenuList(templatelist);

			setExpanded(false);
		}
	}, [Boolean(anchorTempleMenu)]);

	return (
		<Menu
			elevation={1}
			anchorReference={"anchorPosition"}
			anchorEl={anchorTempleMenu}
			open={Boolean(anchorTempleMenu)}
			onClose={handleClose}
			anchorPosition={{
				left: menuLeftPosition,
				top: 80,
			}}
			PopoverClasses={{
				paper: classes.paper
			}}
		>
			<MenuItem disableGutters classes={{ root: classes.menuTitle }} button={false}>
				<Typography variant={"h5"} className={classes.head}>
					サンプル定型文
				</Typography>
				<Typography variant={"body1"} className={classes.explanation}>
					コピーして、本文に貼り付けてください
				</Typography>
			</MenuItem>
			{menuList.map((item, index) => (
				<div key={index}>
					<MenuItem disableGutters classes={{ root: classes.menuItem }} button={false}>
						<ExpansionPanel
							square
							className={classes.root}
							expanded={index === expanded}
							onChange={()=>setExpanded(index)}
						>
							<ExpansionPanelSummary
								onClick={() => {
									index === expanded && setExpanded(false)
								}}
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography>{item.title}</Typography>
							</ExpansionPanelSummary>
							<Divider/>
							<ExpansionPanelDetails className={classes.body}>
								<Box className={classes.action}>
									<CopyToClipboard text={item.text.replace(/\t/g,"")}>
										<Button variant="outlined" size="small" color="primary" onClick={() => handleClose()}>
											クリップボードにコピー
										</Button>
									</CopyToClipboard>
								</Box>
								<Typography variant={"body1"}>
									{item.text.replace(/\t/g,"")}
								</Typography>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</MenuItem>
				</div>
			))}
		</Menu>
	);
};
export default FixedPhrase;
