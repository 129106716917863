import React, { ReactNode } from "react";

//style
import style from "./panel_layout.module.scss";

// img
import Logo from "../../../assets/logo.svg";
import ImgPr from "../../../assets/img_pr.svg";
import {Box, createStyles, Typography, Link} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export interface Props {
	children: ReactNode,
	bg? :boolean
}

const PanelLayout: React.FC<Props> = ({children, bg}) => {

	const classes = useStyles();

	return (
		<div className={style.root}>
			<div  className={style.inner}>
				<Box className={style.frame}>
					<div className={style.side}>
						<img src={Logo} className={style.logo}/>
						<img src={ImgPr} className={style.mainImg}/>
					</div>
					<div className={bg? style.bg: style.main}>
						<div className={style.content}>
							{children}
						</div>
					</div>
				</Box>
				<ul className={classes.nav}>
					<li>
						<Link href={`https://app.harvest.site/terms`} target={"_blank"} className={classes.link}>
							利用規約
						</Link>
					</li>
					<li>
						<Link href={`https://app.harvest.site/policy`} target={"_blank"} className={classes.link}>
							プライバシーポリシー
						</Link>
					</li>
					<li>
						<Link href={`https://app.harvest.site/stcl`} target={"_blank"} className={classes.link}>
							特定商取引法の表示
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}
export default PanelLayout;


const useStyles = makeStyles(() =>
	createStyles({
		nav: {
			display: "flex",
			justifyContent: "center",
			marginTop: 10
		},
		link: {
			color: "#fff",
			margin: 10
		},
	}),
);

