import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

// component
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";

// state
import { RootState } from "../../../../reducers";
import style from "../HeaderMenu/header_menu.module.scss";

// img
import IconEdit from "../../../../assets/editor/icon_edit.svg";

const pressReleaseSelector = (state: RootState) => state.pressRelease;

interface NameDialogProps {
	isOpen: boolean,
	handleSave,
	handleClose
}


const NameDialog: React.FC<NameDialogProps> = ({ isOpen, handleSave, handleClose }) => {

    const classes = useStyles();
	const pressRelease = useSelector(pressReleaseSelector);
	const [name, setName] = React.useState('');

	useEffect(() => {
		if (isOpen) setName(pressRelease.detail.name)
	}, [isOpen]);

    return (
		<Dialog
			open={isOpen}
			onEscapeKeyDown={handleClose}
		>
			<DialogTitle>
				タイトルを入力してください。<br/>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<TextField multiline
						   placeholder="タイトルを入力"
						   value={name}
						   variant="outlined"
						   autoFocus
						   onChange={(e)=>setName(e.target.value)}
				/>
				<Typography variant={"body1"} className={classes.text}>
					ヘッダーの<img src={IconEdit} className={classes.icon}/>から修正できます。
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" size="large" color="primary" onClick={()=>handleClose()}>
					キャンセル
				</Button>
				<Button variant="contained" size="large" color="primary" onClick={()=>handleSave(name)} disabled={!name}>
					保存
				</Button>
			</DialogActions>
		</Dialog>
    );
}

export default NameDialog


const useStyles = makeStyles({
	content: {
        width: 424,
		padding: 20
    },
	text: {
		marginTop: 10
	},
	icon: {
		margin: "0 10px"
	}
});



