import BriefingActionType from '../actions/Briefing/ActionType';
import BriefingAction from '../actions/Briefing/Action';

import createReducer from './createReducer';
import { combineReducers } from 'redux';
import { BriefingAnswerInfoModel, BriefingDetailModel, BriefingPublicInfoModel, BriefingQtyModel } from '../model/BriefingModel';

const initialBriefingDetail: BriefingDetailModel = {
    body: {
        name: '',
        status: 0,
        updated_at: null,
        updated_by: [],
        created_at: null,
        created_by: {
            first_name: '',
            last_name: '',
            uuid: '',
        },
        queries: [],
        send_list: [],
        answer_list: [],
        groupId: '',
        answer_count: 0,
    },
    id: '',
    fetched: false,
}

const detail = createReducer(initialBriefingDetail, {
    // いずれかのリストのページを開いたときに初期化
    [BriefingActionType.GET_BRIEFING_LIST_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return initialBriefingDetail
    },
    [BriefingActionType.GET_BRIEFING_LIST_RECEIVED_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return initialBriefingDetail
    },

    [BriefingActionType.GET_BRIEFING_DETAIL_REQUEST](state: BriefingDetailModel, action: BriefingAction) {
        return {
            ...state,
            fetched: false,
        }
    },
    [BriefingActionType.GET_BRIEFING_DETAIL_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        const request: any = action.payload;
        return {
            ...state,
            ...request,
            fetched: true,
        }
    },
    [BriefingActionType.SET_BRIEFING_TEMPLATE_REQUEST](state: BriefingDetailModel, action: BriefingAction) {
        const request: any = action.payload;
        return {
            ...state,
            ...request,
            fetched: true,
        }
    },
    [BriefingActionType.SET_BRIEFING_QUERIES](state: BriefingDetailModel, action: BriefingAction) {
        const request: any = action.payload;
        return {
            ...state,
            body: {
                ...state.body,
                queries: request,
            },
        }
    },
});

const initialBriefingPublic: BriefingPublicInfoModel = {
    fetched: false,
    answers: [],
}

const publicInfo = createReducer(initialBriefingPublic, {
    // いずれかのリストのページを開いたときに初期化
    [BriefingActionType.GET_BRIEFING_LIST_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return initialBriefingPublic
    },
    [BriefingActionType.GET_BRIEFING_LIST_RECEIVED_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return initialBriefingPublic
    },

    [BriefingActionType.GET_BRIEFING_PUBLIC_INFO_REQUEST](state: BriefingPublicInfoModel, action: BriefingAction) {
        return {
            ...state,
            fetched: false,
        }
    },
    [BriefingActionType.GET_BRIEFING_PUBLIC_INFO_SUCCEEDED](state: BriefingPublicInfoModel, action: BriefingAction) {
        const request: any = action.payload;
        return {
            fetched: true,
            answers: request.answers,
        }
    },
})

const isSorting = createReducer(false, {
    // いずれかのリストのページを開いたときに初期化
    [BriefingActionType.GET_BRIEFING_LIST_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return false
    },
    [BriefingActionType.GET_BRIEFING_LIST_RECEIVED_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return false
    },

    [BriefingActionType.SET_BRIEFING_SORTING](state: boolean, action: BriefingAction) {
        return action.payload
    },
})

const initialQtyState: BriefingQtyModel = {
    fetched: false,
    mail_count: 0,
    last_send: null,
}

const qty = createReducer(initialQtyState, {
    [BriefingActionType.GET_BRIEFING_QTY_REQUEST](state: BriefingQtyModel, action: BriefingAction) {
        return {
            ...state,
            fetched: false,
        }
    },
    [BriefingActionType.GET_BRIEFING_QTY_SUCCEEDED](state: BriefingQtyModel, action: BriefingAction) {
        const request: any = action.payload;
        return {
            fetched: true,
            ...request,
        }
    },
})

const email = createReducer("", {
    [BriefingActionType.POST_BRIEFING_ANSWER_CONFIRM_REQUEST](state: string, action: BriefingAction) {
        return "";
    },
    [BriefingActionType.POST_BRIEFING_ANSWER_CONFIRM_SUCCEEDED](state: string, action: BriefingAction) {
        return action.payload;
    },
})

const initialAnswerInfoState: BriefingAnswerInfoModel = {
    fetched: false,
    id: "",
    body: {
        is_temp: true,
        queries: [],
        created_by: "",
        updated_by: [],
        updated_at: null,
    }
}

const answerInfo = createReducer(initialAnswerInfoState, {
    [BriefingActionType.GET_BRIEFING_LIST_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return initialAnswerInfoState
    },
    [BriefingActionType.GET_BRIEFING_LIST_RECEIVED_SUCCEEDED](state: BriefingDetailModel, action: BriefingAction) {
        return initialAnswerInfoState
    },

    [BriefingActionType.GET_BRIEFING_ANSWER_INFO_REQUEST](state: BriefingPublicInfoModel, action: BriefingAction) {
        return {
            ...state,
            fetched: false,
        }
    },
    [BriefingActionType.GET_BRIEFING_ANSWER_INFO_SUCCEEDED](state: BriefingPublicInfoModel, action: BriefingAction) {
        const request: any = action.payload;
        return {
            fetched: true,
            ...request,
        }
    },

})

export const briefing = combineReducers({
    detail,
    publicInfo,
    answerInfo,
    isSorting,
    qty,
    email,
});
