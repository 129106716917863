import React from 'react';
import Title from '../Title';
import PageTitle from "../PageTitle";
import Point from "../Point";
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//img
import Image01 from '../../../assets/help/media/companyMediaList/image01.png';
import Image02 from '../../../assets/help/media/companyMediaList/image02.png';
import Image03 from '../../../assets/help/media/companyMediaList/image03.png';


const CompanyMediaList = () => {
	return (
		<div className={style.box}>
			<PageTitle title={"メディア管理"}/>
			<Point>
				メディアリストを登録すると、プレスリリースの配信をHARVEST上から行えます。<br/>
				HARVESTから配信すると、メールの開封確認ができます。<br/>
			</Point>
			<Title title={"1.企業メディアリスト"}/>
			<Typography gutterBottom variant={"body1"}>
				企業毎に登録・管理ができます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				企業メディアリストを登録すると、プレスリリースの配信設定時に、メールの作成が企業毎に行えます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
		</div>
	);
};

export default CompanyMediaList;

