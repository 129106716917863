import * as ActionCreators from "../actions/PaymentHistory/ActionCreator";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {selectGroup} from "./selector";
import {fetchGet} from "./fetch";
import PaymentHistoryActionType from "../actions/PaymentHistory/ActionType";
import { PaymentHistoryRequestModel } from "../model/PaymentModel";


function* getPaymentHistory(action: ReturnType<typeof ActionCreators.getPaymentHistory.request>) {
    try {
        const group = yield select(selectGroup);
        const request:PaymentHistoryRequestModel = action.payload;
		const offset = request.offset <= 1 ? 0 : (request.offset * 10) - 10
        const [data, error] = yield call(fetchGet, `gmo/${group.selectedId}/auto_sales/history/?offset=${offset}&limit=${request.limit || 10}`);
        yield put(ActionCreators.getPaymentHistory.success(Object.assign({}, {offset: request.offset}, data)));

    } catch (e) {
        yield put(ActionCreators.getPaymentHistory.failure(e))

    }

}

const PaymentHistory = [
    takeLatest(PaymentHistoryActionType.GET_PAYMENT_HISTORY_REQUEST, getPaymentHistory)
]

export default PaymentHistory;
