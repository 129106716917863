export let API_HOST = "non-select";

switch (process.env.REACT_APP_TARGET_ENV) {
  case "local":
    API_HOST = "localhost:8000";
    break;
  case "development":
  case "staging":
    API_HOST = "stg.app.harvest.site";
    break;
  case "production":
    API_HOST = "app.harvest.site";
    break;
  case "tyrant":
    API_HOST = "tyrant.stg.app.harvest.site";
    break;
  case "island":
    API_HOST = "island.stg.app.harvest.site";
    break;
  default:
    API_HOST = "stg.app.harvest.site";
}

export const API_URL = "//" + API_HOST + "/api/";
