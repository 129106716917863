import React from 'react';
import Typography from "@material-ui/core/Typography";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const HelpSubTitle = ({step, title}) => {

	const classes = useStyles();

	return (
		<>
			<Typography className={classes.title}>
				<span className={classes.step}>step{step}</span>{title}
			</Typography>
		</>
	);
};

export default HelpSubTitle;

const useStyles = makeStyles(() =>
	createStyles({
		step: {
			color: "#f5f5f5",
			fontSize: 14,
			fontWeight: "bold",
			background: "#6d7470",
			padding: 5,
			marginRight: 10,
			marginBottom: 10
},
		title: {
			marginTop: 20,
			fontSize: 16,
			paddingBottom: 10,
		},
	}),
);
