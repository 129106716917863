enum PressKitActionType {
	GET_PRESS_KIT_LIST_REQUEST = "GET_PRESS_KIT_LIST_REQUEST",
	GET_PRESS_KIT_LIST_SUCCEEDED = "GET_PRESS_KIT_LIST_SUCCEEDED",
	GET_PRESS_KIT_LIST_FAILED = "GET_PRESS_KIT_LIST_FAILED",

	GET_PRESS_KIT_DETAILS_REQUEST = "GET_PRESS_KIT_DETAILS_REQUEST",
	GET_PRESS_KIT_DETAILS_SUCCEEDED = "GET_PRESS_KIT_DETAILS_SUCCEEDED",
	GET_PRESS_KIT_DETAILS_FAILED = "GET_PRESS_KIT_DETAILS_FAILED",

	POST_PRESS_KIT_DETAILS_REQUEST = "POST_PRESS_KIT_DETAILS_REQUEST",
	POST_PRESS_KIT_DETAILS_SUCCEEDED = "POST_PRESS_KIT_DETAILS_SUCCEEDED",
	POST_PRESS_KIT_DETAILS_FAILED = "POST_PRESS_KIT_DETAILS_FAILED",

	PATCH_PRESS_KIT_DETAILS_REQUEST = "PATCH_PRESS_KIT_DETAILS_REQUEST",
	PATCH_PRESS_KIT_DETAILS_SUCCEEDED = "PATCH_PRESS_KIT_DETAILS_SUCCEEDED",
	PATCH_PRESS_KIT_DETAILS_FAILED = "PATCH_PRESS_KIT_DETAILS_FAILED",

	DELETE_PRESS_KIT_DETAILS_REQUEST = "DELETE_PRESS_KIT_DETAILS_REQUEST",
	DELETE_PRESS_KIT_DETAILS_SUCCEEDED = "DELETE_PRESS_KIT_DETAILS_SUCCEEDED",
	DELETE_PRESS_KIT_DETAILS_FAILED = "DELETE_PRESS_KIT_DETAILS_FAILED",

	SET_PRESS_KIT_DETAILS = "SET_PRESS_KIT_DETAILS",

	SET_PRESS_KIT_NAME = "SET_PRESS_KIT_NAME",

	PATCH_PRESS_KIT_NAME_REQUEST = "PATCH_PRESS_KIT_NAME_REQUEST",
	PATCH_PRESS_KIT_NAME_SUCCEEDED = "PATCH_PRESS_KIT_NAME_SUCCEEDED",
	PATCH_PRESS_KIT_NAME_FAILED = "PATCH_PRESS_KIT_NAME_FAILED",

	POST_PRESS_KIT_CLONE_REQUEST = "POST_PRESS_KIT_CLONE_REQUEST",
	POST_PRESS_KIT_CLONE_SUCCEEDED = "POST_PRESS_KIT_CLONE_SUCCEEDED",
	POST_PRESS_KIT_CLONE_FAILED = "POST_PRESS_KIT_CLONE_FAILED",

	PATCH_PRESS_KIT_ARCHIVE_REQUEST = "PATCH_PRESS_KIT_ARCHIVE_REQUEST",
	PATCH_PRESS_KIT_ARCHIVE_SUCCEEDED = "PATCH_PRESS_KIT_ARCHIVE_SUCCEEDED",
	PATCH_PRESS_KIT_ARCHIVE_FAILED = "PATCH_PRESS_KIT_ARCHIVE_FAILED",

	SET_PRESS_KIT_CREATE_DIALOG = "SET_PRESS_KIT_CREATE_DIALOG",
	SET_PRESS_KIT_TEMPLATE = "SET_PRESS_KIT_TEMPLATE",
	SET_ADD_PRESS_KIT_DIALOG = "SET_ADD_PRESS_KIT_DIALOG",

	GET_PRESS_KIT_TEMPLATE_REQUEST = "GET_PRESS_KIT_TEMPLATE_REQUEST",
	GET_PRESS_KIT_TEMPLATE_SUCCEEDED = "GET_PRESS_KIT_TEMPLATE_SUCCEEDED",
	GET_PRESS_KIT_TEMPLATE_FAILED = "GET_PRESS_KIT_TEMPLATE_FAILED",

	GET_PRESS_KIT_TEMPLATE_LIST_REQUEST = "GET_PRESS_KIT_TEMPLATE_LIST_REQUEST",
	GET_PRESS_KIT_TEMPLATE_LIST_SUCCEEDED = "GET_PRESS_KIT_TEMPLATE_LIST_SUCCEEDED",
	GET_PRESS_KIT_TEMPLATE_LIST_FAILED = "GET_PRESS_KIT_TEMPLATE_LIST_FAILED",

	ADD_PRESS_KIT_REQUEST = 'ADD_PRESS_KIT_REQUEST',
	ADD_PRESS_KIT_SUCCEEDED = 'ADD_PRESS_KIT_SUCCEEDED',
	ADD_PRESS_KIT_FAILED = 'ADD_PRESS_KIT_FAILED',

	CREATE_GROUP_ID_DOC_REQUEST = 'CREATE_ID_DOC_REQUEST',
	CREATE_GROUP_ID_DOC_SUCCEEDED = 'CREATE_ID_DOC_SUCCEEDED',
	CREATE_GROUP_ID_DOC_FAILED = 'CREATE_ID_DOC_FAILED',

	SET_PRESS_KIT_UPLOAD_DIALOG = 'SET_PRESS_KIT_UPLOAD_DIALOG',

	POST_PRESS_KIT_FILE_UPLOAD_REQUEST = 'POST_PRESS_KIT_FILE_UPLOAD_REQUEST',
	POST_PRESS_KIT_FILE_UPLOAD_SUCCEEDED = 'POST_PRESS_KIT_FILE_UPLOAD_SUCCEEDED',
	POST_PRESS_KIT_FILE_UPLOAD_FAILED = 'POST_PRESS_KIT_FILE_UPLOAD_FAILED',

	SET_SELECTED_PRESS_KIT = 'SET_SELECTED_PRESS_KIT',

	REGISTER_FIRESTORE_AFTER_UPLOAD_REQUEST = 'REGISTER_FIRESTORE_AFTER_UPLOAD_REQUEST',
	REGISTER_FIRESTORE_AFTER_UPLOAD_SUCCEEDED = 'REGISTER_FIRESTORE_AFTER_UPLOAD_SUCCEEDED',
	REGISTER_FIRESTORE_AFTER_UPLOAD_FAILED = 'REGISTER_FIRESTORE_AFTER_UPLOAD_FAILED',

	SET_PRESS_KIT_EDIT_TARGET = 'SET_PRESS_KIT_EDIT_TARGET',

	PRESS_KIT_FILENAME_EDIT_REQUEST = 'PRESS_KIT_FILENAME_EDIT_REQUEST',
	PRESS_KIT_FILENAME_EDIT_SUCEEDED = 'PRESS_KIT_FILENAME_EDIT_SUCCEEDED',
	PRESS_KIT_FILENAME_EDIT_FAILED = 'PRESS_KIT_FILENAME_EDIT_FAILED',

	PRESS_KIT_NAME_EDIT_REQUEST = 'PRESS_KIT_NAME_EDIT_REQUEST',
	PRESS_KIT_NAME_EDIT_SUCEEDED = 'PRESS_KIT_NAME_EDIT_SUCCEEDED',
	PRESS_KIT_NAME_EDIT_FAILED = 'PRESS_KIT_NAME_EDIT_FAILED',

	DELETE_PRESS_KIT_UPLOAD_FILE_REQUEST = 'DELETE_PRESS_KIT_UPLOAD_FILE_REQUEST',
	DELETE_PRESS_KIT_UPLOAD_FILE_SUCCEEDED = 'DELETE_PRESS_KIT_UPLOAD_FILE_SUCCEEDED',
	DELETE_PRESS_KIT_UPLOAD_FILE_FAILED = 'DELETE_PRESS_KIT_UPLOAD_FILE_FAILED',

	DELETE_FIRESTORE_FILE_REQUEST = 'DELETE_FIRESTORE_FILE_REQUEST',
	DELETE_FIRESTORE_FILE_SUCCEEDED = 'DELETE_FIRESTORE_FILE_SUCCEEDED',
	DELETE_FIRESTORE_FILE_FAILED = 'DELETE_FIRESTORE_FILE_FAILED',

	SET_PRESS_KIT_DELETE_DIALOG = 'SET_PRESS_KIT_DELETE_DIALOG',

	SET_PRESS_KIT_DELETE_TARGET = 'SET_PRESS_KIT_DELETE_TARGET',

	LOGICAL_DELETE_PRESSKIT_REQUEST = 'LOGICAL_DELETE_PRESSKIT_REQUEST',
	LOGICAL_DELETE_PRESSKIT_SUCCEEDED = 'LOGICAL_DELETE_PRESSKIT_SUCCEEDED',
	LOGICAL_DELETE_PRESSKIT_FAILED = 'LOGICAL_DELETE_PRESSKIT_FAILED',

	SET_FILE_SORTING = 'SET_FILE_SORTING',

	SET_PRESS_KIT_SORTING = 'SET_PRESS_KIT_SORTING',

	SET_VIEW_FILE = 'SET_VIEW_FILE',

	SET_VIEW_PRESS_KIT = 'SET_VIEW_PRESS_KIT',

	FIRESTORE_FILE_SORT_REQUEST = 'PRESS_KIT_FIRESTORE_REQUEST',
	FIRESTORE_FILE_SORT_SUCCEEDED = 'PRESS_KIT_FIRESTORE_SUCCEEDED',
	FIRESTORE_FILE_SORT_FAILED= 'PRESS_KIT_FIRESTORE_FAILED',

	FIRESTORE_PRESS_KIT_SORT_REQUEST = 'FIRESTORE_PRESS_KIT_SORT_REQUEST',
	FIRESTORE_PRESS_KIT_SORT_SUCCEEDED = 'FIRESTORE_PRESS_KIT_SORT_SUCCEEDED',
	FIRESTORE_PRESS_KIT_SORT_FAILED = 'FIRESTORE_PRESS_KIT_SORT_FAILED',

	APPEND_SORT_HISTORY = 'APPEND_SORT_HISTORY',
	CLEAR_SORT_HISTORY = 'CLEAR_SORT_HISTORY',

	SET_PRESS_KIT_FIRESTORE_FILES = 'SET_PRESS_KIT_FIRESTORE_FILES',

	SET_FIRESTORE_PRESS_KIT = 'SET_FIRESTORE_PRESS_KIT',

	SYNC_PRESS_KIT_FIRESTORE_FILES_REQUEST = 'SYNC_PRESS_KIT_FIRESTORE_FILES_REQUEST',
	SYNC_PRESS_KIT_FIRESTORE_FILES_SUCCEEDED = 'SYNC_PRESS_KIT_FIRESTORE_FILES_SUCCEEDED',
	SYNC_PRESS_KIT_FIRESTORE_FILES_FAILED = 'SYNC_PRESS_KIT_FIRESTORE_FILES_FAILED',

	CLEANUP_SORT_STATE_REQUEST = 'CLEANUP_SORT_STATE_REQUEST',
	CLEANUP_SORT_STATE_SUCCEEDED = 'CLEANUP_SORT_STATE_SUCCEEDED',
	CLEANUP_SORT_STATE_FAILED = 'CLEANUP_SORT_STATE_FAILED',

	SYNC_PRESS_KIT_FIRESTORE_REQUEST = 'SYNC_PRESS_KIT_FIRESTORE_REQUEST',
	SYNC_PRESS_KIT_FIRESTORE_SUCCEEDED = 'SYNC_PRESS_KIT_FIRESTORE_SUCCEEDED',
	SYNC_PRESS_KIT_FIRESTORE_FAILED = 'SYNC_PRESS_KIT_FIRESTORE_FAILED',

	GET_PRESS_KIT_STATUS_REQUEST = 'GET_PRESS_KIT_STATUS_REQUEST',
	GET_PRESS_KIT_STATUS_SUCCEEDED = 'GET_PRESS_KIT_STATUS_SUCCEEDED',
	GET_PRESS_KIT_STATUS_FAILED = 'GET_PRESS_KIT_STATUS_FAILED',
}

export default PressKitActionType
