import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

// components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

// state
import { RootState } from "../../../reducers";
const companySelector = (state: RootState) => state.company;


const OverMember: React.FC = () => {
    const classes = useStyles();
    const { total_member, option, plan } = useSelector(companySelector);

    return (
        <>
            <Typography variant="h4" component="h4" gutterBottom>
                登録しているメンバー数が上限を超過しています
            </Typography>
            <Typography variant="h5" component="h5">
                登録可能なメンバー数： {plan.member_limit + option.member.quantity}<br />
                現在登録しているメンバー数： {total_member}
            </Typography>
            <Typography variant="body1" component="p" className={classes.notes}>
                ※「現在登録しているメンバー数」は、企業が保有している全てのプロジェクトのメンバーの総数です。<br />
                プロジェクト毎のメンバー数ではありません。
            </Typography>
            <Typography variant="h5" component="h5" gutterBottom>
                利用するには、<br />
                ・プランのアップグレード<br />
                ・オプションの追加(メンバー枠の追加)<br />
                ・メンバーの削除<br />
                上記のいずれかを行う必要があります。
            </Typography>

            <div className={classes.buttonBox}>
                <NavLink exact to={"/setting/plan/"} className={classes.button}>
                    <Button variant="contained" size="large" color="primary">
                        プラン・オプションのアップグレード
                    </Button>
                </NavLink>
                <NavLink exact to={"/member/"}>
                    <Button variant="outlined" size="large" color="primary">
                        メンバー管理画面へ
                    </Button>
                </NavLink>
            </div>
        </>
	);
}

export default OverMember;

const useStyles = makeStyles({
    notes: {
        margin: "10px 0 35px",
    },
    buttonBox: {
        display: "flex",
    },
    button: {
        marginRight: 15,
    },
});
