import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// component
import {
  InputLabel,
  Button,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Select,
  FormControl,
  Paper,
  Box,
  Divider
} from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import PreviewDialog from '../PreviewDialog';
import TestSendDialog from '../TestSendDialog';
import FixedPhrase from '../FixedPhrase';
import PressKit from './PressKit';
import File from './File';

// style
import style from './mail.module.scss';

// actions
import * as ActionCreators from '../../../../../actions/PressReleaseReserve/ActionCreator';
import * as MemberActionCreators from '../../../../../actions/Member/ActionCreator';

// state
import { RootState } from '../../../../../reducers';
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) =>
  state.pressReleaseReserve;
const companySelector = (state: RootState) => state.company;
const memberSelector = (state: RootState) => state.member;
const mediaSelector = (state: RootState) => state.media;
const pressGroupSelector = (state: RootState) => state.group;
const meSelector = (state: RootState) => state.me;

interface ContentsProps {
  handleOpenMediaDialog;
  isLocked: boolean;
}

const useStyles = makeStyles({
  address: {
    lineHeight: '30px'
  },
  headerAction: {
    margin: '5px 0 0 auto'
  },
  action: {
    marginLeft: 10
  },
  block: {
    width: '100%',
    display: 'flex'
  },
  mediaCount: {
    fontSize: 28,
    display: 'inline-block',
    marginLeft: 20
  },
  text: {
    padding: 10,
    minHeight: 200
  },
  textareaField: {
    paddingTop: 20,
    minHeight: 200
  },
  panelRoot: {
    boxShadow: 'none',
    border: '1px solid #e2e9e5'
  },
  contactTextareaField: {
    minHeight: 100
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  greeting: {
    padding: '0px 10px;'
  },
  sampleButton: {
    marginLeft: 'auto',
    marginBottom: 10
  },
  fileBox: {
    width: '100%'
  }
});

const Main: React.FC<ContentsProps> = ({ handleOpenMediaDialog, isLocked }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pressRelease = useSelector(pressReleaseSelector);
  const reserve = useSelector(pressReleaseReserveSelector);
  const company = useSelector(companySelector);
  const member = useSelector(memberSelector);
  const media = useSelector(mediaSelector);
  const group = useSelector(pressGroupSelector);
  const { last_name, first_name, email } = useSelector(meSelector);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuTarget, setMenuTarget] = React.useState<null | string>(
    'from_email'
  );
  const [
    anchorTempleMenu,
    setAnchorTempleMenu
  ] = React.useState<null | HTMLElement>(null);
  const [mediaSelect, setMediaSelectDialog] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [testSend, setTestSend] = React.useState<boolean>(false);

  const active: number = reserve.active;
  const isAutoMediaActive = active === 0 ? true : false;
  const isAutoMediaChecked = reserve.data.auto.is_send === true;
  const testSendButtonEnable =
    isAutoMediaActive && isAutoMediaChecked && !isLocked;

  useEffect(() => {
    // // setCategoryDialog(true)
    // //　初期値
    if (reserve.data.fetched === true && company.email) {
      if (!reserve.data.auto.from_email) {
        dispatch(
          ActionCreators.setPressReleaseReserveAuto.request({
            from_email: company.email
          })
        );
      }
    }
  }, [reserve.data.fetched, company]);

  useEffect(() => {
    if (group.selectedId)
      dispatch(MemberActionCreators.getMemberList.request({ offset: 0 }));
  }, [group.selectedId]);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    target: string
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuTarget(target);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenTemplateMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    handleCloseMenu();
    setAnchorTempleMenu(event.currentTarget);
  };

  const handleEmailChange = event => {
    const target: any = event.target;
    dispatch(
      ActionCreators.setPressReleaseReserveAuto.request({
        [target.name]: target.value
      })
    );
  };

  const handleAutoInputContact = () => {
    const contact = `本リリースに関する報道関係のお問い合わせ
    ${company.name}　広報担当： ${last_name + ' ' + first_name}
    TEL： ${company.tel}
    MAIL： ${email}`.replace(/ {2,}/g, '');

    dispatch(
      ActionCreators.setPressReleaseReserveAuto.request({
        mail_contact: contact
      })
    );
  };

  const hasEdit = pressRelease.role.edit;
  const { isReserveEdit, status } = pressRelease.detail;
  const listId = reserve.data.auto.list_id;
  const favoriteList = media.favoriteList.results;
  const mediaList =
    listId === 0
      ? reserve.data.auto.id_list
      : (favoriteList.length > 0 &&
        favoriteList.find(i => i.list_id === listId).id_list) ||
      [];

  const buttonDisabled =
    !hasEdit ||
    !isReserveEdit ||
    isLocked ||
    reserve.data.categories.length === 0 ||
    !reserve.data.type ||
    reserve.data.category_details.length === 0;

  return (
    <div className={style.root}>
      <Paper classes={{ root: classes.panelRoot }}>
        <ul className={style.form}>
          <li>
            <InputLabel>宛先</InputLabel>
            <Box className={classes.block}>
              <Typography variant="h5" className={classes.address}>
                配信メディアリスト
                <span className={classes.mediaCount}>{mediaList.length}</span>
                媒体
              </Typography>
              <Box className={classes.headerAction}>
                {(status === 3 || status === 6) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size={'small'}
                    disabled={buttonDisabled}
                    onClick={handleOpenMediaDialog}
                  >
                    配信先を変更する
                  </Button>
                )}
                {(status === 5 || status >= 7) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size={'small'}
                    onClick={handleOpenMediaDialog}
                  >
                    配信先を確認する
                  </Button>
                )}
                <Button
                  className={classes.action}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => setTestSend(true)}
                  disabled={!testSendButtonEnable}
                >
                  テスト配信
                </Button>
                <Button
                  className={classes.action}
                  size={'small'}
                  variant="outlined"
                  color="primary"
                  onClick={() => setPreview(true)}
                >
                  プレビュー
                </Button>
              </Box>
            </Box>
          </li>
          <li>
            <InputLabel>送信元 </InputLabel>
            <FormControl variant="outlined" className={classes.block}>
              <Select
                value={reserve.data.auto.from_email || company.email}
                onChange={handleEmailChange}
                name="from_email"
                disabled={!hasEdit || !isReserveEdit || isLocked}
              >
                <MenuItem value={company.email}>
                  {company.email}
                  <span className={style.name}>企業アドレス</span>
                </MenuItem>
                {member.list.results.map((user, key) => (
                  <MenuItem value={user.email} key={key}>
                    {user.email}
                    <span className={style.name}>
                      {user.last_name}
                      {user.first_name}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </li>
          <li>
            <InputLabel>件名</InputLabel>
            <TextField
              multiline
              variant="outlined"
              type="textarea"
              name="mail_title"
              placeholder="〇〇のお知らせ"
              onChange={handleEmailChange}
              value={reserve.data.auto.mail_title || ''}
              helperText={
                reserve.data.auto.mail_title ? '' : '入力してください'
              }
              disabled={!hasEdit || !isReserveEdit || isLocked}
            />
          </li>
          <li>
            <Box className={classes.labelContainer}>
              <InputLabel>本文</InputLabel>
              <Button
                variant="outlined"
                color="primary"
                size={'small'}
                onClick={handleOpenTemplateMenu}
                className={classes.sampleButton}
              >
                定型文から入力
              </Button>
            </Box>
            <div className={style.main}>
              <Typography variant={'body1'} className={classes.greeting}>
                〇〇株式会社
                <br />
                メディア名
                <br />
                ご担当者様　(自動挿入)
              </Typography>
              <div>
                <TextField
                  multiline
                  className={classes.text}
                  type="textarea"
                  name="mail_body"
                  placeholder="お世話になっております。&#13;&#10;株式会社〇〇の〇〇と申します。&#13;&#10;リリース内容の概要.."
                  onChange={handleEmailChange}
                  value={reserve.data.auto.mail_body || ''}
                  InputProps={{
                    classes: {
                      input: classes.textareaField
                    }
                  }}
                  helperText={
                    reserve.data.auto.mail_body ? '' : '入力してください'
                  }
                  disabled={!hasEdit || !isReserveEdit || isLocked}
                />
                <PressKit
                  date={reserve.data.released_at}
                  prefix={company.prefix}
                />
              </div>
            </div>
          </li>
          <li>
            <Box className={classes.labelContainer}>
              <InputLabel>お問い合わせ</InputLabel>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleAutoInputContact}
                className={classes.sampleButton}
                disabled={!hasEdit || !isReserveEdit || isLocked}
              >
                自動で入力
              </Button>
            </Box>
            <TextField
              multiline
              type="textarea"
              name="mail_contact"
              variant="outlined"
              placeholder={`本リリースに関する報道関係のお問い合わせ\n〇〇◯◯株式会社　広報担当 〇〇\nTEL： ーーーーーー　直通：ーーーーーーー　MAIL： ーーーーーー\n`}
              onChange={handleEmailChange}
              value={reserve.data.auto.mail_contact || ''}
              InputProps={{
                classes: {
                  input: classes.contactTextareaField
                }
              }}
              disabled={!hasEdit || !isReserveEdit || isLocked}
            />
          </li>
          <li>
            <InputLabel>添付ファイル</InputLabel>
            <Box className={classes.fileBox}>
              <File />
            </Box>
          </li>
        </ul>
      </Paper>
      <PreviewDialog
        isAuto={true}
        isOpen={preview}
        closeHandle={() => setPreview(false)}
        id={0}
      />
      <TestSendDialog
        isOpen={testSend}
        closeHandle={() => setTestSend(false)}
      />
      <FixedPhrase
        handleClose={() => setAnchorTempleMenu(null)}
        mailBody={reserve.data.auto.mail_body}
        anchorTempleMenu={anchorTempleMenu}
      />
    </div>
  );
};

export default Main;
