import * as ActionCreators from "../actions/Option/ActionCreator";
import {call, put, select, takeLatest} from "redux-saga/effects";
import {selectGroup} from "./selector";
import {fetchGet} from "./fetch";
import OptionActionType from "../actions/Option/ActionType";

function* getOption(action: ReturnType<typeof ActionCreators.getOptionList.request>) {
    try {
        const [data, error] = yield call(fetchGet, `option/`);
        if(data && !error){
            yield put(ActionCreators.getOptionList.success(data));
        }
        
    } catch (e) {
        yield put(ActionCreators.getOptionList.failure(e))
    }

}

const OptionSaga = [
    takeLatest(OptionActionType.GET_OPTION_REQUEST, getOption)
]

export default OptionSaga;
