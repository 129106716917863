export const options = [
  // {label:'会員登録', url: "/help/start"},
  {
    label: "会員登録",
    url: ["registration"],
    child: [
      {
        label: "1.新規会員登録",
        url: "/help/registration#1"
      }
    ]
  },
  {
    label: "プレスリリース",
    url: ["press_release"],
    child: [
      {
        label: "1.新規作成〜保存",
        url: "/help/press_release#1"
      },
      {
        label: "2.共同編集",
        url: "/help/press_release#2"
      },
      {
        label: "3.確認依頼",
        subLabel: "確認フロー機能ONの場合",
        url: "/help/press_release#3"
      },
      {
        label: "4.承認・修正依頼",
        subLabel: "確認フロー機能ONの場合",
        url: "/help/press_release#4"
      },
      {
        label: "5.修正依頼確認・反映",
        subLabel: "確認フロー機能ONの場合",
        url: "/help/press_release#5"
      },
      {
        label: "6.コメント機能",
        url: "/help/press_release#6"
      },
      {
        label: "7.ラベル機能",
        url: "/help/press_release#7"
      }
    ]
  },
  {
    label: "エディタ",
    url: ["editor"],
    child: [
      {
        label: "1.AI校閲",
        url: "/help/editor#1"
      },
      {
        label: "2.ヘッダーフッター設定",
        url: "/help/editor#2"
      },
      {
        label: "3.改ページ挿入",
        url: "/help/editor#3"
      },
      {
        label: "4.ブロック登録・挿入・削除",
        url: "/help/editor#4"
      },
      {
        label: "5.見出し",
        url: "/help/editor#5"
      },
      {
        label: "6.カラムレイアウト",
        url: "/help/editor#6"
      }
    ]
  },
  {
    label: "配信",
    url: ["send"],
    child: [
      {
        label: "1.配信設定",
        url: "/help/send#1"
      },
      {
        label: "2.確認依頼",
        subLabel: "確認フロー機能ONの場合",
        url: "/help/send#2"
      },
      {
        label: "3.内容確認",
        subLabel: "確認フロー機能ONの場合",
        url: "/help/send#3"
      },
      {
        label: "4.配信待ち",
        url: "/help/send#4"
      }
    ]
  },
  {
    label: "webページ",
    url: ["web_page"],
    child: [
      {
        label: "1.webページ設定",
        url: "/help/web_page#1"
      }
    ]
  },
  {
    label: "HTML&PDF出力",
    url: ["export"],
    child: [
      {
        label: "1.HTML&PDF出力",
        url: "/help/export#1"
      }
    ]
  },
  {
    label: "プレスキット",
    url: ["press_kit"],
    child: [
      {
        label: "1.作成",
        url: "/help/press_kit#1"
      },
      {
        label: "2.ダウンロードページ",
        url: "/help/press_kit#2"
      },
      {
        label: "3.プレスリリースに添付",
        url: "/help/press_kit#3"
      }
    ]
  },
  {
    label: "ブリーフィング",
    url: ["briefing"],
    child: [
      {
        label: "1.新規作成〜回答依頼",
        url: "/help/briefing#1"
      },
      {
        label: "2.回答状況の確認",
        url: "/help/briefing#2"
      },
      {
        label: "3.回答する",
        url: "/help/briefing#3"
      }
    ]
  },
  {
    label: "ダッシュボード",
    url: ["dashboard"],
    child: [
      {
        label: "1.ダッシュボード",
        url: "/help/dashboard#1"
      }
    ]
  },
  {
    label: "未来トピック",
    url: ["topic"],
    child: [
      {
        label: "1.未来トピック",
        url: "/help/topic#1"
      }
    ]
  },
  {
    label: "記事化レーダー",
    url: ["radar"],
    child: [
      {
        label: "1.記事化レーダー",
        url: "/help/radar#1"
      }
    ]
  },
  {
    label: "メンバー管理",
    url: ["member"],
    child: [
      {
        label: "1.メンバー管理",
        url: "/help/member#1"
      }
    ]
  },
  {
    label: "メディア管理",
    url: ["media"],
    child: [
      {
        label: "1.企業メディアリスト",
        url: "/help/media#1"
      },
      {
        label: "2.HARVEST配信リスト",
        url: "/help/media#2"
      }
    ]
  },
  {
    label: "各種設定",
    url: [
      "company",
      "service",
      "project",
      "user",
      "radar_keyword",
      "plan",
      "payment"
    ],
    child: [
      {
        label: "1.企業情報",
        url: "/help/company#1"
      },
      {
        label: "2.サービス設定",
        url: "/help/service#1"
      },
      {
        label: "3.プロジェクト設定",
        url: "/help/project#1"
      },
      {
        label: "4.アカウント設定",
        url: "/help/user#1"
      },
      {
        label: "5.記事化レーダー設定",
        url: "/help/radar_keyword#1"
      },
      {
        label: "6.プラン/オプション設定",
        url: "/help/plan#1"
      },
      {
        label: "7.ご契約情報",
        url: "/help/payment#1"
      }
    ]
  }
];
