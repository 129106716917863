import React from 'react';

// component
import Title from "../Common/Title";
import HelpFab from "../Common/HelpFab";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent , List, ListItem, Typography } from "@material-ui/core";

// config
import { FeaturesList } from "./FeaturesList";


const Features: React.FC = () => {

	const classes = useStyles();

	return (
		<>
			<Title>
				機能一覧<HelpFab url={'/help/press_release#1'}/>
			</Title>
			{FeaturesList.map((feature, index) => {
				return (
					<div key={index} className={classes.featureContainer}>
						<Typography variant="h3" align="center" gutterBottom>{feature.title}</Typography>
						<Typography variant="h5" align="center" gutterBottom>{feature.subTitle}</Typography>
						<List className={classes.list}>
							{feature.contents.map((content, index) => {
								return (
									<ListItem key={index} className={classes.listItem}>
										<Card className={classes.card}>
											<CardMedia image={content.icon} className={classes.cardMedia} />
											<CardContent>
												<Typography variant="h5" align="center" gutterBottom>{content.title}</Typography>
												<Typography variant="body1" align="center">{content.text}</Typography>
											</CardContent>
										</Card>
									</ListItem>
								)
							})}
						</List>
					</div>
				)
			})}
		</>
	);
};

export default Features;


const useStyles = makeStyles({
	featureContainer: {
		marginBottom: 50,
	},
	list: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
	},
	listItem: {
		width: 280,
		height: 220,
	},
	card: {
		width: "100%",
		height: 200,
	},
	cardMedia: {
		height: "25%",
		backgroundSize: "contain",
		marginTop: 25,
	},
})

