import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

// component
import { makeStyles, Typography, Table, TableHead, TableRow, TableCell, Button, Box, Tooltip } from '@material-ui/core';
import SortableEditTableBody, { EditTableBody } from './Body';

// icons
import PlayListAddIcon from '@material-ui/icons/PlaylistAdd';
import IconDone from "../../../../assets/icon_done.svg";
import IconSort from "../../../../assets/icon_sort.svg";

// actions
import * as BriefingActionCreators from '../../../../actions/Briefing/ActionCreator';

// model
import { BriefingQueryModel } from '../../../../model/BriefingModel';

//config
import { QUERY_TYPES } from '../../../../config/briefing';

// state
import { RootState } from '../../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

const EditTable: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const briefing = useSelector(briefingSelector);
    const { isSorting } = briefing;
    const { body } = briefing.detail;

    const handleAddQuery = () => {
        const queries: BriefingQueryModel[] = [...body.queries];
        const emptyQuery: BriefingQueryModel = {
            type: QUERY_TYPES.TEXT,
            question: '',
            text: '',
            choices: [],
            file: null,
            selected: null,
        }
        queries.push(emptyQuery);
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    const handleSortStart = () => {
        dispatch(BriefingActionCreators.setBriefingSorting.request(true));
    }

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        let queries: BriefingQueryModel[] = [...body.queries];
        queries = arrayMove(queries, oldIndex, newIndex);
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    const handleSetSorted = () => {
        dispatch(BriefingActionCreators.setBriefingSorting.request(false));
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableCellType}>
                        <Typography variant='h6'>回答形式</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCellQuestion}>
                        <Box className={classes.tableCellQuestionInner}>
                            <Typography variant='h6'>質問内容</Typography>
                            <Box>
                                {isSorting ?
                                    <Tooltip title='完了' arrow>
                                        <Button
                                            className={classes.sortOrDoneButton}
                                            color='primary'
                                            variant='contained'
                                            onClick={handleSetSorted}>
                                            <img src={IconDone} />
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip title='並べ替え' arrow>
                                        <Button
                                            className={classes.sortOrDoneButton}
                                            color='primary'
                                            variant='outlined'
                                            onClick={handleSortStart}>
                                            <img src={IconSort} />
                                        </Button>
                                    </Tooltip>}
                                <Tooltip title='質問を追加' arrow>
                                    <Button
                                        className={classes.addQueryButton}
                                        color='primary'
                                        variant='outlined'
                                        disabled={isSorting}
                                        onClick={handleAddQuery}>
                                        <PlayListAddIcon />
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
            </TableHead>
            {isSorting ?
                <SortableEditTableBody body={body} lockAxis='y' lockToContainerEdges
                    hideSortableGhost onSortEnd={handleSortEnd} />
                : <EditTableBody body={body} />}
        </Table>
    );
}

export default EditTable;

const useStyles = makeStyles({
    tableCellQuestionInner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableCellQuestion: {
        width: '80%',
        whiteSpace: 'break-spaces',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#e9f3e9',
    },
    tableCellType: {
        width: '20%',
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: '#e9f3e9',
    },
    addQueryButton: {
        height: 29,
        minWidth: 29,
    },
    sortOrDoneButton: {
        height: 29,
        minWidth: 29,
        marginRight: 5,
    },
});
