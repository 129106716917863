import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

// component
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Greeting from "../Mail/Greeting";

//state
import { RootState } from "../../../../../reducers";
import PreviewPressKit from "./PressKit";
import ImagePDF from "../../../../../assets/icon_pdf.svg";
import { getPdfDownloadUrl } from "../../../../../config/press_release_pdf";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const companySelector = (state: RootState) => state.company;

interface PreviewDialogProps {
    isAuto: boolean,
    id: number | undefined,
    isOpen: boolean,
    closeHandle,
}

const useStyles = makeStyles({
    root: {
        width: 926,
        height: 600,
        padding: 30
    },
    title: {
        backgroundColor: "#fff",
        padding: 30
    },
    text: {
        display: "inline"
    },
    icon: {
        marginRight: 10
    },
    pdf: {
        wordBreak: "break-all",
        marginTop: 20,
        background: "#F8FBF9",
        borderRadius: 4,
        padding: 10,
        display: "flex",
        cursor: "pointer",
        border: "1px solid #ccc",
    },
    pdfInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    notDownloadPdfLink: {
        pointerEvents: 'none',
    },
});

const initialTargetState = {
    from_email: '',
    mail_title: '',
    mail_body: '',
    mail_contact: '',
    name: '',
    publisher: '',
    department: '',
    first_name: '',
    last_name: '',
    position: ''
}

const PreviewDialog: React.FC<PreviewDialogProps> = ({ isAuto, id, isOpen, closeHandle }) => {

    const classes = useStyles();
    const reserve = useSelector(pressReleaseReserveSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const company = useSelector(companySelector);

    const [target, setTarget] = useState(initialTargetState);

    const mailBody = target.mail_body;
    const mailBodyTextList = mailBody.split("\n");

    const mailContact = target.mail_contact;
    const mailContactTextList = mailContact ? mailContact.split("\n") : [];

    useEffect(() => {
        if (isAuto) {
            let t = reserve.data.auto;
            t.publisher = '〇〇株式会社';
            t.name = 'メディア名'
            t.last_name = 'ご担当者'
            setTarget(t)
        } else {
            const list = reserve.data.custom.filter(item => item.id === id);
            if (list.length) {
                setTarget(list[0])
            } else {
                closeHandle()
            }
        }
    }, [isOpen === true])

    const downloadUrl = getPdfDownloadUrl(pressRelease.detail.press_id);

    return (
        <Dialog open={isOpen}>
            <DialogTitle className={classes.title}>
                <div><Typography variant={"caption"}>送信元：</Typography>{target.from_email}</div>
                <div><Typography variant={"caption"}>件名：</Typography>{target.mail_title}</div>
            </DialogTitle>

            <DialogContent className={classes.root}>
                <Typography variant={"body1"} gutterBottom>
                    {isAuto ? (
                        <>
                            {target.publisher}<br />
                            {target.name}<br />
                            {target.last_name}様
                        </>
                    ) : (
                            <Greeting item={target} />
                        )}
                </Typography>
                <div><br /></div>
                {mailBodyTextList.map((text, index) => {
                    if (text === "") return (<Typography key={index}><br /></Typography>)
                    return (
                        <div key={index}><Typography className={classes.text}>{text}</Typography><br /></div>
                    )
                })}
                <div><br /></div>
                <div><br /></div>
                <PreviewPressKit date={reserve.data.released_at} prefix={company.prefix} />
                <div><br /></div>
                <div><br /></div>
                {mailContactTextList.map((text, index) => {
                    if (text === "") return (<Typography key={index}><br /></Typography>)
                    return (
                        <div key={index}><Typography className={classes.text}>{text}</Typography><br /></div>
                    )
                })}
                <div className={classes.pdfInfo}>
                    <a href={`${downloadUrl}?is_download=1`} className={classes.pdf}>
                        <Typography variant={"body1"} className={classes.pdfInfo}>
                            <img src={ImagePDF} className={classes.icon} />プレスリリース
                        </Typography>
                    </a>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" color="primary" onClick={closeHandle}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PreviewDialog
