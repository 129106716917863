import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchGet, fetchPost, fetchPatch, fetchPostForm } from "./fetch";
import { selectGroup, selectPressRelease, selectPressReleasePublish, selectPressReleaseReserve } from "./selector";

// action
import ActionType from '../actions/PressReleasePublish/ActionType';
import * as ActionCreators from '../actions/PressReleasePublish/ActionCreator';
import * as PressReleaseActionCreators from "../actions/PressRelease/ActionCreator";
import { PressReleasePublishRequestModel } from '../model/PressReleasePublishModel';
import { push } from 'connected-react-router';

// 取得
function* getPressReleasePublish(action: ReturnType<typeof ActionCreators.getPressReleasePublish.request>) {
    try {
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchGet, `press/${group.selectedId}/publish/?press_id=${action.payload.press_id}`);
        yield put(ActionCreators.getPressReleasePublish.success(data))
    } catch (e) {
        yield put(ActionCreators.getPressReleasePublish.failure(e));
    }
}

// 保存
function* postPressReleasePublish(action: ReturnType<typeof ActionCreators.postPressReleasePublish.request>) {
    try {
        const publish = yield select(selectPressReleasePublish);
        const pressRelease = yield select(selectPressRelease);
        const reserve = yield select(selectPressReleaseReserve);
        const group = yield select(selectGroup);
        let { press_id } = pressRelease.detail;
        const send = {
            ...publish,
            is_publish: publish.is_publish ? true : false
        }
        const [data, error] = yield call(fetchPost, `press/${group.selectedId}/publish`, { ...send, press_id });
        yield put(ActionCreators.postPressReleasePublish.success())
        yield put(PressReleaseActionCreators.getPressReleaseDetails.request({ press_id: press_id }))
    } catch (e) {
        yield put(ActionCreators.postPressReleasePublish.failure(e));
    }
}

//ogp画像アップロード
function* patchOGPImage(action: ReturnType<typeof ActionCreators.patchOGPImage.request>) {
    try {
        const group = yield select(selectGroup);
        const formData = new FormData();
        const request: any = action.payload;
        formData.append('file', request);
        formData.append('name', request.name);
        const [data, error] = yield call(fetchPostForm, `groups/${group.selectedId}/image`, formData);

        const pressRelease = yield select(selectPressRelease);
        let { press_id } = pressRelease.detail;

        // const [patchData, patchError] = yield call(fetchPatch, `press/publish`, { ogp_url: data.url, press_id: press_id });
        yield put(ActionCreators.patchOGPImage.success({ ogp_url: data.url }));
    } catch (e) {
        yield put(ActionCreators.patchOGPImage.failure(e));
    }
}

function* postMakePressReleasePublic(action: ReturnType<typeof ActionCreators.postMakePressReleasePublic.request>) {
    try {
        const request: PressReleasePublishRequestModel = action.payload;
        const group = yield select(selectGroup);
        const [data, error] = yield call(fetchPost, `press/${group.selectedId}/make_publish`, request);
        if (!error) {
            yield put(push(`/press_release/${group.selectedId}/setting_ogp/${request.press_id}`));
            yield put(ActionCreators.postMakePressReleasePublic.success());
        }
    } catch (e) {
        yield put(ActionCreators.postMakePressReleasePublic.failure(e));
    }
}

const PressReleasePublishSaga = [
    takeLatest(ActionType.GET_PRESS_RELEASE_PUBLISH_REQUEST, getPressReleasePublish),
    takeLatest(ActionType.POST_PRESS_RELEASE_PUBLISH_REQUEST, postPressReleasePublish),
    takeLatest(ActionType.PATCH_OGP_IMAGE_REQUEST, patchOGPImage),
    takeLatest(ActionType.POST_MAKE_PRESS_RELEASE_PUBLIC_REQUEST, postMakePressReleasePublic),
];

export default PressReleasePublishSaga;
