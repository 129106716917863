export const pressReleaseType = [
    {
        id: 1,
        label: 'イベント案内',
        sub: [
            { id: 400, label: 'イベント・展示会の告知・案内'},
            { id: 401, label: 'イベント・展示会の報告'},
            { id: 402, label: 'イベント登壇の告知・案内'},
            { id: 403, label: 'イベント協賛のお知らせ'},
            { id: 404, label: 'セミナー告知・案内'},
            { id: 405, label: 'コンテストの告知・案内'},

        ]
    },
    {
        id: 2,
        label: '製品・サービス系',
        sub: [
            { id: 1, label: '新サービス・新製品リリース(提供開始)' },
            { id: 2, label: 'サービス・製品アップデート・リニューアル' },
            { id: 3, label: 'サービス・製品の発売・提供エリア拡大' },
            { id: 4, label: 'ウェブサイトの開設・公開' },
            { id: 5, label: 'サービス・製品の取り扱い店・流通拡大' },
            { id: 6, label: 'サービス・製品のベータ版提供開始' },
            { id: 7, label: 'サービス・製品の限定発売・限定公開' },
            { id: 8, label: 'サービス・製品の終了のお知らせ' },
            { id: 9, label: 'サービス・製品の終了のバリエーションの追加' },
            { id: 10, label: 'サービス・製品のコラボ展開' },
            { id: 11, label: 'サービス・製品の価格改定' },
            { id: 12, label: 'サービス・製品の予約受付開始' },
            { id: 13, label: 'サービス・製品のプレゼント・キャンペーン' },
            { id: 14, label: 'サービス・製品の海外展開'},
            { id: 15, label: 'サービス・製品の受賞のおしらせ' },
            { id: 16, label: 'サービス・製品の販売○周年のお知らせ' },
            { id: 17, label: 'サービス・製品の導入実績・採用実績' },
            { id: 18, label: 'サービス・製品の販売計画/数量/ダウンロード達成' },
            { id: 19, label: 'サービス・製品の特許取得店舗' },
        ]
    },
    {
        id: 3,
        label: '店舗業態',
        sub: [
            { id: 200, label: '新店舗の開店のお知らせ'},
            { id: 201, label: '店舗のプレオープン' },
            { id: 202, label: '新メニューの提供開始' },
            { id: 203, label: '店舗の地域出店・拡大のお知らせ' },
            { id: 204, label: '地域限定メニュー' },
            { id: 205, label: '数量限定発売のメニュー' },
            { id: 206, label: '店舗閉店のお知らせ' },
            { id: 207, label: '店舗リニューアルのお知らせ' },
        ]
    },
    {
        id: 4,
        label: '調査系',
        sub: [
            { id: 300, label: 'ユーザー利用度調査'},
            { id: 301, label: '意識調査・アンケート' },
            { id: 302, label: 'ランキング発表' }
        ]
    },
    {
        id: 5,
        label: '企業活動',
        sub: [
            { id: 500, label: '社内制度・福利厚生'},
            { id: 501, label: 'ロゴ変更' },
            { id: 502, label: '業務提携' },
            { id: 503, label: '資金調達' },
            { id: 504, label: 'ファンドを設立' },
            { id: 505, label: '役員就任' },
            { id: 506, label: 'ファンドを設立' },
            { id: 507, label: '役員就任' },
            { id: 508, label: '事務所の開設・オフィス移転' },
            { id: 509, label: '受賞' },
            { id: 510, label: 'スポンサー契約' },
            { id: 511, label: 'テレビCM放送開始' },
            { id: 512, label: 'イメージキャラクター' },
            { id: 513, label: '経営統合・事業統合・事業撤退' },
            { id: 514, label: 'CSR' },
        ]
    },
    {
        id: 6,
        label: '地域・団体',
        sub: [
            { id: 601, label: 'まちおこし'},
        ]
    },
    {
        id: 7,
        label: 'その他',
        sub: [
            { id: 701, label: '本日の一部の報道について'},
            { id: 702, label: '寄付金・義援金開始' },
            { id: 703, label: '念頭所感'},
            { id: 704, label: 'エイプリルフール企画'},
            { id: 999, label: 'その他'},
        ]
    }
]
