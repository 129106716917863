enum PressReleaseAIActionType {
	POST_PRESS_RELEASE_CHECK_REQUEST = "POST_PRESS_RELEASE_CHECK_REQUEST",
	POST_PRESS_RELEASE_CHECK_SUCCEEDED = "POST_PRESS_RELEASE_CHECK_SUCCEEDED",
	POST_PRESS_RELEASE_CHECK_FAILED = "POST_PRESS_RELEASE_CHECK_FAILED",

	POST_PRESS_RELEASE_TITLE_SUGGEST_REQUEST = "POST_PRESS_RELEASE_TITLE_SUGGEST_REQUEST",
	POST_PRESS_RELEASE_TITLE_SUGGEST_SUCCEEDED = "POST_PRESS_RELEASE_TITLE_SUGGEST_SUCCEEDED",
	POST_PRESS_RELEASE_TITLE_SUGGEST_FAILED = "POST_PRESS_RELEASE_TITLE_SUGGEST_FAILED",

	SET_PRESS_RELEASE_CHECK_NODE = "SET_PRESS_RELEASE_CHECK_NODE",

}

export default PressReleaseAIActionType

