export const checkColorCode = (bg_color) => {
    const colorCode = bg_color.slice(1);
    if (colorCode.length === 3 || colorCode.length === 6) {
        const colorCodeArray = colorCode.length === 3 ?
            colorCode.match(/.{1}/g).map(code => Array(3).join(code)) :
            colorCode.length === 6 ? colorCode.match(/.{2}/g) : ['error'];
        colorCodeArray.map((code, index) => {
            if (typeof parseInt(code, 16) != 'number') return {
                isError: true,
                isBright: true,
            };
            colorCodeArray[index] = parseInt(code, 16);
        })
        return {
            isError: false,
            isBright: (colorCodeArray[0] * 299 + colorCodeArray[1] * 587 + colorCodeArray[2] * 114) / 2550 > 60,
        };
    } else {
        return {
            isError: true,
            isBright: true,
        }
    }
}

export const generateRandomColorCode = () => {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
}
