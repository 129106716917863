import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useSelector } from "react-redux";

// components
import {
    Divider, ListItemIcon, Typography, withStyles, Accordion, AccordionDetails, AccordionSummary
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SubMenuList, { activeMenuBackgroundColor } from "./SubMenu";

// icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// state
import { RootState } from "../../reducers";
const routerSelector = (state: RootState) => state.router;

interface MenuProps {
    path: string,
    name: string,
    img: string,
    subMenu?: SubMenuProps[],
    handleOpenLogoutDialog: () => void,
}

interface SubMenuProps {
    path: string,
    name: string,
    isBlank?: boolean,
}

const checkIsMatched = (pathname: string, path: string): boolean => {
    return pathname.startsWith(path) || pathname.startsWith(path.slice(0, path.length - 1));
}

const BaseMenu: React.FC<MenuProps> = ({ path, name, img, subMenu = [], handleOpenLogoutDialog }) => {
    const classes = useStyles();
    const { pathname } = useSelector(routerSelector).location;
    const isMatched = checkIsMatched(pathname, path);

    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <Accordion
                expanded={expanded || (subMenu.length != 0 && isMatched)}
                className={classes.accordion}
            >
                <AccordionSummary onClick={() => subMenu.length > 0 && setExpanded(!expanded)}>
                    {subMenu.length == 0 ?
                        <SummaryLink path={path} name={name} img={img} isMatched={subMenu.length == 0 && isMatched} />
                        :
                        <SummaryItem name={name} img={img} subMenu={subMenu} isMatched={isMatched} expanded={expanded || (subMenu.length != 0 && isMatched)} />}
                </AccordionSummary>
                <AccordionDetails>
                    <SubMenuList subMenu={subMenu} pathname={pathname}
                        handleOpenLogoutDialog={handleOpenLogoutDialog} />
                </AccordionDetails>
            </Accordion>
            <Divider />
        </>
    );
}

export default BaseMenu;

interface LinkProps {
    path: string,
    name: string,
    img: string,
    isMatched: boolean,
}

const SummaryLink: React.FC<LinkProps> = ({ path, name, img, isMatched }) => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.item, isMatched ? classes.selectedItem : "")}>
            <NavLink to={path} className={classes.itemLink}>
                <ListItemIcon className={classes.icon}>
                    <img src={img} className={classes.img} />
                </ListItemIcon>
                <Typography variant="h6">{name}</Typography>
            </NavLink>
        </div>
    );
}

const SummaryItem = ({ name, img, subMenu, isMatched, expanded }) => {
    const classes = useStyles();

    return (
        <div className={classNames(classes.linkItem, subMenu.length == 0 && isMatched ? classes.selectedItem : "")}>
            <div className={classes.menuItem}>
                <ListItemIcon className={classes.icon}>
                    <img src={img} className={classes.img} />
                </ListItemIcon>
                <Typography variant="h6">
                    {name}
                </Typography>
            </div>
            <ExpandMoreIcon className={classNames(classes.expandIcon, expanded ? classes.expanded : "")} />
        </div>
    )
}

const useStyles = makeStyles({
    linkItem: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: 0,
        transition: "0.1s",
        "&:hover": {
            backgroundColor: activeMenuBackgroundColor,
        },
    },
    itemLink: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "10px 24px",
    },
    icon: {
        marginRight: 5,
    },
    img: {
        width: 22,
        maxHeight: 26,
    },
    item: {
        display: "flex",
        width: "100%",
        padding: 0,
        transition: "0.1s",
        "&:hover": {
            backgroundColor: activeMenuBackgroundColor,
        },
    },
    selectedItem: {
        backgroundColor: activeMenuBackgroundColor,
    },
    link: {
        width: "100%",
        display: "flex",
    },
    menuItem: {
        display: "flex",
        padding: "10px 24px",
        width: "calc(100% - 30px)",
        alignItems: "center",
    },
    expandIcon: {
        transform: "rotate(0deg)",
        transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    expanded: {
        transform: "rotate(180deg)",
    },
    accordion: {
        backgroundColor: "#f3f9f2",
    }
})

