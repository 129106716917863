enum MediaActionType {
	GET_MEDIA_LIST_REQUEST = "GET_MEDIA_LIST_REQUEST",
	GET_MEDIA_LIST_SUCCEEDED = "GET_MEDIA_LIST_SUCCEEDED",
	GET_MEDIA_LIST_FAILED = "GET_MEDIA_LIST_FAILED",

	GET_MEDIA_ALL_LIST_REQUEST = "GET_MEDIA_ALL_LIST_REQUEST",
	GET_MEDIA_ALL_LIST_SUCCEEDED = "GET_MEDIA_ALL_LIST_SUCCEEDED",
	GET_MEDIA_ALL_LIST_FAILED = "GET_MEDIA_ALL_LIST_FAILED",

	GET_MEDIA_DETAIL_REQUEST = "GET_MEDIA_DETAIL_REQUEST",
	GET_MEDIA_DETAIL_SUCCEEDED = "GET_MEDIA_DETAIL_SUCCEEDED",
	GET_MEDIA_DETAIL_FAILED = "GET_MEDIA_DETAIL_FAILED",
	POST_MEDIA_REQUEST = "POST_MEDIA_REQUEST",
	POST_MEDIA_SUCCEEDED = "POST_MEDIA_SUCCEEDED",
	POST_MEDIA_FAILED = "POST_MEDIA_FAILED",
	PATCH_MEDIA_REQUEST = "PATCH_MEDIA_REQUEST",
	PATCH_MEDIA_SUCCEEDED = "PATCH_MEDIA_SUCCEEDED",
	PATCH_MEDIA_FAILED = "PATCH_MEDIA_FAILED",
	DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST",
	DELETE_MEDIA_SUCCEEDED = "DELETE_MEDIA_SUCCEEDED",
	DELETE_MEDIA_FAILED = "DELETE_MEDIA_FAILED",

	GET_FAVORITE_MEDIA_LIST_REQUEST = 'GET_FAVORITE_MEDIA_LIST_REQUEST',
	GET_FAVORITE_MEDIA_LIST_SUCCEEDED = 'GET_FAVORITE_MEDIA_LIST_SUCCEEDED',
	GET_FAVORITE_MEDIA_LIST_FAILED = 'GET_FAVORITE_MEDIA_LIST_FAILED',

	POST_FAVORITE_MEDIA_LIST_REQUEST = 'POST_FAVORITE_MEDIA_LIST_REQUEST',
	POST_FAVORITE_MEDIA_LIST_SUCCEEDED = 'POST_FAVORITE_MEDIA_LIST_SUCCEEDED',
	POST_FAVORITE_MEDIA_LIST_FAILED = 'POST_FAVORITE_MEDIA_LIST_FAILED',

	PATCH_FAVORITE_MEDIA_LIST_REQUEST = 'PATCH_FAVORITE_MEDIA_LIST_REQUEST',
	PATCH_FAVORITE_MEDIA_LIST_SUCCEEDED = 'PATCH_FAVORITE_MEDIA_LIST_SUCCEEDED',
	PATCH_FAVORITE_MEDIA_LIST_FAILED = 'PATCH_FAVORITE_MEDIA_LIST_FAILED',

	DELETE_FAVORITE_MEDIA_LIST_REQUEST = 'DELETE_FAVORITE_MEDIA_LIST_REQUEST',
	DELETE_FAVORITE_MEDIA_LIST_SUCCEEDED = 'DELETE_FAVORITE_MEDIA_LIST_SUCCEEDED',
	DELETE_FAVORITE_MEDIA_LIST_FAILED = 'DELETE_FAVORITE_MEDIA_LIST_FAILED',

	GET_MEDIA_CATEGORIES_REQUEST = 'GET_MEDIA_CATEGORIES_REQUEST',
	GET_MEDIA_CATEGORIES_SUCCEEDED = 'GET_MEDIA_CATEGORIES_SUCCEEDED',
	GET_MEDIA_CATEGORIES_FAILED = 'GET_MEDIA_CATEGORIES_FAILED',

	SET_FAVORITE_MEDIA = 'SET_FAVORITE_MEDIA',

	SET_FAVORITE_MEDIA_LIST = 'SET_FAVORITE_MEDIA_LIST',

	ADD_MEDIA_FAVORITE_LIST_ALL = 'ADD_MEDIA_FAVORITE_LIST_ALL',
	REMOVE_MEDIA_FAVORITE_LIST_ALL = 'REMOVE_MEDIA_FAVORITE_LIST_ALL',

	SET_MEDIA_CATEGORY_ID_LIST = 'SET_MEDIA_CATEGORY_ID_LIST',
	ADD_MEDIA_CATEGORY_ID_LIST_ALL = 'ADD_MEDIA_CATEGORY_ID_LIST_ALL',
	REMOVE_MEDIA_CATEGORY_ID_LIST_ALL = 'REMOVE_MEDIA_CATEGORY_ID_LIST_ALL',

	RESET_MEDIA_CATEGORY_LIST = 'RESET_MEDIA_CATEGORY_LIST',

	GET_FAVORITE_MEDIA_LIST_DETAIL_REQUEST = 'GET_FAVORITE_MEDIA_LIST_DETAIL_REQUEST',
	GET_FAVORITE_MEDIA_LIST_DETAIL_SUCCEEDED = 'GET_FAVORITE_MEDIA_LIST_DETAIL_SUCCEDED',
	GET_FAVORITE_MEDIA_LIST_DETAIL_FAILED = 'GET_FAVORITE_MEDIA_LIST_DETAIL_FAILED',

	SET_SEARCH = 'SET_SEARCH',

	POST_CSV_IMPORT_REQUEST = 'POST_CSV_IMPORT_REQUEST',
	POST_CSV_IMPORT_SUCCEEDED = 'POST_CSV_IMPORT_SUCCEEDED',
	POST_CSV_IMPORT_FAILED = 'POST_CSV_IMPORT_FAILED',
}

export default MediaActionType;
