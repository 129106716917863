export const MEDIA_TYPE_LIST = ['その他', '新聞', 'WEB', 'TV', 'ラジオ', '書籍/雑誌', 'WEB/書籍']
export const MEDIA_TYPE_COLOR_LIST = ['#001950', '#003e7d', '#1168ad', '#509458', '#80c686', '#ffff51', '#f4d002']

export const MEDIA_CATEGORY_COLOR_LIST = {
    "IT": "#005272",
    "ビジネス": "#0688aa",
    "エンタメ": "#61c2e6",
    "ファッション": "#a2ffff",
    "【廃止予定】ビューティー": "#e3ffff",
    "スポーツ": "#799c00",
    "住宅（インテリア・ハウジング）": "#b1d245",
    "【廃止予定】グルメ": "#ebff7c",
    "医療・健康・福祉": "#ffffb3",
    "教育": "#ffffec",
    "企画": "#ac6258",
    "アウトドア": "#e09085",
    "料理・食品・グルメ": "#ffc1b4",
    "【廃止予定】ブライダル": "#fff4e7",
    "金融・証券・投資・保険": "#f6bc43",
    "観光・旅行": "#b7fffb",
    "ペット": "#52ac97",
    "美容・健康・コスメ": "#84dec8",
    "生活情報・ライフスタイル": "#b7dcf5",
    "結婚・恋愛": "#86aac2",
    "国際関連": "#577b92",
    "環境": "#2a4f64",
    "新商品情報・トレンド": "#ffee74",
    "家電・デジタル製品": "#f38c8d",
    "地域情報": "#ffbdbd",
    "自動車・バイク": "#85bed7",
}
