import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";

//action
import * as ErrorActionCreators from "../../../actions/Error/ActionCreator";

//model
import { RootState } from "../../../reducers";
import { ErrorModel } from "../../../model/ErrorModel";

const errorStateSelector = (state: RootState): ErrorModel => state.error;

const ErrorDialog = () => {

    const dispatch = useDispatch();
    const error = useSelector(errorStateSelector);

    const handleClose = () => {
        dispatch(ErrorActionCreators.cleanUpErrorState())
    };

    return (
        <div>
            <Dialog
                open={error.dialog}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText id="error-dialog-description">
                        {error.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined" size="large">
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ErrorDialog

