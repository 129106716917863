enum SignActionType {
	POST_SIGN_UP_REQUEST = "POST_SIGN_UP_REQUEST",
    POST_SIGN_UP_SUCCEEDED = "POST_SIGN_UP_SUCCEEDED",
    POST_SIGN_UP_FAILED = "POST_SIGN_UP_FAILED",

	GET_SIGN_UP_ACTIVATE_REQUEST = "GET_SIGN_UP_ACTIVATE_REQUEST",
	GET_SIGN_UP_ACTIVATE_SUCCEEDED = "GET_SIGN_UP_ACTIVATE_SUCCEEDED",
	GET_SIGN_UP_ACTIVATE_FAILED = "GET_SIGN_UP_ACTIVATE_FAILED",

	POST_SIGN_IN_REQUEST = "POST_SIGN_IN_REQUEST",
	POST_SIGN_IN_SUCCEEDED = "POST_SIGN_IN_SUCCEEDED",
	POST_SIGN_IN_FAILED = "POST_SIGN_IN_FAILED",

	FIREBASE_LOGIN_REQUEST = 'FIREBASE_LOGIN_REQUEST',
	FIREBASE_LOGIN_SUCCEEDED = 'FIREBASE_LOGIN_SUCCEEDED',
	FIREBASE_LOGIN_FAILED = 'FIREBASE_LOGIN_FAILED',

	FIREBASE_LOGIN_ANONYMOUSLY_REQUEST = 'FIREBASE_LOGIN_ANONYMOUSLY_REQUEST',
	FIREBASE_LOGIN_ANONYMOUSLY_SUCCEEDED = 'FIREBASE_LOGIN_ANONYMOUSLY_SUCCEEDED',
	FIREBASE_LOGIN_ANONYMOUSLY_FAILED = 'FIREBASE_LOGIN_ANONYMOUSLY_FAILED',
}

export default SignActionType;
