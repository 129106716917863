import React, { ReactNode, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import classNames from 'classnames';

// action
import * as GroupActionCreators from "../../actions/Group/ActionCreator";

// state
import {RootState} from "../../reducers";
import HeaderMenu from "../../components/PressRelease/Common/HeaderMenu";
import Panel from "../../components/PressRelease/Common/Panel";
const routerSelector = (state: RootState) => state.router;

export interface Props {
    children: ReactNode,
	page: string,
	panel?: boolean
}

const PressReleaseLayout: React.FC<Props> = ({children, page, panel = true}) => {
	const classes = useStyles();
    const dispatch = useDispatch();
    const router = useSelector(routerSelector);

    const param = router.location.pathname.split('/')
    useEffect(() => {
        dispatch(GroupActionCreators.setSelectedId.request(param[2]));
    }, []);

	return (
		<div className={classes.root}>
			<HeaderMenu page={page}/>
			<div className={classNames(classes.body, page === "PAGE_RESERVE" || "PAGE_OGP" ? classes.bg: "")}>
				<Box className={classes.content}>
					{children}
				</Box>
				{panel && <Panel pageType={page}/>}
			</div>
		</div>
	);
}

export default PressReleaseLayout;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			overflow: 'hidden'
		},
		body: {
			flex: 1,
			display: "flex",
			height: "calc( 100vh - 64px)",
		},
		content: {
			flex: 1,
			display: "flex",
			height: "calc( 100vh - 64px)",
			overflow: "scroll",
			overflowX: "hidden",
			margin: "auto"
		},
		bg: {
			backgroundColor: "#f5f5f5"
		},
	}),
);
