import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// component
import { makeStyles, Button, Typography, List, ListItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import TextField from '../../Field/TextFieldUnderline';

// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// model
import { BriefingBodyModel, BriefingQueryModel } from '../../../model/BriefingModel';

// config
import { QUERY_TYPES } from '../../../config/briefing';

import { RootState } from '../../../reducers';
const BriefingSelector = (state: RootState) => state.briefing;
interface Props {
    choices: string[],
    body: BriefingBodyModel,
    index: number,
}

const RadioQuery: React.FC<Props> = ({ choices, body, index }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isSorting } = useSelector(BriefingSelector);
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const handleChangeChoice = (choice: string, id: number) => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries[index].choices[id] = choice;
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    const handleAddNewChoice = () => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries[index].choices.push('');
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    const handleRemoveChoice = (id: number) => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries[index].choices.splice(id, 1);
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    useEffect(() => {
        if (body.queries[index].type === QUERY_TYPES.RADIO) setIsPanelOpen(true);
    }, [body.queries[index].type])

    return (
        <List>
            <ExpansionPanel expanded={isPanelOpen && !isSorting} className={classes.panelRoot}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                        content: classes.summary,
                        expanded: classes.summary,
                    }}
                    onClick={() => setIsPanelOpen(!isPanelOpen)}
                >
                    <Typography>{choices.length}個の選択肢</Typography>
                    <Button variant='outlined' color='primary' disabled={isSorting}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            handleAddNewChoice();
                            setIsPanelOpen(true);
                            e.stopPropagation();
                        }}>
                        追加
                    </Button>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                    {choices.map((choice: string, id: number) => (
                        <ListItem key={id} dense className={classes.listItem}>
                            <TextField value={choice} multiline
                                handleChange={(text: string) => handleChangeChoice(text, id)} />
                            <Button onClick={() => handleRemoveChoice(id)}>削除</Button>
                        </ListItem>
                    ))}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </List>
    );
}

export default RadioQuery;

const useStyles = makeStyles({
    panelRoot: {
        boxShadow: 'none',
    },
    summary: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: 0,
    },
    details: {
        padding: 0,
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
