import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from "react-redux";

//material-ui
import { Button, Typography } from '@material-ui/core'

//model
import { BasicDropzonePropsModel } from '../../../model/DropzoneModel'

//style
import style from './dropzone.module.scss'

// util
import { convertFileSize } from "../../../util/File";

import { RootState } from "../../../reducers";
const pressKitSelector = (state: RootState) => state.pressKit;
const companySelector = (state: RootState) => state.company;

export const BasicDropzone: React.FC<BasicDropzonePropsModel> = ({ uploadActionDispatcher, text, img }) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
    const pressKit = useSelector(pressKitSelector);
    const company = useSelector(companySelector);

    const { totalSize } = pressKit.status;
    const fileSizeLimit = company.plan.file_size_limit;
    const [isLimit, setIsLimit] = useState<boolean>(false);
    const [remainingFileSize, setRemainingFileSize] = useState<number>(fileSizeLimit - totalSize);

    const fileNames = acceptedFiles.map((file, index) => {
        return (
            <li key={index}>
                <ul>{file.name}</ul>
            </li>
        )
    })

    const isEmpty = acceptedFiles.length > 0 ? false : true

    const handleClick = () => {
        uploadActionDispatcher(acceptedFiles)
    }

    useEffect(() => {
        if (pressKit.upload.dialog && acceptedFiles.length > 0) {
            let estimateFileSize: number = totalSize;

            acceptedFiles.forEach((file) => {
                estimateFileSize += file.size;
            })

            if (estimateFileSize > fileSizeLimit) {
                setIsLimit(true);
                setRemainingFileSize(0);
            } else {
                setIsLimit(false);
                setRemainingFileSize(fileSizeLimit - estimateFileSize);
            }
        }
    }, [acceptedFiles]);

    return (
        <>
        <section className={style.container}>
            {pressKit.upload.dialog &&
                <div>
                    <Typography>残りファイル容量：{convertFileSize(remainingFileSize)}</Typography>
                </div>
            }
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()}/>
                <div className={style.dropzone}>
                    <img src={img} />
                    <Typography variant={"h5"}>ファイルをドラッグ&ドロップ、またはクリックでアップロード</Typography>
                </div>
            </div>
            {acceptedFiles.length > 0 &&
                <aside className={style.preview}>
                    <Typography>ファイル名</Typography>
                    <ul>{fileNames}</ul>
                    {isLimit &&
                        <Typography color="error" className={style.error}>
                            ファイル容量の上限を超えてしまう恐れがあるため、アップロードすることができません。<br/>
                            ファイルを選択し直してください。<br/>
                        </Typography>
                    }
                </aside>
            }
        </section>
        <div className={style.buttonContainer}>
            <Button
                variant='contained'
                color='primary'
                size="large"
                className={style.button}
                onClick={handleClick}
                disabled={isEmpty || isLimit}
            >
                {text}
            </Button>
        </div>
        </>
    )
}
