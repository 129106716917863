import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { makeStyles, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import { CategorySelect } from '../../PressRelease/Common/CategoryDialog';
import MediaSelect from './Select';

// model
import { MediaCategoryModel, MediaCategoryDetailModel } from '../../../model/MediaModel';

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

// image
import IconClose from '../../../assets/icon_close_gray.svg';

// state
import { RootState } from '../../../reducers';

const mediaSelector = (state: RootState) => state.media;

const CreateListDialog = ({ isOpen, handleClose, handleOpenListNameDialog }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const media = useSelector(mediaSelector);
    const [page, setPage] = useState<number>(0);

    const [categories, setCategories] = useState<string>('');
    const [details, setDetails] = useState<string>('');

    const handleChangeTag = (id: number) => {
        let list: any = categories.split(',')
        if (list.indexOf(String(id)) >= 0) list = list.filter(item => item !== String(id));
        else if (list.length <= 2) list.push(id);
        setCategories(list.join(','));
    };

    const handleChangeDetailTag = (id: number) => {
        let list: any = details.split(',');
        if (list.indexOf(String(id)) >= 0) list = list.filter(item => item != String(id));
        else list.push(id);
        setDetails(list.join(','));
    }

    const handleAddAllCheck = (category: MediaCategoryModel) => {
        let list: any = details.split(',');
        category.details.map((detail: MediaCategoryDetailModel, index: number) => list.push(detail.id));
        list = list.filter(function (x, i, self) { return self.indexOf(x) === i });
        setDetails(list.join(','));
    }

    const handleRemoveCheck = (category: MediaCategoryModel) => {
        let list: any = details.split(',');
        category.details.map((detail: MediaCategoryDetailModel) => {
            list = list.filter(n => {
                n !== String(detail.id);
            });
        });
        list = list.filter(function (x, i, self) { return self.indexOf(x) === i });
        setDetails(list.join(','));
    }

    const handlePostCategory = () => {
        const list = categories.split(',').filter(v => v)
        const detail = details.split(',').filter(v => v)
        dispatch(MediaActionCreators.getMediaAllList.request({ offset: 0, categories: list, category_details: detail }))
    }

    const handleResetIdList = () => {
        dispatch(MediaActionCreators.removeMediaCategoryIdListAll.request());
    }

    const handleReset = () => {
        setPage(0);
        setCategories('');
        setDetails('');
        dispatch(MediaActionCreators.resetMediaCategoryList.request());
        dispatch(MediaActionCreators.setSearch.request({ text: '', is_result: false }));
    }

    return (
        <Dialog open={isOpen} fullWidth onExited={() => handleReset()}>
            <DialogTitle>
                <Typography>配信リスト新規作成</Typography>
                <IconButton aria-label="close" onClick={() => handleClose()} className={classes.close}>
                    <img src={IconClose} />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.root}>
                {page === 0 &&
                    <div className={classes.categorySelect}>
                        <CategorySelect
                            categories={categories}
                            details={details}
                            handleChangeTag={handleChangeTag}
                            handleChangeDetailTag={handleChangeDetailTag}
                            handleAddAllCheck={handleAddAllCheck}
                            handleRemoveCheck={handleRemoveCheck}
                        />
                    </div>}
                {page === 1 && <MediaSelect handleSearch={handlePostCategory} />}
            </DialogContent>
            <DialogActions>
                {page === 0 && <Button variant='outlined' size='large' color='primary' onClick={handleClose}>
                    閉じる
                </Button>}
                {page === 0 && <Button variant='contained' size='large' color='secondary' disabled={details.length === 0} onClick={() => {
                    setPage(1);
                    handleResetIdList();
                    handlePostCategory();
                }}>
                    次へ
                </Button>}
                {page === 1 && <Button variant='outlined' size='large' color='primary' onClick={() => {
                    setPage(0);
                    dispatch(MediaActionCreators.setSearch.request({ text: '', is_result: false }));
                }}>
                    戻る
                </Button>}
                {page === 1 && <Button variant='contained' size='large' color='primary'
                    disabled={media.categoryList.id_list.length === 0} onClick={handleOpenListNameDialog}>
                    保存する
                </Button>}
            </DialogActions>
        </Dialog>
    );
}

export default CreateListDialog;

const useStyles = makeStyles({
    root: {
        height: 500,
    },
    categorySelect: {
        padding: '30px 0',
    },
    close: {
        position: "absolute",
        right: 13,
        top: 13,
    },
})
