import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// style
import style from "../Content/media_select_dialog.module.scss";

// component
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import MediaListNameDialog from "../ListNameDialog";
import MediaMenu from "../Content/Menu";
import MediaSelect from "./Select";

// state
import { RootState } from "../../../reducers";

// action
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';
import * as PressReleaseReserveActionCreators from '../../../actions/PressReleaseReserve/ActionCreator';

interface Props {
    isOpen: boolean,
    closeHandle,
}

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;
const mediaSelector = (state: RootState) => state.media;
const meSelector = (state: RootState) => state.me;

const MediaDialog: React.FC<Props> = ({ isOpen, closeHandle }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const reserve = useSelector(pressReleaseReserveSelector);
    const media = useSelector(mediaSelector);
    const me = useSelector(meSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const { status } = pressRelease.detail;
    const hasEdit = pressRelease.role.edit;
    const [listNameDialog, setListNameDialog] = useState(false);
    const [page, setPage] = useState(1);
    const { categories, category_details, type } = reserve.data;

    const handlePostFavoriteList = (name: string, setErrorMessage) => {
        dispatch(MediaActionCreators.postFavoriteMediaList.request({
            name: name, media: reserve.data.auto.id_list,
            postSuccess: () => setListNameDialog(false),
            postError: (text) => setErrorMessage(text)
        }))
    }

    const handlePostMediaRecommend = () => {
        if (categories && type && category_details.length > 0) {
            dispatch(PressReleaseReserveActionCreators.postPressReleaseMediaRecommend.request({ categories: categories, type: type, details: category_details }));
        }
        if (reserve.data.auto.list_id > 0) {
            dispatch(MediaActionCreators.getFavoriteMediaListDetail.request({
                id: reserve.data.auto.list_id,
                offset: 0,
                id_list: reserve.data.auto.id_list
            }))
        }
    }

    const handleResetSearch = () => {
        dispatch(MediaActionCreators.setSearch.request({ text: '', is_result: false }));
    }

    const handleSetAutoListPage = () => {
        setPage(1);
        handleResetSearch();
        if (categories && type && category_details.length > 0) {
            dispatch(PressReleaseReserveActionCreators.postPressReleaseMediaRecommend.request({ categories: categories, type: type, details: category_details }));
        }
        dispatch(PressReleaseReserveActionCreators.setMediaListId.request(0));
    }

    const handleSetCustomListPage = (listId: number) => {
        setPage(1);
        handleResetSearch();
        dispatch(MediaActionCreators.setFavoriteMediaList.request(listId))
        dispatch(PressReleaseReserveActionCreators.setMediaListId.request(listId))
        dispatch(MediaActionCreators.getFavoriteMediaListDetail.request({ id: listId, offset: 0 }))
    }

    const handlePageChange = (pageNumber: number) => {
        setPage(pageNumber);
        const listId = reserve.data.auto.list_id;
        if (listId === 0) {
            dispatch(PressReleaseReserveActionCreators.postPressReleaseMediaRecommend.request({
                categories: categories,
                details: category_details,
                type: type,
            }))
        } else if (listId > 0) {
            dispatch(MediaActionCreators.getFavoriteMediaListDetail.request({ id: listId, offset: pageNumber }));
        }
    }

    return (
        <Dialog open={isOpen} fullWidth onEnter={() => handlePostMediaRecommend()} onExit={() => handleResetSearch()}>
            <DialogTitle>
                <Typography>
                    {(!hasEdit || status === 5 || status >= 7) ? '配信メディアを確認' : '配信メディアを選択'}
                </Typography>
                {(!hasEdit || status === 5 || status >= 7) &&
                    <Typography className={classes.noAuthority}>
                        配信メディアは編集できません
                    </Typography>}
            </DialogTitle>
            <DialogContent className={classes.root}>
                <div className={style.content}>
                    <MediaMenu
                        handleSetAutoListPage={handleSetAutoListPage}
                        handleSetCustomListPage={handleSetCustomListPage}
                    />
                    <MediaSelect
                        openListNameDialog={() => setListNameDialog(true)}
                        page={page}
                        handlePageChange={handlePageChange}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Typography variant='caption'>プレスリリース配信先メールアドレスを正式に公開しているメディアを表示しています。2020年7月 HARVEST調べ</Typography>
                {(status === 3 || status === 6) && (
                    <Button variant="contained" size="large" color="secondary" onClick={() => closeHandle()}>
                        配信先を設定
                    </Button>)}
                {(status === 5 || status >= 7) && (
                    <Button variant="contained" size="large" color="secondary" onClick={() => closeHandle()}>
                        閉じる
                    </Button>)}
            </DialogActions>
            <MediaListNameDialog
                isOpen={listNameDialog}
                handleSave={handlePostFavoriteList}
                handleClose={() => setListNameDialog(false)}
            />
        </Dialog>
    )
}

export default MediaDialog;

const useStyles = makeStyles({
    root: {
        height: 500,
        padding: 0,
        display: 'flex',
        overflowY: 'hidden',
    },
    noAuthority: {
        color: "#E95050",
        position: 'absolute',
        top: 20,
        right: 15,
        fontWeight: 'bold',
    },
})
