import ActionType from './ActionType';
import {
	MediaModel, MediaListModel, MediaRequestModel, MediaListRequestModel, postFavoriteMediaListRequestModel,
	addIdListAllModel, PostCsvImportModel, patchFavoriteMediaListRequestModel, deleteFavoriteMediaListRequestModel,
	MediaCategoryListModel, AllMediaListRequestModel, FavoriteMediaListDetailRequestModel, SearchModel, CsvResultModel
} from '../../model/MediaModel';
import { createAsyncAction } from 'typesafe-actions';

export const getMediaList = createAsyncAction(
	ActionType.GET_MEDIA_LIST_REQUEST,
	ActionType.GET_MEDIA_LIST_SUCCEEDED,
	ActionType.GET_MEDIA_LIST_FAILED,
)<MediaListRequestModel, MediaModel[], Error>();

export const getMediaAllList = createAsyncAction(
	ActionType.GET_MEDIA_ALL_LIST_REQUEST,
	ActionType.GET_MEDIA_ALL_LIST_SUCCEEDED,
	ActionType.GET_MEDIA_ALL_LIST_FAILED,
)<AllMediaListRequestModel, MediaListModel, Error>();

export const getMediaDetail = createAsyncAction(
	ActionType.GET_MEDIA_DETAIL_REQUEST,
	ActionType.GET_MEDIA_DETAIL_SUCCEEDED,
	ActionType.GET_MEDIA_DETAIL_FAILED,
)<MediaRequestModel, MediaModel, Error>();

export const postMedia = createAsyncAction(
	ActionType.POST_MEDIA_REQUEST,
	ActionType.POST_MEDIA_SUCCEEDED,
	ActionType.POST_MEDIA_FAILED,
)<MediaModel, void, Error>();

export const patchMedia = createAsyncAction(
	ActionType.PATCH_MEDIA_REQUEST,
	ActionType.PATCH_MEDIA_SUCCEEDED,
	ActionType.PATCH_MEDIA_FAILED,
)<MediaModel, void, Error>();

export const deleteMedia = createAsyncAction(
	ActionType.DELETE_MEDIA_REQUEST,
	ActionType.DELETE_MEDIA_SUCCEEDED,
	ActionType.DELETE_MEDIA_FAILED,
)<MediaRequestModel, void, Error>();

export const getFavoriteMediaList = createAsyncAction(
	ActionType.GET_FAVORITE_MEDIA_LIST_REQUEST,
	ActionType.GET_FAVORITE_MEDIA_LIST_SUCCEEDED,
	ActionType.GET_FAVORITE_MEDIA_LIST_FAILED,
)<MediaListRequestModel, MediaListModel, Error>();

export const postFavoriteMediaList = createAsyncAction(
	ActionType.POST_FAVORITE_MEDIA_LIST_REQUEST,
	ActionType.POST_FAVORITE_MEDIA_LIST_SUCCEEDED,
	ActionType.POST_FAVORITE_MEDIA_LIST_FAILED,
)<postFavoriteMediaListRequestModel, void, Error>();

export const patchFavoriteMediaList = createAsyncAction(
	ActionType.PATCH_FAVORITE_MEDIA_LIST_REQUEST,
	ActionType.PATCH_FAVORITE_MEDIA_LIST_SUCCEEDED,
	ActionType.PATCH_FAVORITE_MEDIA_LIST_FAILED,
)<patchFavoriteMediaListRequestModel, void, Error>();

export const deleteFavoriteMediaList = createAsyncAction(
	ActionType.DELETE_FAVORITE_MEDIA_LIST_REQUEST,
	ActionType.DELETE_FAVORITE_MEDIA_LIST_SUCCEEDED,
	ActionType.DELETE_FAVORITE_MEDIA_LIST_FAILED,
)<deleteFavoriteMediaListRequestModel, void, Error>();

export const getMediaCategories = createAsyncAction(
	ActionType.GET_MEDIA_CATEGORIES_REQUEST,
	ActionType.GET_MEDIA_CATEGORIES_SUCCEEDED,
	ActionType.GET_MEDIA_CATEGORIES_FAILED,
)<MediaListRequestModel, MediaCategoryListModel, Error>();

export const setFavoriteMedia = createAsyncAction(
	ActionType.SET_FAVORITE_MEDIA,
	'',
	'',
)<number, void, Error>();

export const setFavoriteMediaList = createAsyncAction(
	ActionType.SET_FAVORITE_MEDIA_LIST,
	'',
	'',
)<number, void, Error>();

export const setMediaCategoryIdList = createAsyncAction(
	ActionType.SET_MEDIA_CATEGORY_ID_LIST,
	'',
	'',
)<number, void, Error>();

export const addMediaCategoryIdListAll = createAsyncAction(
	ActionType.ADD_MEDIA_CATEGORY_ID_LIST_ALL,
	'',
	'',
)<addIdListAllModel, void, Error>();

export const removeMediaCategoryIdListAll = createAsyncAction(
	ActionType.REMOVE_MEDIA_CATEGORY_ID_LIST_ALL,
	'',
	'',
)<void, void, Error>();

export const resetMediaCategoryList = createAsyncAction(
	ActionType.RESET_MEDIA_CATEGORY_LIST,
	'',
	'',
)<void, void, Error>();

export const getFavoriteMediaListDetail = createAsyncAction(
	ActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_REQUEST,
	ActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_SUCCEEDED,
	ActionType.GET_FAVORITE_MEDIA_LIST_DETAIL_FAILED,
)<FavoriteMediaListDetailRequestModel, MediaListModel, Error>();

export const setSearch = createAsyncAction(
	ActionType.SET_SEARCH,
	'',
	'',
)<SearchModel, void, Error>();

export const addMediaFavoriteListAll = createAsyncAction(
	ActionType.ADD_MEDIA_FAVORITE_LIST_ALL,
	'',
	'',
)<number[], void, Error>();

export const postCsvImport = createAsyncAction(
	ActionType.POST_CSV_IMPORT_REQUEST,
	ActionType.POST_CSV_IMPORT_SUCCEEDED,
	ActionType.POST_CSV_IMPORT_FAILED,
)<PostCsvImportModel, CsvResultModel, Error>();
