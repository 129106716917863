import React, { useEffect } from "react";
import {createStyles, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const Sctl: React.FC = () => {

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h3" gutterBottom>特定商取引法に基づく表示</Typography>
			<ul className={classes.table}>
				<li>
                    <div>販売業者</div>
                    <div>株式会社m-Lab</div>
                </li>
                <li>
                    <div>代表者</div>
                    <div>村上進也</div>
                </li>
                <li>
                    <div>サイト</div>
                    <div>HARVEST（ハーベスト）</div>
                </li>
                <li>
                    <div>URL</div>
                    <div>https://harvest.site/</div>
                </li>

                <li>
                    <div>所在地</div>
                    <div>
                        〒104-0061<br/>
                        東京都中央区銀座1-16-7 銀座大栄ビル　5階
                    </div>
                </li>
                <li>
                    <div>商品の名称</div>
                    <div>
                        サービス利用料課金
                    </div>
                </li>
                <li>
                    <div>販売価格</div>
                    <div>
                        別途ページにて記載しています
                    </div>
                </li>
                <li>
                    <div>連絡先</div>
                    <div>
                        info@mlabs.jp / 03-4360-8752
                    </div>
                </li>
                <li>
                    <div>支払方法</div>
                    <div>
                        クレジットカード決済・請求書払い<br/>
                        ※請求書払いは利用審査があります<br/>
                        ※支払期限につきましては取引によって異なります<br/>
                    </div>
                </li>

                <li>
                    <div>引渡し時期</div>
                    <div>
                        即時
                    </div>
                </li>
                <li>
                    <div>返品・交換について</div>
                    <div>
                        確定後の取引は原則として返品・交換は不可能です
                    </div>
                </li>
                <li>
                    <div>解約の条件</div>
                    <div>
                        契約期間内であればいつでも解約することが可能です。<br/>
                        解約後、次回更新タイミング以降の請求は行われません。<br/>
                        解約の申請は、お問い合わせよりご連絡ください。
                    </div>
                </li>
			</ul>
		</div>
	);
};

export default Sctl;


const useStyles = makeStyles(() =>
	createStyles({
		root: {
			margin: "80px 100px 0",
			fontSize: 16
		},
        table: {
            marginTop: 50,
            "& > li": {
                display: "flex",
                marginBottom: 10,
            },
            "& li > div:first-child": {
                width: 180,
            }
        }
	})
);
