import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

// component
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";

//action
import * as PressReleaseReserveActionCreators from "../../../../../actions/PressReleaseReserve/ActionCreator";

//state
import {RootState} from "../../../../../reducers";
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const meSelector = (state: RootState) => state.me;
const PressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

interface TestSendDialogProps {
    isOpen: boolean,
    closeHandle,
}

const useStyles = makeStyles({
    root: {
        width: 926,
        padding: 30
    },
    title: {
        backgroundColor: "#fff",
        paddingLeft: 20,
    },
    text: {
        display: "inline"
    },
    mail: {
        fontWeight: "bold"
    },
    warning: {
        color: "#E95050",
        fontSize: 13
    }
});

const TestSendDialog: React.FC<TestSendDialogProps> = ({isOpen, closeHandle}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const me = useSelector(meSelector);
    const pressReleaseReserve = useSelector(PressReleaseReserveSelector);
    const pressRelease = useSelector(pressReleaseSelector);
    const [isSendMail, setIsSendMail] = useState<boolean>(false);

    const active = pressReleaseReserve.active;
    const {auto, custom} = pressReleaseReserve.data;
    const email: string = me.email;
    const hasEdit = pressRelease.role.edit;

    const isCustomMediaSelected = active > 0 ? true : false;
    const autoOrCustom = isCustomMediaSelected ? custom.filter(item => item.id === active)[0] : auto;
    const checkMailTitleExists = (isCustomMedia: boolean, autoOrCustom) => {
        if (isCustomMedia) {
            const custom = autoOrCustom;
            return !!(custom['mail_title']);
        }
        const auto = autoOrCustom;
        return !!(auto['mail_title']);
    }
    const checkMailBodyExists = (isCustomMedia: boolean, autoOrCustom) => {
        if (isCustomMedia) {
            const custom = autoOrCustom;
            return !!(custom['mail_body']);
        }
        const auto = autoOrCustom;
        return !!(auto['mail_body']);
    }

    const isMailTitleExists = checkMailTitleExists(isCustomMediaSelected, autoOrCustom);
    const isMailBodyExists = checkMailBodyExists(isCustomMediaSelected, autoOrCustom);

    const isDataSatisfied = isMailTitleExists && isMailBodyExists;

    const handleSend = () => {
        dispatch(PressReleaseReserveActionCreators.postMailCheck.request({ active }))
        setIsSendMail(true)
    }

    const handleClose = () => {
        closeHandle()
        setIsSendMail(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle className={classes.title}>
                テスト配信
            </DialogTitle>

            <DialogContent className={classes.root}>
                {isSendMail ? (
                    <Typography>メールを送信しました</Typography>
                ) : (
                    <>
                        <Typography>以下のメールアドレスにテストメールを送信します。</Typography>
                        <Typography className={classes.mail}>{email}</Typography>
                        {!isCustomMediaSelected && <Typography>※宛名には送信者の情報が入ります</Typography>}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {!isMailTitleExists && <Typography className={classes.warning}>メールのタイトルが入力されていません</Typography>}
                {!isMailBodyExists && <Typography className={classes.warning}>メールの本文が入力されていません</Typography>}
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={!hasEdit || !isDataSatisfied || isSendMail}
                    onClick={handleSend}
                >
                    送信する
                </Button>
                <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TestSendDialog;
