import React, {useState, useEffect} from "react";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import {useSelector, useDispatch} from "react-redux";
import {push} from "connected-react-router";

// mateiral-ui
import {
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  makeStyles,
  Divider
} from "@material-ui/core";

// components
import SettingTitle from "../../../Common/SettingTitle";

//actions
import * as ContactActionCreator from "../../../../actions/Contact/ActionCreator";

import {calcTax, RADAR, MEMBER} from "../../../../config/payment_config";
import {OptionModel} from "../../../../model/OptionModel";

//reducers
import {RootState} from "../../../../reducers";
const companySelctor = (state: RootState) => state.company;
const radarSelctor = (state: RootState) => state.radar;
const paymentSelctor = (state: RootState) => state.payment;
const meSelector = (state: RootState) => state.me;

interface RemoveOptionProps {
  option: OptionModel;
}

const RemoveOption: React.FC<RemoveOptionProps> = ({option}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(companySelctor);
  const radar = useSelector(radarSelctor);
  const {info: payment, fetching} = useSelector(paymentSelctor);
  const me = useSelector(meSelector);

  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  // indexが0から始まるため1月はmonth()だと0で返ってくる
  const nextmonth = dayjs().add(1, "M");

  useEffect(() => {
    if (option.id === RADAR) {
      setQuantity(company.option.radar.validFromNext);
    }
    if (option.id === MEMBER) {
      setQuantity(company.option.member.validFromNext);
    }
  }, [option]);

  useEffect(() => {
    const amount = value * option.amount;
    const tax = calcTax(amount);
    setAmount(amount + tax);
  }, [value, option]);

  const handleApplyOption = (value: number) => {
    const typeText = createTypeText(option.id, quantity - value);

    const postValues = {
      first_name: me.first_name,
      last_name: me.last_name,
      email: me.email,
      company: company.name,
      phone: company.tel,
      type: typeText,
      text: "",
      successHandler,
      failedHandler
    };

    dispatch(ContactActionCreator.postContact.request(postValues));
  };

  const handleClick = () => {
    dispatch(push("/setting/payment/"));
  };

  const range = (start: number, end: number) => {
    return Array.from({length: end - start + 1}, (v, k) => k + start);
  };

  const menuItemList: Array<any> = range(0, quantity - 1);

  const successHandler = () => {
    dispatch(push(`/setting/plan/apply/complete/`));
  };

  const failedHandler = () => {
    setErrorMessage(
      "申請ができませんでした。\n時間を置いて、再度お試しください。"
    );
  };

  return (
    <>
      {option.id === RADAR && <SettingTitle text="検索ワードの削除申請" />}
      {option.id === MEMBER && <SettingTitle text="メンバー枠の削除申請" />}

      <Divider />
      <div className={classes.root}>
        {option.id === RADAR && (
          <Typography variant={"h6"}>{`${quantity}ワード`}</Typography>
        )}
        {option.id === MEMBER && (
          <Typography variant={"h6"}>{`${quantity}人`}</Typography>
        )}
        <Typography className={classes.arrow} variant={"h6"}>
          →
        </Typography>
        <div className={classes.form}>
          <FormControl className={classes.formControl}>
            <Select
              variant={"outlined"}
              value={value}
              displayEmpty
              inputProps={{"aria-label": "Without label"}}
              onChange={(e: React.ChangeEvent<{value: any}>) =>
                setValue(e.target.value)
              }
            >
              {menuItemList.map((i, k) => (
                <MenuItem value={i} key={k}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {option.id === RADAR && <Typography variant={"h6"}>ワード</Typography>}
        {option.id === MEMBER && <Typography variant={"h6"}>人</Typography>}
      </div>
      <Divider />

      <Divider />
      <div className={classes.info}>
        <Typography variant="body2">{`変更適応は${nextmonth.year()}年${nextmonth.month() +
          1}月1日です。`}</Typography>
        {option.id === RADAR && (
          <Typography
            variant={"body2"}
          >{`次回の請求では合計${value}ワード分の${amount}円が請求されます。`}</Typography>
        )}
        {option.id === MEMBER && (
          <Typography
            variant={"body2"}
          >{`次回の請求では合計${value}人分の${amount}円が請求されます。`}</Typography>
        )}
        {/* クレカ決済廃止のため、請求日は一旦非表示にする
            <Typography variant="body2">{`次回請求日は${nextmonth.year()}年${nextmonth.month() +
            1}月1日です。`}</Typography>
        */}
      </div>

      {errorMessage && (
        <div className={classes.info}>
          <Typography
            variant="body2"
            color="error"
            style={{whiteSpace: "pre-wrap"}}
          >
            {errorMessage}
          </Typography>
        </div>
      )}

      <div style={{margin: "30px 0"}}>
        <Button
          variant="outlined"
          size="large"
          disabled={fetching}
          color="primary"
          onClick={handleClick}
        >
          キャンセル
        </Button>
        <Button
          color="primary"
          className={classes.button}
          variant="contained"
          size="large"
          disabled={fetching}
          onClick={() => handleApplyOption(value)}
        >
          申請する
        </Button>
      </div>
    </>
  );
};

export default RemoveOption;

const createTypeText = (optionId: number, value: number) => {
  switch (optionId) {
    case RADAR:
      return `検索ワードを ${value}つ 削除`;
    case MEMBER:
      return `メンバー枠を ${value}つ 削除`;
    default:
      return `メンバー枠を ${value}つ 削除`;
  }
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    margin: 10
  },
  arrow: {
    margin: "0 10px"
  },
  row: {
    margin: 10,
    display: "flex",
    justifyContent: "space-between",
    height: 56,
    alignItems: "center"
  },
  form: {
    alignItems: "center",
    display: "flex"
  },
  formControl: {
    padding: 10,
    minWidth: 200,
    justifyContent: "center"
  },
  info: {
    margin: 10
  },
  button: {
    marginLeft: 10
  }
});
