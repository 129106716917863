import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';

import {Card, Divider, Typography, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {RootState} from '../../../reducers';

const companySelector = (state: RootState) => state.company;
const planSelector = (state: RootState) => state.plan;

const OptionList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const plan = useSelector(planSelector);
  const [isFreePlan, setIsFreePlan] = useState<boolean>(false);

  useEffect(() => {
    if (company.uuid) {
      setIsFreePlan(company.plan.code == plan.free.code);
    } else {
      setIsFreePlan(true);
    }
  }, [company]);

  return (
    <div className={classes.root}>
      <div className={classes.titleBlock}>
        <Typography variant={'h1'}>オプション</Typography>
        {isFreePlan && (
          <Typography
            className={classes.optionText}
            variant={'h6'}
            color="error"
          >
            無料プランではオプションを購入できません
          </Typography>
        )}
      </div>
      <Card className={classes.card}>
        <div className={classes.row}>
          <div className={classes.leftBlock}>
            <Typography className={classes.title} variant={'h5'}>
              記事化レーダー
            </Typography>
          </div>
          <div className={classes.rightBlock}>
            <Button
              variant="outlined"
              size="large"
              className={classes.button}
              disabled={isFreePlan}
              onClick={() => dispatch(push('/setting/option/radar/add/'))}
            >
              追加
            </Button>
            <Typography className={classes.cost} variant={'body2'}>
              月額¥5,500(税込)
            </Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.row}>
          <div className={classes.leftBlock}>
            <Typography className={classes.title} variant={'h5'}>
              メンバー追加
            </Typography>
          </div>
          <div className={classes.rightBlock}>
            <Button
              variant="outlined"
              size="large"
              className={classes.button}
              disabled={isFreePlan}
              onClick={() => dispatch(push('/setting/option/member/add/'))}
            >
              追加
            </Button>
            <Typography className={classes.cost} variant={'body2'}>
              月額¥1,100(税込)
            </Typography>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OptionList;

const useStyles = makeStyles({
  root: {
    margin: '24px 0'
  },
  card: {
    padding: '20px'
  },
  title: {
    padding: '10px'
  },
  cost: {
    marginLeft: '10px'
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  leftBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    color: '#ff9800',
    borderColor: '#ff9800',
    '&:hover': {
      opacity: 0.7
    }
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  optionText: {
    marginLeft: 'auto'
  }
});
