import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import Title from "../Common/Title";

//component
import {TopicListLayout} from "./List/ListLayout";
import {TopicNoticeDialog} from "./Dialog";
import {SearchResultListLayout} from "./SearchResult/SearchResultListLayout";
import HelpFab from "../Common/HelpFab";

//action
import * as ActionCreators from "../../actions/Topic/ActionCreator";

//model
import {RootState} from "../../reducers"

//img
import BetaIcon from "../../assets/beta.svg";
import InfoIcon from "../../assets/icon_info_orange.svg";

//style
import style from "./topic.module.scss";

const topicProceedingsSelector = (state: RootState) => state.topic.proceedings;
const rssSelector = (state: RootState) => state.topic.rss;
const topicSelectedWordSelector = (state: RootState) => state.topic.selected.word;

const ProceedingsTopics = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const proceedings = useSelector(topicProceedingsSelector);
    const dataList = proceedings.data || [];
    const topicWordSearchResults = useSelector(rssSelector);
    const selectedWord = useSelector(topicSelectedWordSelector);
    const isWordSelected = !!selectedWord;
    const isExistsDataListContent = dataList.length > 1;

    const cleanUp = () => {
        dispatch(ActionCreators.setSelectedTopicWord.request({word: '', year: 0, month: 0}))
    }

    useEffect(() => {
        //最初にAPIからデータを取得して、それ以降はstate内のデータを表示する
        if (!proceedings.isFetched) {
            dispatch(ActionCreators.getProceedingsTopics.request())
        }
    })

    //最初に選択しておく
    useEffect(() => {
        if (!isWordSelected && isExistsDataListContent) {
            for (const data of dataList) {
                if (data.words.length > 0 && data.display_words.length > 0) {
                    const _data = {
                        word: data.display_words[0],
                        year: data.year,
                        month: data.month
                    }
                    dispatch(ActionCreators.setSelectedTopicWord.request(_data))
                    dispatch(ActionCreators.postTopicWord.request(_data))
                    break
                }
            }
        }
    }, [dataList])

    useEffect(() => {
        return () => cleanUp()
    }, [])

    return (
        <div className={style.root}>
            <Title>
                未来トピック
                <HelpFab url={'/help/topic#1'}/>
                <img src={BetaIcon} className={style.betaIcon} />
            </Title>
            <div className={style.listContainer}>
                <TopicListLayout list={dataList}/>
                <SearchResultListLayout list={topicWordSearchResults}/>
            </div>
            <TopicNoticeDialog
                isOpen={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)}
            />
        </div>
    );
};

export default ProceedingsTopics
