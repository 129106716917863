import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import dayjs from 'dayjs';

// component
import Content from './Content';
import {Box, Typography, Paper, Switch} from '@material-ui/core/';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles';
import PreviewDialog from '../../Common/PreviewDialog';
import Tips from '../../Common/Tips';
import DatePicker from '../../Common/DatePicker';

import * as PressReleasePublishActionCreators from '../../../actions/PressReleasePublish/ActionCreator';

// state
import {RootState} from '../../../reducers';

import {getPdfDownloadUrl} from '../../../config/press_release_pdf';
import {STATUS_ACCEPT, STATUS_PUBLIC_DONE} from '../../../config/status_type';

const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleasePublishSelector = (state: RootState) =>
  state.pressReleasePublish;
const companySelector = (state: RootState) => state.company;

const useStyles = makeStyles({
  root: {
    backgroundColor: '#f5f5f5',
    padding: '10px 20px',
    maxWidth: 1000,
    margin: '0 auto',
    width: '100%'
  },
  info: {
    background: '#fff',
    padding: 10,
    margin: '10px 0',
    border: '1px solid #e2e9e5',
    boxShadow: 'none'
  },
  pdfInfo: {
    display: 'flex',
    padding: '0 20px'
  },
  item: {
    display: 'flex',
    padding: '10px 20px',
    alignItems: 'center'
  },
  label: {
    width: 160
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: '#2EB964',
    textDecoration: 'underline'
  },
  headline: {
    width: '100%',
    margin: '20px 10px 20px 0',
    display: 'flex',
    alignItems: 'center'
  },
  noPublic: {
    width: '100%',
    marginBottom: 20,
    color: '#ff0000'
  },
  preview: {
    color: '#494b47',
    marginLeft: 60,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  pdfOpen: {
    color: '#494b47',
    marginLeft: 30,
    textDecoration: 'underline'
  },
  pdfDownload: {
    color: '#494b47',
    marginLeft: 30,
    textDecoration: 'underline'
  }
});

const OGP: React.FC = () => {
  const classes = useStyles();
  const pressRelease = useSelector(pressReleaseSelector);
  const company = useSelector(companySelector);
  const pressReleasePublish = useSelector(pressReleasePublishSelector);
  const dispatch = useDispatch();

  const [previewDialog, setPreviewDialog] = useState<boolean>(false);

  // プレスリリース編集権限
  let hasEdit = pressRelease.role.edit;
  const canEditOGP = pressRelease.detail.status >= STATUS_ACCEPT;
  const pdfDownloadUrl = getPdfDownloadUrl(pressRelease.detail.press_id);

  const releasedAt = new Date(pressReleasePublish.released_at);
  const today = new Date();
  const tomorrow = dayjs(
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  )
    .add(1, 'day')
    .toDate();

  const releasedAtGreaterThanTomorrow = releasedAt > tomorrow;

  // web公開switch
  const handlePublishChange = () => {
    const twoWeeksLater = dayjs(
      `${today.getFullYear()}-${today.getMonth() +
        1}-${today.getDate()}T12:00:00`
    )
      .add(14, 'day')
      .toDate();
    dispatch(
      PressReleasePublishActionCreators.setPressReleasePublish.request({
        is_publish: !pressReleasePublish.is_publish,
        released_at: twoWeeksLater
      })
    );
  };

  const handleChangeDatetime = m => {
    if (m)
      dispatch(
        PressReleasePublishActionCreators.setPressReleasePublish.request({
          released_at: m
        })
      );
  };

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h5" className={classes.headline}>
          WEBページ設定
        </Typography>
        <Paper className={classes.info}>
          <div className={classes.pdfInfo}>
            <Typography variant="h6" className={classes.title}>
              プレスリリース
            </Typography>
            <Typography
              variant="body1"
              className={classes.preview}
              onClick={() => setPreviewDialog(true)}
            >
              WEBプレビュー
            </Typography>
            {pressRelease.detail.status > 2 && (
              <>
                <a
                  href={`${pdfDownloadUrl}`}
                  className={classes.pdfOpen}
                  target="_blank"
                >
                  <Typography variant={'body1'}>PDFを開く</Typography>
                </a>
                <a
                  href={`${pdfDownloadUrl}?is_download=1`}
                  className={classes.pdfDownload}
                >
                  <Typography variant={'body1'}>PDFをダウンロード</Typography>
                </a>
              </>
            )}
          </div>
        </Paper>
        <Paper className={classes.info}>
          <Box className={classes.item}>
            <div className={classes.label}>
              <Typography variant="h6" className={classes.title}>
                公開
              </Typography>
            </div>
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    disabled={
                      !company.is_public_page ||
                      !company.prefix ||
                      !hasEdit ||
                      !canEditOGP
                    }
                    checked={Boolean(
                      company.prefix && pressReleasePublish.is_publish
                    )}
                    onChange={handlePublishChange}
                    color="primary"
                    inputProps={{'aria-label': 'primary checkbox'}}
                  />
                }
                label={
                  pressReleasePublish.is_publish ? '公開する' : '公開しない'
                }
              />
              {!company.prefix && (
                <Typography variant="body2">
                  URLが
                  <NavLink
                    to={`/setting/service/edit/`}
                    className={classes.link}
                  >
                    設定
                  </NavLink>
                  されていません。
                </Typography>
              )}
              {!company.is_public_page && (
                <Typography variant="body2">
                  公開設定が非公開に
                  <NavLink
                    to={`/setting/service/edit/`}
                    className={classes.link}
                  >
                    設定
                  </NavLink>
                  されています。
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
        {company.is_public_page && pressReleasePublish.is_publish && (
          <>
            <Paper className={classes.info}>
              <Box className={classes.item}>
                <Content hasEdit={hasEdit} />
              </Box>
            </Paper>
            <Paper className={classes.info}>
              <Box className={classes.item}>
                <div className={classes.label}>
                  <Typography variant="h6" className={classes.title}>
                    {pressRelease.detail.status === STATUS_PUBLIC_DONE
                      ? '公開時刻'
                      : '公開予定時刻'}
                    <Tips>
                      即時公開はできません。公開前日までに予約をおこなってください。
                    </Tips>
                  </Typography>
                </div>
                <DatePicker
                  datetime={pressReleasePublish.released_at}
                  edit={hasEdit && canEditOGP && releasedAtGreaterThanTomorrow}
                  isReserve={true}
                  handleChangeDatetime={handleChangeDatetime}
                />
              </Box>
            </Paper>
          </>
        )}
      </div>
      <PreviewDialog
        isRevew={false}
        isHistory={false}
        isOpen={previewDialog}
        closeHandle={() => setPreviewDialog(false)}
        page={pressRelease.pageType}
      />
    </>
  );
};

export default OGP;
