import { takeLatest, put, call, select } from 'redux-saga/effects';
import { fetchGet } from "./fetch";
import { selectGroup, selectPressReleaseList, selectRouter } from "./selector";
import { PRESS_RELEASE_LIST_DISPLAY_COUNT } from '../config/list_display_count'

// action
import PressReleaseActionType from '../actions/PressRelease/ActionType';
import * as ActionCreators from '../actions/PressRelease/ActionCreator';
import * as MemberActionCreators from '../actions/Member/ActionCreator';
import * as CompanyActionCreators from '../actions/Company/ActionCreator';
import { PressReleaseListRequestModel } from "../model/PressReleaseModel";

// 一覧取得
function* getPressReleaseList(action: ReturnType<typeof ActionCreators.getPressReleaseList.request>) {
    try {
        const group = yield select(selectGroup);
        const request: PressReleaseListRequestModel = action.payload
        const displayCount = PRESS_RELEASE_LIST_DISPLAY_COUNT
        let offset = request.offset
        offset = offset <= 1 ? 0 : (offset - 1) * displayCount
        const { filter } = yield select(selectPressReleaseList);
        const { creator, reviewer, tag } = filter;
        const [data, error] = yield call(fetchGet,
            `groups/${group.selectedId}/search/?offset=${offset}&limit=${displayCount}&creator=${creator}&reviewer=${reviewer}&tags=${tag}`);

        if (data && !error) {
            yield put(ActionCreators.getPressReleaseList.success(Object.assign({}, { offset: request.offset }, data, { displayCount })))
            yield put(CompanyActionCreators.setIsFreePlanExpired(false));
            yield put(MemberActionCreators.setIsOverMember(false));
        } else {
            if (error) {
                if (error.is_free_plan_expired) {
                    yield put(CompanyActionCreators.setIsFreePlanExpired(true));
                } else if (error.is_over_member) {
                    yield put(MemberActionCreators.setIsOverMember(true));
                }
            }
        }
    } catch (e) {
        yield put(ActionCreators.getPressReleaseList.failure(e));
    }
}

// 公開済み一覧取得
function* getPressReleaseListPublic(action: ReturnType<typeof ActionCreators.getPressReleaseListPublic.request>) {
    try {
        const group = yield select(selectGroup);
        const request: PressReleaseListRequestModel = action.payload
        const displayCount = PRESS_RELEASE_LIST_DISPLAY_COUNT
        let offset = request.offset
        offset = offset <= 1 ? 0 : (offset - 1) * displayCount
        const { filter } = yield select(selectPressReleaseList);
        const { creator, reviewer, tag } = filter;
        const [data, error] = yield call(fetchGet,
            `groups/${group.selectedId}/search/?offset=${offset}&limit=${displayCount}&is_public=1&creator=${creator}&reviewer=${reviewer}&tags=${tag}`);

        if (data && !error) {
            yield put(ActionCreators.getPressReleaseListPublic.success(Object.assign({}, { offset: request.offset }, data, { displayCount })))
            yield put(CompanyActionCreators.setIsFreePlanExpired(false));
            yield put(MemberActionCreators.setIsOverMember(false));
        } else {
            if (error) {
                if (error.is_free_plan_expired) {
                    yield put(CompanyActionCreators.setIsFreePlanExpired(true));
                } else if (error.is_over_member) {
                    yield put(MemberActionCreators.setIsOverMember(true));
                }
            }
        }
    } catch (e) {
        yield put(ActionCreators.getPressReleaseListPublic.failure(e));
    }
}

// アーカイブ一覧取得
function* getPressReleaseListArchive(action: ReturnType<typeof ActionCreators.getPressReleaseListArchive.request>) {
    try {
        const group = yield select(selectGroup);
        const request: PressReleaseListRequestModel = action.payload
        const displayCount = PRESS_RELEASE_LIST_DISPLAY_COUNT
        let offset = request.offset
        offset = offset <= 1 ? 0 : (offset - 1) * displayCount
        const { filter } = yield select(selectPressReleaseList);
        const { creator, reviewer, tag } = filter;
        const [data, error] = yield call(fetchGet,
            `groups/${group.selectedId}/search/?offset=${offset}&limit=${displayCount}&is_archive=1&creator=${creator}&reviewer=${reviewer}&tags=${tag}`);

        if (data && !error) {
            yield put(ActionCreators.getPressReleaseListArchive.success(Object.assign({}, { offset: request.offset }, data, { displayCount })))
            yield put(CompanyActionCreators.setIsFreePlanExpired(false));
            yield put(MemberActionCreators.setIsOverMember(false));
        } else {
            if (error) {
                if (error.is_free_plan_expired) {
                    yield put(CompanyActionCreators.setIsFreePlanExpired(true));
                } else if (error.is_over_member) {
                    yield put(MemberActionCreators.setIsOverMember(true));
                }
            }
        }
    } catch (e) {
        yield put(ActionCreators.getPressReleaseListArchive.failure(e));
    }
}

function* getPressReleaseListSuitableForCurrentPage(action: ReturnType<typeof ActionCreators.getPressReleaseListSuitableForCurrentPage.request>) {
    try {
        const router = yield select(selectRouter);
        const { search, archive, publicList } = yield select(selectPressReleaseList);
        const is_archive = !!(router.location.pathname.indexOf('archive') > -1 ? 1 : 0);
        const is_public = !!(router.location.pathname.indexOf('public') > -1 ? 1 : 0);
        if (is_archive) {
            yield put(ActionCreators.getPressReleaseListArchive.request({ offset: archive.offset }));
        } else if (is_public) {
            yield put(ActionCreators.getPressReleaseListPublic.request({ offset: publicList.offset }));
        } else {
            yield put(ActionCreators.getPressReleaseList.request({ offset: search.offset }));
        }
        yield put(ActionCreators.getPressReleaseListSuitableForCurrentPage.success());
    } catch (e) {
        yield put(ActionCreators.getPressReleaseListSuitableForCurrentPage.failure(e));
    }
}


const pressReleaseListSaga = [
    takeLatest(PressReleaseActionType.GET_PRESS_RELEASE_LIST_REQUEST, getPressReleaseList),
    takeLatest(PressReleaseActionType.GET_PRESS_RELEASE_LIST_PUBLIC_REQUEST, getPressReleaseListPublic),
    takeLatest(PressReleaseActionType.GET_PRESS_RELEASE_LIST_ARCHIVE_REQUEST, getPressReleaseListArchive),
    takeLatest(PressReleaseActionType.GET_PRESS_RELEASE_LIST_SUITABLE_FOR_CURRENT_PAGE, getPressReleaseListSuitableForCurrentPage),
];

export default pressReleaseListSaga;
