import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {push} from "connected-react-router";

// material-ui
import {
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  makeStyles,
  Divider
} from "@material-ui/core";

// components
import SettingTitle from "../../../Common/SettingTitle";

// actions
import * as ContactActionCreator from "../../../../actions/Contact/ActionCreator";

// config
import {calcTax, RADAR, MEMBER} from "../../../../config/payment_config";

// util
import {getNextChargeDate} from "../../../../util/payment";

// model
import {OptionModel} from "../../../../model/OptionModel";

// reducers
import {RootState} from "../../../../reducers";
const paymentSelector = (state: RootState) => state.payment;
const meSelector = (state: RootState) => state.me;
const companySelector = (state: RootState) => state.company;

interface AddOptionProps {
  option: OptionModel;
  optionInfo: {
    isAvailable: boolean;
    quantity: number;
    validFromNext: number;
  };
}

const AddOption: React.FC<AddOptionProps> = ({option, optionInfo}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {info: payment, fetching} = useSelector(paymentSelector);
  const me = useSelector(meSelector);
  const company = useSelector(companySelector);

  const [value, setValue] = useState(1);
  const [amount, setAmount] = useState(0);
  const [tax, setTax] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const nextChargeDate = getNextChargeDate();

  useEffect(() => {
    const amount = (value + optionInfo.validFromNext) * option.amount;
    const tax = calcTax(amount);
    setTax(tax);
    setAmount(amount + tax);
  }, [value, option]);

  const handleApplyOption = (value: number) => {
    const typeText = createTypeText(option.id, value);

    const postValues = {
      first_name: me.first_name,
      last_name: me.last_name,
      email: me.email,
      company: company.name,
      phone: company.tel,
      type: typeText,
      text: "",
      successHandler,
      failedHandler
    };

    dispatch(ContactActionCreator.postContact.request(postValues));
  };

  const successHandler = () => {
    dispatch(push(`/setting/plan/apply/complete/`));
  };

  const failedHandler = () => {
    setErrorMessage(
      "申請ができませんでした。\n時間を置いて、再度お試しください。"
    );
  };

  return (
    <>
      {option.id === RADAR && <SettingTitle text="検索ワードの追加申請" />}
      {option.id === MEMBER && <SettingTitle text="メンバー枠の追加申請" />}
      <Divider />
      <div className={classes.row_m}>
        <div className={classes.form}>
          <FormControl className={classes.formControl}>
            <Select
              variant={"outlined"}
              value={value}
              displayEmpty
              inputProps={{"aria-label": "Without label"}}
              onChange={(e: React.ChangeEvent<{value: any}>) =>
                setValue(e.target.value)
              }
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
        </div>
        {option.id === RADAR && (
          <Typography
            variant={"h6"}
          >{`1ワードにつき ${option.amount}円/月`}</Typography>
        )}
        {option.id === MEMBER && (
          <Typography variant={"h6"}>
            {`1人につき ${option.amount}円/月`}
          </Typography>
        )}
      </div>
      <Divider />
      <div className={classes.row_s}>
        <Typography variant={"h6"}>消費税</Typography>
        <Typography variant={"h6"}>{`${tax}円`}</Typography>
      </div>
      <Divider />
      <div className={classes.row_m}>
        <Typography variant={"h5"}>お支払い合計</Typography>
        <Typography variant={"h5"}>{`${amount}円`}</Typography>
      </div>
      <Divider />

      <div className={classes.info}>
        {option.id === RADAR && (
          <Typography variant="body2">{`合計で${optionInfo.validFromNext +
            value}ワード分の金額が請求されます。`}</Typography>
        )}
        {option.id === MEMBER && (
          <Typography variant="body2">{`合計で${optionInfo.validFromNext +
            value}人分の金額が請求されます。`}</Typography>
        )}
        {/* クレカ決済廃止のため、請求日は一旦非表示にする */}
        {/* <Typography variant="body2">{`次回請求日は${nextChargeDate}です。`}</Typography> */}
      </div>

      {errorMessage && (
        <div className={classes.info}>
          <Typography
            variant="body2"
            color="error"
            style={{whiteSpace: "pre-wrap"}}
          >
            {errorMessage}
          </Typography>
        </div>
      )}

      <div className={classes.action}>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          disabled={fetching}
          onClick={() => {
            dispatch(push("/setting/plan/"));
          }}
        >
          キャンセル
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          disabled={!value || fetching}
          onClick={() => handleApplyOption(value)}
        >
          申請する
        </Button>
      </div>
    </>
  );
};

export default AddOption;

const createTypeText = (optionId: number, value: number) => {
  switch (optionId) {
    case RADAR:
      return `検索ワードを ${value}つ 追加`;
    case MEMBER:
      return `メンバー枠を ${value}つ 追加`;
    default:
      return `メンバー枠を ${value}つ 追加`;
  }
};

const useStyles = makeStyles({
  row_s: {
    margin: "10px",
    display: "flex",
    justifyContent: "space-between",
    height: "32px",
    alignItems: "center"
  },
  row_m: {
    margin: "10px",
    display: "flex",
    justifyContent: "space-between",
    height: "56px",
    alignItems: "center"
  },
  form: {
    alignItems: "center",
    display: "flex"
  },
  formControl: {
    padding: 10,
    minWidth: 200,
    justifyContent: "center"
  },
  info: {
    margin: "24px 0"
  },
  action: {
    margin: "30px 0"
  },
  button: {
    marginLeft: 10
  }
});
