import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// component
import { Typography, Checkbox, Box } from "@material-ui/core";
import Header from "./Header";
import List from "./List";
import Search from "../Search";

// style
import style from "../Content/media_select_dialog.module.scss";

//state
import { RootState } from "../../../reducers";

// actions
import * as PressReleaseReserveActionCreators from '../../../actions/PressReleaseReserve/ActionCreator';
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

const mediaSelector = (state: RootState) => state.media;
const pressReleaseSelector = (state: RootState) => state.pressRelease;
const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

interface MediaSelectProps {
    openListNameDialog,
    page: number,
    handlePageChange,
}

const MediaSelect: React.FC<MediaSelectProps> = ({ openListNameDialog, page, handlePageChange }) => {
    const dispatch = useDispatch();
    const media = useSelector(mediaSelector);
    const [value, setValue] = useState(false);
    const reserve = useSelector(pressReleaseReserveSelector);
    const { status } = useSelector(pressReleaseSelector).detail;
    const listId = reserve.data.auto.list_id;

    const handleAddAllCheck = () => {
        setValue(true);
        if (listId === 0) {
            const list = reserve.data.auto.ai_list.map(i => i.id).concat(reserve.data.auto.id_list);
            dispatch(PressReleaseReserveActionCreators.addPressReleaseReserveAutoMediaAll.request(Array.from(new Set(list))));
        } else {
            dispatch(MediaActionCreators.addMediaFavoriteListAll.request(media.search.is_result ?
                media.categoryList.results.map(i => i.id) : media.categoryList.all_id_list));
        }
    }

    const handleRemoveAllCheck = () => {
        setValue(false);
        if (listId === 0) dispatch(PressReleaseReserveActionCreators.removePressReleaseReserveAutoMediaAll.request());
        else dispatch(MediaActionCreators.addMediaFavoriteListAll.request([]));
    }

    const handleChange = () => {
        if (value) handleRemoveAllCheck();
        else handleAddAllCheck();
    }

    useEffect(() => {
        setValue(false);
    }, [listId])

    useEffect(() => {
        const { id_list, ai_list, ai_length } = reserve.data.auto;
        if (media.search.is_result) {
            if (listId === 0) {
                if (id_list.legnth < ai_list.length) setValue(false);
            } else if (media.favoriteList.results.find(i => i.list_id === listId).id_list.length < media.categoryList.results.length) {
                setValue(false);
            }
        } else if (listId === 0) {
            if (id_list.length < ai_length) setValue(false);
        } else if (media.favoriteList.results.find(i => i.list_id === listId).id_list.length < media.categoryList.all_id_list.length) {
            setValue(false);
        }
    }, [reserve.data.auto, listId, media.categoryList, media.favoriteList])

    return (
        <div className={style.formRootDialog}>
            <div className={style.headerWrapperDialog}>
                <Header openListNameDialog={openListNameDialog} />
                <div className={style.formWrapper}>
                    {(status == 3 || status === 6) &&
                        <div className={style.form}>
                            <Box className={style.checkBox}>
                                <Checkbox checked={value} color='primary' onClick={() => handleChange()} />
                                <Typography variant='caption'>全てにチェックをつける</Typography>
                            </Box>
                            <Search handleSearch={() => handlePageChange(1)} />
                        </div>
                    }
                </div>
            </div>
            <List page={page} handlePageChange={handlePageChange} />
        </div>
    );
};

export default MediaSelect;
