import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash'

const userAgent = window.navigator.userAgent.toLowerCase();

// css
import "./ai.module.scss";

// image
import IconRobot from "../../../../../assets/editor/icon_robot_red.svg";
import IconRobotGreen from "../../../../../assets/icon_robot_green.svg";

// component
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import Avatar from "../../../../Common/Avatar";

// action
import * as ActionCreators from "../../../../../actions/PressReleaseAI/AIActionCreator";

// state
import {RootState} from "../../../../../reducers";
const pressReleaseAISelector = (state: RootState) => state.pressReleaseAI;

const NoData = ({text}) => {
    const classes = useStyles();
    return　<div className={classes.robot}>
        <Avatar src={IconRobot} name={""} size="medium"/>
        <Typography variant={"h6"} className={classes.nodata}>{text}</Typography>
    </div>
};

const Word = ({word}) => {
    const classes = useStyles();
    return <div className={classes.robot}>
        <Avatar src={IconRobot} name={""} size="medium"/>
        <Typography variant={"h6"} className={classes.word}>{word}</Typography>
    </div>
};


const handleScrollClick = (original) => {
    const view = document.querySelector('.fr-wrapper') as HTMLElement;
    const highlight: any = document.getElementById('highlight') as HTMLElement;
    if (highlight) highlight.style.display = 'block';

    if (view) {
        if (userAgent.indexOf('msie') != -1) {
            view.scrollTop = original.offsetTop - 150;
        } else {
            view.scrollTo({
                top: original.offsetTop - 150,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    setTimeout(function () {
        const clientRect = original.getBoundingClientRect();
        highlight.style.top = `${clientRect.top}px`;
        highlight.style.left = `${clientRect.left}px`;
        highlight.style.width = `${clientRect.width}px`;
        highlight.style.height = `${clientRect.height}px`;
    }, 500);
};

const AI: React.FC = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const highlight: HTMLElement = document.getElementById('highlight') as HTMLElement;

    if (!highlight) {
        const element = document.createElement("div");
        const inner = document.createElement("div");
        inner.className = "robot";
        element.className = 'highlight';
        element.id = 'highlight';
        element.appendChild(inner)
        document.body.appendChild(element)
    }

    const handleMouseOut = () => {
        if (highlight) highlight.style.display = 'none';
    };

    const handleCheck = () => {
        const appEditor = document.querySelectorAll('#appEditor .fr-view');
        dispatch(ActionCreators.postPressReleaseCheck.request(appEditor[0]));
    };

    return (
        <div className={classes.list} onMouseOut={handleMouseOut}>
            <Button color="primary" size="medium" variant={"outlined"} className={classes.checkButton} onClick={handleCheck}>
                <img src={IconRobotGreen} className={classes.robotImg}/><span>再チェック</span>
            </Button>
            <DesumasuItem/>
            <VariantsItem/>
            <ZenkakuItem/>
        </div>
    );
};

const DesumasuItem: React.FC = () => {

    const classes = useStyles();
    const pressReleaseAI = useSelector(pressReleaseAISelector);

    const {
        desumasu_sentence,
        desumasu_word,
    } = pressReleaseAI.check.result;

    const Item = ({number, index, key}) => {
        const original = pressReleaseAI.check.node[number] as HTMLElement;
        const clone: HTMLElement = original.cloneNode(true) as HTMLElement;
        const text = highlightWord(desumasu_word[index], clone.innerText);

        return (
            <div className={classes.item} data-number={number}
                 onMouseOver={() => handleScrollClick(original)}>
                <div className={classes.overlay}/>
                <div dangerouslySetInnerHTML={{__html: text}} className={classes.text}/>
            </div>)
    };

    // ですます
    return (
        <>
            {desumasu_sentence && desumasu_sentence.length ?
                <ul className={classes.list}>
                    {desumasu_sentence.map((target, index) => (
                        <li key={index}>
                            <Word word={`末尾に表記揺れがあります。`}/>
                            {target.map(number => (
                                <Item number={number} index={index} key={Math.random().toString()}/>
                            ))}
                        </li>
                    ))}
                </ul>
                :
                <NoData text={"表記揺れはありません"}/>
            }
        </>
    );
};


const VariantsItem: React.FC = () => {

    const classes = useStyles();
    const pressReleaseAI = useSelector(pressReleaseAISelector);

    const {
        variants_sentence,
        variants_word,
    } = pressReleaseAI.check.result;

    const Item = ({number, index}) => {
        const original = pressReleaseAI.check.node[number] as HTMLElement;
        const clone: HTMLElement = original.cloneNode(true) as HTMLElement;
        const text = highlightWords(variants_word[index], clone.innerText);
        return (
            <div key={Math.random().toString()} className={classes.item} data-number={number}
                 onMouseOver={() => handleScrollClick(original)}>
                <div className={classes.overlay}/>
                <div dangerouslySetInnerHTML={{__html: text}} className={classes.text}/>
            </div>
        )

    };

    return (
        <>
            {variants_sentence && variants_sentence.length ?
                <>
                    <ul className={classes.list}>
                        {variants_sentence.map((target, index) => {
                            const u = _.uniq(target); //同じ行番号の重複削除
                            return <li key={Math.random().toString()}>
                                <Word word={`「${variants_word[index].join('、')}」が混在しています`}/>
                                {u.map(number => (
                                    <Item number={number} index={index} key={number + index}/>
                                ))}
                            </li>
                        })}
                    </ul>
                </>
                :
                <NoData text={"ですます表記揺れはありません"}/>
            }
        </>
    );
};


const ZenkakuItem: React.FC = () => {

    const classes = useStyles();
    const pressReleaseAI = useSelector(pressReleaseAISelector);

    const {
        zenkaku_sentence,
        zenkaku_word,
    } = pressReleaseAI.check.result;


    const Item = ({number, index, key}) => {
        const original = pressReleaseAI.check.node[number] as HTMLElement;
        const clone: HTMLElement = original.cloneNode(true) as HTMLElement;
        let text = clone.innerText;
        zenkaku_word[index].map(word => {
            if (clone.innerText.indexOf(word) > -1) {
                text = highlightWord(word, text);
            }
        });

        return (
            <div key={key} className={classes.item} data-number={number}
                 onMouseOver={() => handleScrollClick(original)}>
                <div className={classes.overlay}/>
                <div dangerouslySetInnerHTML={{__html: text}} className={classes.text}/>
            </div>
        );
    };

    return (
        <>
            {zenkaku_sentence && zenkaku_sentence.length ?
                <ul className={classes.list}>
                    {zenkaku_sentence.map((target, index) => (
                        <li key={index}>
                            <Word word={`全角の「${zenkaku_word[index].join('')}」があります。`}/>
                            {target.map(number => (
                                <Item number={number} index={index} key={number + index}/>
                            ))}
                        </li>
                    ))}
                </ul>
                :
                <NoData text={"全角はありません"}/>
            }
        </>
    );
};

export default AI;

const highlightWord = (query, body) => {
    const classes = useStyles();
    const regexp = new RegExp(`(${query})`, 'gi');
    body = body.replace(regexp, '<span class="search-highlight">$1</span>');
    return body
};

const highlightWords = (queries, body) => {
    let text = body;
    queries.map(query => {
        const regexp = new RegExp(`(${query})`, 'gi');
        text = text.replace(regexp, '<span class="search-highlight">$1</span>');
    });
    return text
};


const useStyles = makeStyles({
    nodata: {
        padding: 10,
    },
    title: {
        padding: "5px 10px",
        backgroundColor: "#E2E9E5"
    },
    robot: {
        marginTop: 10,
        padding: 10,
        display: "flex",
        alignItems: "center"
    },
    word: {
        paddingLeft: 10,
    },
    list: {
        padding: 0,
    },
    item: {
        overflow: "hidden",
        cursor: "pointer",
        backgroundColor: "#fff",
        borderRadius: 4,
        border: "1px solid #ddd",
        padding: 10,
        margin: 10,
        fontSize: 13,
        position: "relative",
    },
    overlay: {
        position: "absolute",
        content: "aaa",
        top: 0,
        left: 0,
        backgroundColor: "transparent",
        width: "100%",
        height: "100%",
    },
    text: {
        fontSize: 13
    },
    avater: {
        display: "flex"
    },
    checkButton: {
        margin: "10px 10px 0 auto",
        display: "flex",
    },
    robotImg: {
        marginRight: 10
    }
});

