import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Button} from "@material-ui/core";

// material-ui
import { Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//component
import SettingTitle from "../../Common/SettingTitle";
import TableFrame from "../../Common/TableFrame";
import {AddRadarWordDialog} from "../../Radar/Dialog";
import {RadarWordTable} from "./Table";
import DisabledOption from "../../Common/DisabledOption";

//model
import {OptionInfoModel} from "../../../model/CompanyModel";

// state
import {RootState} from "../../../reducers";


const radarWordsSelector = (state: RootState) => state.radar.words;
const OptionInfoSelector = (state: RootState) => state.company.option;

const RadarSetting: React.FC = () => {
    const classes = useStyles();
    const optionInfo: OptionInfoModel = useSelector(OptionInfoSelector);
    const radarOptionInfo = optionInfo.radar;
    const radarWords = useSelector(radarWordsSelector);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const isWordLimit = radarWords.length >= radarOptionInfo.quantity;
    const buttonDisabled = !radarOptionInfo.isAvailable || isWordLimit;

    return (
        <>
            <SettingTitle text="記事化レーダー検索ワード設定"/>
            <Typography variant="caption">
                記事化レーダーの検索ワードを設定・編集することができます。<br/>
                既存の検索ワードを編集した場合、翌日から変更の結果が反映されます。
            </Typography>
            {radarOptionInfo.isAvailable ?
                <>
                    <Typography variant="h5" className={classes.qtyText}>
                        現在の記事化レーダー検索ワード契約数: {radarOptionInfo.quantity}ワード
                    </Typography>

                    <TableFrame>
                        <RadarWordTable list={radarWords}/>
                    </TableFrame>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => setIsDialogOpen(true)}
                        disabled={buttonDisabled}
                    >
                        検索ワードを追加する
                    </Button>
                    <AddRadarWordDialog
                        isOpen={isDialogOpen}
                        closeHandle={() => setIsDialogOpen(false)}
                    />
                </>
                :
                <div className={classes.disabledOption}>
                    <DisabledOption optionName={"記事化レーダー"} />
                </div>
            }
        </>
    );
}
export default RadarSetting;

const useStyles = makeStyles({
    button: {
        marginTop: 15
    },
    qtyText: {
        marginTop: 5
    },
    disabledOption: {
        marginTop: 20
    },
})
