enum AnalyticsActionType {
    // サマリー
    GET_ANALYTICS_SUMMARY_REQUEST = "GET_ANALYTICS_SUMMARY_REQUEST",
    GET_ANALYTICS_SUMMARY_SUCCEEDED = "GET_ANALYTICS_SUMMARY_SUCCEEDED",
    GET_ANALYTICS_SUMMARY_FAILED = "GET_ANALYTICS_SUMMARY_FAILED",
    GET_ANALYTICS_DETAIL_REQUEST = "GET_ANALYTICS_DETAIL_REQUEST",
    GET_ANALYTICS_DETAIL_SUCCEEDED = "GET_ANALYTICS_DETAIL_SUCCEEDED",
    GET_ANALYTICS_DETAIL_FAILED = "GET_ANALYTICS_DETAIL_FAILED",
// 開封率
    GET_OPEN_MAIL_REQUEST = "GET_OPEN_MAIL_REQUEST",
    GET_OPEN_MAIL_SUCCEEDED = "GET_OPEN_MAIL_SUCCEEDED",
    GET_OPEN_MAIL_FAILED = "GET_OPEN_MAIL_FAILED",
}

export default AnalyticsActionType;

