import React from "react";
import { RouteComponentProps } from "react-router-dom";

// components
import AppLayout from "../../components/Layout/App";
import Features from "../../components/Features";


interface Props extends RouteComponentProps<void> { }

function FeaturesPage(props: Props) {

	return (
		<AppLayout>
			<Features />
		</AppLayout>
	);
}

export default FeaturesPage;

