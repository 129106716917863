import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
} from "@material-ui/core";

//component
import { BasicDropzone } from '../../../Common/Dropzone'

//action
import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";

import { RootState } from "../../../../reducers";

//model
import { uploadActionDispatcherModel } from '../../../../model/DropzoneModel'

//style
import style from '../dialog.module.scss'

// img
import UploadIcon from '../../../../assets/upload_icon.svg'

const pressKitSelector = (state: RootState) => state.pressKit;
const groupSelector = (state: RootState) => state.group;

const FileUploadDialog: React.FC = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const pressKit = useSelector(pressKitSelector);
    const group = useSelector(groupSelector);

    const handleClose = () => {
        dispatch(PressKitActionCreators.setPressKitUploadDialog.request(false));
    };

    const uploadActionDispatcher: uploadActionDispatcherModel = files => {
        files.forEach((file, index) => {
            const actionOrder = index
            const name = file.name
            dispatch(PressKitActionCreators.postPressKitFileUpload.request({ file, name, actionOrder }))
            handleClose()
        })
    }

    return (
        <Dialog open={pressKit.upload.dialog} onClose={handleClose}>
            <DialogTitle className={style.dndHeader}>ファイルをアップロード</DialogTitle>
            <DialogContent className={classes.content}>
                <BasicDropzone
                    uploadActionDispatcher={uploadActionDispatcher}
                    text='アップロード'
                    img={UploadIcon}
                />
            </DialogContent>
        </Dialog>
    );
}

export default FileUploadDialog

const useStyles = makeStyles({
    content: {
        padding: 30
    }
});
