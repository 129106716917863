import ActionType from './ActionType';
import { createAsyncAction } from 'typesafe-actions';

//model
import { TicketQtyModel } from "../../model/TicketModel";

export const getTicketQty = createAsyncAction(
	ActionType.GET_TICKET_QTY_REQUEST,
	ActionType.GET_TICKET_QTY_SUCCEEDED,
	ActionType.GET_TICKET_QTY_FAILED,``
)<void, TicketQtyModel, Error>();
