import React from 'react';

//material-ui
import {makeStyles} from "@material-ui/core/styles";
import {Button, TableCell, TextField, Typography} from "@material-ui/core";

//model
import {RadarWordModel} from "../../../../model/RadarModel";

interface Props {
    wordInfo: RadarWordModel,
    word: string,
    excludeWord: string
    wordChangeHandler
    excludeWordChangeHandler
    handleUpdate: () => void
    handleEditCancelClick: () => void
}

export const TableItemContentForm: React.FC<Props> = (props: Props) => {
    const classes = useStyle()
    const {
        wordInfo,
        word,
        excludeWord,
        wordChangeHandler,
        excludeWordChangeHandler,
        handleUpdate,
        handleEditCancelClick
    } = props;

    const isWordEdit = (wordInfo: RadarWordModel) => {
        return wordInfo.radar_word !== word || wordInfo.exclude_word !== excludeWord
    }

    return (
        <>
            <TableCell>{''}</TableCell>
            <TableCell>
                <TextField placeholder="検索ワード"
                           value={word}
                           variant="outlined"
                           onChange={wordChangeHandler}
                />
                <Typography className={classes.notice}>※ スペース区切りで入力してください</Typography>
            </TableCell>
            <TableCell>
                <TextField placeholder="除外ワード"
                           value={excludeWord}
                           variant="outlined"
                           onChange={excludeWordChangeHandler}
                />
                <Typography className={classes.notice}>※ スペース区切りで入力してください</Typography>
            </TableCell>
            <TableCell>
                <div className={classes.menu}>
                    <Button
                        className={classes.button}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleUpdate}
                        disabled={!word || !isWordEdit(wordInfo)}
                    >
                        保存
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.redButton}
                        onClick={handleEditCancelClick}
                    >
                        キャンセル
                    </Button>
                </div>
            </TableCell>
        </>
    )
}

const useStyle = makeStyles({
    button: {
        marginRight: 10
    },
    redButton: {
        background: "#E95050"
    },
    menu: {
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    notice: {
        fontSize: 11
    }
})
