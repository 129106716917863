enum TopicActionType {
	GET_PROCEEDINGS_TOPICS_REQUEST = "GET_PROCEEDINGS_TOPICS_REQUEST",
	GET_PROCEEDINGS_TOPICS_SUCCEEDED = "GET_PROCEEDINGS_TOPICS_SUCCEEDED",
	GET_PROCEEDINGS_TOPICS_FAILED = "GET_PROCEEDINGS_TOPICS_FAILED",

	POST_TOPIC_WORD_REQUEST = "POST_TOPIC_WORD_REQUEST",
	POST_TOPIC_WORD_SUCCEEDED = "POST_TOPIC_WORD_SUCCEEDED",
	POST_TOPIC_WORD_FAILED = "POST_TOPIC_WORD_FAILED",

	SET_SELECTED_TOPIC_WORD = "SET_SELECTED_TOPIC_WORD",

	SET_TOPIC_LOADING = "SET_TOPIC_LOADING",
}

export default TopicActionType;

