import ErrorActionType from '../actions/Error/ActionType';
import ErrorAction from '../actions/Error/Action';
import { ErrorModel } from "../model/ErrorModel";
import createReducer from "./createReducer";


const initialStateError: ErrorModel = {
	message: "",
	dialog: false
};

export const error = createReducer<ErrorModel>(initialStateError, {
	[ErrorActionType.SET_ERROR_MESSAGE](state: ErrorModel, action: ErrorAction) {
		return {
			...state,
			message: action.payload
		}
	},
	[ErrorActionType.SET_ERROR_DIALOG](state: ErrorModel, action: ErrorAction) {
		return {
			...state,
			dialog: action.payload
		}
	},
	[ErrorActionType.CLEAN_UP_ERROR_STATE](state: ErrorModel, action: ErrorAction) {
		return {
			...initialStateError
		}
	},
});
