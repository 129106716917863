import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// component
import { Button, makeStyles, Typography } from '@material-ui/core';
import SwitchLayoutRadios from './SwitchLayoutRadios';
import CardLayout from './CardLayout';
import ListLayout from './ListLayout';
import HelpFab from '../../Common/HelpFab';
import TrialEnd from '../../Common/TrialEnd';
import OverMember from '../../Common/OverMember';

// config
import { LAYOUT_TYPE_CARD } from '../../../config/layout_type';

// img
import ImageBriefing from '../../../assets/guide/briefing.svg';

// model
import { BriefingListModel, PageTypeModel } from '../../../model/BriefingModel';

// state
import { RootState } from '../../../reducers';
const groupSelector = (state: RootState) => state.group;
const companySelector = (state: RootState) => state.company;
const isOverMemberSelector = (state: RootState) => state.member.isOverMember;

interface Props {
    list: BriefingListModel,
    pageType: PageTypeModel,
}

const List: React.FC<Props> = ({ list, pageType }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const group = useSelector(groupSelector);
    const { isFreePlanExpired } = useSelector(companySelector);
    const isOverMember = useSelector(isOverMemberSelector);
    const [switchLayout, setSwitchLayout] = useState(LAYOUT_TYPE_CARD);

    const handleLayoutSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSwitchLayout(value);
    }

    return (
        <section className={classes.container}>
            <div className={classes.title}>
                <h2 className={classes.titleText}>ブリーフィング</h2>
                <HelpFab url={'/help/briefing#1'} />
            </div>
            {!isFreePlanExpired && !isOverMember &&
                <div className={classes.option}>
                    <Button variant="contained" color="primary" className={classes.newButton}
                        onClick={() => dispatch(push(`/briefing/${group.selectedId}/select/`))}>
                        新規作成
                        </Button>
                    <SwitchLayoutRadios type={switchLayout} handleChange={handleLayoutSwitch} />
                </div>
            }
            {// トライアル期間終了
                isFreePlanExpired && <TrialEnd />
            }
            {// メンバー数超過
                !isFreePlanExpired && isOverMember && <OverMember />
            }
            {!isFreePlanExpired && !isOverMember && (
                list.results.length ?
                    <div className={classes.contents}>
                        {switchLayout === LAYOUT_TYPE_CARD ?
                            <CardLayout list={list.results} pageType={pageType} />
                            :
                            <ListLayout list={list.results} pageType={pageType} />}
                    </div>
                    :
                    <div className={classes.noData}>
                        <div className={classes.noDataImg}><img src={ImageBriefing} /></div>
                        <Typography variant="h4" gutterBottom>
                            ブリーフィングシートを作成してみましょう
                        </Typography>
                        <Button variant="contained" color="primary" size={"large"} className={classes.createButton}
                            onClick={() => {
                                dispatch(push(`/briefing/${group.selectedId}/select/`))
                            }}>
                            新規作成する
                        </Button>
                    </div>
            )}
        </section>
    )
}

export default List;

const useStyles = makeStyles({
    container: {
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1000,
    },
    contents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        marginBottom: 20,
        color: '#616763',
        display: 'flex',
    },
    titleText: {
        fontSize: 22,
        padding: '5px 0',
    },
    forms: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    option: {
        display: 'flex',
        marginLeft: 'auto',
    },
    noData: {
        textAlign: "center",
        margin: '0 auto',
    },
    noDataImg: {
        margin: "30px auto",
        width: 250,
    },
    createButton: {
        marginTop: 20,
        width: 400,
    },
    newButton: {
        marginRight: 15,
        height: 42,
    },
})
