import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Button, Tooltip} from "@material-ui/core";

import * as PressKitActionCreators from "../../../../actions/PressKit/ActionCreator";

import {RootState} from "../../../../reducers";
import {
    PressKitFirestoreModel,
    SortHistoryContentModel
} from "../../../../model/PressKitModel";

import style from "./pressKitSortButton.module.scss";

//img
import IconSort from "../../../../assets/icon_sort.svg";
import IconSortDisabled from "../../../../assets/icon_sort_disabled.svg";
import IconDone from "../../../../assets/icon_done.svg";
import {makeStyles} from "@material-ui/core/styles";

const sortingSelector = (state: RootState): boolean => state.pressKit.edit.sorting;
const pressKitSortingSelector = (state: RootState): boolean => state.pressKit.edit.pressKitSorting;
const firestorePressKitsSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.firestore.pressKits;
const viewPressKitSelector = (state: RootState): Array<PressKitFirestoreModel> => state.pressKit.edit.viewPressKit;
const sortHistorySelector = (state: RootState): Array<SortHistoryContentModel> => state.pressKit.edit.sortHistory;

const useStyles = makeStyles({
    root: {
        height: 29,
        minWidth: 29
    }
})

export const PressKitSortButton: React.FC<any> = () => {
    const fileSorting = useSelector(sortingSelector);
    const pressKitSorting = useSelector(pressKitSortingSelector);
    const firestorePressKits = useSelector(firestorePressKitsSelector);
    const viewPressKit = useSelector(viewPressKitSelector);
    const sortHistory = useSelector(sortHistorySelector);
    const dispatch = useDispatch();

    const classes = useStyles();

    const cleanUp = () => {
        dispatch(PressKitActionCreators.setPressKitSorting(false))
        dispatch(PressKitActionCreators.setFileSorting(false))
        dispatch(PressKitActionCreators.setViewFile([]))
        dispatch(PressKitActionCreators.setViewPressKit([]))
        dispatch(PressKitActionCreators.clearSortHistory())
    }

    const handleSortStart = () => {
        dispatch(PressKitActionCreators.setViewPressKit(firestorePressKits))
        dispatch(PressKitActionCreators.setPressKitSorting(true))
    }

    const handleSortEnd = () => {
        const isNotSorted = sortHistory.length == 0 ? true : false;
        if (isNotSorted) {
            return cleanUp()
        }
        const data = {
            sortedList: viewPressKit,
            sortHistory
        }
        dispatch(PressKitActionCreators.firestorePressKitSort.request(data))
    }

    const handler = pressKitSorting ? handleSortEnd : handleSortStart;
    const variant = pressKitSorting ? 'contained' : 'outlined';
    const tooltipText = pressKitSorting ? '順番を適用する' : 'フォルダを並べ替える';
    const sortIcon = fileSorting ? IconSortDisabled : IconSort;
    const icon = pressKitSorting ? IconDone : sortIcon;


    useEffect(() => {
        cleanUp()
    }, [])

    return (
        <div className={style.buttonContainer}>
            <Tooltip title={tooltipText} placement='top'>
                <span>
                    <Button
                        className={classes.root}
                        onClick={handler}
                        variant={variant}
                        size='small'
                        color='primary'
                        disabled={fileSorting}
                    >
                        <img src={icon}/>
                    </Button>
                </span>
            </Tooltip>
        </div>
    )
};
