import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/editor/newPage/image01.png';
import Image02 from '../../../assets/help/editor/newPage/image02.png';

const Block = () => {
	return (
		<div className={style.box}>
			<Title title={"3.改ページ"}/>
			<Typography gutterBottom variant={"body1"}>
				メニューの「現在の場所に改ページを挿入」ボタンから改ページコードを挿入することができます。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>

			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
		</div>
	);
};

export default Block
