import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import * as React from "react";

// A theme with custom primary and secondary color.
// It's optional.
export const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#c8e6c9",
            main: "#2eb964",
            dark: "#388e3c",
            contrastText: "#fff",
        },
        secondary: {
            light: "#fff9c4",
            main: "#f9a825",
            dark: "#ff7d33",
            contrastText: "#fff",
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            "游ゴシック体",
            "YuGothic",
            "游ゴシック Medium",
            "Yu Gothic Medium",
            "游ゴシック",
            "Yu Gothic",
            "sans-serif"
        ].join(','),
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: "#ddd"
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 12
            },
            popper: {
                fontSize: 18
            },
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                }
            },
            outlined: {
                border: '1px solid #E2E9E5',
                background: '#fff',
                //opacity: '0.7',
                '&:hover': {
                    opacity: 0.9
                }
            },
            sizeLarge: {
                fontSize: 14,
                padding: '5px 12px',
                minHeight: 40,
                fontWeight: "bold",
                lineHeight: 2
            },
            sizeSmall: {
                fontSize: '1.2rem',
            },
            label: {
                whiteSpace: 'nowrap'
            },
            root: {
                boxSizing: "border-box",
                color: '#909491',
                textTransform: "none",
                fontSize: 14
            },
        },
        MuiInputBase: {
            input: {
                background: '#fff',
                fontSize: 14,
                lineHeight: '1.8',
                color: '#333',
                '&:disabled': {
                    background: "#f5f5f5"
                }
            },
            root: {
                background: '#fff',
            }
        },
        MuiInputLabel: {
            root: {
                color: '#6d7470'
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    content: ""
                },
                '&:after': {
                    borderBottom: 'none'
                }
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
            }
        },
        MuiButtonBase: {
            root: {
                fontSize: 14,
                color: '#2EB964'
            },
        },
        MuiLink: {
            root: {
                fontSize: 14,
                textDecoration: "underline"
            },
        },
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: '#F3F7F4',
                }
            },
            button: {
                '&:hover': {
                    backgroundColor: '#E3F5EA',
                }
            },
            gutters: {
                paddingRight: 10,
                paddingLeft: 10
            },
        },
        MuiListItemText: {
            primary: {
                color: '#2EB964'
            },
            root: {
                margin: 0
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 30,
                marginRight: 10,
                display: 'flex',
                alignItems: "center"
            }
        },
        MuiCard: {
            root: {
                border: "1px solid #e2e9e5",
                boxShadow: 'none',
            }
        },
        MuiTypography: {
            root: {
                color: '#3c3e3c',
                fontWeight: 'bold',
            },
            caption: {
                fontSize: 13,
            },
            h1: {
                color: '#6d7470',
                fontSize: 22,
                fontWeight: 'bold',
                marginBottom: 10
            },
            h2: {
                fontSize: 22,
                fontWeight: 'bold',
                marginBottom: 10,
            },
            h4: {
                lineHeight: 1.4
            },
            body1: {
                fontSize: 14
            },
            body2: {
                fontSize: 12
            },
            h5: {
                fontSize: 17,
                fontWeight: 'bold',
                color: '#6D7470',
            },
            h6: {
                fontSize: 14,
                fontWeight: 'bold',
                color: '#6D7470',
            },
            gutterBottom: {
                marginBottom: 20
            },
            colorError: {
                color: "#E95050"
            }
        },
        MuiChip: {
            root: {
                fontSize: 13,
                height: 22
            },
            deleteIcon: {
                height: 15
            }
        },
        MuiTabs: {
            root: {
                borderBottom: '1px solid #E2E9E5',
                background: '#fff'
            }
        },
        MuiTab: {
            root: {
                minWidth: '9.6rem',
                fontSize: 14,
                '@media (min-width: 960px)': {
                    fontSize: 14,
                }
            },
            textColorPrimary: {
                color: '#6d7470'
            }
        },
        MuiIconButton: {
            // root: {
            // 	minWidth: 30,
            // 	minHeight: 30,
            // 	maxWidth: 30,
            // 	maxHeight: 30,
            // }
            edgeEnd: {
                marginRight: 0
            },
        },
        MuiOutlinedInput: {
            multiline: {
                padding: 10,
                lineHeight: 1.4
            },
            root: {
                '&:focused': {
                    "&$focused": {
                        borderColor: "#E2E9E5"
                    }
                }
            },
            notchedOutline: {
                borderColor: "#E2E9E5"
            },
            input: {
                padding: 16
            },
        },
        MuiMenuItem: {
            root: {
                minWidth: '10rem',
                fontSize: '1.4rem',
                minHeight: 'auto',
                color: '#6D7470'
            }
        },
        MuiSelect: {
            root: {
                display: "flex",
            },
            select: {
                backgroundColor: 'inherit',
                lineHeight: 1.5,
                minHeight: 25
            },
            icon: {
                top: "calc(50% - 7px)",
                color: "#2EB964"
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 1000,
                overflow: "hidden"
            }
        },
        MuiDialogActions: {
            root: {
                borderTop: '1px solid #E2E9E5'
            },
            // spacing: {
            // 			// 	'& > * + *': {
            // 			// 		marginLeft:  8,
            // 			// 	},
            // 			// }
        },
        MuiDialogTitle: {
            root: {
                minHeight: 62,
                borderBottom: '1px solid #E2E9E5;',
                background: '#F3F7F4',
                display: 'flex',
                alignItems: 'center',
                padding: '0 20px'
            },
        },
        MuiTextField: {
            root: {
                width: '100%'
            }
        },
        MuiFormHelperText: {
            root: {
                marginLeft: 0,
                color: "#E95050",
                fontSize: 13
            },
            contained: {
                marginLeft: 0,
            }
        },
        MuiTable: {
            root: {
                overflowX: "scroll",
                whiteSpace: "nowrap",
                tableLayout: "fixed"
            }
        },
        MuiTableHead: {
            root: {
                background: "#fff"
            },
        },
        MuiTableBody: {
            root: {
                background: "#fff"
            },
        },
        MuiTableCell: {
            root: {
                fontSize: 13,
                whiteSpace: "normal"
            },
            head: {
                fontSize: 13,
            }
        },
        MuiExpansionPanel: {
            root: {
                border: '1px solid #E9EFEB',
                borderRadius: 4,
                '&$expanded': {
                    margin: 0
                }
            }
        },
        MuiExpansionPanelDetails: {
            root: {
                display: "block",
                padding: '10px 0'
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                flexDirection: 'row-reverse',
                padding: '0 10px 0 10px',
                minHeight: 44,
                '&$expanded': {
                    minHeight: 44
                }
            },
            content: {
                alignItems: 'center',
                paddingLeft: '10px',
                margin: 0,
                '&$expanded': {
                    margin: 0,
                    padding: 10
                }
            },
            expandIcon: {
                padding: 0
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: 14,
            }
        },
        MuiCheckbox: {},
        MuiStepIcon: {
            root: {
                width: 28,
                height: 28,
                color: '#e3f5ea'
            },
            text: {
                fontSize: 13
            }
        },
        MuiStepLabel: {
            label: {
                color: "#D5F1E0",
                fontWeight: "bold",
                fontSize: 16,
                '&$active': {
                    color: "#2eb964",
                    fontWeight: "bold",
                },
                '&$completed': {
                    color: "#2eb964",
                    fontWeight: "bold",
                }
            },
        },
        MuiAvatar: {
            img: {
                width: "100% !important"
            }
        },
        MuiFab: {
            root: {
                boxShadow: "none"
            }
        },
        MuiAccordion: {
            root: {
                boxShadow: "none",
                borderRadius: 5,
                "&:before": {
                    display: "none",
                },
                "&$expanded": {
                    margin: 0,
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                minHeight: 42,
                padding: 0,
                "&$expanded": {
                    minHeight: 42,
                },
            },
            content: {
                display: "flex",
                alignItems: "center",
                minHeight: 42,
                margin: 0,
                "&$expanded": {
                    margin: 0,
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: 0,
            },
        },
    },

});

function withRoot(Component: any) {
    function WithRoot(props: object) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <MuiThemeProvider theme={theme}>
                {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />
            </MuiThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
