// actions
import CompanyActionType from '../actions/Company/ActionType';
import CompanyAction from '../actions/Company/Action';

// models
import { CompanyModel } from "../model/CompanyModel";

// state
import createReducer from "./createReducer";

const initialState: CompanyModel = {
	name: "",
	postal_code:"",
	address: "",
	tel: "",
	fax: "",
	email: "",
	url: "",
	img: "",
	is_public_page: false,
	is_review_skip: false,
	can_change_plan: false,
	can_cancel_plan: false,
	plan: {
		id: 0,
		code: 0,
		name: "",
		amount: 0,
		member_limit: 0,
		project_limit: 0,
		media_limit: 0,
		folder_limit: 0,
		file_size_limit: 0,
		default_ticket: 0,
		is_review_skip_limit: false,
		edit_history_limit: false,
		export_html_limit: false,
		public_page_limit: false,
		briefing_content_limit: 0,
		briefing_mail_limit: 0,
	},
	groups: [],
	total_member: 0,
	option: {
		radar:{
			isAvailable: false,
			quantity: 0,
			validFromNext: 0
		},
		member:{
			isAvailable: false,
			quantity: 0,
			validFromNext: 0
		}
	},
	isFreePlanExpired: false,
};

export const company = createReducer<CompanyModel>(initialState, {
	[CompanyActionType.GET_COMPANY_SUCCEEDED](state: CompanyModel, action: CompanyAction) {
		const result:any = action.payload
		const detail = result.length ? result[0]: state
		return {
			...detail,
			option:{
				radar:{
					isAvailable: detail.option.radar.is_available,
					quantity: detail.option.radar.quantity,
					validFromNext: detail.option.radar.valid_from_next,
				},
				member:{
					isAvailable: detail.option.member.is_available,
					quantity: detail.option.member.quantity,
					validFromNext: detail.option.member.valid_from_next,
				}
			},
			isFreePlanExpired: state.isFreePlanExpired,
		}
	},
	[CompanyActionType.POST_COMPANY_SUCCEEDED](state: CompanyModel, action: CompanyAction) {
		const result:any = action.payload
		return {
			...state,
			...result,
		}
	},
	[CompanyActionType.PATCH_COMPANY_SUCCEEDED](state: CompanyModel, action: CompanyAction) {
		const result:any = action.payload
		return {
			...state,
			...result,
		}
	},
	[CompanyActionType.PATCH_LOGO_SUCCEEDED](state: CompanyModel, action: CompanyAction) {
		const result:any = action.payload
		return {
			...state,
			img: result.img
		}
	},
	[CompanyActionType.SET_IS_FREE_PLAN_EXPIRED](state: CompanyModel, action: CompanyAction) {
		return {
			...state,
			isFreePlanExpired: action.payload,
		}
	}
});
