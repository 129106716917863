import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/pressKit/send/image01.png';
import Image02 from '../../../assets/help/pressKit/send/image02.png';
import Image03 from '../../../assets/help/pressKit/send/image03.png';

const Send = () => {
	return (
		<div className={style.box}>
			<Title title={"3. プレスリリースに添付"}/>
			<Typography gutterBottom variant={"body1"}>
				配信設定画面で、「プレスキットを選択」から関連プレスキットを選択します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				選択すると、本文にURLが記載され、プレスリリースとセットで配信されます。
			</Typography>
			<div className={style.img}>
				<img src={Image03}/>
			</div>
		</div>
	);
};

export default Send;
