import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// component
import { Button, makeStyles, Box } from '@material-ui/core';
import { Card, CardContent, Typography } from '@material-ui/core';
import AnswerTable from '../../Answer/Table';

// style
import style from './template.module.scss';

// actions
import * as BriefingActionCreators from '../../../../actions/Briefing/ActionCreator';

// model
import { BriefingDetailModel } from '../../../../model/BriefingModel';

// state
import { RootState } from '../../../../reducers';
const BriefingGroupSelector = (state: RootState) => state.group;

interface ListLayoutProps {
    list: BriefingDetailModel[],
}

const ListLayout: React.FC<ListLayoutProps> = ({ list }) => {
    return (
        <ul className={style.card}>
            {list.map((item, index) => (
                <ListLayoutItems item={item} key={index} />
            ))}
        </ul>
    )
}

interface ListLayoutItemProps {
    item: BriefingDetailModel,
}

const ListLayoutItems: React.FC<ListLayoutItemProps> = ({ item }) => {
    const classes = useStyles();
    const group = useSelector(BriefingGroupSelector);
    const dispatch = useDispatch();

    const handleSetTemplate = () => {
        dispatch(BriefingActionCreators.setBriefingTemplate.request({ body: item.body, id: "" }));
        dispatch(push(`/briefing/${group.selectedId}/create`));
    }


    return (
        <li className={style.list}>
            <Card className={style.inner}>
                <CardContent className={style.card_footer}>
                    <Box className={classes.cardInfo}>
                        <Typography variant='h6' className={style.author}>{item.body.name}</Typography>
                    </Box>
                    <div className={style.overlay}>
                        <Button className={style.template_open} onClick={handleSetTemplate}>
                            このテンプレートで作成する
                        </Button>
                    </div>
                    <div className={style.bg}>
                        <AnswerTable body={item.body} id={item.id} />
                    </div>
                </CardContent>
            </Card>
        </li>
    )
}

export default ListLayout;

const useStyles = makeStyles({
    author: {
        fontSize: 12,
    },
    cardInfo: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        background: '#f8fbf9',
        width: '100%',
        borderBottom: '1px solid #e2e9e5',
        padding: '8px 16px',
    },
    subHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingRight: 20,
    },
    cardInner: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
    },
    avator: {
        width: 28,
        marginRight: 10,
    },
})
