import React from "react";
import {NavLink} from "react-router-dom";
import {Typography, Button} from "@material-ui/core";

const ApplyComplete: React.FC = () => {
  return (
    <>
      <Typography variant="h1" component="h1" gutterBottom>
        申請が完了しました
      </Typography>
      <Typography variant="body1" gutterBottom>
        担当より通常1営業日以内にご連絡いたします。
        <br />
        今しばらくお待ちいただけますようお願い申し上げます。
      </Typography>
      <NavLink exact to={"/setting/plan"}>
        <Button variant="contained" size="large" color="primary">
          プラン設定へ戻る
        </Button>
      </NavLink>
    </>
  );
};

export default ApplyComplete;
