import React, {useEffect, useState} from "react";
import { Theme, makeStyles, withTheme } from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import { RouteComponentProps } from "react-router-dom";


// action
import * as PressReleaseActionCreators from "../../actions/PressRelease/ActionCreator";

// components
import DiffEditor from "../../components/PressRelease/Diff/";
import Layout from "./Layout";
// state
import {RootState} from "../../reducers";
import {PAGE_DIFF} from "../../config/page_type";
import {checkEditRole, checkReviewRole} from "../../util/checkAuthority";

const meSelector = (state: RootState) => state.me;
const pressReleaseSelector = (state: RootState) => state.pressRelease;

type PageProps = {} & RouteComponentProps<{groupId:string, pressReleaseId: string, historyId: string}>;

const DiffPage: React.FC<PageProps> = props => {
	const dispatch = useDispatch();
	const pressRelease = useSelector(pressReleaseSelector);
	const me = useSelector(meSelector);

	useEffect(()=> {
		const pressReleaseId = props.match.params.pressReleaseId
		if (pressReleaseId){
			dispatch(PressReleaseActionCreators.getPressReleaseDetails.request({press_id: pressReleaseId}));
		}

		const historyId = props.match.params.historyId
		if (historyId){
			dispatch(PressReleaseActionCreators.getPressReleaseDiffHistoryDetails.request({
				press_id: pressReleaseId,
				history_id: historyId
			}));
		}

		dispatch(PressReleaseActionCreators.setPressReleasePageType.request(PAGE_DIFF));
	}, []);

	useEffect(()=> {
		if (me.uuid && pressRelease.detail.fetched === true) {
			dispatch(PressReleaseActionCreators.setPressReleaseEditRole.request(checkEditRole(pressRelease, me)));
			dispatch(PressReleaseActionCreators.setPressReleaseReviewRole.request(checkReviewRole(pressRelease.detail.review.member, me)));
		}
	}, [me, pressRelease.detail, pressRelease.creators]);

	return (
		<Layout page={PAGE_DIFF} panel={false}>
			<DiffEditor/>
		</Layout>
	);
}

export default withTheme(DiffPage);
