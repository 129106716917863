import React from 'react';
import Create from "./Create";
import Confirm from "./Confirm";
import Answer from "./Answer";

//style
import style from "../help.module.scss";


const Briefing = () => {
    return (
        <article className={style.root}>
            <section><a id="1" className={style.anchor}/><Create/></section>
            <section><a id="2" className={style.anchor}/><Confirm/></section>
            <section><a id="3" className={style.anchor}/><Answer/></section>
        </article>
    );
};

export default Briefing;

