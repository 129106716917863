import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import TextField from "../Field/TextField";
import { Values, Errors } from "../../model/FormModel";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles'
import style from "./form.module.scss";

const targetNameSelector = state => state.pressKit.edit.targetName

const validate = (values: Values) => {
  const errors: Errors = {}
  const requireFields = [
    'nameChange',
  ]
  requireFields.forEach(field => {
    if (!values[field]) {
      errors[field] = ''
    }
  })
  return errors
}
/*
const useStyle = makeStyles({
  button: {
    display: 'none'
  }
})
*/

interface NameChangeFormProps extends InjectedFormProps {
  width: number,
}
const NameChangeForm: React.FC<InjectedFormProps> = props => {

  const useStyle = makeStyles({
    form: {
      width: props.width,
    },
    button: {
      display: 'none'
    }
  })

  const classes = useStyle()
  const targetName = useSelector(targetNameSelector)
  const { handleSubmit, error, pristine, submitting } = props

  useEffect(() => {
    props.change('nameChange', targetName)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      {error && <p>{error}</p>}
       <div className={classes.form}>
        <Field
          name="nameChange"
          type="text"
          component={TextField}
        />
        <Button
          variant="contained"
          size="medium"
          type="submit"
          color="primary"
          disabled={pristine || submitting}
          className={classes.button}
        >
          作成
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  validate,
  form: 'NAME_CHANGE',
})(NameChangeForm)
