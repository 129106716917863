import React from 'react';
import { LAYOUT_TYPE_LIST, LAYOUT_TYPE_CARD } from '../../../../config/layout_type';
import { FormControl, RadioGroup, Radio } from '@material-ui/core';

// img
import IconListOff from '../../../../assets/switch/icon_list_off.svg';
import IconListOn from '../../../../assets/switch/icon_list_on.svg';
import IconCardOff from '../../../../assets/switch/icon_card_off.svg';
import IconCardOn from '../../../../assets/switch/icon_card_on.svg';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'block',
        marginLeft: 'auto',
        width: 80,
    }
})

export default function SwitchLayout({ type, handleChange }) {
    const classes = useStyles();
    return (
        <FormControl>
            <RadioGroup className={classes.root}>
                <Radio
                    checked={type === LAYOUT_TYPE_LIST}
                    onChange={handleChange}
                    value={LAYOUT_TYPE_LIST}
                    icon={<img src={IconListOff} />}
                    checkedIcon={<img src={IconListOn} />}
                />
                <Radio
                    checked={type === LAYOUT_TYPE_CARD}
                    onChange={handleChange}
                    value={LAYOUT_TYPE_CARD}
                    icon={<img src={IconCardOff} />}
                    checkedIcon={<img src={IconCardOn} />}
                />
            </RadioGroup>
        </FormControl>
    );
}
