import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/pressKit/download/image01.png';

const Create = () => {
	return (
		<div className={style.box}>
			<Title title={"2. ダウンロードページ"}/>
			<Typography gutterBottom variant={"body1"}>
				メディアにURLをシェアするなど、自由にお使いいただけます。<br/>
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
		</div>
	);
};

export default Create
