import React from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

// style
import style from "../detail_info.module.scss";

//components
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// state
import {RootState} from "../../../../../../reducers";
const ticketQtySelector = (state: RootState) => state.ticket.qty;

interface PlanAndTicketProps {
  planName: string;
}

const PlanAndTicket: React.FC<PlanAndTicketProps> = ({planName}) => {
  const classes = useStyles();
  const {default: freeTicket, non_default: paidTicket} = useSelector(
    ticketQtySelector
  );
  const totalAvailableTicket = freeTicket.available + paidTicket.available;
  const totalReservedTicket = freeTicket.reserved + paidTicket.reserved;

  return (
    <section className={style.item}>
      <p className={style.title}>プラン&配信数</p>
      <div className={style.inner}>
        <Typography variant="body1">現在のプラン： {planName}プラン</Typography>

        <div className={classes.button}>
          <NavLink to={"/setting/plan/"}>
            <Button variant="contained" color="primary" size="large">
              プランの変更
            </Button>
          </NavLink>
        </div>

        <Typography variant="body1">
          今月の残り配信数： {totalAvailableTicket}回<br />
          予約中： {totalReservedTicket}
        </Typography>
      </div>
    </section>
  );
};

export default PlanAndTicket;

const useStyles = makeStyles({
  button: {
    textAlign: "center",
    margin: "10px 0 20px"
  }
});
