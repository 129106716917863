import {takeLatest, put, call} from "redux-saga/effects";
import {fetchPost} from "./fetch";

// actions
import ContactActionType from "../actions/Contact/ActionType";
import * as ActionCreators from "../actions/Contact/ActionCreator";

function* postContact(
  action: ReturnType<typeof ActionCreators.postContact.request>
) {
  const {successHandler, failedHandler, ...postValue} = action.payload;

  try {
    const [data, error] = yield call(fetchPost, `contact`, postValue);

    if (data && !error) {
      yield put(ActionCreators.postContact.success());
      successHandler();
    } else {
      yield put(ActionCreators.postContact.failure(error));
      failedHandler();
    }
  } catch (e) {
    yield put(ActionCreators.postContact.failure(e));
    failedHandler();
  }
}

const contactSaga = [
  takeLatest(ContactActionType.POST_CONTACT_REQUEST, postContact)
];

export default contactSaga;
