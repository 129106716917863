import Froalaeditor from 'froala-editor';
import { useEffect, useState } from "react";

Object.assign(Froalaeditor.POPUP_TEMPLATES, {
    'codeblockColor.picker':'[_BUTTONS_][_CODEBLOCK_COLORS_]'
});

Object.assign(Froalaeditor.DEFAULTS, {
    colorsCodeBlock: ["#61BD6D", "#1ABC9C", "#54ACD2", "#2C82C9", "#9365B8", "#475577", "#CCCCCC", "#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000", "#F7DA64", "#FBA026", "#EB6B56", "#E25041", "#A38F84", "#EFEFEF", "#FFFFFF", "#FAC51C", "#F37934", "#D14841", "#B8312F", "#7C706B", "#D1D5D8", "REMOVE"],
    colorsStep: 7,
    colorsHEXInput: false,
    colorsButtons: ["colorsBack", "|", "-"]
});


const FroalaCodeBlockPlugin = (editor) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const codeblockFillCheckbox: any = document.getElementsByClassName('codeblock-fill-check');
    const codeblockBorderRadiusCheckbox: any = document.getElementsByClassName('codeblock-border-radius-check');

    // popup表示時にチェックボックスを選択状態にするか判断
    useEffect(() => {
        if (isOpen && editor) {
            const blocks: any = editor.selection.blocks();

            if (blocks[0].tagName === 'LI') {
                if (blocks[0].parentElement.classList.contains('codeblock')) {
                    if (blocks[0].parentElement.style.backgroundColor) {
                        codeblockFillCheckbox[0].checked = true;
                    } else {
                        codeblockFillCheckbox[0].checked = false;
                    }

                    if (blocks[0].parentElement.style.borderRadius) {
                        codeblockBorderRadiusCheckbox[0].checked = true;
                    } else {
                        codeblockBorderRadiusCheckbox[0].checked = false;
                    }
                } else { //デフォルト
                    codeblockFillCheckbox[0].checked = true;
                    codeblockBorderRadiusCheckbox[0].checked = true;
                }
            } else {
                if (blocks[0].classList.contains('codeblock')) {
                    if (blocks[0].style.backgroundColor) {
                        codeblockFillCheckbox[0].checked = true;
                    } else {
                        codeblockFillCheckbox[0].checked = false;
                    }

                    if (blocks[0].style.borderRadius) {
                        codeblockBorderRadiusCheckbox[0].checked = true;
                    } else {
                        codeblockBorderRadiusCheckbox[0].checked = false;
                    }
                } else { //デフォルト
                    codeblockFillCheckbox[0].checked = true;
                    codeblockBorderRadiusCheckbox[0].checked = true;
                }
            }
        }
    }, [isOpen]);


    Froalaeditor.PLUGINS.codeblock = (editor) => {
        const E = editor.$,
              l = '<div class="fr-color-hex-layer fr-active fr-layer" id="fr-color-hex-layer- \n  '.concat(editor.id, '"><div class="fr-input-line"><input maxlength="7" id="[ID]"\n  type="text" placeholder="').concat(editor.language.translate("HEX Color"), '" \n  tabIndex="1" aria-required="true"></div><div class="fr-action-buttons"><button \n  type="button" class="fr-command fr-submit" data-cmd="[COMMAND]" tabIndex="2" role="button">\n  ').concat(editor.language.translate("OK"), "</button></div></div>");

        function s(o) {
            let r = '<div class="fr-color-set fr-'.concat(o, '-color fr-selected-set">');
            for (let t = editor.opts.colorsCodeBlock, e = 0; e < t.length; e++) {
                0 !== e && e % editor.opts.colorsStep == 0 && (r += "<br>"), "REMOVE" !== t[e] ? r += '<span class="fr-command fr-select-color" style="background:'.concat(t[e], ';" \n        tabIndex="-1" aria-selected="false" role="button" data-cmd="apply').concat(o, 'Color" \n        data-param1="').concat(t[e], '"><span class="fr-sr-only"> ').concat(editor.language.translate("Color")).concat(t[e], " \n        &nbsp;&nbsp;&nbsp;</span></span>") : r += '<span class="fr-command fr-select-color" data-cmd="apply'.concat(o, 'Color"\n         tabIndex="-1" role="button" data-param1="REMOVE" \n         title="').concat(editor.language.translate("Clear Formatting"), '">').concat(editor.icon.create("remove"), ' \n        <span class="fr-sr-only"> ').concat(editor.language.translate("Clear Formatting"), " </span></span>");
            }
            r += '</div>' +
                 '<div class="fr-select-codeblock-contents">'+
                 `<div class="fr-select-codeblock-fill"><label><input name="codeblock-fill" type="checkbox" class="codeblock-fill-check">塗りつぶし</label></div>` +
                 `<div class="fr-select-border-radius"><label><input name="codeblock-border-radius" type="checkbox" class="codeblock-border-radius-check">角を丸くする</label></div>` +
                 '</div>';

            return r;
        };

        function i(o) {
            let t = "codeblock-color",
                r = editor.popups.get("".concat(o, "Color.picker")),
                e = E(editor.selection.element());
            const a = r.find(".fr-".concat(o, "-color .fr-select-color"));

            for (a.find(".fr-selected-color").remove(), a.removeClass("fr-active-item"), a.not('[data-param1="REMOVE"]').attr("aria-selected", false); e.get(0) !== editor.el;) {
                if ("transparent" !== e.css(t) && "rgba(0, 0, 0, 0)" !== e.css(t)) {
                    const c = r.find(".fr-".concat(o, '-color .fr-select-color[data-param1="').concat(editor.helpers.RGBToHex(e.css(t)), '"]'));
                    c.append("<span class=\"fr-selected-color\" aria-hidden=\"true\">\uF00C</span>"), c.addClass("fr-active-item").attr("aria-selected", true);
                    break;
                }
                e = e.parent();
            }

            function n(o) {
                let t = editor.popups.get("".concat(o, "Color.picker")),
                    r = t.find(".fr-".concat(o, "-color .fr-active-item")).attr("data-param1"),
                    e = t.find(".fr-color-hex-layer input");
                r || (r = "");
                e.length && E(e.val(r).input).trigger("change");
            }(o);
        };

        //カラーパレットで選択後実行される
        function e(o) {
            const blocks: any = editor.selection.blocks();
            const isCheckedFillCheckBox: boolean = codeblockFillCheckbox[0].checked;
            const isCheckedBorderRadiusCheckBox: boolean = codeblockBorderRadiusCheckbox[0].checked;

            Object.keys(blocks).forEach(key => {
                if (blocks[key].tagName !== 'TD' && blocks[key].tagName !== 'TH') {
                    if (blocks[key].tagName === 'LI') {
                        if (Object.keys(blocks[key].parentElement.classList).length > 0) {
                            if (blocks[key].parentElement.classList.contains('codeblock')) {
                                if (o === "REMOVE") {
                                    blocks[key].parentElement.style.backgroundColor = '';
                                    blocks[key].parentElement.style.border = '';
                                    blocks[key].parentElement.style.borderRadius = '';
                                    blocks[key].parentElement.classList.remove('codeblock');
                                } else {
                                    if (isCheckedFillCheckBox) {
                                        blocks[key].parentElement.style.backgroundColor = editor.helpers.HEXtoRGB(o);
                                    } else {
                                        blocks[key].parentElement.style.border = `1px solid ${editor.helpers.HEXtoRGB(o)}`;
                                    }

                                    if (isCheckedBorderRadiusCheckBox) {
                                        blocks[key].parentElement.style.borderRadius = '5px';
                                    } else {
                                        blocks[key].parentElement.style.borderRadius = '';
                                    }
                                }
                            } else {
                                blocks[key].parentElement.classList.add('codeblock');
                                if (isCheckedFillCheckBox) {
                                    blocks[key].parentElement.style.backgroundColor = editor.helpers.HEXtoRGB(o);
                                } else {
                                    blocks[key].parentElement.style.border = `1px solid ${editor.helpers.HEXtoRGB(o)}`;
                                }

                                if (isCheckedBorderRadiusCheckBox) {
                                    blocks[key].parentElement.style.borderRadius = '5px';
                                } else {
                                    blocks[key].parentElement.style.borderRadius = '';
                                }
                                blocks[key].parentElement.outerHTML = `<p><br></p>${blocks[key].parentElement.outerHTML}<p><br></p>`;
                                focusPosition();
                            }
                        } else {
                            blocks[key].parentElement.classList.add('codeblock');
                            if (isCheckedFillCheckBox) {
                                blocks[key].parentElement.style.backgroundColor = editor.helpers.HEXtoRGB(o);
                            } else {
                                blocks[key].parentElement.style.border = `1px solid ${editor.helpers.HEXtoRGB(o)}`;
                            }

                            if (isCheckedBorderRadiusCheckBox) {
                                blocks[key].parentElement.style.borderRadius = '5px';
                            } else {
                                blocks[key].parentElement.style.borderRadius = '';
                            }
                            blocks[key].parentElement.outerHTML = `<p><br></p>${blocks[key].parentElement.outerHTML}<p><br></p>`;
                            focusPosition();
                        }
                    } else { //ol, ul以外
                        if (Object.keys(blocks[key].classList).length > 0) {
                            if(blocks[key].classList.contains('codeblock')) {
                                if (o === "REMOVE") {
                                    blocks[key].style.backgroundColor = '';
                                    blocks[key].style.border = '';
                                    blocks[key].style.borderRadius = '';
                                    blocks[key].classList.remove('codeblock');
                                } else {
                                    if (isCheckedFillCheckBox) {
                                        blocks[key].style.backgroundColor = editor.helpers.HEXtoRGB(o);
                                    } else {
                                        blocks[key].style.border = `1px solid ${editor.helpers.HEXtoRGB(o)}`;
                                    }

                                    if (isCheckedBorderRadiusCheckBox) {
                                        blocks[key].style.borderRadius = '5px';
                                    } else {
                                        blocks[key].style.borderRadius = '';
                                    }
                                }
                            } else {
                                if (o !== "REMOVE") {
                                    blocks[key].classList.add('codeblock');
                                    if (isCheckedFillCheckBox) {
                                        blocks[key].style.backgroundColor = editor.helpers.HEXtoRGB(o);
                                    } else {
                                        blocks[key].style.border = `1px solid ${editor.helpers.HEXtoRGB(o)}`;
                                    }

                                    if (isCheckedBorderRadiusCheckBox) {
                                        blocks[key].style.borderRadius = '5px';
                                    } else {
                                        blocks[key].style.borderRadius = '';
                                    }
                                    blocks[key].outerHTML = `<p><br></p>${blocks[key].outerHTML}<p><br></p>`;

                                    if (key == '0') {
                                        focusPosition();
                                    }
                                }
                            }
                        } else {
                            if(o !== "REMOVE") {
                                blocks[key].classList.add('codeblock');
                                if (isCheckedFillCheckBox) {
                                    blocks[key].style.backgroundColor = editor.helpers.HEXtoRGB(o);
                                } else {
                                    blocks[key].style.border = `1px solid ${editor.helpers.HEXtoRGB(o)}`;
                                }

                                if (isCheckedBorderRadiusCheckBox) {
                                    blocks[key].style.borderRadius = '5px';
                                } else {
                                    blocks[key].style.borderRadius = '';
                                }
                                blocks[key].outerHTML = `<p><br></p>${blocks[key].outerHTML}<p><br></p>`;
                                
                                if (key == '0') {
                                    focusPosition();
                                }
                            }
                        }
                    }
                }
            });
            editor.popups.hide("codeblockColor.picker");
        };

        function focusPosition() {
            const blocks: any = editor.selection.blocks();
            const selection: any = window.getSelection();
            const range: any = document.createRange();
            range.setStart(blocks[0].nextElementSibling, 0);
            range.setEnd(blocks[0].nextElementSibling, 0);
            selection.removeAllRanges(); 
            selection.addRange(range);
        }

        return {
            showColorsPopup: function p(o) {
                let t = editor.$tb.find('.fr-command[data-cmd="'.concat(o, '"]')),
                    r = editor.popups.get("".concat(o, ".picker"));

                if (r || (r = function n(o) {
                    let t = '<div class="fr-buttons fr-tabs">\n'.concat(editor.button.buildList(editor.opts.colorsButtons), '\n</div>');
                    editor.opts.toolbarInline && 0 < editor.opts.colorsCodeBlock.length && (t += '<div class="fr-buttons fr-colors-buttons fr-tabs">\n        '.concat(editor.button.buildList(editor.opts.colorsButtons), "\n        </div>"));
                    const r = {
                        buttons: t,
                        codeblock_colors: s("codeblock"),
                    };
                    const a = editor.popups.create("".concat(o, ".picker"), r);

                    return function c(C, b) {
                        editor.events.on("popup.tab", function (o) {
                            const t = E(o.currentTarget);

                            if (!editor.popups.isVisible(b) || !t.is("span")) return true;
                            let r = o.which,
                                e = true;

                            if (Froalaeditor.KEYCODE.TAB === r) {
                                const a = C.find(".fr-buttons");
                                e = !editor.accessibility.focusToolbar(a, !!o.shiftKey);
                            } else if (Froalaeditor.KEYCODE.ARROW_UP === r || Froalaeditor.KEYCODE.ARROW_DOWN === r || Froalaeditor.KEYCODE.ARROW_LEFT === r || Froalaeditor.KEYCODE.ARROW_RIGHT === r) {
                                if (t.is("span.fr-select-color")) {
                                    let c = t.parent().find("span.fr-select-color"),
                                        n = c.index(t),
                                        l = editor.opts.colorsStep,
                                        s = Math.floor(c.length / l),
                                        i = n % l,
                                        p = Math.floor(n / l),
                                        u = p * l + i,
                                        d = s * l;
                                    Froalaeditor.KEYCODE.ARROW_UP === r ? u = ((u - l) % d + d) % d : Froalaeditor.KEYCODE.ARROW_DOWN === r ? u = (u + l) % d : Froalaeditor.KEYCODE.ARROW_LEFT === r ? u = ((u - 1) % d + d) % d : Froalaeditor.KEYCODE.ARROW_RIGHT === r && (u = (u + 1) % d);
                                    const f = E(c.get(u));
                                    editor.events.disableBlur(), f.focus(), e = false;
                                }
                            } else Froalaeditor.KEYCODE.ENTER === r && (editor.button.exec(t), e = false);

                            return false === e && (o.preventDefault(), o.stopPropagation()), e;
                        }, true);
                    }(a, "".concat(o, ".picker")), a;
                }(o)), !r.hasClass("fr-active")) if (editor.popups.setContainer("".concat(o, ".picker"), editor.$tb), i("codeblock"), t.isVisible()) {
                    const e = editor.button.getPosition(t),
                        a = e.left,
                        c = e.top;
                    editor.popups.show("".concat(o, ".picker"), a, c, t.outerHeight());
                    setIsOpen(true);
                } else editor.position.forSelection(r), editor.popups.show("".concat(o, ".picker")), setIsOpen(true);


                editor.popups.onHide('codeblockColor.picker', function () {
                    setIsOpen(false);
                });


                codeblockFillCheckbox[0].onclick = () => {
                    const isChecked: boolean = codeblockFillCheckbox[0].checked;
                    const blocks: any = editor.selection.blocks();

                    if (blocks[0].tagName === 'LI') {
                        if (blocks[0].parentElement.classList.contains('codeblock')) {
                            if (isChecked) {
                                const borderColor: string = blocks[0].parentElement.style.borderColor;
                                blocks[0].parentElement.style.backgroundColor = borderColor;
                                blocks[0].parentElement.style.border = '';
                            } else {
                                const backgroundColor: string = blocks[0].parentElement.style.backgroundColor;
                                blocks[0].parentElement.style.border = `1px solid ${backgroundColor}`;
                                blocks[0].parentElement.style.backgroundColor = '';
                            }
                        }
                    } else {
                        if (blocks[0].classList.contains('codeblock')) {
                            if (isChecked) {
                                const borderColor: string = blocks[0].style.borderColor;
                                blocks[0].style.backgroundColor = borderColor;
                                blocks[0].style.border = '';
                            } else {
                                const backgroundColor: string = blocks[0].style.backgroundColor;
                                blocks[0].style.border = `1px solid ${backgroundColor}`;
                                blocks[0].style.backgroundColor = '';
                            }
                        }
                    }
                };

                codeblockBorderRadiusCheckbox[0].onclick = () => {
                    const isChecked: boolean = codeblockBorderRadiusCheckbox[0].checked;
                    const blocks: any = editor.selection.blocks();

                    if (blocks[0].tagName === 'LI') {
                        if (blocks[0].parentElement.classList.contains('codeblock')) {
                            if (isChecked) {
                                blocks[0].parentElement.style.borderRadius = '5px';
                            } else {
                                blocks[0].parentElement.style.borderRadius = '';
                            }
                        }
                    } else {
                        if (blocks[0].classList.contains('codeblock')) {
                            if (isChecked) {
                                blocks[0].style.borderRadius = '5px';
                            } else {
                                blocks[0].style.borderRadius = '';
                            }
                        }
                    }
                };
            },

            background: e,

            back: function o() {
                editor.popups.hide("codeblockColor.picker");
                editor.toolbar.showInline();
            }
        };
    };
}

export default FroalaCodeBlockPlugin;
