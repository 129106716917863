enum MemberActionType {
	GET_MEMBER_LIST_REQUEST = "GET_MEMBER_LIST_REQUEST",
	GET_MEMBER_LIST_SUCCEEDED = "GET_MEMBER_LIST_SUCCEEDED",
	GET_MEMBER_LIST_FAILED = "GET_MEMBER_LIST_FAILED",
	POST_MEMBER_REQUEST = "POST_MEMBER_REQUEST",
	POST_MEMBER_SUCCEEDED = "POST_MEMBER_SUCCEEDED",
	POST_MEMBER_FAILED = "POST_MEMBER_FAILED",
	PATCH_MEMBER_REQUEST = "PATCH_MEMBER_REQUEST",
	PATCH_MEMBER_SUCCEEDED = "PATCH_MEMBER_SUCCEEDED",
	PATCH_MEMBER_FAILED = "PATCH_MEMBER_FAILED",
	DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST",
	DELETE_MEMBER_SUCCEEDED = "DELETE_MEMBER_SUCCEEDED",
	DELETE_MEMBER_FAILED = "DELETE_MEMBER_FAILED",

	GET_MEMBER_SEARCH_REQUEST = "GET_MEMBER_SEARCH_REQUEST",
	GET_MEMBER_SEARCH_SUCCEEDED = "GET_MEMBER_SEARCH_SUCCEEDED",
	GET_MEMBER_SEARCH_FAILED = "GET_MEMBER_SEARCH_FAILED",

	SET_IS_OVER_MEMBER = "SET_IS_OVER_MEMBER",
}

export default MemberActionType;

