import React from 'react';

//component
import Title from '../Title';
import PageTitle from "../PageTitle";

//material-ui
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

//image
import Image01 from '../../../assets/help/setting/user/image01.png';
import Image02 from '../../../assets/help/setting/user/image02.png';


const User = () => {
    return (
        <article className={style.root}>
            <a id="1" className={style.anchor}/>
            <div className={style.box}>
                <PageTitle title={"各種設定"}/>
                <Title title={"4. アカウント設定"}/>
                <Typography gutterBottom variant={"body1"}>
                    <Typography gutterBottom variant={"body1"} color={"error"}>
                       HARVEST内で使用します。外部に表示されることはありません。
                    </Typography>
                    右上のプルダウンから「個人設定」を選択します。
                </Typography>
                <div className={style.img}>
                    <img src={Image01}/>
                </div>
                <Typography gutterBottom variant={"body1"}>
                    「基本情報変更」から内容を修正することができます。
                </Typography>
                <div className={style.img}>
                    <img src={Image02}/>
                </div>
            </div>
        </article>
    );
};

export default User;
