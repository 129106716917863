import React, {ReactNode} from 'react';
import List from "./List";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {Box} from "@material-ui/core";

export interface Props {
	children: ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'block',
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				display: 'flex',
			},
			height: "100vh",
		},
		title: {
			position: 'fixed',
		},
		content: {
			flex: 1,
			marginLeft: 300,
		}
	}),
);

const Menu = ({children}) => {
	const classes = useStyles();
	return (
		<Box>
			<Typography variant="h1" component="h1" gutterBottom className={classes.title}>
				機能別ヘルプセンター
			</Typography>
			<Box className={classes.root}>
				<List/>
				<div className={classes.content}>
					{children}
				</div>
			</Box>
		</Box>
	);
};

export default Menu

