import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';

export interface Props {
    children: ReactNode
}

const Tips = ({children}) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className={classes.root}>
            <IconButton aria-describedby={id} onClick={handleClick} color="secondary">
                <InfoIcon fontSize="large"/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.content}>{children}</div>
            </Popover>
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        margin: "10px 0"
    },
    content: {
        fontSize: 14,
        lineHeight: 1.6,
        padding: 20
    }
});


export default Tips;
