import React, {useState} from "react";
import {useSelector} from "react-redux";
import clsx from 'clsx';

// component
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Stepper, StepConnector, Step, StepLabel, Typography} from "@material-ui/core";
import {StepIconProps} from '@material-ui/core/StepIcon';
import HelpFab from "../Common/HelpFab";
import Title from "../Common/Title";
import StepContent from "./StepContent";

// util
import {settingStepInfo, StepModel} from "./TopPageStep";

// state
import {RootState} from "../../reducers";
const companySelector = (state: RootState) => state.company;


const List = () => {

	const classes = useStyles();
	const company = useSelector(companySelector);

	const stepInfo = settingStepInfo(company);
	const {onePersonPRStep, teamPRStep} = stepInfo;

	const [activeOnePersonStep, setActiveOnePersonStep] = useState<number>(0);
	const [activeTeamStep, setActiveTeamStep] = useState<number>(0);

	const handleSetStep = (type: StepModel["type"], newStep: number): void => {
		type === "onePerson" ? setActiveOnePersonStep(newStep) : setActiveTeamStep(newStep);
	};


	return (
		<>
			<Title page="TOP_PAGE">
				HARVESTでPRをはじめよう！<HelpFab url={'/help/press_release#1'}/>
			</Title>
			<div>
				<Typography variant="h4">1人広報はこちら</Typography>
				<Stepper alternativeLabel nonLinear activeStep={activeOnePersonStep}
					connector={<CustomConnector/>} classes={{root: classes.stepperRoot}}>

					{onePersonPRStep.contents.map((content, index) => {
						return (
							<Step key={index}>
								<StepLabel StepIconComponent={StepIcon}>{content.label}</StepLabel>
							</Step>
						)
					})}

				</Stepper>
				<StepContent step={onePersonPRStep} activeStep={activeOnePersonStep} handleSetStep={handleSetStep} />
			</div>
			<div>
				<Typography variant="h4">チーム広報はこちら</Typography>
				<Stepper alternativeLabel nonLinear activeStep={activeTeamStep}
					connector={<CustomConnector/>} classes={{root: classes.stepperRoot}}>

					{teamPRStep.contents.map((content, index) => {
						return (
							<Step key={index}>
								<StepLabel StepIconComponent={StepIcon}>{content.label}</StepLabel>
							</Step>
						)
					})}

				</Stepper>
				<StepContent step={teamPRStep} activeStep={activeTeamStep} handleSetStep={handleSetStep} />
			</div>
		</>
	);
};

export default List;


const CustomConnector = withStyles({
	alternativeLabel: {
	  top: 15,
	  left: "calc(-50% + 40px)",
	  right: "calc(50% + 40px)",
	},
	line: {
	  borderTopWidth: 2,
	  borderRadius: 1,
	},
})(StepConnector);


const StepIcon = (props: StepIconProps) => {
	const classes = useStyles();
	const {active, icon} = props;

	return (
		<div className={clsx(classes.iconRoot, {
			[classes.activeIcon]: active,
		})}>
			<Typography variant="body1" align="center" display="inline" className={classes.iconText}>
				Step{icon}
			</Typography>
		</div>
	);
};

const useStyles = makeStyles({
	stepperRoot: {
		padding: "24px 10px",
	},
	iconRoot: {
		color: "#e3f5ea",
		border: "2px solid #e3f5ea",
		borderRadius: 5,
		backgroundColor: "#e3f5ea",
		padding: "5px 8px",
	},
	activeIcon: {
		borderColor: "#2eb964",
		backgroundColor: "#2eb964",
	},
	iconText: {
		color: "#fff",
		fontSize: 15,
	},
});

