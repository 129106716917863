import DocIcon from "../assets/file_icons/doc_icon.svg";
import Mp4Icon from "../assets/file_icons/mp4_icon.svg";
import MoVIcon from "../assets/file_icons/mov_icon.svg";
import PdfIcon from "../assets/file_icons/pdf_icon.svg";
import PptIcon from "../assets/file_icons/ppt_icon.svg";
import PsdIcon from "../assets/file_icons/psd_icon.svg";
import XlsIcon from "../assets/file_icons/xls_icon.svg";
import FileIcon from "../assets/file_icons/outlined_file_icon.svg";

const fileIcons = {
  doc: DocIcon,
  docx: DocIcon,
  mp4: Mp4Icon,
  mov: MoVIcon,
  pdf: PdfIcon,
  ppt: PptIcon,
  pptx: PptIcon,
  psd: PsdIcon,
  xls: XlsIcon,
  xlsx: XlsIcon,
  none: FileIcon,
};

export const imageFileExtensionList = [
  "jpg",
  "jpeg",
  "JPG",
  "png",
  "PNG",
  "gif",
  "GIF"
];

export const getIcon = (fileType: string): string => {
  if (Object.keys(fileIcons).indexOf(fileType) == -1) {
    return fileIcons['none']
  }
  return fileIcons[fileType];
};
