import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {push} from "connected-react-router";

// material-ui
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  TextField
} from "@material-ui/core";

// actions
import * as ContactActionCreators from "../../../../actions/Contact/ActionCreator";

// utils
import {getNextChargeDate} from "../../../../util/payment";

// reducers
import {RootState} from "../../../../reducers";
const companySelector = (state: RootState) => state.company;
const meSelector = (state: RootState) => state.me;
const paymentSelector = (state: RootState) => state.payment;

interface CancelDialogProps {
  isOpen: boolean;
  closeHandle;
}

const CancelDialog: React.FC<CancelDialogProps> = ({isOpen, closeHandle}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {plan, name, tel} = useSelector(companySelector);
  const me = useSelector(meSelector);
  const {fetching} = useSelector(paymentSelector);
  const nextChargeDate = getNextChargeDate();

  const [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleApply = () => {
    const postValues = {
      first_name: me.first_name,
      last_name: me.last_name,
      email: me.email,
      company: name,
      phone: tel,
      type: `${plan.name}プランを解約`,
      text: text ? text : "",
      successHandler,
      failedHandler
    };

    dispatch(ContactActionCreators.postContact.request(postValues));
  };

  const successHandler = () => {
    handleClickClose();
    dispatch(push(`/setting/plan/apply/complete/`));
  };

  const failedHandler = () => {
    setErrorMessage("申請ができませんでした。時間を置いて、再度お試し下さい。");
  };

  const handleClickClose = () => {
    closeHandle();
    setText("");
    setErrorMessage("");
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h5">プランキャンセル</Typography>
      </DialogTitle>
      <DialogContent classes={{root: classes.content}}>
        <div className={classes.settingTitle}>内容</div>
        <div className={classes.text}>
          <Typography variant={"h6"}>プラン</Typography>
          <Typography variant={"h6"}>{`${plan.name}プラン`}</Typography>
        </div>
        <div className={classes.text}>
          <Typography variant={"h6"}>備考入力(任意)</Typography>
          <TextField
            multiline
            className={classes.textField}
            rows={4}
            rowsMax={4}
            defaultValue={""}
            variant="outlined"
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </div>
        <div className={classes.text}>
          <Typography
            className={classes.caption}
            variant="h6"
            align="center"
          >{`現在契約中のプランをキャンセルします。`}</Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button disabled={fetching} onClick={handleClickClose}>
          閉じる
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={fetching}
          onClick={handleApply}
        >
          申請する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;

const useStyles = makeStyles({
  settingTitle: {
    background: "#f3f7f4",
    borderRadius: 4,
    color: "#616763",
    fontSize: 14,
    fontWeight: "bold",
    padding: "10px 20px",
    marginBottom: 10
  },
  content: {
    width: 600,
    padding: 36
  },
  text: {
    display: "flex",
    justifyContent: "space-between",
    margin: 16
  },
  caption: {
    marginTop: 20
  },
  textField: {
    maxWidth: 350
  },
  dialogActions: {
    display: "block",
    textAlign: "right"
  }
});
