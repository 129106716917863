import React from 'react';

// components
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

interface IProps {
    value: number | null,
    choices: string[],
    handleChange,
}

const RadioAnswer: React.FC<IProps> = ({ value, choices, handleChange }) => {
    return (
        <RadioGroup
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(Number(e.target.value));
            }}>
            {choices.map((choice, index) => (
                <FormControlLabel key={index} value={index} control={<Radio />} label={choice} />
            ))}
        </RadioGroup>
    );
}

export default RadioAnswer;
