import React from 'react';
import Title from '../Title'
import SubTitle from '../SubTitle';
import Typography from "@material-ui/core/Typography";

//style
import style from "../help.module.scss";

import Image01 from '../../../assets/help/editor/column/image01.png';
import Image02 from '../../../assets/help/editor/column/image02.png';

const Block = () => {
	return (
		<div className={style.box}>
			<Title title={"6. カラムレイアウト"}/>
			<Typography gutterBottom variant={"body1"}>
				2,3,4カラムのレイアウト作成も簡単です。<br/>
				メニューからカラムのアイコンを選択します。
			</Typography>
			<div className={style.img}>
				<img src={Image01}/>
			</div>
			<Typography gutterBottom variant={"body1"}>
				カーソルの箇所に、画像+テキストのレイアウトが挿入されます。
			</Typography>
			<div className={style.img}>
				<img src={Image02}/>
			</div>
		</div>
	);
};

export default Block
