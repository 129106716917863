import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import style from "./card_layout.module.scss";
import dayjs from "dayjs";

//model
import { PressReleaseListModel } from "../../../../model/PressReleaseModel";
import { createPressReleaseURL } from "../../../../util/Status";

// component
import Status from "../../Common/Status";
import DateTime from "../../../Common/DateTime";
import ListMenu from "../ListMenu";
import Avatar from '../../../Common/Avatar';
import TagDialog from "../../Tag/Dialog";
import Label from "../../Tag/Label";
import Preview from "../../../Common/Editor/Preview";

// actions
import * as PressReleaseActionCreators from '../../../../actions/PressRelease/ActionCreator';

//material-ui
import { IconButton, Tooltip, makeStyles, Box } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { member } from "../../../../reducers/Member";
//state
import { RootState } from "../../../../reducers";

const pressGroupSelector = (state: RootState) => state.group;
const routerSelector = (state: RootState) => state.router;
const pressReleaseListSelector = (state: RootState) => state.pressReleaseList;

interface ListLayoutProps {
    list: PressReleaseListModel[],
    handleDeleteDialogOpen,
    handleClone,
    handleArchive,
    handleOpenMailDialog
}

const ListLayout: React.FC<ListLayoutProps> = ({ list, handleDeleteDialogOpen, handleClone, handleArchive, handleOpenMailDialog }) => {

    return (
        <ul className={style.card}>
            {list.map((item, index) => (
                <ListLayoutItems
                    item={item}
                    key={index}
                    handleDeleteDialogOpen={handleDeleteDialogOpen}
                    handleClone={handleClone}
                    handleArchive={handleArchive}
                    handleOpenMailDialog={handleOpenMailDialog} />
            ))}
        </ul>
    );
}
export default ListLayout;

interface ListLayoutItemsProps {
    item: PressReleaseListModel,
    handleDeleteDialogOpen,
    handleClone,
    handleArchive,
    handleOpenMailDialog
}

const ListLayoutItems: React.FC<ListLayoutItemsProps> = ({ item, handleDeleteDialogOpen, handleClone, handleArchive, handleOpenMailDialog }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [tagDialog, setTagDialog] = useState<boolean>(false);
    const router = useSelector(routerSelector);
    const { search, archive, publicList } = useSelector(pressReleaseListSelector);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const group = useSelector(pressGroupSelector);

    let label = "編集";
    if (item.status === 1) {
        label = "確認";
    } else if (item.status > 3) {
        label = "配信設定";
    }

    const getPressReleaseList = () => {
        const is_archive = !!(router.location.pathname.indexOf('archive') > -1 ? 1 : 0);
        const is_public = !!(router.location.pathname.indexOf('public') > -1 ? 1 : 0);
        if (is_archive) {
            dispatch(PressReleaseActionCreators.getPressReleaseListArchive.request({ offset: archive.offset }));
        } else if (is_public) {
            dispatch(PressReleaseActionCreators.getPressReleaseListPublic.request({ offset: publicList.offset }));
        } else {
            dispatch(PressReleaseActionCreators.getPressReleaseList.request({ offset: search.offset }));
        }
    }

    return (
        <li className={style.list}>
            <Card className={style.inner}>
                <CardContent className={classes.cardContent}>
                    <div className={style.press_info}>
                        <PressInfo item={item} />
                    </div>
                    <div className={style.overlay}>
                        <NavLink exact to={`/press_release/${group.selectedId}/detail/${item.press_id}`} className={style.open}>
                            プレビュー
                        </NavLink>
                        <NavLink exact to={`${createPressReleaseURL(group.selectedId, item.press_id, item.status)}`} className={style.open}>
                            {label}
                        </NavLink>
                    </div>
                    <div className={style.bg}>
                        {item.history.body &&
                            <Preview body={item.history.body} isCard={true} />
                        }
                    </div>
                    <Box className={classes.tags}>
                        {item.tags && item.tags.map((tag, index) => (
                            <Box className={classes.tag} key={index}>
                                <Label tag={tag} />
                            </Box>))}
                    </Box>
                    <Box className={classes.cardInfo}>
                        <Typography className={style.author}>{item.name}</Typography>
                        <Box className={classes.cardInner}>
                            <Box className={classes.avator}>
                                <Avatar src={item.create_user.img} name={item.create_user.last_name} size="menu" color={item.create_user.color_cd} />
                            </Box>
                            <Box className={style.subHeader}>
                                <p className={style.createUser}>{item.create_user.last_name} {item.create_user.first_name}</p>
                                <Box className={classes.subInfo}>
                                    <DateTime datetime={item.history.created_at} />
                                    <Status type="label" review={item.review} status={Number(item.status)} />
                                </Box>
                            </Box>
                            <Tooltip title="メニュー" placement="top" arrow>
                                <IconButton onClick={handleClick} className={style.iconMenu}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <div className={style.info}>
                {/*<div className={style.deadline}><DeadLine review={item.review} status={item.status} min={true}/></div>*/}
            </div>
            <ListMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                item={item}
                handleDeleteDialogOpen={handleDeleteDialogOpen}
                handleClone={handleClone}
                handleArchive={handleArchive}
                handleTagDialogOpen={() => setTagDialog(true)} />
            <TagDialog isOpen={tagDialog} item={item} handleClose={() => {
                setTagDialog(false);
                getPressReleaseList();
            }} />
        </li>
    );
}

const useStyles = makeStyles({
    cardHeader: {
        padding: '8px 16px',
    },
    cardHeaderAction: {
        margin: '0 -8px',
    },
    tags: {
        bottom: 85,
        display: 'flex',
        right: 0,
        flexDirection: 'column',
        zIndex: 10,
        position: 'absolute',
        maxHeight: 83,
        overflowY: 'scroll',
        paddingRight: 10,
        msOverflowStyle: 'none',
        textAlign: 'right',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    tag: {
        margin: '3px 2px 3px auto',
    },
    cardInfo: {
        position: 'absolute',
        bottom: 0,
        background: '#f8fbf9',
        width: '100%',
        borderTop: '1px solid #e2e9e5',
        padding: "8px 0",
    },
    cardContent: {
        padding: 0,
    },
    subInfo: {
        display: 'flex',
        marginTop: 5
    },
    cardInner: {
        display: 'flex',
        alignItems: "center"
    },
    avator: {
        flex: 1,
    },
    openedCheck: {
        position: 'absolute',
        top: 165,
        left: 5,
        zIndex: 100,
    },
    pressInfo: {
        width: '100%',
        position: 'absolute',
        zIndex: 10,
        backgroundColor: '#fff',
    },
})

const PressInfo = ({ item }) => {
    let members: any[] = []
    if (item.review) {
        members = item.review.member
    }
    let reserve_members: any[] = []
    if (item.reserve) {
        reserve_members = item.reserve.member
    }

    let reserve_deadline = ''
    if (item.reserve) reserve_deadline = dayjs(item.reserve.deadline_at).format('YYYY/MM/DD HH:mm');

    let deadline = ''
    if (item.review) deadline = dayjs(item.review.deadline_at).format('YYYY/MM/DD HH:mm');

    let release = ''
    if (item.released_at) release = dayjs(item.released_at).format('YYYY/MM/DD HH:mm');

    return (
        <>
            {item.status == 1 && (
                <div className={style.footerInner}>
                    {deadline && (
                        <div className={style.footerLeft}>
                            <p className={style.footerMessage}>確認期限</p>
                            <p className={style.footerDate}>{deadline}</p>
                        </div>
                    )}
                    <div className={style.members}>
                        {members && (<Members members={members} />)}
                    </div>
                </div>
            )}
            {(item.status == 4 || item.status == 5) && (
                <div className={style.footerInner}>
                    {reserve_deadline && (
                        <div className={style.footerLeft}>
                            <p className={style.footerMessage}>確認期限</p>
                            <p className={style.footerDate}>{reserve_deadline}</p>
                        </div>
                    )}
                    <div className={style.members}>
                        {reserve_members.length > 0 && (<Members members={reserve_members} />)}
                    </div>
                </div>
            )}
            {item.status == 7 && (
                <div className={style.footerInnerReserve}>
                    <p className={style.footerMessage}>配信予約済み</p>
                    <p className={style.footerDate}>{release}</p>
                </div>
            )}
        </>
    )
}

const Members = ({ members }) => (
    <>
        {members.map((member, index) => (
            <React.Fragment key={index}>
                <Tooltip arrow title={`${member.last_name} ${member.first_name}`}>
                    <span className={style.avatar} style={{ zIndex: 10 - index, display: index > 1 ? 'none' : 'block' }}>
                        <Avatar src={member.img} name={member.last_name} size="menu" color={member.color_cd} />
                    </span>
                </Tooltip>
                <>{members.length > 2 && (<p className={style.otherText}>他</p>)}</>
            </React.Fragment>
        ))}
    </>
)
