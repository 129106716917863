import React from 'react';
import { useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Typography } from '@material-ui/core';
import AnswerTable from './Table';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

const Content: React.FC = () => {
    const classes = useStyles();
    const { detail, publicInfo } = useSelector(briefingSelector);
    const fetched = detail.fetched && publicInfo.fetched;

    return (
        <>
            {fetched ?
                <div className={classes.table}>
                    <AnswerTable body={detail.body} id={detail.id} />
                </div>
                :
                <div className={classes.circularProgressWrapper}>
                    <CircularProgress />
                </div>}
        </>
    );
}

export default Content;

const useStyles = makeStyles({
    table: {
        overflowX: 'scroll',
    },
    circularProgressWrapper: {
        minHeight: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})

