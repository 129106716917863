import BriefingActionType from '../actions/Briefing/ActionType';
import BriefingAction from '../actions/Briefing/Action';

import createReducer from './createReducer';
import { combineReducers } from 'redux';
import { BriefingListModel } from '../model/BriefingModel';

const initialBriefingList: BriefingListModel = {
    results: [],
}

const working = createReducer(initialBriefingList, {
    [BriefingActionType.GET_BRIEFING_LIST_SUCCEEDED](state, action: BriefingAction) {
        return action.payload
    }
})

const received = createReducer(initialBriefingList, {
    [BriefingActionType.GET_BRIEFING_LIST_RECEIVED_SUCCEEDED](state, action: BriefingAction) {
        return action.payload
    }
})

const template = createReducer(initialBriefingList, {
    [BriefingActionType.GET_BRIEFING_LIST_TEMPLATE_SUCCEEDED](state, action: BriefingAction) {
        return action.payload
    }
})

export const briefingList = combineReducers({
    working,
    received,
    template,
})
