import React from 'react';
import {useSelector, useDispatch} from "react-redux";

//material-ui
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from "@material-ui/core";

//action
import * as ActionCreators from "../../../actions/Radar/ActionCreator";

//model
import {RootState} from "../../../reducers";
import {makeStyles} from "@material-ui/core/styles";

const radarWordsSelector = (state: RootState) => state.radar.words;

interface Props {
    isOpen: boolean
    handleClose: () => void
    dialogOpenHandler: (isOpen: boolean) => void
    deleteTargetId: number | null
    deleteTargetIdSetter: (id: number | null) => void
}

export const DeleteConfirmDialog: React.FC<Props> = (
    {isOpen, handleClose, dialogOpenHandler, deleteTargetId, deleteTargetIdSetter}
    ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const words = useSelector(radarWordsSelector);
    const targetWord = words.find(word => word.id === deleteTargetId);

    const handleClick = (): void => {
        if (deleteTargetId) {
            dispatch(ActionCreators.radarWordDelete.request(deleteTargetId));
            dialogOpenHandler(false);
            deleteTargetIdSetter(null);
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
        >
            <DialogTitle>以下の検索ワードを削除しますか？</DialogTitle>
            <DialogContent className={classes.content}>
                {targetWord &&
                    <>
                        <Typography
                            variant="h6"
                            className={classes.text}
                        >
                            {`検索ワード: ${targetWord.radar_word}`}
                        </Typography>
                        <Typography
                            variant="h6"
                            className={classes.text}
                        >
                            {`除外ワード:  ${targetWord.exclude_word}`}
                        </Typography>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
                    キャンセル
                </Button>
                <Button variant="contained" size="large" color="primary" onClick={handleClick} className={classes.deleteButton}>
                    削除
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles({
    deleteButton: {
        background: "#E95050"
    },
    text: {
        marginBottom: 5
    },
    content: {
        marginTop: 10,
        marginBottom: 10
    }
})
