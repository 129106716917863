enum BriefingActionType {
	GET_BRIEFING_DETAIL_REQUEST = 'GET_BRIEFING_DETAIL_REQUEST',
	GET_BRIEFING_DETAIL_SUCCEEDED = 'GET_BRIEFING_DETAIL_SUCCEEDED',
	GET_BRIEFING_DETAIL_FAILED = 'GET_BRIEFING_DETAIL_FAILED',

	GET_BRIEFING_DETAIL_ANONYMOUS_REQUEST = 'GET_BRIEFING_DETAIL_ANONYMOUS_REQUEST',
	GET_BRIEFING_DETAIL_ANONYMOUS_SUCCEEDED = 'GET_BRIEFING_DETAIL_ANONYMOUS_SUCCEEDED',
	GET_BRIEFING_DETAIL_ANONYMOUS_FAILED = 'GET_BRIEFING_DETAIL_ANONYMOUS_FAILED',

	GET_BRIEFING_LIST_REQUEST = "GET_BRIEFING_LIST_REQUEST",
	GET_BRIEFING_LIST_SUCCEEDED = "GET_BRIEFING_LIST_SUCCEEDED",
	GET_BRIEFING_LIST_FAILED = "GET_BRIEFING_LIST_FAILED",

	GET_BRIEFING_LIST_TEMPLATE_REQUEST = 'GET_BRIEFING_LIST_TEMPLATE_REQUEST',
	GET_BRIEFING_LIST_TEMPLATE_SUCCEEDED = 'GET_BRIEFING_LIST_TEMPLATE_SUCCEEDED',
	GET_BRIEFING_LIST_TEMPLATE_FAILED = 'GET_BRIEFING_LIST_TEMPLATE_FAILED',

	GET_BRIEFING_LIST_RECEIVED_REQUEST = 'GET_BRIEFING_LIST_RECEIVED_REQUEST',
	GET_BRIEFING_LIST_RECEIVED_SUCCEEDED = 'GET_BRIEFING_LIST_RECEIVED_SUCCEEDED',
	GET_BRIEFING_LIST_RECEIVED_FAILED = 'GET_BRIEFING_LIST_RECEIVED_FAILED',

	PATCH_BRIEFING_CONTENT_REQUEST = "PATCH_BRIEFING_CONTENT_REQUEST",
	PATCH_BRIEFING_CONTENT_SUCCEEDED = "PATCH_BRIEFING_CONTENT_SUCCEEDED",
	PATCH_BRIEFING_CONTENT_FAILED = "PATCH_BRIEFING_CONTENT_FAILED",

	POST_BRIEFING_CONTENT_REQUEST = "POST_BRIEFING_CONTENT_REQUEST",
	POST_BRIEFING_CONTENT_SUCCEEDED = "POST_BRIEFING_CONTENT_SUCCEEDED",
	POST_BRIEFING_CONTENT_FAILED = "POST_BRIEFING_CONTENT_FAILED",

	DELETE_BRIEFING_CONTENT_REQUEST = "DELETE_BRIEFING_CONTENT_REQUEST",
	DELETE_BRIEFING_CONTENT_SUCCEEDED = "DELETE_BRIEFING_CONTENT_SUCCEEDED",
	DELETE_BRIEFING_CONTENT_FAILED = "DELETE_BRIEFING_CONTENT_FAILED",

	POST_BRIEFING_ANSWER_REQUEST = 'POST_BRIEFING_ANSWER_REQUEST',
	POST_BRIEFING_ANSWER_SUCCEEDED = 'POST_BRIEFING_ANSWER_SUCCEEDED',
	POST_BRIEFING_ANSWER_FAILED = 'POST_BRIEFING_ANSWER_FAILED',

	SET_BRIEFING_TEMPLATE_REQUEST = 'SET_BRIEFING_TEMPLATE_REQUEST',
	SET_BRIEFING_TEMPLATE_SUCCEEDED = 'SET_BRIEFING_TEMPLATE_SUCCEEDED',
	SET_BRIEFING_TEMPLATE_FAILED = 'SET_BRIEFING_TEMPLATE_FAILED',

	POST_BRIEFING_FILE_UPLOAD_REQUEST = 'POST_BRIEFING_FILE_UPLOAD_REQUEST',
	POST_BRIEFING_FILE_UPLOAD_SUCCEEDED = 'POST_BRIEFING_FILE_UPLOAD_SUCCEEDED',
	POST_BRIEFING_FILE_UPLOAD_FAILED = 'POST_BRIEFING_FILE_UPLOAD_FAILED',

	GET_BRIEFING_PUBLIC_INFO_REQUEST = 'GET_BRIEFING_PUBLIC_INFO_REQUEST',
	GET_BRIEFING_PUBLIC_INFO_SUCCEEDED = 'GET_BRIEFING_PUBLIC_INFO_SUCCEEDED',
	GET_BRIEFING_PUBLIC_INFO_FAILED = 'GET_BRIEFING_PUBLIC_INFO_FAILED',

	GET_BRIEFING_ANSWER_INFO_REQUEST = 'GET_BRIEFING_ANSWER_INFO_REQUEST',
	GET_BRIEFING_ANSWER_INFO_SUCCEEDED = 'GET_BRIEFING_ANSWER_INFO_SUCCEEDED',
	GET_BRIEFING_ANSWER_INFO_FAILED = 'GET_BRIEFING_ANSWER_INFO_FAILED',

	POST_BRIEFING_SEND_MAIL_REQUEST = 'POST_BRIEFING_SEND_MAIL_REQUEST',
	POST_BRIEFING_SEND_MAIL_SUCCEEDED = 'POST_BRIEFING_SEND_MAIL_SUCCEEDED',
	POST_BRIEFING_SEND_MAIL_FAILED = 'POST_BRIEFING_SEND_MAIL_FAILED',

	SET_BRIEFING_QUERIES = 'SET_BRIEFING_QUERIES',

	SET_BRIEFING_SORTING = 'SET_BRIEFING_SORTING',

	GET_BRIEFING_QTY_REQUEST = 'GET_BRIEFING_QTY_REQUEST',
	GET_BRIEFING_QTY_SUCCEEDED = 'GET_BRIEFING_QTY_SUCCEEDED',
	GET_BRIEFING_QTY_FAILED = 'GET_BRIEFING_QTY_FAILED',

	MAKE_HTML_FROM_BRIEFING_REQUEST = "MAKE_HTML_FROM_BRIEFING_REQUEST",
	MAKE_HTML_FROM_BRIEFING_SUCCEEDED = "MAKE_HTML_FROM_BRIEFING_SUCCEEDED",
	MAKE_HTML_FROM_BRIEFING_FAILED = "MAKE_HTML_FROM_BRIEFING_FAILED",

	POST_BRIEFING_ANSWER_CONFIRM_REQUEST = "POST_BRIEFING_CONFIRM_REQUEST",
	POST_BRIEFING_ANSWER_CONFIRM_SUCCEEDED = "POST_BRIEFING_CONFIRM_SUCCEEDED",
	POST_BRIEFING_ANSWER_CONFIRM_FAILED = "POST_BRIEFING_CONFIRM_FAILED",
}

export default BriefingActionType;
