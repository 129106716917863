import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";

//model
import { PressReleaseListModel, PressReleaseModel } from "../../../../model/PressReleaseModel";


//style
import style from "./list_layout.module.scss";

// state
import { RootState } from "../../../../reducers";
import { STATUS_ACCEPT } from "../../../../config/status_type";
const pressGroupSelector = (state: RootState) => state.group;


interface ListLayoutItemProps {
    item: PressReleaseListModel,
    handleClose,
    handleDeleteDialogOpen,
    handleClone,
    handleArchive,
    anchorEl: null | HTMLElement,
    handleTagDialogOpen,
}

const ListMenu: React.FC<ListLayoutItemProps> = ({
    item, handleClose, handleDeleteDialogOpen, handleClone, handleArchive, anchorEl, handleTagDialogOpen
}) => {
    const group = useSelector(pressGroupSelector);
    const isDelete = item.is_archive || item.status === 0

    return (
        <Menu
            elevation={1}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={style.popupMenu}
        >
            <MenuItem onClick={() => {
                handleTagDialogOpen();
                handleClose();
            }}>
                ラベルを管理
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => {
                handleClone(item.history.body, `コピー${item.name}`, item.history.is_footer_loop, item.history.is_footer_loop);
                handleClose();
            }}>
                コピーを作成
                </MenuItem>
            <Divider />
            {item.status >= STATUS_ACCEPT &&
                <MenuItem>
                    <NavLink exact
                        to={`/press_release/${group.selectedId}/setting_ogp/${item.press_id}/`}>
                        WEBページ設定
                    </NavLink>
                </MenuItem>}
            <Divider />
            {!item.is_archive &&
                <MenuItem onClick={() => {
                    handleArchive(item.press_id);
                    handleClose();
                }}>
                    {item.is_archive ? '下書きに戻す' : 'アーカイブ'}
                </MenuItem>
            }
            <Divider />
            <MenuItem onClick={() => {
                handleDeleteDialogOpen(item.press_id);
                handleClose();
            }}>
                削除
                </MenuItem>
        </Menu>
    );
}

export default ListMenu;
