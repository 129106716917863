import React from 'react'
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import TextField from "../Field/TextField";
import { Values, Errors } from "../../model/FormModel";
import Button from "@material-ui/core/Button";
import style from "./form.module.scss";
import { Typography } from '@material-ui/core';

const validate = (values: Values) => {
  const errors: Errors = {}
  const requireFields = [
    'pressKitName',
  ]
  requireFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'プレスキットの名前を入力してください'
    }
  })
  return errors
}

const AddPressKitForm: React.FC<InjectedFormProps> = ({ handleSubmit, submitting, pristine, error }) => {

  return (
    <form onSubmit={handleSubmit}>
      {error && <p>{error}</p>}
       <div className={style.rootAddPressKit}>
        <Field name="pressKitName" type="text" component={TextField} label="プレスキットの名前を入力してください"/>
        <Button variant="contained" size="medium" type="submit" color="primary" disabled={pristine || submitting}>
          <p>作成</p>
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  validate,
  form: "ADD_PRESS_KIT_NAME",
})(AddPressKitForm)
