import React from "react";
import { RouteComponentProps } from "react-router-dom";
import PanelLayout from "../../components/Layout/Panel";
import { Link, Typography } from "@material-ui/core";

interface Props extends RouteComponentProps<void> {}

const SendResetMailPage: React.FC<Props> = () => {

	return (
		<PanelLayout>
			<Typography variant="h1" component="h2" gutterBottom>
				メールを送信しました。
			</Typography>
			<Typography variant="caption" gutterBottom>
				入力いただいたメールアドレスに、パスワード再設定用のメールをお送りします。
			</Typography>
		</PanelLayout>
	);
}

export default SendResetMailPage;
