import React, {useEffect} from "react";

// component
import {Button, makeStyles, Typography} from "@material-ui/core/";

import {pressReleaseType} from "../../../../../config/press_release_type";
import {useSelector} from "react-redux";

import {RootState} from "../../../../../reducers";
import AppDialog from "../../../../Common/AppDialog";

const pressReleaseReserveSelector = (state: RootState) => state.pressReleaseReserve;

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: "center",
		flex: 1
	},
	tags: {
		marginRight: 10
	},
	button: {
		marginLeft: "auto"
	},
	required: {
		marginLeft: 30,
	},
});


interface IProps {
	handleOpenDialog,
	hasEdit: boolean,
	isReserveEdit: boolean,
	isLocked: boolean
}


const releaseType: React.FC<IProps> = ({handleOpenDialog, hasEdit, isReserveEdit, isLocked}) => {
	const classes = useStyles();
	const reserve = useSelector(pressReleaseReserveSelector);
	const [type, setType] = React.useState('');
	const [dialog, setDialog] = React.useState(false);

	useEffect(() => {
		const data = pressReleaseType.map((category, num) => {
			category.sub.map(item => {
				if (item.id === Number(reserve.data.type)) {
					setType(item.label)
					return item
				}
			})
		});
	}, [reserve.data.fetched, reserve.data.type]);


	return (
		<>
			{type ?
				<div className={classes.root}>
					<div className={classes.tags}>
						<Typography variant="h6" display={"block"}>
							{type}
						</Typography>
					</div>
					<Button variant="outlined" size={"small"} color={"primary"} onClick={() => setDialog(true)}
							className={classes.button} disabled={ !hasEdit || !isReserveEdit || isLocked}>
						再設定
					</Button>
				</div>
				:
				<>
					<Button variant="contained" color="primary" size={"large"} onClick={() => handleOpenDialog()} disabled={!hasEdit || isLocked}>
						リリースの種類を選択
					</Button>
					<Typography color={"error"} className={classes.required}>リリースの種類を選択してください</Typography>
				</>
			}

			<AppDialog isOpen={dialog} closeHandle={() => setDialog(false)} mainHandle={() => {
				setDialog(false)
				handleOpenDialog()
			}} text='自動選定送信先が変更になりますがよろしいですか？' ButtonText="再設定"/>
		</>
	);
};

export default releaseType;
