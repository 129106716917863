export const FREE = 0
export const SPOT = 1
export const PARTNERSHIP = 2
export const STANDARD = 3
export const PREMIUM = 4
export const ENTERPRISE = 5

// オプション
export const RADAR = 1
export const MEMBER = 2


export const optionList:{[key:number]:{id:number,"name":string,amount:number}} = {
    1:{ id: 1, name: "検索ワード", amount: 5000 },
    2:{ id: 2, name: "メンバー枠", amount: 1000 },
}

export const settingPaymentStatus = (status) => {
    switch (status) {
        case 0:
            return "未決済"
        case 1:
            return "支払済み"

    }
}

const TAX = 10

export const calcTax=(amount:number)=>{
    return amount * TAX / 100
}
