import React from "react";
import { useDispatch } from "react-redux";

// action
import * as PressReleasePublishActionCreators from "../../../../../actions/PressReleasePublish/ActionCreator";

// component
import Button from "@material-ui/core/Button";

interface disabledProps {
    hasEdit: boolean,
    publishCheck: boolean,
    loading: boolean,
    isPublic: boolean,
}

const PressReleasePublishSaveButton: React.FC<disabledProps> = ({ hasEdit, publishCheck, loading, isPublic }) => {

    const dispatch = useDispatch();

    //WEB公開情報(OGP)の保存
    const handlePublishSave = () => {
        dispatch(PressReleasePublishActionCreators.postPressReleasePublish.request());
    };

    return (
        <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!hasEdit || !publishCheck || loading || !isPublic}
            onClick={() => handlePublishSave()}>
            保存
        </Button>
    );
};

export default PressReleasePublishSaveButton;

