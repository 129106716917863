import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// component
import { makeStyles, Button, Typography, Tooltip, IconButton } from '@material-ui/core';
import FileDialog from '../Dialog/File';

// icons
import PublishIcon from '@material-ui/icons/Publish';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// model
import { BriefingBodyModel, BriefingQueryModel, FileDataModel } from '../../../model/BriefingModel';

interface ImageAnswerProps {
    file: File | FileDataModel | null,
    body: BriefingBodyModel,
    index: number,
}

const ImageAnswer: React.FC<ImageAnswerProps> = ({ file, body, index }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeImage = (file = null) => {
        const queries: BriefingQueryModel[] = [...body.queries];
        queries[index].file = file;
        dispatch(BriefingActionCreators.setBriefingQueries.request(queries));
    }

    return (
        <>
            <Button variant='outlined' onClick={() => setIsOpen(true)}>
                <PublishIcon color='primary' />
                <Typography>ファイルをアップロード</Typography>
            </Button>
            {file &&
                <div className={classes.fileWrapper}>
                    <div className={classes.file}>
                        <div className={classes.fileIcon}>
                            <InsertDriveFileIcon />
                        </div>
                        <Typography className={classes.fileName}>{file.name}</Typography>
                    </div>
                    <Tooltip title='削除'>
                        <IconButton onClick={() => handleChangeImage()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>}
            <FileDialog isOpen={isOpen} file={file} closeHandle={() => setIsOpen(false)}
                handleChange={handleChangeImage} />
        </>
    );
}

export default ImageAnswer;

const useStyles = makeStyles({
    fileWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    file: {
        display: 'flex',
        alignItems: 'center',
    },
    fileIcon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
    },
    fileName: {
        wordBreak: 'normal',
    },
});
