import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

// components
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Divider, List, ListItem, Typography } from '@material-ui/core';

// actions
import * as BriefingActionCreators from '../../../actions/Briefing/ActionCreator';

// config
import { BRIEFING_PAGE_TYPES as PAGE_TYPES, BRIEFING_STATUS } from '../../../config/briefing';

// state
import { RootState } from '../../../reducers';
const briefingSelector = (state: RootState) => state.briefing;

interface Props {
    pageType: string,
}

const Info: React.FC<Props> = ({ pageType }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { detail, publicInfo } = useSelector(briefingSelector);
    const { send_list: mailList, answer_list: answerList } = detail.body;
    const [selected, setSelected] = useState<number | null>(null);

    const handleSetAnswer = (email: string, index: number): void => {
        setSelected(index);
        const answers = publicInfo.answers.filter(({ created_by }) => created_by === email);
        if (answers.length > 0) {
            dispatch(BriefingActionCreators.setBriefingQueries.request(answers[0].queries));
        }
    }

    return (
        <Box className={classes.root}>
            <Typography variant='h6' className={classes.title}>回答状況</Typography>
            {detail.fetched ?
                detail.body.status != BRIEFING_STATUS.PUBLIC ?
                    <div className={classes.nonMessage}>
                        <Typography>
                            公開されていません。<br />
                            ヘッダーの公開ボタンを押して、<br />
                            回答の募集を開始できます。
                        </Typography>
                    </div>
                    :
                    <SendList mailList={mailList} answerList={answerList} pageType={pageType}
                        handleSetAnswer={handleSetAnswer} selected={selected} />
                :
                <div className={classes.circularProgress}>
                    <CircularProgress />
                </div>}
        </Box>

    )
}

export default Info;

const useStyles = makeStyles({
    root: {
        border: '1px solid #e0e0e0',
        minHeight: 500,
    },
    sendedItem: {
        height: 52,
    },
    innerItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: 5,
    },
    activeItem: {
        backgroundColor: '#E3F5EA',
        borderRadius: 4,
        display: 'flex',
    },
    title: {
        padding: 10,
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        padding: '100px 0',
    },
    nonMessage: {
        padding: '70px 10px',
        display: 'flex',
        justifyContent: 'center',
    },
    email: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    nonAnswer: {
        width: 60,
        padding: '0 5px',
    },
});

const SendList = ({ mailList, answerList, selected, handleSetAnswer, pageType }) => {
    const classes = useStyles();
    return (
        <List>
            <Divider />
            {mailList.map((email, index) => {
                const isAnswered = answerList.indexOf(email) >= 0;
                const isSelected = selected === index;
                return (
                    <React.Fragment key={index}>
                        <ListItem key={index} className={classes.sendedItem} button disabled={!isAnswered || pageType != PAGE_TYPES.PUBLIC}
                            onClick={() => handleSetAnswer(email, index)}>
                            <div className={classNames(classes.innerItem, isSelected ? classes.activeItem : '')}>
                                <Typography className={classes.email}>
                                    {email}
                                </Typography>
                                <Typography className={classes.nonAnswer}>
                                    {!isAnswered && "未回答"}
                                </Typography>
                            </div>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )
            })}
            {mailList.length === 0 &&
                <div className={classes.nonMessage}>
                    <Typography>
                        まだ回答依頼メールを送信していません
                    </Typography>
                </div>}
        </List>
    )
}
