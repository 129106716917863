import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// style
import style from '../Detail/media_reserve_list.module.scss';

// components
import { makeStyles, Typography, Checkbox, FormControlLabel, CircularProgress, Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Search from '../Search';

// actions
import * as MediaActionCreators from '../../../actions/Media/ActionCreator';

// state
import { RootState } from '../../../reducers';

// config
import { MEDIA_TYPE_LIST } from '../../../config/media_type';

const mediaSelector = (state: RootState) => state.media;

const MediaSelect = ({ handleSearch }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const media = useSelector(mediaSelector);
    const [value, setValue] = useState(false);

    const handleAddAllCheck = () => {
        setValue(true);
        dispatch(MediaActionCreators.addMediaCategoryIdListAll.request({ search: media.search.is_result }));
    }

    const handleRemoveAllCheck = () => {
        setValue(false);
        dispatch(MediaActionCreators.removeMediaCategoryIdListAll.request());
    }

    const handleChange = () => {
        if (value) handleRemoveAllCheck();
        else handleAddAllCheck();
    }

    useEffect(() => {
        const { id_list, results, all_id_list } = media.categoryList;
        if (media.search.is_result) {
            if (id_list.length < results.length) setValue(false);
        } else if (id_list.length < all_id_list.length) setValue(false);
    }, [media.categoryList])

    return (
        <div className={style.formRootDialog}>
            <div className={style.headerWrapperDialog}>
                <Typography variant='h5' className={style.header}>
                    {`${media.categoryList.id_list.length} / ${media.categoryList.all_id_list.length} 媒体`}
                </Typography>
                <Box className={classes.head}>
                    <FormControlLabel
                        control={<Checkbox checked={value} color='primary' onClick={() => handleChange()} />}
                        label="全てにチェックをつける"
                    />
                </Box>
                <Search handleSearch={handleSearch} />
            </div>
            <List />
        </div>
    );
};

export default MediaSelect;

const List = () => {
    const classes = useStyles();
    const media = useSelector(mediaSelector);
    const [page, setPage] = useState(0);

    return (
        <div className={style.listWrapper}>
            <div className={style.listContainer}>
                {media.categoryList.fetched ?
                    media.categoryList.results.length > 0 ?
                        <>
                            <ul className={style.list}>
                                {media.categoryList.results.slice(51 * page, 51 * (page + 1)).map((item, index) => (
                                    <ListLayoutItem key={index} item={item} />
                                ))}
                            </ul>
                        </>
                        : <NoMediaMessage />
                    :
                    <div className={classes.circularProgress}>
                        <CircularProgress />
                    </div>
                }
            </div>
            {media.categoryList.count > 0 && (
                <div className={style.paging}>
                    <Pagination count={Math.ceil(media.categoryList.count / 51)} page={page + 1} onChange={(e: React.ChangeEvent<unknown>, page: number) => setPage(page - 1)} />
                </div>
            )}
        </div>
    );
}

interface ListLayoutItemProps {
    item,
}

const ListLayoutItem: React.FC<ListLayoutItemProps> = ({ item }) => {
    const media = useSelector(mediaSelector);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = () => {
        dispatch(MediaActionCreators.setMediaCategoryIdList.request(item.id));
    }

    return (
        <li className={style.mediaItem}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={media.categoryList.id_list.indexOf(item.id) >= 0}
                        onChange={() => handleChange()}
                        value={item.id}
                        color="primary"
                    />
                }
                className={classes.mediaLabel}
                classes={{ label: classes.mediaInner }}
                label={<a target="_blank" href={item.url} className={classes.link}>{item.name}</a>}
            />
            <Typography className={classes.mediaType}>{MEDIA_TYPE_LIST[item.media_type]}</Typography>
        </li>
    );
}

const emptyDataStyles = makeStyles({
    root: {
        padding: '150px 20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
});

const NoMediaMessage: React.FC = () => {
    const classes = emptyDataStyles();

    return (
        <Typography variant={"h6"} className={classes.root}>
            メディアはありません
        </Typography>
    );
};

const useStyles = makeStyles({
    root: {
        height: 500,
    },
    mediaLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    },
    mediaInner: {
        verticalAlign: 'middle',
    },
    link: {
        color: "#5965b9",
        textDecoration: "underline",
    },
    mediaType: {
        fontSize: 11,
        color: "#999"
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85%',
        width: '100%',
    },
    head: {
        marginRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
})
