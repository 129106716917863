import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { makeStyles } from "@material-ui/core/styles";
import {
    Box, Button, ClickAwayListener, Paper, Typography
} from '@material-ui/core';
import FilterPanel from './Panel';

// icons
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

//actions
import * as PressReleaseActionCreators from '../../../../actions/PressRelease/ActionCreator';

// state
import { RootState } from '../../../../reducers';
import { PressFilterTypes } from '../../../../config/press_filter_type';
const pressReleaseListSelector = (state: RootState) => state.pressReleaseList;
const pressReleaseTagSelector = (state: RootState) => state.pressReleaseTag;
const memberSelector = (state: RootState) => state.member;
const routerSelector = (state: RootState) => state.router;

const Filter = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { archive, publicList, search } = useSelector(pressReleaseListSelector);
    const pressTag = useSelector(pressReleaseTagSelector);
    const router = useSelector(routerSelector);
    const members = useSelector(memberSelector).list.results;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleClose = () => setIsOpen(false);

    const handleResetFilter = () => {
        dispatch(PressReleaseActionCreators.resetPressReleaseFilter.request());
        getPressReleaseList();
    }

    const getPressReleaseList = () => {
        const is_archive = !!(router.location.pathname.indexOf('archive') > -1 ? 1 : 0);
        const is_public = !!(router.location.pathname.indexOf('public') > -1 ? 1 : 0);
        if (is_archive) {
            dispatch(PressReleaseActionCreators.getPressReleaseListArchive.request({ offset: archive.offset }));
        } else if (is_public) {
            dispatch(PressReleaseActionCreators.getPressReleaseListPublic.request({ offset: publicList.offset }));
        } else {
            dispatch(PressReleaseActionCreators.getPressReleaseList.request({ offset: search.offset }));
        }
    }

    return (
        <ClickAwayListener onClickAway={() => handleClose()}>
            <Box className={classes.root}>
                <Button variant={"outlined"} size={"small"} color={"primary"} className={classes.filters} onClick={() => setIsOpen(!isOpen)}>
                    <LibraryBooksIcon className={classes.iconGray} />
                    <Typography>絞り込み</Typography>
                    {isOpen ?
                        <KeyboardArrowDownIcon className={classes.iconGray} /> : <KeyboardArrowUpIcon className={classes.iconGray} />}
                </Button>
                {isOpen &&
                    <Paper className={classes.filterBox} variant={"outlined"} elevation={0}>
                        <Box className={classes.buttons}>
                            <Button variant='outlined' color='primary' className={classes.button} onClick={() => {
                                handleResetFilter();
                                handleClose();
                            }}>
                                リセット
                            </Button>
                            <Button variant='contained' color='primary' className={classes.button} onClick={() => {
                                getPressReleaseList();
                                handleClose();
                            }}>
                                条件を適用する
                            </Button>
                        </Box>
                        <Box className={classes.boxes}>
                            <FilterPanel items={members} type={PressFilterTypes.creator} />
                            <FilterPanel items={members} type={PressFilterTypes.reviewer} />
                            <FilterPanel items={pressTag.list.results} type={PressFilterTypes.tag} />
                        </Box>
                    </Paper>
                }
            </Box>
        </ClickAwayListener>
    );
}

export default Filter;

const useStyles = makeStyles({
    root: {
        position: 'relative',
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        cursor: 'pointer',
    },
    iconGray: {
        color: 'gray',
        margin: '0 10px',
    },
    filterBox: {
        position: 'absolute',
        zIndex: 100,
        right: '-100px',
        top: 53,
        background: '#e2e9e5',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        padding: '5px 15px',
        width: 650,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '10px 0 5px',
    },
    button: {
        margin: '0 5px',
    },
    boxes: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
    },
})
