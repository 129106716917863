import React from "react";

//material-ui
import {Divider, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

//model
import {TicketQtyModel} from "../../../../../model/TicketModel";

interface Props {
  ticketQty: TicketQtyModel;
}

export const TicketQtyView: React.FC<Props> = ({ticketQty}) => {
  const classes = useStyles();
  const totalAvailableTicketQty =
    ticketQty["default"]["available"] + ticketQty["non_default"]["available"];
  const totalReservedTicketQty =
    ticketQty["default"]["reserved"] + ticketQty["non_default"]["reserved"];

  return (
    <div className={classes.qty}>
      <Typography variant="h6">現在の配信数の総数</Typography>
      <Divider />
      <Typography variant="h6">
        残り配信数： {totalAvailableTicketQty}回<br />
        予約中： {totalReservedTicketQty}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles({
  qty: {
    margin: 15
  }
});
